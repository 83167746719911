import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import delay from "delay";
import Copyicon from "../../common/Copy/Copyicon";
import Copymsg from "../../common/Copy/Copymsg";

import { OverlayTrigger, Popover } from "react-bootstrap";

const From = ({ from, fromNameTag }) => {
    const [getFromCopy, setCopyFrom] = useState("");

    async function copyfrom() {
        setCopyFrom("copied");
        await delay(1000);
        setCopyFrom("");
    }

    const popoverTop2 = (
        <Popover id="popover-basic">
            {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
            <Popover.Body>The sending party of the transaction (could be from a contract address).</Popover.Body>
        </Popover>
    );

    return (
        <>
            <div className="row">
                <div className="col-md-3 mb-1 bs h6 text-blueish">
                    <OverlayTrigger style={{ padding: "100px 0" }} trigger="hover" placement="top" overlay={popoverTop2}>
                        <i class="fal fa-question-circle text-secondary mr-1" aria-hidden="true"></i>
                    </OverlayTrigger>
                    From
                </div>
                <div className="col-md-6 mb-1 bs text-blue" key={fromNameTag}>
                    <Link to={`/address/${from}`} className="text-blue">
                        {from}{" "}
                    </Link>
                    &nbsp;
                    {fromNameTag ? (
                        <span
                            className="hash-tag text-truncate u-label u-label--value u-label--secondary"
                            data-toggle="tooltip"
                            title
                            data-original-title="My Name Tag: hld name
"
                        >
                            {fromNameTag}
                        </span>
                    ) : (
                        ""
                    )}
                    <CopyToClipboard
                        text={from}
                        onCopy={() => {
                            copyfrom();
                        }}
                    >
                        <span>{getFromCopy === "" ? <Copyicon /> : <Copymsg />} </span>
                    </CopyToClipboard>
                    {/* {
                        getFromCopy ? <span className="mx-2 text-black "> copied <i className="fa fa-check"></i></span> : ""
                    } */}
                </div>
            </div>
            <hr />
        </>
    );
};

export default From;
