import React, { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Header from "../common/Header";

function ConfirmRegister() {
  const { id } = useParams();
  const [success, setSucceess] = useState("");
  const [failed, setFailed] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [message, setMessage] = useState("");

  const confirmUserRegistration = async () => {
    await axios.post(`${process.env.REACT_APP_BACKEND_URL}user/verifyToken`, { token: id }).then((res) => {
      if (res.data.error) {
        setFailed(true);
        setIsExpired(true);
        setMessage("Token is expired, please register again.");
      } else {
        setFailed(false);
        setIsExpired(false);
        setMessage("");
      }
    });
  };

  useEffect(() => {
    confirmUserRegistration();
  });

  return (
    <>
      <Header />
      <main
        id="content"
        role="main"
        class="error-img"
        style={{ justifyContent: "center", minHeight: "800px" }}
      >
        <div className="container d-flex justify-content-center" style={{ marginTop: "5%" }}>
          <div className="card w-md-50 w-75">
            <div>
              <div
                className="card-header pt-4 py-2 bg-igtred"
                style={{ justifyContent: "center", color: "white", fontWeight: "500" }}
              >
                <p className="text-center">
                  {failed ? (
                    <>
                      {message ? (
                        <>
                          <div class="text-center mb-3">
                            <img
                              src="/asset/images/mail_images/ckeck-your-email.png"
                              alt=""
                              height="100px"
                              width="100px"
                            />
                          </div>
                          <span>{message}</span>
                          <div className="d-flex justify-content-center align-items-center mb-5 pr-5 pl-5 mt-5">
                            <u>
                              <a href="/register" class="btn btn-sm btn-primary">
                                <span data-toggle="modal" data-target="#basicModal">
                                  Register
                                </span>
                              </a>
                            </u>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <>
                      <div class="text-center mb-3">
                        <img
                          src="/asset/images/mail_images/email-confirmation.png"
                          alt=""
                          height="100px"
                          width="150px"
                        />
                      </div>
                      <span class="text-success"> {success}</span> <br />
                      <span> Please Login to your account</span>
                      <div className="d-flex flex-md-row flex-column justify-content-between align-items-center mb-5 pr-5 pl-5 mt-5">
                        <>
                          {" "}
                          <u>
                            <Link to="/" class="btn btn-sm btn-primary mb-2">
                              Back to Home
                            </Link>
                          </u>
                          <u>
                            <Link to="/login" class="btn btn-sm btn-primary mb-2">
                              <span data-toggle="modal" data-target="#basicModal">
                                Login
                              </span>
                            </Link>
                          </u>
                        </>
                      </div>
                    </>
                  )}
                  {/*{failed ? (
                                        <>
                                            {message ? (
                                                <>
                                                    <div class="text-center mb-3">
                                                        <img
                                                            src="/asset/images/mail_images/ckeck-your-email.png"
                                                            alt=""
                                                            height="100px"
                                                            width="100px"
                                                        />
                                                    </div>
                                                    <span>{message}</span>
                                                </>
                                            ) : (
                                                <>
                                                    <div class="text-center mb-3">
                                                        <img src="/asset/images/mail_images/token-expired.png" alt="" height="80px" width="110px" />
                                                    </div>

                                                    {isExpired ? (
                                                        <>
                                                            {" "}
                                                            <span class="text-danger">{failed}</span> <br />
                                                            {getToken ? (
                                                                <>
                                                                    {" "}
                                                                    {/* <span type="button" onClick={() => updateResendLink()}>
                                                                        Resend the Link
                                                                    </span>{" "}
                                                                    <a href="/register">Register</a>
                                                                </>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </>
                                                    ) : (
                                                        <span> Email already confirmed</span>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <div class="text-center mb-3">
                                                <img src="/asset/images/mail_images/email-confirmation.png" alt="" height="100px" width="150px" />
                                            </div>
                                            <span class="text-success"> {success}</span> <br />
                                            <span> Please Login to your account</span>
                                        </>
                                    )} */}
                </p>
              </div>
              {/* {failed ? (
                                <>
                                    <div className="text-center mb-5 pr-5 pl-5 mt-5">
                                        <u class="text-center">
                                            <button class="btn btn-sm btn-primary cursor-pointer" onClick={updateResendLink}>
                                                Resend mail
                                            </button>
                                        </u>
                                    </div>
                                </>
                            ) : (
                                <div className="d-flex justify-content-between align-items-center mb-5 pr-5 pl-5 mt-5">
                                    <>
                                        {" "}
                                        <u>
                                            <Link to="/" class="btn btn-sm btn-primary">
                                                Back to Home
                                            </Link>
                                        </u>
                                        <u>
                                            <Link to="/login" class="btn btn-sm btn-primary">
                                                <span data-toggle="modal" data-target="#basicModal">
                                                    Login
                                                </span>
                                            </Link>
                                        </u>
                                    </>
                                </div>
                            )} */}
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ConfirmRegister;
