import React, { useContext, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Spinner from "../../common/Spinner";
import { Link } from "react-router-dom";
import axios from "axios";
import Pagesize from "../../common/Pagesize";
import Pagination from "../../common/Pagination";
import { IgniteContext } from "../../IgniteContext";
import NoEntryMessage from "../../common/NoEntryMessage";
import FadingLoaderCard1 from "../../common/ContentLoaders/LoaderBigLine";

const Erc721TokenTxns = ({
  id,
  addressDetails,
  tokens,
  spinner,
  total,
  setPageSize,
  PageSize,
  currentPage,
  setCurrentPage,
  pages,
}) => {
  const { formatAddress, formatHash, formatTokenName, formatTokenSymbol } = useContext(IgniteContext);

  return (
    <div className="tab-pane fade show active">
      {spinner ? (
        <Spinner />
      ) : total === 0 ? (
        <div>
          <p className="text-black container" style={{ textAlign: "center" }}>
            No Transactions Found
          </p>
        </div>
      ) : (
        <div className="table-responsive mb-2 mb-md-0">
          <p className="text-grey">Total {total} transactions found</p>
          <table className="table table-hover">
            <thead className="thead-light">
              <tr>
                <th scope="col" width={140}>
                  {" "}
                  Txn Hash{" "}
                </th>
                <th scope="col" width={100}>
                  Age
                </th>
                <th scope="col" width={150}>
                  From
                </th>
                <th scope="col" width={20}></th>
                <th scope="col" width={100}>
                  To
                </th>
                <th scope="col" width={100}>
                  TokenID{" "}
                </th>
                <th scope="col" width={100}>
                  Token
                </th>
              </tr>
            </thead>
            <tbody>
              {spinner ? (
                <>
                  <tr>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                    <td> </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                    <td> </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                    <td> </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                    <td> </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                    <td> </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                    <td> </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                    <td> </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                    <td> </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                    <td> </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                    <td> </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                    </td>
                    <td>
                      {" "}
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                    </td>
                  </tr>
                </>
              ) : (
                <>
                  {tokens && tokens.length ? (
                    tokens.map((item, index) => {
                      // const ind = currentPage * 10 - 10 + (index + 1);
                      return (
                        <tr>
                          {/* <td>{}</td> */}
                          <td>
                            {" "}
                            <span className="hash-tag text-truncate">
                              <Link to={`/tx/${item.hash}`}>{formatHash(item.hash)}</Link>
                            </span>
                          </td>
                          <td className="showAge">{item.age}</td>
                          <td>
                            <OverlayTrigger
                              placement="top"
                              trigger="hover"
                              overlay={<Tooltip>{item.from}</Tooltip>}
                            >
                              {item.from === addressDetails?.address?.toLowerCase() ? (
                                <span>
                                  {item?.fromNameTag?.nameTag
                                    ? item?.fromNameTag?.nameTag
                                    : formatAddress(item.from)}
                                </span>
                              ) : (
                                <Link to={`/address/${item.from}`} className="text-blue">
                                  {item?.fromNameTag?.nameTag
                                    ? item?.fromNameTag?.nameTag
                                    : formatAddress(item.from)}
                                </Link>
                              )}
                            </OverlayTrigger>
                          </td>
                          <td>
                            {id === item.from ? (
                              item.from === item.to ? (
                                <span class="u-label u-label--xs u-label--secondary color-strong text-uppercase text-center w-100 rounded text-nowrap">
                                  SELF
                                </span>
                              ) : (
                                <span className="u-label u-label--xs u-label--warning color-strong text-uppercase text-center w-100 rounded text-nowrap">
                                  OUT
                                </span>
                              )
                            ) : (
                              <span className="u-label u-label--xs u-label--success color-strong text-uppercase text-center w-100 rounded text-nowrap">
                                &nbsp;IN&nbsp;
                              </span>
                            )}
                          </td>
                          <td>
                            <OverlayTrigger
                              placement="top"
                              trigger="hover"
                              overlay={<Tooltip>{item.to}</Tooltip>}
                            >
                              {item.to === addressDetails?.address?.toLowerCase() ? (
                                <span class="text-black">
                                  {item?.toNameTag?.nameTag
                                    ? item?.toNameTag?.nameTag
                                    : formatAddress(item.to)}
                                </span>
                              ) : (
                                <Link to={`/address/${item.to}`} className="text-blue">
                                  {item?.toNameTag?.nameTag
                                    ? item?.toNameTag?.nameTag
                                    : formatAddress(item.to)}
                                </Link>
                              )}
                            </OverlayTrigger>
                          </td>
                          <td>
                            <Link to={{ pathname: `/token/${item.token}`, search: `?a=${item.tokenId}` }}>
                              {item.tokenId}
                            </Link>
                          </td>
                          <td>
                            <span>
                              <img
                                class="mr-1 mCS_img_loaded"
                                width="18"
                                src={
                                  item && (item?.status === "true" || item?.status === true) && item?.logo
                                    ? process.env.REACT_APP_TOKEN_LOGO_PATH + item?.logo
                                    : "/asset/images/48.png"
                                }
                                alt="TWT"
                              />
                            </span>{" "}
                            <OverlayTrigger
                              placement="top"
                              trigger="hover"
                              overlay={
                                <Tooltip>
                                  {item.name} ({item.symbol})
                                </Tooltip>
                              }
                            >
                              <Link to={`/address/${item.token}`}>
                                {formatTokenName(item.name)} ({formatTokenSymbol(item.symbol)}){" "}
                              </Link>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <NoEntryMessage message={process.env.REACT_APP_BLANK_DATA_MESSAGE} />
                  )}
                </>
              )}
            </tbody>
          </table>
          {spinner ? (
            ""
          ) : (
            <>
              <div class="row w-100 mt-3">
                <div class="col-md-6 mt-3">
                  <Pagesize setPageSize={setPageSize} PageSize={PageSize} />
                </div>
                <div class="col-md-6 mt-3">
                  <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPage={pages} />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Erc721TokenTxns;
