import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import FadingLoaderCard1 from "../ContentLoaders/LoaderBigLine";

const BlockDetailsLoader = () => {
    const detail1 = (
        <Popover>
            <Popover.Body>
                Also known as Block Number. The block height, which indicates the length of the blockchain, increases after the addition of the new
                block.
            </Popover.Body>
        </Popover>
    );
    const detail2 = (
        <Popover>
            <Popover.Body>The date and time at which a block is mined.</Popover.Body>
        </Popover>
    );
    const detail3 = (
        <Popover>
            <Popover.Body>
                The number of transactions in the block. Internal transaction is transactions as a result of contract execution that involves Ether
                value.
            </Popover.Body>
        </Popover>
    );
    const detail4 = (
        <Popover>
            <Popover.Body>Miner who successfully include the block onto the blockchain.</Popover.Body>
        </Popover>
    );
    const detail5 = (
        <Popover>
            <Popover.Body>
                For each block, the miner is rewarded with a finite amount of Ether on top of the fees paid for all transactions in the block.
            </Popover.Body>
        </Popover>
    );
    const detail6 = (
        <Popover>
            <Popover.Body>The amount of effort required to mine a new block. The difficulty algorithm may adjust according to time.</Popover.Body>
        </Popover>
    );
    const detail7 = (
        <Popover>
            <Popover.Body>The amount of effort required to mine a new block. The difficulty algorithm may adjust according to time.</Popover.Body>
        </Popover>
    );
    const detail8 = (
        <Popover>
            <Popover.Body>The total gas used in the block and its percentage of gas filled in the block.</Popover.Body>
        </Popover>
    );
    const detail9 = (
        <Popover>
            <Popover.Body>Total gas limit provided by all transactions in the block.</Popover.Body>
        </Popover>
    );
    const detail10 = (
        <Popover>
            <Popover.Body>The hash of the block header of the current block.</Popover.Body>
        </Popover>
    );
    const detail11 = (
        <Popover>
            <Popover.Body>The hash of the block from which this block was generated, also known as its parent block.</Popover.Body>
        </Popover>
    );
    const detail12 = (
        <Popover>
            <Popover.Body>Block nonce is a value used during mining to demonstrate proof of work for a block.</Popover.Body>
        </Popover>
    );
    const detail13 = (
        <Popover>
            <Popover.Body>Total difficulty of the chain until this block.</Popover.Body>
        </Popover>
    );
    const detail14 = (
        <Popover>
            <Popover.Body>Any data that can be included by the miner in the block.</Popover.Body>
        </Popover>
    );
    const detail15 = (
        <Popover>
            <Popover.Body>
                Post-London Upgrade, this represents the minimum gasUsed multiplier required for a tx to be included in a block.{" "}
            </Popover.Body>
        </Popover>
    );
    const detail16 = (
        <Popover>
            <Popover.Body>The mechanism which Polygon Javascript RLP encodes an empty string.</Popover.Body>
        </Popover>
    );

    return (
        <div>
            <div className="card-header d-flex justify-content-between align-items-center p-0">
                <ul className="nav nav-custom nav-borderless nav_tabs1" id="nav_tabs" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" href="#overview" data-toggle="tab">
                            Overview
                        </a>
                    </li>
                </ul>
            </div>
            <div className="tab-content">
                <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="home-tab">
                    <div id="ContentPlaceHolder1_maintable" className="card-body">
                        <div className="row align-items-center  mt-1">
                            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                <OverlayTrigger placement="top" trigger="hover" overlay={detail1}>
                                    <i
                                        className="fal fa-question-circle text-secondary mr-1"
                                        data-container="body"
                                        data-toggle="popover"
                                        data-placement="top"
                                        data-original-title
                                        title
                                        data-content="Also known as Block Number. The block height, which indicates the length of the blockchain, increases after the addition of the new block."
                                    />
                                </OverlayTrigger>
                                Block Height:
                            </div>
                            <div className="col-md-9">
                                <div className="d-flex">
                                    <span className="font-weight-sm-bold mr-2">
                                        <div className="mb-2 mb-md-0e">
                                            <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="150" height="12" />
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <hr className="hr-space" />
                        <div className="row align-items-center">
                            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                <OverlayTrigger placement="top" trigger="hover" overlay={detail2}>
                                    <i
                                        className="fal fa-question-circle text-secondary mr-1"
                                        data-container="body"
                                        data-toggle="popover"
                                        data-placement="top"
                                        data-original-title
                                        title
                                        data-content="The date and time at which a block is validated."
                                    />
                                </OverlayTrigger>
                                Timestamp:
                            </div>
                            <div className="col-md-9">
                                <div className="mb-2 mb-md-0e">
                                    <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="250" height="12" />
                                </div>
                            </div>
                        </div>
                        <div id="ContentPlaceHolder1_div_tx_fieldname">
                            <hr className="hr-space mb-2" />
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    <OverlayTrigger placement="top" trigger="hover" overlay={detail3}>
                                        <i
                                            className="fal fa-question-circle text-secondary mr-1"
                                            data-container="body"
                                            data-toggle="popover"
                                            data-placement="top"
                                            data-original-title
                                            title
                                            data-content={`The number of transactions in the block. Internal transaction is transactions as a result of contract execution that involves ${process.env.REACT_APP_TICKER} value.`}
                                        />
                                    </OverlayTrigger>
                                    Transactions:
                                </div>
                                <div id="ContentPlaceHolder1_div_tx_fieldvalue" className="col-md-9">
                                    <div className="mb-2 mb-md-0e">
                                        <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="300" height="12" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="hr-space mt-2" />
                        <div className="row align-items-center">
                            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                <OverlayTrigger placement="top" trigger="hover" overlay={detail4}>
                                    <i
                                        className="fal fa-question-circle text-secondary mr-1"
                                        data-container="body"
                                        data-toggle="popover"
                                        data-placement="top"
                                        data-original-title
                                        title
                                        data-content="Validator who successfully include the block onto the blockchain."
                                    />
                                </OverlayTrigger>
                                Validated by:
                            </div>
                            <div className="col-md-9">
                                <div className="mb-2 mb-md-0e">
                                    <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="270" height="12" />
                                </div>
                            </div>
                        </div>
                        <hr className="hr-space" />
                        <div className="row align-items-center">
                            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                <OverlayTrigger placement="top" trigger="hover" overlay={detail5}>
                                    <i
                                        className="fal fa-question-circle text-secondary mr-1"
                                        data-container="body"
                                        data-toggle="popover"
                                        data-placement="top"
                                        data-original-title
                                        title
                                        data-content="The amount of effort required to mine a new block. The difficulty algorithm may adjust according to time."
                                    />
                                </OverlayTrigger>
                                Block Reward:
                            </div>
                            <div className="col-md-9">
                                <div className="mb-2 mb-md-0e">
                                    <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="210" height="12" />
                                </div>
                            </div>
                        </div>
                        <hr className="hr-space" />
                        <div className="row align-items-center">
                            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                <OverlayTrigger placement="top" trigger="hover" overlay={detail6}>
                                    <i
                                        className="fal fa-question-circle text-secondary mr-1"
                                        data-container="body"
                                        data-toggle="popover"
                                        data-placement="top"
                                        data-original-title
                                        title
                                        data-content="The amount of effort required to mine a new block. The difficulty algorithm may adjust according to time."
                                    />
                                </OverlayTrigger>
                                Difficulty:
                            </div>
                            <div className="col-md-9">
                                <div className="mb-2 mb-md-0e">
                                    <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="70" height="12" />
                                </div>
                            </div>
                        </div>
                        <hr className="hr-space" />
                        <div className="row align-items-center">
                            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                <OverlayTrigger placement="top" trigger="hover" overlay={detail13}>
                                    <i
                                        className="fal fa-question-circle text-secondary mr-1"
                                        data-container="body"
                                        data-toggle="popover"
                                        data-placement="top"
                                        data-original-title
                                    />
                                </OverlayTrigger>
                                Total Difficulty:
                            </div>
                            <div className="col-md-9">
                                <div className="mb-2 mb-md-0e">
                                    <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="100" height="12" />
                                </div>
                            </div>
                        </div>
                        <hr className="hr-space" />
                        <div className="row align-items-center">
                            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                <OverlayTrigger placement="top" trigger="hover" overlay={detail7}>
                                    <i
                                        className="fal fa-question-circle text-secondary mr-1"
                                        data-container="body"
                                        data-toggle="popover"
                                        data-placement="top"
                                        data-original-title
                                        title
                                        data-content="The block size is actually determined by the block's gas limit."
                                    />
                                </OverlayTrigger>
                                Size:
                            </div>
                            <div className="col-md-9">
                                <div className="mb-2 mb-md-0e">
                                    <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="110" height="12" />
                                </div>
                            </div>
                        </div>
                        <hr className="hr-space" />
                        <div className="row align-items-center">
                            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                <OverlayTrigger placement="top" trigger="hover" overlay={detail8}>
                                    <i
                                        className="fal fa-question-circle text-secondary mr-1"
                                        data-container="body"
                                        data-toggle="popover"
                                        data-placement="top"
                                        data-original-title
                                        title
                                        data-content="The total gas used in the block and its percentage of gas filled in the block."
                                    />
                                </OverlayTrigger>
                                Gas Used:
                            </div>
                            <div className="col-md-9">
                                <div className="mb-2 mb-md-0e">
                                    <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="130" height="12" />
                                </div>
                            </div>
                        </div>
                        <hr className="hr-space" />
                        <div className="row align-items-center">
                            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                <OverlayTrigger placement="top" trigger="hover" overlay={detail9}>
                                    <i
                                        className="fal fa-question-circle text-secondary mr-1"
                                        data-container="body"
                                        data-toggle="popover"
                                        data-placement="top"
                                        data-original-title
                                        title
                                        data-content="Total gas limit provided by all transactions in the block."
                                    />
                                </OverlayTrigger>
                                Gas Limit:
                            </div>
                            <div className="col-md-9">
                                <div className="mb-2 mb-md-0e">
                                    <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="110" height="12" />
                                </div>
                            </div>
                        </div>
                        <hr className="hr-space" />
                        <div className="row align-items-center">
                            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                <OverlayTrigger placement="top" trigger="hover" overlay={detail15}>
                                    <i
                                        className="fal fa-question-circle text-secondary mr-1"
                                        data-container="body"
                                        data-toggle="popover"
                                        data-placement="top"
                                        data-original-title
                                    />
                                </OverlayTrigger>
                                Base Fee Per Gas:
                            </div>
                            <div className="col-md-9">
                                <div className="mb-2 mb-md-0e">
                                    <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="290" height="12" />
                                </div>
                            </div>
                        </div>
                        <hr className="hr-space" />
                        <div className="row align-items-center">
                            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                <OverlayTrigger placement="top" trigger="hover" overlay={detail14}>
                                    <i
                                        className="fal fa-question-circle text-secondary mr-1"
                                        data-container="body"
                                        data-toggle="popover"
                                        data-placement="top"
                                        data-original-title
                                        title
                                    />
                                </OverlayTrigger>
                                Extra Data:
                            </div>
                            <div className="col-md-9">
                                <div className="mb-2 mb-md-0e">
                                    <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="300" height="130" />
                                </div>
                            </div>
                        </div>
                        <hr className="hr-space" />
                        <div className="collapse  " id="collapsePanel">
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    <OverlayTrigger placement="top" trigger="hover" overlay={detail10}>
                                        <i
                                            className="fal fa-question-circle text-secondary mr-1"
                                            data-container="body"
                                            data-toggle="popover"
                                            data-placement="top"
                                            data-original-title
                                            title
                                            data-content="The hash of the block header of the current block."
                                        />
                                    </OverlayTrigger>
                                    Hash:
                                </div>
                                <div className="col-md-9">
                                    <div className="mb-2 mb-md-0e">
                                        <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="300" height="12" />
                                    </div>
                                </div>
                            </div>
                            <hr className="hr-space" />
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    <OverlayTrigger placement="top" trigger="hover" overlay={detail11}>
                                        <i
                                            className="fal fa-question-circle text-secondary mr-1"
                                            data-container="body"
                                            data-toggle="popover"
                                            data-placement="top"
                                            data-original-title
                                            title
                                            data-content="The hash of the block from which this block was generated, also known as its parent block."
                                        />
                                    </OverlayTrigger>
                                    Parent Hash:
                                </div>
                                <div className="col-md-9">
                                    <span>
                                        <div className="mb-2 mb-md-0e">
                                            <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="300" height="12" />
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <hr className="hr-space" />
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    <OverlayTrigger placement="top" trigger="hover" overlay={detail16}>
                                        <i
                                            className="fal fa-question-circle text-secondary mr-1"
                                            data-container="body"
                                            data-toggle="popover"
                                            data-placement="top"
                                            data-original-title
                                        />
                                    </OverlayTrigger>
                                    Sha3Uncles:
                                </div>
                                <div className="col-md-9">
                                    <div className="mb-2 mb-md-0e">
                                        <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="300" height="12" />
                                    </div>
                                </div>
                            </div>
                            <hr className="hr-space" />
                            <div className="row align-items-center">
                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                    <OverlayTrigger placement="top" trigger="hover" overlay={detail12}>
                                        <i
                                            className="fal fa-question-circle text-secondary mr-1"
                                            data-container="body"
                                            data-toggle="popover"
                                            data-placement="top"
                                            data-original-title
                                            title
                                            data-content="Block nonce is a value used during mining to demonstrate proof of work for a block."
                                        />
                                    </OverlayTrigger>
                                    Nonce:
                                </div>
                                <div className="col-md-9">
                                    <div className="mb-2 mb-md-0e">
                                        <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="170" height="12" />
                                    </div>
                                </div>
                            </div>
                            <hr className="hr-space" />
                        </div>
                        {/* <span id="ContentPlaceHolder1_collapsedLink_span">
                                        <a className={!acToggle ? "d-block collapsed text-primary cursor-pointer" : "d-block text-primary cursor-pointer"} id="collapsedLink" data-toggle="collapse" role="button" aria-expanded={acToggle} aria-controls="collapsePanel" onClick={handleAcordian}>
                                            <span>
                                                Click to see <span className="card-arrow-more">more</span> <span className="card-arrow-less">less</span>
                                                <span className="card-btn-arrow card-btn-arrow--up ml-1">
                                                    <span className="fas fa-arrow-up small my-3" />
                                                </span>
                                            </span>
                                        </a>
                                    </span> */}
                        <span id="ContentPlaceHolder1_collapsedLink_span">
                            <a
                                className="d-block collapsed"
                                id="collapsedLink"
                                data-toggle="collapse"
                                href="#collapsePanel"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapsePanel"
                            >
                                <span>
                                    Click to see <span className="card-arrow-more">more</span> <span className="card-arrow-less">less</span>
                                    <span className="card-btn-arrow card-btn-arrow--up ml-1">
                                        <span className="fas fa-arrow-up small my-3" />
                                    </span>
                                </span>
                            </a>
                        </span>
                    </div>
                </div>
                <div className="tab-pane fade" id="comments" role="tabpanel" aria-labelledby="comments-tab">
                    <div className="card-body">
                        Make sure to use the "Vote Down" button for any spammy posts, and the "Vote Up" for interesting conversations.
                        <br />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlockDetailsLoader;
