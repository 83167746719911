import React, { useState } from "react";
import { Link } from "react-router-dom";

const Mode = () => {
  const [isHoverMode, setIsHoverMode] = useState(false);

  return (
    <li
      className={`nav-item hs-has-sub-menu u-header__nav-item my-md-n1 ${
        isHoverMode ? "hs-sub-menu-opened" : ""
      }`}
      onMouseEnter={() => {
        setIsHoverMode(true);
      }}
      onMouseLeave={() => {
        setIsHoverMode(false);
      }}
      data-event="hover"
      data-animation-in="slideInUp"
      data-animation-out="fadeOut"
    >
      {/* eslint-disable-next-line */}
      <a
        id="explorersWithDropdown"
        className="nav-link u-header__nav-link u-header__nav-link-toggle u-header__nav-link-toggle--mobile pr-0"
        href="javascript:void(0);"
        aria-haspopup="true"
        aria-expanded="false"
        aria-labelledby="explorersDropdown"
      >
        <span className="btn btn-sm btn-icon btn-soft-primary cursor-pointer d-none d-md-inline-block  navbar_logo_icon">
          <img className="u-xs-avatar btn-icon__inner" src="/asset/images/48.png" alt="Logo1" />
        </span>
        <span className="d-inline-block d-md-none">Explorers</span>
      </a>
      <ul
        id="explorersDropdown"
        className={`hs-sub-menu u-header__sub-menu u-header__sub-menu--spacer ${
          isHoverMode ? "fadeOut custom-drop-hover-none" : "slideInUp custom-drop-hover"
        }`}
        aria-labelledby="explorersWithDropdown"
      >
        <li id="LI_Mainnet">
          <Link className="nav-link u-header__sub-menu-nav-link" to="#">
            {process.env.REACT_APP_TICKER} Mainnet
          </Link>
        </li>
        {/* <li id="LI_testnet">
          <Link className="nav-link u-header__sub-menu-nav-link" to="#">
            {process.env.REACT_APP_TICKER} Testnet
          </Link>
        </li> */}
      </ul>
    </li>
  );
};

export default Mode;
