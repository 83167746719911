import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import web3 from "web3";
import Footer from "../common/Footer";
import Header2 from "../common/Header2";

function UnitConverter() {
    const location = useLocation();
    const navigate = useNavigate();

    let validator = /^^[0-9]+([\.\-]?[0-9]+)?$/;
    const [wei, setWei] = useState(0);
    const [kwei, setKwei] = useState(0);
    const [mwei, setMwei] = useState(0);
    const [gwei, setGwei] = useState(0);
    const [szabo, setSzabo] = useState(0);
    const [finney, setFinney] = useState(0);
    const [igt, setIgt] = useState("1");
    const [kigt, setKigt] = useState(0);
    const [migt, setMigt] = useState(0);
    const [gigt, setGigt] = useState(0);
    const [tigt, setTigt] = useState(0);

    useEffect(() => {
        let weisearchval = 0;
        if (location.search) {
            weisearchval = location.search.split("wei=")[1];
            handleChangeWei(weisearchval);
        } else {
            handleChangeIgt(1);
        }
    }, []);

    const handleReset = () => {
        handleChangeIgt(1);
        navigate("/unitconverter");
    };

    const handleChangeWei = (value) => {
        if (value !== "") {
            if (validator.test(value)) {
                let vallength = value.toString().split(".");
                if (!vallength[1] || vallength[1]?.length <= 1) {
                    const wei = web3.utils.toWei(value.toString(), "wei");
                    setWei(value);
                    setKwei(web3.utils.fromWei(wei, "kwei"));
                    setMwei(web3.utils.fromWei(wei, "mwei"));
                    setGwei(web3.utils.fromWei(wei, "gwei"));
                    setSzabo(web3.utils.fromWei(wei, "szabo"));
                    setFinney(web3.utils.fromWei(wei, "finney"));
                    setIgt(web3.utils.fromWei(wei, "ether"));
                    setKigt(web3.utils.fromWei(wei, "kether"));
                    setMigt(web3.utils.fromWei(wei, "mether"));
                    setGigt(web3.utils.fromWei(wei, "gether"));
                    setTigt(web3.utils.fromWei(wei, "tether"));
                } else {
                    setWei(value);
                }
            } else {
                setWei(value);
            }
        } else {
            setWei(value);
        }
    };
    const handleChangeKwei = (value) => {
        if (value !== "") {
            if (validator.test(value)) {
                let vallength = value.toString().split(".");
                if (!vallength[1] || vallength[1]?.length <= 3) {
                    const wei = web3.utils.toWei(value.toString(), "kwei");
                    setWei(web3.utils.fromWei(wei, "wei"));
                    setKwei(value);
                    setMwei(web3.utils.fromWei(wei, "mwei"));
                    setGwei(web3.utils.fromWei(wei, "gwei"));
                    setSzabo(web3.utils.fromWei(wei, "szabo"));
                    setFinney(web3.utils.fromWei(wei, "finney"));
                    setIgt(web3.utils.fromWei(wei, "ether"));
                    setKigt(web3.utils.fromWei(wei, "kether"));
                    setMigt(web3.utils.fromWei(wei, "mether"));
                    setGigt(web3.utils.fromWei(wei, "gether"));
                    setTigt(web3.utils.fromWei(wei, "tether"));
                } else {
                    setKwei(value);
                }
            } else {
                setKwei(value);
            }
        } else {
            setKwei(value);
        }
    };
    const handleChangeMwei = (value) => {
        if (value !== "") {
            if (validator.test(value)) {
                let vallength = value.toString().split(".");
                if (!vallength[1] || vallength[1]?.length <= 6) {
                    const wei = web3.utils.toWei(value.toString(), "mwei");
                    setWei(web3.utils.fromWei(wei, "wei"));
                    setKwei(web3.utils.fromWei(wei, "kwei"));
                    setMwei(value);
                    setGwei(web3.utils.fromWei(wei, "gwei"));
                    setSzabo(web3.utils.fromWei(wei, "szabo"));
                    setFinney(web3.utils.fromWei(wei, "finney"));
                    setIgt(web3.utils.fromWei(wei, "ether"));
                    setKigt(web3.utils.fromWei(wei, "kether"));
                    setMigt(web3.utils.fromWei(wei, "mether"));
                    setGigt(web3.utils.fromWei(wei, "gether"));
                    setTigt(web3.utils.fromWei(wei, "tether"));
                } else {
                    setMwei(value);
                }
            } else {
                setMwei(value);
            }
        } else {
            setMwei(value);
        }
    };
    const handleChangeGwei = (value) => {
        if (value !== "") {
            if (validator.test(value)) {
                let vallength = value.toString().split(".");
                if (!vallength[1] || vallength[1]?.length <= 9) {
                    const wei = web3.utils.toWei(value.toString(), "gwei");
                    setWei(web3.utils.fromWei(wei, "wei"));
                    setKwei(web3.utils.fromWei(wei, "kwei"));
                    setMwei(web3.utils.fromWei(wei, "mwei"));
                    setGwei(value);
                    setSzabo(web3.utils.fromWei(wei, "szabo"));
                    setFinney(web3.utils.fromWei(wei, "finney"));
                    setIgt(web3.utils.fromWei(wei, "ether"));
                    setKigt(web3.utils.fromWei(wei, "kether"));
                    setMigt(web3.utils.fromWei(wei, "mether"));
                    setGigt(web3.utils.fromWei(wei, "gether"));
                    setTigt(web3.utils.fromWei(wei, "tether"));
                } else {
                    setGwei(value);
                }
            } else {
                setGwei(value);
            }
        } else {
            setGwei(value);
        }
    };
    const handleChangeSzabo = (value) => {
        if (value !== "") {
            if (validator.test(value)) {
                let vallength = value.toString().split(".");
                if (!vallength[1] || vallength[1]?.length <= 12) {
                    const wei = web3.utils.toWei(value.toString(), "szabo");
                    setWei(web3.utils.fromWei(wei, "wei"));
                    setKwei(web3.utils.fromWei(wei, "kwei"));
                    setMwei(web3.utils.fromWei(wei, "mwei"));
                    setGwei(web3.utils.fromWei(wei, "gwei"));
                    setSzabo(value);
                    setFinney(web3.utils.fromWei(wei, "finney"));
                    setIgt(web3.utils.fromWei(wei, "ether"));
                    setKigt(web3.utils.fromWei(wei, "kether"));
                    setMigt(web3.utils.fromWei(wei, "mether"));
                    setGigt(web3.utils.fromWei(wei, "gether"));
                    setTigt(web3.utils.fromWei(wei, "tether"));
                } else {
                    setSzabo(value);
                }
            } else {
                setSzabo(value);
            }
        } else {
            setSzabo(value);
        }
    };
    const handleChangeFinney = (value) => {
        if (value !== "") {
            if (validator.test(value)) {
                let vallength = value.toString().split(".");
                if (!vallength[1] || vallength[1]?.length <= 15) {
                    const wei = web3.utils.toWei(value.toString(), "finney");
                    setWei(web3.utils.fromWei(wei, "wei"));
                    setKwei(web3.utils.fromWei(wei, "kwei"));
                    setMwei(web3.utils.fromWei(wei, "mwei"));
                    setGwei(web3.utils.fromWei(wei, "gwei"));
                    setSzabo(web3.utils.fromWei(wei, "szabo"));
                    setFinney(value);
                    setIgt(web3.utils.fromWei(wei, "ether"));
                    setKigt(web3.utils.fromWei(wei, "kether"));
                    setMigt(web3.utils.fromWei(wei, "mether"));
                    setGigt(web3.utils.fromWei(wei, "gether"));
                    setTigt(web3.utils.fromWei(wei, "tether"));
                } else {
                    setFinney(value);
                }
            } else {
                setFinney(value);
            }
        } else {
            setFinney(value);
        }
    };
    const handleChangeIgt = (value) => {
        if (value !== "") {
            if (validator.test(value)) {
                let vallength = value.toString().split(".");
                if (!vallength[1] || vallength[1]?.length <= 18) {
                    const wei = web3.utils.toWei(value.toString(), "ether");
                    setWei(web3.utils.fromWei(wei, "wei"));
                    setKwei(web3.utils.fromWei(wei, "kwei"));
                    setMwei(web3.utils.fromWei(wei, "mwei"));
                    setGwei(web3.utils.fromWei(wei, "gwei"));
                    setSzabo(web3.utils.fromWei(wei, "szabo"));
                    setFinney(web3.utils.fromWei(wei, "finney"));
                    setIgt(value);
                    setKigt(web3.utils.fromWei(wei, "kether"));
                    setMigt(web3.utils.fromWei(wei, "mether"));
                    setGigt(web3.utils.fromWei(wei, "gether"));
                    setTigt(web3.utils.fromWei(wei, "tether"));
                } else {
                    setIgt(value);
                }
            } else {
                setIgt(value);
            }
        } else {
            setIgt(value);
        }
    };
    const handleChangeKigt = (value) => {
        if (value !== "") {
            if (validator.test(value)) {
                let vallength = value.toString().split(".");
                if (!vallength[1] || vallength[1]?.length <= 21) {
                    const wei = web3.utils.toWei(value.toString(), "kether");
                    setWei(web3.utils.fromWei(wei, "wei"));
                    setKwei(web3.utils.fromWei(wei, "kwei"));
                    setMwei(web3.utils.fromWei(wei, "mwei"));
                    setGwei(web3.utils.fromWei(wei, "gwei"));
                    setSzabo(web3.utils.fromWei(wei, "szabo"));
                    setFinney(web3.utils.fromWei(wei, "finney"));
                    setIgt(web3.utils.fromWei(wei, "ether"));
                    setKigt(value);
                    setMigt(web3.utils.fromWei(wei, "mether"));
                    setGigt(web3.utils.fromWei(wei, "gether"));
                    setTigt(web3.utils.fromWei(wei, "tether"));
                } else {
                    setKigt(value);
                }
            } else {
                setKigt(value);
            }
        } else {
            setKigt(value);
        }
    };
    const handleChangeMigt = (value) => {
        if (value !== "") {
            if (validator.test(value)) {
                let vallength = value.toString().split(".");
                if (!vallength[1] || vallength[1]?.length <= 24) {
                    const wei = web3.utils.toWei(value.toString(), "mether");
                    setWei(web3.utils.fromWei(wei, "wei"));
                    setKwei(web3.utils.fromWei(wei, "kwei"));
                    setMwei(web3.utils.fromWei(wei, "mwei"));
                    setGwei(web3.utils.fromWei(wei, "gwei"));
                    setSzabo(web3.utils.fromWei(wei, "szabo"));
                    setFinney(web3.utils.fromWei(wei, "finney"));
                    setIgt(web3.utils.fromWei(wei, "ether"));
                    setKigt(web3.utils.fromWei(wei, "kether"));
                    setMigt(value);
                    setGigt(web3.utils.fromWei(wei, "gether"));
                    setTigt(web3.utils.fromWei(wei, "tether"));
                } else {
                    setMigt(value);
                }
            } else {
                setMigt(value);
            }
        } else {
            setMigt(value);
        }
    };
    const handleChangeGigt = (value) => {
        if (value !== "") {
            if (validator.test(value)) {
                let vallength = value.toString().split(".");
                if (!vallength[1] || vallength[1]?.length <= 27) {
                    const wei = web3.utils.toWei(value.toString(), "gether");
                    setWei(web3.utils.fromWei(wei, "wei"));
                    setKwei(web3.utils.fromWei(wei, "kwei"));
                    setMwei(web3.utils.fromWei(wei, "mwei"));
                    setGwei(web3.utils.fromWei(wei, "gwei"));
                    setSzabo(web3.utils.fromWei(wei, "szabo"));
                    setFinney(web3.utils.fromWei(wei, "finney"));
                    setIgt(web3.utils.fromWei(wei, "ether"));
                    setKigt(web3.utils.fromWei(wei, "kether"));
                    setMigt(web3.utils.fromWei(wei, "mether"));
                    setGigt(value);
                    setTigt(web3.utils.fromWei(wei, "tether"));
                } else {
                    setGigt(value);
                }
            } else {
                setGigt(value);
            }
        } else {
            setGigt(value);
        }
    };
    const handleChangeTigt = (value) => {
        if (value !== "") {
            if (validator.test(value)) {
                let vallength = value.toString().split(".");
                if (!vallength[1] || vallength[1]?.length <= 30) {
                    const wei = web3.utils.toWei(value.toString(), "tether");
                    setWei(web3.utils.fromWei(wei, "wei"));
                    setKwei(web3.utils.fromWei(wei, "kwei"));
                    setMwei(web3.utils.fromWei(wei, "mwei"));
                    setGwei(web3.utils.fromWei(wei, "gwei"));
                    setSzabo(web3.utils.fromWei(wei, "szabo"));
                    setFinney(web3.utils.fromWei(wei, "finney"));
                    setIgt(web3.utils.fromWei(wei, "ether"));
                    setKigt(web3.utils.fromWei(wei, "kether"));
                    setMigt(web3.utils.fromWei(wei, "mether"));
                    setGigt(web3.utils.fromWei(wei, "gether"));
                    setTigt(value);
                } else {
                    setTigt(value);
                }
            } else {
                setTigt(value);
            }
        } else {
            setTigt(value);
        }
    };

    return (
        <>
            <Header2 />
            <div>
                <title className="">Unit Converter | BscScan</title>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
                <meta name="Description" content="Unit Conversion in the Ethereum Network." />
                <meta name="author" content="BscScan.com" />
                <meta name="keywords" content="binance, explorer, BNB, search, blockchain, crypto, currency" />
                <meta name="format-detection" content="telephone=no" />
                <meta property="og:title" content="Unit Converter | BscScan" />{" "}
                <meta property="og:description" content="Unit Conversion in the Ethereum Network." /> <meta property="og:type" content="website" />{" "}
                <meta property="og:site_name" content="Binance (BNB) Blockchain Explorer" />{" "}
                <meta property="og:url" content="http://bscscan.com/unitconverter" />{" "}
                <meta property="og:image" content="https://BscScan.com/images/brandassets/BscScan-logo-circle.jpg" />{" "}
                <meta property="og:image:alt" content="Visit BscScan.com" /> <meta name="twitter:card" content="summary" />{" "}
                <meta name="twitter:title" content="Unit Converter | BscScan" />{" "}
                <meta property="twitter:description" content="Unit Conversion in the Ethereum Network." />{" "}
                <meta name="twitter:site" content="@BscScan" />{" "}
                <meta property="twitter:image" content="https://BscScan.com/images/brandassets/BscScan-logo-circle.jpg" />
                <link rel="shortcut icon" href="/images/favicon.ico" />
                <link rel="stylesheet" href="/assets/vendor/font-awesome/css/fontawesome-all.min.css?v=22.11.3.0" />
                <link rel="stylesheet" href="/assets/vendor/animate.css/animate.min.css?v=22.11.3.0" />
                <link rel="stylesheet" href="/assets/vendor/hs-megamenu/src/hs.megamenu.css?v=22.11.3.0" />
                <link rel="stylesheet" href="/assets/vendor/malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css?v=22.11.3.0" />
                <link rel="stylesheet" href="/assets/vendor/select2/dist/css/select2.min.css?v=22.11.3.0" />
                <link rel="stylesheet" href="/assets/css/theme.min.css?v=22.11.3.0" />
                <link rel="stylesheet" href="/assets/css/custom.css?v=22.11.3.0" />
                <style
                    dangerouslySetInnerHTML={{
                        __html: "\n        .ui-autocomplete {\n            position: absolute;\n            top: 0;\n            left: 0;\n            cursor: default\n        }\n        .ui-state-active {\n            border-color: #e7eaf3;\n            background: #f8fafd;\n        }\n        body.dark-mode .ui-state-active {\n            background-color: #252525;\n            border-color: #323232;\n        }\n        .autolink-safari a{\n            pointer-events: none !important;\n            text-decoration:none !important; \n            color:inherit !important;\n        }\n    ",
                    }}
                />
                <style
                    dangerouslySetInnerHTML={{
                        __html: "\n        @media (min-width: 1500px) {\n            .input-group-append {\n                width: 8%;\n            }\n        }\n        @media (max-width: 1500px) {\n            .input-group-append {\n                width: 8%;\n            }\n        }\n\n        @media (max-width: 1200px) {\n            .input-group-append {\n                width: 12%;\n            }\n        }\n\n        @media (max-width: 870px) {\n            .input-group-append {\n                width: 14%;\n            }\n        }\n\n        @media (max-width: 767.98px) {\n            .input-group-append {\n                width: 17%;\n            }\n        }\n\n        @media (max-width: 760px) {\n            .input-group-append {\n                width: 23%;\n            }\n        }\n\n       /* @media (max-width: 700px) {\n            .input-group-append {\n                width: 20%;\n            }\n        }*/\n\n        @media (max-width: 650px) {\n            .input-group-append {\n                width: 27%;\n            }\n        }\n\n        @media (max-width: 575.98px) {\n            .input-group-append {\n                width: 30%;\n            }\n        }\n\n        .input-group-append .input-group-text {\n            width: 100%;\n            overflow: hidden;\n        }\n    ",
                    }}
                />
                <main id="content" role="main">
                    <input type="hidden" name="hdnAgeText" id="hdnAgeText" defaultValue="Age" />
                    <input type="hidden" name="hdnDateTimeText" id="hdnDateTimeText" defaultValue="Date Time (UTC)" />
                    <input type="hidden" name="hdnAgeTitle" id="hdnAgeTitle" defaultValue="Click to show Age Format" />
                    <input type="hidden" name="hdnDateTimeTitle" id="hdnDateTimeTitle" defaultValue="Click to show Datetime Format" />
                    <input type="hidden" name="hdnGasPriceTitle" id="hdnGasPriceTitle" defaultValue="Gas Price in Gwei" />
                    <input type="hidden" name="hdnTxnFeeTitle" id="hdnTxnFeeTitle" defaultValue="(Gas Price * Gas Used by Txns) in BNB" />
                    <input type="hidden" name="hdnGasPriceText" id="hdnGasPriceText" defaultValue="Gas Price" />
                    <input type="hidden" name="hdnTxnText" id="hdnTxnText" defaultValue="Txn Fee" />
                    <div className="container mb-4">
                        <div className="border-bottom py-3 mb-3">
                            <div className="mb-2 mb-sm-0">
                                <h1 className="h4 d-flex mb-0">
                                    <figure className="u-sm-avatar mr-2">
                                        <img src="/asset/images/icon-10.svg" style={{ height: "30px", width: "50px" }} alt="SVG" />
                                        <span className="text-start">Unit Converter</span>
                                    </figure>
                                </h1>
                            </div>
                        </div>
                        <p className="mx-2">
                            {process.env.REACT_APP_NAME} is often used in different denominations of its currency, such as Wei for interacting with
                            smart contracts and Gwei for calculating gas prices. Use our Unit Converter to easily convert between them!
                        </p>
                    </div>
                    <div className="container space-bottom-2">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex justify-content-end mb-3">
                                    <a id="btnReset" href="javascript:;" onClick={handleReset}>
                                        [Reset]
                                    </a>
                                </div>
                                <div className="input-group mb-3 mt-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <a
                                                href="javascript:;"
                                                className="js-clipboard btn btn-xs btn-icon text-secondary"
                                                data-toggle="tooltip"
                                                data-title="Copy to clipboard"
                                                data-type="tooltip"
                                                data-success-text="Copied"
                                                data-default-class="far fa-copy"
                                                data-success-class="fa fa-check"
                                                data-content-target="#wei"
                                                data-class-change-target="#linkIcon_wei"
                                            >
                                                <span className="pt-1 far fa-copy" id="linkIcon_wei" />
                                            </a>
                                        </span>
                                    </div>

                                    <input
                                        name="wei"
                                        type="text"
                                        id="wei"
                                        className="form-control convertedValue"
                                        placeholder="Wei"
                                        aria-label="Wei"
                                        value={wei}
                                        onChange={(e) => {
                                            handleChangeWei(e.target.value);
                                        }}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            Wei (10<sup>-18</sup>)
                                        </span>
                                    </div>
                                </div>

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <a
                                                href="javascript:;"
                                                className="js-clipboard btn btn-xs btn-icon text-secondary"
                                                data-toggle="tooltip"
                                                data-title="Copy to clipboard"
                                                data-type="tooltip"
                                                data-success-text="Copied"
                                                data-default-class="far fa-copy"
                                                data-success-class="fa fa-check"
                                                data-content-target="#wei"
                                                data-class-change-target="#linkIcon_wei"
                                            >
                                                <span className="pt-1 far fa-copy" id="linkIcon_wei" />
                                            </a>
                                        </span>
                                    </div>
                                    <input
                                        name="kwei"
                                        type="text"
                                        className="form-control convertedValue"
                                        placeholder="KWei"
                                        aria-label="KWei"
                                        id="kwei"
                                        value={kwei}
                                        onChange={(e) => {
                                            handleChangeKwei(e.target.value);
                                        }}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">KWei</span>
                                    </div>
                                </div>

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <a
                                                href="javascript:;"
                                                className="js-clipboard btn btn-xs btn-icon text-secondary"
                                                data-toggle="tooltip"
                                                data-title="Copy to clipboard"
                                                data-type="tooltip"
                                                data-success-text="Copied"
                                                data-default-class="far fa-copy"
                                                data-success-class="fa fa-check"
                                                data-content-target="#wei"
                                                data-class-change-target="#linkIcon_wei"
                                            >
                                                <span className="pt-1 far fa-copy" id="linkIcon_wei" />
                                            </a>
                                        </span>
                                    </div>
                                    <input
                                        name="mwei"
                                        type="text"
                                        className="form-control convertedValue"
                                        placeholder="MWei"
                                        aria-label="MWei"
                                        id="mwei"
                                        value={mwei}
                                        onChange={(e) => {
                                            handleChangeMwei(e.target.value);
                                        }}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">MWei</span>
                                    </div>
                                </div>

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <a
                                                href="javascript:;"
                                                className="js-clipboard btn btn-xs btn-icon text-secondary"
                                                data-toggle="tooltip"
                                                data-title="Copy to clipboard"
                                                data-type="tooltip"
                                                data-success-text="Copied"
                                                data-default-class="far fa-copy"
                                                data-success-class="fa fa-check"
                                                data-content-target="#wei"
                                                data-class-change-target="#linkIcon_wei"
                                            >
                                                <span className="pt-1 far fa-copy" id="linkIcon_wei" />
                                            </a>
                                        </span>
                                    </div>
                                    <input
                                        type="text"
                                        name="gwei"
                                        className="form-control convertedValue"
                                        placeholder="GWei"
                                        aria-label="GWei"
                                        id="gwei"
                                        value={gwei}
                                        onChange={(e) => {
                                            handleChangeGwei(e.target.value);
                                        }}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">
                                            GWei (10<sup>-9</sup>)
                                        </span>
                                    </div>
                                </div>

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <a
                                                href="javascript:;"
                                                className="js-clipboard btn btn-xs btn-icon text-secondary"
                                                data-toggle="tooltip"
                                                data-title="Copy to clipboard"
                                                data-type="tooltip"
                                                data-success-text="Copied"
                                                data-default-class="far fa-copy"
                                                data-success-class="fa fa-check"
                                                data-content-target="#wei"
                                                data-class-change-target="#linkIcon_wei"
                                            >
                                                <span className="pt-1 far fa-copy" id="linkIcon_wei" />
                                            </a>
                                        </span>
                                    </div>
                                    <input
                                        type="text"
                                        name="szabo"
                                        className="form-control convertedValue"
                                        placeholder="Szabo"
                                        aria-label="Szabo"
                                        id="szabo"
                                        value={szabo}
                                        onChange={(e) => {
                                            handleChangeSzabo(e.target.value);
                                        }}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">Szabo</span>
                                    </div>
                                </div>

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <a
                                                href="javascript:;"
                                                className="js-clipboard btn btn-xs btn-icon text-secondary"
                                                data-toggle="tooltip"
                                                data-title="Copy to clipboard"
                                                data-type="tooltip"
                                                data-success-text="Copied"
                                                data-default-class="far fa-copy"
                                                data-success-class="fa fa-check"
                                                data-content-target="#wei"
                                                data-class-change-target="#linkIcon_wei"
                                            >
                                                <span className="pt-1 far fa-copy" id="linkIcon_wei" />
                                            </a>
                                        </span>
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control convertedValue"
                                        placeholder="Finney"
                                        aria-label="Finney"
                                        id="finney"
                                        value={finney}
                                        onChange={(e) => {
                                            handleChangeFinney(e.target.value);
                                        }}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">Finney</span>
                                    </div>
                                </div>

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <a
                                                href="javascript:;"
                                                className="js-clipboard btn btn-xs btn-icon text-secondary"
                                                data-toggle="tooltip"
                                                data-title="Copy to clipboard"
                                                data-type="tooltip"
                                                data-success-text="Copied"
                                                data-default-class="far fa-copy"
                                                data-success-class="fa fa-check"
                                                data-content-target="#wei"
                                                data-class-change-target="#linkIcon_wei"
                                            >
                                                <span className="pt-1 far fa-copy" id="linkIcon_wei" />
                                            </a>
                                        </span>
                                    </div>
                                    <input
                                        type="text"
                                        name="igt"
                                        className="form-control convertedValue"
                                        placeholder="IGT"
                                        aria-label="IGT"
                                        id="ether"
                                        value={igt}
                                        onChange={(e) => {
                                            handleChangeIgt(e.target.value);
                                        }}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">{process.env.REACT_APP_TICKER} (1)</span>
                                    </div>
                                </div>

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <a
                                                href="javascript:;"
                                                className="js-clipboard btn btn-xs btn-icon text-secondary"
                                                data-toggle="tooltip"
                                                data-title="Copy to clipboard"
                                                data-type="tooltip"
                                                data-success-text="Copied"
                                                data-default-class="far fa-copy"
                                                data-success-class="fa fa-check"
                                                data-content-target="#wei"
                                                data-class-change-target="#linkIcon_wei"
                                            >
                                                <span className="pt-1 far fa-copy" id="linkIcon_wei" />
                                            </a>
                                        </span>
                                    </div>
                                    <input
                                        type="text"
                                        name="kigt"
                                        className="form-control convertedValue"
                                        placeholder="KIGT"
                                        aria-label="KIGT"
                                        id="kether"
                                        value={kigt}
                                        onChange={(e) => {
                                            handleChangeKigt(e.target.value);
                                        }}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">K{process.env.REACT_APP_TICKER}</span>
                                    </div>
                                </div>

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <a
                                                href="javascript:;"
                                                className="js-clipboard btn btn-xs btn-icon text-secondary"
                                                data-toggle="tooltip"
                                                data-title="Copy to clipboard"
                                                data-type="tooltip"
                                                data-success-text="Copied"
                                                data-default-class="far fa-copy"
                                                data-success-class="fa fa-check"
                                                data-content-target="#wei"
                                                data-class-change-target="#linkIcon_wei"
                                            >
                                                <span className="pt-1 far fa-copy" id="linkIcon_wei" />
                                            </a>
                                        </span>
                                    </div>
                                    <input
                                        type="text"
                                        name="migt"
                                        className="form-control convertedValue"
                                        placeholder="MIGT"
                                        aria-label="MIGT"
                                        id="mether"
                                        value={migt}
                                        onChange={(e) => {
                                            handleChangeMigt(e.target.value);
                                        }}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">M{process.env.REACT_APP_TICKER}</span>
                                    </div>
                                </div>

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <a
                                                href="javascript:;"
                                                className="js-clipboard btn btn-xs btn-icon text-secondary"
                                                data-toggle="tooltip"
                                                data-title="Copy to clipboard"
                                                data-type="tooltip"
                                                data-success-text="Copied"
                                                data-default-class="far fa-copy"
                                                data-success-class="fa fa-check"
                                                data-content-target="#wei"
                                                data-class-change-target="#linkIcon_wei"
                                            >
                                                <span className="pt-1 far fa-copy" id="linkIcon_wei" />
                                            </a>
                                        </span>
                                    </div>
                                    <input
                                        type="text"
                                        name="gigt"
                                        className="form-control convertedValue"
                                        placeholder="GIGT"
                                        aria-label="GIGT"
                                        id="gether"
                                        value={gigt}
                                        onChange={(e) => {
                                            handleChangeGigt(e.target.value);
                                        }}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">G{process.env.REACT_APP_TICKER}</span>
                                    </div>
                                </div>

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <a
                                                href="javascript:;"
                                                className="js-clipboard btn btn-xs btn-icon text-secondary"
                                                data-toggle="tooltip"
                                                data-title="Copy to clipboard"
                                                data-type="tooltip"
                                                data-success-text="Copied"
                                                data-default-class="far fa-copy"
                                                data-success-class="fa fa-check"
                                                data-content-target="#wei"
                                                data-class-change-target="#linkIcon_wei"
                                            >
                                                <span className="pt-1 far fa-copy" id="linkIcon_wei" />
                                            </a>
                                        </span>
                                    </div>
                                    <input
                                        type="text"
                                        name="tigt"
                                        className="form-control convertedValue"
                                        placeholder="TIGT"
                                        aria-label="TIGT"
                                        id="tether"
                                        value={tigt}
                                        onChange={(e) => {
                                            handleChangeTigt(e.target.value);
                                        }}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text">T{process.env.REACT_APP_TICKER}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <div id="push" />
                <div className="modal fade" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" id="emailSubscribeModalBox" aria-hidden="true">
                    <div className="modal-dialog modal-sm" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button type="button" className="close close-md" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                                <div className="text-center py-5-alt px-4">
                                    <div id="emailSubscribeModalBoxText" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* </div> */}
                <div id="divcookie" className="fixed-bottom w-md-75 w-xl-60 mx-md-auto mx-3" style={{ display: "none" }}>
                    <div className="alert alert-light border shadow p-3" role="alert">
                        <div className="d-md-flex justify-content-center align-items-center">
                            <span>
                                <p className="text-dark mr-3 mb-2 mb-md-0">
                                    <i className="far fa-cookie-bite text-secondary mr-1" />
                                    This website{" "}
                                    <a href="/terms#cookiestatement" target="_blank">
                                        uses cookies to improve your experience
                                    </a>
                                    . By continuing to use this website, you agree to its{" "}
                                    <a href="/terms" target="_blank">
                                        Terms
                                    </a>{" "}
                                    and <a href="/privacyPolicy">Privacy Policy</a>.
                                </p>
                            </span>
                            <div>
                                <button id="btnCookie" className="btn btn-sm btn-primary text-nowrap py-1" data-dismiss="alert" aria-label="Close">
                                    Got It
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <a
                    className="js-go-to u-go-to"
                    href="#"
                    data-position='{"bottom": 20, "right": 15 }'
                    data-type="fixed"
                    data-offset-top={400}
                    data-compensation="#header"
                    data-show-effect="slideInUp"
                    data-hide-effect="slideOutDown"
                >
                    <span className="fa fa-arrow-up u-go-to__inner" />
                </a>
            </div>

            <Footer />
        </>
    );
}

export default UnitConverter;
