import React from "react";
import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Igtprice = ({ latestblock }) => {
  return (
    <div className="media align-items-center">
      <figure className="mx-4">
        <img className="homepage_icon" src="/asset/images/48.png" alt="icon" />
      </figure>
      <div className="media-body text-left">
        <h2 className="font-size-1 text-uppercase text-secondary mb-0">Rate</h2>
        <OverlayTrigger placement="bottom" trigger="hover" s overlay={<Tooltip>Rate</Tooltip>}>
          <Link
            className="text-size-1 text-link"
            to="#"
            rel="tooltip"
            data-toggle="tooltip"
            data-placement="bottom"
            title=""
          >
            {" "}
            ${process.env.REACT_APP_PRICE}
          </Link>
        </OverlayTrigger>
      </div>
    </div>
  );
};
export default Igtprice;
