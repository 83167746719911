import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { IgniteContext } from "../IgniteContext";
const Sidebar = () => {
    const { logout } = useContext(IgniteContext);
    return (
        <>
            <div className="list-group list-group-lg mb-3">
                <NavLink
                    to="/myaccount"
                    id="ContentPlaceHolder1_AdminSideNavigator_Control_myaccount"
                    className="list-group-item list-group-item-action nav-link"
                >
                    <i className="fa fa-user-circle mr-1" /> My Profile
                </NavLink>
                <NavLink
                    to="/mynotes_tx"
                    id="ContentPlaceHolder1_AdminSideNavigator_Control_mynotes_tx"
                    className="list-group-item list-group-item-action nav-link"
                >
                    <i className="far fa-sticky-note mr-1" /> Txn Private Notes
                </NavLink>
                <NavLink
                    to="/mynotes_address"
                    id="ContentPlaceHolder1_AdminSideNavigator_Control_mynotes_address"
                    className="list-group-item list-group-item-action nav-link"
                >
                    <i className="far fa-sticky-note mr-1" /> Address Private Notes
                </NavLink>
                {/* <NavLink
                    to="/MytokenList"
                    id="ContentPlaceHolder1_AdminSideNavigator_Control_mynotes_address"
                    className="list-group-item list-group-item-action nav-link"
                >
                    <i className="far fa-sticky-note mr-1" />
                    Token List
                </NavLink> */}
                <button
                    id="ContentPlaceHolder1_AdminSideNavigator_Control_mycustomabi"
                    className="list-group-item list-group-item-action nav-link text-bold"
                    onClick={logout}
                >
                    <i className="fa fa-user-circle mr-1" />
                    Sign Out
                </button>
            </div>
        </>
    );
};

export default Sidebar;
