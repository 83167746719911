import React from "react";
import { OverlayTrigger, Popover } from 'react-bootstrap';
const Nonce = ({ nonce }) => {


    const popoverTop11 = (
        <Popover id="popover-basic">
            {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
            <Popover.Body>
            Sequential running number for an address, beginning with 0 for the first transaction. For example, if the nonce of a transaction is 10, it would be the 11th transaction sent from the sender's address.
            </Popover.Body>
        </Popover>
    );


    return (
        <>
            <div className="row">
                <div className="col-md-3 mb-1 bs  h6 text-blueish">
                <OverlayTrigger trigger="hover" placement="top" overlay={popoverTop11}>
                     <i class="fal fa-question-circle text-secondary mr-1" aria-hidden="true"></i> 
                     </OverlayTrigger> Nonce
                </div>
                <div className="col-md-4 mb-1 bs text-dark bs">
                    {nonce}
                </div>
            </div>
            <hr />

        </>
    )
}

export default Nonce;