import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const TransactionLogs = ({ logsData }) => {
    const [topicDecode, setTopicDecode] = useState([]);
    const [dataDecode, setDataDecode] = useState([]);
    const [verifyedDataDecode, setVerifyedDataDecode] = useState(true);

    useEffect(() => {
        if (logsData) {
            let arr = [];
            for (let i = 0; i < logsData.length; i++) {
                if (!logsData[i]?.data[0]?.dec) {
                    for (let j = 0; j < logsData[i].data?.length; j++) {
                        arr.push({ main: i, sub: j, action: "hex" });
                    }
                }
            }
            setDataDecode([...arr]);
        }
    }, [logsData]);

    const hansleTopicDecoding = (mainind, subind, action) => {
        if (action === 1) {
            setTopicDecode([...topicDecode, { main: mainind, sub: subind }]);
        } else if (action === 0) {
            let arr = [...topicDecode];
            let ind = arr.findIndex((item) => item.main === mainind && item.sub === subind);
            arr.splice(ind, 1);
            setTopicDecode([...arr]);
        }
    };

    const hansleDataTDecoding = (mainind, subind, action) => {
        let arr = [...dataDecode];
        let ind = dataDecode.findIndex((item) => item.main === mainind && item.sub === subind);
        arr[ind].action = action;
        setDataDecode([...arr]);
    };

    return (
        <div className="card-body">
            <h6 className="font-weight-normal mb-4"> Transaction Receipt Event Logs </h6>
            {logsData &&
                logsData?.map((logsdata, mainind) => {
                    return (
                        <>
                            <div className="media">
                                <div className="mt-1 mr-3">
                                    <span className="btn btn-icon btn-soft-success rounded-circle">
                                        <span className="btn-icon__inner">{logsdata.logIndex}</span>
                                    </span>
                                </div>
                                <div className="media-body">
                                    <dl className="row align-items-baseline mb-3">
                                        <dt className="col-md-2 col-lg-1 text-md-right">
                                            <h6 className="font-weight-bold mb-0">Address</h6>
                                        </dt>
                                        <dd className="col-md-10 mb-0">
                                            <Link className="text-break mr-2" to={`/address/${logsdata.address}`}>
                                                {logsdata.address}
                                            </Link>
                                            {/* <span className="dropdown">
                                                <button
                                                    className="btn btn-xss btn-custom dropdown-toggle"
                                                    type="button"
                                                    id="matchDropdown-0"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <i className="fa fa-search-plus" />
                                                </button>
                                                <div className="dropdown-menu" aria-labelledby="matchDropdown-0">
                                                    <a className="dropdown-item" href="#">
                                                        Matches Topic[0]
                                                    </a>
                                                </div>
                                            </span> */}
                                        </dd>
                                    </dl>
                                    {logsdata.input ? (
                                        <dl className="row align-items-start mb-3">
                                            <dt className="col-md-2 col-lg-1 text-md-right">
                                                <h6 className="mb-0">Name</h6>
                                            </dt>
                                            <dd className="col-md-10 mb-0">
                                                <p className="text-secondary text-monospace mb-0">
                                                    <span
                                                        id="funcname_1"
                                                        style={{ display: "inline-block", marginBottom: "6px" }}
                                                        data-title="ABI Event Input"
                                                    >
                                                        {logsdata.input.name} (
                                                        {logsdata.input &&
                                                            logsdata?.input?.inputs?.map((item, subind) => {
                                                                return (
                                                                    <>
                                                                        {item.indexed ? `index_topic_${subind + 1} ` : ""}
                                                                        <span className="text-success">{item.type}</span>
                                                                        <span className="text-danger">{` ${item.name}`}</span>
                                                                        {subind <= logsdata?.input?.inputs?.length - 2 ? ", " : ""}
                                                                    </>
                                                                );
                                                            })}
                                                        )
                                                        <Link className="ml-1" to={`/address/${logsdata.address}#code`}>
                                                            View Source
                                                        </Link>{" "}
                                                    </span>
                                                </p>{" "}
                                            </dd>
                                        </dl>
                                    ) : (
                                        ""
                                    )}
                                    <dl className="row align-items-baseline mb-3">
                                        <dt className="col-md-2 col-lg-1 text-md-right">
                                            <h6 className="mb-0">Topics</h6>
                                        </dt>
                                        <dd className="col-md-10 mb-0">
                                            <ul className="list-unstyled list-xs-space mb-0">
                                                <li>
                                                    <span className="u-label u-label--xs u-label--secondary rounded mr-1">0</span>&nbsp;
                                                    <span className="text-monospace text-break">{logsdata.topic}</span>
                                                </li>
                                                {logsdata?.topics?.length
                                                    ? logsdata?.topics?.map((item, subind) => {
                                                          return (
                                                              <li>
                                                                  <span className="u-label u-label--xs u-label--secondary rounded mr-1">
                                                                      {subind + 1}
                                                                  </span>{" "}
                                                                  <i className="fa fa-long-arrow-alt-right text-secondary mx-1" />
                                                                  <span className="text-monospace text-break" id="chunk_decode_0_2">
                                                                      {item}
                                                                  </span>
                                                              </li>
                                                          );
                                                      })
                                                    : ""}
                                                {logsdata?.decodedtopics?.length
                                                    ? logsdata?.decodedtopics?.map((item, subind) => {
                                                          let hexstatus = topicDecode.some((item) => item.main === mainind && item.sub === subind);
                                                          return (
                                                              <li>
                                                                  <span className="dropdown">
                                                                      <button
                                                                          className="btn btn-xss btn-custom dropdown-toggle"
                                                                          type="button"
                                                                          id="convert_button_1_1"
                                                                          data-toggle="dropdown"
                                                                          aria-haspopup="True"
                                                                          aria-expanded="False"
                                                                      >
                                                                          {hexstatus ? "Dec" : "Hex"}
                                                                      </button>
                                                                      <div className="dropdown-menu" aria-labelledby="convert_button_1_1">
                                                                          <button
                                                                              className={`dropdown-item ${hexstatus ? "active" : ""}`}
                                                                              onClick={() => {
                                                                                  hansleTopicDecoding(mainind, subind, 1);
                                                                              }}
                                                                          >
                                                                              Decode
                                                                          </button>
                                                                          <button
                                                                              className={`dropdown-item ${hexstatus ? "" : "active"}`}
                                                                              onClick={() => {
                                                                                  hansleTopicDecoding(mainind, subind, 0);
                                                                              }}
                                                                          >
                                                                              Hex
                                                                          </button>
                                                                      </div>
                                                                      <span>
                                                                          <i className="fa fa-long-arrow-alt-right mx-1" />
                                                                          <span className="text-monospace text-break">
                                                                              {hexstatus ? (
                                                                                  item.type === "address" ? (
                                                                                      <Link to={`/address/${item.dec}`}>{item.dec}</Link>
                                                                                  ) : (
                                                                                      item.dec
                                                                                  )
                                                                              ) : (
                                                                                  item.hex
                                                                              )}
                                                                          </span>
                                                                      </span>
                                                                  </span>
                                                              </li>
                                                          );
                                                      })
                                                    : ""}
                                            </ul>
                                        </dd>
                                    </dl>
                                    {logsdata?.data[0]?.dec ? (
                                        <dl className="row align-items-baseline mb-0">
                                            <dt className="col-md-2 col-lg-1 text-md-right">
                                                <h6 className="font-italic mb-0">Data</h6>
                                            </dt>
                                            <dd className="col-md-10 mb-0">
                                                <ul className="list-unstyled list-xs-space bg-light rounded p-4 mb-2 d-md-flex justify-content-between ">
                                                    {verifyedDataDecode ? (
                                                        <div id="event_dec_data_2">
                                                            {logsdata?.data[0]?.dec?.length
                                                                ? logsdata?.data[0]?.dec?.map((item, subind) => {
                                                                      return (
                                                                          <li className="d-lg-flex">
                                                                              <span className="text-secondary rounded mr-1 text-truncate">
                                                                                  {item?.name ? item.name : ""} :
                                                                              </span>
                                                                              <span
                                                                                  className="text-monospace text-break d-block d-sm-inline-block"
                                                                                  data-toggle="tooltip"
                                                                                  title
                                                                                  data-original-title="positionIndex (uint256 )"
                                                                              >
                                                                                  {item?.value ? item.value : ""}
                                                                              </span>
                                                                          </li>
                                                                      );
                                                                  })
                                                                : ""}
                                                        </div>
                                                    ) : (
                                                        <span id="event_raw_data_2" className="text-monospace text-break">
                                                            {logsdata?.data[0].hex}
                                                        </span>
                                                    )}
                                                    <div className="d-flex align-items-start mt-3 mt-md-0 ml-md-3">
                                                        {" "}
                                                        <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                                            {" "}
                                                            <label className="btn btn-xs btn-secondary cursor-pointer active">
                                                                {" "}
                                                                <input
                                                                    type="radio"
                                                                    name="options"
                                                                    onClick={() => setVerifyedDataDecode(true)}
                                                                /> Dec{" "}
                                                            </label>{" "}
                                                            <label className="btn btn-xs btn-secondary cursor-pointer">
                                                                {" "}
                                                                <input type="radio" name="options" onClick={() => setVerifyedDataDecode(false)} /> Hex
                                                            </label>{" "}
                                                        </div>{" "}
                                                    </div>
                                                </ul>{" "}
                                            </dd>
                                        </dl>
                                    ) : (
                                        <dl className="row align-items-baseline mb-0">
                                            <dt className="col-md-2 col-lg-1 text-md-right">
                                                <h6 className="font-italic mb-0">Data</h6>
                                            </dt>
                                            <dd className="col-md-10 mb-0">
                                                <ul className="list-unstyled list-xs-space bg-light rounded p-4 mb-0">
                                                    {logsdata?.data?.length
                                                        ? logsdata?.data?.map((item, subind) => {
                                                              let decdata = dataDecode.find(
                                                                  (itemsb) => itemsb.main === mainind && itemsb.sub === subind
                                                              );
                                                              return (
                                                                  <li>
                                                                      <span className="dropdown">
                                                                          <button
                                                                              className="btn btn-xss btn-custom dropdown-toggle"
                                                                              type="button"
                                                                              id="convert_button_3_1"
                                                                              data-toggle="dropdown"
                                                                              aria-haspopup="True"
                                                                              aria-expanded="false"
                                                                          >
                                                                              {decdata?.action === "hex"
                                                                                  ? "Hex"
                                                                                  : decdata?.action === "num"
                                                                                  ? "Num"
                                                                                  : decdata?.action === "text"
                                                                                  ? "Text"
                                                                                  : decdata?.action === "addr"
                                                                                  ? "Addr"
                                                                                  : ""}
                                                                          </button>
                                                                          <div className="dropdown-menu" aria-labelledby="convert_button_3_1">
                                                                              <button
                                                                                  className={`dropdown-item ${
                                                                                      decdata?.action === "hex" ? "active" : ""
                                                                                  }`}
                                                                                  onClick={() => hansleDataTDecoding(mainind, subind, "hex")}
                                                                              >
                                                                                  Hex
                                                                              </button>
                                                                              <button
                                                                                  className={`dropdown-item ${
                                                                                      decdata?.action === "num" ? "active" : ""
                                                                                  }`}
                                                                                  onClick={() => hansleDataTDecoding(mainind, subind, "num")}
                                                                              >
                                                                                  Number
                                                                              </button>
                                                                              <button
                                                                                  className={`dropdown-item ${
                                                                                      decdata?.action === "text" ? "active" : ""
                                                                                  }`}
                                                                                  onClick={() => hansleDataTDecoding(mainind, subind, "text")}
                                                                              >
                                                                                  Text
                                                                              </button>
                                                                              <button
                                                                                  className={`dropdown-item ${
                                                                                      decdata?.action === "addr" ? "active" : ""
                                                                                  }`}
                                                                                  onClick={() => hansleDataTDecoding(mainind, subind, "addr")}
                                                                              >
                                                                                  Address
                                                                              </button>
                                                                          </div>
                                                                          <span>
                                                                              <i className="fa fa-long-arrow-alt-right mx-1" />
                                                                              <span className="text-monospace text-break" id="chunk_3_1">
                                                                                  {decdata?.action === "hex" ? (
                                                                                      item.hex
                                                                                  ) : decdata?.action === "num" ? (
                                                                                      item.number
                                                                                  ) : decdata?.action === "text" ? (
                                                                                      item.text
                                                                                  ) : decdata?.action === "addr" ? (
                                                                                      <Link to={`/address/${item.address}`}>{item.address}</Link>
                                                                                  ) : (
                                                                                      ""
                                                                                  )}
                                                                              </span>
                                                                          </span>
                                                                      </span>
                                                                  </li>
                                                              );
                                                          })
                                                        : ""}
                                                </ul>
                                            </dd>
                                        </dl>
                                    )}
                                </div>
                            </div>
                            <hr className="my-4" />
                        </>
                    );
                })}
            {/* <hr className="my-4" /> */}
        </div>
    );
};

export default TransactionLogs;
