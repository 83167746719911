import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Spinner from "../common/Spinner";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { IgniteContext } from "../IgniteContext";
import axios from "axios";
import NoEntryMessage from "../common/NoEntryMessage";

export default function ValidatedBlocks({ id }) {
    const { numberWithCommas, formatDate } = useContext(IgniteContext);
    const navigate = useNavigate();

    const [spinner, setSpinner] = useState(true);
    const [validateBlocks, setValidatedBlocks] = useState([]);
    const [total, setTotal] = useState(0);
    const [intAgeColChange, setintAgeColChange] = useState(true);

    const getValidatedBlocks = async () => {
        setSpinner(true);
        await axios
            .get(`${process.env.REACT_APP_BACKEND_URL}minedBlocks/${id}`, {
                params: {
                    limit: 25,
                    page: 1,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getValidatedBlocks : ", res.data.error);
                } else {
                    setValidatedBlocks(res.data.items);
                    setTotal(res.data.total);
                    setSpinner(false);
                }
            })
            .catch((err) => {
                console.log("error in getValidatedBlocks : ", err);
            });
    };

    useEffect(() => {
        getValidatedBlocks();
    }, [id]);

    return (
        <>
            {spinner ? (
                <Spinner />
            ) : (
                <>
                    <div class="d-md-flex justify-content-between align-items-center mb-4">
                        <p class="mb-2 mb-lg-0">
                            <i class="fas fa-sort-amount-down text-secondary" data-toggle="tooltip" title="" data-original-title="Oldest First"></i>
                            &nbsp;Latest 25 blocks (From a total of{" "}
                            <Link to={`/blocks?m=${id}`} data-toggle="tooltip" title="" data-original-title="View all blocks validated">
                                {numberWithCommas(total)} blocks{" "}
                            </Link>{" "}
                            )
                        </p>
                        <nav className="mb-0 mb-md-0" aria-label="page navigation">
                            <Link
                                id="ContentPlaceHolder1_linkShowAllBlocksMined"
                                title
                                className="btn btn-xss btn-primary"
                                data-toggle="tooltip"
                                to={`/blocks?m=${id}`}
                            >
                                View All
                            </Link>
                        </nav>
                    </div>
                    <div className="table-responsive mb-2 mb-md-0">
                        <table className="table table-hover">
                            <thead className="thead-light">
                                <tr className=" text-black">
                                    <th scope="col">Block</th>

                                    <th scope="col">
                                        {intAgeColChange ? (
                                            <OverlayTrigger
                                                placement="top"
                                                trigger="hover"
                                                overlay={<Tooltip>Click to show Datetime Format</Tooltip>}
                                            >
                                                <a
                                                    role="button"
                                                    className="text-primary"
                                                    onClick={() => setintAgeColChange(!intAgeColChange)}
                                                    id="lnkAgeDateTime"
                                                >
                                                    Age
                                                </a>
                                            </OverlayTrigger>
                                        ) : (
                                            <OverlayTrigger placement="top" trigger="hover" overlay={<Tooltip>Click to show Age Format</Tooltip>}>
                                                <a role="button" className="text-primary" onClick={() => setintAgeColChange(!intAgeColChange)}>
                                                    Date Time (UTC)
                                                </a>
                                            </OverlayTrigger>
                                        )}
                                    </th>

                                    <th scope="col" width={250}>
                                        Transaction
                                    </th>
                                    <th scope="col" width={250}>
                                        Gas Used
                                    </th>
                                    <th scope="col">Rewards</th>
                                </tr>
                            </thead>
                            <tbody>
                                {validateBlocks && validateBlocks?.length ? (
                                    validateBlocks?.map((item, index) => {
                                        const gasUsedPer = (Number(item.gasUsed) * 100) / Number(item.gasLimit);
                                        return (
                                            <tr>
                                                <td>{item.blockNumber}</td>
                                                <td className="">
                                                    {intAgeColChange ? (
                                                        <span rel="tooltip" data-toggle="tooltip" data-placement="bottom">
                                                            {item.age}
                                                        </span>
                                                    ) : (
                                                        <span rel="tooltip" data-toggle="tooltip" data-placement="bottom">
                                                            {formatDate(item.timestemp)}
                                                        </span>
                                                    )}
                                                </td>
                                                <td className="showAge">{item.transactions}</td>

                                                {/* <td className="">
                                                        9,843,923 (32.81%)
                                                        <div className="progress mt-1" style={{ height: "2px" }}>
                                                            <div
                                                                className="progress-bar bg-primary"
                                                                role="progressbar"
                                                                style={{ width: "45.80%" }}
                                                                aria-valuenow="45.80"
                                                                aria-valuemin={0}
                                                                aria-valuemax={100}
                                                            />
                                                        </div>
                                                    </td> */}
                                                <td width="0px">
                                                    {numberWithCommas(item.gasUsed)}{" "}
                                                    <span className="small text-secondary">({Number(gasUsedPer).toFixed(2)} %)</span>
                                                    <div className="progress mt-1" style={{ height: "2px" }}>
                                                        <div
                                                            className="progress-bar bg-primary"
                                                            role="progressbar"
                                                            style={{ width: `${gasUsedPer}%` }}
                                                            aria-valuenow={item.gasused}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        />
                                                    </div>
                                                </td>
                                                <td width="0px">
                                                    {item.reward && item.Number > 0 ? item.reward : 0} {process.env.REACT_APP_TICKER}
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <NoEntryMessage message={process.env.REACT_APP_BLANK_DATA_MESSAGE} />
                                )}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </>
    );
}
