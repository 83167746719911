import React, {useContext} from "react";
import Web3 from "web3";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {IgniteContext} from "../IgniteContext";
import {setLocalStorage} from "../../utils";

const Footer = () => {
    const {theme, setTheme} = useContext(IgniteContext);
    var date = new Date();
    var year = date.getFullYear();

    const setLightTheme = () => {
        setTheme("normal");
        setLocalStorage("stormode", "normal");
    };
    const setDarkTheme = () => {
        setTheme("dark");
        setLocalStorage("stormode", "dark");
    };

    const addNetwork = async () => {
        if (typeof window.ethereum === "undefined") {
            alert("MetaMask is not installed!");
        } else {
            let chainId = Number(process.env.REACT_APP_CHAINID);
            const params = [
                {
                    chainId: Web3.utils.toHex(chainId),
                    chainName: "StorChain",
                    nativeCurrency: {
                        name: "StorChain",
                        symbol: "STR",
                        decimals: 18,
                    },
                    rpcUrls: [process.env.REACT_APP_HTTP_RPC],
                    blockExplorerUrls: [process.env.REACT_APP_EXPLORER],
                },
            ];
            window.ethereum
                .request({method: "wallet_addEthereumChain", params})
                .then(() => {
                })
                .catch((error) => {
                    console.log(error)
                });
        }
    };

    return (
        <>
            <footer id="masterFooter" className="bg-footer-light mt-auto d-print-none">
                <div className="container">
                    <div className="py-4">
                        <div className="d-flex flex-column flex-md-row justify-content-between">
                            <div className="row">
                                <div className="col-md-6 col-12 mb-2 d-flex p-0 d-flex justify-content-center">
                                    <OverlayTrigger placement="top" trigger="hover"
                                                    overlay={<Tooltip>Twitter</Tooltip>}>
                                        <a
                                            className="social-media-icon m-1"
                                            style={{width: "2rem", height: "2rem"}}
                                            href="https://x.com/Storchain_io"
                                            rel="nofollow noopener"
                                            target="_blank"
                                            data-bs-toggle="tooltip"
                                            data-bs-trigger="hover"
                                            data-bs-placement="top"
                                            aria-label="Twitter"
                                        >
                                            <i className="fab fa-twitter"/>
                                        </a>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top" trigger="hover" overlay={<Tooltip>Medium</Tooltip>}>
                                        <a
                                            className="social-media-icon m-1"
                                            style={{width: "2rem", height: "2rem"}}
                                            href="https://storchain.medium.com/"
                                            rel="nofollow noopener"
                                            target="_blank"
                                            data-bs-toggle="tooltip"
                                            data-bs-trigger="hover"
                                            data-bs-placement="top"
                                            aria-label="Twitter"
                                        >
                                            <i className="fab fa-medium"/>
                                        </a>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top" trigger="hover"
                                                    overlay={<Tooltip>Facebook</Tooltip>}>
                                        <a
                                            className="social-media-icon m-1"
                                            style={{width: "2rem", height: "2rem"}}
                                            href="https://www.facebook.com/Storchainofficial/"
                                            rel="nofollow noopener"
                                            target="_blank"
                                            data-bs-toggle="tooltip"
                                            data-bs-trigger="hover"
                                            data-bs-placement="top"
                                            aria-label="Twitter"
                                        >
                                            <i className="fab fa-facebook"/>
                                        </a>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top" trigger="hover"
                                                    overlay={<Tooltip>Instagram</Tooltip>}>
                                        <a
                                            className="social-media-icon m-1"
                                            style={{width: "2rem", height: "2rem"}}
                                            href="https://www.instagram.com/storchainofficial/"
                                            rel="nofollow noopener"
                                            target="_blank"
                                            data-bs-toggle="tooltip"
                                            data-bs-trigger="hover"
                                            data-bs-placement="top"
                                            aria-label="Twitter"
                                        >
                                            <i className="fab fa-instagram"/>
                                        </a>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top" trigger="hover"
                                                    overlay={<Tooltip>Youtube</Tooltip>}>
                                        <a
                                            className="social-media-icon m-1"
                                            style={{width: "2rem", height: "2rem"}}
                                            href="https://www.youtube.com/@Storchain"
                                            rel="nofollow noopener"
                                            target="_blank"
                                            data-bs-toggle="tooltip"
                                            data-bs-trigger="hover"
                                            data-bs-placement="top"
                                            aria-label="Twitter"
                                        >
                                            <i className="fab fa-youtube"/>
                                        </a>
                                    </OverlayTrigger>
                                </div>
                                <div className="col-md-6 col-12 mb-2 d-flex p-0 d-flex justify-content-center">
                                    <OverlayTrigger placement="top" trigger="hover"
                                                    overlay={<Tooltip>Telegram</Tooltip>}>
                                        <a
                                            className="social-media-icon m-1"
                                            style={{width: "2rem", height: "2rem"}}
                                            href="https://t.me/storchain"
                                            rel="nofollow noopener"
                                            target="_blank"
                                            data-bs-toggle="tooltip"
                                            data-bs-trigger="hover"
                                            data-bs-placement="top"
                                            aria-label="Twitter"
                                        >
                                            <i className="fab fa-telegram"/>
                                        </a>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top" trigger="hover"
                                                    overlay={<Tooltip>Pinterest</Tooltip>}>
                                        <a
                                            className="social-media-icon m-1"
                                            style={{width: "2rem", height: "2rem"}}
                                            href="https://www.pinterest.com/storchain/"
                                            rel="nofollow noopener"
                                            target="_blank"
                                            data-bs-toggle="tooltip"
                                            data-bs-trigger="hover"
                                            data-bs-placement="top"
                                            aria-label="Twitter"
                                        >
                                            <i class="fab fa-pinterest-p" aria-hidden="true"></i>
                                        </a>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top" trigger="hover"
                                                    overlay={<Tooltip>Blogspot</Tooltip>}>
                                        <a
                                            className="social-media-icon m-1"
                                            style={{width: "2rem", height: "2rem"}}
                                            href="https://storchain.blogspot.com/"
                                            rel="nofollow noopener"
                                            target="_blank"
                                            data-bs-toggle="tooltip"
                                            data-bs-trigger="hover"
                                            data-bs-placement="top"
                                            aria-label="Twitter"
                                        >
                                            {theme === "dark" ? (
                                                <img src="/asset/images/blogger-dark.png"
                                                     style={{height: "100%", width: "100%"}}/>
                                            ) : (
                                                <img
                                                    src="/asset/images/blogger-light.png"
                                                    style={{height: "100%", width: "100%"}}
                                                />
                                            )}
                                        </a>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top" trigger="hover" overlay={<Tooltip>Tumblr</Tooltip>}>
                                        <a
                                            className="social-media-icon m-1"
                                            style={{width: "2rem", height: "2rem"}}
                                            href="https://storchain.tumblr.com/"
                                            rel="nofollow noopener"
                                            target="_blank"
                                            data-bs-toggle="tooltip"
                                            data-bs-trigger="hover"
                                            data-bs-placement="top"
                                            aria-label="Twitter"
                                        >
                                            <i class="fab fa-tumblr" aria-hidden="true"></i>
                                        </a>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top" trigger="hover" overlay={<Tooltip>Quora</Tooltip>}>
                                        <a
                                            className="social-media-icon m-1"
                                            style={{width: "2rem", height: "2rem"}}
                                            href="https://storchain.quora.com/"
                                            rel="nofollow noopener"
                                            target="_blank"
                                            data-bs-toggle="tooltip"
                                            data-bs-trigger="hover"
                                            data-bs-placement="top"
                                            aria-label="Twitter"
                                        >
                                            <i class="fab fa-quora" aria-hidden="true"></i>
                                        </a>
                                    </OverlayTrigger>
                                </div>
                            </div>

                            <a className="link-dark go-to-top-btn m-1 text-center" href="#" style={{color: "#000000"}}>
                                {theme === "normal" ? (
                                    <img className="mx-1" src="/asset/images/gototop.svg" alt="go to top image"/>
                                ) : (
                                    <img className="mx-1" src="/asset/images/gototop-white.svg" alt="go to top image"/>
                                )}
                                Back to Top
                            </a>
                        </div>
                    </div>
                    <hr className="my-0"/>
                    <div className="row justify-content-md-between py-5">
                        <div className="col-lg-4 pe-xl-16 mb-4 mb-lg-0">
                            <div className="d-flex align-items-center mb-3">
                                <img
                                    className="mr-2"
                                    width={20}
                                    data-img-theme="light"
                                    src="/asset/images/48.png"
                                    alt="Ethereum Logo"
                                />
                                <span style={{fontSize: "1.18rem"}}>Powered by STORCHAIN</span>
                            </div>
                            <p className="fs-sm">
                                STRScan is a Block Explorer and Analytics Platform for STR, a decentralized smart
                                contracts
                                platform.
                            </p>
                            <div className="d-none d-lg-block mt-n4 mb-n6">
                                {theme === "normal" ? (
                                    <img
                                        className="opacity-50"
                                        width={280}
                                        data-img-theme="light"
                                        src="/asset/images/map.webp"
                                        alt="Background Map Image"
                                    />
                                ) : (
                                    <img
                                        className="opacity-50"
                                        width={280}
                                        data-img-theme="light"
                                        src="/asset/images/map-light.webp"
                                        alt="Background Map Image"
                                    />
                                )}
                            </div>
                        </div>
                        {/* <div className="col-6 col-md-4 col-lg mb-10 mb-md-0">
              <h4 className="h6 mb-3">
                <b>Company</b>
              </h4>
              <ul className="list-unstyled list-sm-space fs-sm mb-0">
                <li>
                  <Link className="link-dark" to="/aboutus">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link className="link-dark" to="/brandassets">
                    Brand Assets
                  </Link>
                </li>
                <li>
                  <Link className="link-dark" to="/contactus">
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link className="link-dark" to="/careers">
                    <span className="me-1">Careers</span>{" "}
                    <span className="bg-primary text-white small fw-medium text-nowrap rounded-pill p-1 px-2">
                      We're Hiring!
                    </span>
                  </Link>
                </li>
                <li>
                  <Link className="link-dark" to="/terms">
                    Terms of Service
                  </Link>
                </li>
                <li>
                  <Link className="link-dark" to="/bugbounty">
                    Bug Bounty
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-6 col-md-4 col-lg mb-10 mb-md-0">
              <h4 className="h6 mb-3">
                <b>Community</b>
              </h4>
              <ul className="list-unstyled list-sm-space fs-sm mb-0">
                <li>
                  <Link className="link-dark" to="/newsletters">
                    Newsletters
                  </Link>
                </li>
              </ul>
            </div> */}
                        <div className="col-6 col-md-4 col-lg">
                            {/* <h4 className="h6 mb-3">
                                <b>Products &amp; Services</b>
                            </h4>
                            <ul className="list-unstyled list-sm-space fs-sm mb-0">
                                <li>
                                    <a className="link-dark" href="/contactusadvertise">
                                        Advertise
                                    </a>
                                </li>
                                <li>
                                    <a className="link-dark" href="/eaas">
                                        Explorer-as-a-Service (EaaS)
                                    </a>
                                </li>
                                <li>
                                    <a className="link-dark" href="/apis">
                                        API Plans
                                    </a>
                                </li>
                                <li>
                                    <a className="link-dark" href="/priority-support">
                                        Priority Support
                                    </a>
                                </li>
                                <li>
                                    <a className="link-dark" href="https://blockscan.com" target="_blank">
                                        Blockscan <i className="far fa-arrow-up-right-from-square text-muted ms-1" />
                                    </a>
                                </li>
                                <li>
                                    <a className="link-dark" href="https://chat.blockscan.com/start" target="_blank">
                                        Blockscan Chat <i className="far fa-arrow-up-right-from-square text-muted ms-1" />
                                    </a>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                    <div className="border-top py-3 px-1">
                        <div className="row justify-content-between align-items-center fs-sm">
                            <div className="col-6 mb-2 mb-md-0">
                                <p className="mb-0">
                                    <b></b>
                                    storscan © {year}
                                </p>
                            </div>
                            <div className="col-6 text-md-end" style={{textAlign: "end"}}>
                                <div className="d-flex justify-content-end align-items-center">
                                    <div className="d-flex align-items-center mr-1">
                    <span className="d-inline-block">
                      <button
                          type="button"
                          name="add-ig-network"
                          className="btn btn-xss btn-soft-secondary text-nowrap d-flex align-items-center mr-1"
                          onClick={addNetwork}
                      >
                        <img
                            className="mr-1"
                            width="15"
                            src="/asset/images/metamask_first.svg"
                            alt="Metamask"
                        />{" "}
                          Add STR Network
                      </button>
                    </span>
                                    </div>
                                    {theme === "normal" ? (
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-icon mr-2 btn-soft-secondary"
                                            onClick={() => {
                                                setDarkTheme();
                                            }}
                                        >
                                            <i id="darkModaBtnIcon" className="fa fa-moon"/>
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-icon btn-soft-light mr-2 btn-soft-secondary"
                                            onClick={() => {
                                                setLightTheme();
                                            }}
                                        >
                                            <i id="darkModaBtnIcon" className="fa fa-sun"/>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;
