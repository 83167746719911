import React, { useContext } from "react";
import { Spinner } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useSearchParams } from "react-router-dom";
import FadingLoaderCard1 from "../common/ContentLoaders/LoaderBigLine";
import { IgniteContext } from "../IgniteContext";

function Overview({ tokenDetails, transferOnId, tokenSpinner }) {
    const [searchParams] = useSearchParams();
    const paramsid = searchParams.get("a");
    const { numberWithCommas, isAddress } = useContext(IgniteContext);
    const ot = <Tooltip>Calculated by multiplying the token's Total Supply on Ethereum with the current market price per token.</Tooltip>;
    return (
        <>
            <div className="col-md-6 mb-3 mb-md-0">
                <div className="card h-100">
                    <div className="card-header d-flex align-items-center">
                        <h2 className="card-header-title">Overview</h2>&nbsp;
                        {(tokenDetails?.type === "erc721" || tokenDetails?.type === "erc1155") && !paramsid ? (
                            <span
                                class="u-label u-label--xs u-label--secondary rounded mr-1"
                                data-toggle="tooltip"
                                data-original-title="SRC1155 or Compatible token type"
                            >
                                {tokenDetails?.type === "erc20" ? (
                                    <b>SRC20</b>
                                ) : tokenDetails?.type === "erc721" ? (
                                    <b>SRC721</b>
                                ) : tokenDetails?.type === "erc1155" ? (
                                    <b>SRC1155</b>
                                ) : (
                                    ""
                                )}
                            </span>
                        ) : (
                            ""
                        )}
                        <div />
                    </div>
                    {paramsid && !isAddress(paramsid) ? (
                        <div className="card-body">
                            <div className="row align-items-center">
                                <div className="col-md-4 mb-1 mb-md-0">TokenID:</div>
                                <div className="col-md-8 font-weight-medium">
                                    {tokenSpinner ? (
                                        <>
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                        </>
                                    ) : (
                                        <>
                                            <span>
                                                <b>{paramsid}</b>
                                            </span>
                                            <span>
                                                <i
                                                    className="fal fa-info-circle text-secondary cursor-pointer ml-1"
                                                    data-toggle="tooltip"
                                                    data-placement="auto"
                                                    data-title="Submit burn details to us if you are project owner"
                                                    onclick="goToContactUs();"
                                                    data-original-title
                                                    title
                                                />
                                            </span>
                                        </>
                                    )}
                                </div>
                            </div>
                            <div id="ContentPlaceHolder1_trNoOfTxns">
                                <hr className="hr-space" />
                                <div className="row align-items-center">
                                    <div className="col-md-4 mb-1 mb-md-0">Transfers:</div>
                                    <div className="col-md-8">
                                        {tokenSpinner ? (
                                            <>
                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                            </>
                                        ) : (
                                            <>
                                                <span id="totaltxns">{numberWithCommas(transferOnId)}</span>
                                            </>
                                        )}
                                    </div>
                                    <hr className="hr-space" />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="card-body">
                            {tokenDetails?.type ? (
                                <>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="small text-uppercase text-muted">
                                                <span>Price</span>
                                            </div>
                                            {tokenSpinner ? (
                                                <>
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />{" "}
                                                </>
                                            ) : (
                                                <>
                                                    <span class="d-block">
                                                        <span
                                                            data-toggle="tooltip"
                                                            data-html="true"
                                                            data-title="$0.0814565"
                                                            data-original-title
                                                            style={{ fontSize: "14px" }}
                                                        >
                                                            $0.000
                                                        </span>
                                                        <span class="small text-secondary text-nowrap">@ 0 {process.env.REACT_APP_TICKER}</span>
                                                        <span class="small text-danger">(-0.00%)</span>
                                                    </span>
                                                </>
                                            )}
                                        </div>
                                        <div class="col-md-6 u-ver-divider u-ver-divider--left">
                                            <div class="small text-uppercase text-muted">
                                                <span>Fully Diluted Market Cap</span>
                                                <OverlayTrigger placement="top" trigger="hover" overlay={ot}>
                                                    <i class="far fa-question-circle text-muted mx-1"></i>
                                                </OverlayTrigger>
                                            </div>
                                            {tokenSpinner ? (
                                                <>
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />{" "}
                                                </>
                                            ) : (
                                                <>
                                                    <div class="d-block small text-muted">
                                                        <span class="u-label u-label--sm u-label--value u-label--text-dark u-label--secondary rounded">
                                                            $0.00
                                                        </span>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <hr className="hr-space" />
                                </>
                            ) : (
                                ""
                            )}
                            <div className="row align-items-center">
                                <div className="col-md-4 mb-1 mb-md-0">Total Supply:</div>
                                <div className="col-md-8">
                                    {tokenSpinner ? (
                                        <>
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                        </>
                                    ) : (
                                        <>
                                            {Number(tokenDetails?.totalSupplyNumber) > 0
                                                ? numberWithCommas(Number(tokenDetails?.totalSupplyNumber).toFixed(5).toString())
                                                : 0}
                                            <strong class="mx-1">{tokenDetails?.symbol}</strong>
                                        </>
                                    )}
                                </div>
                            </div>
                            <hr className="hr-space" />
                            <div className="row align-items-center">
                                <div className="col-md-4 mb-1 mb-md-0">Holders:</div>
                                <div className="col-md-8">
                                    {tokenSpinner ? (
                                        <>
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                        </>
                                    ) : (
                                        <>{tokenDetails?.holderCount ? numberWithCommas(tokenDetails?.holderCount) : 0}</>
                                    )}
                                </div>
                            </div>
                            {paramsid && !isAddress(paramsid) ? (
                                <>
                                    <hr className="hr-space" />
                                    <div className="row align-items-center">
                                        <div className="col-md-4 mb-1 mb-md-0">Transfers:</div>
                                        <div className="col-md-8">
                                            {tokenSpinner ? (
                                                <>
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                </>
                                            ) : (
                                                <>{tokenDetails?.transferCount ? numberWithCommas(tokenDetails?.transferCount) : 0}</>
                                            )}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                ""
                            )}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default Overview;
