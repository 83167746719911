import React from 'react'
import { Link } from 'react-router-dom'


function Notice() {
    return (
        <>


            {/* <div className="card"> */}
          
          
                        <span id="ContentPlaceHolder1_lblErrorStatus" />
                        <div id="ContentPlaceHolder1_maindisplay">
                            <div className="alert alert-dark alert-dismissible fade show font-size-1" style={{ backgroundColor: '#E9ECF1' }}>
                                <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                                <ul className="list-unstyled list-xs-space alert-text mb-0 mt-2">
                                    <li>1. If the contract compiles correctly at <a href="https://remix.ethereum.org/" target="_blank" rel="noopener noreferrer">REMIX</a>, it should also compile
                                        correctly here.</li>
                                    <li>2. We have limited support for verifying contracts created by
                                        another contract and there is a timeout of up to 45 seconds for each
                                        contract compiled. </li>
                                    <li>3. For programatic contract verification, check out the <Link to="#" target="_blank">Contract API Endpoint</Link></li>
                                </ul>
                            </div>

                        </div>
                    {/* </div>

                </div>
            </div> */}
            {/* </div> */}





            {/*  */}


        </>
    )
}

export default Notice