import React from "react";

const Copyicon = () => {
    return (
        <i className="far fa-copy mx-2" style={{ fontSize: "12.25px" }} />
    )
}

export default Copyicon;

