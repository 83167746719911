import React, { useState, useEffect, useContext, useRef } from "react";
// import { CSVLink } from "react-csv";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";
import Spinner from "../common/Spinner";
import Header2 from "../common/Header2";
import Pagesize from "../common/Pagesize";
import Footer from "../common/Footer";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import { IgniteContext } from "../IgniteContext";
import Web3 from "web3";
import FadingLoaderCard1 from "../common/ContentLoaders/LoaderBigLine";

function AddressTransactions() {
    const { formatAddress, formatHash } = useContext(IgniteContext);

    const location = useLocation();
    const navigate = useNavigate();

    const mainTgRef = useRef(null);

    const [searchParams] = useSearchParams();
    const add = searchParams.get("a");
    const filtype = searchParams.get("f");
    const pagefromurl = searchParams.get("p");
    const cpage = pagefromurl ? Number(pagefromurl) : 1;

    if (Number(filtype) !== 2 && Number(filtype) !== 3) {
        navigate("/error");
    }

    const [toggleFileterMenu, setToggleFilterMenu] = useState(false);

    const [currentPage, setCurrentPage] = useState(cpage);
    const [addressTransactions, setAddressTransaction] = useState([]);
    const [spinner, setSpinner] = useState(true);
    const [length, setLength] = useState("");
    const [totalAddTxs, setTotalAddTxs] = useState(0);
    const [PageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState("");

    useEffect(() => {
        if (toggleFileterMenu) {
            function handleClickOutside(event) {
                if (mainTgRef.current && !mainTgRef.current.contains(event.target)) {
                    setToggleFilterMenu(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }
    }, [toggleFileterMenu]);

    const getAddressTransaction = async () => {
        setSpinner(true);
        setToggleFilterMenu(false);
        let params = {
            limit: PageSize,
            page: currentPage,
        };
        if (Number(filtype) === 2) {
            params = { ...params, tx_type: "out" };
        } else if (Number(filtype) === 3) {
            params = { ...params, tx_type: "in" };
        }

        await axios
            .get(`${process.env.REACT_APP_BACKEND_URL}txs/${add}`, {
                params: params,
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in address transaction : ", res.data.error);
                } else {
                    setAddressTransaction(res.data.items);
                    setTotalAddTxs(res.data.total);
                    setPages(res.data.pages);
                    setSpinner(false);
                }
            })
            .catch((err) => {
                console.log("error in address transaction (catch) : ", err);
            });
    };

    useEffect(() => {
        getAddressTransaction();
    }, [add, filtype, currentPage]);

    const onNext = (page) => {
        if (page === pages) return false;
        setCurrentPage(currentPage + 1);
        navigate({
            pathname: "/txs",
            search: `?a=${add}&f=${filtype}&p=` + (currentPage + 1),
        });
    };

    const onPrevious = (page) => {
        if (page === 1) return false;
        setCurrentPage(currentPage - 1);
        navigate({
            pathname: "/txs",
            search: `?a=${add}&f=${filtype}&p=` + (currentPage - 1),
        });
    };

    return (
        <>
            <Header2 />
            <main id="content" role="main">
                <input type="hidden" name="hdnAgeText" id="hdnAgeText" defaultValue="Age" />
                <input type="hidden" name="hdnDateTimeText" id="hdnDateTimeText" defaultValue="Date Time (UTC)" />
                <input type="hidden" name="hdnAgeTitle" id="hdnAgeTitle" defaultValue="Click to show Age Format" />
                <input type="hidden" name="hdnDateTimeTitle" id="hdnDateTimeTitle" defaultValue="Click to show Datetime Format" />
                <input type="hidden" name="hdnGasPriceTitle" id="hdnGasPriceTitle" defaultValue="Gas Price in Gwei" />
                <input type="hidden" name="hdnTxnFeeTitle" id="hdnTxnFeeTitle" defaultValue="(Gas Price * Gas Used by Txns) in Matic" />
                <input type="hidden" name="hdnGasPriceText" id="hdnGasPriceText" defaultValue="Gas Price" />
                <input type="hidden" name="hdnTxnText" id="hdnTxnText" defaultValue="Txn Fee" />
                <div className="container">
                    <div className="d-md-flex justify-content-between align-items-center py-3">
                        <div className="mb-1 mb-md-0">
                            <h1 className="h4 mb-0">
                                Transactions&nbsp;
                                <span className="small text-secondary" />
                                <p className="mb-0 text-break small">
                                    <span id="ContentPlaceHolder1_divPageTitle" className="small text-secondary">
                                        For{" "}
                                        <Link href={`/address/${add}`}>
                                            <span className="text-primary text-break">{add}</span>
                                        </Link>
                                    </span>
                                </p>
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="container space-bottom-2">
                    <span id="ContentPlaceHolder1_lblAdResult" />
                    <div id="ContentPlaceHolder1_mainrow" className="card">
                        <div className="card-body">
                            <div className="d-flex flex-wrap flex-xl-nowrap text-nowrap align-items-center ml-auto"></div>
                            <div id="ContentPlaceHolder1_topPageDiv" className="d-md-flex justify-content-between mb-4">
                                <p className="mb-2 mb-md-0">
                                    <span className="d-flex align-items-center">
                                        <i id="spinwheel" className="fa fa-spin fa-spinner fa-1x fa-pulse mr-1" style={{ display: "none" }} />
                                        <span>Total {totalAddTxs}</span>&nbsp;&nbsp;
                                        {Number(filtype) === 2 ? (
                                            <span class="u-label u-label--xs u-label--warning color-strong text-uppercase text-center rounded text-nowrap">
                                                OUT
                                            </span>
                                        ) : Number(filtype) === 3 ? (
                                            <span className="u-label u-label--xs u-label--success color-strong text-uppercase text-center mx-2 rounded">
                                                IN
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                        &nbsp;&nbsp;<span>transactions found</span>
                                    </span>
                                    {totalAddTxs > 100000 ? <span className="d-block small">(Showing the last 100k records)</span> : ""}
                                </p>
                                <nav aria-label="page navigation">
                                    <ul className="pagination pagination-sm mb-0">
                                        <div class="position-relative ml-1">
                                            <button
                                                id="ddlTxFilter"
                                                class={
                                                    toggleFileterMenu
                                                        ? "btn btn-sm btn-icon btn-soft-secondary active"
                                                        : "btn btn-sm btn-icon btn-soft-secondary"
                                                }
                                                role="button"
                                                aria-controls="ddlTxFilterTools"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                data-unfold-duration="300"
                                                data-unfold-delay="300"
                                                data-toggle="tooltip"
                                                title=""
                                                onClick={() => {
                                                    setToggleFilterMenu(!toggleFileterMenu);
                                                }}
                                            >
                                                <i class="fa fa-ellipsis-v btn-icon__inner"></i>
                                            </button>
                                            <div
                                                id="ddlTxFilterTools"
                                                class={
                                                    toggleFileterMenu
                                                        ? "dropdown-menu dropdown-unfold dropdown-menu-sm-right u-unfold--css-animation slideInUp"
                                                        : "dropdown-menu dropdown-unfold dropdown-menu-sm-right u-unfold--css-animation u-unfold--hidden"
                                                }
                                                // class="dropdown-menu dropdown-unfold dropdown-menu-sm-right u-unfold--css-animation slideInUp"
                                                style={{
                                                    width: "200px",
                                                    animationDuration: "300ms",
                                                    right: "0px",
                                                }}
                                                ref={mainTgRef}
                                                aria-labelledby="ddlTxFilter"
                                            >
                                                {/* <a
                                                                        class="dropdown-item"
                                                                        href="/txs?a=0xf6c4be238d59d4a8a5a5b91a553926f763ee4d2f"
                                                                    >
                                                                        <i class="fa fa-circle mr-1"></i> View Completed
                                                                        Txns
                                                                    </a>
                                                                    <a
                                                                        class="dropdown-item"
                                                                        href="/txsPending?a=0xf6c4be238d59d4a8a5a5b91a553926f763ee4d2f&amp;m=hf"
                                                                    >
                                                                        <i class="far fa-circle mr-1"></i> View Pending
                                                                        Txns
                                                                    </a>
                                                                    <a
                                                                        class="dropdown-item"
                                                                        href="#"
                                                                        data-toggle="tooltip"
                                                                        title=""
                                                                        data-original-title="Option Not Available for this Address"
                                                                    >
                                                                        <font color="silver">
                                                                            <i class="fa fa-exclamation-circle mr-1"></i>{" "}
                                                                            View Failed Txns
                                                                        </font>
                                                                    </a>
                                                                    <hr class="my-1" /> */}
                                                <Link
                                                    class={Number(filtype) === 2 ? "dropdown-item active" : "dropdown-item"}
                                                    to={`/txs?a=${add}&f=2`}
                                                >
                                                    <i class="fa fa-long-arrow-alt-right mr-1"></i> View Outgoing Txns
                                                    {Number(filtype) === 2 && <i class="fa fa-check small ml-1"></i>}
                                                </Link>
                                                <Link
                                                    class={Number(filtype) === 3 ? "dropdown-item active" : "dropdown-item"}
                                                    to={`/txs?a=${add}&f=3`}
                                                >
                                                    <i class="fas fa-long-arrow-alt-left mr-1"></i> View Incoming Txns
                                                    {Number(filtype) === 3 && <i class="fa fa-check small ml-1"></i>}
                                                </Link>
                                                {/* <a
                                                                        class="dropdown-item"
                                                                        href="/txs?a=0xf6c4be238d59d4a8a5a5b91a553926f763ee4d2f&amp;f=5"
                                                                    >
                                                                        <i class="fas fa-newspaper text-secondary mr-1"></i>{" "}
                                                                        View Contract Creation
                                                                    </a> */}
                                            </div>
                                        </div>
                                    </ul>
                                </nav>
                            </div>
                            <div className="table-responsive mb-2 mb-md-0" id="paywall_mask">
                                <table className="table table-hover">
                                    <thead className="thead-light">
                                        <tr>
                                            {/* <th scope="col" width={20} /> */}
                                            <th scope="col" width={50}>
                                                ID
                                            </th>
                                            <th scope="col" width={1}>
                                                Txn Hash
                                            </th>
                                            {/* <th scope="col" width={1}>
                                                <div className="d-flex align-items-center">
                                                    <div className="mr-2">
                                                        Method{" "}
                                                        <i
                                                            className="fal fa-info-circle"
                                                            data-toggle="tooltip"
                                                            data-html="true"
                                                            data-boundary="viewport"
                                                            title="Function executed based on decoded input data. For unidentified functions, method ID is displayed instead."
                                                        />
                                                    </div>
                                                </div>
                                            </th> */}
                                            <th scope="col" width={1} className="d-none d-sm-table-cell">
                                                <div className="d-flex align-items-center">
                                                    <div className="mr-2">Block</div>
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className="d-flex align-items-center">
                                                    <div className="mr-2">Age</div>
                                                </div>
                                            </th>
                                            <th scope="col">
                                                <div className="d-flex align-items-center">
                                                    <div className="mr-2">From</div>
                                                </div>
                                            </th>
                                            <th scope="col" width={30} />
                                            <th scope="col">
                                                <div className="d-flex align-items-center">
                                                    <div className="mr-2">To</div>
                                                </div>
                                            </th>
                                            <th scope="col">Value</th>
                                            <th scope="col">
                                                <div className="d-flex align-items-center">
                                                    <div className="mr-2">Txn Fee</div>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {spinner ? (
                                            <>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td> </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td> </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td> </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td> </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td> </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td> </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td> </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td> </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td> </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td> </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                    </td>
                                                </tr>
                                            </>
                                        ) : (
                                            <>
                                                {addressTransactions && addressTransactions.length ? (
                                                    addressTransactions.map((item, index) => {
                                                        const value = Web3.utils.fromWei(
                                                            item.value.toLocaleString("fullwide", { useGrouping: false })
                                                        );
                                                        const ind = currentPage * 10 - 10 + (index + 1);
                                                        return (
                                                            <tr key={index}>
                                                                <td>{ind}</td>
                                                                <td>
                                                                    <span className="hash-tag">
                                                                        <Link to={`/tx/${item.hash}`}>{formatHash(item.hash)}</Link>
                                                                    </span>
                                                                </td>
                                                                <td className="d-none d-sm-table-cell ">
                                                                    {item.blockNumber ? (
                                                                        <Link to={`/block/${item.blockNumber}`}>{item.blockNumber}</Link>
                                                                    ) : (
                                                                        <i class="text-secondary">(pending)</i>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {item.blockNumber ? (
                                                                        <span class="text-black">{item.age}</span>
                                                                    ) : (
                                                                        <i class="text-secondary">{item.age}</i>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        trigger="hover"
                                                                        overlay={<Tooltip>{item.from}</Tooltip>}
                                                                    >
                                                                        <span className="hash-tag">
                                                                            {add === item.from ? (
                                                                                <span>
                                                                                    {item.blockNumber ? (
                                                                                        <span class="text-black">{formatAddress(item.from)}</span>
                                                                                    ) : (
                                                                                        <i class="text-secondary">{formatAddress(item.form)}</i>
                                                                                    )}
                                                                                </span>
                                                                            ) : (
                                                                                <Link to={`/address/${item.from}`}>
                                                                                    {item.blockNumber ? (
                                                                                        <span class="text-black">{formatAddress(item.from)}</span>
                                                                                    ) : (
                                                                                        <i class="text-secondary">{formatAddress(item.from)}</i>
                                                                                    )}
                                                                                </Link>
                                                                            )}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </td>
                                                                <td>
                                                                    {add === item.from ? (
                                                                        item.from === item.to ? (
                                                                            <span class="u-label u-label--xs u-label--secondary color-strong text-uppercase text-center w-100 rounded text-nowrap">
                                                                                SELF
                                                                            </span>
                                                                        ) : (
                                                                            <span className="u-label u-label--xs u-label--warning color-strong text-uppercase text-center w-100 rounded text-nowrap">
                                                                                OUT
                                                                            </span>
                                                                        )
                                                                    ) : (
                                                                        <span className="u-label u-label--xs u-label--success color-strong text-uppercase text-center w-100 rounded text-nowrap">
                                                                            &nbsp;IN&nbsp;
                                                                        </span>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    <span
                                                                        style={{
                                                                            whiteSpace: "nowrap",
                                                                        }}
                                                                    >
                                                                        {/* {(item.fieldcontractaddress || item.isContract) && <i className="far fa-file-alt text-secondary ml-1" />} */}
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            width="100px"
                                                                            trigger="hover"
                                                                            overlay={<Tooltip>{item.to}</Tooltip>}
                                                                        >
                                                                            <span className="mx-1 hash-tag" data-html="true">
                                                                                {/* {item.fieldto == addressDetails.address ? <span>{item.fieldblocknumber == null ? <i class="text-secondary">{to}</i> : <span class="text-black">{to}</span>}</span> : <Link to={item.fieldto ? `/address/${item.fieldto}` : item.fieldcontractaddress ? `/address/${item.fieldcontractaddress}` : "#"}>{item.fieldblocknumber == null ? <i class="">{to}</i> : to}</Link>} */}
                                                                                {item.to === add ? (
                                                                                    <span>
                                                                                        {item.blockNumber ? (
                                                                                            <span class="text-black">{formatAddress(item.to)}</span>
                                                                                        ) : (
                                                                                            <i class="text-secondary">{formatAddress(item.to)}</i>
                                                                                        )}
                                                                                    </span>
                                                                                ) : (
                                                                                    <Link to={`/address/${item.to}`}>
                                                                                        <span>
                                                                                            {item.blockNumber ? (
                                                                                                <span class="text-black">
                                                                                                    {formatAddress(item.to)}
                                                                                                </span>
                                                                                            ) : (
                                                                                                <i class="text-secondary">{formatAddress(item.to)}</i>
                                                                                            )}
                                                                                        </span>
                                                                                    </Link>
                                                                                )}
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span className="text-black">
                                                                        <span>
                                                                            {item.blockNumber ? (
                                                                                <span class="text-black">
                                                                                    {(Number(value) !== 0 ? Number(value).toFixed(5) : 0) +
                                                                                        " " +
                                                                                        process.env.REACT_APP_TICKER}
                                                                                </span>
                                                                            ) : (
                                                                                <i class="text-secondary">
                                                                                    {(Number(value) !== 0 ? Number(value).toFixed(5) : 0) +
                                                                                        " " +
                                                                                        process.env.REACT_APP_TICKER}
                                                                                </i>
                                                                            )}
                                                                        </span>
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <span>
                                                                        {item.blockNumber ? (
                                                                            <span class="small text-secondary">{Number(item.fee).toFixed(5)}</span>
                                                                        ) : (
                                                                            <i class="text-secondary">{Number(item.fee).toFixed(5)}</i>
                                                                        )}
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <tr>
                                                        <td colspan="15">
                                                            <div class="alert alert-warning mb-0" role="alert">
                                                                There are no matching entries
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            {addressTransactions && addressTransactions.length ? (
                                <>
                                    <div id="ContentPlaceHolder1_pageRecords">
                                        <div class="row w-100 mt-3">
                                            <div class="col-md-6 mt-3">
                                                <Pagesize setPageSize={setPageSize} PageSize={PageSize} />
                                            </div>
                                            <div class="col-md-6 mt-3">
                                                <div className="float-right mr-3">
                                                    <div class="row">
                                                        <button
                                                            class={currentPage === 1 ? "page-link disabled" : "page-link"}
                                                            onClick={() => {
                                                                setCurrentPage(1);
                                                                navigate({
                                                                    pathname: "/txs",
                                                                    search: `?a=${add}&f=${filtype}&p=` + 1,
                                                                });
                                                            }}
                                                            disabled={currentPage === 1}
                                                        >
                                                            First
                                                        </button>
                                                        <button
                                                            class={currentPage === 1 ? "page-link disabled" : "page-link"}
                                                            onClick={() => onPrevious(currentPage)}
                                                            disabled={currentPage === 1}
                                                        >
                                                            <i className="fa fa-angle-left" />
                                                        </button>
                                                        <div class="page-link-badge" disabled>
                                                            {" "}
                                                            {`${currentPage} of ${pages}`}
                                                        </div>
                                                        <button
                                                            class={currentPage === pages ? "page-link disabled" : "page-link"}
                                                            onClick={() => onNext(currentPage)}
                                                            disabled={currentPage === pages}
                                                        >
                                                            <i className="fa fa-angle-right" />
                                                        </button>
                                                        <button
                                                            class={currentPage === pages ? "page-link disabled" : "page-link"}
                                                            onClick={() => {
                                                                setCurrentPage(pages);
                                                                navigate({
                                                                    pathname: "/txs",
                                                                    search: `?a=${add}&f=${filtype}&p=` + pages,
                                                                });
                                                            }}
                                                            disabled={currentPage === pages}
                                                        >
                                                            Last
                                                        </button>
                                                    </div>
                                                </div>
                                                {/* <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPage={totalLength} /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-items-center mt-3 mb-4">
                                        <div className="d-flex align-items-center text-secondary" />
                                        <div className="small text-muted">
                                            <span
                                                className="text-sm-right"
                                                style={{ marginTop: "20px" }}
                                                data-toggle="tooltip"
                                                title="Export records in CSV format"
                                            >
                                                [ Download{" "}
                                                <a href="/exportData?type=address&a=0xa47c861de3272690a51f57e0e43551e55f6c790d" target="_blank">
                                                    <b>CSV Export</b>
                                                </a>
                                                &nbsp;
                                                <span className="fas fa-download text-secondary" /> ]&nbsp;
                                            </span>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
}

export default AddressTransactions;
