import React, { useState } from "react";
import Header2 from "../common/Header2";
import Footer from "../common/Footer";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

const Newsletters = () => {
    const [reCaptchaToken, setReCaptchaToken] = useState(false);
    const [reCaptchaTokenErr, setReCaptchaTokenErr] = useState("");

    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        await axios
            .post(`${process.env.REACT_APP_BACKEND_URL}user/insertmail`, {
                email: email,
            })
            .then((res) => {
                setError(res.data.error);
                if (res.data.error) {
                    setError(res.data.error);
                    setSuccess("");
                    setEmail("");
                } else {
                    setSuccess(res.data.message);
                    setEmail("");
                }
            })
            .catch((err) => {
                console.log("error in userLogin (cathc) : ", err);
                setError(err.message);
                setSuccess("");
                setEmail("");
            });
    };

    return (
        <>
            <Header2 />
            <main id="content" role="main">
                <div className="space-1">
                    <div className="container">
                        <div id="contactSection" className="space-1 space-bottom-2">
                            <div className="w-lg-75 w-xl-50 mx-auto my-5">
                                <div className="text-center">
                                    <h2 className="h3">Newsletters</h2>
                                    {/* <p className="lead">Please use this form to get in touch for priority support with our team.</p> */}
                                </div>
                            </div>
                            <div className="w-lg-75 w-xl-50 mx-auto">
                                {error ? (
                                    <div className="alert alert-danger" role="alert">
                                        {error}
                                    </div>
                                ) : success ? (
                                    <div className="alert alert-success" role="alert">
                                        {success}
                                    </div>
                                ) : (
                                    ""
                                )}
                                <form onSubmit={handleSubmit}>
                                    <div className="js-form-message mb-5">
                                        <label>
                                            Email Address <span className="text-danger">*</span>{" "}
                                            <span className="font-size-1 text-secondary">(required)</span>
                                        </label>
                                        <input
                                            name="ctl00$ContentPlaceHolder1$txtName"
                                            type="email"
                                            maxLength={150}
                                            className="form-control"
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="text-center">
                                        <input type="submit" className="btn btn-primary btn-wide transition-3d-hover mb-4 px-5" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </>
    );
};

export default Newsletters;
