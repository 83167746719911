import React from "react";
import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Totalblocks = ({ totalBlocks }) => {
  return (
    <div className="media align-items-center">
      <figure className="mx-4">
        <img src="asset/images/blocks.png" alt="icon" className="homepage_icon" />
      </figure>
      <div className="media-body text-left">
        <h2 className="font-size-1 text-uppercase text-secondary mb-0">Total Blocks</h2>
        <OverlayTrigger
          placement="bottom"
          trigger="hover"
          overlay={<Tooltip>Total Blocks of {process.env.REACT_APP_TICKER}</Tooltip>}
        >
          <Link
            className="text-size-1 text-link"
            to="blocks"
            rel="tooltip"
            data-toggle="tooltip"
            data-placement="bottom"
            title=""
          >
            {totalBlocks}
          </Link>
        </OverlayTrigger>
        &nbsp;<span className="text-secondary small">(2spb)</span>
      </div>
    </div>
  );
};

export default Totalblocks;
