import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import delay from "delay";
import AceEditor from "react-ace";
import ReadFromContract from "./ReadFromContract";
import WriteInContract from "./WriteInContract";

const ContractDetailsForAddress = ({ contractDeta, hash, id }) => {
    const [copySourceCode, setCopySourceCode] = useState(false);
    const [copyAbi, setCopyAbi] = useState(false);
    const [copyBytecode, setCopyByteCode] = useState(false);

    const copyContent = async (type) => {
        if (type === "sourceCode") {
            setCopySourceCode(true);
            await delay(1000);
            setCopySourceCode(false);
        } else if (type === "abi") {
            setCopyAbi(true);
            await delay(1000);
            setCopyAbi(false);
        } else if (type === "bytecode") {
            setCopyByteCode(true);
            await delay(1000);
            setCopyByteCode(false);
        } else {
            return false;
        }
    };
    return contractDeta?.abiCode === null && contractDeta?.sourceCode === null ? (
        <>
            <span class="mb-3">
                <span class="h5 mb-0">
                    {" "}
                    <i class="fa fa-info-circle text-primary"></i>{" "}
                </span>{" "}
                Are you the contract creator?
                <Link to={{ pathname: "/verifyContract", search: `?a=${contractDeta?.address}` }}>
                    &nbsp;<b className="text-primary">Verify and Publish</b>
                </Link>
                &nbsp; your contract source code today!
                <br />
            </span>

            <textarea
                readOnly
                spellCheck="false"
                className="form-control bg-light text-black text-monospace mt-3 p-1"
                rows={12}
                id="extraData"
                defaultValue={contractDeta?.code}
            />
        </>
    ) : (
        <>
            <div className="row">
                <div className="col-md-12">
                    <ul className="nav nav-pills light">
                        <ul className="nav nav-pills mb-3 light">
                            <Link
                                to={`/address/${contractDeta?.address}#code`}
                                className={`nav-link mx-1 ${hash === "#code" ? "active" : ""}`}
                                active
                            >
                                <li className=" nav-item">Code</li>
                            </Link>

                            <Link
                                to={`/address/${contractDeta?.address}#readContract`}
                                className={`nav-link mx-1 ${hash === "#readContract" ? "active" : ""}`}
                            >
                                <li className="nav-item">Read</li>
                            </Link>

                            <Link
                                to={`/address/${contractDeta?.address}#writeContract`}
                                className={`nav-link mx-1 ${hash === "#writeContract" ? "active" : ""}`}
                                type="button"
                            >
                                <li className="nav-item">Write</li>
                            </Link>
                        </ul>
                    </ul>
                    <div className="tab-content">
                        {
                            hash === "#code" ? (
                                <div id="code" className="tab-pane active">
                                    <div className="card">
                                        <div class="card-body">
                                            <div className="p-2 mb-1 bs">
                                                <span>
                                                    <i className="fa fa-check-circle badge-text"></i> Source Code Verified
                                                </span>
                                            </div>
                                            <div class="row mt-3">
                                                <div className="col-md-6">
                                                    <div class="row">
                                                        <div className="col-md-6 mb-1 h6 text-blueish">Contract Name:</div>
                                                        <div className="col-md-6 h6 font-weight-bold mb-0">{contractDeta?.contractName}</div>
                                                    </div>
                                                    <hr class="hr-space" />
                                                    {contractDeta?.optimization ? (
                                                        <div class="row">
                                                            <div className="col-md-6 mb-1 h6 text-blueish">Optimization:</div>
                                                            <div className="col-md-6 mb-1 bs h6 mb-0">
                                                                {contractDeta.optimization ? (
                                                                    <>
                                                                        <b>Yes</b> with <b>{contractDeta.optimization}</b> runs
                                                                    </>
                                                                ) : (
                                                                    "No"
                                                                )}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>

                                                <div className="col-md-6">
                                                    {/* <div className="col-md-6"> */}
                                                    <div class="row">
                                                        <div className="col-md-6 mb-1 h6 text-blueish">Compiler version:</div>
                                                        <div className="col-md-6 mb-1 bs h6 font-weight-bold mb-0">{contractDeta?.compiler} </div>
                                                    </div>
                                                    {/* </div> */}
                                                    <hr class="hr-space" />
                                                    <div className="row"></div>
                                                    {/* <div class="col-md-6"></div> */}
                                                </div>
                                            </div>

                                            <div class="mt-5 mb-3 d-flex justify-content-between">
                                                <span class="font-weight-bold h6">
                                                    {" "}
                                                    <i className="far fa-file-code text-secondary mr-1"></i> Contract Source Code{" "}
                                                </span>
                                                <CopyToClipboard text={contractDeta?.sourceCode} onCopy={() => copyContent("sourceCode")}>
                                                    {/* eslint-disable-next-line */}
                                                    <a
                                                        class="js-clipboard btn btn-sm btn-icon btn-secondary mr-1 text-light pt-2"
                                                        data-toggle="tooltip"
                                                        title
                                                        data-original-title="Copy source code to clipboard"
                                                    >
                                                        {copySourceCode ? <i className="fa fa-check" /> : <i className="fal fa-copy" />}{" "}
                                                    </a>
                                                </CopyToClipboard>
                                            </div>

                                            <div className="row pl-3 ">
                                                <div className="editor">
                                                    <AceEditor
                                                        placeholder="Placeholder Text"
                                                        mode="javascript"
                                                        name="blah2"
                                                        showPrintMargin={false}
                                                        width={1275}
                                                        height={255}
                                                        showGutter={true}
                                                        highlightActiveLine={true}
                                                        value={contractDeta?.sourceCode}
                                                        minLines={10}
                                                        setOptions={{
                                                            enableBasicAutocompletion: false,
                                                            enableLiveAutocompletion: false,
                                                            enableSnippets: false,
                                                            showLineNumbers: true,
                                                            readOnly: true,
                                                            tabSize: 2,
                                                        }}
                                                        style={{ backgroundColor: "#f9f9f9", fontSize: "87.5%" }}
                                                    />
                                                </div>
                                            </div>

                                            <div class="mt-5 mb-3 d-flex justify-content-between">
                                                <span class="font-weight-bold h6">
                                                    <i className="fa fa-tasks text-secondary mr-1"></i> Contract ABI
                                                </span>
                                                <CopyToClipboard text={contractDeta.abiCode} onCopy={() => copyContent("abi")}>
                                                    {/* eslint-disable-next-line */}
                                                    <a
                                                        href="javascript:void(0);"
                                                        class="js-clipboard btn btn-sm btn-icon btn-secondary mr-1 text-light pt-2"
                                                        data-toggle="tooltip"
                                                        title
                                                        data-original-title="Copy source code to clipboard"
                                                    >
                                                        {copyAbi ? <i className="fa fa-check" /> : <i className="fal fa-copy" />}
                                                    </a>
                                                </CopyToClipboard>
                                            </div>

                                            <div className="row pl-3 ">
                                                <textarea
                                                    readOnly
                                                    spellCheck="false"
                                                    className="form-control bg-light text-black text-monospace p-3 text-black small"
                                                    rows={6}
                                                    id="extraData"
                                                    defaultValue={contractDeta?.abiCode}
                                                />
                                            </div>
                                            <div class="mt-5 mb-3 d-flex justify-content-between">
                                                <span class="font-weight-bold h6">
                                                    <i className="fa fa-code text-secondary mr-1"></i> Contract Creation Code
                                                </span>
                                                <CopyToClipboard text={contractDeta.bytecode} onCopy={() => copyContent("bytecode")}>
                                                    <a
                                                        class="js-clipboard btn btn-sm btn-icon btn-secondary mr-1 text-light pt-2"
                                                        data-toggle="tooltip"
                                                        title
                                                        data-original-title="Copy source code to clipboard"
                                                    >
                                                        {copyBytecode ? <i className="fa fa-check" /> : <i className="fal fa-copy" />}
                                                    </a>
                                                </CopyToClipboard>
                                            </div>

                                            <div className="row pl-3 ">
                                                <textarea
                                                    readOnly
                                                    spellCheck="false"
                                                    className="form-control bg-light text-black text-monospace p-3 text-black small"
                                                    rows={10}
                                                    id="extraData"
                                                    defaultValue={contractDeta.bytecode}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : hash === "#readContract" ? (
                                <ReadFromContract id={id} />
                            ) : hash === "#writeContract" ? (
                                <WriteInContract id={id} contractDeta={contractDeta} />
                            ) : (
                                ""
                            )
                            // hash === "#read" ? (
                            //     dispRead ? (
                            //         <Spinner />
                            //     ) : (
                            //         <div className="tab-pane active">
                            //             <div id="accordion">
                            //                 <div {...{ className: "" }}>
                            //                     {readContractFields.length &&
                            //                         readContractFields.map((data, mainkey) => {
                            //                             // console.log("data", data);
                            //                             return (
                            //                                 <ul {...{ className: "card shadow-none mb-3 pl-0" }} style={{ listStyle: "none" }}>
                            //                                     <li
                            //                                         {...{
                            //                                             className: "accordion-list__item",
                            //                                             mainkey,
                            //                                         }}
                            //                                     >
                            //                                         <AccordionItem
                            //                                             readHandleSubmit={readHandleSubmit}
                            //                                             readHandleChange={readHandleChange}
                            //                                             mainkey={mainkey}
                            //                                             manageReadData={manageReadData}
                            //                                             {...data}
                            //                                         />
                            //                                     </li>
                            //                                 </ul>
                            //                             );
                            //                         })}
                            //                 </div>
                            //             </div>
                            //         </div>
                            //         // <div className="tab-pane active">
                            //         //     <div id="accordion">
                            //         //         {readContractFields.length &&
                            //         //             readContractFields.map((item, Mainkey) => {
                            //         //                 var indexKey = Mainkey;
                            //         //                 var value = item.value;
                            //         //                 return (
                            //         //                     <div class="card shadow-none mb-3" id="accordion" style={{ border: "1px solid rgba(0,0,0,.125)" }}>
                            //         //                         <div class="card-header bg-light card-collapse p-0">
                            //         //                             <a
                            //         //                                 class="collapsed card-link btn btn-link btn-block text-dark d-flex justify-content-between align-items-center py-2"
                            //         //                                 data-toggle="collapse"
                            //         //                                 // href={`#collapseOne${item.name} `}
                            //         //                                 data-target={`#collapseOne${item.name}`}
                            //         //                                 type="button"
                            //         //                                 onClick={() => {
                            //         //                                     generateFieldInput(item.input.length);
                            //         //                                     handleIcon(indexKey);
                            //         //                                 }}
                            //         //                             >
                            //         //                                 {item.name}
                            //         //                                 <i
                            //         //                                     class={`${formIcon.length && formIcon[indexKey] && formIcon[indexKey].icon ? "fa fa-arrow-down" : "fa fa-arrow-right"
                            //         //                                         } small`}
                            //         //                                     id={indexKey}
                            //         //                                 ></i>
                            //         //                             </a>
                            //         //                         </div>
                            //         //                         <div id={`collapseOne${item.name}`} class="collapse">
                            //         //                             <div class="card-body">
                            //         //                                 <form
                            //         //                                     onSubmit={(event) => {
                            //         //                                         readHandleSubmit(event, item.name, indexKey, Mainkey);
                            //         //                                     }}
                            //         //                                 >
                            //         //                                     <div className="accordion__body--text">
                            //         //                                         <p className="mx-2 text-black">{value}</p>
                            //         //                                         {item.input.map((item, key) => {
                            //         //                                             return (
                            //         //                                                 <>
                            //         //                                                     <input
                            //         //                                                         type="text"
                            //         //                                                         className="form-control input-default h-25 mt-2"
                            //         //                                                         placeholder={item.type}
                            //         //                                                         onChange={(e) => {
                            //         //                                                             readHandleChange(key, e, Mainkey);
                            //         //                                                             handleIcon(indexKey);
                            //         //                                                         }}
                            //         //                                                     />
                            //         //                                                 </>
                            //         //                                             );
                            //         //                                         })}
                            //         //                                         {item.outputs.map((item, key) => {
                            //         //                                             return (
                            //         //                                                 <>
                            //         //                                                     <span className="mx-2">
                            //         //                                                         <strong>
                            //         //                                                             {readFormOutput[indexKey] && readFormOutput[indexKey].output !== ""
                            //         //                                                                 ? readFormOutput[indexKey].output
                            //         //                                                                 : ""}
                            //         //                                                         </strong>
                            //         //                                                         &nbsp;{item.type}
                            //         //                                                     </span>
                            //         //                                                 </>
                            //         //                                             );
                            //         //                                         })}
                            //         //                                         <div>{newError === Mainkey ? <span className="text-danger">{newErrorMessage}</span> : ""}</div>
                            //         //                                         <div>
                            //         //                                             {item.input.length ? (
                            //         //                                                 <button
                            //         //                                                     type="submit"
                            //         //                                                     onSubmit={(event) => {
                            //         //                                                         readHandleSubmit(event, item.name, indexKey);
                            //         //                                                     }}
                            //         //                                                     className="btn btn-primary btn-xs mt-3 mb-3 light badge-light"
                            //         //                                                 >
                            //         //                                                     <span className="text-white">Query</span>
                            //         //                                                     {newSpinner === Mainkey ? (
                            //         //                                                         <span>
                            //         //                                                             {" "}
                            //         //                                                             <div className="spinner-border spinner-border-sm mx-2 " role="status"></div>{" "}
                            //         //                                                         </span>
                            //         //                                                     ) : (
                            //         //                                                         ""
                            //         //                                                     )}
                            //         //                                                 </button>
                            //         //                                             ) : (
                            //         //                                                 ""
                            //         //                                             )}
                            //         //                                         </div>
                            //         //                                         {getreadinputFields[indexKey] && getreadinputFields[indexKey] !== "" ? (
                            //         //                                             <span>{getreadinputFields[indexKey]}</span>
                            //         //                                         ) : (
                            //         //                                             ""
                            //         //                                         )}
                            //         //                                     </div>
                            //         //                                 </form>
                            //         //                             </div>
                            //         //                         </div>
                            //         //                     </div>
                            //         //                 );
                            //         //             })}
                            //         //     </div>
                            //         // </div>
                            //     )
                            // ) : hash === "#write" ? (
                            //     dispWrite ? (
                            //         <Spinner />
                            //     ) : (
                            //         <div className="tab-pane active">
                            //             <div id="accordion">
                            //                 {isConnected ? (
                            //                     // <div>{currentAccount} </div>
                            //                     // <div className='d-flex justify-content-between mr-2'>
                            //                     //     <span>
                            //                     //         <i class="fa fa-circle badge-text mr-1 my-3" />
                            //                     //         Connected to Metamask
                            //                     //         <a href={`/ address / ${ currentAccount } `} className='text-primary'>[{currentAccount}] </a>
                            //                     //     </span>
                            //                     //     <span type="button" class="text-primary" onClick={onReset}>[Reset]</span>
                            //                     // </div>
                            //                     <div class="d-flex justify-content-between my-2">
                            //                         <span>
                            //                             {" "}
                            //                             <i class="fa fa-circle badge-text mr-1" />
                            //                             Connected to metamask
                            //                             <span type="button" class="text-primary">
                            //                                 {" "}
                            //                                 &nbsp;[{currentAccount}]{" "}
                            //                             </span>
                            //                         </span>
                            //                         <span type="button" class="text-primary" onClick={onReset}>
                            //                             [Reset]
                            //                         </span>{" "}
                            //                     </div>
                            //                 ) : (
                            //                     <h6 type="button" className="my-1 text-primary" onClick={() => onLoginHandler()}>
                            //                         <i class="fa fa-circle text-danger mr-1 my-3"></i> connect to Metamask
                            //                     </h6>
                            //                 )}
                            //                 <div {...{ className: "" }}>
                            //                     {writeContractFields &&
                            //                         writeContractFields.map((item1, key1) => {
                            //                             // writeContractFields &&
                            //                             //     writeContractFields.map((item, key) => {
                            //                             // var indexKey = key;
                            //                             const writeData = item1;
                            //                             // console.log("icon", data);
                            //                             return (
                            //                                 <>
                            //                                     <ul
                            //                                         {...{
                            //                                             className: "card shadow-none mb-3 pl-0",
                            //                                         }}
                            //                                         style={{ listStyle: "none" }}
                            //                                     >
                            //                                         <li
                            //                                             {...{
                            //                                                 className: "accordion-list__item",
                            //                                                 key1,
                            //                                             }}
                            //                                         >
                            //                                             <AccordianWrite
                            //                                                 {...writeData}
                            //                                                 formHash={formHash}
                            //                                                 handleChange={handleChange}
                            //                                                 handleSubmit={handleSubmit}
                            //                                                 indexKey1={key1}
                            //                                                 formValueErr={formValueErr}
                            //                                             />
                            //                                         </li>
                            //                                     </ul>
                            //                                     {/* <div class="card shadow-none mb-3" id="accordion" style={{ border: "1px solid rgba(0,0,0,.125)" }}>
                            //                                                                 <div class="card-header bg-light card-collapse p-0">
                            //                                                                     <a
                            //                                                                         class={formIcon.length && formIcon[indexKey] && formIcon[indexKey].icon ? "card-link btn btn-link btn-block text-dark d-flex justify-content-between align-items-center py-2" : "collapsed card-link btn btn-link btn-block text-dark d-flex justify-content-between align-items-center py-2"}
                            //                                                                         data-toggle="collapse"
                            //                                                                         href={`#collapseOne${item.name}`}
                            //                                                                         type="button"
                            //                                                                         onClick={() => {
                            //                                                                             generateFieldInput(item.input.length);
                            //                                                                             handleIcon(indexKey);
                            //                                                                         }}
                            //                                                                     >
                            //                                                                         {item.name}
                            //                                                                         <i
                            //                                                                             class={`${formIcon.length && formIcon[indexKey] && !formIcon[indexKey].icon ? "fa fa-arrow-right" : "fa fa-arrow-down"
                            //                                                                                 } small`}
                            //                                                                             id={indexKey}
                            //                                                                         ></i>
                            //                                                                     </a>
                            //                                                                 </div>
                            //                                                                 <div id={`collapseOne${item.name}`} class={formIcon.length && formIcon[indexKey] && !formIcon[indexKey].icon ? "collapse show" : "collapse"} data-parent="#accordion">
                            //                                                                     <div class="card-body">
                            //                                                                         <form
                            //                                                                             onSubmit={(event) => {
                            //                                                                                 handleSubmit(event, item.name, indexKey);
                            //                                                                             }}
                            //                                                                         >
                            //                                                                             <div className="accordion__body--text">
                            //                                                                                 {item.input.map((item, key) => {
                            //                                                                                     return (
                            //                                                                                         <>
                            //                                                                                             <span className="text-black ml-2">{item.name}</span>
                            //                                                                                             <input
                            //                                                                                                 type="text"
                            //                                                                                                 className="form-control input-default h-25 mt-2"
                            //                                                                                                 onChange={(e) => handleChange(key, e)}
                            //                                                                                                 placeholder={item.type}
                            //                                                                                             />
                            //                                                                                         </>
                            //                                                                                     );
                            //                                                                                 })}
                            //                                                                             </div>
                            //                                                                             <p className="mx-3">{formError[indexKey] ? <span className="text-danger">{formError[indexKey].error} </span> : ""}</p>
                            //                                                                             <button
                            //                                                                                 type="submit"
                            //                                                                                 onSubmit={(event) => {
                            //                                                                                     handleSubmit(event, item.name, indexKey);
                            //                                                                                 }}
                            //                                                                                 className="btn btn-primary btn-xs mb-3 mx-1 badge-light"
                            //                                                                             >
                            //                                                                                 <span className="text-white">
                            //                                                                                     <strong>Query</strong>
                            //                                                                                 </span>
                            //                                                                             </button>
                            //                                                                             {item.input.length && formHash[indexKey] && formHash[indexKey].hash !== "" ? (

                            //                                                                                 <a
                            //                                                                                     type="button"
                            //                                                                                     className="btn btn-primary btn-xs mb-3 mx-1"
                            //                                                                                     target="_blank"
                            //                                                                                     rel="noopener noreferrer"
                            //                                                                                     href={formHash[indexKey] && formHash[indexKey].hash !== "" ? `/tx/${formHash[indexKey].hash}` : "#"}
                            //                                                                                 >
                            //                                                                                     <span className="text-white">
                            //                                                                                         <strong>View on Explorer</strong>
                            //                                                                                     </span>
                            //                                                                                 </a>
                            //                                                                             ) : (
                            //                                                                                 ""
                            //                                                                             )}
                            //                                                                         </form>
                            //                                                                     </div>
                            //                                                                 </div>
                            //                                                             </div> */}
                            //                                 </>
                            //                             );
                            //                         })}
                            //                 </div>
                            //             </div>
                            //         </div>
                            //     )
                            // ) : (
                            //     ""
                            // )
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContractDetailsForAddress;
