import React from "react";

function NoDataFound() {
    return (
        <>
            <main id="content" role="main" style={{ minHeight: "880px" }}>
                <input type="hidden" name="hdnAgeText" id="hdnAgeText" defaultValue="Age" />
                <input type="hidden" name="hdnDateTimeText" id="hdnDateTimeText" defaultValue="Date Time (UTC)" />
                <input type="hidden" name="hdnAgeTitle" id="hdnAgeTitle" defaultValue="Click to show Age Format" />
                <input type="hidden" name="hdnDateTimeTitle" id="hdnDateTimeTitle" defaultValue="Click to show Datetime Format" />
                <input type="hidden" name="hdnTxnText" id="hdnTxnText" defaultValue="Txn Fee" />
                <input type="hidden" name="hdnGasPriceText" id="hdnGasPriceText" defaultValue="Gas Price" />
                <input
                    type="hidden"
                    name="hdnTxnFeeTitle"
                    id="hdnTxnFeeTitle"
                    defaultValue={`(Gas Price * Gas Used by Txns) in ${process.env.REACT_APP_TICKER}`}
                />
                <input type="hidden" name="hdnGasPriceTitle" id="hdnGasPriceTitle" defaultValue="Gas Price in Gwei" />
                {/* <div className="container py-3 mn-b3"> */}
                <div className="container py-3 mn-b3">
                    <div className="d-lg-flex justify-content-between align-items-center">
                        <div className="mb-3 mb-lg-0">
                            <h1 className="h4 mb-0"> Transaction Details </h1>
                        </div>
                        <div className="d-flex flex-wrap mt-1 mt-md-0 flex-wrap ml-md-auto" />
                    </div>
                </div>
                <div className="container">
                    <div className="border-top py-3">
                        <div className="d-flex text-secondary" style={{ lineHeight: "2.2" }} />
                    </div>
                </div>
                <div className="container space-bottom-2">
                    <div className="card">
                        <div className="card-header sticky-card-header d-flex justify-content-between p-0">
                            <ul className="nav nav-custom nav-borderless nav_tabs1" id="nav_tabs" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" aria-controls="home">
                                        Overview
                                    </a>
                                </li>
                            </ul>
                            <div className="d-flex mr-3 mt-3">
                                <div id="spinnerwait" style={{ display: "none" }}>
                                    <img src="asset/images/main/EllipsisSpinner3.svg?v=0.0.1" alt="not found." />
                                </div>
                            </div>
                        </div>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                <div className="card-body px-lg-5">
                                    <div className="space-2 text-center">
                                        {/* <img className="mb-5-alt darkmode" width={150} src="asset/images/ignite-blockchain-explorer_files/empty-search-state-dark.svg" alt="Search Not Found" /> */}
                                        <img
                                            className="mb-5-alt normalMode"
                                            width={150}
                                            src="/asset/images/empty-search-state.svg"
                                            alt="Search Not Found"
                                        />
                                        <p className="lead mb-0">Sorry, We are unable to locate this TxnHash</p>
                                    </div>
                                    <hr className="mt-0 mb-4" />
                                    <div className="alert alert-dark">
                                        <p className="alert-text mb-0">
                                            <i className="fa fa-info-circle mr-1" /> {process.env.REACT_APP_NAME}scan only provides an overview of the
                                            current state of the blockchain such as your transaction status,{" "}
                                            <strong> but we have no control over these transactions</strong>.
                                        </p>
                                    </div>
                                    <ul className="list-unstyled list-xs-space">
                                        <li>
                                            1) If you have just submitted a transaction please wait for at least 30 seconds before refreshing this
                                            page.
                                        </li>
                                        <li>2) It could still be in the TX Pool of a different node, waiting to be broadcasted.</li>
                                        <li>
                                            3) When the network is busy it can take a while for your transaction to propagate through the network and
                                            for us to index it.
                                        </li>
                                        <li>
                                            4) If it still does not show up after 1 hour, please check with your sender/exchange/wallet/transaction
                                            provider for additional information.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="internal" role="tabpanel" aria-labelledby="internal-tab">
                                <div
                                    className="ml-auto float-right mr-4 mt-3 mb-4"
                                    id="divswitch"
                                    data-container="body"
                                    data-toggle="popover"
                                    data-placement="top"
                                    data-original-title
                                    title
                                    data-content="Toggle between Simplified and Advanced view. The 'Advanced' view also shows zero value ETH transfers, while the 'Simple' view only shows ETH transfers with value. Name tags integration is not available in advanced view"
                                >
                                    <div className="d-flex">
                                        <label
                                            className="checkbox-switch mb-0"
                                            id="labelswitch"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            data-original-title
                                            title
                                        >
                                            <input type="checkbox" className="checkbox-switch__input" id="customSwitch1" onclick="toggleMode();" />
                                            <span className="checkbox-switch__slider" />
                                        </label>
                                        <span className="ml-1" id="toggletext">
                                            Simple
                                        </span>
                                    </div>
                                </div>
                                <div className="card-body mt-1">
                                    Invoked Transactions
                                    <div id="ContentPlaceHolder1_divinternaltable" className="table-responsive mb-2 mb-md-0">
                                        <table className="table table-hover table-align-middle">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col">Type Trace Address</th>
                                                    <th scope="col" width={180}>
                                                        From
                                                    </th>
                                                    <th scope="col" width={30} />
                                                    <th scope="col">To</th>
                                                    <th scope="col">Value</th>
                                                    <th scope="col">Gas Limit</th>
                                                </tr>
                                            </thead>
                                            <tbody></tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="eventlog" role="tabpanel" aria-labelledby="eventlog-tab">
                                <div className="card-body">
                                    <h6 className="font-weight-normal mb-4">Transaction Receipt Event Logs</h6>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="accesslist" role="tabpanel" aria-labelledby="accesslist-tab">
                                <div className="card-body">
                                    <h6 className="font-weight-normal mb-4"></h6>
                                    <pre />
                                </div>
                            </div>
                            <div className="tab-pane fade" id="comments" role="tabpanel" aria-labelledby="comments-tab">
                                <div className="card-body">
                                    <p>
                                        Make sure to use the "Vote Down" button for any spammy posts, and the "Vote Up" for interesting conversations.
                                    </p>
                                    <br />
                                    <div className="panel-body">
                                        <div>
                                            <div id="disqus_thread" />
                                            <noscript>
                                                Please enable JavaScript to view the &lt;a href='https://disqus.com/?ref_noscript' rel='nofollow
                                                noopener'&gt;comments powered by Disqus&lt;/a&gt;.
                                            </noscript>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="statechange" role="tabpanel" aria-labelledby="statechange-tab">
                                <div className="card-body">
                                    <div id="loadingStateChangeFrame">
                                        <div id="overlay" className="py-3 text-center">
                                            <img src="/images/main/loadingblock.svg" alt="Loading" />
                                        </div>
                                    </div>
                                    <div className="table-responsive" style={{ overflow: "auto", WebkitOverflowScrolling: "touch" }}>
                                        <iframe
                                            title="iframe1"
                                            width="100%"
                                            allowTransparency="true"
                                            background="transparent"
                                            id="statechangeframe"
                                            src
                                            frameBorder={0}
                                            scrolling="yes"
                                            style={{ height: "500px" }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pendingtxlog" role="tabpanel" aria-labelledby="pendingtxeventlog-tab">
                                <div className="card-body">
                                    <div id="loadingPendingTxEventLogFrame">
                                        <div id="overlay" className="py-3 text-center">
                                            <img src="/images/main/loadingblock.svg" alt="Loading" />
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <iframe
                                            title="iframe2"
                                            width="100%"
                                            allowTransparency="true"
                                            background="transparent"
                                            id="pendingtxeventlogframe"
                                            src
                                            frameBorder={0}
                                            scrolling="yes"
                                            style={{ height: "500px", minHeight: "500px" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="d-flex align-items-baseline font-size-1 mt-4">
                                <i className="fal fa-lightbulb-on text-secondary mr-1" />
                                <p className="mb-0">
                                    A transaction is a cryptographically signed instruction from an account that changes the state of the blockchain. Block explorers track the details of all transactions in the network. Learn more about transactions in our <a target="_blank" href="https://info.etherscan.com/understanding-an-ethereum-transaction/">Knowledge Base</a>.
                                </p>
                            </div> */}
                    {/* <div className="container mt-4 text-center">
                                <div>
                                    <div className="coinzilla" data-zone="C-1775ebb9d466c9d4408" />
                                </div>
                            </div> */}
                </div>
                {/* </div> */}
                {/* </div> */}
            </main>
            <div id="push" />
            <div className="modal fade" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" id="emailSubscribeModalBox" aria-hidden="true">
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="close close-md" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                            <div className="text-center py-5-alt px-4">
                                <div id="emailSubscribeModalBoxText" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default NoDataFound;
