import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Home from "../common/HeaderComponents/Home";
import Blockchain from "./HeaderComponents/BlockChain";
import Misc from "./HeaderComponents/Misc";
import Mode from "./HeaderComponents/Mode";
import Resources from "./HeaderComponents/Resources";
import Tokens from "./HeaderComponents/Tokens";
import SignIn from "./HeaderComponents/UserProfiler";
import Logo from "./HeaderComponents/logo";
import Validators from "./HeaderComponents/Validators";
import { IgniteContext } from "../IgniteContext";
import NFTs from "./HeaderComponents/NFTs";
import Developers from "./HeaderComponents/Developers";
function Header() {
  const { settingData, gas } = useContext(IgniteContext);
  return (
    <>
      {settingData && settingData?.maintnanceMessage && settingData?.maintnanceStatus ? (
        <div
          style={{
            backgroundColor: `#${
              settingData && settingData?.maintnanceBgColor ? settingData?.maintnanceBgColor : "0d6efd"
            }`,
          }}
        >
          <div class="text-center m-0">
            {" "}
            <p className="text-white m-0">{settingData?.maintnanceMessage}</p>
          </div>
        </div>
      ) : (
        ""
      )}
      <header id="header" className="u-header">
        <div className="u-header__section py-1">
          <div id="logoAndNav" className="container">
            <nav className="js-mega-menu navbar navbar-expand-md u-header__navbar u-header__navbar--no-space">
              <div className="w-lg-auto">
                <div id="ethPrice">
                  <div className="d-none d-md-inline-block u-label u-label--price rounded mx-1 mb-1 text-nowrap">
                    <span className="text-dark">{process.env.REACT_APP_TICKER}:</span>
                    <span className="text-primary">${process.env.REACT_APP_PRICE} (+0.0%)</span>,{" "}
                    <span className="text-dark">Gas: </span>
                    <span className="text-primary">{Number(gas).toFixed(2)} Gwei</span>
                  </div>
                </div>
                <Link className="navbar-brand pt-md-0 p-0" to="/" target="_parent">
                  <Logo />
                </Link>
              </div>
              <div>
                <button
                  type="button"
                  className="navbar-toggler btn u-hamburger"
                  aria-label="Toggle navigation"
                  aria-expanded="false"
                  aria-controls="navBar"
                  data-toggle="collapse"
                  data-target="#navBar"
                >
                  <span id="hamburgerTrigger" className="u-hamburger__box">
                    <span className="u-hamburger__inner" />
                  </span>
                </button>
              </div>
              <div className="d-flex flex-column w-100">
                <div id="navBar" className="collapse navbar-collapse u-header__navbar-collapse order-md-2">
                  <ul className="navbar-nav u-header__navbar-nav px-2 px-md-0">
                    <Home />
                    <Blockchain />
                    <Validators />
                    <Tokens />
                    <NFTs />
                    <Developers />
                    <Resources />
                    {/* <Misc /> */}
                    {process.env.REACT_APP_LOGIN === "true" || process.env.REACT_APP_LOGIN === true ? (
                      <SignIn />
                    ) : (
                      ""
                    )}
                    <Mode />
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
