import React from "react";
import Header2 from "../common/Header2";
import ContactUsHeading from "./ContactUsHeading";
import FeedbackForm from "./FeedbackForm";
import HelpfulLinks from "./HelpfulLinks";
import Footer from "../common/Footer";

function ContactUs() {
    return (
        <>
            <Header2 />
            <main id="content" role="main" style={{ minHeight: "800px" }}>
                <ContactUsHeading />

                <div class="container space-bottom-2 pt-3">
                    <div class="row">
                        <FeedbackForm />
                        <HelpfulLinks />
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
}

export default ContactUs;
