import React, { useRef, useState, useEffect } from "react";
import Copymsg from "../common/Copy/Copymsg";
import Copyicon from "../common/Copy/Copyicon";
import delay from "delay";
import CopyToClipboard from "react-copy-to-clipboard";
import Web3 from "web3";
import { Link } from "react-router-dom";
import FadingLoaderCard1 from "../common/ContentLoaders/LoaderBigLine";
import { getLocalStorage } from "../../utils";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
function MoreInfo({ tokenDetails, id, state, copyaddress, tokenSpinner }) {
  const isLogin =
    getLocalStorage("ua5s21san") && getLocalStorage("ea4sd4fam") && getLocalStorage("pn4sec3f1s")
      ? true
      : false;
  const ref = useRef();
  const btnref = useRef();

  const [getFromCopy, setCopyFrom] = useState("");
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        openMenu &&
        ref.current &&
        !ref.current.contains(e.target) &&
        btnref.current &&
        !btnref.current.contains(e.target)
      ) {
        setOpenMenu(false);
      }
    };

    document.addEventListener("mouseup", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mouseup", checkIfClickedOutside);
    };
  }, [openMenu]);

  async function copyfrom() {
    setCopyFrom("copied");
    await delay(1000);
    setCopyFrom("");
  }

  // const t1 = <Tooltip>Email: {getTokenData.verificationData ? getTokenData.verificationData.email : ""}</Tooltip>;
  const t2 = <Tooltip>Whitepaper: {tokenDetails?.info ? tokenDetails?.info?.social_whitepaper : ""}</Tooltip>;
  const t3 = <Tooltip>Twitter: {tokenDetails?.info ? tokenDetails?.info?.social_twitter : ""}</Tooltip>;
  const t4 = <Tooltip>Github: {tokenDetails?.info ? tokenDetails?.info?.social_github : ""}</Tooltip>;
  const t5 = <Tooltip>Telegram : {tokenDetails?.info ? tokenDetails?.info?.social_telegram : ""}</Tooltip>;
  const t6 = <Tooltip>Facebook: {tokenDetails?.info ? tokenDetails?.info?.social_facebook : ""}</Tooltip>;
  const t7 = <Tooltip>Reddit: {tokenDetails?.info ? tokenDetails?.info?.social_reddit : ""}</Tooltip>;
  const t8 = <Tooltip>Medium: {tokenDetails?.info ? tokenDetails?.info?.social_medium : ""}</Tooltip>;
  const t9 = <Tooltip>WeChat: {tokenDetails?.info ? tokenDetails?.info?.social_wechat : ""}</Tooltip>;
  const t10 = <Tooltip>LinkedIn : {tokenDetails?.info ? tokenDetails?.info?.social_linkedin : ""}</Tooltip>;
  const t11 = <Tooltip>Discord: {tokenDetails?.info ? tokenDetails?.info?.social_discord : ""}</Tooltip>;
  const t12 = <Tooltip>Slack: {tokenDetails?.info ? tokenDetails?.info?.social_slack : ""}</Tooltip>;
  const t13 = (
    <Tooltip>BitCoinTalk: {tokenDetails?.info ? tokenDetails?.info?.social_bitcointalk : ""}</Tooltip>
  );
  const t14 = <Tooltip>Ticketing : {tokenDetails?.info ? tokenDetails?.info?.social_ticketing : ""}</Tooltip>;
  const t15 = (
    <Tooltip>CoinMarketCap: {tokenDetails?.info ? tokenDetails?.info?.coinmarketcap_ticker : ""}</Tooltip>
  );
  const t16 = <Tooltip>CoinGecko: {tokenDetails?.info ? tokenDetails?.info?.coingecko_ticker : ""}\</Tooltip>;

  const handleRedirect = () => {
    if (isLogin) {
      sessionStorage.setItem("a1BDc45Adfad", JSON.stringify({ id: id, state: 45 }));
      sessionStorage.setItem("a1BDc45Adfa", JSON.stringify({ id: id, state: 50 }));
      window.location.assign(`/tokenupdate/${tokenDetails?.address}`);
    } else {
      window.location.assign("/login");
    }
  };

  const network = 6667;
  const onLoginHandler = async () => {
    const provider = window.ethereum;
    if (provider) {
      if (provider !== window.ethereum) {
        console.error("Not window.ethereum provider. Do you have multiple wallet installed ?");
      }
      await provider.request({
        method: "eth_requestAccounts",
      });
    }
    connectweb3(provider);
  };

  const connectweb3 = async (provider) => {
    const Login = async () => {
      const web3 = new Web3(Web3.givenProvider || process.env.REACT_APP_HTTP_RPC);
      const accounts = await web3.eth.getAccounts();
      const chainid = await web3.eth.getChainId();
      if (chainid !== network) {
        const hexchainid = web3.utils.toHex(chainid);
        try {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: hexchainid }],
          });
        } catch (err) {
          // This error code indicates that the chain has not been added to MetaMask
          if (err.code === 4902) {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: Web3.utils.toHex(Number(process.env.REACT_APP_CHAINID)),
                  chainName: "StorChain Mainnet",
                  nativeCurrency: {
                    name: "StorChain Mainnet",
                    symbol: "STR",
                    decimals: 18,
                  },
                  rpcUrls: [process.env.REACT_APP_HTTP_RPC],
                  blockExplorerUrls: [process.env.REACT_APP_EXPLORER],
                },
              ],
            });
          }
        }
        // window.ethereum.request({
        //     method: "wallet_addEthereumChain",
        //     params: [{
        //         chainId: `0x${Number(19).toString(16)}`,
        //         chainName: "",
        //         nativeCurrency: {
        //             name: "",
        //             symbol: "",
        //             decimals: 18
        //         },
        //         rpcUrls: [""],
        //         blockExplorerUrls: [""]
        //     }]
        // })
        // setNetworkConnectionErr("MetaMask is not connected to Cryptocreatores network")
      }

      if (accounts.length === 0) {
        return false;
      } else {
        if (chainid === network) {
          try {
            const { ethereum } = window;
            await ethereum.request({
              method: "wallet_watchAsset",
              params: {
                type:
                  tokenDetails?.type === "erc20"
                    ? "ERC20"
                    : tokenDetails?.type === "erc721"
                    ? "ERC721"
                    : tokenDetails?.type === "erc1155"
                    ? "ERC1155"
                    : "",
                options: {
                  address: tokenDetails?.address, // ERC20 token address
                  symbol: tokenDetails?.symbol,
                  decimals: Number(tokenDetails?.decimals),
                  image: "",
                },
              },
            });
          } catch (ex) {
            console.error(ex);
          }
        } else {
          return false;
        }
      }
    };
    Login();
  };
  // const addNetwork = () => {
  //     // alert("hii there")
  //     const params = [{
  //         chainId: '0x7c7',
  //         chainName: ' Mainnet',
  //         nativeCurrency: {
  //             name: ' Mainnet',
  //             symbol: '',
  //             decimals: 18
  //         },
  //         rpcUrls: [''],
  //         blockExplorerUrls: ['']
  //     }]

  //     window.ethereum.request({ method: 'wallet_addEthereumChain', params })
  //         // .then(() => console.log('Success'))
  //         // .catch((error) => console.log("Error", error.message))
  // }
  return (
    <>
      <div id="ContentPlaceHolder1_cardright" className="col-md-6">
        <div className="card h-100">
          <div className="card-header d-flex justify-content-between align-items-center">
            <div className="d-flex d-none">
              <h2 className="card-header-title mr-1 mb-0 d-flex mb-1">More Info</h2>
            </div>
            <div class="d-flex d-none">
              <div id="ContentPlaceHolder1_moreoptionsdiv" className="position-relative">
                {/* eslint-disable-next-line */}
                <button
                  id="dropdownToolsInvoker-2"
                  className={
                    openMenu
                      ? "btn btn-xs btn-icon btn-soft-secondary cursor-pointer active-self-focus"
                      : "btn btn-xs btn-icon btn-soft-secondary cursor-pointer active-self"
                  }
                  ref={btnref}
                  role="button"
                  aria-controls="ddlTxFilterTools"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-unfold-duration="300"
                  data-unfold-delay="300"
                  data-toggle="tooltip"
                  title=""
                  onClick={() => {
                    setOpenMenu(!openMenu);
                  }}
                >
                  <i class="fa fa-ellipsis-v btn-icon__inner"></i>
                </button>
                <div
                  id="dropdownTools-2"
                  className={
                    openMenu
                      ? "dropdown-menu dropdown-menu-right dropdown-unfold u-unfold--css-animation slideInUp"
                      : "dropdown-menu dropdown-menu-right dropdown-unfold u-unfold--css-animation u-unfold--hidden"
                  }
                  ref={ref}
                  aria-labelledby="dropdownToolsInvoker-2"
                  style={{ zIndex: "1021", animationDuration: "300ms", right: "0px" }}
                >
                  {/* {/ <a class='dropdown-item' href='/tokencheck-tool?t=0x2170ed0880ac9a755fd29b2688956bd959f933f8'><i class='fa fa-fw fa-history mr-1'></i>Check previous token supply</a> /} */}
                  {/* eslint-disable-next-line */}
                  <a
                    class="dropdown-item"
                    type="button"
                    onClick={() => {
                      onLoginHandler();
                    }}
                    href="javascript:void(0);"
                  >
                    <i class="fas fa-fw fa-wallet mr-1"></i>Add Token to Web3 Wallet
                  </a>
                  <hr class="my-1" />
                  {/* eslint-disable-next-line */}
                  <a class="dropdown-item" href="javascript:ShowPopup();">
                    <font>
                      <i class="fa fa-fw fa-pencil-alt mr-1"></i>Update Token Info
                    </font>
                  </a>
                  <a
                    class="dropdown-item"
                    href={`/contactus?a=${"0x7ceb23fd6bc0add59e62ac25578270cff1b9f619"}`}
                  >
                    <font>
                      <i class="fa fa-fw fa-user-tag mr-1"></i>Update Name Tag
                    </font>
                  </a>
                  {/* <a class="dropdown-item" href={`/contactus?a=${"0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619"}`}>
                                        <font>
                                            <i class="fa fa-fw fa-tags mr-1"></i>Submit Label
                                        </font>
                                    </a> */}
                  {/* {/ <a class='dropdown-item' href='/contactus?id=6'><font><i class='fa fa-fw fa-flag mr-1'></i>Report/Flag Address</font></a> /} */}
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            {/* <div className="row align-items-center">
                            <div className="col-md-4 mb-1 mb-md-0">
                                <i className="far fa-question-circle text-muted" data-toggle="tooltip" data-html="true" data-title="<p class='text-white text-left'>Name tags or notes can be attached to an address for identifying addresses of interest. <br><br>(This info is private and can ONLY be viewed by you)</p>"></i>  My Name Tag:
                            </div>
                            <div className="col-md-8">
                                <span id="Public_Private_Tag">Not Available, <Link data-toggle="tooltip" title="To use this feature, please login to your mCoin account and return to this page" to="/login"> login to update</Link></span>
                            </div>
                        </div> */}
            {/* <hr className="hr-space" /> */}
            <div id="ContentPlaceHolder1_tr_mined">
              <div className="row align-items-center">
                <div className="col-md-4 mb-1 mb-md-0"> Contract:</div>

                <div className="col-md-8">
                  {tokenSpinner ? (
                    <>
                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="250" height="15" />{" "}
                    </>
                  ) : (
                    <>
                      <div class="d-flex clipboard-hover">
                        {/* eslint-disable-next-line */}
                        <Link class="text-truncate d-block" to={`/address/${tokenDetails?.address}`}>
                          {tokenDetails?.address}
                        </Link>
                        <CopyToClipboard
                          text={tokenDetails?.address}
                          onCopy={() => {
                            copyfrom();
                          }}
                        >
                          <span>{getFromCopy === "" ? <Copyicon /> : <Copymsg />} </span>
                        </CopyToClipboard>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <hr className="hr-space" />
            {tokenDetails?.type === "erc721" || tokenDetails?.type === "erc1155" ? (
              ""
            ) : (
              <>
                <div id="ContentPlaceHolder1_tr_mined">
                  <div className="row align-items-center">
                    <div className="col-md-4 mb-1 mb-md-0"> Decimals:</div>
                    <div className="col-md-8">
                      {tokenSpinner ? (
                        <>
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />{" "}
                        </>
                      ) : (
                        <>{tokenDetails?.decimals}</>
                      )}
                    </div>
                  </div>
                </div>
                <hr className="hr-space" />
              </>
            )}
            {/* {getTokenData && getTokenData.isVerified && getTokenData.verificationData && getTokenData.verificationData.website ? ( */}
            {true ? (
              <>
                <div id="ContentPlaceHolder1_tr_mined">
                  <div className="row align-items-center">
                    <div className="col-md-4 mb-1 mb-md-0"> Official Website:</div>
                    {tokenSpinner ? (
                      <>
                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />{" "}
                      </>
                    ) : tokenDetails &&
                      tokenDetails?.status &&
                      tokenDetails?.info?.official_project_Website ? (
                      <>
                        <div className="col-md-8">
                          <a
                            className="text-primary cursor-pointer"
                            href={tokenDetails?.info?.official_project_Website}
                            target="_blank"
                          >
                            {tokenDetails?.info?.official_project_Website}
                          </a>
                        </div>
                      </>
                    ) : (
                      <div className="col-md-8">
                        <>
                          Not Available,&nbsp;
                          {isLogin ? (
                            <a className="text-primary cursor-pointer" onClick={handleRedirect}>
                              Update ?
                            </a>
                          ) : (
                            <a href="/login">Update ?</a>
                          )}
                        </>
                      </div>
                    )}
                    {/* <div className="col-md-8">{getTokenData.verificationData.website ? <a href={`${getTokenData.verificationData.website}`} target="_blank" rel="noopener noreferrer">{getTokenData.verificationData.website}<i class="fa fa-external-link-alt small ml-2"></i></a> : "Not available"}</div> */}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            <hr className="hr-space" />
            <div id="ContentPlaceHolder1_tr_mined">
              <div className="row align-items-center">
                <div className="col-md-4 mb-1 mb-md-0">Social Profiles:</div>

                <div className="col-md-8">
                  {tokenDetails && (tokenDetails.status === "true" || tokenDetails.status === true) ? (
                    <>
                      {/* {getTokenData.verificationData.email ? (
                                                <span class="mr-3">
                                                    <OverlayTrigger placement="top" trigger="hover" overlay={t1}>
                                                        <a
                                                            href={`mailto:${getTokenData.verificationData.email}`}
                                                            target="_blank"
                                                            className="link-hover-secondary"
                                                            data-tip
                                                            data-for="registerTip"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <i class="fa fa-envelope mt-2" aria-hidden="true" />
                                                        </a>
                                                    </OverlayTrigger>
                                                </span>
                                            ) : (
                                                ""
                                            )} */}
                      {
                        tokenDetails?.info?.social_whitepaper ? (
                          <span class="mr-3">
                            <OverlayTrigger placement="top" trigger="hover" overlay={t2}>
                              <a
                                href={`${tokenDetails?.info?.social_whitepaper}`}
                                target="_blank"
                                className="link-hover-secondary"
                                rel="noopener noreferrer"
                                data-tip
                                data-for="registerTip"
                              >
                                <i class="fa fa-file-alt mt-2" />
                              </a>
                            </OverlayTrigger>
                          </span>
                        ) : (
                          ""
                        )

                        // eslint-disable-next-line
                      }
                      {tokenDetails?.info?.social_twitter ? (
                        <span class="mr-3">
                          <OverlayTrigger placement="top" trigger="hover" overlay={t3}>
                            <a
                              href={`${tokenDetails?.info?.social_twitter}`}
                              target="_blank"
                              className="link-hover-secondary"
                              rel="noopener noreferrer"
                              data-tip
                              data-for="registerTip"
                            >
                              <i class="fab fa-twitter mt-2" aria-hidden="true" />
                            </a>
                          </OverlayTrigger>
                        </span>
                      ) : (
                        ""
                      )}
                      {tokenDetails?.info?.social_github ? (
                        <span class="mr-3">
                          <OverlayTrigger placement="top" trigger="hover" overlay={t4}>
                            <a
                              href={`${tokenDetails?.info?.social_github}`}
                              target="_blank"
                              className="link-hover-secondary"
                              data-tip
                              data-for="registerTip"
                              rel="noopener noreferrer"
                            >
                              <i class="fab fa-github mt-2" aria-hidden="true"></i>
                            </a>
                          </OverlayTrigger>
                        </span>
                      ) : (
                        ""
                      )}
                      {tokenDetails?.info?.social_telegram ? (
                        <span class="mr-3">
                          <OverlayTrigger placement="top" trigger="hover" overlay={t5}>
                            <a
                              href={`${tokenDetails?.info?.social_telegram}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="link-hover-secondary"
                              data-tip
                              data-for="registerTip"
                            >
                              <i class="fab fa-telegram mt-2"></i>
                            </a>
                          </OverlayTrigger>
                        </span>
                      ) : (
                        ""
                      )}
                      {tokenDetails?.info?.social_facebook ? (
                        <span class="mr-3">
                          <OverlayTrigger placement="top" trigger="hover" overlay={t6}>
                            <a
                              href={`${tokenDetails?.info?.social_facebook}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="link-hover-secondary"
                              data-tip
                              data-for="registerTip"
                            >
                              <i class="fab fa-facebook mt-2"></i>
                            </a>
                          </OverlayTrigger>
                        </span>
                      ) : (
                        ""
                      )}
                      {tokenDetails?.info?.social_reddit ? (
                        <span class="mr-3">
                          <OverlayTrigger placement="top" trigger="hover" overlay={t7}>
                            <a
                              href={`${tokenDetails?.info?.social_reddit}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="link-hover-secondary"
                              data-tip
                              data-for="registerTip"
                            >
                              <i class="fab fa-reddit mt-2"></i>
                            </a>
                          </OverlayTrigger>
                        </span>
                      ) : (
                        ""
                      )}
                      {tokenDetails?.info?.social_medium ? (
                        <span class="mr-3">
                          <OverlayTrigger placement="top" trigger="hover" overlay={t8}>
                            <a
                              href={`${tokenDetails?.info?.social_medium}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="link-hover-secondary"
                              data-tip
                              data-for="registerTip"
                            >
                              <i class="fab fa-medium mt-2"></i>
                            </a>
                          </OverlayTrigger>
                        </span>
                      ) : (
                        ""
                      )}
                      {tokenDetails?.info?.social_wechat ? (
                        <span class="mr-3">
                          <OverlayTrigger placement="top" trigger="hover" overlay={t9}>
                            <a
                              href={`${tokenDetails?.info?.social_wechat}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="link-hover-secondary"
                              data-tip
                              data-for="registerTip"
                            >
                              <i class="fab fa-weixin mt-2"></i>
                            </a>
                          </OverlayTrigger>
                        </span>
                      ) : (
                        ""
                      )}
                      {tokenDetails?.info?.social_linkedin ? (
                        <span class="mr-3">
                          <OverlayTrigger placement="top" trigger="hover" overlay={t10}>
                            <a
                              href={`${tokenDetails?.info?.social_linkedin}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="link-hover-secondary"
                              data-tip
                              data-for="registerTip"
                            >
                              <i class="fab fa-linkedin mt-2"></i>
                            </a>
                          </OverlayTrigger>
                        </span>
                      ) : (
                        ""
                      )}
                      {tokenDetails?.info?.social_discord ? (
                        <span class="mr-3">
                          <OverlayTrigger placement="top" trigger="hover" overlay={t11}>
                            <a
                              href={`${tokenDetails?.info?.social_discord}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="link-hover-secondary"
                              data-tip
                              data-for="registerTip"
                            >
                              <i class="fab fa-discord mt-2"></i>
                            </a>
                          </OverlayTrigger>
                        </span>
                      ) : (
                        ""
                      )}
                      {tokenDetails?.info?.social_slack ? (
                        <span class="mr-3">
                          <OverlayTrigger placement="top" trigger="hover" overlay={t12}>
                            <a
                              href={`${tokenDetails?.info?.social_slack}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="link-hover-secondary"
                              data-tip
                              data-for="registerTip"
                            >
                              <i class="fab fa-slack mt-2"></i>
                            </a>
                          </OverlayTrigger>
                        </span>
                      ) : (
                        ""
                      )}
                      {tokenDetails?.info?.social_bitcointalk ? (
                        <span class="mr-3">
                          <OverlayTrigger placement="top" trigger="hover" overlay={t13}>
                            <a
                              href={`${tokenDetails?.info?.social_bitcointalk}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="link-hover-secondary"
                              data-tip
                              data-for="registerTip"
                            >
                              <i class="fab fa-bitcoin mt-2"></i>
                            </a>
                          </OverlayTrigger>
                        </span>
                      ) : (
                        ""
                      )}
                      {tokenDetails?.info?.social_ticketing ? (
                        <span class="mr-2">
                          <OverlayTrigger placement="top" trigger="hover" overlay={t14}>
                            <a
                              href={`${tokenDetails?.info?.social_ticketing}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="link-hover-secondary"
                              data-tip
                              data-for="registerTip"
                            >
                              <i class="fas fa-user-tag mt-2"></i>
                            </a>
                          </OverlayTrigger>
                        </span>
                      ) : (
                        ""
                      )}
                      {tokenDetails?.info?.coinmarketcap_ticker ? (
                        <span class="mr-2">
                          <OverlayTrigger placement="top" trigger="hover" overlay={t15}>
                            <a
                              href={`${tokenDetails?.info?.coinmarketcap_ticker}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="link-hover-secondary"
                              data-tip
                              data-for="registerTip"
                              style={{ marginTop: "1px", position: "absolute" }}
                            >
                              <i class="coinmarketcap">
                                <svg
                                  height="15px"
                                  width="20px"
                                  fill="#6e6d6d"
                                  viewBox="0 0 76.52 77.67"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="m66.54 46.41a4.09 4.09 0 0 1 -4.17.28c-1.54-.87-2.37-2.91-2.37-5.69v-8.52c0-4.09-1.62-7-4.33-7.79-4.58-1.34-8 4.27-9.32 6.38l-8.1 13.11v-16c-.09-3.69-1.29-5.9-3.56-6.56-1.5-.44-3.75-.26-5.94 3.08l-18.11 29.07a32 32 0 0 1 -3.64-14.94c0-17.52 14-31.77 31.25-31.77s31.3 14.25 31.3 31.77v.09s0 .06 0 .09c.17 3.39-.93 6.09-3 7.4zm10-7.57v-.17c-.14-21.35-17.26-38.67-38.29-38.67s-38.25 17.42-38.25 38.83 17.16 38.84 38.25 38.84a37.81 37.81 0 0 0 26-10.36 3.56 3.56 0 0 0 .18-5 3.43 3.43 0 0 0 -4.86-.23 30.93 30.93 0 0 1 -44.57-2.08l16.3-26.2v12.09c0 5.81 2.25 7.69 4.14 8.24s4.78.17 7.81-4.75l9-14.57c.28-.47.55-.87.79-1.22v7.41c0 5.43 2.18 9.77 6 11.91a11 11 0 0 0 11.21-.45c4.2-2.73 6.49-7.67 6.25-13.62z" />
                                </svg>
                              </i>
                            </a>
                          </OverlayTrigger>
                        </span>
                      ) : (
                        ""
                      )}
                      {tokenDetails?.info?.coingecko_ticker ? (
                        <span class="ml-3">
                          <OverlayTrigger placement="top" trigger="hover" overlay={t16}>
                            <a
                              href={`${tokenDetails?.info?.coingecko_ticker}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="px-1 text-secondary"
                              data-tip
                              data-for="registerTip"
                              style={{ marginTop: "1px", position: "absolute" }}
                            >
                              <i class="coinmgecko">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="15px"
                                  width="20px"
                                  fill="#6e6d6d"
                                  viewBox="0 0 295 295"
                                >
                                  <g transform="translate(9.499 10.501)">
                                    <path
                                      id="Path_34"
                                      data-name="Path 34"
                                      d="M310,183A138,138,0,1,1,171.4,45.61,138,138,0,0,1,310,183Z"
                                      transform="translate(-34 -46.61)"
                                      fill="none"
                                      stroke="#6e6d6d"
                                      stroke-width="19"
                                    />
                                    <path
                                      id="Path_36"
                                      data-name="Path 36"
                                      d="M174.35,64.27a70.18,70.18,0,0,1,24.53,0,74.66,74.66,0,0,1,23.43,7.85c7.28,4,13.57,9.43,19.83,14.52s12.49,10.3,18.42,16a93.381,93.381,0,0,1,15.71,19,108.069,108.069,0,0,1,11,22.17c5.33,15.66,7.18,32.53,4.52,48.62H291c-2.67-15.95-6.29-31.15-12-45.61a178.006,178.006,0,0,0-9.44-21.25,208.8,208.8,0,0,0-12.42-19.93,72.3,72.3,0,0,0-16.64-16.8c-6.48-4.62-13.93-7.61-21.14-10.45S205,72.61,197.48,70.45s-15.16-3.78-23.14-5.35Z"
                                      transform="translate(-34 -45.61)"
                                      fill="none"
                                    />
                                    <path
                                      id="Path_37"
                                      data-name="Path 37"
                                      d="M236.74,138c-9.26-2.68-18.86-6.48-28.58-10.32-.56-2.44-2.72-5.48-7.09-9.19-6.35-5.51-18.28-5.37-28.59-2.93-11.38-2.68-22.62-3.63-33.41-1-88.25,24.31-38.21,83.62-70.61,143.24,4.61,9.78,54.3,66.84,126.2,51.53,0,0-24.59-59.09,30.9-87.45C270.57,198.79,303.09,156.07,236.74,138Z"
                                      transform="translate(-34 -45.61)"
                                      fill="#6e6d6d"
                                    />
                                    <path
                                      id="Path_38"
                                      data-name="Path 38"
                                      d="M247.64,176.81a5.35,5.35,0,1,1-5.38-5.32,5.35,5.35,0,0,1,5.38,5.32Z"
                                      transform="translate(-34 -45.61)"
                                      fill="#9faab5"
                                    />
                                    <path
                                      id="Path_39"
                                      data-name="Path 39"
                                      d="M172.48,115.52c6.43.46,29.68,8,35.68,12.12-5-14.5-21.83-16.43-35.68-12.12Z"
                                      transform="translate(-34 -45.61)"
                                      fill="#6e6d6d"
                                    />
                                    <path
                                      id="Path_40"
                                      data-name="Path 40"
                                      d="M178.6,152.19a24.68,24.68,0,1,1-24.677-24.67A24.68,24.68,0,0,1,178.6,152.19Z"
                                      transform="translate(-34 -45.61)"
                                      fill="#9faab5"
                                    />
                                    <path
                                      id="Path_41"
                                      data-name="Path 41"
                                      d="M171.28,152.41a17.36,17.36,0,1,1-17.36-17.36A17.36,17.36,0,0,1,171.28,152.41Z"
                                      transform="translate(-34 -45.61)"
                                      fill="#6e6d6d"
                                    />
                                    <path
                                      id="Path_42"
                                      data-name="Path 42"
                                      d="M267.63,187.69c-20,14.09-42.74,24.78-75,24.78-15.1,0-18.16-16-28.14-8.18-5.15,4.06-23.31,13.14-37.72,12.45S89,207.6,82.49,176.84c-2.58,30.76-3.9,53.42-15.45,79.39,23,36.83,77.84,65.24,127.62,53-5.35-37.35,27.29-73.93,45.68-92.65,7-7.09,20.3-18.66,27.29-28.91Z"
                                      transform="translate(-34 -45.61)"
                                      fill="#6e6d6d"
                                    />
                                    <path
                                      id="Path_43"
                                      data-name="Path 43"
                                      d="M266.85,188.61c-6.21,5.66-13.6,9.85-21.12,13.55a134.24,134.24,0,0,1-23.7,8.63c-8.16,2.11-16.67,3.7-25.29,2.92S179.31,210,173.6,203.54l.27-.31c7,4.54,15.08,6.14,23.12,6.37a108.569,108.569,0,0,0,24.3-2,132.339,132.339,0,0,0,23.61-7.3c7.63-3.15,15.18-6.8,21.68-12Z"
                                      transform="translate(-34 -45.61)"
                                      fill="#9faab5"
                                    />
                                  </g>
                                </svg>
                              </i>
                            </a>
                          </OverlayTrigger>
                        </span>
                      ) : (
                        ""
                      )}
                    </>
                  ) : !isLogin ? (
                    <span>
                      Not Available <Link to="/login">Update ?</Link>
                    </span>
                  ) : (
                    <>
                      {" "}
                      <span>
                        Not Available{" "}
                        <span className="text-primary cursor-pointer" onClick={handleRedirect}>
                          Update ?
                        </span>
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
          id="myModal"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header p-2">
                <h5 class="modal-title" id="myModalLabel">
                  Update Token Information Page
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <p>
                  To submit a token information's update request, please confirm beforehand that you have:
                  <ul>
                    <li>registered and logged in to your {process.env.REACT_APP_NAME}Scan account</li>
                    <li>
                      verified the contract's source code using our tool{" "}
                      <a href="/verifyContract" target="_blank" rel="noopener noreferrer">
                        here
                      </a>
                    </li>
                    <li>
                      been successfully verified as the{" "}
                      <a
                        href="https://bscscan.freshdesk.com/support/solutions/articles/67000573840-what-is-verify-contract-address-ownership"
                        data-placement="top"
                        data-toggle="tooltip"
                        data-original-title="What is Verify Address Ownership?"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        contract address owner
                      </a>
                    </li>
                  </ul>
                </p>
              </div>
              <div class="modal-footer">
                <button data-dismiss="modal" class="btn btn-sm btn-secondary btn-xs" type="button">
                  Close
                </button>
                {isLogin ? (
                  <input
                    type="button"
                    name="ctl00$ContentPlaceHolder1$btnGotoLogin"
                    value="Verify Address Ownership"
                    onClick={handleRedirect}
                    id="ContentPlaceHolder1_btnGotoLogin"
                    class="btn btn-sm btn-primary btn-xs"
                  />
                ) : (
                  <input
                    type="button"
                    name="ctl00$ContentPlaceHolder1$btnGotoLogin"
                    value="Login"
                    onClick={handleRedirect}
                    id="ContentPlaceHolder1_btnGotoLogin"
                    class="btn btn-sm btn-primary btn-xs"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MoreInfo;
