import React from "react";
import { Link } from "react-router-dom";
const Home = () => {
    const pathName = window.location.pathname;
    return (
        <li
            id="LI_default"
            className={`nav-item u-header__nav-item ${pathName === "/" ? "active" : ""}`}
            data-event="hover"
            data-animation-in="slideInUp"
            data-animation-out="fadeOut"
        >
            <Link className="nav-link u-header__nav-link" to="/" aria-haspopup="true" aria-expanded="false" aria-labelledby="pagesSubMenu">
                Home
            </Link>
        </li>
    );
};

export default Home;
