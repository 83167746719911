import React from "react";

const NoEntryMessage = ({ message }) => {
    return (
        <tr>
            <td colspan="10">
                <div class="alert alert-warning mb-0" role="alert">
                    {message}
                </div>
            </td>
        </tr>
    );
};

export default NoEntryMessage;
