import React, { useState, useEffect } from "react";
import axios from "axios";
import Web3 from "web3";
import { Link } from "react-router-dom";

const WriteInContract = ({ id, contractDeta }) => {
  const [writeData, setWriteData] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [inputValues, setInputValues] = useState([]);
  const [manageWriteData, setManageWriteData] = useState([]);

  const [isConnected, setIsConnected] = useState(false);
  const [currentAccount, setCurrentAccount] = useState("");

  const network = 80001;

  const getWriteContractData = async () => {
    setSpinner(true);
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}contracts/${id}/write`)
      .then((res) => {
        if (res.data.error) {
          console.log("error in getReadContractData : ", res.data.error);
        } else {
          setSpinner(false);
          setWriteData(res.data);
          let arr = [];
          let manageWriteData = [];
          for (let i = 0; i < res?.data?.length; i++) {
            for (let j = 0; j < res.data[i]?.inputs.length; j++) {
              arr.push({ mainkey: i, subkey: j, name: "" });
            }
            manageWriteData.push({ errid: i, error: "", spinner: false, hash: "" });
          }
          setInputValues([...arr]);
          setManageWriteData([...manageWriteData]);
        }
      })
      .catch((err) => {
        console.log("error in getReadContractData (catch) : ", err);
      });
  };

  useEffect(() => {
    getWriteContractData();
  }, []);

  const handleConnect = async () => {
    if (
      window.confirm(
        'Please take note that this is a beta version feature and is provided on an "as is" and "as available" basis. BscScan does not give any warranties and will not be liable for any loss, direct or indirect through continued use of this feature.'
      )
    ) {
      const provider = window.ethereum;
      if (provider) {
        if (provider !== window.ethereum) {
          console.error("Not window.ethereum provider. Do you have multiple wallet installed ?");
        }
        await provider.request({
          method: "eth_requestAccounts",
        });
      }
      connectweb3(provider);
    }
  };

  const connectweb3 = async (provider) => {
    const web3 = await new Web3(process.env.REACT_APP_HTTP_RPC);
    const rpcChainId = await web3.eth.getChainId();
    const rpcChainIdHex = await web3.utils.toHex(rpcChainId);
    const metaChainIdHex = await window.ethereum.request({ method: "eth_chainId" });
    if (rpcChainIdHex === metaChainIdHex) {
      const account = await window.ethereum.request({ method: "eth_accounts" });
      setCurrentAccount(account[0]);
    } else {
      if (window.ethereum) {
        try {
          // Try to switch to the Mumbai testnet
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            // params: [{ chainId: `0x${Number(80001).toString(16)}` }],
            params: [{ chainId: rpcChainIdHex }],
          });
        } catch (error) {
          // This error code means that the chain we want has not been added to MetaMask
          // In this case we ask the user to add it to their MetaMask
          if (error.code === 4902) {
            try {
              await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: Web3.utils.toHex(Number(process.env.REACT_APP_CHAINID)),
                    chainName: "StorChain Mainnet",
                    nativeCurrency: {
                      name: "StorChain Mainnet",
                      symbol: "STR",
                      decimals: 18,
                    },
                    rpcUrls: [process.env.REACT_APP_HTTP_RPC],
                    blockExplorerUrls: [process.env.REACT_APP_EXPLORER],
                  },
                ],
              });
            } catch (error) {
              console.log("error 1 =>", error);
            }
          }
          console.log("error 2 ", error);
        }
      } else {
        // If window.ethereum is not found then MetaMask is not installed
        alert(
          "MetaMask is not installed. Please install it to use this app: https://metamask.io/download.html"
        );
      }
    }
  };

  window.ethereum.on("accountsChanged", function (accounts) {
    setCurrentAccount("");
  });
  window.ethereum.on("chainChanged", (chainId) => {
    setCurrentAccount("");
  });

  let writeHandleChange = (mkey, skey, targetval) => {
    let { value, name } = targetval;
    let newReadFormValues = [...inputValues];
    let find = newReadFormValues.find((item) => item.mainkey === mkey && item.subkey === skey);
    find.name = value;
    setInputValues([...newReadFormValues]);
  };

  const writeHandleSubmit = async (mkey, name, signature) => {
    if (currentAccount) {
      let manReadData = [...manageWriteData];
      manReadData[mkey].hash = "";
      manReadData[mkey].error = "";
      manReadData[mkey].spinner = true;

      let paramLength = inputValues?.filter((item) => item.mainkey === mkey)?.length;
      let paramsarr = [];
      let errtemp = 0;
      for (let i = 0; i < paramLength; i++) {
        let val = inputValues?.find((item) => item.mainkey === mkey && item.subkey === i);
        if (val.name === "") {
          errtemp = 1;
        } else {
          manReadData[mkey].error = "";
          paramsarr.push(val.name);
        }
        if (errtemp === 1) {
          manReadData[mkey].error = "Provide Valid value!";
          manReadData[mkey].spinner = false;
        } else {
          manReadData[mkey].error = "";
        }
      }
      if (paramLength === paramsarr.length) {
        const web3 = await new Web3(process.env.REACT_APP_HTTP_RPC);
        var contract = new web3.eth.Contract(JSON.parse(contractDeta.abiCode), id);
        try {
          contract.methods[name](...paramsarr).send({ from: currentAccount }, (error, hash) => {
            if (error) {
              manReadData[mkey].error = error.message;
              manReadData[mkey].spinner = false;
              setManageWriteData([...manReadData]);
            } else {
              manReadData[mkey].hash = hash;
              manReadData[mkey].spinner = false;
              setManageWriteData([...manReadData]);
            }
          });
        } catch (error) {
          manReadData[mkey].error = error.message;
        }
      }
      setManageWriteData([...manReadData]);
    }
  };

  return (
    <div className="read-contract-container">
      <div id="header">
        <div class="d-sm-flex justify-content-between mb-3">
          {currentAccount ? (
            <p className="ml-3 mr-3 mb-1">
              <i
                id="connector"
                className="fa fa-circle mr-1 text-success"
                title
                data-toggle-second="tooltip"
                data-original-title="Connected"
              />
              <a id="connectStatus" className="forum-title">
                Connected - Web3 [
              </a>
              <a target="_blank" href={`/address/${currentAccount}`}>
                {currentAccount}
              </a>
              ]
            </p>
          ) : (
            <p class="ml-3 mr-3 mb-0">
              <i id="connector" class="fa fa-circle text-danger mr-1"></i>
              <a onClick={handleConnect} className="text-primary cursor-pointer">
                Connect to Web3
              </a>
            </p>
          )}
          {/* <span>
                        <a className="text-primary ml-2 cursor-pointer">[Expand all]</a>
                        <a className="text-primary ml-2 cursor-pointer" onClick={() => setCurrentAccount("")}>
                            [Reset]
                        </a>
                    </span> */}
        </div>
      </div>
      {writeData && writeData?.length
        ? writeData?.map((item, mainind) => {
            let ind = mainind + 1;
            return (
              <div className="tab-pane active mb-3" id="readContract" style={{ display: "" }}>
                <div
                  className="table-responsive"
                  style={{
                    visibility: "visible",
                    border: "1px solid rgba(0,0,0,.125)",
                    borderRadius: "calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0",
                  }}
                >
                  <div id="basicsAccordion">
                    <div className="card-header card-collapse" id="basicsHeadingThree">
                      <h5 className="mb-0">
                        <button
                          type="button"
                          className="btn btn-link btn-block d-flex justify-content-between card-btn collapsed py-2 px-2"
                          data-toggle="collapse"
                          data-target={`#readAcordianId${ind}`}
                          style={{ backgroundColor: "#f8f9fa" }}
                        >
                          <span>
                            {ind}. {item.name}
                          </span>
                          <span className="card-btn-arrow">
                            <span className="fas fa-arrow-down small" />
                          </span>
                        </button>
                      </h5>
                    </div>
                    <div
                      id={`readAcordianId${ind}`}
                      className="collapse"
                      aria-labelledby={`readAcordianId${ind}`}
                      data-parent="#basicsAccordion"
                    >
                      <div className="card-body p-3">
                        <div className="form-group">
                          {item.inputs.map((inputItem, subind) => {
                            let fieldval = inputValues.find(
                              (inpval) => inpval.mainkey === mainind && inpval.subkey === subind
                            ).name;
                            return (
                              <div className="form-group">
                                <label>
                                  {inputItem.name} ({inputItem.type})
                                </label>
                                <input
                                  type="text"
                                  className="form-control form-control-xs"
                                  name="input_1"
                                  value={fieldval}
                                  id="input_1_1"
                                  data-grp="g1"
                                  data-type="address"
                                  placeholder={` ${inputItem.name} (${inputItem.type})`}
                                  onChange={(e) => {
                                    writeHandleChange(mainind, subind, e.target);
                                  }}
                                />
                              </div>
                            );
                          })}
                          <button
                            type="button"
                            id="btn_1"
                            className="btn btn-primary btn-xs light badge-light"
                            onClick={() => writeHandleSubmit(mainind, item.name, item.signature)}
                            disabled={manageWriteData && manageWriteData[mainind]?.spinner ? true : false}
                          >
                            <span className="text-white">Query</span>
                            {manageWriteData && manageWriteData[mainind]?.spinner ? (
                              <span>
                                {" "}
                                <div
                                  className="spinner-border spinner-border-sm mx-2 "
                                  role="status"
                                ></div>{" "}
                              </span>
                            ) : (
                              ""
                            )}
                          </button>
                          &nbsp;
                          <span id="myanswer_1">
                            {manageWriteData && manageWriteData[mainind]?.error ? (
                              <span className="text-danger">{manageWriteData[mainind]?.error}</span>
                            ) : (
                              ""
                            )}
                          </span>
                          <span id="myanswer_1">
                            {manageWriteData && manageWriteData[mainind]?.hash ? (
                              <span className="text-danger">
                                <Link to={`/tx/${manageWriteData[mainind]?.hash}`}>
                                  {manageWriteData[mainind]?.hash}
                                </Link>
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : ""}
    </div>
  );
};

export default WriteInContract;
