import React, { useEffect, useState } from "react";
import Header2 from "../common/Header2";
import { Link, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Pagesize from "../common/Pagesize";
import Spinner from "../common/Spinner";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { IgniteContext } from "../IgniteContext";
import { useContext } from "react";
import NoEntryMessage from "../common/NoEntryMessage";
import Footer from "../common/Footer";
import FadingLoaderCard1 from "../common/ContentLoaders/LoaderBigLine";

const AllBlocks = () => {
    document.title = `${process.env.REACT_APP_NAME} chain Blocks | ${process.env.REACT_APP_NAME}Scan`;

    const { formatAddress, numberWithCommas, formatDate } = useContext(IgniteContext);

    const [searchParams] = useSearchParams();
    const miner = searchParams.get("m");
    const cpage = searchParams.get("p");

    const history = useNavigate();

    const [blocks, setBlocks] = useState([]);
    const [spinner, setSpinner] = useState(true);
    const [ageColChange, setAgeColChange] = useState(true);

    const [currentPage, setCurrentPage] = useState(cpage ? Number(cpage) : 1);
    const [totalBlocks, setTotalBlocks] = useState(0);
    const [pages, setPages] = useState(0);
    const [PageSize, setPageSize] = useState(10);

    const getAllBlocks = async () => {
        setSpinner(true);
        let param = {};
        if (miner) {
            param = {
                limit: PageSize,
                page: currentPage,
                address: miner,
            };
        } else {
            param = {
                limit: PageSize,
                page: currentPage,
            };
        }
        await axios
            .get(`${process.env.REACT_APP_BACKEND_URL}blocks`, {
                params: param,
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getallblocks : ", res.data.error);
                } else {
                    setBlocks(res.data.items);
                    setTotalBlocks(res.data.total);
                    setPages(res.data.pages);
                    setSpinner(false);
                }
            })
            .catch((err) => {
                console.log("error in getallblocks (catch) : ", err);
            });
    };

    useEffect(() => {
        getAllBlocks();
    }, [currentPage, PageSize]);

    const onNext = (page) => {
        if (page === pages) return false;
        setCurrentPage(currentPage + 1);
        if (miner) {
            history({
                pathname: "/blocks",
                search: "?m=" + miner + "&p=" + (currentPage + 1),
            });
        } else {
            history({
                pathname: "/blocks",
                search: "?p=" + (currentPage + 1),
            });
        }
    };

    const onPrevious = (page) => {
        if (page === 1) return false;
        setCurrentPage(currentPage - 1);
        if (miner) {
            history({
                pathname: "/blocks",
                search: "?m=" + miner + "&p=" + (currentPage - 1),
            });
        } else {
            history({
                pathname: "/blocks",
                search: "?p=" + (currentPage - 1),
            });
        }
    };

    return (
        <>
            <Header2 />
            <main id="content" role="main" style={{ minHeight: "800px" }}>
                <div className="container">
                    <div className="d-md-flex justify-content-between align-items-center py-3">
                        <div className="mb-1 mb-md-0">
                            <h1 className="h4 font-weight-normal mb-0">
                                Blocks&nbsp;
                                <span className="small text-secondary" />
                                {miner ? (
                                    <p className="mb-0 text-break small">
                                        <span className="small text-secondary">
                                            Validated by{" "}
                                            <Link to={`/address/${miner}`}>
                                                <i className="fal fa-user" /> {miner}
                                            </Link>
                                        </span>
                                    </p>
                                ) : (
                                    ""
                                )}
                            </h1>
                        </div>
                    </div>
                </div>
                <div className="container space-bottom-2">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive mb-2 mb-md-0">
                                {totalBlocks > 500000 ? (
                                    <p className="text-grey">
                                        More than &gt; <span className="text-black">{500000}</span> Blocks found
                                    </p>
                                ) : (
                                    <p className="text-grey">
                                        Total <span className="text-black">{numberWithCommas(totalBlocks)}</span> Blocks found
                                    </p>
                                )}
                                <table className="table table-hover" id="datatable">
                                    <thead className="thead-light">
                                        <tr className=" text-black">
                                            <th scope="col">Id</th>
                                            <th scope="col">Height</th>
                                            <th scope="col">
                                                {ageColChange ? (
                                                    <OverlayTrigger
                                                        placement="top"
                                                        trigger="hover"
                                                        overlay={<Tooltip>Click to show Datetime Format</Tooltip>}
                                                    >
                                                        <Link onClick={() => setAgeColChange(!ageColChange)} id="lnkAgeDateTime">
                                                            Age
                                                        </Link>
                                                    </OverlayTrigger>
                                                ) : (
                                                    <OverlayTrigger
                                                        placement="top"
                                                        trigger="hover"
                                                        overlay={<Tooltip>Click to show Age Format</Tooltip>}
                                                    >
                                                        <Link onClick={() => setAgeColChange(!ageColChange)}>Date Time (UTC)</Link>
                                                    </OverlayTrigger>
                                                )}
                                            </th>
                                            <th scope="col">Txn</th>
                                            <th scope="col" width={250}>
                                                Validator
                                            </th>
                                            <th scope="col" width={250}>
                                                Gas Used
                                            </th>
                                            <th scope="col" width={200}>
                                                Gas Limit
                                            </th>
                                            <th scope="col">Rewards</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {spinner ? (
                                            <>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                </tr>
                                            </>
                                        ) : blocks && blocks?.length ? (
                                            blocks.map((item, index) => {
                                                const ind = currentPage * PageSize - PageSize + (index + 1);
                                                const gasUsedPer = (Number(item.gasUsed) * 100) / Number(item.gasLimit);
                                                return (
                                                    <tr>
                                                        <td>{ind}</td>
                                                        <td className="">
                                                            <Link to={`/block/${item.hieght}`} className="text-blue">
                                                                {" "}
                                                                {item.hieght}
                                                            </Link>
                                                        </td>
                                                        <td className="showAge">
                                                            {ageColChange ? (
                                                                item.age
                                                            ) : (
                                                                <span rel="tooltip" data-toggle="tooltip" data-placement="bottom">
                                                                    {formatDate(item.timestamp)}
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td className="">{item.txn}</td>
                                                        <td className="">
                                                            <OverlayTrigger placement="top" trigger="hover" overlay={<Tooltip>{item.miner}</Tooltip>}>
                                                                <a href={`/address/${item.miner}`}>{formatAddress(item.miner)}</a>
                                                            </OverlayTrigger>
                                                        </td>
                                                        <td width="0px">
                                                            {numberWithCommas(item.gasUsed)}{" "}
                                                            <span className="small text-secondary">({Number(gasUsedPer).toFixed(2)} %)</span>
                                                            <div className="progress mt-1" style={{ height: "2px" }}>
                                                                <div
                                                                    className="progress-bar bg-primary"
                                                                    role="progressbar"
                                                                    style={{ width: `${gasUsedPer}%` }}
                                                                    aria-valuenow={item.gasUsed}
                                                                    aria-valuemin={0}
                                                                    aria-valuemax={100}
                                                                />
                                                            </div>
                                                        </td>
                                                        <td className="">{numberWithCommas(item.gasLimit)}</td>
                                                        <td className="showAge">
                                                            {"0 reward"}&nbsp;{process.env.REACT_APP_TICKER}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <NoEntryMessage message={process.env.REACT_APP_BLANK_DATA_MESSAGE} />
                                        )}
                                    </tbody>
                                </table>
                                {blocks && blocks?.length ? (
                                    <div class="row w-100 mt-3">
                                        <div class="col-md-6 mt-3">
                                            <Pagesize setPageSize={setPageSize} PageSize={PageSize} />
                                        </div>
                                        <div class="col-md-6 mt-3">
                                            <div className="float-right mr-3">
                                                <div class="row">
                                                    <button
                                                        class={currentPage === 1 ? "page-link disabled" : "page-link"}
                                                        onClick={() => {
                                                            setCurrentPage(1);
                                                            miner
                                                                ? history({
                                                                      pathname: "/blocks",
                                                                      search: "?m=" + miner + "&p=" + 1,
                                                                  })
                                                                : history({
                                                                      pathname: "/blocks",
                                                                      search: "?p=" + 1,
                                                                  });
                                                        }}
                                                        disabled={currentPage === 1}
                                                    >
                                                        First
                                                    </button>
                                                    <button
                                                        class={currentPage === 1 ? "page-link disabled" : "page-link"}
                                                        onClick={() => onPrevious(currentPage)}
                                                        disabled={currentPage === 1}
                                                    >
                                                        <i className="fa fa-angle-left" />
                                                    </button>
                                                    <div class="page-link-badge" disabled>
                                                        {" "}
                                                        {`${currentPage} of ${pages}`}
                                                    </div>
                                                    <button
                                                        class={currentPage === pages ? "page-link disabled" : "page-link"}
                                                        onClick={() => onNext(currentPage)}
                                                        disabled={currentPage === pages}
                                                    >
                                                        <i className="fa fa-angle-right" />
                                                    </button>
                                                    <button
                                                        class={currentPage === pages ? "page-link disabled" : "page-link"}
                                                        onClick={() => {
                                                            setCurrentPage(pages);
                                                            miner
                                                                ? history({
                                                                      pathname: "/blocks",
                                                                      search: "?m=" + miner + "&p=" + pages,
                                                                  })
                                                                : history({
                                                                      pathname: "/blocks",
                                                                      search: "?p=" + pages,
                                                                  });
                                                        }}
                                                        disabled={currentPage === pages}
                                                    >
                                                        Last
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default AllBlocks;
