import React, { useContext } from "react";
import { IgniteContext } from "../IgniteContext";

function ContactUsHeading() {
    const { selectValue } = useContext(IgniteContext);

    return (
        <>
            <div class="container">
                <div class="d-flex justify-content-between align-items-center border-bottom py-3">
                    {(() => {
                        if (selectValue === "1" || selectValue === 1) {
                            return (
                                <>
                                    <h1 class="h4 mb-0">General Inquiry</h1>
                                </>
                            );
                        } else if (selectValue === "2" || selectValue === 2) {
                            return (
                                <>
                                    <h1 class="h4 mb-0">Support / Technical Issue</h1>
                                </>
                            );
                        } else if (selectValue === "3" || selectValue === 3) {
                            return (
                                <>
                                    <h1 class="h4 mb-0">Name Tagging</h1>
                                </>
                            );
                        } else {
                            return (
                                <>
                                    <h1 class="h4 mb-0">Contact Us</h1>
                                </>
                            );
                        }
                    })()}

                    <nav aria-label="breadcrumb">
                        {(() => {
                            if (selectValue === "1" || selectValue === 1) {
                                return (
                                    <>
                                        <ol class="breadcrumb small p-0 mb-0">
                                            <li class="breadcrumb-item active">Contact Us / General Inquiry</li>
                                        </ol>
                                    </>
                                );
                            } else if (selectValue === "2" || selectValue === 2) {
                                return (
                                    <>
                                        <ol class="breadcrumb small p-0 mb-0">
                                            <li class="breadcrumb-item active">Contact Us / Support & Technical Issue</li>
                                        </ol>
                                    </>
                                );
                            } else if (selectValue === "3" || selectValue === 3) {
                                return (
                                    <>
                                        <ol class="breadcrumb small p-0 mb-0">
                                            <li class="breadcrumb-item active">Contact Us / Name Tagging</li>
                                        </ol>
                                    </>
                                );
                            } else {
                                return (
                                    <>
                                        <ol class="breadcrumb small p-0 mb-0">
                                            <li class="breadcrumb-item active">Contact Us</li>
                                        </ol>
                                    </>
                                );
                            }
                        })()}
                    </nav>
                </div>
            </div>
        </>
    );
}

export default ContactUsHeading;
