import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

const Gasprice = ({ price }) => {
    const popoverTop8 = (
        <Popover id="popover-basic">
            <Popover.Body>
                Cost per unit of gas specified for the transaction, in {process.env.REACT_APP_TICKER} and Gwei. The higher the gas price the higher
                chance of getting included in a block.
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <div className="row">
                <div className="col-md-3 mb-1 bs  h6 text-blueish">
                    <OverlayTrigger style={{ padding: "100px 0" }} trigger="hover" placement="top" overlay={popoverTop8}>
                        <i class="fal fa-question-circle text-secondary mr-1" aria-hidden="true"></i>
                    </OverlayTrigger>{" "}
                    Gas Price
                </div>
                <div className="col-md-4 mb-1 bs text-dark bs">
                    {price?.igt} <span> {process.env.REACT_APP_TICKER}</span> ({price?.gwei} Gwei)
                </div>
            </div>
            <hr />
        </>
    );
};

export default Gasprice;
