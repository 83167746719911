import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function TokenVerifyStatus() {
    const navigate = useNavigate();
    useEffect(() => {
        const redirectState1 = JSON.parse(sessionStorage.getItem("a1BDc45Adfad"));
        sessionStorage.removeItem("a1BDc45Adfa");
        sessionStorage.setItem("a1BDc45Adfad", JSON.stringify({ ...redirectState1, state: 45 }));
    }, []);

    // window.onpopstate = () => {
    //     window.location.reload();
    // };

    return (
        <section className="section token_update_bg">
            <div className="container min_height">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-6 col-12 mt-5 mb-0 text-center">
                        <div className="p-0">
                            <h5 className="title mb-0"> Verify Address Owenership </h5>
                            <small className="text-muted">
                                Find out more about <span className="text-primary fw-bold"> verify address owenership </span>
                            </small>
                        </div>
                        <div className="component-wrapper rounded shadow mt-5 application_form_bg">
                            {/* <div className="p-4"> */}
                            <div className="row mx-3">
                                <div className="col-lg-12 mt-2 pt-2">
                                    <div className="border-0 overflow-hidden">
                                        <div className="row g-0 d-flex justify-content-center flex-column">
                                            <small
                                                className="text-center text-dark text-wrap mb-2 fw-500 mt-4 verify_address_text badge bg-soft-success"
                                                style={{ padding: "2%" }}
                                            >
                                                <span className="mt-2 mb-5 fs-6"> Request Submitted successfully</span>
                                            </small>
                                            <div className="row mt-3 mb-4">
                                                <div className="col-sm-12">
                                                    <a href="/">
                                                        {" "}
                                                        <input
                                                            type="button"
                                                            id="submit"
                                                            name="send"
                                                            className="btn btn-primary sign_msg_button mx-2"
                                                            defaultValue="Home"
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TokenVerifyStatus;
