import React from "react";

const Spinner = () => {
    return (
        <>
            <div className="col-md-12 mt-5 mb-5" style={{ display: "flex", justifyContent: "center" }}>
                <div className="loader"></div>
            </div>
        </>
    );
};

export default Spinner;
