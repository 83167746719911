import React from "react";
import Header2 from "../common/Header2";
import Accounts from "./Accounts";

const BlockChain = () => {
    return (
        <>
            <Header2 />
            <main id="main" role="main" style={{ minHeight: "800px" }}>
                <Accounts />
            </main>
        </>
    );
};

export default BlockChain;
