import React, { useContext } from "react";
import { IgniteContext } from "../../IgniteContext";
import { OverlayTrigger, Popover } from "react-bootstrap";

const Timestamp = ({ time, timeAgo, date }) => {
    const popoverTop15 = (
        <Popover id="popover-basic">
            <Popover.Body>
                And here's some <strong>amazing</strong> content. It's very engaging. right?
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <div className="row">
                <div className="col-md-3 mb-1 bs  h6 text-blueish">
                    <OverlayTrigger style={{ padding: "100px 0" }} trigger="hover" placement="top" overlay={popoverTop15}>
                        <i class="fal fa-question-circle text-secondary mr-1" aria-hidden="true"></i>
                    </OverlayTrigger>
                    Timestamp
                </div>
                <div className="col-md-4 mb-1 bs text-dark bs">
                    {timeAgo && time && date ? <i class="fal fa-clock small text-secondary"></i> : ""}
                    {timeAgo && time && date ? (
                        " " + timeAgo + " " + "(" + new Date(date * 1000).toUTCString() + ")"
                    ) : (
                        <i class="text-secondary">(pending)</i>
                    )}
                </div>
            </div>
            <hr />
        </>
    );
};

export default Timestamp;
