import React, { useEffect, useState } from "react";
import axios from "axios";
import Header2 from "../common/Header2";
import Overview from "./Overview";
import MoreInfo from "./MoreInfo";
import Lists from "./Lists";
import delay from "delay";
import "brace/mode/javascript";
import "brace/theme/monokai";
import { useParams } from "react-router-dom";
import Footer from "../common/Footer";
import FadingLoaderCard1 from "../common/ContentLoaders/LoaderBigLine";
import Web3 from "web3";

function TokenDetails() {
  const { id } = useParams();

  const [state, setState] = useState("");
  const [transferOnId, setTransferOnId] = useState(0);

  const [tokenDetails, setTokenDetails] = useState(null);

  const [tokenSpinner, setTokenSpinner] = useState(true);

  const getTokenDetails = async () => {
    setTokenSpinner(true);
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}token/${id}`)
      .then((res) => {
        if (res.data.error) {
          console.log("error in token details : ", res.data.error);
        } else {
          setTokenDetails(res.data);
          setTokenSpinner(false);
        }
      })
      .catch((err) => {
        console.log("error in token details (catch) : ", err);
      });
  };

  useEffect(() => {
    getTokenDetails();
  }, [id]);

  const connectweb3 = () => {
    const params = [
      {
        chainId: Web3.utils.toHex(Number(process.env.REACT_APP_CHAINID)),
        chainName: "StorChain Mainnet",
        nativeCurrency: {
          name: "StorChain Mainnet",
          symbol: "STR",
          decimals: 18,
        },
        rpcUrls: [process.env.REACT_APP_HTTP_RPC],
        blockExplorerUrls: [process.env.REACT_APP_EXPLORER],
      },
    ];
    window.ethereum.request({ method: "wallet_addEthereumChain", params });
  };

  async function copyaddress() {
    setState(true);
    await delay(1000);
    setState(false);
  }

  // const copyContent = async (type) => {
  //     if (type === "sourceCode") {
  //         setCopySourceCode(true);
  //         await delay(1000);
  //         setCopySourceCode(false);
  //     } else if (type === "abi") {
  //         setCopyAbi(true);
  //         await delay(1000);
  //         setCopyAbi(false);
  //     } else if (type === "bytecode") {
  //         setCopyByteCode(true);
  //         await delay(1000);
  //         setCopyByteCode(false);
  //     } else {
  //         return false;
  //     }
  // };
  return (
    <>
      <Header2 />
      <main id="content" role="main" style={{ minHeight: "800px" }}>
        <div className="container space-1">
          <div className="container py-3">
            <div className="d-flex flex-wrap justify-content-between align-items-center">
              <div className="mb-3 mb-lg-0">
                <h1 className="h4 mb-0">
                  <img
                    style={{ height: "35px", width: "35px" }}
                    id="icon"
                    className="u-xs-avatar-token rounded mt-n1 mr-1 image.logo-size"
                    src={
                      tokenDetails &&
                      (tokenDetails?.status === "true" || tokenDetails?.status === true) &&
                      tokenDetails?.logo
                        ? process.env.REACT_APP_TOKEN_LOGO_PATH + tokenDetails?.logo
                        : "/asset/images/48.png"
                    }
                    alt=""
                  />
                  <span
                    id="mainaddress"
                    className="text-size-address ttext-black font-weight-bold text-break mr-1"
                    data-placement="top"
                  >
                    {tokenSpinner ? (
                      <>
                        <FadingLoaderCard1 x="" y="3" rx="3" ry="3" width="70" height="15" />{" "}
                      </>
                    ) : (
                      tokenDetails?.name + " " + `(${tokenDetails?.symbol})`
                    )}
                  </span>
                </h1>

                <div className="mt-1" />
              </div>

              <div className="d-flex flex-wrap" />
            </div>
          </div>
          <div className="container w-100">
            <div className="row mb-4">
              <Overview
                tokenDetails={tokenDetails && tokenDetails}
                transferOnId={transferOnId}
                tokenSpinner={tokenSpinner}
              />

              <MoreInfo
                copyaddress={copyaddress}
                state={state}
                tokenDetails={tokenDetails && tokenDetails}
                id={id}
                tokenSpinner={tokenSpinner}
              />
            </div>
          </div>
          <Lists tokenDetails={tokenDetails} setTransferOnId={setTransferOnId} />
        </div>
      </main>
      <Footer />
    </>
  );
}

export default TokenDetails;
