import React from "react";
import { Link } from "react-router-dom";

import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";

const Block = ({ block, blockConfirmation }) => {
    const popoverTop16 = (
        <Popover id="popover-basic">
            {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
            <Popover.Body>
                The number of the block in which the transaction was recorded. Block confirmation indicate how many blocks since the transaction is
                validated.
            </Popover.Body>
        </Popover>
    );
    return (
        <>
            <div className="row">
                <div className="col-md-3 mb-1 bs  h6 text-blueish">
                    <OverlayTrigger style={{ padding: "100px 0" }} trigger="hover" placement="top" overlay={popoverTop16}>
                        <i class="fal fa-question-circle text-secondary mr-1" aria-hidden="true"></i>
                    </OverlayTrigger>
                    Block
                </div>
                <div className="col-md-4 mb-1 bs text-info bs" key={block}>
                    <div className="bs text-info">
                        <Link to={`/block/${block}`} className="text-blue">
                            {block ? <span className="text-blue"> {block}</span> : <i class="text-secondary">(pending)</i>}
                        </Link>
                        {block !== null ? (
                            <OverlayTrigger placement="top" trigger="hover" overlay={<Tooltip>Number of blocks validated since</Tooltip>}>
                                <span className="u-label u-label--xs u-label--badge-in u-label--secondary ml-1">
                                    {blockConfirmation} Block Confirmations
                                </span>
                            </OverlayTrigger>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
            <hr />
        </>
    );
};

export default Block;
