import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IgniteContext } from "../IgniteContext";
import NoEntryMessage from "../common/NoEntryMessage";

const InternalTxs = ({ internalTx, fromAddress, toAddress }) => {
    const { numberWithCommas } = useContext(IgniteContext);
    const [internaltsxType, setInternaltxsType] = useState(false);
    const [simpleTxs, setSimpleTxs] = useState([]);
    const [advancedTxs, setAdvancedTxs] = useState([]);
    const [internalTransactions, setInternalTransacions] = useState([]);

    useEffect(() => {
        // const advancedTx = internalTx.filter((item) => !item.isSimple);
        // setSimpleTxs(simpleTx);
        // setAdvancedTxs(advancedTx);
        if (!internaltsxType) {
            const simpleTx = internalTx.filter((item) => item.isSimple);
            setInternalTransacions([...simpleTx]);
        } else {
            setInternalTransacions([...internalTx]);
        }
    }, [internaltsxType]);

    return (
        <div className="card-body mt-1">
            <div className="mb-3 d-flex justify-content-between">
                <div>
                    <i className="fa fa-puzzle-piece text-secondary" /> The contract call <strong>From</strong>{" "}
                    <span
                        data-toggle="tooltip"
                        title
                        className="hash-tag text-truncate "
                        data-original-title="0xc779acead2eb508621c20e906eb27daf495babe8"
                    >
                        <OverlayTrigger placement="top" trigger="hover" overlay={<Tooltip>{fromAddress}</Tooltip>}>
                            <Link to={`/address/${fromAddress}`}>{fromAddress}</Link>
                        </OverlayTrigger>
                    </span>{" "}
                    <strong>To</strong>{" "}
                    <span
                        data-toggle="tooltip"
                        title
                        className="hash-tag text-truncate "
                        data-original-title="0x7ce2e0c90352c9fb1083a4f228bb97c2c7621d1f"
                    >
                        <OverlayTrigger placement="top" trigger="hover" overlay={<Tooltip>{toAddress?.address}</Tooltip>}>
                            <Link to={`/address/${toAddress?.address}`}>{toAddress?.address}</Link>
                        </OverlayTrigger>
                    </span>{" "}
                    produced {internalTransactions.length} Internal Transaction
                </div>
                <div className="ml-auto float-right" id="divswitch" data-container="body" data-toggle="popover" data-placement="top">
                    <div className="d-flex">
                        <label className="checkbox-switch mb-0" id="labelswitch" data-toggle="tooltip" data-placement="top" data-original-title title>
                            <input
                                type="checkbox"
                                className="checkbox-switch__input"
                                id="customSwitch1"
                                onChange={(e) => setInternaltxsType(e.target.checked)}
                            />
                            <span className="checkbox-switch__slider" />
                        </label>
                        <span className="ml-1" id="toggletext">
                            <b>{internaltsxType ? "Advanced" : "Simple"}</b>
                        </span>
                    </div>
                </div>
            </div>
            <div id="ContentPlaceHolder1_divinternaltable" className="table-responsive mb-2 mb-md-0">
                <table className="table table-hover table-align-middle">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Type Trace Address</th>
                            <th scope="col" width={180}>
                                From
                            </th>
                            <th scope="col" width={30} />
                            <th scope="col">To</th>
                            <th scope="col">Value</th>
                            <th scope="col">Gas Limit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {internalTransactions.length ? (
                            internalTransactions?.map((item, index) => {
                                let counts = [];
                                for (let i = 0; i < item.countOne - 1; i++) {
                                    counts.push(<img className="mt-n1" width={8} src="/asset/images/shape-2.svg?v=0.01" />);
                                }
                                return (
                                    <tr>
                                        <td>
                                            <img className="mt-n1" width={8} src="/asset/images/shape-1.svg?v=0.01" />{" "}
                                            {counts.map((item) => {
                                                return <>{item}&nbsp;</>;
                                            })}
                                            <i class="fa fa-check-circle text-success small mr-1"></i>
                                            {item.typeTraceAddress}
                                        </td>
                                        <td>
                                            <span className="hash-tag text-truncate">
                                                <OverlayTrigger placement="top" trigger="hover" overlay={<Tooltip>{item.from}</Tooltip>}>
                                                    <Link to={`/address/${item.from}`} className="hash-tag text-truncate">
                                                        {item.from}
                                                    </Link>
                                                </OverlayTrigger>
                                            </span>
                                        </td>
                                        <td>
                                            <span className="btn btn-xs btn-icon btn-soft-success rounded-circle">
                                                <i className="fa fa-long-arrow-alt-right btn-icon__inner" />
                                            </span>
                                        </td>
                                        <td>
                                            {fromAddress.toLowerCase() === item.to.toLowerCase() ||
                                            toAddress.address.toLowerCase() === item.to.toLowerCase() ? (
                                                ""
                                            ) : (
                                                <OverlayTrigger placement="top" trigger="hover" overlay={<Tooltip>An External Address</Tooltip>}>
                                                    <i class="fa fa-sign-in-alt text-secondary small mr-1"></i>
                                                </OverlayTrigger>
                                            )}
                                            <span className="hash-tag text-truncate">
                                                <OverlayTrigger placement="top" trigger="hover" overlay={<Tooltip>{item.to}</Tooltip>}>
                                                    <Link to={`/address/${item.to}`} className="hash-tag text-truncate">
                                                        {item.to}
                                                    </Link>
                                                </OverlayTrigger>
                                            </span>
                                        </td>
                                        <td>
                                            {item.value} {process.env.REACT_APP_TICKER}
                                        </td>
                                        <td>{numberWithCommas(item.gasLimit)}</td>
                                    </tr>
                                );
                            })
                        ) : (
                            <NoEntryMessage message={process.env.REACT_APP_BLANK_DATA_MESSAGE} />
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default InternalTxs;
