// import { CopyToClipboard } from 'react-copy-to-clipboard';
// import delay from 'delay';
import React from "react";

import { OverlayTrigger, Popover } from "react-bootstrap";

const Status = ({ status }) => {
    const popoverTop3 = (
        <Popover id="popover-basic">
            <Popover.Body>The status of the transaction.</Popover.Body>
        </Popover>
    );

    return (
        <>
            <div className="row">
                <div className="col-md-3 mt-1 bs text-blueish" key={status}>
                    <OverlayTrigger style={{ padding: "100px 0" }} trigger="hover" placement="top" overlay={popoverTop3}>
                        <i class="fal fa-question-circle text-secondary mr-1" aria-hidden="true"></i>
                    </OverlayTrigger>
                    Status
                </div>
                <div className="col-md-4 mt-1 text-dark">
                    <p className="text text-success mb-0">
                        {status === true ? (
                            <div class="badge badge-green" style={{ display: "inline-block" }}>
                                <i class="fa fa-check-circle badge-text-icon" aria-hidden="true" />
                                <span class="badge-text ml-1">Success</span>
                            </div>
                        ) : status === false ? (
                            <div class="badge badge-red" style={{ display: "inline-block" }}>
                                <i class="fa fa-times-circle badge-text-icon-red" aria-hidden="true" />
                                <span class="badge-text-red ml-1 ">Failed</span>
                            </div>
                        ) : (
                            <span className="u-label u-label--sm u-label--secondary rounded">
                                <i className="fa fa-dot-circle mr-1" /> Pending
                            </span>
                        )}
                    </p>
                </div>
            </div>
            <hr />
        </>
    );
};

export default Status;
