import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { LineChart, Line, Tooltip, XAxis, YAxis } from "recharts";
import { IgniteContext } from "../IgniteContext";

const TxChart2 = () => {
  const {theme} = useContext(IgniteContext);
  const [options, setOptions] = useState(null);
  const getBlocksData = async () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}last10dtxs`)
      .then((res) => {
        if (res.data.error) {
          console.log("error in home blocks : ", res.data.error);
        } else {
          let data = res.data.data;

          setOptions(data);
        }
      })
      .catch((err) => {
        console.log("error in home blocks (catch): ", err);
      });
  };

  useEffect(() => {
    getBlocksData();
  }, []);

  return (
    <div style={{ padding: "5px" }}>
      <p style={{ textAlign: "center" }}>Transaction History in 10 days</p>
      <LineChart width={280} height={90} data={options} style={{ fontSize: "0.6rem" }}>
        <Line type="monotone" dataKey="transactions" stroke="#8884d8" />
        {/* <CartesianGrid stroke="#ccc" /> */}
        <XAxis dataKey="date" opacity="0.5" />
        {/* <YAxis stroke="transparent" strokeDasharray="0 0" /> */}
        <Tooltip contentStyle={{backgroundColor: theme === "normal" ? "#fff" : "#181818"}} />
      </LineChart>
    </div>
  );
};

export default TxChart2;
