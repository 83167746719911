import React, { useContext } from "react";
import Header2 from "../common/Header2";
import Footer from "../common/Footer";
import { IgniteContext } from "../IgniteContext";

const BrandAssets = () => {
  const { theme } = useContext(IgniteContext);
  return (
    <>
      <Header2 />
      <main id="content" role="main">
        <div className="d-lg-flex">
          <div className="container d-lg-flex align-items-lg-center space-2 space-sm-3 space-lg-0">
            <div className="w-lg-80 mx-lg-auto">
              <div className="card">
                <div className="position-relative info-page-title space-1 space-md-2 p-4 px-md-7 px-md-9 rounded-lg">
                  <h1 className="text-white text-center">Brand Assets</h1>
                  {/* <p className="text-white-70">Last updated: 25 September, 2023</p> */}
                  <figure className="position-absolute right-0 bottom-0 left-0 mb-n1">
                    {theme === "normal" ? (
                      <svg
                        preserveAspectRatio="none"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 1920 100.1"
                        width="100%"
                        height={32}
                      >
                        <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                      </svg>
                    ) : (
                      <svg
                        preserveAspectRatio="none"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 1920 100.1"
                        width="100%"
                        height={32}
                      >
                        <path fill="#212121" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                      </svg>
                    )}
                  </figure>
                </div>
                <div className="card-body p-4 p-md-9">
                  <div className="mb-5">
                    <p>
                      Welcome to the STOR Scan Brand Assets page. Here, you'll find a collection of resources
                      to help you represent STOR Scan accurately and professionally. We encourage you to use
                      these brand assets in a manner that aligns with our brand guidelines and values.
                    </p>
                  </div>
                  <div className="mb-5">
                    <p>
                      <strong>Logo Usage Guidelines</strong>
                    </p>
                    <p>
                      <ul className="text-secondary">
                        <li>
                          Please use the STOR Scan logo in a manner that does not compromise its visibility,
                          clarity, or integrity.
                        </li>
                        <li>
                          Maintain adequate spacing around the logo to ensure it stands out and remains
                          visually appealing.
                        </li>
                        <li>
                          Please do not alter, modify, or manipulate the logo in any way that distorts its
                          proportions or colors.
                        </li>
                        <li>
                          Always use this page's official, high-resolution logo files for the best quality.
                        </li>
                      </ul>
                    </p>
                  </div>
                  <div className="mb-5">
                    <p>
                      <strong>STOR Scan Icons</strong>
                    </p>
                    <p>
                      Our icons are designed to be clear and easily recognizable, adding a touch of visual
                      sophistication to your content.
                    </p>
                  </div>
                  <div className="mb-5">
                    <p>
                      <strong>Brand Guidelines</strong>
                    </p>
                    <p>
                      To ensure consistency and maintain the integrity of the STOR Scan brand, please adhere
                      to the following guidelines:
                    </p>
                    <p>
                      <strong>Logo Usage: </strong>Use the official STOR Scan logo provided on this page. Do
                      not alter the logo in any way.
                    </p>
                    <p>
                      <strong>Color Scheme: </strong>When using our brand colors, refer to the color palette
                      provided to ensure consistency.
                    </p>
                    <p>
                      <strong>Typography: </strong>Utilize the specified fonts for headers and body text in
                      your content. Iconography: Incorporate our icons to enhance visual appeal where
                      appropriate.
                    </p>
                    <p>
                      <strong>Iconography: </strong>
                      Incorporate our icons to enhance visual appeal where appropriate.
                    </p>
                    <p>
                      <strong>Spacing: </strong>Maintain proper spacing around our logo to allow it to stand
                      out. Transparent Background: Use the logo with a transparent background when placing it
                      on colored or textured backgrounds.
                    </p>
                    <p>
                      <strong>Respect Brand Integrity: </strong>Do not use our brand assets in a way that
                      misrepresents or tarnished the reputation of STOR Scan.
                    </p>
                  </div>
                  <div className="mb-5">
                    <p>
                      <strong>Contact</strong>
                    </p>
                    <p>
                      If you have any questions, require additional brand assets, or need guidance on using
                      our materials, don't hesitate to contact our Brand Team. We are here to assist you and
                      ensure our brand is consistently represented with integrity.
                    </p>
                    <p>
                      Thank you for being so cooperative in upholding the STOR Scan brand standards. Together,
                      we can create a unified and strong brand identity for STOR Scan.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default BrandAssets;
