import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Pagesize from "../common/Pagesize";
import Spinner from "../common/Spinner";
import Header2 from "../common/Header2";
import { IgniteContext } from "../IgniteContext";
import Web3 from "web3";
import Footer from "../common/Footer";
import NoEntryMessage from "../common/NoEntryMessage";
import FadingLoaderCard1 from "../common/ContentLoaders/LoaderBigLine";

const BlockTransactions = ({ block }) => {
    document.title = `${process.env.REACT_APP_NAME} chain Transactions Information | ${process.env.REACT_APP_NAME}Scan`;

    const { formatAddress, formatHash, formatDate, formatMethod, verifyAndGetUser } = useContext(IgniteContext);
    const { encusername } = verifyAndGetUser();
    const location = useLocation();
    const pagefromurl = location.search.split("p=")[1];
    const cpage = pagefromurl ? Number(pagefromurl) : 1;

    const history = useNavigate();

    const [ageColChange, setAgeColChange] = useState(true);

    const [currentPage, setCurrentPage] = useState(cpage);
    const [allTransaction, setAllTransaction] = useState([]);
    const [totalTsx, setTotalTsx] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [spinner, setSpinner] = useState(true);
    const [PageSize, setPageSize] = useState(10);

    const getAllTransactions = async () => {
        setSpinner(true);
        await axios
            .get(`${process.env.REACT_APP_BACKEND_URL}txs?limit=${PageSize}&page=${currentPage}&blockNumber=${block}`, {
                headers: {
                    etoken: encusername,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getAllTransactions : ", res.data.error);
                } else {
                    setAllTransaction(res.data.items);
                    setTotalTsx(res.data.total);
                    setTotalPages(res.data.pages);
                    setSpinner(false);
                }
            })
            .catch((err) => {
                console.log("error in getAllTransactions (catch) : ", err);
            });
    };

    useEffect(() => {
        getAllTransactions();
    }, []);

    useEffect(() => {
        getAllTransactions();
    }, [currentPage]);

    const onNext = (page) => {
        if (page === totalPages) return false;
        setCurrentPage(currentPage + 1);
        history({
            pathname: "/txs",
            search: "?block=" + block + "&p=" + (currentPage + 1),
        });
    };

    const onPrevious = (page) => {
        if (page === 1) return false;
        setCurrentPage(currentPage - 1);
        history({
            pathname: "/txs",
            search: "?block=" + block + "&p=" + (currentPage - 1),
        });
    };

    return (
        <>
            <Header2 />
            <main id="content" role="main" style={{ minHeight: "800px" }}>
                <div className="container">
                    <div className="d-md-flex justify-content-between align-items-center py-3">
                        <div className="d-md-flex justify-content-between align-items-center py-3">
                            <div className="mb-1 mb-md-0">
                                <h1 className="h4 mb-0">
                                    Transactions&nbsp;
                                    <span className="small text-secondary" />
                                    <p className="mb-0 text-break small">
                                        <span id="ContentPlaceHolder1_divPageTitle" className="small text-secondary">
                                            For Block{" "}
                                            <Link to={`/block/${block}`}>
                                                <span className="text-primary text-break">{block}</span>
                                            </Link>
                                        </span>
                                    </p>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container space-bottom-2">
                    <div className="card">
                        <div className="card-body">
                            <span class="d-flex align-items-center mb-2 text-secondary">
                                <i id="spinwheel" class="fa fa-spin fa-spinner fa-1x fa-pulse mr-1" style={{ display: "none" }}></i>
                                Total {totalTsx} transactions found.
                            </span>

                            <div className="table-responsive mb-2 mb-md-0">
                                <table className="table table-hover" id="datatable">
                                    <thead className="thead-light">
                                        <tr className=" text-black">
                                            <th>ID</th>
                                            <th>Hash</th>
                                            <th>
                                                <div className="d-flex align-items-center">
                                                    <div className="mr-2">
                                                        Method{" "}
                                                        <OverlayTrigger
                                                            placement="top"
                                                            trigger="hover"
                                                            overlay={
                                                                <Tooltip>
                                                                    Function executed based on decoded input data. For unidentified functions, method
                                                                    ID is displayed instead.
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <i
                                                                className="fal fa-info-circle"
                                                                data-toggle="tooltip"
                                                                data-html="true"
                                                                data-boundary="viewport"
                                                                title
                                                            />
                                                        </OverlayTrigger>
                                                    </div>
                                                </div>
                                            </th>
                                            <th>Block</th>
                                            <th>
                                                {ageColChange ? (
                                                    <OverlayTrigger
                                                        placement="top"
                                                        trigger="hover"
                                                        overlay={<Tooltip>Click to show Datetime Format</Tooltip>}
                                                    >
                                                        <Link onClick={() => setAgeColChange(!ageColChange)} id="lnkAgeDateTime">
                                                            Age
                                                        </Link>
                                                    </OverlayTrigger>
                                                ) : (
                                                    <OverlayTrigger
                                                        placement="top"
                                                        trigger="hover"
                                                        overlay={<Tooltip>Click to show Age Format</Tooltip>}
                                                    >
                                                        <Link onClick={() => setAgeColChange(!ageColChange)}>Date Time (UTC)</Link>
                                                    </OverlayTrigger>
                                                )}
                                            </th>
                                            <th>From</th>
                                            <th>To</th>
                                            <th>Value</th>
                                            <th>Fee</th>
                                        </tr>
                                    </thead>
                                    <tbody id="fetch">
                                        {spinner ? (
                                            <>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>

                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                    </td>
                                                </tr>
                                            </>
                                        ) : (
                                            <>
                                                {allTransaction && allTransaction.length ? (
                                                    allTransaction.map((item, index) => {
                                                        const value = Web3.utils.fromWei(
                                                            item.value.toLocaleString("fullwide", { useGrouping: false })
                                                        );
                                                        const ind = currentPage * 10 - 10 + (index + 1);
                                                        return (
                                                            <tr>
                                                                <td>{ind}</td>
                                                                <td>
                                                                    {item.status ? "" : <i className="fa fa-exclamation-circle text-danger mr-1"></i>}
                                                                    <Link to={`/tx/${item.hash}`} className="text-blue">
                                                                        {formatHash(item.hash)}
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        trigger="hover"
                                                                        overlay={
                                                                            <Tooltip>{item.method.name ? item.method.name : item.method.id}</Tooltip>
                                                                        }
                                                                    >
                                                                        <span
                                                                            style={{ minWidth: "68px" }}
                                                                            className="u-label u-label--xs u-label--info rounded text-dark text-center"
                                                                            data-toggle="tooltip"
                                                                            data-boundary="viewport"
                                                                            data-html="true"
                                                                            title
                                                                            data-original-title="Collect From Tiles"
                                                                        >
                                                                            {item.method.name
                                                                                ? formatMethod(item.method.name)
                                                                                : formatMethod(item.method.id)}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </td>
                                                                <td>
                                                                    <Link to={`/block/${item.blockNumber}`} className="text-blue">
                                                                        {item.blockNumber === null ? (
                                                                            <i class="text-secondary">(pending)</i>
                                                                        ) : (
                                                                            item.blockNumber
                                                                        )}
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    {ageColChange ? (
                                                                        item.blockNumber == null ? (
                                                                            <i class="text-secondary">{item.age}</i>
                                                                        ) : (
                                                                            item.age
                                                                        )
                                                                    ) : (
                                                                        <span rel="tooltip" data-toggle="tooltip" data-placement="bottom">
                                                                            {formatDate(item.timestamp)}
                                                                        </span>
                                                                    )}
                                                                </td>

                                                                <td>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        trigger="hover"
                                                                        overlay={<Tooltip>{item.from}</Tooltip>}
                                                                    >
                                                                        <Link to={`/address/${item.from}`} className="text-blue">
                                                                            {item?.fromNameTag?.nameTag
                                                                                ? item?.fromNameTag?.nameTag
                                                                                : formatAddress(item.from)}
                                                                        </Link>
                                                                    </OverlayTrigger>
                                                                </td>
                                                                <td>
                                                                    {item.contractAddress || item.isContract ? (
                                                                        <i className="far fa-file-alt text-secondary ml-1 mr-1" />
                                                                    ) : (
                                                                        " "
                                                                    )}
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        trigger="hover"
                                                                        overlay={<Tooltip>{item.to ? item.to : item.contractAddress}</Tooltip>}
                                                                    >
                                                                        <Link
                                                                            to={
                                                                                item.to
                                                                                    ? `/address/${item.to}`
                                                                                    : item.contractAddress
                                                                                    ? `/address/${item.contractAddress}`
                                                                                    : "#"
                                                                            }
                                                                            className="text-blue"
                                                                        >
                                                                            {item.to
                                                                                ? item?.toNameTag?.nameTag
                                                                                    ? item?.toNameTag?.nameTag
                                                                                    : formatAddress(item.to)
                                                                                : item.contractAddress
                                                                                ? formatAddress(item.contractAddress)
                                                                                : ""}
                                                                        </Link>
                                                                    </OverlayTrigger>
                                                                </td>

                                                                <td>
                                                                    {item.blockNumber == null ? (
                                                                        <i class="text-secondary">
                                                                            {Number(value) > 0 ? Number(value).toFixed(5) : 0}
                                                                            {process.env.REACT_APP_TICKER}
                                                                        </i>
                                                                    ) : (
                                                                        <>
                                                                            {Number(value) > 0 ? Number(value).toFixed(5) : 0}{" "}
                                                                            {process.env.REACT_APP_TICKER}
                                                                        </>
                                                                    )}
                                                                    <span className="text-black"></span>
                                                                </td>
                                                                <td>
                                                                    {item.blockNumber == null ? (
                                                                        <i class="text-secondary">
                                                                            {Number(item.fee) > 0 ? Number(item.fee).toFixed(5) : 0}
                                                                        </i>
                                                                    ) : Number(item.fee) > 0 ? (
                                                                        Number(item.fee).toFixed(5)
                                                                    ) : (
                                                                        0
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <NoEntryMessage message={process.env.REACT_APP_BLANK_DATA_MESSAGE} />
                                                )}
                                            </>
                                        )}
                                    </tbody>
                                </table>
                                {allTransaction && allTransaction.length ? (
                                    <div class="row w-100 mt-3">
                                        <div class="col-md-6 mt-3">
                                            <Pagesize setPageSize={setPageSize} PageSize={PageSize} />
                                        </div>
                                        <div class="col-md-6 mt-3">
                                            <div className="float-right mr-3">
                                                <div class="row">
                                                    <button
                                                        class={currentPage === 1 ? "page-link disabled" : "page-link"}
                                                        onClick={() => {
                                                            setCurrentPage(1);
                                                            history({
                                                                pathname: "/txs",
                                                                search: "?block=" + block + "&p=" + 1,
                                                            });
                                                        }}
                                                        disabled={currentPage === 1}
                                                    >
                                                        First
                                                    </button>
                                                    <button
                                                        class={currentPage === 1 ? "page-link disabled" : "page-link"}
                                                        onClick={() => onPrevious(currentPage)}
                                                        disabled={currentPage === 1}
                                                    >
                                                        <i className="fa fa-angle-left" />
                                                    </button>
                                                    <div class="page-link-badge" disabled>
                                                        {" "}
                                                        {`${currentPage} of ${totalPages}`}
                                                    </div>
                                                    <button
                                                        class={currentPage === totalPages ? "page-link disabled" : "page-link"}
                                                        onClick={() => onNext(currentPage)}
                                                        disabled={currentPage === totalPages}
                                                    >
                                                        <i className="fa fa-angle-right" />
                                                    </button>
                                                    <button
                                                        class={currentPage === totalPages ? "page-link disabled" : "page-link"}
                                                        onClick={() => {
                                                            setCurrentPage(totalPages);
                                                            history({
                                                                pathname: "/txs",
                                                                search: "?block=" + block + "&p=" + totalPages,
                                                            });
                                                        }}
                                                        disabled={currentPage === totalPages}
                                                    >
                                                        Last
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default BlockTransactions;
