import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

const Gaslimit = ({ gasLimit }) => {
    const popoverTop7 = (
        <Popover id="popover-basic">
            {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
            <Popover.Body>
                Maximum amount of gas provided for the transaction. For normal {process.env.REACT_APP_TICKER} transfers, the value is 21,000. For
                contract this value is higher and bound by block gas limit.
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <div className="row">
                <div className="col-md-3 mb-1 bs h6 text-blueish">
                    <OverlayTrigger style={{ padding: "100px 0" }} trigger="hover" placement="top" overlay={popoverTop7}>
                        <i class="fal fa-question-circle text-secondary mr-1" aria-hidden="true"></i>
                    </OverlayTrigger>{" "}
                    Gas Limit
                </div>
                <div className="col-md-4 mb-1 bs text-dark bs">{gasLimit}</div>
            </div>
            <hr />
        </>
    );
};

export default Gaslimit;
