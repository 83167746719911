import React, { createContext, useEffect, useState } from "react";
import moment from "moment/moment";
import Web3 from "web3";
import CryptoJS from "crypto-js";
import axios from "axios";
import { getLocalStorage } from "../utils";

export const IgniteContext = createContext();

const ContextProvider = ({ children }) => {
  const [theme, setTheme] = useState(getLocalStorage("stormode") ? getLocalStorage("stormode") : "normal");
  const [selectValue, setSelectValue] = useState("");
  const [settingData, setSettingData] = useState(null);
  const [gas, setGas] = useState(0);
  const formatAddress = (address) => {
    if (!address) return address;
    return address.substr(0, 21) + "...";
  };

  const formatHash = (hash) => {
    if (!hash) return hash;
    return hash.substr(0, 21) + "...";
  };

  const formatReward = (reward) => {
    if (!reward) return reward;
    return parseFloat(reward).toFixed(10);
  };

  const formatTrxValue = (str, val) => {
    str = str.toString();
    str = str.slice(0, str.indexOf(".") + val + 1);
    return Number(str);
  };

  const formatTokenName = (str) => {
    if (!str) return str;
    if (str?.length <= 12) return str;
    return str.substr(0, 12) + "...";
  };

  const formatTokenSymbol = (str) => {
    if (!str) return str;
    if (str?.length <= 10) return str;
    return str.substr(0, 10) + "...";
  };

  const formatMethod = (str) => {
    if (!str) return str;
    if (str?.length <= 16) return str;
    return str.substr(0, 16) + "...";
  };

  function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function formatDate(timestamp) {
    return moment(new Date(timestamp)).utc(false).format("YYYY-MM-DD hh:mm:ss");
  }

  function isNumber(n) {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
  }
  function isAddress(address) {
    return Web3.utils.isAddress(address.toString());
  }
  function isHash(hash) {
    return /^0x([A-Fa-f0-9]{64})$/.test(hash);
  }

  function validateEmail(value) {
    let mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
    return mailformat.test(value);
  }
  function validateUrl(value) {
    // let urlformat = /^(ftp|http|https?):\/\/+(www.)?[a-z0-9\-.]{2,}\.[a-zA-Z1-9_\-:\\]{2,}$/;
    let urlformat =
      /^(ftp|http|https?):\/\/+[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
    return urlformat.test(value);
  }

  const verifyAndGetUser = () => {
    if (
      getLocalStorage("ua5s21san") &&
      getLocalStorage("ea4sd4fam") &&
      getLocalStorage("pn4sec3f1s") &&
      getLocalStorage("ls5sdf4al")
    ) {
      const username = CryptoJS.AES.decrypt(
        getLocalStorage("ua5s21san"),
        process.env.REACT_APP_PASSWORD
      ).toString(CryptoJS.enc.Utf8);
      const email = CryptoJS.AES.decrypt(
        getLocalStorage("ea4sd4fam"),
        process.env.REACT_APP_PASSWORD
      ).toString(CryptoJS.enc.Utf8);
      const password = CryptoJS.AES.decrypt(
        getLocalStorage("pn4sec3f1s"),
        process.env.REACT_APP_PASSWORD
      ).toString(CryptoJS.enc.Utf8);
      const lastLogin = CryptoJS.AES.decrypt(
        getLocalStorage("ls5sdf4al"),
        process.env.REACT_APP_PASSWORD
      ).toString(CryptoJS.enc.Utf8);
      const encusername = CryptoJS.AES.encrypt(
        JSON.stringify({ username: username }),
        process.env.REACT_APP_PASSWORD
      ).toString();
      return { isLogin: true, username, email, password, lastLogin, encusername };
    } else {
      localStorage.removeItem("ua5s21san");
      localStorage.removeItem("ea4sd4fam");
      localStorage.removeItem("pn4sec3f1s");
      localStorage.removeItem("ls5sdf4al");
      return { isLogin: false };
    }
  };

  async function logout() {
    const username = CryptoJS.AES.decrypt(
      getLocalStorage("ua5s21san"),
      process.env.REACT_APP_PASSWORD
    ).toString(CryptoJS.enc.Utf8);
    const password = CryptoJS.AES.decrypt(
      getLocalStorage("pn4sec3f1s"),
      process.env.REACT_APP_PASSWORD
    ).toString(CryptoJS.enc.Utf8);
    const encBody = CryptoJS.AES.encrypt(
      JSON.stringify({
        username: username.trim(),
        password: password.trim(),
        timestemp: Math.round(Date.now() / 1000),
      }),
      process.env.REACT_APP_PASSWORD
    ).toString();
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}user/logout`, {
        token: encBody,
      })
      .then((res) => {
        if (res.data.error) {
          // console.log("error in logout : ", res.data.error);
        } else {
          localStorage.removeItem("ua5s21san");
          localStorage.removeItem("ea4sd4fam");
          localStorage.removeItem("pn4sec3f1s");
          localStorage.removeItem("ls5sdf4al");
          window.location.assign("/login");
        }
      })
      .catch((err) => {
        // console.log("error in logout (cathc) : ", err);
      });
  }

  const getSettingData = async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}settings`)
      .then((res) => {
        if (res.data.error) {
          // console.log("error in logout : ", res.data.error);
        } else {
          setSettingData(res.data);
        }
      })
      .catch((err) => {
        console.log("error in getsettingdata (cathc) : ", err);
      });
  };

  useEffect(() => {
    getSettingData();
  }, []);

  useEffect(() => {
    if (theme === "dark") {
      document.querySelector("body").classList.add("dark-mode");
    } else {
      document.querySelector("body").classList.remove("dark-mode");
    }
  }, [theme]);

  return (
    <IgniteContext.Provider
      value={{
        numberWithCommas,
        nFormatter,
        formatTrxValue,
        formatReward,
        formatHash,
        formatAddress,
        formatTokenSymbol,
        formatTokenName,
        formatDate,
        isNumber,
        isAddress,
        logout,
        isHash,
        validateEmail,
        validateUrl,
        formatMethod,
        setSelectValue,
        selectValue,
        verifyAndGetUser,
        settingData,
        theme,
        setTheme,
        gas,
        setGas,
      }}
    >
      {children}
    </IgniteContext.Provider>
  );
};

export default ContextProvider;
