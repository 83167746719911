import React from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";
import HomeSearch from "./HomeSearch";
import HomeStatisticsBox from "./HomeStatisticsBox";
import LatestBlocks from "./LatestBlocks";
import LatestTransaction from "./LatestTransaction";

const Home = () => {
    document.title = `${process.env.REACT_APP_NAME} (${process.env.REACT_APP_TICKER}) Blockchain Explorer`;
    return (
        <>
            <div id="main" role="main" style={{ minHeight: "850px" }}>
                <Header />
                <HomeSearch />
                <div className="container space-bottom-1 mt-n5">
                    <HomeStatisticsBox />
                    <div className="d-block d-lg-none text-center pl-lg-4 pl-xll-5 mb-4"></div>
                    <div className="row mb-5">
                        <LatestBlocks />
                        <LatestTransaction />
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default Home;
