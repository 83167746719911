import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header2 from "../common/Header2";
import Footer from "../common/Footer";
import Web3 from "web3";

const web3 = new Web3(process.env.REACT_APP_HTTP_RPC);

function BlockCountDown() {
    document.title = `${process.env.REACT_APP_NAME} chain Block Countdown`;

    const navigate = useNavigate();

    const { id } = useParams();

    // const [timeRemaining, settimeRemaining] = useState();
    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [hours, setHours] = useState(0);
    const [days, setDays] = useState(0);

    const [estimateDate, setEstimateDate] = useState("");

    const countdownBlock = id;
    const [currBlock, setCurrBlock] = useState(0);
    const [remainingBlock, setRemainingBlock] = useState(0);

    useEffect(() => {
        setTimer();
    }, []);

    const setTimer = (cblock) => {
        let currentdate = new Date();
        let cdate = currentdate.getTime();
        let ndate = new Date(currentdate.setDate(currentdate.getDate() + 18)).getTime();
        let reamainingmilisec = ndate - cdate;
        // let secondDate = now + grandtotalsec;
        timerClock(reamainingmilisec);
    };

    const timerClock = (difference) => {
        let timer;
        if (difference <= 0) {
            clearTimer(timer);
            setSeconds(0);
            setMinutes(0);
            setHours(0);
            setDays(0);
        } else {
            timer = setInterval(() => {
                if (difference <= 0) {
                    clearTimer(timer);
                    setSeconds(0);
                    setMinutes(0);
                    setHours(0);
                    setDays(0);
                }
                difference = difference - 1000;
                var seconds = Math.floor(difference / 1000);
                var minutes = Math.floor(seconds / 60);
                var hours = Math.floor(minutes / 60);
                var days = Math.floor(hours / 24);

                hours %= 24;
                minutes %= 60;
                seconds %= 60;

                setSeconds(seconds);
                setMinutes(minutes);
                setHours(hours);
                setDays(days);
            }, 1000);
        }
    };

    const clearTimer = (timer) => {
        clearInterval(timer);
        setSeconds(0);
        setMinutes(0);
        setHours(0);
        setDays(0);
        navigate(`/block/${id}`);
    };

    return (
        <>
            <Header2 />
            <main id="content" role="main" style={{ minHeight: "740px" }}>
                <input type="hidden" name="hdnAgeText" id="hdnAgeText" defaultValue="Age" />
                <input type="hidden" name="hdnDateTimeText" id="hdnDateTimeText" defaultValue="Date Time (UTC)" />
                <input type="hidden" name="hdnAgeTitle" id="hdnAgeTitle" defaultValue="Click to show Age Format" />
                <input type="hidden" name="hdnDateTimeTitle" id="hdnDateTimeTitle" defaultValue="Click to show Datetime Format" />
                <input type="hidden" name="hdnTxnText" id="hdnTxnText" defaultValue="Txn Fee" />
                <input type="hidden" name="hdnGasPriceText" id="hdnGasPriceText" defaultValue="Gas Price" />
                <input type="hidden" name="hdnTxnFeeTitle" id="hdnTxnFeeTitle" defaultValue="(Gas Price * Gas Used by Txns) in Ether" />
                <input type="hidden" name="hdnGasPriceTitle" id="hdnGasPriceTitle" defaultValue="Gas Price in Gwei" />
                <div
                    className="toast border-0"
                    role="button"
                    onclick="reloadPage()"
                    aria-live="assertive"
                    aria-atomic="true"
                    data-autohide="false"
                    style={{ position: "fixed", top: "10px", right: "10px", minWidth: "220px", zIndex: 9999 }}
                >
                    <div className="toast-header py-2">
                        <strong className="mr-auto">Block</strong>
                        <button type="button" className="ml-2 close" data-dismiss="toast" aria-label="Close" onclick="reloadPage()">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="toast-body text-center p-4 py-5-alt">
                        <div className="mb-3">
                            <i className="fal fa-cube text-secondary fa-2x" />
                        </div>
                        <p className="lead mb-2" style={{ lineHeight: "1.4" }}>
                            The block <strong>#155490009</strong>
                            <br />
                            has been mined!
                        </p>
                        <div className="small">
                            {/* eslint-disable-next-line */}
                            <a href="javascript:void(0);">
                                See details <i className="far fa-arrow-right ml-1" />
                            </a>
                            <div className="font-italic small">
                                <p>
                                    <br />
                                    Sound credited to
                                    <a
                                        href="https://freesound.org/people/shinephoenixstormcrow/sounds/337049/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        shinephoenixstormcrow
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-lg-flex align-items-md-center min-height-600 space-2">
                    <div className="container">
                        <div className="row align-items-lg-center">
                            <div className="col-lg-6 mb-5 mb-lg-0">
                                <figure className="mx-auto">
                                    <img className="w-100 w-md-90" src="/asset/images/virtual-reality-ether.svg?v=0.0.1" alt="Block Countdown" />
                                </figure>
                            </div>
                            <div className="col-lg-6">
                                <div className="pl-lg-4">
                                    <div
                                        id="ContentPlaceHolder1_countdownPanel"
                                        className="js-countdown row countdownPanel"
                                        data-month-format="%m"
                                        data-days-format="%D"
                                        data-hours-format="%H"
                                        data-minutes-format="%M"
                                        data-seconds-format="%S"
                                    >
                                        <div className="col-3">
                                            <strong className="js-cd-days h1 text-primary font-weight-bold mb-0 mr-1">{days}</strong>
                                            <span className="h4 font-weight-normal mb-0 d-block d-sm-inline-block">Days</span>
                                        </div>
                                        <div className="col-3">
                                            <strong className="js-cd-hours h1 text-primary font-weight-bold mb-0 mr-1">{hours}</strong>
                                            <span className="h4 font-weight-normal mb-0 d-block d-sm-inline-block">Hours</span>
                                        </div>
                                        <div className="col-3">
                                            <strong className="js-cd-minutes h1 text-primary font-weight-bold mb-0 mr-1">{minutes}</strong>
                                            <span className="h4 font-weight-normal mb-0 d-block d-sm-inline-block">Mins</span>
                                        </div>
                                        <div className="col-3">
                                            <strong className="js-cd-seconds h1 text-primary font-weight-bold mb-0 mr-1">{seconds}</strong>
                                            <span className="h4 font-weight-normal mb-0 d-block d-sm-inline-block">Secs</span>
                                        </div>
                                    </div>
                                    <hr className="hr-space" />
                                    <p className="h5 text-secondary mb-0">
                                        Estimated Target Date:{" "}
                                        <strong>
                                            <span id="estimatedDate" />
                                            {estimateDate}
                                        </strong>
                                    </p>
                                    <div className="row my-4">
                                        <div className="col-md-4 mb-3 mb-md-0">
                                            <div className="card h-100 bg-primary text-white p-3">
                                                <h3 className="h6 text-white mb-0">Countdown For Block:</h3>
                                                <span className="font-weight-bold text-truncate d-block">#{countdownBlock}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3 mb-md-0">
                                            <div className="card h-100 bg-secondary text-white p-3">
                                                <h3 className="h6 text-white mb-0">Current Block:</h3>
                                                <span className="font-weight-bold text-truncate d-block">#{currBlock}</span>
                                            </div>
                                        </div>
                                        <div className="col-md-4 mb-3 mb-md-0">
                                            <div className="card h-100 bg-secondary text-white p-3">
                                                <h3 className="h6 text-white mb-0">Remaining Blocks:</h3>
                                                <span className="font-weight-bold text-truncate d-block">#{remainingBlock}</span>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="d-flex align-items-center">
                                        <label className="checkbox-switch mr-2 mb-0" id="labelswitch" data-toggle="tooltip" data-placement="top" data-original-title title>
                                            <input type="checkbox" className="checkbox-switch__input" id="togglenotification" />
                                            <span className="checkbox-switch__slider" />
                                        </label>
                                        <div>Click on the Notification bell for alert when the block is mined!</div>
                                    </div> */}
                                    <audio id="audioid">
                                        <source src="/assets/audio/beep.mp3" type="audio/mpeg" />
                                        <source src="/assets/audio/beep.ogg" type="audio/ogg" />
                                    </audio>
                                    {/* <div className="d-flex justify-content-end">
                                        <p className="h5 text-secondary mt-2 mr-3 mb-0">Add to your calendar:</p>
                                        <span className="mr-1">
                                            <a className="btn btn-icon btn-soft-secondary rounded-circle" id="addToGoogleCalendar" href="#" data-toggle="tooltip" data-title="Add to Google calendar" data-class-change-target="#linkGIcon" data-type="tooltip" data-original-title title target="_blank">
                                                <span id="linkGIcon" className="fab fa-google btn-icon__inner" />
                                            </a>
                                        </span>
                                        <span className="mr-1" id="spanAppleEvent">
                                            <a className="btn btn-icon btn-soft-secondary rounded-circle" id="addToAppleCalendar" href="#" data-toggle="tooltip" data-title="Add to Apple calendar" data-class-change-target="#linkAIcon" data-type="tooltip" data-original-title title target="_blank">
                                                <span id="linkAIcon" className="fab fa-apple btn-icon__inner" />
                                            </a>
                                        </span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
}

export default BlockCountDown;
