import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Spinner from "../../common/Spinner";
import { Link } from "react-router-dom";
import Pagesize from "../../common/Pagesize";
import Pagination from "../../common/Pagination";
import { IgniteContext } from "../../IgniteContext";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import NoEntryMessage from "../../common/NoEntryMessage";
import { useSearchParams } from "react-router-dom";
import FadingLoaderCard1 from "../../common/ContentLoaders/LoaderBigLine";

const Erc721TokenTransactions = ({ id, setTransferOnId }) => {
    const [ageColChange, setAgeColChange] = useState(true);
    const [searchParams] = useSearchParams();
    const paramsid = searchParams.get("a");
    const { formatAddress, formatHash, formatDate, formatMethod, isAddress } = useContext(IgniteContext);

    const [tokenTransaction, setTokenTransaction] = useState([]);
    const [totalTokenTxs, setTotalTokenTxs] = useState(0);
    const [tokenSpinner, setTokenSpinner] = useState(true);

    const [txsCurrentPage, setTxsCurrentPage] = useState(1);
    const [txsPages, setTxsPages] = useState(1);
    const [txsPageSize, setTxsPageSize] = useState(10);

    const getTokenTransactions = async () => {
        setTokenSpinner(true);
        let params = {};
        if (paramsid && isAddress(paramsid)) {
            params = {
                limit: txsPageSize,
                page: txsCurrentPage,
                token: id,
                address: paramsid,
            };
        } else if (paramsid) {
            params = {
                limit: txsPageSize,
                page: txsCurrentPage,
                token: id,
                tokenId: paramsid,
            };
        } else {
            params = {
                limit: txsPageSize,
                page: txsCurrentPage,
                token: id,
            };
        }
        await axios
            .get(`${process.env.REACT_APP_BACKEND_URL}tokentxs/erc721`, {
                params: params,
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in get token transaction : ", res.data.error);
                } else {
                    setTokenTransaction(res.data.items);
                    setTotalTokenTxs(res.data.total);
                    setTransferOnId(res.data.total);
                    setTokenSpinner(false);
                    setTxsPages(res.data.pages);
                }
            })
            .catch((err) => {
                console.log("error in get token transaction (catch) : ", err);
            });
    };

    useEffect(() => {
        getTokenTransactions();
    }, [id, txsCurrentPage, txsPageSize, paramsid]);

    return (
        <>
            <div className="tab-pane fade show active" id="transactions" role="tabpanel" aria-labelledby="transactions-tab">
                <div className="table-responsive mb-2 mb-md-0">
                    <p class="mr-2 mb-2">
                        <i class="fas fa-sort-amount-down text-secondary" data-toggle="tooltip" title="" data-original-title="Oldest First"></i>
                        &nbsp;Total {totalTokenTxs} transactions found.
                    </p>
                    <table className="table table-hover">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col" width={20}>
                                    Id
                                </th>
                                <th scope="col" width={1}>
                                    Txn Hash
                                </th>
                                <th>
                                    <div className="d-flex align-items-center">
                                        <div className="mr-2">
                                            Method{" "}
                                            <OverlayTrigger
                                                placement="top"
                                                trigger="hover"
                                                overlay={
                                                    <Tooltip>
                                                        Function executed based on decoded input data. For unidentified functions, method ID is
                                                        displayed instead.
                                                    </Tooltip>
                                                }
                                            >
                                                <i
                                                    className="fal fa-info-circle"
                                                    data-toggle="tooltip"
                                                    data-html="true"
                                                    data-boundary="viewport"
                                                    title
                                                />
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                </th>
                                <th>
                                    {ageColChange ? (
                                        <OverlayTrigger placement="top" trigger="hover" overlay={<Tooltip>Click to show Datetime Format</Tooltip>}>
                                            <Link onClick={() => setAgeColChange(!ageColChange)} id="lnkAgeDateTime">
                                                Age
                                            </Link>
                                        </OverlayTrigger>
                                    ) : (
                                        <OverlayTrigger placement="top" trigger="hover" overlay={<Tooltip>Click to show Age Format</Tooltip>}>
                                            <Link onClick={() => setAgeColChange(!ageColChange)}>Date Time (UTC)</Link>
                                        </OverlayTrigger>
                                    )}
                                </th>
                                <th>From</th>
                                <th scope="col" width={30} />
                                <th scope="col">To</th>
                                <th scope="col">TokenID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tokenSpinner ? (
                                <>
                                    <tr>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>

                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                        </td>
                                        <td></td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>

                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                        </td>
                                        <td></td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>

                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                        </td>
                                        <td></td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>

                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                        </td>
                                        <td></td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>

                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                        </td>
                                        <td></td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>

                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                        </td>
                                        <td></td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>

                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                        </td>
                                        <td></td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>

                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                        </td>
                                        <td></td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>

                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                        </td>
                                        <td></td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>

                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                        </td>
                                        <td></td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                        <td>
                                            {" "}
                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                        </td>
                                    </tr>
                                </>
                            ) : (
                                <>
                                    {tokenTransaction && tokenTransaction.length ? (
                                        tokenTransaction.map((item, index) => {
                                            //   const value = Web3.utils.fromWei(item.value.toString());
                                            const ind = txsCurrentPage * 10 - 10 + (index + 1);
                                            return (
                                                <tr>
                                                    <td>{ind}</td>
                                                    <td>
                                                        <span className="hash-tag">
                                                            <Link to={`/tx/${item.hash}`} className="text-blue">
                                                                {formatHash(item.hash)}
                                                            </Link>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            trigger="hover"
                                                            overlay={<Tooltip>{item?.method?.name ? item?.method?.name : item?.method?.id}</Tooltip>}
                                                        >
                                                            <span
                                                                style={{ minWidth: "68px" }}
                                                                className="u-label u-label--xs u-label--info rounded text-dark text-center"
                                                                data-toggle="tooltip"
                                                                data-boundary="viewport"
                                                                data-html="true"
                                                                title
                                                                data-original-title="Collect From Tiles"
                                                            >
                                                                {item?.method?.name
                                                                    ? formatMethod(item?.method?.name)
                                                                    : formatMethod(item?.method?.id)}
                                                            </span>
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td style={{}} className="showAge">
                                                        {ageColChange ? (
                                                            item.blockNumber == null ? (
                                                                <i class="text-secondary">{item.age}</i>
                                                            ) : (
                                                                item.age
                                                            )
                                                        ) : (
                                                            <span rel="tooltip" data-toggle="tooltip" data-placement="bottom">
                                                                {formatDate(item.timestamp)}
                                                            </span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        <span className="hash-tag" data-html="true">
                                                            <OverlayTrigger placement="top" trigger="hover" overlay={<Tooltip>{item.from}</Tooltip>}>
                                                                <Link to={`/address/${item.from}`} className="text-blue">
                                                                    {item?.fromNameTag
                                                                        ? item?.fromNameTag
                                                                        : item.publicFromNameTag
                                                                        ? item.publicFromNameTag
                                                                        : formatAddress(item.from)}
                                                                </Link>
                                                            </OverlayTrigger>
                                                        </span>
                                                    </td>
                                                    <td className="text-center">
                                                        <span className="btn btn-xs btn-icon btn-soft-success rounded-circle">
                                                            <i className="fas fa-long-arrow-alt-right btn-icon__inner" />
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <OverlayTrigger
                                                            placement="top"
                                                            trigger="hover"
                                                            overlay={<Tooltip>{item.to ? item.to : "Contract Created"}</Tooltip>}
                                                        >
                                                            <span style={{ whiteSpace: "nowrap" }}>
                                                                {/* <i className="far fa-file-alt text-secondary mr-1" /> */}
                                                                <span className="hash-tag" data-html="true" data-toggle="tooltip">
                                                                    {item.to !== null ? (
                                                                        <Link to={`/address/${item.to}`} className="text-blue">
                                                                            {item?.toNameTag
                                                                                ? item?.toNameTag
                                                                                : item.publicToNameTag
                                                                                ? item.publicToNameTag
                                                                                : formatAddress(item.to)}
                                                                        </Link>
                                                                    ) : (
                                                                        "Contract Created"
                                                                    )}
                                                                </span>
                                                            </span>
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td>
                                                        <span className="text-black">
                                                            <Link to={{ pathname: `/token/${item.token}`, search: `?a=${item.tokenId}` }}>
                                                                {item.tokenId}
                                                            </Link>
                                                        </span>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <NoEntryMessage message={process.env.REACT_APP_BLANK_DATA_MESSAGE} />
                                    )}
                                </>
                            )}
                        </tbody>
                    </table>
                    {tokenTransaction && tokenTransaction.length ? (
                        <div class="row w-100 mt-3">
                            <div class="col-md-6 mt-3">
                                <Pagesize setPageSize={setTxsPageSize} PageSize={txsPageSize} />
                            </div>
                            <div class="col-md-6 mt-3">
                                <Pagination currentPage={txsCurrentPage} setCurrentPage={setTxsCurrentPage} totalPage={txsPages} />
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </>
    );
};

export default Erc721TokenTransactions;
