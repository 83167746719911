import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Header2 from "../common/Header2";
import Spinner from "../common/Spinner";
import Pagesize from "../common/Pagesize";
import NoEntryMessage from "../common/NoEntryMessage";
import { useSearchParams } from "react-router-dom";
import FadingLoaderCard1 from "../common/ContentLoaders/LoaderBigLine";
import Footer from "../common/Footer";

const Validators = () => {
    document.title = `${process.env.REACT_APP_NAME}chain Validators | ${process.env.REACT_APP_NAME}Scan`;

    const [searchParams] = useSearchParams();
    const cpage = searchParams.get("p");

    const history = useNavigate();

    const [currentPage, setCurrentPage] = useState(Number(cpage) ? Number(cpage) : 1);
    const [validators, setValidators] = useState([]);
    const [item, setItem] = useState([]);
    const [PageSize, setPageSize] = useState(5);
    const [spinner, setSpinner] = useState(true);
    const [pages, setPages] = useState(0);

    const getValidators = async () => {
        setSpinner(true);
        await axios
            .post(process.env.REACT_APP_HTTP_RPC, {
                jsonrpc: "2.0",
                method: "clique_getSigners",
                params: [],
                id: 1,
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getValidators : ", res.data.error);
                } else if (res?.data?.result) {
                    setValidators(res.data.result);
                    let page = Math.floor(res.data.result.length / PageSize) + 1;
                    setPages(page);
                    setSpinner(false);
                } else {
                    return false;
                }
            })
            .catch((err) => {
                console.log("error in getValidators (catch) : ", err);
            });
    };

    const setPagination = () => {
        setSpinner(true);
        let ind = currentPage * PageSize - PageSize;
        let skip = currentPage * PageSize;
        const item = validators.slice(ind, skip);
        setItem(item);
        setSpinner(false);
    };

    useEffect(() => {
        setPagination();
    }, [validators, currentPage, PageSize]);

    useEffect(() => {
        getValidators();
    }, []);

    const onNext = (page) => {
        if (page === pages) return false;
        setCurrentPage(Number(currentPage) + 1);
        history({
            pathname: "/validators",
            search: "?p=" + (Number(currentPage) + 1),
        });
    };

    const onPrevious = (page) => {
        if (page === 1) return false;
        setCurrentPage(Number(currentPage) - 1);
        history({
            pathname: "/validators",
            search: "?p=" + (Number(currentPage) - 1),
        });
    };

    return (
        <>
            <Header2 />
            <main id="content" role="main" style={{ minHeight: "800px" }}>
                <div className="container">
                    <div className="d-md-flex justify-content-between align-items-center py-3">
                        <div className="d-sm-flex align-items-center">
                            <div className="mb-2 mb-sm-0">
                                <h1 className="h4 d-flex align-items-center mb-0">Validators Top Leaderboard (Blocks Validated)</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container space-bottom-2">
                    <div className="card">
                        <div className="card-header d-md-flex justify-content-between align-items-center">
                            <div className="d-flex flex-wrap">
                                <div className="position-relative mr-2 mb-2 mb-md-0">
                                    <h2 className="card-header-title">Validators</h2>
                                </div>
                                <div className="mr-2 mb-2 mb-md-0"></div>
                                <div className="mr-2 mb-2 mb-md-0" />
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive mb-2 mb-md-0">
                                <table className="sticky-thead-cloned-table table table-text-normal table-hover mb-0 border-bottom-0" id="datatable">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">ID</th>
                                            <th scope="col" width={220}></th>
                                            <th scope="col">Address</th>

                                            <th scope="col"></th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {spinner ? (
                                            <>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td> </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="250" height="15" />
                                                    </td>
                                                    <td> </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="250" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td> </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="250" height="15" />
                                                    </td>
                                                    <td> </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="250" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td> </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="250" height="15" />
                                                    </td>
                                                    <td> </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="250" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td> </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="250" height="15" />
                                                    </td>
                                                    <td> </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="250" height="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                    </td>
                                                    <td> </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="250" height="15" />
                                                    </td>
                                                    <td> </td>
                                                    <td>
                                                        {" "}
                                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="250" height="15" />
                                                    </td>
                                                </tr>
                                            </>
                                        ) : validators && validators?.length ? (
                                            item?.map((item, index) => {
                                                const ind = currentPage * PageSize - PageSize + (index + 1);
                                                return (
                                                    <tr>
                                                        <td>{ind}</td>
                                                        <td></td>

                                                        <td>
                                                            <Link to={`/address/${item}`}>{item}</Link>
                                                        </td>
                                                        <td></td>

                                                        <td>
                                                            {item ? (
                                                                <span class="text-success-verify">
                                                                    <i class="fa fa-signal"></i> Yes
                                                                </span>
                                                            ) : (
                                                                <span class="text-danger">
                                                                    <i class="fas fa-signal-slash"></i> No
                                                                </span>
                                                            )}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <NoEntryMessage message={process.env.REACT_APP_BLANK_DATA_MESSAGE} />
                                        )}
                                    </tbody>
                                </table>
                                <div class="row w-100 mt-3">
                                    <div class="col-md-6 mt-3">
                                        <Pagesize setPageSize={setPageSize} PageSize={PageSize} />
                                    </div>
                                    <div class="col-md-6 mt-3">
                                        <div className="float-right mr-3">
                                            <div class="row">
                                                <button
                                                    class={currentPage === 1 ? "page-link disabled" : "page-link"}
                                                    onClick={() => {
                                                        setCurrentPage(1);
                                                        history({
                                                            pathname: "/validators",
                                                            search: "?p=" + 1,
                                                        });
                                                    }}
                                                    disabled={currentPage === 1}
                                                >
                                                    First
                                                </button>
                                                <button
                                                    class={currentPage === 1 ? "page-link disabled" : "page-link"}
                                                    onClick={() => onPrevious(currentPage)}
                                                    disabled={currentPage === 1}
                                                >
                                                    <i className="fa fa-angle-left" />
                                                </button>
                                                <div class="page-link-badge" disabled>
                                                    {" "}
                                                    {`${currentPage} of ${pages}`}
                                                </div>
                                                <button
                                                    class={currentPage === pages ? "page-link disabled" : "page-link"}
                                                    onClick={() => onNext(currentPage)}
                                                    disabled={currentPage === pages}
                                                >
                                                    <i className="fa fa-angle-right" />
                                                </button>
                                                <button
                                                    class={currentPage === pages ? "page-link disabled" : "page-link"}
                                                    onClick={() => {
                                                        setCurrentPage(pages);
                                                        history({
                                                            pathname: "/validators",
                                                            search: "?p=" + pages,
                                                        });
                                                    }}
                                                    disabled={currentPage === pages}
                                                >
                                                    Last
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default Validators;
