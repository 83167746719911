import React from "react";
import AddressTransactions from "./AddressTransactions";
import AllTransactions from "./AllTransactions";
import { useSearchParams } from "react-router-dom";
import BlockTransactions from "./BlockTransactions";

const TransactionsType = () => {
    const [searchParams] = useSearchParams();
    const a = searchParams.get("a");
    const block = searchParams.get("block");

    return a ? <AddressTransactions /> : block ? <BlockTransactions block={block} /> : <AllTransactions />;
};

export default TransactionsType;
