import React, { useState, useContext, useEffect } from "react";
import Header2 from "../common/Header2";
import { Link, useParams, useNavigate } from "react-router-dom";
import { IgniteContext } from "../IgniteContext";
import CryptoJS from "crypto-js";
import axios from "axios";
import { getLocalStorage } from "../../utils";

function TokenUpdateForm() {
    const { isAddress } = useContext(IgniteContext);
    const { id } = useParams();

    const navigate = useNavigate();

    const [contractAddress, setContractAddress] = useState(id ? id : "");
    const [contractDetails, setContractDetails] = useState(null);
    const [contractError, setContractError] = useState(null);

    const [mainError, setMainError] = useState("");
    const [isVerified, setIsVerified] = useState(false);

    const isLogin = getLocalStorage("ua5s21san") && getLocalStorage("ea4sd4fam") && getLocalStorage("pn4sec3f1s") ? true : false;

    // useEffect(() => {
    //     const redirectState1 = JSON.parse(sessionStorage.getItem("a1BDc45Adfad"));
    //     const redirectState = JSON.parse(sessionStorage.getItem("a1BDc45Adfa"));
    //     if (!redirectState) {
    //         navigate(`/token/${redirectState1.id}`);
    //     }
    // }, []);

    const verifyLogin = async () => {
        if (isLogin) {
            const username = CryptoJS.AES.decrypt(getLocalStorage("ua5s21san"), process.env.REACT_APP_PASSWORD).toString(CryptoJS.enc.Utf8);
            const password = CryptoJS.AES.decrypt(getLocalStorage("pn4sec3f1s"), process.env.REACT_APP_PASSWORD).toString(CryptoJS.enc.Utf8);
            const encBody = CryptoJS.AES.encrypt(
                JSON.stringify({
                    username: username.trim(),
                    password: password.trim(),
                    timestemp: Math.round(Date.now() / 1000),
                }),
                process.env.REACT_APP_PASSWORD
            ).toString();
            await axios
                .post(`${process.env.REACT_APP_BACKEND_URL}user/signin`, {
                    token: encBody,
                })
                .then((res) => {
                    if (res.data.error) {
                        window.location.assign("/login");
                    } else {
                        return true;
                    }
                })
                .catch((err) => {
                    console.log("error in userLogin (cathc) : ", err);
                });
        } else {
            window.location.assign("/login");
        }
    };
    useEffect(() => {
        verifyLogin();
    }, []);

    const getContractDetails = async () => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_URL}contract/${contractAddress}`)
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getContractDetails : ", res.data.error);
                    setMainError("Please enter valid contarct address.");
                } else {
                    setContractDetails(res.data);
                    setIsVerified(res.data.abiCode !== null && res.data.sourceCode !== null ? true : false);
                    setMainError("");
                }
            })
            .catch((err) => {
                console.log("error in getContractDetails (catch) : ", err);
                setMainError("Please enter valid contarct address.");
            });
    };
    useEffect(() => {
        getContractDetails();
    }, []);

    const handleInputChange = (val) => {
        setContractAddress(val);
        if (val === "") {
            setContractError("Contract address is required");
        } else {
            if (isAddress(val)) {
                setContractError("");
            } else {
                setContractError("Please enter valid contract address.");
            }
        }
    };

    const handleResetForm = () => {
        setContractAddress("");
        setContractError(null);
    };
    const handleNext = async () => {
        let temp = 1;
        if (contractAddress === "") {
            setContractError("Contract address is required");
            temp = 0;
        } else {
            if (isAddress(contractAddress)) {
                setContractError("");
            } else {
                setContractError("Please enter valid contract address.");
                temp = 0;
            }
        }
        if (temp) {
            await getContractDetails();
            if (isVerified && !mainError) navigate(`/verifyAddress/${contractAddress}`, { state: { id: contractAddress } });
        }
    };

    return (
        <>
            <Header2 />
            <main id="content" role="main" style={{ minHeight: "880px" }}>
                <div class="container space-2">
                    <div class="text-center mb-4">
                        <h2 class="h3 mb-1">Token Update Application Form</h2>
                    </div>
                    <div class="card">
                        <div class="card-body p-4">
                            <>
                                {" "}
                                {mainError ? (
                                    <div class="alert alert-danger mb-4" role="alert">
                                        <i class="fas fa-ban"></i>&nbsp;{mainError}{" "}
                                    </div>
                                ) : isVerified ? (
                                    <div class="alert alert-danger mb-4" role="alert">
                                        <i class="fas fa-ban"></i>&nbsp; To update token information, you will need to verify as{" "}
                                        <b>contract address owner</b>, please verify address ownership using this{" "}
                                        <Link to={`/verifyAddress/${contractDetails?.address}`} state={{ id: contractAddress }}>
                                            <b>tool</b>
                                        </Link>{" "}
                                    </div>
                                ) : (
                                    <div class="alert alert-danger mb-4" role="alert">
                                        &nbsp; <i class="fas fa-ban"></i> Contract source code not verified, please get your contract developer to
                                        verify using this{" "}
                                        <Link to={`/verifyContract?a=${contractDetails?.address}`}>
                                            <b>tool</b>
                                        </Link>{" "}
                                    </div>
                                )}
                            </>

                            <div id="ContentPlaceHolder1_tokenupdate1div">
                                <div class="w-md-75 w-lg-50 mx-md-auto">
                                    <div class="row">
                                        <div class="js-form-message form-group col-12">
                                            <label class="d-block" for="VerifyAddress">
                                                Please enter the contract address <span class="text-danger">*</span>
                                            </label>
                                            <input
                                                name="ctl00$ContentPlaceHolder1$txtFormVerifyAddress"
                                                type="text"
                                                maxlength="42"
                                                id="ContentPlaceHolder1_txtFormVerifyAddress"
                                                className={
                                                    contractError === null
                                                        ? "form-control form-control-sm"
                                                        : contractError === ""
                                                        ? "form-control form-control-sm success"
                                                        : "form-control form-control-sm error"
                                                }
                                                value={contractAddress}
                                                onChange={(e) => handleInputChange(e.target.value)}
                                            />
                                            {contractError && (
                                                <div id="ContentPlaceHolder1_txtUserName-error" class="invalid-feedback" style={{ display: "Block" }}>
                                                    {contractError}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div class="row align-items-center">
                                        <div class="col-6"></div>
                                        <div class="col-6 text-right">
                                            <button className="btn btn-sm btn-secondary mr-1 cursor-pointer" onClick={handleResetForm}>
                                                Reset
                                            </button>
                                            <button
                                                className={`btn btn-sm btn-primary cursor-pointer ${
                                                    "contractDetails.isverified" === false ? "disabled" : ""
                                                }`}
                                                onClick={handleNext}
                                                disabled={!contractDetails ? true : false}
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default TokenUpdateForm;
