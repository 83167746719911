import React, { useState, useContext, useEffect, useMemo } from "react";
import Header2 from "../common/Header2";
import axios from "axios";
import Pagination from "../common/Pagination";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Pagesize from "../common/Pagesize";
import { IgniteContext } from "../IgniteContext";
import { useSearchParams } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import NoEntryMessage from "../common/NoEntryMessage";
import FadingLoaderCard1 from "../common/ContentLoaders/LoaderBigLine";
import Footer from "../common/Footer";
import Web3 from "web3";

const ViewPendingTransaction = () => {
    const [searchParams] = useSearchParams();
    const cpage = searchParams.get("p");
    const add = searchParams.get("a");

    const { formatHash, formatMethod } = useContext(IgniteContext);

    const history = useNavigate();

    document.title = `${process.env.REACT_APP_NAME}chain Pending Trasactions | ${process.env.REACT_APP_NAME}Scan`;

    const [spinner, setSpinner] = useState(true);

    const [nonceSort, setNonceSort] = useState(2);

    const [gaslimitSort, setGaslimitSort] = useState(2);
    const [gaspriceSort, setGaspriceSort] = useState(2);

    const [pendingtxsn, setPendingtxsn] = useState([]);

    const [currentPage, setCurrentPage] = useState(cpage ? cpage : 1);

    const [totalpendingtxsn, setTotalpendingtxsn] = useState(0);
    const [pages, setPages] = useState(0);
    const [PageSize, setPageSize] = useState(10);

    const handleNonceSort = (sortStatus) => {
        let sortedData = [];
        setGaslimitSort(2);
        setGaspriceSort(2);

        if (sortStatus === 0) {
            sortedData = pendingtxsn.sort((a, b) => a.nonce - b.nonce);
            setPendingtxsn([...sortedData]);
            setNonceSort(0);
        } else if (sortStatus === 1) {
            sortedData = pendingtxsn.sort((a, b) => b.nonce - a.nonce);
            setPendingtxsn([...sortedData]);
            setNonceSort(1);
        } else if (sortStatus === 2) {
            getpendingtxsn();
            setNonceSort(2);
        }
    };
    const handleGaslimitSort = (sortStatus) => {
        let sortgasLimitData = [];
        setNonceSort(2);
        setGaspriceSort(2);

        if (sortStatus === 0) {
            sortgasLimitData = pendingtxsn.sort((a, b) => a.gasLimit - b.gasLimit);
            setPendingtxsn([...sortgasLimitData]);
            setGaslimitSort(0);
        } else if (sortStatus === 1) {
            sortgasLimitData = pendingtxsn.sort((a, b) => b.gasLimit - a.gasLimit);
            setPendingtxsn([...sortgasLimitData]);
            setGaslimitSort(1);
        } else if (sortStatus === 2) {
            getpendingtxsn();
            setGaslimitSort(2);
        }
    };
    const handleGaspriceSort = (sortStatus) => {
        let sortgasData = [];
        setNonceSort(2);
        setGaslimitSort(2);

        if (sortStatus === 0) {
            sortgasData = pendingtxsn.sort((a, b) => a.gasPrice - b.gasPrice);
            setPendingtxsn([...sortgasData]);
            setGaspriceSort(0);
        } else if (sortStatus === 1) {
            sortgasData = pendingtxsn.sort((a, b) => b.gasPrice - a.gasPrice);
            setPendingtxsn([...sortgasData]);
            setGaspriceSort(1);
        } else if (sortStatus === 2) {
            getpendingtxsn();
            setGaspriceSort(2);
        }
    };

    const getpendingtxsn = async () => {
        setSpinner(true);
        let param = null;

        if (add) {
            param = {
                limit: PageSize,
                page: currentPage,
                address: add,
            };
        } else {
            param = {
                limit: PageSize,
                page: currentPage,
            };
        }
        await axios
            .get(`${process.env.REACT_APP_BACKEND_URL}txsPending`, {
                params: param,
            })

            .then((res) => {
                if (res.data.error) {
                    console.log("error in getpendingtxsn : ", res.data.error);
                } else {
                    setPendingtxsn(res.data.items);
                    setTotalpendingtxsn(res.data.total);
                    setPages(res.data.pages);
                    setSpinner(false);
                }
            })
            .catch((err) => {
                console.log("error in getpendingtxsn (catch) : ", err);
            });
    };

    useEffect(() => {
        getpendingtxsn();
    }, [Pagesize, currentPage, add]);

    const onNext = (page) => {
        if (page === pages) return false;

        setCurrentPage(currentPage + 1);
        history({
            pathname: "/txsPending",
            search: "?p=" + (currentPage + 1),
        });
    };

    const onPrevious = (page) => {
        if (page === 1) return false;
        setCurrentPage(currentPage - 1);

        history({
            pathname: "/txsPending",
            search: "?p=" + (currentPage - 1),
        });
    };

    return (
        <>
            <Header2 />
            <main id="content" role="main" style={{ minHeight: "800px" }}>
                <div className="container py-3">
                    <div className="d-sm-flex align-items-center">
                        <div className="mb-2 mb-sm-0">
                            <h1 className="h4 mb-0">Pending Transactions</h1>
                        </div>
                    </div>
                </div>
                <div className="container space-bottom-2">
                    <div id="ContentPlaceHolder1_mainrow" className="card">
                        <div className="card-body">
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="transfers" role="tabpanel" aria-labelledby="transfers-tab">
                                    <div class="row w-100">
                                        <div class="col-md-6">
                                            <p>A total of {totalpendingtxsn} pending txns found</p>
                                        </div>
                                    </div>
                                    <div className="table-responsive mb-2 mb-md-0">
                                        <table className="table table-hover" id="datatable">
                                            <thead className="thead-light">
                                                <tr className=" text-black">
                                                    <th>Txn Hash</th>
                                                    <th>
                                                        {nonceSort === 0 ? (
                                                            <>
                                                                <strong>
                                                                    <i class="fa fa-angle-up"></i>
                                                                </strong>
                                                                &nbsp;
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    trigger="hover"
                                                                    overlay={<Tooltip>Ascending, click to sort</Tooltip>}
                                                                >
                                                                    <Link onClick={() => handleNonceSort(1)}>Nonce</Link>
                                                                </OverlayTrigger>
                                                            </>
                                                        ) : nonceSort === 1 ? (
                                                            <>
                                                                <strong>
                                                                    <i class="fa fa-angle-down"></i>
                                                                </strong>{" "}
                                                                &nbsp;
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    trigger="hover"
                                                                    overlay={<Tooltip>Descending, click to sort</Tooltip>}
                                                                >
                                                                    <Link onClick={() => handleNonceSort(2)}>Nonce</Link>
                                                                </OverlayTrigger>
                                                            </>
                                                        ) : (
                                                            <OverlayTrigger
                                                                placement="top"
                                                                trigger="hover"
                                                                overlay={<Tooltip>Unsorted, click to sort</Tooltip>}
                                                            >
                                                                <Link onClick={() => handleNonceSort(0)}>Nonce</Link>
                                                            </OverlayTrigger>
                                                        )}
                                                    </th>

                                                    <th>
                                                        <div className="d-flex align-items-center">
                                                            <div className="mr-2">
                                                                Method{" "}
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    trigger="hover"
                                                                    overlay={
                                                                        <Tooltip>
                                                                            Function executed based on decoded input data. For unidentified functions,
                                                                            method ID is displayed instead.
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <i
                                                                        className="fal fa-info-circle"
                                                                        data-toggle="tooltip"
                                                                        data-html="true"
                                                                        data-boundary="viewport"
                                                                        title
                                                                    />
                                                                </OverlayTrigger>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th>Last Seen</th>
                                                    <th>
                                                        {gaslimitSort === 0 ? (
                                                            <>
                                                                <strong>
                                                                    <i class="fa fa-angle-up"></i>
                                                                </strong>
                                                                &nbsp;
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    trigger="hover"
                                                                    overlay={<Tooltip>Ascending, click to sort</Tooltip>}
                                                                >
                                                                    <Link onClick={() => handleGaslimitSort(1)}>Gas Limit</Link>
                                                                </OverlayTrigger>
                                                            </>
                                                        ) : gaslimitSort === 1 ? (
                                                            <>
                                                                <strong>
                                                                    <i class="fa fa-angle-down"></i>
                                                                </strong>{" "}
                                                                &nbsp;
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    trigger="hover"
                                                                    overlay={<Tooltip>Descending, click to sort</Tooltip>}
                                                                >
                                                                    <Link onClick={() => handleGaslimitSort(2)}>Gas Limit</Link>
                                                                </OverlayTrigger>
                                                            </>
                                                        ) : (
                                                            <OverlayTrigger
                                                                placement="top"
                                                                trigger="hover"
                                                                overlay={<Tooltip>Unsorted, click to sort</Tooltip>}
                                                            >
                                                                <Link onClick={() => handleGaslimitSort(0)}>Gas Limit</Link>
                                                            </OverlayTrigger>
                                                        )}
                                                    </th>
                                                    <th>
                                                        {gaspriceSort === 0 ? (
                                                            <>
                                                                <strong>
                                                                    <i class="fa fa-angle-up"></i>
                                                                </strong>
                                                                &nbsp;
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    trigger="hover"
                                                                    overlay={<Tooltip>Ascending, click to sort</Tooltip>}
                                                                >
                                                                    <Link onClick={() => handleGaspriceSort(1)}>Gas Price</Link>
                                                                </OverlayTrigger>
                                                            </>
                                                        ) : gaspriceSort === 1 ? (
                                                            <>
                                                                <strong>
                                                                    <i class="fa fa-angle-down"></i>
                                                                </strong>{" "}
                                                                &nbsp;
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    trigger="hover"
                                                                    overlay={<Tooltip>Descending, click to sort</Tooltip>}
                                                                >
                                                                    <Link onClick={() => handleGaspriceSort(2)}>Gas Price</Link>
                                                                </OverlayTrigger>
                                                            </>
                                                        ) : (
                                                            <OverlayTrigger
                                                                placement="top"
                                                                trigger="hover"
                                                                overlay={<Tooltip>Unsorted, click to sort</Tooltip>}
                                                            >
                                                                <Link onClick={() => handleGaspriceSort(0)}>Gas Price</Link>
                                                            </OverlayTrigger>
                                                        )}
                                                    </th>
                                                    <th>From</th>
                                                    <th>To</th>
                                                    <th>Value</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {spinner ? (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />{" "}
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="110" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />{" "}
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="110" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />{" "}
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="110" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />{" "}
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="110" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />{" "}
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="110" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />{" "}
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="110" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />{" "}
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="110" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />{" "}
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="110" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />{" "}
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="110" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                            </td>
                                                        </tr>
                                                    </>
                                                ) : pendingtxsn && pendingtxsn?.length ? (
                                                    pendingtxsn.map((item) => {
                                                        let val = item.value
                                                            ? Web3.utils.fromWei(
                                                                  item.value.toLocaleString("fullwide", { useGrouping: false }),
                                                                  "ether"
                                                              )
                                                            : 0;
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <Link to={`/tx/${item.hash}`} className="text-blue">
                                                                        {formatHash(item.hash)}
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    <span
                                                                        rel="tooltip"
                                                                        data-toggle="tooltip"
                                                                        data-placement="bottom"
                                                                        aria-describedby="tooltip"
                                                                    >
                                                                        {item.nonce}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        trigger="hover"
                                                                        overlay={
                                                                            <Tooltip>{item.method.name ? item.method.name : item.method.id}</Tooltip>
                                                                        }
                                                                    >
                                                                        <span
                                                                            style={{ minWidth: "68px" }}
                                                                            className="u-label u-label--xs u-label--info rounded text-dark text-center"
                                                                            data-toggle="tooltip"
                                                                            data-boundary="viewport"
                                                                            data-html="true"
                                                                            title
                                                                            data-original-title="Collect From Tiles"
                                                                        >
                                                                            {item.method.name
                                                                                ? formatMethod(item.method.name)
                                                                                : formatMethod(item.method.id)}
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </td>
                                                                <td>{item.age}</td>
                                                                <td>
                                                                    {gaslimitSort ? (
                                                                        <span rel="tooltip" data-toggle="tooltip" data-placement="bottom">
                                                                            {item.gasLimit}
                                                                        </span>
                                                                    ) : (
                                                                        <span rel="tooltip" data-toggle="tooltip" data-placement="bottom">
                                                                            {item.gasLimit}
                                                                        </span>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {gaspriceSort ? (
                                                                        <span rel="tooltip" data-toggle="tooltip" data-placement="bottom">
                                                                            {item.gasPrice}
                                                                        </span>
                                                                    ) : (
                                                                        <span rel="tooltip" data-toggle="tooltip" data-placement="bottom">
                                                                            {item.gasPrice}
                                                                        </span>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    <Link
                                                                        to={item.from !== null ? `/address/${item.from}` : "#"}
                                                                        className="text-blue"
                                                                    >
                                                                        {formatHash(item.from)}
                                                                    </Link>
                                                                    <Link to={item.from !== null ? `/txsPending?a=${item.from}` : "#"}>
                                                                        <i
                                                                            class="fa fa-filter  text-secondary small"
                                                                            data-toggle="tooltip"
                                                                            title=""
                                                                            data-original-title="Apply filter by address"
                                                                        ></i>
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    <Link to={item.to !== null ? `/address/${item.to}` : "#"} className="text-blue">
                                                                        {formatHash(item.to)}
                                                                    </Link>
                                                                    <Link to={item.to !== null ? `/txsPending?a=${item.to}` : "#"}>
                                                                        <i
                                                                            class="fa fa-filter text-secondary small"
                                                                            data-toggle="tooltip"
                                                                            title=""
                                                                            data-original-title="Apply filter by address"
                                                                        ></i>
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    {Number(val) === 0 ? 0 : Number(val).toFixed(7)} {process.env.REACT_APP_TICKER}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <NoEntryMessage message={process.env.REACT_APP_BLANK_DATA_MESSAGE} />
                                                )}
                                            </tbody>
                                        </table>
                                        {pendingtxsn && pendingtxsn?.length ? (
                                            <div class="row w-100 mt-3">
                                                <div class="col-md-6 mt-3">
                                                    <Pagesize setPageSize={setPageSize} PageSize={PageSize} />
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <div className="float-right mr-3">
                                                        <div class="row">
                                                            <button
                                                                class={currentPage === 1 ? "page-link disabled" : "page-link"}
                                                                onClick={() => {
                                                                    setCurrentPage(1);
                                                                    history({
                                                                        pathname: "/txsPending",
                                                                        search: "?p=" + 1,
                                                                    });
                                                                }}
                                                                disabled={currentPage === 1}
                                                            >
                                                                First
                                                            </button>
                                                            <button
                                                                class={currentPage === 1 ? "page-link disabled" : "page-link"}
                                                                onClick={() => onPrevious(currentPage)}
                                                                disabled={currentPage === 1}
                                                            >
                                                                <i className="fa fa-angle-left" />
                                                            </button>
                                                            <div class="page-link-badge" disabled>
                                                                {" "}
                                                                {`${currentPage} of ${pages}`}
                                                            </div>
                                                            <button
                                                                class={currentPage === pages ? "page-link disabled" : "page-link"}
                                                                onClick={() => onNext(currentPage)}
                                                                disabled={currentPage === pages}
                                                            >
                                                                <i className="fa fa-angle-right" />
                                                            </button>
                                                            <button
                                                                class={currentPage === pages ? "page-link disabled" : "page-link"}
                                                                onClick={() => {
                                                                    setCurrentPage(pages);
                                                                    history({
                                                                        pathname: "/txsPending",
                                                                        search: "?p=" + pages,
                                                                    });
                                                                }}
                                                                disabled={currentPage === pages}
                                                            >
                                                                Last
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default ViewPendingTransaction;
