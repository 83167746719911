import React, { useState, useEffect, useContext } from "react";
import Igtprice from "./Cards/IgtPrice";
import Totalsupply from "./Cards/TotalSupply";
import Totaltransactions from "./Cards/TotalTransactions";
import Totalblocks from "./Cards/totalBlocks";
import Totalaccounts from "./Cards/TotalAccounts";
import Marketcap from "./Cards/MarketCap";
import Epoch from "./Cards/Epoch";
import Gasprice from "./Cards/GasPrice";
import axios from "axios";
import { IgniteContext } from "../IgniteContext";
import TxChart from "../common/TxChart";
import TxChart2 from "../common/TxChart2";

const HomeStatisticsBox = () => {
  const { nFormatter, setGas } = useContext(IgniteContext);
  const [cardDetails, setCardDetails] = useState(null);

  const getCardDetails = async () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}home`)
      .then((res) => {
        if (res.data.error) {
          console.log("error in getCardDetails : ", res.data.error);
        } else {
          setGas(res.data.gwei);
          setCardDetails(res.data);
        }
      })
      .catch((err) => {
        console.log("error in getCardDetails (catch) : ", err);
      });
  };

  useEffect(() => {
    setInterval(() => {
      getCardDetails();
    }, 3000);
  }, []);

  return (
    <div>
      <div id="ContentPlaceHolder1_mainboxes" className="card mb-4">
        <div className="card-body p-4">
          <div className="row mx-gutters-md-1 align-items-center">
            <div className="col-md-6 col-lg-3">
              {/* <Igtprice price={cardDetails ? cardDetails?.price.toString() : "0"} /> */}
              <Igtprice latestblock={cardDetails ? cardDetails?.totalBlocks : "0"} />
              <hr className="hr-space-lg" />
              <Marketcap marketCap={cardDetails ? cardDetails?.marketCap.toString() : "0"} />
              <hr className="d-block d-md-none hr-space-lg" />
            </div>
            <div className="col-md-6 col-lg-3 u-ver-divider u-ver-divider--left u-ver-divider--none-md">
              <Totalblocks
                totalBlocks={cardDetails ? nFormatter(cardDetails?.totalBlocks.toString()) : "0"}
              />
              <hr className="hr-space-lg" />
              <Totaltransactions
                totalTransactions={cardDetails ? nFormatter(cardDetails?.totalTxs.toString()) : "0"}
              />
              <hr className="d-block d-md-none hr-space-lg" />
            </div>
            <div className="col-md-6 col-lg-3 u-ver-divider u-ver-divider--left u-ver-divider--none-md">
              <Totalsupply totalSupply={cardDetails ? cardDetails?.totalSupply.toString() : "0"} />
              <hr className="hr-space-lg" />
              <Totalaccounts
                totalAccounts={cardDetails ? nFormatter(cardDetails?.totalAccounts.toString()) : "0"}
              />
              <hr className="d-block d-md-none hr-space-lg" />
            </div>
            <div className="col-md-6 col-lg-3 u-ver-divider u-ver-divider--left u-ver-divider--none-md">
              {/* <Gasprice gasPrice={cardDetails ? Number(cardDetails?.gwei).toFixed(2).toString() : "0"} />
                            <hr className="hr-space-lg" />
                            <Epoch epoch={cardDetails ? cardDetails?.epoch.toString() : "0"} />
                            <hr className="d-block d-md-none hr-space-lg" /> */}
              <div className="row d-flex justify-content-center p-0 m-0">
                <div
                  className="col-12 card d-flex justify-content-center align-items-center p-0 m-0"
                  style={{ height: "130px", width: "350px" }}
                >
                  {/* <TxChart /> */}
                  <TxChart2 />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeStatisticsBox;
