import React, { useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import axios from "axios";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const Inputdata = ({ inputData }) => {
    const { hash } = useParams();

    const [defaultInput, setDefaultInput] = useState(null);
    const [utf8Input, setutf8Input] = useState(null);
    const [originalInput, setoriginalInput] = useState(null);
    const [decodedInputData, setDecodedInputData] = useState(null);

    const [inputFieldData, setInputFieldData] = useState("");

    const [formateInputData, setFormateInputData] = useState(1);
    const [decodedData, setDecodeData] = useState(false);

    const popoverTop12 = (
        <Popover id="popover-basic">
            <Popover.Body>Additional information that is required for the transaction.</Popover.Body>
        </Popover>
    );

    useEffect(() => {
        getInputData();
    }, [inputData]);

    useEffect(() => {
        setInputData();
    }, [formateInputData]);

    const setInputData = () => {
        let input = "";
        if (formateInputData === 0) {
            if (inputData?.default) {
                input = "Function: " + defaultInput?.function + "\n\n" + "methodId: " + defaultInput.methodId;
                for (let i = 0; i < defaultInput.inputs.length; i++) {
                    input += "\n[" + i + "]: " + defaultInput.inputs[i];
                }
            } else {
                input = inputData?.original;
                setFormateInputData(2);
            }
        } else if (formateInputData === 1) {
            if (inputData?.utf8) {
                input = inputData?.utf8;
            }
        } else if (formateInputData === 2) {
            if (inputData?.original) {
                input = inputData?.original;
            }
        }
        setInputFieldData(input);
    };

    const getInputData = async () => {
        setDefaultInput(inputData?.default ? inputData?.default : null);
        setutf8Input(inputData?.utf8 ? inputData?.utf8 : null);
        setoriginalInput(inputData?.original ? inputData.original : null);
        setDecodedInputData(inputData?.decoded ? inputData?.decoded : null);
        setFormateInputData(0);
    };

    const handleDeocdeInputData = (val) => {
        setDecodeData(val);
    };
    return (
        <>
            <div className="row">
                <div className="col-md-3 mb-1 bs h6 text-blueish">
                    <OverlayTrigger style={{ padding: "100px 0" }} trigger="hover" placement="top" overlay={popoverTop12}>
                        <i class="fal fa-question-circle text-secondary mr-1" aria-hidden="true"></i>
                    </OverlayTrigger>{" "}
                    Input Data
                </div>
                {typeof inputData === "object" ? (
                    <div className="col-md-8 mb-1 bs text-dark">
                        {decodedData === true ? (
                            <div id="decodebox" style={{}}>
                                <div
                                    className="js-scrollbar scrollbox mCustomScrollbar _mCS_1 mCS-autoHide"
                                    style={{ position: "relative", overflow: "visible" }}
                                >
                                    <div
                                        id="mCSB_1"
                                        className="mCustomScrollBox mCS-minimal-dark mCSB_vertical mCSB_outside"
                                        style={{ maxHeight: "168px" }}
                                        tabIndex={0}
                                    >
                                        <div
                                            id="mCSB_1_container"
                                            className="mCSB_container"
                                            style={{ position: "relative", top: "0px", left: "0px" }}
                                            dir="ltr"
                                        >
                                            <div id="inputDecode" className="bg-light table-responsive">
                                                <table className="table table-sm table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th style={{ width: "5%" }}>#</th>
                                                            <th>Name</th>
                                                            <th>Type</th>
                                                            <th>Data</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {decodedInputData?.length !== 0 &&
                                                            decodedInputData?.map((item, ind) => {
                                                                return (
                                                                    <tr>
                                                                        <td>{item.id}</td>
                                                                        <td>{item.name}</td>
                                                                        <td>{item.type}</td>
                                                                        <td>
                                                                            <span style={{ textOverflow: "ellipsis" }}>
                                                                                {typeof Array.isArray(item?.value) ? (
                                                                                    item?.isAddress ? (
                                                                                        item.data &&
                                                                                        item.data.map((itemadd, indadd) => {
                                                                                            return (
                                                                                                <>
                                                                                                    <a href={`/address/${itemadd}`}>{`${itemadd}`}</a>
                                                                                                    {`${indadd < item?.data?.length - 1 ? ", " : ""}`}
                                                                                                    {/* {", "} */}
                                                                                                </>
                                                                                            );
                                                                                        })
                                                                                    ) : (
                                                                                        item.value
                                                                                    )
                                                                                ) : (
                                                                                    <a href={`/address/${item.data}`}>{item.data}</a>
                                                                                )}
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    className="js-animation-link btn btn-xss btn-secondary mt-2"
                                    onClick={() => {
                                        handleDeocdeInputData(false);
                                    }}
                                >
                                    <i className="fa fa-undo txhash-icon mr-1" />
                                    Switch Back
                                </button>
                            </div>
                        ) : (
                            <>
                                <textarea
                                    readOnly
                                    spellCheck="false"
                                    className="form-control bg-light text-secondary text-monospace p-3"
                                    rows={6}
                                    id="extraData"
                                    defaultValue={inputFieldData}
                                />
                                <div class="row mt-3">
                                    <div class="dropdown mx-3">
                                        <button
                                            class="btn btn-xs btn-secondary dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            View input as
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            {inputData?.default ? (
                                                <button
                                                    class={`dropdown-item ${formateInputData === 0 ? "active" : ""}`}
                                                    onClick={() => setFormateInputData(0)}
                                                >
                                                    Default
                                                </button>
                                            ) : (
                                                ""
                                            )}
                                            <button
                                                class={`dropdown-item ${formateInputData === 1 ? "active" : ""}`}
                                                onClick={() => setFormateInputData(1)}
                                            >
                                                UTF-8
                                            </button>
                                            <button
                                                class={`dropdown-item ${formateInputData === 2 ? "active" : ""}`}
                                                onClick={() => setFormateInputData(2)}
                                            >
                                                Original
                                            </button>
                                        </div>
                                    </div>
                                    {inputData?.decoded ? (
                                        <button
                                            class="btn btn-xs btn-secondary"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            onClick={() => {
                                                handleDeocdeInputData(true);
                                            }}
                                        >
                                            <i class="fa fa-cubes mr-1"></i> decode Input Data
                                        </button>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                ) : (
                    <div className="col-md-8 mb-1 bs text-dark">
                        <textarea
                            readOnly
                            spellCheck="false"
                            className="form-control bg-light text-secondary text-monospace p-3"
                            rows={6}
                            id="extraData"
                            defaultValue={inputData && inputData}
                        />
                    </div>
                )}
            </div>
            <hr />
        </>
    );
};

export default Inputdata;
