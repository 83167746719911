import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import CryptoJS from "crypto-js";
import { IgniteContext } from "../../IgniteContext";
import axios from "axios";
import { getLocalStorage } from "../../../utils";

const UserProfiler = () => {
    const pathName = window.location.pathname;

    const { logout } = useContext(IgniteContext);

    const signIn = ["/myprofile", "/token-nft", "/token-txns-nft"];

    const [isHoverMyprofile1, setIsHoverMyprofile1] = useState(false);
    const [isHoverMyprofile2, setIsHoverMyprofile2] = useState(false);
    const [isLogin, setIslogin] = useState(false);

    const username = getLocalStorage("ua5s21san")
        ? CryptoJS.AES.decrypt(getLocalStorage("ua5s21san"), process.env.REACT_APP_PASSWORD).toString(CryptoJS.enc.Utf8)
        : null;
    const email = getLocalStorage("ea4sd4fam")
        ? CryptoJS.AES.decrypt(getLocalStorage("ea4sd4fam"), process.env.REACT_APP_PASSWORD).toString(CryptoJS.enc.Utf8)
        : null;
    const password = getLocalStorage("pn4sec3f1s")
        ? CryptoJS.AES.decrypt(getLocalStorage("pn4sec3f1s"), process.env.REACT_APP_PASSWORD).toString(CryptoJS.enc.Utf8)
        : null;

    const verifyUser = async () => {
        if (username && password) {
            const encBody = CryptoJS.AES.encrypt(
                JSON.stringify({
                    username: username,
                    password: password,
                    timestemp: Math.round(Date.now() / 1000),
                }),
                process.env.REACT_APP_PASSWORD
            ).toString();
            await axios
                .post(`${process.env.REACT_APP_BACKEND_URL}user/signin`, {
                    token: encBody,
                })
                .then((res) => {
                    if (res.data.error) {
                        localStorage.removeItem("ua5s21san");
                        localStorage.removeItem("ea4sd4fam");
                        localStorage.removeItem("pn4sec3f1s");
                        localStorage.removeItem("ls5sdf4al");
                    } else {
                        setIslogin(true);
                    }
                })
                .catch((err) => {
                    console.log("error in userLogin (cathc) : ", err);
                });
        }
    };
    useEffect(() => {
        verifyUser();
    }, []);

    return (
        <>
            <li className="u-header__nav-separator active" />
            {!username || !email || !password ? (
                <li className={`nav-item hs-has-sub-menu u-header__nav-item ${pathName === "/login" ? "active" : ""}`}>
                    <a className="u-header__nav-link navbar_signin" href="/login">
                        <i className={`fa fa-user-circle mr-1`} />
                        Sign In
                    </a>
                </li>
            ) : (
                <li
                    id="LI_resources"
                    className={
                        signIn.includes(pathName)
                            ? `nav-item hs-has-sub-menu u-header__nav-item active ${isHoverMyprofile1 ? "hs-sub-menu-opened" : ""}`
                            : `nav-item hs-has-sub-menu u-header__nav-item ${isHoverMyprofile2 ? "hs-sub-menu-opened" : ""}`
                    }
                    onMouseEnter={() => {
                        setIsHoverMyprofile1(true);
                        setIsHoverMyprofile2(true);
                    }}
                    onMouseLeave={() => {
                        setIsHoverMyprofile1(false);
                        setIsHoverMyprofile2(false);
                    }}
                    data-event="hover"
                    data-animation-in="slideInUp"
                    data-animation-out="fadeOut"
                >
                    {/* eslint-disable-next-line */}
                    <a
                        href="javascript:void(0);"
                        id="resourcesMegaMenu"
                        className="nav-link u-header__nav-link u-header__nav-link-toggle"
                        aria-haspopup="true"
                        aria-expanded="false"
                        aria-labelledby="resourcesSubMenu"
                    >
                        <i className="fa fa-user-circle mr-1" />
                        {username.toUpperCase()}
                    </a>
                    <ul
                        id="testTokensSubMenu"
                        className={`hs-sub-menu u-header__sub-menu  u-header__sub-menu--spacer ${
                            isHoverMyprofile1 ? "fadeOut custom-drop-hover-none" : "slideInUp custom-drop-hover"
                        }`}
                        aria-labelledby="testTokensMegaMenu"
                        style={{ minWidth: "190px" }}
                    >
                        <li id="LI18">
                            <Link className="nav-link u-header__sub-menu-nav-link" to="/myaccount">
                                My Profile
                            </Link>
                        </li>
                        <li className="dropdown-divider" />
                        <li id="LI42">
                            <Link className="nav-link u-header__sub-menu-nav-link" to="/mynotes_tx">
                                Txn Private Notes
                            </Link>
                        </li>
                        <li id="LI40">
                            <Link className="nav-link u-header__sub-menu-nav-link" to="/mynotes_address">
                                Address Private Notes
                            </Link>
                        </li>
                        {/* <li id="LI40">
                            <Link className="nav-link u-header__sub-menu-nav-link" to="/MytokenList">
                                Token List
                            </Link>
                        </li> */}
                        <li className="dropdown-divider" />
                        <li className="px-3">
                            <button type="button" className="btn btn-xs btn-block btn-soft-primary" onClick={logout}>
                                Sign Out
                            </button>
                        </li>
                    </ul>
                </li>
            )}

            <li className="u-header__nav-separator" />
        </>
    );
};

export default UserProfiler;
