import React, { useState } from "react";
import { Link } from "react-router-dom";

const Validators = () => {
    const pathName = window.location.pathname;
    const last = pathName.substring(pathName.lastIndexOf("/") + 1, pathName.length);

    const validatores = ["validators"];

    const findPathValidatores = validatores.find((e) => e === last);
    const [isHoverValidators1, setIsHoverValidators1] = useState(false);

    const [isHoverValidators2, setIsHoverValidators2] = useState(false);

    return (
        <li
            id="LI_Tokens"
            className={
                last === findPathValidatores
                    ? `nav-item hs-has-sub-menu u-header__nav-item active ${isHoverValidators1 ? "hs-sub-menu-opened" : ""}`
                    : `nav-item hs-has-sub-menu u-header__nav-item ${isHoverValidators2 ? "hs-sub-menu-opened" : ""}`
            }
            onMouseEnter={() => {
                setIsHoverValidators1(true);
                setIsHoverValidators2(true);
            }}
            onMouseLeave={() => {
                setIsHoverValidators1(false);
                setIsHoverValidators2(false);
            }}
            data-event="hover"
            data-animation-in="slideInUp"
            data-animation-out="fadeOut"
        >
            {/* eslint-disable-next-line */}
            <a
                id="testTokensMegaMenu"
                className="nav-link u-header__nav-link u-header__nav-link-toggle"
                href="javascript:void(0);"
                aria-haspopup="true"
                aria-expanded="false"
                aria-labelledby="testTokensSubMenu"
            >
                Validators
            </a>
            <ul
                id="testTokensSubMenu"
                className={`hs-sub-menu u-header__sub-menu  u-header__sub-menu--spacer ${
                    isHoverValidators1 ? "fadeOut custom-drop-hover-none" : "slideInUp custom-drop-hover"
                }`}
                aria-labelledby="testTokensMegaMenu"
            >
                <li id="LI18" className={`${pathName === "/validators" ? "text-primary active" : ""}`}>
                    <Link className="nav-link u-header__sub-menu-nav-link" to="/validators">
                        Validators Leaderboard
                    </Link>
                </li>
            </ul>
        </li>
    );
};

export default Validators;
