import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Copyicon from "../../common/Copy/Copyicon";
import Copymsg from "../../common/Copy/Copymsg";
import delay from "delay";

import { OverlayTrigger, Popover } from "react-bootstrap";

const Hash = ({ hash }) => {
    const [getHashCopy, setCopyHash] = useState("");

    async function copyhash() {
        setCopyHash("copied");
        await delay(1000);
        setCopyHash("");
    }

    const popoverTop1 = (
        <Popover id="popover-basic">
            <Popover.Body>
                A TxHash or transaction hash is a unique 66 characters identifier that is generated whenever a transaction is executed.
            </Popover.Body>
        </Popover>
    );
    return (
        <>
            <div className="row">
                <div className="col-md-3 mb-1 bs  h6 text-blueish">
                    <OverlayTrigger style={{ padding: "100px 0" }} trigger="hover" placement="top" overlay={popoverTop1}>
                        <i class="fal fa-question-circle text-secondary mr-1" aria-hidden="true"></i>
                    </OverlayTrigger>
                    TXN#
                </div>
                <div className="col-md-8 mb-1 bs text-dark bs" key={hash}>
                    {hash}

                    <CopyToClipboard text={hash} onCopy={() => copyhash()}>
                        <span>{getHashCopy === "" ? <Copyicon /> : <Copymsg />} </span>
                    </CopyToClipboard>
                </div>
            </div>
            <hr />
        </>
    );
};

export default Hash;
