import React, { useContext, useEffect, useState } from "react";
import { IgniteContext } from "../../IgniteContext";

const Logo = () => {
  const { theme } = useContext(IgniteContext);
  return (
    <>
      {theme === "dark" ? (
        <img
          id="logo-header"
          width={175}
          src="/asset/images/stor-dark.png"
          alt={`${process.env.REACT_APP_TICKER} Logo not found.`}
        />
      ) : (
        <img
          id="logo-header"
          width={175}
          src="/asset/images/stor-light.png"
          alt={`${process.env.REACT_APP_TICKER} Logo not found.`}
        />
      )}
    </>
  );
};

export default Logo;
