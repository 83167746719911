import React, { useContext } from "react";
import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { IgniteContext } from "../../IgniteContext";

const Totaltransactions = ({ totalTransactions }) => {
  const { theme } = useContext(IgniteContext);
  return (
    <div className="media align-items-center">
      <figure className="mx-4">
        {
          theme === "normal" ? (
            <i className="fas fa-server homepage_icon fa-2x"></i>
          ) : (
            <i className="fas fa-server  fa-2x homepage_icon"></i>
          )
          // <img src="asset/images/transactions-light.png" alt="SVG" className="homepage_icon" /> :
          // <img src="asset/images/transactions-dark.png" alt="SVG" className="homepage_icon" />
        }
      </figure>
      <div className="media-body text-left">
        <h2 className="font-size-1 text-uppercase text-secondary mb-0">Total Transactions</h2>
        <OverlayTrigger placement="left" trigger="hover" overlay={<Tooltip>Total Transactions</Tooltip>}>
          <Link
            to="/txs?p=1"
            className="text-size-1 text-link"
            rel="tooltip"
            data-toggle="tooltip"
            data-placement="left"
            data-html="true"
            title=""
          >
            {totalTransactions}
          </Link>
        </OverlayTrigger>
      </div>
      <div className="text-right" />
    </div>
  );
};

export default Totaltransactions;
