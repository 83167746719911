import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IgniteContext } from "../IgniteContext";
import axios from "axios";
import LatestBlocksLoader from "../common/PageContentLoaders/LatestBlocksLoader";

const LatestBlocks = () => {
    let lastblock2 = 0;
    const { formatAddress } = useContext(IgniteContext);
    const [blocksData, setBlocksData] = useState(null);
    const [lastblock, setLastblock] = useState(0);
    const [spinner, setSpinner] = useState(true);

    const getBlocksData = async () => {
        setSpinner(true);
        axios
            .get(`${process.env.REACT_APP_BACKEND_URL}blocks/home`)
            .then((res) => {
                if (res.data.error) {
                    console.log("error in home blocks : ", res.data.error);
                } else {
                    const dispblock = res.data;
                    lastblock2 = dispblock[0].hieght;
                    setBlocksData([...dispblock]);
                    setSpinner(false);
                }
            })
            .catch((err) => {
                console.log("error in home blocks (catch): ", err);
            });
    };
    const getBlocksData2 = async () => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_URL}blocks/home`)
            .then((res) => {
                if (res.data.error) {
                    console.log("error in home blocks : ", res.data.error);
                } else {
                    let prevdata = lastblock2;
                    setLastblock(prevdata);
                    lastblock2 = res.data[0].hieght;
                    setBlocksData(res.data);
                }
            })
            .catch((err) => {
                console.log("error in home blocks (catch): ", err);
            });
    };
    useEffect(() => {
        getBlocksData();
    }, []);

    useEffect(() => {
        setInterval(() => {
            getBlocksData2();
        }, 1000);
    }, []);
    return (
        <>
            <div className="col-lg-6 mb-4 mb-lg-0">
                <div className="card w-100">
                    <div className="card-header">
                        <h2 className="card-header-title text-left">Latest Blocks</h2>
                    </div>
                    <div className="scrollbar_area card-body example" style={{ height: "400px" }}>
                        {spinner ? (
                            <LatestBlocksLoader />
                        ) : blocksData && blocksData?.length ? (
                            blocksData.map((items, ind) => {
                                return (
                                    <>
                                        <div key={items.hieght} className={`row ${Number(items.hieght) > Number(lastblock) ? "fade-in-anim" : ""}`}>
                                            {/* <div key={items.hieght} className="row"> */}
                                            <div className="col-sm-4">
                                                <div className="media align-items-sm-center mr-4 mb-1 mb-sm-0">
                                                    <div className="d-none d-sm-flex mr-2">
                                                        <span className="btn btn-icon btn-soft-secondary p-2">
                                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1.6rem" width="1.6rem" xmlns="http://www.w3.org/2000/svg"><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M448 341.37V170.61A32 32 0 0 0 432.11 143l-152-88.46a47.94 47.94 0 0 0-48.24 0L79.89 143A32 32 0 0 0 64 170.61v170.76A32 32 0 0 0 79.89 369l152 88.46a48 48 0 0 0 48.24 0l152-88.46A32 32 0 0 0 448 341.37z"></path><path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="m69 153.99 187 110 187-110m-187 310v-200"></path></svg>
                                                        </span>
                                                    </div>
                                                    <div className="media-body text-left">
                                                        <span className="d-inline-block d-sm-none">Block</span>
                                                        <Link to={`block/${items.hieght}`}>{items.hieght}</Link>
                                                        <span
                                                            className="d-sm-block small text-secondary ml-1 ml-sm-0 text-nowrap"
                                                            data-countdown={13000}
                                                        >
                                                            {items.age}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-8">
                                                <div className="d-flex justify-content-between">
                                                    <div className="text-nowrap text-left">
                                                        <span className="d-block mb-1 mb-sm-0">
                                                            Validated By{" "}
                                                            <Link className="hash-tag text-truncate" to={`/address/${items.miner}`}>
                                                                {formatAddress(items.miner)}
                                                            </Link>
                                                        </span>
                                                        {Number(items.txn) === 0 ? (
                                                            <OverlayTrigger
                                                                placement="top"
                                                                trigger="hover"
                                                                overlay={<Tooltip>Transactions in this Block</Tooltip>}
                                                            >
                                                                <span>{`${items.txn} txn`}</span>
                                                            </OverlayTrigger>
                                                        ) : (
                                                            <OverlayTrigger
                                                                placement="top"
                                                                trigger="hover"
                                                                overlay={<Tooltip>Transactions in this Block</Tooltip>}
                                                            >
                                                                <Link
                                                                    to={{
                                                                        pathname: "/txs",
                                                                        search: "?block=" + items.hieght,
                                                                    }}
                                                                >
                                                                    {`${items.txn} txn`}
                                                                </Link>
                                                            </OverlayTrigger>
                                                        )}
                                                        <span className="small text-secondary"> in 3 seconds </span>
                                                    </div>
                                                    <OverlayTrigger placement="top" trigger="hover" overlay={<Tooltip>Block Reward</Tooltip>}>
                                                        <div className="d-block d-sm-block">
                                                            <span
                                                                className="u-label u-label--xs u-label--badge-in u-label--secondary text-center text-nowrap"
                                                                data-toggle="tooltip"
                                                            >
                                                                {Number(items.reward) !== 0
                                                                    ? Number(items.reward).toFixed(5) + " " + process.env.REACT_APP_TICKER
                                                                    : "0" + " " + process.env.REACT_APP_TICKER}
                                                            </span>
                                                        </div>
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="hr-space" />
                                    </>
                                );
                            })
                        ) : (
                            <p className="text-black container" style={{ textAlign: "center" }}>
                                No Blocks Found
                            </p>
                        )}
                    </div>

                    <div className="card-footer">
                        <Link className="btn btn-xs btn-block btn-soft-primary card_footer_background" to="/blocks">
                            View all blocks
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LatestBlocks;
