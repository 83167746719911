import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams, Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Web3 from "web3";
import AceEditor from "react-ace";
import "brace/mode/javascript";
import "brace/theme/monokai";
import Header2 from "../common/Header2";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "react-qr-code";
import delay from "delay";
import Spinner from "../common/Spinner";
import Pagesize from "../common/Pagesize";
import Pagination from "../common/Pagination";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Dropdown } from "react-bootstrap";
import Footer from "../common/Footer";
import { IgniteContext } from "../IgniteContext";
import Erc20TokenTxns from "./tokenTransactions/Erc20TokenTxns";
import Erc721TokenTxns from "./tokenTransactions/Erc721TokenTxns";
import Erc1155TokenTxns from "./tokenTransactions/Erc1155TokenTxns";
import ContractDetailsForAddress from "../tokenDetails/contractDetails/ContractDetailsForAddress";
import { useSearchParams } from "react-router-dom";
import NoEntryMessage from "../common/NoEntryMessage";
import ValidatedBlocks from "./ValidatedBlocks";
import CryptoJS from "crypto-js";
import FadingLoaderCard1 from "../common/ContentLoaders/LoaderBigLine";
import { formatAmountFromWei } from "../common/functions";

function Address() {
  const { formatAddress, formatHash, formatDate, formatMethod, verifyAndGetUser } = useContext(IgniteContext);
  const { encusername, isLogin, username, password } = verifyAndGetUser();
  const [searchParams] = useSearchParams();
  const fromaddress = searchParams.get("fromaddress");
  const toaddress = searchParams.get("toaddress");

  const [ageColChange, setAgeColChange] = useState(true);

  const mainTgRef = useRef();
  const toTgRef = useRef();
  const fromTgRef = useRef();

  const toTgBtnRef = useRef();
  const fromTgBtnRef = useRef();

  const btnToggleFileterMenu = useRef();

  const moreInfoRef = useRef();
  const moreInfoTMBtn = useRef();

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { hash, search } = location;

  document.title = `Address ${id}`;

  const valid = Web3.utils.isAddress(id.toString());

  const [toggleFileterMenu, setToggleFilterMenu] = useState(false);
  const [toToggleFilter, setToToggleFilter] = useState(false);
  const [fromToggleFilter, setFromToggleFilter] = useState(false);

  const [moreInfoToggleMenu, setMoreInfoToggleMenu] = useState(false);

  const [fromFilterInput, setFromFilterInput] = useState(
    fromaddress === null && fromaddress === "" ? "" : fromaddress
  );
  const [toFilterInput, setToFilterInput] = useState(toaddress === null && toaddress === "" ? "" : toaddress);
  // const [toFilterStatus, setToFilterStatus] = useState(false);
  // const [fromFilterStatus, setFromFilterStatus] = useState(false);

  const [addressDetails, setAddressDetails] = useState(null);
  const [contractDeta, setContractDeta] = useState(null);
  const [tokenHoldingsErc20, setTokenHoldingsErc20] = useState([]);
  const [tokenHoldingsErc721, setTokenHoldingsErc721] = useState([]);
  const [tokenHoldingsErc1155, setTokenHoldingsErc1155] = useState([]);
  const [totalTokenHolding, setTotalTokenHolding] = useState(0);
  const [state, setState] = useState("");
  const [isValid, setisValid] = useState(true);
  const [addressTransactions, setAddressTransaction] = useState([]);
  const [totalAddTrx, setTotalAddTrx] = useState(0);
  const [contractSpinner, setContractSpinner] = useState(true);

  const [intAgeColChange, setintAgeColChange] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [addDetSpinner, setAddDetSpinner] = useState(true);
  const [spinner, setSpinner] = useState(true);
  const [PageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(0);

  const [erc20CurrentPage, setErc20CurrentPage] = useState(1);
  const [erc20Spinner, setErc20Spinner] = useState(true);
  const [erc20Tokens, setErc20Tokens] = useState([]);
  const [erc20Pages, setErc20Pages] = useState(0);
  const [erc20Total, setErc20Total] = useState(0);
  const [erc20PageSize, setErc20PageSize] = useState(10);

  const [erc721CurrentPage, setErc721CurrentPage] = useState(1);
  const [erc721Spinner, setErc721Spinner] = useState(true);
  const [erc721Tokens, setErc721Tokens] = useState([]);
  const [erc721Pages, setErc721Pages] = useState(0);
  const [erc721Total, setErc721Total] = useState(0);
  const [erc721PageSize, setErc721PageSize] = useState(10);

  const [erc1155CurrentPage, setErc1155CurrentPage] = useState(1);
  const [erc1155Spinner, setErc1155Spinner] = useState(true);
  const [erc1155Tokens, setErc1155Tokens] = useState([]);
  const [erc1155Pages, setErc1155Pages] = useState(0);
  const [erc1155Total, setErc1155Total] = useState(0);
  const [erc1155PageSize, setErc1155PageSize] = useState(10);

  const [searchToken, setSearchToken] = useState(null);
  const [getSearchResult, setSearchResult] = useState(null);

  const [internalTxs, setInternalTxs] = useState([]);
  const [interTxsSpinner, setInterTxsSpinner] = useState(true);
  const [intPageSize, setIntPageSize] = useState(10);
  const [intCurrentPage, setIntCurrentPage] = useState(1);
  const [totalIntTxs, setTotalIntTxs] = useState(0);
  const [intPages, setIntPages] = useState(0);

  // name tagg states
  const [address, setAddress] = useState("");
  const [nameTag, setNameTag] = useState("");
  const [addNotes, setAddNotes] = useState("");
  const [editAddNote, setEditAddNote] = useState("");

  const [nameTagError, setNameTagError] = useState(null);
  const [addNotesError, setAddNotesError] = useState(null);

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const handleEditAddNote = (address, name, note) => {
    setEditAddNote(address);
    setAddress(address);
    setNameTag(name);
    setAddNotes(note);
    setErrorMsg("");
    setSuccessMsg("");
    setNameTagError(null);
    setAddNotesError(null);
  };

  const handleNameTagChange = (val) => {
    setNameTag(val);
    if (val === "") {
      setNameTagError("Require.");
    } else {
      setNameTagError("");
    }
  };
  const handleNoteChange = (val) => {
    setAddNotes(val);
    if (val === "") {
      setAddNotesError("Require.");
    } else {
      setAddNotesError("");
    }
  };

  const clearStates = () => {
    setErrorMsg("");
    setSuccessMsg("");
    setNameTagError(null);
    setAddNotesError(null);
    setAddress("");
    setNameTag("");
    setAddNotes("");
    setEditAddNote("");
  };

  const addAddressNote = async (e) => {
    let temp = 1;
    setNameTagError(null);
    setAddNotesError(null);
    if (nameTag === "") {
      setNameTagError("Require.");
      temp = 0;
    } else {
      setNameTagError("");
    }
    if (addNotes === "") {
      setAddNotesError("Require.");
      temp = 0;
    } else {
      setAddNotesError("");
    }
    if (temp) {
      const encBody = CryptoJS.AES.encrypt(
        JSON.stringify({
          username: username,
          password: password,
          address: address,
          note: addNotes,
          name: nameTag,
          action: "insert",
          page: currentPage,
          limit: PageSize,
          timestemp: Math.round(Date.now() / 1000),
        }),
        process.env.REACT_APP_PASSWORD
      ).toString();
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}user/addressprivatenote`, {
          token: encBody,
        })
        .then((res) => {
          if (res.data.error) {
            setErrorMsg(res.data.error);
          } else {
            setSuccessMsg(res.data.message);
            getAddressData();
          }
        });
    }
  };

  const updateAddressNote = async (e) => {
    let temp = 1;
    setNameTagError(null);
    setAddNotesError(null);
    if (nameTag === "") {
      setNameTagError("Require.");
      temp = 0;
    } else {
      setNameTagError("");
    }
    if (addNotes === "") {
      setAddNotesError("Require.");
      temp = 0;
    } else {
      setAddNotesError("");
    }
    if (temp) {
      const encBody = CryptoJS.AES.encrypt(
        JSON.stringify({
          username: username,
          password: password,
          address: address,
          note: addNotes,
          name: nameTag,
          action: "update",
          page: currentPage,
          limit: PageSize,
          timestemp: Math.round(Date.now() / 1000),
        }),
        process.env.REACT_APP_PASSWORD
      ).toString();
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}user/addressprivatenote`, {
          token: encBody,
        })
        .then((res) => {
          if (res.data.error) {
            setErrorMsg(res.data.error);
          } else {
            setSuccessMsg(res.data.message);
            setEditAddNote("");
            getAddressData();
          }
        });
    }
  };

  const getinternaltsxn = async () => {
    setInterTxsSpinner(true);
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}txs/internal/${addressDetails?.address}?limit=${intPageSize}&page=${intCurrentPage}`,
        {
          headers: {
            etoken: encusername,
          },
        }
      )
      .then((res) => {
        if (res.data.error) {
          // console.log("error in getinternaltsxn : ", res.data.error);
        } else {
          setInternalTxs(res.data.items);
          setTotalIntTxs(res.data.total);
          setIntPages(res.data.pages);
          setInterTxsSpinner(false);
        }
      })
      .catch((err) => {
        // console.log("error in getinternaltsxn (catch) : ", err);
      });
  };

  useEffect(() => {
    getinternaltsxn();
  }, [addressDetails, intPageSize, intCurrentPage]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        toggleFileterMenu &&
        mainTgRef.current &&
        !mainTgRef.current.contains(e.target) &&
        btnToggleFileterMenu.current &&
        !btnToggleFileterMenu.current.contains(e.target)
      ) {
        setToggleFilterMenu(false);
        setToToggleFilter(false);
        setFromToggleFilter(false);
      }
    };

    document.addEventListener("mouseup", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mouseup", checkIfClickedOutside);
    };
  }, [toggleFileterMenu]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        moreInfoToggleMenu &&
        moreInfoRef.current &&
        !moreInfoRef.current.contains(e.target) &&
        moreInfoTMBtn.current &&
        !moreInfoTMBtn.current.contains(e.target)
      ) {
        setMoreInfoToggleMenu(false);
      }
    };

    document.addEventListener("mouseup", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mouseup", checkIfClickedOutside);
    };
  }, [moreInfoToggleMenu]);

  useEffect(() => {
    if (searchToken) {
      const mergetTokens = [...tokenHoldingsErc20, ...tokenHoldingsErc721, ...tokenHoldingsErc1155];
      const data = mergetTokens.filter((item) => item.name.toLowerCase().includes(searchToken.toLowerCase()));
      setSearchResult(data);
    }
  }, [searchToken, id]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        toToggleFilter &&
        toTgRef.current &&
        !toTgRef.current.contains(e.target) &&
        toTgBtnRef.current &&
        !toTgBtnRef.current.contains(e.target)
      ) {
        setToToggleFilter(false);
        setToFilterInput(toaddress);
      }
    };

    document.addEventListener("mouseup", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mouseup", checkIfClickedOutside);
    };
  }, [toToggleFilter]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        fromToggleFilter &&
        fromTgRef.current &&
        !fromTgRef.current.contains(e.target) &&
        fromTgBtnRef.current &&
        !fromTgBtnRef.current.contains(e.target)
      ) {
        setFromToggleFilter(false);
        setFromFilterInput(fromaddress);
      }
    };

    document.addEventListener("mouseup", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mouseup", checkIfClickedOutside);
    };
  }, [fromToggleFilter]);

  const getAddressData = async () => {
    setAddDetSpinner(true);
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}address/${id}`, {
        headers: {
          etoken: encusername,
        },
      })
      .then((res) => {
        if (res.data.error) {
          // console.log("error in address data : ", res.data.error);
        } else {
          setAddress(res.data.address);
          setAddressDetails(res.data);
          setAddDetSpinner(false);
        }
      })
      .catch((err) => {
        // console.log("error in address data (catch) : ", err);
      });
  };

  const getContractData = async () => {
    setContractSpinner(true);
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}contract/${id}`)
      .then((res) => {
        if (res.data.error) {
          // console.log("error in address data : ", res.data.error);
          setContractSpinner(false);
        } else {
          setContractDeta(res.data);
          setContractSpinner(false);
        }
      })
      .catch((err) => {
        // console.log("error in address data (catch) : ", err);
      });
  };

  useEffect(() => {
    getAddressData();
    getContractData();
  }, [id]);
  const getAddressTransaction = async () => {
    setSpinner(true);
    let params = {
      limit: PageSize,
      page: currentPage,
    };
    if (fromaddress) {
      params = { ...params, tx_type: "in", filterAddress: fromaddress };
      // if (search.includes("fromaddress")) {
      //     const substr = search.slice(1);
      //     const serarchArr = substr.split("&");
      //     const ind = serarchArr.findIndex((item) => item.includes("fromaddress"));
      //     const newStr = serarchArr[ind];
      //     const fromadd = newStr.split("=")[1];
      //     params = { ...params, tx_type: "out", filterAddress: fromadd };
      // }
      // // if (search.includes("toaddress")) {
      // //     const substr = search.slice(1);
      // //     const serarchArr = substr.split("&");
      // //     const ind = serarchArr.findIndex((item) => item.includes("fromaddress"));
      // //     const newStr = serarchArr[ind];
      // //     const toadd = newStr.split("=")[1];
      // //     params = { ...params, tx_type: "in", filterAddress: "fromadd" };
      // // }
    }
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}txs/${id}`, {
        params: params,
        headers: {
          etoken: encusername,
        },
      })
      .then((res) => {
        if (res.data.error) {
          // console.log("error in address transaction : ", res.data.error);
        } else {
          console.log("res.data.items", res.data.items);

          setAddressTransaction(res.data.items);
          setPages(res.data.pages);
          setTotalAddTrx(res.data.total);
          setSpinner(false);
        }
      })
      .catch((err) => {
        // console.log("error in address transaction (catch) : ", err);
      });
  };

  useEffect(() => {
    getAddressTransaction();
  }, [id, currentPage, toaddress, fromaddress]);

  const getTokenHoldings = async () => {
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}tokens/holding/${id}`, {
        headers: {
          etoken: encusername,
        },
      })
      .then((res) => {
        if (res.data.error) {
          // console.log("error in token holding : ", res.data.error);
        } else {
          let erc20token = res.data.items.filter((item) => item.type === "erc20");
          let erc721token = res.data.items.filter((item) => item.type === "erc721");
          let erc1155token = res.data.items.filter((item) => item.type === "erc1155");
          console.log("erc20token", erc20token);

          setTokenHoldingsErc20(erc20token);
          setTokenHoldingsErc721(erc721token);
          setTokenHoldingsErc1155(erc1155token);
          setTotalTokenHolding(res.data.total);
        }
      })
      .catch((err) => {
        // console.log("error in token holding (catch) : ", err);
      });
  };

  useEffect(() => {
    getTokenHoldings();
  }, [id]);

  useEffect(() => {
    if (!valid) {
      setisValid(false);
    } else {
      setisValid(true);
    }
  }, [id]);

  async function copy() {
    setState(true);
    await delay(1000);
    setState(false);
  }

  const onFromFilter = () => {
    if (fromFilterInput === "") {
      alert("Please enter the required field.");
    } else {
      if (search.includes("fromaddress")) {
        const substr = search.slice(1);
        const serarchArr = substr.split("&");
        const newArr = serarchArr.filter((item) => item.includes("fromaddress") === false);
        const newStr = newArr.join("&");
        navigate({
          pathname: `/address/${id}`,
          search:
            newStr === ""
              ? `?fromaddress=${fromFilterInput}`
              : "?" + newStr + `&fromaddress=${fromFilterInput}`,
        });
        setFromToggleFilter(false);
      } else {
        navigate({
          pathname: `/address/${id}`,
          search:
            search === "" ? `?fromaddress=${fromFilterInput}` : `${search}&fromaddress=${fromFilterInput}`,
        });
        setFromToggleFilter(false);
      }
    }
  };
  const onFromClear = () => {
    setFromFilterInput("");
  };
  const onToFilter = () => {
    if (toFilterInput === "") {
      alert("Please enter the required field.");
    } else {
      if (search.includes("toaddress")) {
        const substr = search.slice(1);
        const serarchArr = substr.split("&");
        const newArr = serarchArr.filter((item) => item.includes("toaddress") === false);
        const newStr = newArr.join("&");
        navigate({
          pathname: `/address/${id}`,
          search:
            newStr === "" ? `?toaddress=${toFilterInput}` : "?" + newStr + `&toaddress=${toFilterInput}`,
        });
        setToToggleFilter(false);
      } else {
        navigate({
          pathname: `/address/${id}`,
          search: search === "" ? `?toaddress=${toFilterInput}` : `${search}&toaddress=${toFilterInput}`,
        });
        setToToggleFilter(false);
      }
    }
  };
  const onToClear = () => {
    setToFilterInput("");
  };

  const handleRemoveFrom = () => {
    if (toaddress) {
      navigate({
        pathname: `/address/${id}`,
        search: `?toaddress=${toaddress}`,
      });
    } else {
      navigate(`/address/${id}`);
    }
    setFromFilterInput("");
  };

  const handleRemoveTo = () => {
    if (fromaddress) {
      navigate({
        pathname: `/address/${id}`,
        search: `?fromaddress=${fromaddress}`,
      });
    } else {
      navigate(`/address/${id}`);
    }
    setToFilterInput("");
  };

  const getAddressErc20TokenTxns = async () => {
    setErc20Spinner(true);
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}tokentxs/erc20`, {
        params: {
          limit: erc20PageSize,
          page: erc20CurrentPage,
          address: id,
        },
        headers: {
          etoken: encusername,
        },
      })
      .then((res) => {
        if (res.data.error) {
          console.log("error in getAddressTokenTxns : ", res.data.error);
        } else {
          setErc20Tokens(res.data.items);
          setErc20Total(res.data.total);
          setErc20Pages(res.data.pages);
          setErc20Spinner(false);
        }
      })
      .catch((err) => {
        console.log("error in getAddressTokenTxns (catch) : ", err);
      });
  };

  useEffect(() => {
    getAddressErc20TokenTxns();
  }, [id, erc20CurrentPage, erc20PageSize]);

  const getAddressErc721TokenTxns = async () => {
    setErc721Spinner(true);
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}tokentxs/erc721`, {
        params: {
          limit: erc721PageSize,
          page: erc721CurrentPage,
          address: id,
        },
        headers: {
          etoken: encusername,
        },
      })
      .then((res) => {
        if (res.data.error) {
          console.log("error in getAddressTokenTxns : ", res.data.error);
        } else {
          setErc721Tokens(res.data.items);
          setErc721Total(res.data.total);
          setErc721Pages(res.data.pages);
          setErc721Spinner(false);
        }
      })
      .catch((err) => {
        console.log("error in getAddressTokenTxns (catch) : ", err);
      });
  };

  useEffect(() => {
    getAddressErc721TokenTxns();
  }, [id, erc721CurrentPage, erc721PageSize]);

  const getAddressErc1155TokenTxns = async () => {
    setErc1155Spinner(true);
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}tokentxs/erc1155`, {
        params: {
          limit: erc1155PageSize,
          page: erc1155CurrentPage,
          address: id,
        },
        headers: {
          etoken: encusername,
        },
      })
      .then((res) => {
        if (res.data.error) {
          console.log("error in getAddressTokenTxns : ", res.data.error);
        } else {
          setErc1155Tokens(res.data.items);
          setErc1155Total(res.data.total);
          setErc1155Pages(res.data.pages);
          setErc1155Spinner(false);
        }
      })
      .catch((err) => {
        console.log("error in getAddressTokenTxns (catch) : ", err);
      });
  };

  useEffect(() => {
    getAddressErc1155TokenTxns();
  }, [id, erc1155CurrentPage, erc1155PageSize]);

  return (
    <>
      <Header2 />
      <main id="content" role="main" style={{ minHeight: "800px" }}>
        <div className="container space-1">
          <div className="container py-3">
            <div className="d-flex flex-wrap justify-content-between align-items-center">
              <div className="mb-3 mb-lg-0">
                <h1 className="h5 mb-0">
                  <img
                    id="icon"
                    className="u-xs-avatar rounded mt-n1 mr-1"
                    src="/asset/images/48.png"
                    alt=""
                  />
                  {addressDetails?.isContract ? "Contract" : "Address"} &nbsp;
                  <span id="mainaddress" className="text-secondary mr-1 fs-address" data-placement="top">
                    {addressDetails?.address}{" "}
                  </span>
                  {isValid ? (
                    <span id className="d-inline-flex">
                      <span className="mr-1">
                        <CopyToClipboard text={id} onCopy={() => copy()}>
                          <span
                            className="js-clipboard btn btn-sm btn-icon btn-soft-secondary rounded-circle"
                            href="javascript();"
                          >
                            {state === true ? (
                              <i className="fa fa-check mx-1 mt-2"></i>
                            ) : (
                              <i className="far fa-copy mt-2"></i>
                            )}{" "}
                          </span>
                        </CopyToClipboard>
                      </span>
                      <span id="ContentPlaceHolder1_qrcodeimg">
                        <a
                          id="target"
                          className="btn btn-sm btn-icon btn-soft-secondary rounded-circle"
                          href="/#"
                          data-title="Click to view QR Code"
                          data-toggle="modal"
                          data-target="#exampleModal"
                        >
                          <i className="fa fa-qrcode btn-icon__inner" />
                          <div
                            className="modal fade"
                            id="exampleModal"
                            tabIndex={-1}
                            role="dialog"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog modal-sm" role="document">
                              <div className="modal-content modal_box">
                                <div className="modal-header">
                                  {/* <h5 className="modal-title" id="exampleModalLabel">Modal title</h5> */}
                                  <h5 class="modal-title text-break small  text-center w-100">
                                    <span id="qraddress" className="modal_heading">
                                      {addressDetails?.address}
                                    </span>
                                  </h5>
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true" className="modal_close_button">
                                      ×
                                    </span>
                                  </button>
                                </div>
                                <div className="modal-body mb-4">
                                  <QRCode value={id} size="250" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </span>
                    </span>
                  ) : (
                    ""
                  )}
                </h1>
                <div className="mt-1" />
              </div>
              <div className="d-flex flex-wrap" />
            </div>
          </div>
          {/* <div id="overlayMain" /> */}
          <div className="container">
            <div className="border-top py-3">
              {/* <div className="d-flex text-secondary" style={{ lineHeight: '2.2' }}>
                                &nbsp;
                            </div> */}
            </div>
          </div>
          <div className="container">
            <div className="row mb-4">
              <div className="col-md-6 mb-3 mb-md-0">
                <div className="card h-100">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <h2 className="card-header-title">
                      {addressDetails?.isContract ? "Contract " : ""}Overview
                    </h2>
                    {/* {
                                            getTokenData.contractname ?
                                                <span className="fs-14 mb-3 font-weight-normal">{getTokenData.contractname}:&nbsp;{getTokenData.symbol} Token&nbsp;<span className="text-blue" href="#"> <i className="fa fa-external-link"></i></span></span> : ''
                                        } */}
                    {addressDetails?.publicNameTag ? (
                      <div>
                        <span className="u-label u-label--secondary text-dark font-size-1 rounded py-1 px-3">
                          <span
                            data-toggle="tooltip"
                            title
                            data-original-title="Public Name Tag (viewable by anyone)"
                          >
                            {addressDetails?.publicNameTag?.nametag}
                          </span>{" "}
                          <a
                            href={addressDetails?.publicNameTag?.site}
                            target="_blank"
                            rel="nofollow"
                            data-toggle="tooltip"
                            title
                            data-original-title="External Site - More Info"
                          >
                            {" "}
                            <i className="fa fa-external-link-alt pos-top-n1 small ml-1" />
                          </a>
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col-md-4 mb-1 mb-md-0">Balance:</div>
                      <div className="col-md-8">
                        <span className="balance" />
                        {addDetSpinner ? (
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="18" />{" "}
                          </td>
                        ) : (
                          addressDetails?.balance + " " + process.env.REACT_APP_TICKER
                        )}
                      </div>
                    </div>
                    <hr className="hr-space" />
                    <div className="row align-items-center">
                      <div className="col-md-4 mb-1 mb-md-0">Total Balance:</div>
                      <div className="col-md-8">
                        {/* <span className="value">${parseFloat(liveRate * addressDetails.Balance).toFixed(4)} </span> <span className="small" style={{ position: "relative", top: "-1px" }}></span> */}
                        <span className="value">
                          {addDetSpinner ? (
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="18" />{" "}
                            </td>
                          ) : (
                            // "$0.00"
                            <><span className="value">${parseFloat(Number(3) * Number(addressDetails?.balance)).toFixed(8)} </span> <span className="small" style={{ position: "relative", top: "-1px" }}></span></>
                          )}
                        </span>{" "}
                        <span className="small" style={{ position: "relative", top: "-1px" }}></span>
                      </div>
                    </div>
                    {totalTokenHolding ? (
                      <div id="ContentPlaceHolder1_tokenbalance">
                        <hr className="hr-space" />
                        <div className="row align-items-center">
                          <div className="col-md-4 mb-1 mb-md-0">Token:</div>
                          {addDetSpinner ? (
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100%" height="30" />{" "}
                            </td>
                          ) : (
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="none"
                                id="dropdown-basic"
                                style={{
                                  width: "400px",
                                  border: "1px solid #d5dae2",
                                  textAlign: "left",
                                  fontSize: "0.875rem",
                                  padding: "0.3rem 0.6rem",
                                  position: "relative",
                                }}
                              >
                                $0.00
                                <OverlayTrigger
                                  placement="top"
                                  trigger="hover"
                                  overlay={<Tooltip>5 Token Contracts</Tooltip>}
                                >
                                  <span className="badge badge-primary mx-1">{totalTokenHolding}</span>
                                </OverlayTrigger>
                              </Dropdown.Toggle>

                              <Dropdown.Menu
                                className="pl-2 pr-2"
                                style={{ width: "400px", position: "relative" }}
                              >
                                <div className="js-focus-state">
                                  <input
                                    type="text"
                                    className="form-control form-control-xs search mb-3"
                                    placeholder="Search for Token Name"
                                    aria-label="Search for Token name"
                                    aria-describedby="filter-search"
                                    onChange={(e) => setSearchToken(e.target.value)}
                                  />
                                </div>
                                {getSearchResult && searchToken !== "" ? (
                                  getSearchResult.map((item, ind) => {
                                    return (
                                      <>
                                        <li className="list-custom list-custom-SRC20">
                                          <Link
                                            className="link-hover d-flex justify-content-between align-items-center"
                                            to={`/token/${item?.token}`}
                                          >
                                            <div>
                                              <div className="d-flex align-items-center">
                                                <img
                                                  className="mr-1 mCS_img_loaded"
                                                  width={12}
                                                  src={
                                                    item &&
                                                    (item?.status === "true" || item?.status === true) &&
                                                    item?.logo
                                                      ? process.env.REACT_APP_TOKEN_LOGO_PATH + item.logo
                                                      : "/asset/images/48.png"
                                                  }
                                                  alt=""
                                                />
                                                <span className="list-name hash-tag text-truncate">
                                                  <span
                                                    data-toggle="tooltip"
                                                    title
                                                    data-original-title="$ SimpSwap.fi"
                                                  >
                                                    <font color>{item.name}</font>
                                                  </span>{" "}
                                                  ({item.symbol})
                                                </span>
                                              </div>
                                              <span className="list-amount link-hover__item hash-tag hash-tag--md text-truncate">
                                                {formatAmountFromWei(item.quantity, item.decimals)}{" "}
                                                {item.symbol}
                                              </span>
                                            </div>
                                            <div className="text-right">
                                              <span className="list-usd-value d-block">&nbsp;</span>
                                            </div>
                                          </Link>
                                        </li>
                                      </>
                                    );
                                  })
                                ) : (
                                  <>
                                    {tokenHoldingsErc20.length ? (
                                      <li className="list-custom-divider list-custom-divider-SRC20 d-flex justify-content-between align-items-center font-size-1 rounded-sm py-1 px-2 mb-1">
                                        <span>
                                          <i className="fa fa-angle-right text-secondary" />
                                          <strong> SRC20 Tokens</strong> ({tokenHoldingsErc20.length})
                                        </span>
                                      </li>
                                    ) : (
                                      ""
                                    )}
                                    {tokenHoldingsErc20 &&
                                      tokenHoldingsErc20.map((item, ind) => {
                                        return (
                                          <>
                                            <li className="list-custom list-custom-SRC20">
                                              <Link
                                                className="link-hover d-flex justify-content-between align-items-center"
                                                to={`/token/${item?.token}`}
                                              >
                                                <div>
                                                  <div className="d-flex align-items-center">
                                                    <img
                                                      className="mr-1 mCS_img_loaded"
                                                      width={12}
                                                      src={
                                                        item && item?.status === "true" && item?.logo
                                                          ? process.env.REACT_APP_TOKEN_LOGO_PATH + item.logo
                                                          : "/asset/images/48.png"
                                                      }
                                                      alt=""
                                                    />
                                                    <span className="list-name hash-tag text-truncate">
                                                      <span
                                                        data-toggle="tooltip"
                                                        title
                                                        data-original-title="$ SimpSwap.fi"
                                                      >
                                                        <font color>{item.name}</font>
                                                      </span>{" "}
                                                      ({item.symbol})
                                                    </span>
                                                  </div>
                                                  <span className="list-amount link-hover__item hash-tag hash-tag--md text-truncate">
                                                    {formatAmountFromWei(item.quantity, item.decimals)}{" "}
                                                    {item.symbol}
                                                  </span>
                                                </div>
                                                <div className="text-right">
                                                  <span className="list-usd-value d-block">&nbsp;</span>
                                                </div>
                                              </Link>
                                            </li>
                                            {/* <hr className="mt-1 mb-2" /> */}
                                          </>
                                        );
                                      })}
                                    {tokenHoldingsErc721.length ? (
                                      <li className="list-custom-divider list-custom-divider-SRC20 d-flex justify-content-between align-items-center font-size-1 rounded-sm py-1 px-2 mb-1">
                                        <span>
                                          <i className="fa fa-angle-right text-secondary" />
                                          <strong> SRC721 Tokens</strong> ({tokenHoldingsErc721.length})
                                        </span>
                                      </li>
                                    ) : (
                                      ""
                                    )}
                                    {tokenHoldingsErc721 &&
                                      tokenHoldingsErc721.map((item, ind) => {
                                        return (
                                          <>
                                            <li className="list-custom list-custom-SRC20">
                                              <Link
                                                className="link-hover d-flex justify-content-between align-items-center"
                                                to={`/token/${item?.token}`}
                                              >
                                                <div>
                                                  <div className="d-flex align-items-center">
                                                    <img
                                                      className="mr-1 mCS_img_loaded"
                                                      width={12}
                                                      src={
                                                        item && item?.status === "true" && item?.logo
                                                          ? process.env.REACT_APP_TOKEN_LOGO_PATH + item.logo
                                                          : "/asset/images/48.png"
                                                      }
                                                      alt=""
                                                    />
                                                    <span className="list-name hash-tag text-truncate">
                                                      <span
                                                        data-toggle="tooltip"
                                                        title
                                                        data-original-title="$ SimpSwap.fi"
                                                      >
                                                        <font color>{item.name}</font>
                                                      </span>{" "}
                                                      ({item.symbol})
                                                    </span>
                                                  </div>
                                                  <span className="list-amount link-hover__item hash-tag hash-tag--md text-truncate">
                                                    {formatAmountFromWei(item.quantity, item.decimals)}{" "}
                                                    {item.symbol}
                                                  </span>
                                                </div>
                                                <div className="text-right">
                                                  <span className="list-usd-value d-block">&nbsp;</span>
                                                </div>
                                              </Link>
                                            </li>
                                          </>
                                        );
                                      })}
                                    {tokenHoldingsErc1155.length ? (
                                      <li className="list-custom-divider list-custom-divider-SRC20 d-flex justify-content-between align-items-center font-size-1 rounded-sm py-1 px-2 mb-1">
                                        <span>
                                          <i className="fa fa-angle-right text-secondary" />
                                          <strong> SRC1155 Tokens</strong> ({tokenHoldingsErc1155.length})
                                        </span>
                                      </li>
                                    ) : (
                                      ""
                                    )}
                                    {tokenHoldingsErc1155 &&
                                      tokenHoldingsErc1155.map((item, ind) => {
                                        return (
                                          <>
                                            <li className="list-custom list-custom-SRC20">
                                              <Link
                                                className="link-hover d-flex justify-content-between align-items-center"
                                                to={`/token/${item?.token}`}
                                              >
                                                <div>
                                                  <div className="d-flex align-items-center">
                                                    <img
                                                      className="mr-1 mCS_img_loaded"
                                                      width={12}
                                                      src={
                                                        item && item?.status === "true" && item?.logo
                                                          ? process.env.REACT_APP_TOKEN_LOGO_PATH + item.logo
                                                          : "/asset/images/48.png"
                                                      }
                                                      alt=""
                                                    />
                                                    <span className="list-name hash-tag text-truncate">
                                                      <span
                                                        data-toggle="tooltip"
                                                        title
                                                        data-original-title="$ SimpSwap.fi"
                                                      >
                                                        <font color>{item.name}</font>
                                                      </span>{" "}
                                                      ({item.symbol})
                                                    </span>
                                                  </div>
                                                  <span className="list-amount link-hover__item hash-tag hash-tag--md text-truncate">
                                                    {formatAmountFromWei(item.quantity, item.decimals)}{" "}
                                                    {item.symbol}
                                                  </span>
                                                </div>
                                                <div className="text-right">
                                                  <span className="list-usd-value d-block">&nbsp;</span>
                                                </div>
                                              </Link>
                                            </li>
                                          </>
                                        );
                                      })}
                                  </>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div id="ContentPlaceHolder1_cardright" className="col-md-6">
                <div className="card h-100">
                  <div className="card-header d-flex justify-content-between align-items-center">
                    <div id="ContentPlaceHolder1_td_misc" className="d-flex">
                      <h2 className="card-header-title mr-1">More Info</h2>
                    </div>
                    <div id="ContentPlaceHolder1_moreoptionsdiv" className="d-flex">
                      <div className="ml-auto mr-1" />
                      <div class="position-relative">
                        <button
                          id="dropdownToolsInvoker-2"
                          className={
                            moreInfoToggleMenu
                              ? "btn btn-xs btn-icon btn-soft-secondary cursor-pointer active-self-focus"
                              : "btn btn-xs btn-icon btn-soft-secondary cursor-pointer active-self"
                          }
                          ref={moreInfoTMBtn}
                          role="button"
                          aria-controls="ddlTxFilterTools"
                          aria-haspopup="true"
                          aria-expanded="false"
                          data-unfold-duration="300"
                          data-unfold-delay="300"
                          data-toggle="tooltip"
                          title=""
                          onClick={() => {
                            setMoreInfoToggleMenu(!moreInfoToggleMenu);
                          }}
                        >
                          <i class="fa fa-ellipsis-v btn-icon__inner"></i>
                        </button>
                        <div
                          id="dropdownTools-2"
                          className={
                            moreInfoToggleMenu
                              ? "dropdown-menu dropdown-menu-right dropdown-unfold u-unfold--css-animation slideInUp"
                              : "dropdown-menu dropdown-menu-right dropdown-unfold u-unfold--css-animation u-unfold--hidden"
                          }
                          ref={moreInfoRef}
                          aria-labelledby="dropdownToolsInvoker-2"
                          style={{ zIndex: "1021", animationDuration: "300ms", right: "0px" }}
                        >
                          {/* <span
                            data-toggle="tooltip"
                            title=""
                            data-original-title="Must be logged in to access this feature"
                          >
                            <a class="dropdown-item disabled" href="#">
                              <i class="far fa-fw fa-sticky-note mr-1"></i>View Private Note
                            </a>
                          </span> */}
                          {/* <a
                            class="dropdown-item"
                            href="/balancecheck-tool?a=0x78f6ef4fa55e41b816cbc0f97a2d8743ba8795d9"
                          >
                            <i class="fa fa-fw fa-history  mr-1"></i>Check Previous Balance
                          </a> */}
                          {/* <hr class="my-1" /> */}
                          <a
                            class="dropdown-item"
                            href="/contactus?id=5&amp;a=0x78f6ef4fa55e41b816cbc0f97a2d8743ba8795d9"
                          >
                            <i class="fa fa-fw fa-user-tag mr-1"></i>Update Name Tag
                          </a>
                          {/* <a
                            class="dropdown-item"
                            href="/contactus?id=5&amp;a=0x78f6ef4fa55e41b816cbc0f97a2d8743ba8795d9"
                          >
                            <i class="fa fa-fw fa-tags mr-1"></i>Submit Label
                          </a> */}
                          {/* <a class="dropdown-item" href="/contactus?id=6">
                            <i class="fa fa-fw fa-flag mr-1"></i>Report/Flag Address
                          </a> */}
                          {/* <a
                            class="dropdown-item"
                            href="/tokenapprovalchecker?search=0x78f6ef4fa55e41b816cbc0f97a2d8743ba8795d9"
                          >
                            <i class="fas fa-user-check"></i> Token Approvals{" "}
                            <sup>
                              <span class="badge badge-secondary ml-1"> Beta</span>
                            </sup>
                          </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col-md-4 mb-1 mb-md-0">
                        {" "}
                        <i
                          className="far fa-question-circle text-muted"
                          data-toggle="tooltip"
                          data-html="true"
                          data-title="<p class='text-white text-left'>Name tags or notes can be attached to an address for identifying addresses of interest. <br><br>(This info is private and can ONLY be viewed by you)</p>"
                        />{" "}
                        My Name Tag:
                      </div>
                      <div className="col-md-6 mb-1">
                        {addDetSpinner ? (
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100%" height="18" />
                        ) : (
                          <div className="ml-auto mr-1 w-100">
                            {isLogin ? (
                              addressDetails?.nameTag ? (
                                <div className="col-md-8">
                                  <span id="Public_Private_Tag">
                                    <span
                                      data-toggle="tooltip"
                                      title
                                      className="u-label u-label--value u-label--secondary text-dark"
                                      style={{ padding: ".20rem .6rem" }}
                                      data-original-title="private note 2"
                                    >
                                      {addressDetails?.nameTag}
                                    </span>
                                  </span>{" "}
                                  <span id="Public_Private_Tag_edit_button">
                                    {" "}
                                    <a
                                      className="cursor-pointer"
                                      data-toggle="modal"
                                      data-target="#responsive"
                                      rel="tooltip"
                                      onClick={() =>
                                        handleEditAddNote(
                                          addressDetails?.address,
                                          addressDetails?.nameTag,
                                          addressDetails?.nameTag.note
                                        )
                                      }
                                    >
                                      {" "}
                                      <i className="far fa-edit text-info ml-1" />{" "}
                                    </a>
                                  </span>
                                </div>
                              ) : (
                                <span>
                                  Not Available,{" "}
                                  <span
                                    className="text-primary update-text cursor-pointer"
                                    data-toggle="modal"
                                    data-target="#responsive"
                                  >
                                    update ?{" "}
                                  </span>
                                </span>
                              )
                            ) : (
                              <span>
                                Not Available,{" "}
                                <a href="/login" className="update-text cursor-pointer">
                                  login to update ?{" "}
                                </a>
                              </span>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <hr className="hr-space" />
                    {addressDetails && addressDetails?.isContract ? (
                      <div id="ContentPlaceHolder1_tr_mined">
                        <div className="row align-items-center">
                          <div className="col-md-4 mb-1 mb-md-0">Contract Creater</div>
                          <div className="col-md-8">
                            {addDetSpinner ? (
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100%" height="18" />
                            ) : (
                              <>
                                <i className="fa fa-file-archive-o text-dark pt-1"></i>
                                {addressDetails?.contractCreater ? (
                                  <Link
                                    to={`/address/${addressDetails?.contractCreater}`}
                                    className="text-blue"
                                  >
                                    {" "}
                                    {formatHash(addressDetails?.contractCreater)}
                                  </Link>
                                ) : (
                                  "not found."
                                )}{" "}
                                at txn{" "}
                                {addressDetails?.contractHash ? (
                                  <Link to={`/tx/${addressDetails?.contractHash}`} className="text-blue">
                                    {" "}
                                    {formatHash(addressDetails?.contractHash)}
                                  </Link>
                                ) : (
                                  "not found."
                                )}
                                {/* <div className="col-md-6 mb-1"> Not Available </div> */}
                              </>
                            )}
                          </div>
                        </div>
                        <hr className="hr-space" />
                      </div>
                    ) : (
                      ""
                    )}
                    {addressDetails &&
                    addressDetails?.isToken &&
                    addressDetails?.token &&
                    addressDetails?.token?.name &&
                    addressDetails?.token?.symbol ? (
                      <div id="ContentPlaceHolder1_tr_mined">
                        <div className="row align-items-center">
                          <div className="col-md-4 mb-1 mb-md-0">Token Tracker</div>
                          <div className="col-md-4 pt-1">
                            <a href={`/token/${addressDetails?.token?.address}`} className="text-blue">
                              <img
                                id="icon"
                                className="u-xs-avatar rounded mt-n1 mr-1"
                                src={
                                  addressDetails &&
                                  (addressDetails?.token?.status === "true" ||
                                    addressDetails?.token?.status === true) &&
                                  addressDetails?.token?.logo
                                    ? process.env.REACT_APP_TOKEN_LOGO_PATH + addressDetails?.token?.logo
                                    : "/asset/images/48.png"
                                }
                                alt=""
                              />
                              {addressDetails?.token?.name} ({addressDetails?.token?.symbol})
                            </a>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="container mt-4 text-center"></div>
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center p-0">
                <ul className="nav nav-custom nav-borderless nav_tabs1" id="nav_tabs" role="tablist">
                  <li id="ContentPlaceHolder1_li_transactions" className="nav-item">
                    <Link
                      className={`nav-link ${hash ? "" : "active"}`}
                      to={{ pathname: `/address/${id}`, hash: "" }}
                    >
                      Transactions
                    </Link>
                  </li>
                  {internalTxs?.length ? (
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${hash === "#internaltxns" ? "active" : ""}`}
                        to={{ pathname: `/address/${id}`, hash: "internaltxns" }}
                      >
                        Internal Txns
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {Number(erc20Total) > 0 ? (
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${hash === "#tokentxns" ? "active" : ""}`}
                        to={{ pathname: `/address/${id}`, hash: "tokentxns" }}
                      >
                        {process.env.REACT_APP_TICKER}-20 Token Txns
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {Number(erc721Total) > 0 ? (
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${hash === "#tokentxnsErc721" ? "active" : ""}`}
                        to={{ pathname: `/address/${id}`, hash: "tokentxnsErc721" }}
                      >
                        {process.env.REACT_APP_TICKER}-721 Token Txns
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {Number(erc1155Total) > 0 ? (
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${hash === "#tokentxnsErc1155" ? "active" : ""}`}
                        to={{ pathname: `/address/${id}`, hash: "tokentxnsErc1155" }}
                      >
                        {process.env.REACT_APP_TICKER}-1155 Token Txns
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {contractDeta !== null && addressDetails?.isContract ? (
                    <li className="nav-item">
                      {/* <Link className={`nav-link ${hash === "#code" || hash === "#read" || hash === "#write" ? "active" : ""}`} to={`/address/${addressDetails.address}#code`}> */}
                      <Link
                        className={`nav-link ${
                          hash === "#code" || hash === "#readContract" || hash === "#writeContract"
                            ? "active"
                            : ""
                        }`}
                        to={{ pathname: `/address/${id}`, hash: "code" }}
                      >
                        Contract &nbsp;
                        {contractDeta?.abiCode !== null &&
                        contractDeta?.sourceCode !== null &&
                        contractDeta !== null ? (
                          <i class="fa fa-check-circle text-success"></i>
                        ) : (
                          ""
                        )}
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                  {addressDetails && addressDetails?.minedBlock ? (
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${hash === "#mine" ? "active" : ""}`}
                        to={{ pathname: `/address/${id}`, hash: "mine" }}
                      >
                        Validated Blocks
                      </Link>
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
              <div className="card-body">
                <div className="tab-content">
                  {hash === "" ? (
                    <div className="tab-pane fade show active">
                      <>
                        <div className="d-md-flex align-items-center mb-3 justify-content-end">
                          {!fromaddress && !toaddress ? (
                            <>
                              <p class="mr-2 mb-2">
                                <i
                                  class="fas fa-sort-amount-down text-secondary"
                                  data-toggle="tooltip"
                                  title=""
                                  data-original-title="Oldest First"
                                ></i>
                                &nbsp;Total {totalAddTrx} transactions found.
                              </p>

                              {/* <div className=''>
                                                                <i class="fas fa-sort-amount-down text-secondary" data-toggle="tooltip" title="" data-original-title="Oldest First"></i>    
                                                                <p className="mr-2 mb-2">total {length} transactions</p>
                                                            </div> */}
                              <div className="d-flex flex-wrap flex-xl-nowrap text-nowrap align-items-center ml-auto" />
                              <div class="position-relative ml-1">
                                <button
                                  id="ddlTxFilter"
                                  class={
                                    toggleFileterMenu
                                      ? "btn btn-sm btn-icon btn-soft-secondary cursor-pointer active-self-focus"
                                      : "btn btn-sm btn-icon btn-soft-secondary cursor-pointer active-self"
                                  }
                                  ref={btnToggleFileterMenu}
                                  role="button"
                                  aria-controls="ddlTxFilterTools"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  data-unfold-duration="300"
                                  data-unfold-delay="300"
                                  data-toggle="tooltip"
                                  title=""
                                  onClick={() => {
                                    setToggleFilterMenu(!toggleFileterMenu);
                                  }}
                                >
                                  <i class="fa fa-ellipsis-v btn-icon__inner"></i>
                                </button>
                                <div
                                  id="ddlTxFilterTools"
                                  class={
                                    toggleFileterMenu
                                      ? "dropdown-menu dropdown-unfold dropdown-menu-sm-right u-unfold--css-animation slideInUp"
                                      : "dropdown-menu dropdown-unfold dropdown-menu-sm-right u-unfold--css-animation u-unfold--hidden"
                                  }
                                  // class="dropdown-menu dropdown-unfold dropdown-menu-sm-right u-unfold--css-animation slideInUp"
                                  style={{
                                    width: "200px",
                                    animationDuration: "300ms",
                                    right: "0px",
                                  }}
                                  ref={mainTgRef}
                                  aria-labelledby="ddlTxFilter"
                                >
                                  <Link class="dropdown-item" to={`/txs?a=${id}&f=2`}>
                                    <i class="fa fa-long-arrow-alt-right mr-1"></i> View Outgoing Txns
                                  </Link>
                                  <Link class="dropdown-item" to={`/txs?a=${id}&f=3`}>
                                    <i class="fas fa-long-arrow-alt-left mr-1"></i> View Incoming Txns
                                  </Link>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div class="d-md-flex align-items-center">
                              <div class="d-flex flex-wrap flex-xl-nowrap text-nowrap align-items-center ml-auto">
                                <div class="text-secondary mr-1 mb-2" id="divFilterBy">
                                  Filtered By:
                                </div>
                                {fromaddress ? (
                                  <div
                                    class="u-label u-label--xs u-label--secondary d-flex align-items-center font-size-1 mr-1 mb-2"
                                    id="divFromAddress"
                                  >
                                    <span class="mr-1">
                                      From{" "}
                                      <strong>
                                        <span class="hash-tag text-truncate">{fromaddress}</span>
                                      </strong>
                                    </span>
                                    <button
                                      type="button"
                                      class="btn btn-xs btn-soft-secondary btn-icon rounded-circle ml-1 mr-n1"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title=""
                                      onClick={handleRemoveFrom}
                                      id="btnFromClear"
                                      data-original-title="Clear Filter"
                                    >
                                      <i class="far fa-times btn-icon__inner"></i>
                                    </button>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {toaddress ? (
                                  <div
                                    class="u-label u-label--xs u-label--secondary d-flex align-items-center font-size-1 mr-1 mb-2"
                                    id="divFromAddress"
                                  >
                                    <span class="mr-1">
                                      To{" "}
                                      <strong>
                                        <span class="hash-tag text-truncate">{toaddress}</span>
                                      </strong>
                                    </span>
                                    <button
                                      type="button"
                                      class="btn btn-xs btn-soft-secondary btn-icon rounded-circle ml-1 mr-n1"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title=""
                                      onClick={handleRemoveTo}
                                      id="btnFromClear"
                                      data-original-title="Clear Filter"
                                    >
                                      <i class="far fa-times btn-icon__inner"></i>
                                    </button>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="table-responsive mb-2 mb-md-0">
                          <table className="table table-hover">
                            <thead className="thead-light">
                              <tr>
                                <th scope="col" width={50}>
                                  ID
                                </th>
                                <th scope="col" width={140}>
                                  {" "}
                                  Txn Hash{" "}
                                </th>
                                <th>
                                  <div className="d-flex align-items-center">
                                    <div className="mr-2">
                                      Method{" "}
                                      <OverlayTrigger
                                        placement="top"
                                        trigger="hover"
                                        overlay={
                                          <Tooltip>
                                            Function executed based on decoded input data. For unidentified
                                            functions, method ID is displayed instead.
                                          </Tooltip>
                                        }
                                      >
                                        <i
                                          className="fal fa-info-circle"
                                          data-toggle="tooltip"
                                          data-html="true"
                                          data-boundary="viewport"
                                          title
                                        />
                                      </OverlayTrigger>
                                    </div>
                                  </div>
                                </th>
                                <th scope="col" width={100}>
                                  <div className="d-flex align-items-center">
                                    <div className="mr-2">Block</div>
                                  </div>
                                </th>
                                <th scope="col">
                                  {ageColChange ? (
                                    <OverlayTrigger
                                      placement="top"
                                      trigger="hover"
                                      overlay={<Tooltip>Click to show Datetime Format</Tooltip>}
                                    >
                                      <Link
                                        onClick={() => setAgeColChange(!ageColChange)}
                                        id="lnkAgeDateTime"
                                      >
                                        Age
                                      </Link>
                                    </OverlayTrigger>
                                  ) : (
                                    <OverlayTrigger
                                      placement="top"
                                      trigger="hover"
                                      overlay={<Tooltip>Click to show Age Format</Tooltip>}
                                    >
                                      <Link onClick={() => setAgeColChange(!ageColChange)}>
                                        Date Time (UTC)
                                      </Link>
                                    </OverlayTrigger>
                                  )}
                                </th>
                                <th scope="col">
                                  <div className="d-flex">
                                    From &nbsp;
                                    <div
                                      id="ContentPlaceHolder1_divToFilter"
                                      class={fromToggleFilter ? "dropdown show" : "dropdown"}
                                    >
                                      <button
                                        class="btn btn-xs btn-soft-secondary btn-icon"
                                        type="button"
                                        id="dropdownTo"
                                        ref={fromTgBtnRef}
                                        onClick={() => {
                                          setFromToggleFilter(!fromToggleFilter);
                                        }}
                                      >
                                        <i class="fa fa-filter btn-icon__inner"></i>
                                      </button>
                                      <div
                                        class={fromToggleFilter ? "dropdown-menu show" : "dropdown-menu"}
                                        style={
                                          fromToggleFilter
                                            ? { minWidth: "220px" }
                                            : {
                                                minWidth: "220px",
                                                position: "absolute",
                                                transform: "translate3d(0px, 22px, 0px)",
                                                top: "0px",
                                                left: "0px",
                                                willChange: "transform",
                                              }
                                        }
                                        ref={fromTgRef}
                                      >
                                        <div class="py-0 px-3">
                                          <input
                                            type="text"
                                            class="form-control form-control-xs mb-2"
                                            placeholder="Search by address e.g. 0x.."
                                            id="toaddress"
                                            name="toaddress"
                                            value={fromFilterInput}
                                            onChange={(e) => {
                                              setFromFilterInput(e.target.value);
                                            }}
                                          />
                                          <div class="d-flex">
                                            <button
                                              type=""
                                              class="btn btn-xs btn-block btn-primary mr-2 mt-0"
                                              onclick="constructFilterUrl(this)"
                                              id="btnToFilter"
                                              onClick={onFromFilter}
                                            >
                                              <i class="fa fa-filter mr-1"></i> Filter
                                            </button>
                                            <button
                                              type=""
                                              class="btn btn-xs btn-block btn-soft-secondary mt-0"
                                              onclick="clearFilter(this, event)"
                                              id="btnToFilterClear"
                                              onClick={onFromClear}
                                            >
                                              Clear
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </th>
                                <th scope="col" width={20}></th>
                                <th scope="col">
                                  <div className="d-flex">
                                    To &nbsp;
                                    <div
                                      id="ContentPlaceHolder1_divToFilter"
                                      class={toToggleFilter ? "dropdown show" : "dropdown"}
                                    >
                                      <button
                                        class="btn btn-xs btn-soft-secondary btn-icon"
                                        type="button"
                                        id="dropdownTo"
                                        ref={toTgBtnRef}
                                        onClick={() => {
                                          setToToggleFilter(!toToggleFilter);
                                          setFromToggleFilter(false);
                                        }}
                                      >
                                        <i class="fa fa-filter btn-icon__inner"></i>
                                      </button>
                                      <div
                                        class={toToggleFilter ? "dropdown-menu show" : "dropdown-menu"}
                                        style={
                                          toToggleFilter
                                            ? { minWidth: "220px" }
                                            : {
                                                minWidth: "220px",
                                                position: "absolute",
                                                transform: "translate3d(0px, 22px, 0px)",
                                                top: "0px",
                                                left: "0px",
                                                willChange: "transform",
                                              }
                                        }
                                        ref={toTgRef}
                                      >
                                        <div class="py-0 px-3">
                                          <input
                                            type="text"
                                            class="form-control form-control-xs mb-2"
                                            placeholder="Search by address e.g. 0x.."
                                            id="toaddress"
                                            name="toaddress"
                                            value={toFilterInput}
                                            onChange={(e) => {
                                              setToFilterInput(e.target.value);
                                            }}
                                          />
                                          <div class="d-flex">
                                            <button
                                              type=""
                                              class="btn btn-xs btn-block btn-primary mr-2 mt-0"
                                              onclick="constructFilterUrl(this)"
                                              id="btnToFilter"
                                              onClick={onToFilter}
                                            >
                                              <i class="fa fa-filter mr-1"></i> Filter
                                            </button>
                                            <button
                                              type=""
                                              class="btn btn-xs btn-block btn-soft-secondary mt-0"
                                              onclick="clearFilter(this, event)"
                                              id="btnToFilterClear"
                                              onClick={onToClear}
                                            >
                                              Clear
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </th>
                                <th scope="col"> Value </th>
                                <th scope="col"> [Txn Fee] </th>
                              </tr>
                            </thead>
                            <tbody>
                              {spinner ? (
                                <>
                                  <tr>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1
                                        x="0"
                                        y="3"
                                        rx="3"
                                        ry="3"
                                        width="30"
                                        height="15"
                                      />{" "}
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="160" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="120" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td></td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1
                                        x="0"
                                        y="3"
                                        rx="3"
                                        ry="3"
                                        width="30"
                                        height="15"
                                      />{" "}
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="160" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="120" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td></td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1
                                        x="0"
                                        y="3"
                                        rx="3"
                                        ry="3"
                                        width="30"
                                        height="15"
                                      />{" "}
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="160" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="120" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td></td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1
                                        x="0"
                                        y="3"
                                        rx="3"
                                        ry="3"
                                        width="30"
                                        height="15"
                                      />{" "}
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="160" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="120" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td></td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1
                                        x="0"
                                        y="3"
                                        rx="3"
                                        ry="3"
                                        width="30"
                                        height="15"
                                      />{" "}
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="160" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="120" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td></td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1
                                        x="0"
                                        y="3"
                                        rx="3"
                                        ry="3"
                                        width="30"
                                        height="15"
                                      />{" "}
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="160" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="120" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td></td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1
                                        x="0"
                                        y="3"
                                        rx="3"
                                        ry="3"
                                        width="30"
                                        height="15"
                                      />{" "}
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="160" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="120" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td></td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1
                                        x="0"
                                        y="3"
                                        rx="3"
                                        ry="3"
                                        width="30"
                                        height="15"
                                      />{" "}
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="160" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="120" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td></td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1
                                        x="0"
                                        y="3"
                                        rx="3"
                                        ry="3"
                                        width="30"
                                        height="15"
                                      />{" "}
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="160" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="120" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td></td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1
                                        x="0"
                                        y="3"
                                        rx="3"
                                        ry="3"
                                        width="30"
                                        height="15"
                                      />{" "}
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="160" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="120" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td></td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                    </td>
                                    <td>
                                      {" "}
                                      <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                    </td>
                                  </tr>
                                </>
                              ) : addressTransactions && addressTransactions?.length ? (
                                addressTransactions.map((item, index) => {
                                  const value = Web3.utils.fromWei(
                                    item.value.toLocaleString("fullwide", { useGrouping: false })
                                  );
                                  const ind = currentPage * 10 - 10 + (index + 1);
                                  return (
                                    <tr key={index}>
                                      <td>{ind}</td>
                                      <td>
                                        <span className="hash-tag">
                                          <Link to={`/tx/${item.hash}`}>{formatHash(item.hash)}</Link>
                                        </span>
                                      </td>
                                      <td>
                                        <OverlayTrigger
                                          placement="top"
                                          trigger="hover"
                                          overlay={
                                            <Tooltip>
                                              {item.method.name ? item.method.name : item.method.id}
                                            </Tooltip>
                                          }
                                        >
                                          <span
                                            style={{ minWidth: "68px" }}
                                            className="u-label u-label--xs u-label--info rounded text-dark text-center"
                                            data-toggle="tooltip"
                                            data-boundary="viewport"
                                            data-html="true"
                                            title
                                            data-original-title="Collect From Tiles"
                                          >
                                            {item.method.name
                                              ? formatMethod(item.method.name)
                                              : formatMethod(item.method.id)}
                                          </span>
                                        </OverlayTrigger>
                                      </td>
                                      <td className="d-none d-sm-table-cell ">
                                        {item.blockNumber ? (
                                          <Link to={`/block/${item.blockNumber}`}>{item.blockNumber}</Link>
                                        ) : (
                                          <i class="text-secondary">(pending)</i>
                                        )}
                                      </td>
                                      <td>
                                        {ageColChange ? (
                                          <span rel="tooltip" data-toggle="tooltip" data-placement="bottom">
                                            {item.blockNumber ? (
                                              <span class="text-black">{item.age}</span>
                                            ) : (
                                              <i class="text-secondary">{item.age}</i>
                                            )}
                                          </span>
                                        ) : (
                                          <span rel="tooltip" data-toggle="tooltip" data-placement="bottom">
                                            {item.blockNumber ? (
                                              <span class="text-black">{formatDate(item.timestamp)}</span>
                                            ) : (
                                              <i class="text-secondary">{formatDate(item.timestamp)}</i>
                                            )}
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <OverlayTrigger
                                          placement="top"
                                          trigger="hover"
                                          overlay={<Tooltip>{item.from}</Tooltip>}
                                        >
                                          <span className="hash-tag">
                                            {id === item.from ? (
                                              <span>
                                                {item.blockNumber ? (
                                                  <span class="text-black">
                                                    {item?.fromNameTag
                                                      ? item?.fromNameTag
                                                      : formatAddress(item.from)}
                                                  </span>
                                                ) : (
                                                  <i class="text-secondary">
                                                    {item?.fromNameTag
                                                      ? item?.fromNameTag
                                                      : formatAddress(item.from)}
                                                  </i>
                                                )}
                                              </span>
                                            ) : (
                                              <Link to={`/address/${item.from}`}>
                                                {item.blockNumber ? (
                                                  <span class="text-black">
                                                    {item?.fromNameTag
                                                      ? item?.fromNameTag
                                                      : formatAddress(item.from)}
                                                  </span>
                                                ) : (
                                                  <i class="text-secondary">
                                                    {item?.fromNameTag
                                                      ? item?.fromNameTag
                                                      : formatAddress(item.from)}
                                                  </i>
                                                )}
                                              </Link>
                                            )}
                                          </span>
                                        </OverlayTrigger>
                                      </td>
                                      <td>
                                        {id === item.from ? (
                                          item.from === item.to ? (
                                            <span class="u-label u-label--xs u-label--secondary color-strong text-uppercase text-center w-100 rounded text-nowrap">
                                              SELF
                                            </span>
                                          ) : (
                                            <span className="u-label u-label--xs u-label--warning color-strong text-uppercase text-center w-100 rounded text-nowrap">
                                              OUT
                                            </span>
                                          )
                                        ) : (
                                          <span className="u-label u-label--xs u-label--success color-strong text-uppercase text-center w-100 rounded text-nowrap">
                                            &nbsp;IN&nbsp;
                                          </span>
                                        )}
                                      </td>
                                      <td>
                                        <span
                                          style={{
                                            whiteSpace: "nowrap",
                                          }}
                                        >
                                          {item.contractAddress ? (
                                            <i className="far fa-newspaper text-secondary ml-1 mr-1" />
                                          ) : item.isContract ? (
                                            <i className="far fa-file-alt text-secondary ml-1 mr-1" />
                                          ) : (
                                            ""
                                          )}
                                          {item.to ? (
                                            <OverlayTrigger
                                              placement="top"
                                              width="100px"
                                              trigger="hover"
                                              overlay={<Tooltip>{item.to}</Tooltip>}
                                            >
                                              <span className="mx-1 hash-tag text-truncate" data-html="true">
                                                {item.to === id ? (
                                                  <span>
                                                    {item.blockNumber ? (
                                                      <span class="text-black">
                                                        {item?.toNameTag
                                                          ? item?.toNameTag
                                                          : formatAddress(item.to)}
                                                      </span>
                                                    ) : (
                                                      <i class="text-secondary">
                                                        {item?.toNameTag
                                                          ? item?.toNameTag
                                                          : formatAddress(item.to)}
                                                      </i>
                                                    )}
                                                  </span>
                                                ) : (
                                                  <Link to={`/address/${item.to}`}>
                                                    <span>
                                                      {item.blockNumber ? (
                                                        <span class="text-black">
                                                          {item?.toNameTag
                                                            ? item?.toNameTag
                                                            : formatAddress(item.to)}
                                                        </span>
                                                      ) : (
                                                        <i class="text-secondary">
                                                          {item?.toNameTag
                                                            ? item?.toNameTag
                                                            : formatAddress(item.to)}
                                                        </i>
                                                      )}
                                                    </span>
                                                  </Link>
                                                )}
                                              </span>
                                            </OverlayTrigger>
                                          ) : (
                                            <OverlayTrigger
                                              placement="top"
                                              width="100px"
                                              trigger="hover"
                                              overlay={<Tooltip>{item.contractAddress}</Tooltip>}
                                            >
                                              <span className="mx-1 hash-tag text-truncate" data-html="true">
                                                {item.contractAddress === id ? (
                                                  <span>
                                                    {item.blockNumber ? (
                                                      <span class="text-black">Contract Creation</span>
                                                    ) : (
                                                      <i class="text-secondary">Contract Creation</i>
                                                    )}
                                                  </span>
                                                ) : (
                                                  <Link to={`/address/${item.contractAddress}`}>
                                                    <span>
                                                      {item.blockNumber ? (
                                                        <span class="text-black">Contract Creation</span>
                                                      ) : (
                                                        <i class="text-secondary">Contract Creation</i>
                                                      )}
                                                    </span>
                                                  </Link>
                                                )}
                                              </span>
                                            </OverlayTrigger>
                                          )}
                                        </span>
                                      </td>
                                      <td>
                                        <span className="text-black">
                                          <span>
                                            {item.blockNumber ? (
                                              <span class="text-black">
                                                {(Number(value) !== 0 ? Number(value).toFixed(5) : 0) +
                                                  " " +
                                                  process.env.REACT_APP_TICKER}
                                              </span>
                                            ) : (
                                              <i class="text-secondary">
                                                {(Number(value) !== 0 ? Number(value).toFixed(5) : 0) +
                                                  " " +
                                                  process.env.REACT_APP_TICKER}
                                              </i>
                                            )}
                                          </span>
                                        </span>
                                      </td>
                                      <td>
                                        <span>
                                          {item.blockNumber ? (
                                            <span class="small text-secondary">
                                              {Number(item.fee).toFixed(5)}
                                            </span>
                                          ) : (
                                            <i class="text-secondary">{Number(item.fee).toFixed(5)}</i>
                                          )}
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <NoEntryMessage message={process.env.REACT_APP_BLANK_DATA_MESSAGE} />
                              )}
                            </tbody>
                          </table>
                        </div>
                        {addressTransactions && addressTransactions?.length ? (
                          <>
                            <div class="row w-100 mt-3">
                              <div class="col-md-6 mt-3">
                                <Pagesize setPageSize={setPageSize} PageSize={PageSize} />
                              </div>
                              <div class="col-md-6 mt-3">
                                <Pagination
                                  currentPage={currentPage}
                                  setCurrentPage={setCurrentPage}
                                  totalPage={pages}
                                />
                              </div>
                            </div>
                            <div
                              id="ContentPlaceHolder1_divExport"
                              className="d-flex justify-content-between align-items-center mt-3 mb-4"
                            >
                              <div className="d-flex align-items-center text-secondary" />
                              <div className="small text-muted">
                                {/* {myData.length ? ( */}
                                <span className="text-end mx-2">
                                  [<span className="text-secondary small">&nbsp;Download&nbsp;</span>
                                  {/* <CSVLink >Export to CSV</CSVLink> */}
                                  <i className="ml-1 fa fa-download text-secondary" /> &nbsp;]
                                </span>
                                {/* ) : (
                                                        ""
                                                    )} */}
                              </div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    </div>
                  ) : hash === "#tokentxns" ? (
                    Number(erc20Total) > 0 ? (
                      <Erc20TokenTxns
                        id={id}
                        addressDetails={addressDetails}
                        tokens={erc20Tokens}
                        spinner={erc20Spinner}
                        total={erc20Total}
                        setPageSize={setErc20PageSize}
                        PageSize={erc20PageSize}
                        currentPage={erc20CurrentPage}
                        setCurrentPage={setErc20CurrentPage}
                        pages={erc20Pages}
                      />
                    ) : (
                      ""
                    )
                  ) : hash === "#tokentxnsErc721" ? (
                    Number(erc721Total) > 0 ? (
                      <Erc721TokenTxns
                        id={id}
                        addressDetails={addressDetails}
                        tokens={erc721Tokens}
                        spinner={erc721Spinner}
                        total={erc721Total}
                        setPageSize={setErc721PageSize}
                        PageSize={erc721PageSize}
                        currentPage={erc721CurrentPage}
                        setCurrentPage={setErc721CurrentPage}
                        pages={erc721Pages}
                      />
                    ) : (
                      ""
                    )
                  ) : hash === "#tokentxnsErc1155" ? (
                    Number(erc1155Total) > 0 ? (
                      <Erc1155TokenTxns
                        id={id}
                        addressDetails={addressDetails}
                        tokens={erc1155Tokens}
                        spinner={erc1155Spinner}
                        total={erc1155Total}
                        setPageSize={setErc1155PageSize}
                        PageSize={erc1155PageSize}
                        currentPage={erc1155CurrentPage}
                        setCurrentPage={setErc1155CurrentPage}
                        pages={erc1155Pages}
                      />
                    ) : (
                      ""
                    )
                  ) : hash === "#code" || hash === "#readContract" || hash === "#writeContract" ? (
                    contractDeta === null ? (
                      ""
                    ) : contractSpinner ? (
                      <Spinner />
                    ) : (
                      <ContractDetailsForAddress contractDeta={contractDeta} hash={hash} id={id} />
                    )
                  ) : hash === "#internaltxns" && internalTxs?.length ? (
                    <div
                      className="tab-pane fade active show"
                      id="internaltx"
                      role="tabpanel"
                      aria-labelledby="internaltx-tab"
                    >
                      <div className="d-md-flex justify-content-between mb-4">
                        <div className="mb-2 mb-lg-0 mt-1">
                          <div className="d-flex">
                            <i className="fas fa-sort-amount-down text-secondary mr-1 mt-1" />
                            Latest {totalIntTxs} internal transaction
                            <div
                              className="ml-2"
                              id="divswitch"
                              data-container="body"
                              data-toggle="popover"
                              data-placement="top"
                              data-original-title
                              title
                              data-content="Toggle between Simplified and Advanced view. The 'Advanced' view also shows zero value MATIC transfers, while the 'Simple' view only shows MATIC transfers with value. Name tags integration is not available in advanced view"
                              style={{ display: "none" }}
                            >
                              <label
                                className="checkbox-switch mb-0"
                                id="labelswitch"
                                data-toggle="tooltip"
                                data-placement="top"
                                data-original-title
                                title
                              >
                                <input
                                  type="checkbox"
                                  className="checkbox-switch__input"
                                  id="customSwitch1"
                                  onclick="toggleMode();"
                                />
                                <span className="checkbox-switch__slider" />
                              </label>
                            </div>
                          </div>
                        </div>
                        <nav aria-label="page navigation">
                          <a
                            id="ContentPlaceHolder1_linkShowMoreInternalTx"
                            title
                            className="btn btn-xss btn-primary"
                            data-toggle="tooltip"
                            data-toggle-second="tooltip"
                            href="/txsInternal?a=0xc590175e458b83680867afd273527ff58f74c02b"
                            data-original-title="View All Internal Transactions"
                          >
                            View All
                          </a>
                        </nav>
                      </div>
                      <div
                        id="ContentPlaceHolder1_divinternaltxtable"
                        className="table-responsive mb-2 mb-md-0"
                      >
                        <table className="table table-hover">
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Parent Txn Hash</th>
                              <th scope="col">Block</th>
                              <th scope="col">
                                {intAgeColChange ? (
                                  <OverlayTrigger
                                    placement="top"
                                    trigger="hover"
                                    overlay={<Tooltip>Click to show Datetime Format</Tooltip>}
                                  >
                                    <a
                                      onClick={() => setintAgeColChange(!intAgeColChange)}
                                      id="lnkAgeDateTime"
                                      className="text-primary"
                                    >
                                      Age
                                    </a>
                                  </OverlayTrigger>
                                ) : (
                                  <OverlayTrigger
                                    placement="top"
                                    trigger="hover"
                                    overlay={<Tooltip>Click to show Age Format</Tooltip>}
                                  >
                                    <a
                                      onClick={() => setintAgeColChange(!intAgeColChange)}
                                      className="text-primary"
                                    >
                                      Date Time (UTC)
                                    </a>
                                  </OverlayTrigger>
                                )}
                              </th>
                              <th scope="col">From</th>
                              <th scope="col"></th>
                              {/* <th scope="col"  /> */}
                              <th scope="col">To</th>
                              <th scope="col">Value</th>
                              <th scope="col" />
                            </tr>
                          </thead>
                          <tbody>
                            {interTxsSpinner ? (
                              <>
                                <tr>
                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="150"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="250"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="150"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="250"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="150"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="250"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="150"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="250"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="150"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="250"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="150"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="250"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>
                                </tr>

                                <tr>
                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="150"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="250"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>

                                  <td>
                                    {" "}
                                    <FadingLoaderCard1
                                      x="0"
                                      y="3"
                                      rx="3"
                                      ry="3"
                                      width="50"
                                      height="15"
                                    />{" "}
                                  </td>
                                </tr>
                              </>
                            ) : internalTxs && internalTxs?.length ? (
                              internalTxs?.map((item, ind) => {
                                return (
                                  <tr>
                                    <td>
                                      <Link className="hash-tag text-truncate" to={`/tx/${item.hash}`}>
                                        {item.hash}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link
                                        className="hash-tag text-truncate"
                                        to={`/block/${item.blockNumber}`}
                                      >
                                        {item.blockNumber}
                                      </Link>
                                    </td>
                                    <td className="showDate " style={{ display: "none !important" }}>
                                      {intAgeColChange ? (
                                        <span rel="tooltip" data-toggle="tooltip" data-placement="bottom">
                                          {item.age}
                                        </span>
                                      ) : (
                                        <span rel="tooltip" data-toggle="tooltip" data-placement="bottom">
                                          {formatDate(item.timestamp)}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {item.isSendercontract ? (
                                        <OverlayTrigger
                                          placement="top"
                                          trigger="hover"
                                          overlay={<Tooltip>Contract</Tooltip>}
                                        >
                                          <i
                                            className="far fa-file-alt text-secondary"
                                            data-toggle="tooltip"
                                          />
                                        </OverlayTrigger>
                                      ) : (
                                        ""
                                      )}

                                      {item.from === addressDetails?.address ? (
                                        <OverlayTrigger
                                          placement="top"
                                          trigger="hover"
                                          overlay={<Tooltip>{item.from}</Tooltip>}
                                        >
                                          <span className="hash-tag text-truncate">
                                            {formatAddress(item.from)}
                                          </span>
                                        </OverlayTrigger>
                                      ) : (
                                        <OverlayTrigger
                                          placement="top"
                                          trigger="hover"
                                          overlay={<Tooltip>{item.from}</Tooltip>}
                                        >
                                          <Link
                                            className="hash-tag text-truncate"
                                            to={`/address/${item.from}`}
                                          >
                                            {formatAddress(item.from)}
                                          </Link>
                                        </OverlayTrigger>
                                      )}
                                    </td>
                                    <td>
                                      <span className="btn btn-xs btn-icon btn-soft-success rounded-circle">
                                        <i className="fas fa-long-arrow-alt-right btn-icon__inner" />
                                      </span>
                                    </td>
                                    <td>
                                      {item.isRecievercontract ? (
                                        <OverlayTrigger
                                          placement="top"
                                          trigger="hover"
                                          overlay={<Tooltip>Contract</Tooltip>}
                                        >
                                          <i
                                            className="far fa-file-alt text-secondary"
                                            data-toggle="tooltip"
                                          />
                                        </OverlayTrigger>
                                      ) : (
                                        ""
                                      )}

                                      {item.to === addressDetails?.address ? (
                                        <OverlayTrigger
                                          placement="top"
                                          trigger="hover"
                                          overlay={<Tooltip>{item.to}</Tooltip>}
                                        >
                                          <span
                                            className="hash-tag text-truncate"
                                            data-boundary="viewport"
                                            data-html="true"
                                          >
                                            {formatAddress(item.to)}
                                          </span>
                                        </OverlayTrigger>
                                      ) : (
                                        <OverlayTrigger
                                          placement="top"
                                          trigger="hover"
                                          overlay={<Tooltip>{item.to}</Tooltip>}
                                        >
                                          <Link className="hash-tag text-truncate" to={`/address/${item.to}`}>
                                            {formatAddress(item.to)}
                                          </Link>
                                        </OverlayTrigger>
                                      )}
                                    </td>
                                    <td>{item.value + " " + process.env.REACT_APP_TICKER}</td>
                                  </tr>
                                );
                              })
                            ) : (
                              ""
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div class="row w-100 mt-3">
                        {interTxsSpinner ? (
                          <>
                            <div class="col-md-6 mt-3">
                              <Pagesize setPageSize={setIntPageSize} PageSize={intPageSize} />
                            </div>
                            <div class="col-md-6 mt-3">
                              <Pagination
                                currentPage={intCurrentPage}
                                setCurrentPage={setIntCurrentPage}
                                totalPage={intPages}
                              />
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="d-flex justify-content-between align-items-center mt-3 mb-4">
                        <div className="d-flex align-items-center text-secondary"></div>
                        <div className="small text-muted">
                          <span
                            className="text-sm-right"
                            style={{ marginTop: "20px" }}
                            data-toggle="tooltip"
                            title
                            data-original-title="Export records in CSV format"
                          >
                            [ Download{" "}
                            <a
                              href="/exportData?type=internaltxns&a=0xc590175e458b83680867afd273527ff58f74c02b"
                              target="_blank"
                            >
                              <b>CSV Export</b>
                            </a>
                            &nbsp;
                            <span className="fas fa-download text-secondary" /> ]&nbsp;
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : hash === "#mine" ? (
                    <ValidatedBlocks id={id} />
                  ) : (
                    ""
                  )}

                  <div className="tab-pane fade" id="analytics">
                    <p>Analytics data </p>
                  </div>
                  <div className="tab-pane fade in" id="comments">
                    <div>
                      <p>
                        {" "}
                        Make sure to use the "Vote Down" button for any spammy posts, and the "Vote Up" for
                        interesting conversations.
                      </p>
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel1"
          aria-hidden="true"
          id="responsive"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header p-2">
                <h5
                  className="pl-3 modal-title h6 font-weight-bold text-break w-100 mt-2 mb-2"
                  id="myresponsiveLabel"
                >
                  My Address Private Note
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={clearStates}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                {errorMsg ? (
                  <span id="spanResult">
                    <div className="alert alert-danger alert-dismissible">
                      <strong>Status:</strong> Error! {errorMsg}{" "}
                      <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="True">×</span>
                      </button>
                    </div>
                  </span>
                ) : (
                  ""
                )}
                {successMsg ? (
                  <span id="spanResult">
                    <div className="alert alert-success alert-dismissible">
                      <strong>Status:</strong> Success! {successMsg}{" "}
                      <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="True">×</span>
                      </button>
                    </div>
                  </span>
                ) : (
                  ""
                )}
                <div className="form-group row">
                  <label className="col-sm-12 mt-12" htmlFor="nameTag">
                    &nbsp;Name Tag :
                  </label>
                  <div className="input-group input-group-sm col-sm-12">
                    <input
                      name="ctl00$ContentPlaceHolder1$txtPrivateNameTag"
                      type="text"
                      value={nameTag}
                      maxLength={35}
                      id="ContentPlaceHolder1_txtPrivateNameTag"
                      className={
                        nameTagError === null
                          ? "form-control form-control-sm"
                          : nameTagError === ""
                          ? "form-control form-control-sm success"
                          : "form-control form-control-sm error"
                      }
                      onChange={(e) => {
                        handleNameTagChange(e.target.value);
                      }}
                      spellCheck="false"
                    />
                    {nameTagError && (
                      <div
                        id="ContentPlaceHolder1_txtUserName-error"
                        class="invalid-feedback"
                        style={{ display: "Block" }}
                      >
                        {nameTagError}
                      </div>
                    )}
                  </div>
                  <div className="small text-secondary mt-1 ml-3">
                    <span id="privatetagtip">
                      Private Name Tags (up to 35 characters) can be used for easy identification of addresses
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 mt-12" htmlFor="nameTag">
                    &nbsp;Private Note :
                  </label>
                  <div className="input-group input-group-sm col-sm-12">
                    <textarea
                      name="ctl00$ContentPlaceHolder1$txtPrivateNoteArea"
                      value={addNotes}
                      rows={6}
                      cols={20}
                      id="ContentPlaceHolder1_txtPrivateNoteArea"
                      className={
                        addNotesError === null
                          ? "form-control form-control-sm"
                          : addNotesError === ""
                          ? "form-control form-control-sm success"
                          : "form-control form-control-sm error"
                      }
                      onChange={(e) => {
                        handleNoteChange(e.target.value);
                      }}
                    />
                    {addNotesError && (
                      <div
                        id="ContentPlaceHolder1_txtUserName-error"
                        class="invalid-feedback"
                        style={{ display: "Block" }}
                      >
                        {addNotesError}
                      </div>
                    )}
                  </div>
                  <div className="small text-secondary mt-1 ml-3">
                    <span id="privatenotetip" />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  data-dismiss="modal"
                  className="btn btn-xs btn-secondary"
                  type="button"
                  id="closenote"
                  onClick={clearStates}
                >
                  Cancel
                </button>
                {editAddNote ? (
                  <button
                    className="btn btn-xs btn-primary"
                    type="button"
                    id="savenote"
                    data-dismiss="modal"
                    onClick={(e) => {
                      updateAddressNote(e);
                    }}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    className="btn btn-xs btn-primary"
                    type="button"
                    id="savenote"
                    data-dismiss="modal"
                    onClick={(e) => {
                      addAddressNote(e);
                    }}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default Address;
