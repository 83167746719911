import React from "react";

const Pagesize = ({ setPageSize, PageSize }) => {
    return (
        <>

            <div className="float-left d-flex">
                <span className="mt-1">show</span>
                <select onChange={(e) => setPageSize(e.target.value)} className="custom-select custom-select-xs mx-2" style={{ borderRadius: "5px", backgroundColor: "transparent" }}>
                    <option value={Number(10)} selected={Number(PageSize) === 10 ? true : false}>10</option>
                    <option value={Number(25)} selected={Number(PageSize) === 25 ? true : false}>25</option>
                    <option value={Number(50)} selected={Number(PageSize) === 50 ? true : false}>50</option>
                    <option value={Number(100)} selected={Number(PageSize) === 100 ? true : false}>100</option>
                </select>
                <span className="mt-1"> Records</span>
            </div>
        </>
    )
}

export default Pagesize;