import React from "react";
import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Totalaccounts = ({ totalAccounts }) => {
    return (
        <div className="media align-items-center">
            <figure className="mx-4">
                <img src="asset/images/Homecardimages/calculating_image.png" alt="Market Cap" className="homepage_icon" />
            </figure>
            <div className="media-body text-left">
                <h2 className="font-size-1 text-uppercase text-secondary mb-0">Total Accounts</h2>
                <OverlayTrigger placement="bottom" trigger="hover" overlay={<Tooltip>{process.env.REACT_APP_TICKER} Accounts</Tooltip>}>
                    <Link className="text-size-1 text-link" to="/accounts" rel="tooltip" data-placement="bottom" title="">
                        {totalAccounts}
                    </Link>
                </OverlayTrigger>
            </div>
        </div>
    );
};

export default Totalaccounts;
