import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Header2 from "../common/Header2";
import Spinner from "../common/Spinner";
import Pagesize from "../common/Pagesize";
import Footer from "../common/Footer";
import NoEntryMessage from "../common/NoEntryMessage";
import { IgniteContext } from "../IgniteContext";
import FadingLoaderCard1 from "../common/ContentLoaders/LoaderBigLine";

const Erc1155Tokens = () => {
  document.title = `Token Tracker | ${process.env.REACT_APP_NAME}Scan`;
  const { numberWithCommas } = useContext(IgniteContext);

  const history = useNavigate();
  const location = useLocation();

  const pagefromurl = location.search.split("p=")[1];
  const cpage = pagefromurl ? Number(pagefromurl) : 1;

  const [currentPage, setCurrentPage] = useState(cpage);
  const [tokens, setTokens] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [totalTokens, setTotalTokens] = useState(1);
  const [pages, setPages] = useState(1);
  const [PageSize, setPageSize] = useState(10);

  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;

  if (location.search) {
    if (currentPage !== Number(location.search.split("p=")[1])) {
      setCurrentPage(Number(location.search.split("p=")[1]));
    }
  }

  const getTokens = async () => {
    setSpinner(true);
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}tokens`, {
        params: {
          limit: PageSize,
          page: currentPage,
          type: "erc1155",
        },
      })
      .then((res) => {
        if (res.data.error) {
          console.log("error in getTokens : ", res.data);
        } else {
          setSpinner(false);
          setTokens(res.data.items);
          setTotalTokens(res.data.total);
          setPages(res.data.pages);
        }
      });
  };

  useEffect(() => {
    getTokens();
  }, [PageSize, currentPage]);

  const onNext = (page) => {
    if (page === lastPageIndex) return false;
    // setPage(n);
    setCurrentPage(currentPage + 1);
    history({
      pathname: "/tokens",
      search: "?p=" + (currentPage + 1),
    });
  };

  const onPrevious = (page) => {
    if (page === 1) return false;
    setCurrentPage(currentPage - 1);
    // setMinPage(n);
    history({
      pathname: "/tokens",
      search: "?p=" + (currentPage - 1),
    });
  };

  return (
    <>
      <Header2 />
      <main id="content" role="main" style={{ minHeight: "800px" }}>
        <div className="container">
          <div className="d-md-flex justify-content-between align-items-center py-3">
            <div className="d-sm-flex align-items-center">
              <div className="mb-2 mb-sm-0">
                <h1 className="h4 d-flex align-items-center mb-0">
                  Multi-Token Token Tracker
                  <span
                    className="u-label u-label--xs u-label--secondary rounded color-strong ml-2"
                    href="#"
                    data-toggle="tooltip"
                    title
                    data-original-title={`External link to SRC1155 Information`}
                  >
                    SRC1155
                  </span>
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container space-bottom-2">
          <div className="card">
            <div className="card-header d-md-flex justify-content-between align-items-center">
              <div className="d-flex flex-wrap">
                <div className="position-relative mr-2 mb-2 mb-md-0">
                  <h2 className="card-header-title">SRC1155 Tokens</h2>
                </div>
                <div className="mr-2 mb-2 mb-md-0"></div>
                <div className="mr-2 mb-2 mb-md-0" />
              </div>
              <div className="d-flex ml-auto">
                <div
                  className="position-relative order-1 order-md-2"
                  data-toggle="tooltip"
                  title
                  data-original-title="Search for Token Name or Address"
                >
                  {/* <a id="searchFilterInvoker" className="btn btn-sm btn-icon btn-primary  input_search_box" href="javascript:void(0);" role="button" aria-controls="searchFilter" aria-haspopup="true" aria-expanded="false" data-unfold-target="#searchFilter" data-unfold-type="css-animation" data-unfold-duration={300} data-unfold-delay={300} data-unfold-hide-on-scroll="false" data-unfold-animation-in="slideInUp" data-unfold-animation-out="fadeOut">
                                        <i className="fa fa-search btn-icon__inner" />
                                    </a> */}
                  <div
                    id="searchFilter"
                    className="dropdown-menu dropdown-unfold dropdown-menu-sm-right p-2 u-unfold--css-animation u-unfold--hidden fadeOut"
                    aria-labelledby="searchFilterInvoker"
                    style={{ width: "265px", animationDuration: "300ms" }}
                  >
                    <form action method="get" className="js-focus-state input-group input-group-sm w-100">
                      <input
                        name="cn"
                        type="search"
                        maxLength={60}
                        defaultValue
                        className="form-control"
                        placeholder="Search for Token Name or Address"
                      />
                      <div className="input-group-append">
                        <button
                          type="submit"
                          className="btn btn-primary"
                          data-toggle="tooltip"
                          data-original-title="Search for Token Name or Address"
                        >
                          Find
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive mb-2 mb-md-0">
                <p className="text-grey">
                  More than <span className="text-black">{totalTokens}</span> Tokens found
                </p>
                <table
                  className="sticky-thead-cloned-table table table-text-normal table-hover mb-0 border-bottom-0"
                  id="datatable"
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col" width={220}>
                        Name
                      </th>
                      <th scope="col">Symbol</th>
                      <th scope="col">Decimal</th>
                      <th scope="col">Contract Address</th>
                      <th scope="col">
                        Total Supply{" "}
                        <span
                          data-toggle="tooltip"
                          title
                          size={2}
                          data-original-title="Circulating Supply MarketCap - Calculated by multiplying the number of outstanding tokens with the current market price"
                        >
                          <i className="fas fa-info-circle" />
                        </span>
                      </th>
                      <th scope="col">Holders</th>
                    </tr>
                  </thead>
                  <tbody>
                    {spinner ? (
                      <>
                        <tr>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                          </td>
                          <td>
                            {" "}
                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                          </td>
                        </tr>
                      </>
                    ) : tokens && tokens?.length ? (
                      tokens?.map((item, index) => {
                        const ind = currentPage * 10 - 10 + (index + 1);
                        return (
                          <tr>
                            <td>{ind}</td>
                            <td>
                              <div className="media">
                                <img
                                  className="u-xs-avatar mr-2"
                                  src={
                                    item && (item?.status === "true" || item?.status === true) && item?.logo
                                      ? process.env.REACT_APP_TOKEN_LOGO_PATH + item?.logo
                                      : "/asset/images/48.png"
                                  }
                                  alt="not found."
                                />
                                <div className="media-body">
                                  <h3 className="h6 mb-0">
                                    <Link className="text-primary" to={`/token/${item.address}`}>
                                      {item.name}
                                    </Link>
                                  </h3>
                                </div>
                              </div>
                            </td>
                            <td className="text-nowrap">
                              {item.symbol}
                              <div className="small text-secondary"></div>
                            </td>
                            <td className="text-nowrap">
                              {item.decimals}
                              <div className="small text-secondary"></div>
                            </td>
                            <td>
                              <Link to={`/address/${item.address}`} class="text-blue">
                                {item.address}
                              </Link>{" "}
                              &nbsp;&nbsp;
                            </td>
                            <td>{item.totalSupplyNumber}</td>
                            <td>{numberWithCommas(item.holders)}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <NoEntryMessage message={process.env.REACT_APP_BLANK_DATA_MESSAGE} />
                    )}
                  </tbody>
                </table>
                {tokens && tokens?.length ? (
                  <div class="row w-100 mt-3">
                    <div class="col-md-6 mt-3">
                      <Pagesize setPageSize={setPageSize} PageSize={PageSize} />
                    </div>
                    <div class="col-md-6 mt-3">
                      <div className="float-right mr-3">
                        <div class="row">
                          <button
                            class={currentPage === 1 ? "page-link disabled" : "page-link"}
                            onClick={() => {
                              setCurrentPage(1);
                              history({
                                pathname: "/tokens",
                                search: "?p=" + 1,
                              });
                            }}
                            disabled={currentPage === 1}
                          >
                            First
                          </button>
                          <button
                            class={currentPage === 1 ? "page-link disabled" : "page-link"}
                            onClick={() => onPrevious(currentPage)}
                            disabled={currentPage === 1}
                          >
                            <i className="fa fa-angle-left" />
                          </button>
                          <div class="page-link-badge" disabled>
                            {" "}
                            {`${currentPage} of ${pages}`}
                          </div>
                          <button
                            class={currentPage === pages ? "page-link disabled" : "page-link"}
                            onClick={() => onNext(currentPage)}
                            disabled={currentPage === pages}
                          >
                            <i className="fa fa-angle-right" />
                          </button>
                          <button
                            class={currentPage === pages ? "page-link disabled" : "page-link"}
                            onClick={() => {
                              setCurrentPage(pages);
                              history({
                                pathname: "/tokens",
                                search: "?p=" + pages,
                              });
                            }}
                            disabled={currentPage === pages}
                          >
                            Last
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Erc1155Tokens;
