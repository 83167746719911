import React, { useContext } from "react";
import Header2 from "../common/Header2";
import Footer from "../common/Footer";
import { IgniteContext } from "../IgniteContext";

const BugBounty = () => {
  const { theme } = useContext(IgniteContext);
  return (
    <>
      <Header2 />
      <main id="content" role="main">
        <div className="d-lg-flex">
          <div className="container d-lg-flex align-items-lg-center space-2 space-sm-3 space-lg-0">
            <div className="w-lg-80 mx-lg-auto">
              <div className="card">
                <div className="position-relative info-page-title space-1 space-md-2 p-4 px-md-7 px-md-9 rounded-lg">
                  <h1 className="text-white text-center">Bug Bounty Program at STOR Scan</h1>
                  {/* <p className="text-white-70">Last updated: 25 September, 2023</p> */}
                  <figure className="position-absolute right-0 bottom-0 left-0 mb-n1">
                    {theme === "normal" ? (
                      <svg
                        preserveAspectRatio="none"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 1920 100.1"
                        width="100%"
                        height={32}
                      >
                        <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                      </svg>
                    ) : (
                      <svg
                        preserveAspectRatio="none"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 1920 100.1"
                        width="100%"
                        height={32}
                      >
                        <path fill="#212121" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                      </svg>
                    )}
                  </figure>
                </div>
                <div className="card-body p-4 p-md-9">
                  <div className="mb-5">
                    <p>
                      Welcome to the Bug Bounty Program at STOR Scan! We take the security of our platform
                      seriously and recognize the valuable contributions of security researchers and ethical
                      hackers in helping us maintain a safe and secure trading environment. If you've
                      discovered a potential security vulnerability on our platform, we encourage you to
                      participate in our bug bounty program.
                    </p>
                    <br />
                    <p>
                      <strong>Our Commitment to Security</strong>
                    </p>
                    <p>
                      At STOR Scan, we are committed to ensuring the highest level of security for our users.
                      We understand that vulnerabilities can exist despite our best efforts, so we have
                      established this bug bounty program. By working with the security community, we can
                      proactively identify and address potential issues, enhancing the overall security of our
                      platform.
                    </p>
                  </div>
                  <p>
                    <strong>How to Participate</strong>
                  </p>
                  <p>Participating in the STOR Scan Bug Bounty Program is straightforward:</p>
                  <div className="mb-5">
                    <p>
                      <strong>Scope: </strong>Please review our content to ensure your findings are eligible
                      for the program. Focus on areas related to our trading platform, user accounts,
                      authentication, and fund security.
                    </p>
                    <p>
                      <strong>Report: </strong>
                      If you discover a potential security vulnerability, we ask that you responsibly disclose
                      it by submitting a detailed report. Be sure to include all relevant information,
                      including a detailed description of the issue, its potential impact, and any supporting
                      evidence.
                    </p>
                    <p>
                      <strong>Assessment: </strong>Our security team will assess your report promptly. We will
                      verify the issue and evaluate its severity.
                    </p>
                    <p>
                      <strong>Rewards: </strong>If your report is valid and falls within the scope of our
                      program, you may be eligible for a prize. The reward amount will be determined based on
                      the severity of the issue and its potential impact.
                    </p>
                  </div>
                  <div className="mb-5">
                    <p>
                      <strong>Scope of the Bug Bounty Program</strong>
                    </p>
                    <p>
                      The STOR Scan Bug Bounty Program covers a wide range of areas, including but not limited
                      to:
                    </p>
                    <p>
                      Welcome to the Bug Bounty Program at STOR Scan! We take the security of our platform
                      seriously and recognize the valuable contributions of security researchers and ethical
                      hackers in helping us maintain a safe and secure trading environment. If you've
                      discovered a potential security vulnerability on our platform, we encourage you to
                      participate in our bug bounty program.
                    </p>
                    <p>
                      <ul className="text-secondary">
                        <li>Authentication vulnerabilities</li>
                        <li>Cross-Site Scripting (XSS)</li>
                        <li>Cross-Site Request Forgery (CSRF)</li>
                        <li>Remote Code Execution (RCE)</li>
                        <li>SQL Injection</li>
                        <li>Server-Side Request Forgery (SSRF)</li>
                        <li>Information disclosure issues</li>
                        <li>Escalation of privileges</li>
                      </ul>
                    </p>
                    <p>
                      Please refer to our Bug Bounty Program Policy for more details on eligibility, rewards,
                      and the responsible disclosure process.
                    </p>
                  </div>
                  <div className="mb-5">
                    <p>
                      <strong>Responsible Disclosure</strong>
                    </p>
                    <p>
                      We request that all security researchers adhere to responsible disclosure practices when
                      participating in our program. This includes:
                    </p>
                    <p>
                      <ul className="text-secondary">
                        <li>
                          Not exploiting the vulnerability further than necessary to demonstrate the issue.
                        </li>
                        <li>
                          Please do not share or disclose the vulnerability with anyone else until it has been
                          verified and resolved.
                        </li>
                        <li>
                          Providing us with reasonable time to address and remediate the issue before
                          disclosing it publicly.
                        </li>
                      </ul>
                    </p>
                  </div>
                  <div className="mb-5">
                    <p>
                      <strong>Get Started</strong>
                    </p>
                    <p>
                      To begin your bug-hunting journey with STOR Scan, don't hesitate to contact us to report
                      your findings or ask any questions you may have about our Bug Bounty Program. Your
                      contributions to our platform's security are greatly appreciated, and we look forward to
                      working together to enhance the safety of our trading environment.
                    </p>
                    <p>Thank you for helping us make STOR Scan a more secure place for all our users.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default BugBounty;
