import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Marketcap = ({ marketCap }) => {
  return (
    <div className="media align-items-center">
      <figure className="mx-4">
        <i className="fas fa-globe homepage_icon  fa-2x"></i>
        {/*<img src="asset/images/Homecardimages/market-cap.png" alt="Network Difficulty"*/}
        {/*     className="homepage_icon"/>*/}
      </figure>
      <div className="media-body text-left">
        <h2 className="font-size-1 text-uppercase text-secondary mb-0">market cap</h2>
        <OverlayTrigger
          placement="bottom"
          trigger="hover"
          overlay={<Tooltip>Total Marketcap of {process.env.REACT_APP_TICKER}</Tooltip>}
        >
          <a className="text-size-1 text-link" href rel="tooltip" data-placement="bottom" title="">
            ${Number(process.env.REACT_APP_PRICE) * 21000000}
          </a>
        </OverlayTrigger>
      </div>
      <div className="text-right" />
    </div>
  );
};

export default Marketcap;
