import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Link, useLocation, useParams } from "react-router-dom";
import Header2 from "../common/Header2";
import Hash from "./TransactionComponents/Hash";
import Status from "./TransactionComponents/Status";
import Block from "./TransactionComponents/Block";
import Timestamp from "./TransactionComponents/TimeStamp";
import From from "./TransactionComponents/From";
import To from "./TransactionComponents/To";
import Value from "./TransactionComponents/Value";
import TransactionFee from "./TransactionComponents/TransactionFee";
import Gasprice from "./TransactionComponents/GasPrice";
import GasLimit from "./TransactionComponents/GasLimit";
import GasUsedByTansaction from "./TransactionComponents/GasUsedByTansaction";
import Nonce from "./TransactionComponents/Nonce";
import Inputdata from "./TransactionComponents/InputData";
import Erc20TokenTransfered from "./TransactionComponents/Erc20TokenTransfered";
import Erc721TokenTransfered from "./TransactionComponents/Erc721TokenTransfered";
import Erc1155TokenTransfered from "./TransactionComponents/Erc1155TokenTransfered";
import Spinner from "../common/Spinner";
import Footer from "../common/Footer";
import NoDataFound from "../common/NoDataFound";
import InternalTxs from "./InternalTxs";
import TransactionLogs from "./TransactionLogs";
import Type from "./TransactionComponents/Type";
import TxnPrivateNameTag from "./TransactionComponents/TxnPrivateNameTag";
import { IgniteContext } from "../IgniteContext";
import TransactionDetailsLoader from "../common/PageContentLoaders/TransactionDetailsLoader";
import Web3 from "web3";

const TransactionDetails = () => {
    document.title = `${process.env.REACT_APP_NAME}chain Transactions Hash (Txhash) Detaild | ${process.env.REACT_APP_NAME}Scan`;

    const { verifyAndGetUser } = useContext(IgniteContext);
    const { encusername } = verifyAndGetUser();

    const location = useLocation();

    const { id } = useParams();
    const { hash } = location;

    const [txDetails, setTxDetails] = useState(null);
    const [logsData, setLogsData] = useState(null);
    const [spinner, setSpinner] = useState(true);

    const getTransactionDetails = async () => {
        setSpinner(true);
        await axios
            .get(`${process.env.REACT_APP_BACKEND_URL}tx/${id}`, {
                headers: {
                    etoken: encusername,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in transaction details : ", res.data.error);
                } else {
                    setTxDetails(res.data);
                    setLogsData(res.data.logs);
                    setSpinner(false);
                }
            })
            .catch((err) => {
                console.log("error in transaction details (catch) : ", err);
            });
    };

    useEffect(() => {
        getTransactionDetails();
    }, []);

    return (
        <>
            <Header2 />
            {spinner ? (
                <TransactionDetailsLoader />
            ) : !txDetails ? (
                <NoDataFound />
            ) : (
                <main id="content" role="main" style={{ minHeight: "800px" }}>
                    <div className="container py-3 mn-b3">
                        <div className="d-lg-flex justify-content-between align-items-center">
                            <div className="mb-3 mb-lg-0">
                                <h1 className="h4 mb-0"> Transaction Details </h1>
                            </div>
                            <div className="d-flex flex-wrap mt-1 mt-md-0 flex-wrap ml-md-auto" />
                        </div>
                    </div>
                    <div className="container">
                        <div className="border-top py-3">
                            <div className="d-flex text-secondary" style={{ lineHeight: "2.2" }} />
                        </div>
                    </div>
                    <div className="container space-bottom-2">
                        <div className="card">
                            <div className="card-header sticky-card-header d-flex justify-content-between p-0">
                                <ul className="nav nav-custom nav-borderless nav_tabs1" id="nav_tabs" role="tablist">
                                    <li className="nav-item">
                                        <Link
                                            className={`nav-link ${hash === "#internaltxs" || hash === "#logs" ? "" : "active"}`}
                                            to={{ pathname: `/tx/${id}`, hash: "" }}
                                        >
                                            Overview
                                        </Link>
                                    </li>
                                    {txDetails && txDetails?.internalTx?.length ? (
                                        <li className="nav-item">
                                            <Link
                                                className={`nav-link ${hash === "#internaltxs" ? "active" : ""} `}
                                                to={{ pathname: `/tx/${id}`, hash: "internaltxs" }}
                                            >
                                                Internal Txns
                                            </Link>
                                        </li>
                                    ) : (
                                        ""
                                    )}
                                    {txDetails && txDetails?.logs?.length ? (
                                        <li className="nav-item">
                                            <Link
                                                className={`nav-link ${hash === "#logs" ? "active" : ""} `}
                                                to={{ pathname: `/tx/${id}`, hash: "logs" }}
                                            >
                                                Logs ({txDetails?.logs?.length})
                                            </Link>
                                        </li>
                                    ) : (
                                        ""
                                    )}
                                </ul>
                                {!txDetails?.blockNumber && !txDetails?.status ? (
                                    <div id="spinnerwait" className="d-flex align-items-center">
                                        <img className="mr-2" height={20} src="/asset/images/ellipsis-spinner3.svg" alt="" />
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                            {hash === "" ? (
                                <div className="tab-content" id="myTabContent">
                                    <div className="tab-pane fade active show" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        <div id="ContentPlaceHolder1_maintable" className="card-body">
                                            <div>
                                                <Hash hash={txDetails?.hash} />
                                                <Status status={txDetails?.status} />
                                                <Block block={txDetails?.blockNumber} blockConfirmation={txDetails?.blockConfirmation} />
                                                <Timestamp time={txDetails?.age} timeAgo={txDetails?.age} date={txDetails?.timestamp} />
                                                <From
                                                    from={txDetails?.from}
                                                    fromNameTag={txDetails?.fromNameTag ? txDetails?.fromNameTag : txDetails?.publicFromNameTag}
                                                />
                                                <To
                                                    to={txDetails?.to}
                                                    internalTx={txDetails?.internalTx}
                                                    toNameTag={txDetails?.toNameTag ? txDetails?.toNameTag : txDetails?.publicToNameTag}
                                                />
                                                {txDetails?.erc20?.length ? <Erc20TokenTransfered erc20trx={txDetails?.erc20} /> : ""}
                                                {txDetails?.erc721?.length ? <Erc721TokenTransfered erc721trx={txDetails?.erc721} /> : ""}
                                                {txDetails?.erc1155?.length ? <Erc1155TokenTransfered erc1155trx={txDetails?.erc1155} /> : ""}
                                                <Value value={txDetails?.value} rate={0} />
                                                <Type type={txDetails?.type} />
                                                <TransactionFee transactionFee={txDetails?.fee} rate={0} />
                                                {/* <Rewards
                                                validatorReward={"0.002975195"}
                                                txnReward={"0.0007437987"}
                                                systemReward={"0.0008628066"}
                                                foundationReward={"0.0002975195"}
                                                developerReward={"0.0001190078"}
                                                delegatorReward={"0.0002082637"}
                                            /> */}
                                                <div className="collapse " id="collapsePanel">
                                                    <Gasprice price={txDetails?.gasPrice} />
                                                    <GasLimit gasLimit={831540} />
                                                    <GasUsedByTansaction gasUsed={txDetails?.gasUsed} />
                                                    <Nonce nonce={txDetails?.nonce} />
                                                    <Inputdata inputData={txDetails?.inputdata} />
                                                </div>
                                            </div>
                                            <span id="ContentPlaceHolder1_collapsedLink_span">
                                                <a
                                                    className="d-block collapsed"
                                                    id="collapsedLink"
                                                    data-toggle="collapse"
                                                    href="#collapsePanel"
                                                    role="button"
                                                    aria-expanded="false"
                                                    aria-controls="collapsePanel"
                                                >
                                                    <span>
                                                        Click to see <span className="card-arrow-more">more</span>{" "}
                                                        <span className="card-arrow-less">less</span>
                                                        <span className="card-btn-arrow card-btn-arrow--up ml-1">
                                                            <span className="fas fa-arrow-up small my-3" />
                                                        </span>
                                                    </span>
                                                </a>
                                            </span>
                                            <TxnPrivateNameTag
                                                txnNameTag={txDetails?.txNote}
                                                hash={txDetails?.hash}
                                                getTransactionDetails={getTransactionDetails}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ) : hash === "#internaltxs" && txDetails?.internalTx?.length ? (
                                <InternalTxs internalTx={txDetails?.internalTx} fromAddress={txDetails?.from} toAddress={txDetails?.to} />
                            ) : hash === "#logs" && logsData?.length !== 0 ? (
                                <TransactionLogs logsData={txDetails?.logs} />
                            ) : (
                                ""
                            )}
                        </div>
                        <div className="container mt-4 text-center" />
                    </div>
                </main>
            )}
            <Footer />
        </>
    );
};

export default TransactionDetails;
