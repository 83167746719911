import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const Totalsupply = ({ totalSupply }) => {
    return (
        <div className="media align-items-center">
            <figure className="mx-4">
                <img src="asset/images/Homecardimages/total-supply.png" alt="Network Difficulty" className="homepage_icon" />
            </figure>
            <div className="media-body text-left">
                <h2 className="font-size-1 text-uppercase text-secondary mb-0">Total Supply {process.env.REACT_APP_TICKER}</h2>
                <OverlayTrigger placement="bottom" trigger="hover" overlay={<Tooltip>Total Supply of {process.env.REACT_APP_TICKER}</Tooltip>}>
                    <a className="text-size-1 text-link" href rel="tooltip" data-placement="bottom" title="">
                        {totalSupply}
                    </a>
                </OverlayTrigger>
            </div>
            <div className="text-right" />
        </div>
    );
};

export default Totalsupply;
