import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Header2 from "../common/Header2";
import Footer from "../common/Footer";
import { IgniteContext } from "../IgniteContext";
import { Link } from "react-router-dom";
import { formatAmountFromWei } from "../common/functions";
import Web3 from "web3";

const web3 = new Web3(process.env.REACT_APP_HTTP_RPC)

function GetBalances() {
  const { verifyAndGetUser, isAddress } = useContext(IgniteContext);
  const { encusername } = verifyAndGetUser();
  const [tokenHoldingsErc20, setTokenHoldingsErc20] = useState([]);
  const [tokenHoldingsErc721, setTokenHoldingsErc721] = useState([]);
  const [tokenHoldingsErc1155, setTokenHoldingsErc1155] = useState([]);
  const [totalTokenHolding, setTotalTokenHolding] = useState(0);
  document.title = `Get Address balance`;
  const [address, setAddress] = useState("");
  const [error, setError] = useState(null);
  const [balance, setBalance] = useState("");

  const getCoinBalance = async () => {
    try {
      const balWei = await web3.eth.getBalance(address);
      if(balWei) {
        setBalance(formatAmountFromWei(balWei, 18));
      } else {
        setBalance("0")
      }
    } catch(err) {
      setBalance("0");
    }
  }

  const handleGetAddressBalance = async (e) => {
    if (e) {
      e.preventDefault();
    }
    try {
      let temp = true;
      if (!isAddress(address)) {
        setError("Invalid address entered.");
        temp = false;
      } else {
        setError("");
      }
      if (temp) {
        await axios
          .get(`${process.env.REACT_APP_BACKEND_URL}tokens/holding/${address}`, {
            headers: {
              etoken: encusername,
            },
          })
          .then((res) => {
            if (res.data.error) {
              // console.log("error in token holding : ", res.data.error);
            } else {
              let erc20token = res.data.items.filter((item) => item.type === "erc20");
              let erc721token = res.data.items.filter((item) => item.type === "erc721");
              let erc1155token = res.data.items.filter((item) => item.type === "erc1155");

              setTokenHoldingsErc20(erc20token);
              setTokenHoldingsErc721(erc721token);
              setTokenHoldingsErc1155(erc1155token);
              setTotalTokenHolding(res.data.total);
            }
          })
          .catch((err) => {
            // console.log("error in token holding (catch) : ", err);
          });
      } else {
        clearStates();
      }
    } catch (err) {
      console.log("error in handleGetAddressBalance : ", err);
      setError(err.message);
    }
  };

  const clearStates = () => {
    setTokenHoldingsErc20([]);
    setTokenHoldingsErc721([]);
    setTokenHoldingsErc1155([]);
    setTotalTokenHolding(0);
    setBalance("")
  };

  useEffect(() => {
    if (address) {
      getCoinBalance();
      handleGetAddressBalance();
    } else {
      setError("");
      clearStates();
    }
  }, [address]);

  return (
    <>
      <Header2 />
      <main id="content" role="main" style={{ minHeight: "800px" }}>
        <div className="container space-top-1">
          <form id="ctl00" className="js-validate mt-2" onSubmit={(e) => handleGetAddressBalance(e)}>
            <div className="text-center mb-3">
              <div className="w-md-75 w-lg-75 mx-md-auto ">
                <h1 className="h3">Get Address Balance </h1>
              </div>
            </div>
            <div className="py-4">
              <div className="w-md-75 w-lg-50 mx-md-auto">
                <div>
                  <label className="d-block" htmlFor="txtUserName">
                    Enter the address from where you want to receive the balance
                  </label>
                  <input
                    type="search"
                    className="form-control form-control-sm"
                    class={error ? "form-control form-control-sm error" : "form-control form-control-sm"}
                    required
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    placeholder="0x..."
                  />
                </div>
                {error && (
                  <div
                    id="ContentPlaceHolder1_txtUserName-error"
                    class="invalid-feedback"
                    style={{ display: "Block" }}
                  >
                    {error}
                  </div>
                )}
              </div>
            </div>
          </form>
          <div className="w-md-75 w-lg-50 mx-md-auto my-3">
            <div>
              <ul className="p-0">
                {
                  balance ? 
                  <li className="list-custom list-custom-SRC20" style={{ listStyleType: "none" }}>
                    <Link className="link-hover d-flex justify-content-between align-items-center">
                      <div>
                        <div className="d-flex align-items-center">
                          <img
                            className="mr-1 mCS_img_loaded"
                            width={22}
                            src={"/asset/images/48.png"}
                            alt=""
                          />
                          <span className="list-name hash-tag text-truncate">
                            <span>
                              <font color>{process.env.REACT_APP_TICKER}</font>
                            </span>{" "}
                            ({process.env.REACT_APP_TICKER})
                          </span>
                        </div>
                      </div>
                      <span className="list-amount link-hover__item hash-tag hash-tag--md text-truncate">
                        {balance} {process.env.REACT_APP_TICKER}
                      </span>
                    </Link>
                  </li>
                  : ""
                }
                {tokenHoldingsErc20.length ? (
                  <li className="list-custom-divider list-custom-divider-SRC20 d-flex justify-content-between align-items-center font-size-1 rounded-sm py-1 px-2 mb-1">
                    <span>
                      <i className="fa fa-angle-right text-secondary" />
                      <strong> SRC20 Tokens</strong> ({tokenHoldingsErc20.length})
                    </span>
                  </li>
                ) : (
                  ""
                )}
                {tokenHoldingsErc20 &&
                  tokenHoldingsErc20.map((item, ind) => {
                    return (
                      <>
                        <li className="list-custom list-custom-SRC20" style={{ listStyleType: "none" }}>
                          <Link
                            className="link-hover d-flex justify-content-between align-items-center"
                            to={`/token/${item?.token}`}
                          >
                            <div>
                              <div className="d-flex align-items-center">
                                <img
                                  className="mr-1 mCS_img_loaded"
                                  width={22}
                                  src={
                                    item && item?.status === "true" && item?.logo
                                      ? process.env.REACT_APP_TOKEN_LOGO_PATH + item.logo
                                      : "/asset/images/48.png"
                                  }
                                  alt=""
                                />
                                <span className="list-name hash-tag text-truncate">
                                  <span data-toggle="tooltip" title data-original-title="$ SimpSwap.fi">
                                    <font color>{item.name}</font>
                                  </span>{" "}
                                  ({item.symbol})
                                </span>
                              </div>
                            </div>
                              <span className="list-amount link-hover__item hash-tag hash-tag--md text-truncate">
                                {formatAmountFromWei(item.quantity, item.decimals)} {item.symbol}
                              </span>
                          </Link>
                        </li>
                        {/* <hr className="mt-1 mb-2" /> */}
                      </>
                    );
                  })}
                {tokenHoldingsErc721.length ? (
                  <li className="list-custom-divider list-custom-divider-SRC20 d-flex justify-content-between align-items-center font-size-1 rounded-sm py-1 px-2 mb-1">
                    <span>
                      <i className="fa fa-angle-right text-secondary" />
                      <strong> SRC721 Tokens</strong> ({tokenHoldingsErc721.length})
                    </span>
                  </li>
                ) : (
                  ""
                )}
                {tokenHoldingsErc721 &&
                  tokenHoldingsErc721.map((item, ind) => {
                    return (
                      <>
                        <li className="list-custom list-custom-SRC20">
                          <Link
                            className="link-hover d-flex justify-content-between align-items-center"
                            to={`/token/${item?.token}`}
                          >
                            <div>
                              <div className="d-flex align-items-center">
                                <img
                                  className="mr-1 mCS_img_loaded"
                                  width={22}
                                  src={
                                    item && item?.status === "true" && item?.logo
                                      ? process.env.REACT_APP_TOKEN_LOGO_PATH + item.logo
                                      : "/asset/images/48.png"
                                  }
                                  alt=""
                                />
                                <span className="list-name hash-tag text-truncate">
                                  <span data-toggle="tooltip" title data-original-title="$ SimpSwap.fi">
                                    <font color>{item.name}</font>
                                  </span>{" "}
                                  ({item.symbol})
                                </span>
                              </div>
                            </div>
                              <span className="list-amount link-hover__item hash-tag hash-tag--md text-truncate">
                                {formatAmountFromWei(item.quantity, item.decimals)} {item.symbol}
                              </span>
                          </Link>
                        </li>
                      </>
                    );
                  })}
                {tokenHoldingsErc1155.length ? (
                  <li className="list-custom-divider list-custom-divider-SRC20 d-flex justify-content-between align-items-center font-size-1 rounded-sm py-1 px-2 mb-1">
                    <span>
                      <i className="fa fa-angle-right text-secondary" />
                      <strong> SRC1155 Tokens</strong> ({tokenHoldingsErc1155.length})
                    </span>
                  </li>
                ) : (
                  ""
                )}
                {tokenHoldingsErc1155 &&
                  tokenHoldingsErc1155.map((item, ind) => {
                    return (
                      <>
                        <li className="list-custom list-custom-SRC20">
                          <Link
                            className="link-hover d-flex justify-content-between align-items-center"
                            to={`/token/${item?.token}`}
                          >
                            <div>
                              <div className="d-flex align-items-center">
                                <img
                                  className="mr-1 mCS_img_loaded"
                                  width={22}
                                  src={
                                    item && item?.status === "true" && item?.logo
                                      ? process.env.REACT_APP_TOKEN_LOGO_PATH + item.logo
                                      : "/asset/images/48.png"
                                  }
                                  alt=""
                                />
                                <span className="list-name hash-tag text-truncate">
                                  <span data-toggle="tooltip" title data-original-title="$ SimpSwap.fi">
                                    <font color>{item.name}</font>
                                  </span>{" "}
                                  ({item.symbol})
                                </span>
                              </div>
                            </div>
                              <span className="list-amount link-hover__item hash-tag hash-tag--md text-truncate">
                                {formatAmountFromWei(item.quantity, item.decimals)} {item.symbol}
                              </span>
                          </Link>
                        </li>
                      </>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default GetBalances;
