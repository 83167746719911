import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { IgniteContext } from "../../IgniteContext";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";

const Erc721TokenTransfered = ({ erc721trx }) => {
  const { formatTokenName, formatTokenSymbol } = useContext(IgniteContext);

  const popoverTop4 = (
    <Popover id="popover-basic">
      <Popover.Body>
        And here's some <strong>amazing</strong> content. It's very engaging. right?
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <div className="row">
        <div className="col-md-3 mb-1 bs  h6 text-blueish">
          <OverlayTrigger
            style={{ padding: "100px 0" }}
            trigger="hover"
            placement="top"
            overlay={popoverTop4}
          >
            <i class="fal fa-question-circle text-secondary mr-1" aria-hidden="true"></i>
          </OverlayTrigger>
          Token transferred:&nbsp;
          <span class="badge badge-pill badge-secondary align-midle">{erc721trx?.length}</span>
        </div>
        <div className="col-md-9 mb-1 bs bs text-dark bg-igtcds text-truncate rounded bg-transparent">
          {erc721trx?.map((item, index) => {
            return (
              <li className="media align-items-baseline mb-2">
                <span className="row-count text-secondary small mr-1">
                  <i className="fa fa-caret-right" />
                </span>
                <div className="media-body">
                  <span className>
                    <b>From</b>{" "}
                  </span>
                  <span className="hash-tag text-truncate  mr-1">
                    <OverlayTrigger placement="top" trigger="hover" overlay={<Tooltip>{item.from}</Tooltip>}>
                      <Link to={`/token/${item.address}?a=${item.from}`}>
                        <span className="hash-tag text-truncate hash-tag-custom-from tooltip-address">
                          {item?.fromNameTag
                            ? item?.fromNameTag
                            : item?.publicFromNameTag
                            ? item?.publicFromNameTag
                            : item.from}
                        </span>
                      </Link>
                    </OverlayTrigger>
                  </span>
                  <span className="mr-1">
                    <b>To</b>{" "}
                  </span>
                  <span className="hash-tag text-truncate ">
                    <OverlayTrigger placement="top" trigger="hover" overlay={<Tooltip>{item.to}</Tooltip>}>
                      <Link to={`/token/${item.address}?a=${item.to}`}>
                        <span className="hash-tag text-truncate hash-tag-custom-to tooltip-address">
                          {item?.toNameTag
                            ? item?.toNameTag
                            : item?.publicToNameTag
                            ? item?.publicToNameTag
                            : item.to}
                        </span>
                      </Link>
                    </OverlayTrigger>
                  </span>
                  <span className="mr-1">
                    {" "}
                    <b>For</b> SRC721 TokneId [<Link to={""}>{item.tokenId}</Link>]
                  </span>
                  <img
                    src={
                      item && (item?.status === "true" || item?.status === true) && item?.logo
                        ? process.env.REACT_APP_TOKEN_LOGO_PATH + item?.logo
                        : "/asset/images/48.png"
                    }
                    className="mt-n1 mr-1"
                    width={15}
                  />
                  <OverlayTrigger placement="top" trigger="hover" overlay={<Tooltip>{item.name}</Tooltip>}>
                    <Link to={`/token/${item.address}`}>
                      <span data-toggle="tooltip" title data-original-title="Wrapped Matic">
                        <font color>{formatTokenName(item.name)}</font>
                      </span>{" "}
                      ({formatTokenSymbol(item.symbol)})
                    </Link>
                  </OverlayTrigger>
                </div>
              </li>
            );
          })}
        </div>
      </div>
      <hr />
    </>
  );
};

export default Erc721TokenTransfered;
