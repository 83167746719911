import React, { useState, useEffect, useContext } from "react";
import Pagesize from "../../common/Pagesize";
import Pagination from "../../common/Pagination";
import axios from "axios";
import Spinner from "../../common/Spinner";
import NoEntryMessage from "../../common/NoEntryMessage";
import { Link } from "react-router-dom";
import { IgniteContext } from "../../IgniteContext";
import FadingLoaderCard1 from "../../common/ContentLoaders/LoaderBigLine";

const Erc721Holders = ({ id }) => {
    const { verifyAndGetUser } = useContext(IgniteContext);
    const { encusername } = verifyAndGetUser();
    const [currentPage, setCurrentPage] = useState(1);
    const [hldPageSize, setHldPageSize] = useState(10);

    const [tokenHolders, setTokenHolders] = useState([]);
    const [totalHolders, setTotalHolders] = useState(0);
    const [tokenHldSpinner, setTokenHldSpinner] = useState(true);
    const [hldPages, setHldPages] = useState(1);
    const [PageSize, setPageSize] = useState(10);

    const getTokenHolders = async () => {
        setTokenHldSpinner(true);
        await axios
            .get(`${process.env.REACT_APP_BACKEND_URL}erc721/holders/${id}`, {
                params: {
                    limit: PageSize,
                    page: currentPage,
                },
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in get token holders : ", res.data.error);
                } else {
                    setTokenHolders(res.data.items);
                    setTotalHolders(res.data.total);
                    setTokenHldSpinner(false);
                    setHldPages(res.data.pages);
                }
            })
            .catch((err) => {
                console.log("error in get token 721 holders (catch) : ", err);
            });
    };

    useEffect(() => {
        getTokenHolders();
    }, [id, currentPage, hldPageSize]);

    return (
        <div className="tab-pane fade show active">
            <p class="mr-2 mb-2">
                <i class="fas fa-sort-amount-down text-secondary" data-toggle="tooltip" title="" data-original-title="Oldest First"></i>
                &nbsp;Total {totalHolders} Holders found.
            </p>
            <div className="table-responsive mb-2 mb-md-0">
                <table className="table table-hover">
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Rank</th>
                            <th scope="col">Address</th>
                            <th scope="col"> Quantity </th>
                            <th scope="col">Percentage </th>
                        </tr>
                    </thead>
                    <tbody>
                        {tokenHldSpinner ? (
                            <>
                                <tr>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                    </td>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                    </td>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>

                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                    </td>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                    </td>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>

                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                    </td>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                    </td>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>

                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                    </td>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                    </td>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>

                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                    </td>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                    </td>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>

                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                    </td>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                    </td>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>

                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                    </td>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                    </td>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>

                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                    </td>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                    </td>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>

                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                    </td>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                    </td>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>

                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                                    </td>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                    </td>
                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>

                                    <td>
                                        {" "}
                                        <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                    </td>
                                </tr>
                            </>
                        ) : (
                            <>
                                {tokenHolders && tokenHolders?.length ? (
                                    tokenHolders.map((item, index) => {
                                        return (
                                            <tr>
                                                <td>{item.rank}</td>
                                                <td>
                                                    <span className="hash-tag">
                                                        {item?.nameTag?.nameTag ? (
                                                            <span
                                                                className="u-label u-label--value u-label--secondary text-dark my-n1"
                                                                data-toggle="tooltip"
                                                                aria-describedby="tooltip952905"
                                                            >
                                                                <Link to={`/address/${item.address}`} title={item.address} className="text-blue">
                                                                    {item?.nameTag?.nameTag}
                                                                </Link>
                                                            </span>
                                                        ) : (
                                                            <Link to={`/address/${item.address}`} title={item.address} className="text-blue">
                                                                {item.address}
                                                            </Link>
                                                        )}
                                                    </span>
                                                </td>
                                                <td>{item.quantityNumber.toFixed(5)}</td>
                                                <td>
                                                    {item.percentage} <span className="small text-secondary">({item.percentage} &nbsp; %)</span>
                                                    <div className="progress mt-1" style={{ height: "2px" }}>
                                                        <div
                                                            className="progress-bar bg-primary"
                                                            role="progressbar"
                                                            style={{ width: `${item.percentage}%` }}
                                                            aria-valuenow={item.percentage}
                                                            aria-valuemin={0}
                                                            aria-valuemax={100}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <NoEntryMessage message={process.env.REACT_APP_BLANK_DATA_MESSAGE} />
                                )}
                            </>
                        )}
                    </tbody>
                </table>
                {tokenHolders && tokenHolders?.length ? (
                    <div class="row w-100 mt-3">
                        <div class="col-md-6 mt-3">
                            <Pagesize setPageSize={setPageSize} PageSize={PageSize} />
                        </div>
                        <div class="col-md-6 mt-3">
                            <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPage={hldPages} />
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export default Erc721Holders;
