import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import Spinner from "../../common/Spinner";
import web3 from "web3";
import { Link } from "react-router-dom";

const ReadFromContract = ({ id }) => {
    const [readData, setReadData] = useState([]);
    const [spinner, setSpinner] = useState(true);
    const [inputValues, setInputValues] = useState([]);
    const [manageReadData, setManageReadData] = useState([]);

    const getReadContractData = async () => {
        setSpinner(true);
        await axios
            .get(`${process.env.REACT_APP_BACKEND_URL}contracts/${id}/read`)
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getReadContractData : ", res.data.error);
                } else {
                    setSpinner(false);
                    setReadData(res.data);
                    let arr = [];
                    let managereaddata = [];
                    for (let i = 0; i < res?.data?.length; i++) {
                        for (let j = 0; j < res.data[i]?.inputs.length; j++) {
                            arr.push({ mainkey: i, subkey: j, name: "" });
                        }
                        managereaddata.push({ errid: i, error: "", spinner: false, result: "" });
                    }
                    setInputValues([...arr]);
                    setManageReadData([...managereaddata]);
                }
            })
            .catch((err) => {
                console.log("error in getReadContractData (catch) : ", err);
            });
    };

    useEffect(() => {
        getReadContractData();
    }, []);

    let readHandleChange = (mkey, skey, targetval) => {
        let { value, name } = targetval;
        let newReadFormValues = [...inputValues];
        let find = newReadFormValues.find((item) => item.mainkey === mkey && item.subkey === skey);
        find.name = value;
        setInputValues([...newReadFormValues]);
    };

    const ReadContractInput = async (funcname, params, signature, index) => {
        let manreaddata = manageReadData;
        await axios
            .post(`${process.env.REACT_APP_BACKEND_URL}contracts/${id}/call`, {
                functionName: funcname,
                signature: signature,
                strParams: params,
            })
            .then((res) => {
                if (res.data[0].name === "Error") {
                    manreaddata[index].error = res.data[0].value;
                    manreaddata[index].spinner = false;
                    setManageReadData([...manreaddata]);
                    return false;
                } else {
                    manreaddata[index].result = res.data;
                    manreaddata[index].spinner = false;
                    setManageReadData([...manreaddata]);
                    return false;
                }
            });
    };

    const readHandleSubmit = async (mkey, name, signature) => {
        let manReadData = [...manageReadData];
        manReadData[mkey].result = "";
        manReadData[mkey].error = "";
        manReadData[mkey].spinner = true;
        let paramLength = inputValues?.filter((item) => item.mainkey === mkey)?.length;
        let paramsarr = [];
        let errtemp = 0;
        for (let i = 0; i < paramLength; i++) {
            let val = inputValues?.find((item) => item.mainkey === mkey && item.subkey === i);
            if (val.name === "") {
                errtemp = 1;
            } else {
                manReadData[mkey].error = "";
                try {
                    let prm = JSON.parse(val.name);
                    if (Array.isArray(prm)) {
                        paramsarr.push(prm);
                    } else {
                        paramsarr.push(val.name);
                    }
                } catch (err) {
                    paramsarr.push(val.name);
                }
            }
            if (errtemp === 1) {
                manReadData[mkey].error = "Provide Valid value!";
            } else {
                manReadData[mkey].error = "";
            }
        }

        if (paramLength === paramsarr.length) {
            try {
                ReadContractInput(name, paramsarr, signature, mkey);
                setManageReadData([...manReadData]);
            } catch (error) {
                manReadData[mkey].error = error.message;
                setManageReadData([...manReadData]);
            }
        } else {
            manReadData[mkey].spinner = false;
            manReadData[mkey].error = "Provide Valid value!";
            setManageReadData([...manReadData]);
        }
        setManageReadData([...manReadData]);
    };

    return spinner ? (
        <Spinner />
    ) : (
        <div className="read-contract-container">
            <div className="d-flex justify-content-between mb-3">
                <p className="ml-1 mr-3 mb-1">
                    <i className="far fa-file-alt text-secondary mr-1" /> Read Contract Information
                </p>{" "}
                {/* <span>
                    <a href="#" className="mr-1 expandCollapseAllButton" onclick="expandCollapseAll()">
                        [Expand all]
                    </a>
                    <a href="/readcontract?m=normal&a=0x3e4ca7899426af93e2216d3d1610d13dab6a4b9e&v=0x5d666f215a85b87cb042d59662a7ecd2c8cc44e6">
                        [Reset]
                    </a>
                </span> */}
            </div>
            {readData && readData?.length
                ? readData?.map((item, mainind) => {
                      let ind = mainind + 1;
                      return (
                          <div className="tab-pane active mb-3" id="readContract" style={{ display: "" }}>
                              <div
                                  className="table-responsive"
                                  style={{
                                      visibility: "visible",
                                      border: "1px solid rgba(0,0,0,.125)",
                                      borderRadius: "calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0",
                                  }}
                              >
                                  <div id="basicsAccordion">
                                      <div className="card-header card-collapse" id="basicsHeadingThree">
                                          <h5 className="mb-0">
                                              <button
                                                  type="button"
                                                  className="btn btn-link btn-block d-flex justify-content-between card-btn collapsed py-2 px-2"
                                                  data-toggle="collapse"
                                                  data-target={`#readAcordianId${ind}`}
                                                  style={{ backgroundColor: "#f8f9fa" }}
                                              >
                                                  <span>
                                                      {ind}. {item.name}
                                                  </span>
                                                  <span className="card-btn-arrow">
                                                      <span className="fas fa-arrow-down small" />
                                                  </span>
                                              </button>
                                          </h5>
                                      </div>
                                      <div
                                          id={`readAcordianId${ind}`}
                                          className="collapse"
                                          aria-labelledby={`readAcordianId${ind}`}
                                          data-parent="#basicsAccordion"
                                      >
                                          <div className="card-body p-3">
                                              <form>
                                                  {item.inputs.length ? (
                                                      <>
                                                          <div className="form-group">
                                                              {item.inputs.map((inputItem, subind) => {
                                                                  let fieldval = inputValues.find(
                                                                      (inpval) => inpval.mainkey === mainind && inpval.subkey === subind
                                                                  ).name;
                                                                  return (
                                                                      <div className="form-group">
                                                                          <label>
                                                                              {inputItem.name} ({inputItem.type})
                                                                          </label>
                                                                          <input
                                                                              type="text"
                                                                              className="form-control form-control-xs"
                                                                              name="input_1"
                                                                              value={fieldval}
                                                                              id="input_1_1"
                                                                              data-grp="g1"
                                                                              data-type="address"
                                                                              placeholder={` ${inputItem.name} (${inputItem.type})`}
                                                                              onChange={(e) => {
                                                                                  readHandleChange(mainind, subind, e.target);
                                                                              }}
                                                                          />
                                                                      </div>
                                                                  );
                                                              })}
                                                              <button
                                                                  type="button"
                                                                  id="btn_1"
                                                                  className="btn btn-primary btn-xs light badge-light"
                                                                  onClick={() => readHandleSubmit(mainind, item.name, item.signature)}
                                                                  disabled={manageReadData && manageReadData[mainind]?.spinner ? true : false}
                                                              >
                                                                  <span className="text-white">Query</span>
                                                                  {manageReadData && manageReadData[mainind]?.spinner ? (
                                                                      <span>
                                                                          {" "}
                                                                          <div
                                                                              className="spinner-border spinner-border-sm mx-2 "
                                                                              role="status"
                                                                          ></div>{" "}
                                                                      </span>
                                                                  ) : (
                                                                      ""
                                                                  )}
                                                              </button>
                                                          </div>
                                                          <div className="text-secondary mt-3">
                                                              <img src="/asset/images/shape-1.svg" className="mt-n1" width={8} />{" "}
                                                              <i>
                                                                  <span className="text-monospace text-secondary">uint256</span>
                                                              </i>{" "}
                                                              <span id="myanswer_1">
                                                                  {manageReadData && manageReadData[mainind]?.error ? (
                                                                      <span className="text-danger">{manageReadData[mainind]?.error}</span>
                                                                  ) : (
                                                                      ""
                                                                  )}
                                                              </span>
                                                              {manageReadData.length !== 0 && manageReadData[mainind].result !== "" ? (
                                                                  <span id="myanswer_1">
                                                                      {/* <br />
                                                                      <br /> [&nbsp;<b>balanceOf</b> method Response ]<br />
                                                                      &nbsp;
                                                                       */}
                                                                      <br />
                                                                      <br />
                                                                      {"["}
                                                                      <strong>{item.name + " "}</strong>
                                                                      <span className="text-secondary">method Response</span>
                                                                      {" ]"}
                                                                      <br />
                                                                      {manageReadData[mainind].result.map((itemres, index) => {
                                                                          return (
                                                                              <>
                                                                                  <span className="text-success">
                                                                                      <i className="fa  fa-angle-double-right" />
                                                                                  </span>{" "}
                                                                                  <strong /> &nbsp;
                                                                                  <span className="text-secondary">
                                                                                      <i>{itemres.type}</i>
                                                                                  </span>{" "}
                                                                                  <b>:</b> &nbsp;
                                                                                  {Array.isArray(itemres.type) ? (
                                                                                      web3.utils.isAddress(itemres.value[0]) ? (
                                                                                          <>
                                                                                              [
                                                                                              {itemres.value.map((addItem, addIndex) => {
                                                                                                  return (
                                                                                                      <Link to={`/address/${addItem}`}>{`${addItem}${
                                                                                                          addIndex < itemres.value.length - 1
                                                                                                              ? ", "
                                                                                                              : ""
                                                                                                      } `}</Link>
                                                                                                  );
                                                                                              })}
                                                                                              ]
                                                                                          </>
                                                                                      ) : itemres.type.includes("uint") ? (
                                                                                          <>
                                                                                              [
                                                                                              {itemres.value.map((weiItem, addIndex) => {
                                                                                                  return Number(weiItem) > 0 ? (
                                                                                                      <a
                                                                                                          href={`/unitconverter?wei=${weiItem}`}
                                                                                                      >{`${weiItem}${
                                                                                                          addIndex < itemres.value.length - 1
                                                                                                              ? ", "
                                                                                                              : ""
                                                                                                      } `}</a>
                                                                                                  ) : (
                                                                                                      <>
                                                                                                          [
                                                                                                          {`${weiItem}${
                                                                                                              addIndex < itemres.value.length - 1
                                                                                                                  ? ", "
                                                                                                                  : ""
                                                                                                          } `}
                                                                                                          ]
                                                                                                      </>
                                                                                                  );
                                                                                              })}
                                                                                              ]
                                                                                          </>
                                                                                      ) : (
                                                                                          <>
                                                                                              [
                                                                                              {itemres.value.map((normItem, addIndex) => {
                                                                                                  return { normItem };
                                                                                              })}
                                                                                              ]
                                                                                          </>
                                                                                      )
                                                                                  ) : web3.utils.isAddress(itemres.value) ? (
                                                                                      <a href={`/address/${itemres.value}`}>{itemres.value}</a>
                                                                                  ) : itemres.type.includes("uint") ? (
                                                                                      Number(itemres.value) > 0 ? (
                                                                                          <a href={`/unitconverter?wei=${itemres.value}`}>
                                                                                              {itemres.value}
                                                                                          </a>
                                                                                      ) : (
                                                                                          itemres.value
                                                                                      )
                                                                                  ) : (
                                                                                      itemres.value
                                                                                  )}
                                                                                  <br />
                                                                              </>
                                                                          );
                                                                      })}
                                                                      <br />
                                                                  </span>
                                                              ) : (
                                                                  ""
                                                              )}
                                                          </div>
                                                      </>
                                                  ) : (
                                                      <div className="form-group">
                                                          {item?.outputs?.map((itemout) => {
                                                              return (
                                                                  <>
                                                                      {Array.isArray(item?.result) ? (
                                                                          web3.utils.isAddress(item?.result[0]) ? (
                                                                              item?.result?.map((addItem, addIndex) => {
                                                                                  return (
                                                                                      <>
                                                                                          [
                                                                                          <a href={`/address/${addItem}`}>{`${addItem}${
                                                                                              addIndex < item?.result.length - 1 ? ", " : ""
                                                                                          } `}</a>
                                                                                          ]
                                                                                      </>
                                                                                  );
                                                                              })
                                                                          ) : itemout?.type.includes("uint") ? (
                                                                              item?.result.map((weiItem, weiIndex) => {
                                                                                  return Number(weiItem) > 0 ? (
                                                                                      <>
                                                                                          [
                                                                                          <a href={`/unitconverter?wei=${weiItem}`}>{`${weiItem}${
                                                                                              weiIndex < item?.result.length - 1 ? ", " : ""
                                                                                          } `}</a>
                                                                                          ]
                                                                                      </>
                                                                                  ) : (
                                                                                      <>
                                                                                          [
                                                                                          {`${weiItem}${
                                                                                              weiIndex < item?.result.length - 1 ? ", " : ""
                                                                                          } `}
                                                                                          ]
                                                                                      </>
                                                                                  );
                                                                              })
                                                                          ) : (
                                                                              item?.result.map((weiItem, weiIndex) => {
                                                                                  return (
                                                                                      <>
                                                                                          [
                                                                                          {`${weiItem}${
                                                                                              weiIndex < item?.result.length - 1 ? ", " : ""
                                                                                          } `}
                                                                                          ]
                                                                                      </>
                                                                                  );
                                                                              })
                                                                          )
                                                                      ) : web3.utils.isAddress(item?.result) ? (
                                                                          <a href={`/address/${item?.result}`}>{item?.result}</a>
                                                                      ) : itemout?.type.includes("uint") ? (
                                                                          Number(item?.result) > 0 ? (
                                                                              <a href={`/unitconverter?wei=${item?.result}`}>{item?.result}</a>
                                                                          ) : (
                                                                              item?.result
                                                                          )
                                                                      ) : (
                                                                          item?.result
                                                                      )}{" "}
                                                                      <i>
                                                                          <span className="text-monospace text-secondary">{itemout.type}</span>
                                                                      </i>
                                                                      <br />
                                                                  </>
                                                              );
                                                          })}
                                                      </div>
                                                  )}
                                              </form>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      );
                  })
                : ""}
        </div>
    );
};

export default ReadFromContract;
