import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header2 from "../common/Header2";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import Footer from "../common/Footer";
import CryptoJS from "crypto-js";
import { setLocalStorage, getLocalStorage } from "../../utils";

const Login = () => {
    document.title = `${process.env.REACT_APP_NAME}Scan Login Page`;

    const navigate = useNavigate();

    const [username, setUsername] = useState("");
    const [usernameError, setusernameError] = useState(null);
    const [password, setPassword] = useState("");
    const [passwordError, setpasswordError] = useState(null);

    const [serverErr, setServerErr] = useState("");

    const [reCaptchaToken, setReCaptchaToken] = useState(false);
    const [reCaptchaTokenErr, setReCaptchaTokenErr] = useState("");

    const userLogin = async () => {
        let temp = 1;
        if (username === "") {
            setusernameError("Username is required.");
            temp = 0;
        } else {
            setusernameError("");
        }
        if (password === "") {
            setpasswordError("Your password is invalid. Please try again.");
            temp = 0;
        } else {
            setpasswordError("");
        }
        if (!reCaptchaToken) {
            setReCaptchaTokenErr("Invalid captcha.");
            temp = 0;
        } else {
            setReCaptchaTokenErr("");
        }
        if (temp) {
            const encBody = CryptoJS.AES.encrypt(
                JSON.stringify({
                    username: username.trim(),
                    password: password.trim(),
                    timestemp: Math.round(Date.now() / 1000),
                }),
                process.env.REACT_APP_PASSWORD
            ).toString();
            await axios
                .post(`${process.env.REACT_APP_BACKEND_URL}user/signin`, {
                    token: encBody,
                })
                .then((res) => {
                    if (res.data.error) {
                        setServerErr(res.data.error);
                    } else {
                        setServerErr("");
                        setUsername("");
                        setPassword("");
                        navigate("");
                        setLocalStorage("ua5s21san", CryptoJS.AES.encrypt(res.data.username, process.env.REACT_APP_PASSWORD).toString());
                        setLocalStorage("ea4sd4fam", CryptoJS.AES.encrypt(res.data.email, process.env.REACT_APP_PASSWORD).toString());
                        setLocalStorage("pn4sec3f1s", CryptoJS.AES.encrypt(password.trim(), process.env.REACT_APP_PASSWORD).toString());
                        setLocalStorage("ls5sdf4al", CryptoJS.AES.encrypt(res.data.loginTime, process.env.REACT_APP_PASSWORD).toString());
                        navigate("/myaccount");
                    }
                })
                .catch((err) => {
                    console.log("error in userLogin (cathc) : ", err);
                });
        }
    };

    const handleUsername = (val) => {
        setUsername(val);
        if (val === "") {
            setusernameError("Username is required.");
        } else {
            setusernameError("");
        }
    };

    const handlePassword = (val) => {
        setPassword(val);
        if (val === "") {
            setpasswordError("Your password is invalid. Please try again.");
        } else {
            setpasswordError("");
        }
    };

    const handleReCaptcha = async (checkToken) => {
        await axios
            .post(`${process.env.REACT_APP_BACKEND_URL}captcha`, {
                token: checkToken,
                skey: process.env.REACT_APP_CAPTCHA_SECRET_KEY,
            })
            .then((res) => {
                if (!res.data.success) {
                    setReCaptchaToken(false);
                    setReCaptchaTokenErr("Invalid captcha.");
                } else {
                    setReCaptchaToken(res.data.success);
                    setReCaptchaTokenErr("");
                }
            })
            .catch((err) => {
                console.log("error in handleReCaptcha (catch) : ", err);
            });
    };

    return (
        <>
            <Header2 />

            <main id="content" role="main" style={{ minHeight: "760px" }}>
                <div className="container space-2">
                    <form id="form1" className="js-validate w-md-75 w-lg-50 mx-md-auto" noValidate="novalidate">
                        <div className="mb-4">
                            <h2 className="h3 text-primary font-weight-normal mb-0">
                                Welcome <span className="font-weight-semi-bold">back</span>
                            </h2>
                            <p>Login to your account</p>
                        </div>
                        {serverErr ? <div className="alert alert-danger">{serverErr}</div> : ""}
                        <div className="js-form-message form-group u-has-error,js-form-message form-group">
                            <label className="d-block" htmlFor="txtUserName">
                                Username
                            </label>
                            <input
                                type="text"
                                value={username}
                                tabIndex={1}
                                maxLength={50}
                                className={
                                    usernameError === null
                                        ? "form-control form-control-sm"
                                        : usernameError === ""
                                        ? "form-control form-control-sm success"
                                        : "form-control form-control-sm error"
                                }
                                placeholder="Username"
                                onChange={(e) => {
                                    handleUsername(e.target.value);
                                }}
                            />
                            {usernameError && (
                                <div id="ContentPlaceHolder1_txtUserName-error" class="invalid-feedback" style={{ display: "Block" }}>
                                    {usernameError}
                                </div>
                            )}
                        </div>

                        <div className="js-form-message form-group u-has-error,js-form-message form-group">
                            <label className="d-block" htmlFor="txtPassword">
                                <span className="d-flex justify-content-between align-items-center">
                                    Password
                                    <a className="link-muted font-size-1" href="/loastpassword" tabIndex={0}>
                                        Forgot your password?
                                    </a>
                                </span>
                            </label>
                            <input
                                type="password"
                                value={password}
                                maxLength={75}
                                tabIndex={2}
                                className={
                                    passwordError === null
                                        ? "form-control form-control-sm"
                                        : passwordError === ""
                                        ? "form-control form-control-sm success"
                                        : "form-control form-control-sm error"
                                }
                                placeholder="Password"
                                onChange={(e) => handlePassword(e.target.value)}
                            />
                            {passwordError && (
                                <div id="ContentPlaceHolder1_txtUserName-error" class="invalid-feedback" style={{ display: "Block" }}>
                                    {passwordError}
                                </div>
                            )}
                        </div>
                        <div className="row form-group text-center justify-content-center">
                            <div className="d-flex flex-column justify-content-center my-2 align-items-center">
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                                    onChange={(getmyToken) => {
                                        handleReCaptcha(getmyToken);
                                    }}
                                    onExpired={() => {
                                        setReCaptchaToken(false);
                                    }}
                                    tabIndex={3}
                                />
                                {reCaptchaTokenErr && (
                                    <div
                                        id="ContentPlaceHolder1_txtUserName-error"
                                        className="invalid-feedback f- text-center "
                                        style={{ display: "Block", fontSize: "14px" }}
                                    >
                                        {reCaptchaTokenErr}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="js-form-message">
                            <div className="custom-control custom-checkbox d-flex align-items-center text-muted">
                                <input name type="checkbox" id="chkRemember" className="custom-control-input" tabIndex={4} />
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-5 col-sm-6">
                                <span className="text-muted text-nowrap">Don't have an account? </span>
                                <a href="/register" className="text-nowrap">
                                    Click to sign up
                                </a>
                            </div>
                        </div>
                        <div className="text-center pt-2">
                            <input type="button" defaultValue="LOGIN" className="btn btn-sm btn-primary" onClick={userLogin} />
                        </div>
                    </form>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default Login;
