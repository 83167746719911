import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Blockchain from "./HeaderComponents/BlockChain";
import Home from "./HeaderComponents/Home";
import Logo from "./HeaderComponents/logo";
import Misc from "./HeaderComponents/Misc";
import Mode from "./HeaderComponents/Mode";
import Resources from "./HeaderComponents/Resources";
import Tokens from "./HeaderComponents/Tokens";
import SignIn from "./HeaderComponents/UserProfiler";
import Validators from "./HeaderComponents/Validators";
import { useNavigate } from "react-router-dom";
import { IgniteContext } from "../IgniteContext";
import axios from "axios";
import NFTs from "./HeaderComponents/NFTs";
import Developers from "./HeaderComponents/Developers";

const Header2 = () => {
  const history = useNavigate();
  const { isNumber, isAddress, settingData, gas } = useContext(IgniteContext);
  const [Search, setsearch] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [dispDropDown, setDispDropDown] = useState(false);

  const ref = useRef();
  const btnref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        dispDropDown &&
        ref.current &&
        !ref.current.contains(e.target) &&
        btnref.current &&
        !btnref.current.contains(e.target)
      ) {
        setDispDropDown(false);
      }
    };

    document.addEventListener("mouseup", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mouseup", checkIfClickedOutside);
    };
  }, [dispDropDown]);

  const searchToken = async (val) => {
    if (val !== "") {
      await axios
        .get(`${process.env.REACT_APP_BACKEND_URL}tokens/search?option=${val}`)
        .then((res) => {
          if (res.data.error) {
            // console.log("error in getallblocks : ", res.data.error);
          } else {
            setSearchData(res.data);
            if (res.data.length) {
              setDispDropDown(true);
            } else {
              setDispDropDown(false);
            }
          }
        })
        .catch((err) => {
          // console.log("error in getallblocks (catch) : ", err);
        });
    } else {
      setSearchData([]);
      setDispDropDown(false);
    }
  };

  async function handlesubmit(e) {
    e.preventDefault();
    var a = isNumber(Search.trim());
    if (Search.trim().length === 64 || Search.trim().length === 66) {
      setsearch("");
      history(`/tx/${Search.trim()}`);
      // window.location.reload(false);
      return false;
    } else if (Search.trim().length === 42) {
      let valid = isAddress(Search.trim());
      if (Search.trim() && valid) {
        setsearch("");
        history(`/address/${Search.trim().toLowerCase()}`);
        // window.location.reload(false);
        return false;
      } else {
        history("/error");
        return false;
      }
    } else if (Search.trim().length > 0 && a === true) {
      setsearch("");
      history(`/block/${parseInt(Search, 10)}`);
      // window.location.reload(false);
      return false;
    } else if (Search.length === 0) {
      return false;
    } else {
      history("/error");
      return false;
    }
  }

  const handleSearch = (val) => {
    setsearch(val);
    searchToken(val);
  };

  return (
    <>
      {settingData && settingData?.maintnanceMessage && settingData?.maintnanceStatus ? (
        <div
          style={{
            backgroundColor: `#${
              settingData && settingData?.maintnanceBgColor ? settingData?.maintnanceBgColor : "0d6efd"
            }`,
          }}
        >
          <div class="text-center m-0">
            {" "}
            <p className="text-white m-0">{settingData?.maintnanceMessage}</p>
          </div>
        </div>
      ) : (
        ""
      )}
      <header id="header" className="u-header">
        <div className="u-header__section">
          <div id="logoAndNav" className="container">
            <nav className="js-mega-menu navbar navbar-expand-md u-header__navbar u-header__navbar--no-space">
              <div className="w-lg-auto">
                <div id="ethPrice">
                  <div className="d-none d-md-inline-block u-label u-label--price rounded mx-1 mb-1 text-nowrap">
                    <span className="text-dark">{process.env.REACT_APP_TICKER}:</span>
                    <span className="text-primary">${process.env.REACT_APP_PRICE} (+0.0%)</span>,{" "}
                    <span className="text-dark">Gas: </span>
                    <span className="text-primary">{Number(gas).toFixed(2)} Gwei</span>
                  </div>
                </div>
                <Link className="navbar-brand pt-md-0" to="/" target="_parent">
                  <Logo />
                </Link>
              </div>
              <div>
                <button
                  type="button"
                  className="navbar-toggler btn u-hamburger"
                  aria-label="Toggle navigation"
                  aria-expanded="false"
                  aria-controls="navBar"
                  data-toggle="collapse"
                  data-target="#navBar"
                >
                  <span id="hamburgerTrigger" className="u-hamburger__box">
                    <span className="u-hamburger__inner" />
                  </span>
                </button>
              </div>
              <div className="d-flex flex-column w-100">
                <div id="navBar" className="collapse navbar-collapse u-header__navbar-collapse order-md-2">
                  <ul className="navbar-nav u-header__navbar-nav px-2 px-md-0">
                    <Home />
                    <Blockchain />
                    <Validators />
                    <Tokens />
                    <NFTs />
                    <Developers />
                    <Resources />
                    {/* <Misc /> */}
                    {process.env.REACT_APP_LOGIN === "true" || process.env.REACT_APP_LOGIN === true ? (
                      <SignIn />
                    ) : (
                      ""
                    )}
                    <Mode />
                  </ul>
                </div>
                <div id="form1">
                  <form
                    className="w-100 w-lg-55 order-md-1 ml-md-auto mt-2 mb-2 mb-md-0"
                    autoComplete="off"
                    spellCheck="false"
                    onSubmit={(e) => handlesubmit(e)}
                  >
                    <div className="input-group input-group-sm position-relative">
                      <input
                        type="text"
                        className="form-control searchautocomplete"
                        id="txtSearchInput"
                        placeholder="Search by Address / Txn Hash / Block / Token"
                        aria-label="Search by Address / Txn Hash / Block / Token"
                        aria-describedby="button-header-search"
                        ref={btnref}
                        onChange={(e) => handleSearch(e.target.value)}
                      />
                      <div
                        className={`dropdown-menu d-block m-1 p-0 ${dispDropDown ? "" : "hide_div"}`}
                        style={{ width: "100%", maxHeight: "300px", overflowY: "auto" }}
                        ref={ref}
                      >
                        <div className="search-history">
                          <ul
                            id="ui-id-1"
                            className="ui-menu ui-widget ui-widget-content ui-autocomplete ui-front list-unstyled bg-white-content shadow p-2 mb-0"
                          >
                            {searchData && searchData?.length
                              ? searchData?.map((item, index) => {
                                  return (
                                    <li className="mb-2 ui-menu-item" key={index}>
                                      <Link
                                        className="ether-search media rounded p-2 ui-menu-item-wrapper"
                                        to={`/token/${item.address}`}
                                      >
                                        <div className="mr-2">
                                          <img
                                            className="u-xs-avatar mr-2"
                                            src={
                                              item &&
                                              (item?.status === "true" || item?.status === true) &&
                                              item?.logo
                                                ? process.env.REACT_APP_TOKEN_LOGO_PATH + item?.logo
                                                : "/asset/images/48.png"
                                            }
                                          />
                                        </div>
                                        <div className="media-body text-truncate">
                                          <h6 className="d-flex align-items-center text-size-1 mb-0">
                                            <div className="text-truncate mr-2">
                                              {item.name} ({item.symbol})
                                            </div>
                                            <span className="badge bg-soft-secondary mr-2">$0.00</span>
                                          </h6>
                                          <span className="text-secondary text-truncate font-size-1">
                                            {item.address}
                                          </span>
                                          <div className="text-secondary font-size-1 text-truncate">
                                            https://multichain.org/
                                          </div>
                                        </div>
                                      </Link>
                                    </li>
                                  );
                                })
                              : ""}
                          </ul>
                        </div>
                      </div>
                      <br />
                      <br />
                      {/* <div className={ "dropdown-menu custom-drop-non" : "dropdown-menu custom-drop"}> */}

                      <div className="input-group-append" ref={btnref}>
                        <button className="btn btn-primary  input_search_box" id="search" type="submit">
                          <span className="fas fa-search" />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header2;
