export const formatAmount = (amount) => {
  if(Number(amount) <= 0) {
      return 0
  } else if(Number(amount) >= 0.00001 && Number(amount) < 0.0001) {
      return amount.toFixed(6)
  } else if(Number(amount) >= 0.0001 && Number(amount) < 0.001) {
      return amount.toFixed(6)
  } else if(Number(amount) >= 0.001 && Number(amount) < 0.01) {
      return amount.toFixed(5)
  } else if(Number(amount) >= 0.01 && Number(amount) < 0.1) {
      return amount.toFixed(4)
  } else if(Number(amount) >= 0.1 && Number(amount) < 1) {
      return amount.toFixed(3)
  } else if(Number(amount) >= 1 && Number(amount) < 100) {
      return amount.toFixed(2)
  } else if(Number(amount) >= 100 && Number(amount) < 1000) {
      return amount.toFixed(1)
  } else if(Number(amount) >= 1000) {
      return amount.toFixed(0)
  }
}

export const formatAmountFromWei = (amount, decimals = 18) => {
  amount = Number(amount) / (10**Number(decimals))
  if(Number(amount) <= 0) {
      return 0
  } else if(Number(amount) >= 0.00001 && Number(amount) < 0.0001) {
      return amount.toFixed(6)
  } else if(Number(amount) >= 0.0001 && Number(amount) < 0.001) {
      return amount.toFixed(6)
  } else if(Number(amount) >= 0.001 && Number(amount) < 0.01) {
      return amount.toFixed(5)
  } else if(Number(amount) >= 0.01 && Number(amount) < 0.1) {
      return amount.toFixed(4)
  } else if(Number(amount) >= 0.1 && Number(amount) < 1) {
      return amount.toFixed(3)
  } else if(Number(amount) >= 1 && Number(amount) < 100) {
      return amount.toFixed(2)
  } else if(Number(amount) >= 100 && Number(amount) < 1000) {
      return amount.toFixed(1)
  } else if(Number(amount) >= 1000) {
      return amount.toFixed(0)
  }
}