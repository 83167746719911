import React, { useState, useEffect } from "react";
import Header2 from "../common/Header2";
import CopyToClipboard from "react-copy-to-clipboard";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";
import delay from "delay";
import axios from "axios";
import Web3 from "web3";
import { getLocalStorage } from "../../utils";
const web3 = new Web3(process.env.REACT_APP_HTTP_RPC);
const Buffer = window.Buffer || require("buffer").Buffer;

const nowDate = new Date();
const nowTime = nowDate.toLocaleTimeString("en-IN", { hour12: false });

function VerifyAddressOwnership2() {
  let { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  if (location?.state === null || !id || location?.state?.id !== id) {
    navigate(-1);
  }

  const [contractDetails, setContractDetails] = useState(null);

  const [manuallyVerify, setManuallyVerify] = useState(false);
  const [error, setError] = useState();

  const [copyAddress, setCopyAddress] = useState("");

  const [mainError, setMainError] = useState("");

  const [contractaddress, setContractAddress] = useState(id ? id : "");
  const [contractaddressError, setContractaddressError] = useState(null);

  const [account, setAccount] = useState("");

  const [signature, setSignature] = useState("");
  const [signatureError, setSignatureError] = useState(null);

  const [manualSignMessage, setManualSignMessage] = useState("");
  const [manualSignMessageError, setManualSignMessageError] = useState(null);

  const defaultMessage = `[${
    process.env.REACT_APP_NAME
  }Scan.com ${nowDate.toLocaleDateString()} ${nowTime}] I, here by verify that i am the the ownwer/creatore of the address [${id}]`;

  const isLogin =
    getLocalStorage("ua5s21san") && getLocalStorage("ea4sd4fam") && getLocalStorage("pn4sec3f1s")
      ? true
      : false;

  useEffect(() => {
    const redirectState1 = JSON.parse(sessionStorage.getItem("a1BDc45Adfad"));
    const redirectState = JSON.parse(sessionStorage.getItem("a1BDc45Adfa"));
    if (!redirectState) {
      navigate(`/token/${id}`);
    }
  }, []);

  const verifyLogin = async () => {
    if (isLogin) {
      const username = CryptoJS.AES.decrypt(
        getLocalStorage("ua5s21san"),
        process.env.REACT_APP_PASSWORD
      ).toString(CryptoJS.enc.Utf8);
      const password = CryptoJS.AES.decrypt(
        getLocalStorage("pn4sec3f1s"),
        process.env.REACT_APP_PASSWORD
      ).toString(CryptoJS.enc.Utf8);
      const encBody = CryptoJS.AES.encrypt(
        JSON.stringify({
          username: username.trim(),
          password: password.trim(),
          timestemp: Math.round(Date.now() / 1000),
        }),
        process.env.REACT_APP_PASSWORD
      ).toString();
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}user/signin`, {
          token: encBody,
        })
        .then((res) => {
          if (res.data.error) {
            window.location.assign("/login");
          } else {
            return true;
          }
        })
        .catch((err) => {
          console.log("error in userLogin (cathc) : ", err);
        });
    } else {
      window.location.assign("/login");
    }
  };
  useEffect(() => {
    verifyLogin();
  }, []);

  const getContractDetails = async () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}contract/${id}`)
      .then((res) => {
        if (res.data.error) {
          console.log("error in getContractDetails : ", res.data.error);
          return navigate(-1);
        } else {
          setContractDetails(res.data);
          setContractAddress(res.data.contractCreater);
          if (res.data.abiCode === null && res.data.sourceCode === null) {
            navigate("/");
          }
        }
      })
      .catch((err) => {
        console.log("error in getContractDetails (catch) : ", err);
        return navigate(-1);
      });
  };
  useEffect(() => {
    getContractDetails();
  }, []);

  const hanldecontractaddress = (val) => {
    setContractAddress(val);
    if (val === "") {
      setContractaddressError("Contract Ownwer/Creatore Address Required");
    } else {
      setContractaddressError("");
    }
  };

  const handleManualMessage = (val) => {
    setManualSignMessage(val);
    if (!val) {
      setManualSignMessageError("Signed message is required");
    } else {
      setManualSignMessageError("");
    }
  };

  const handleSignature = (val) => {
    setSignature(val);
    if (!val) {
      setSignatureError("Message signature hash is required");
    } else {
      setSignatureError("");
    }
  };

  const handleVerification = async () => {
    setMainError("");
    let temp = 1;
    if (!manualSignMessage) {
      setManualSignMessageError("Signed message is required");
      temp = 0;
    } else {
      setManualSignMessageError("");
    }
    if (!signature) {
      setSignatureError("Message signature hash is required");
      temp = 0;
    } else {
      setSignatureError("");
    }
    if (temp) {
      try {
        const signerAddr = await web3.eth.accounts.recover(manualSignMessage, signature);
        // console.log("contractDetails", contractDetails);
        // console.log("signerAddr", signerAddr);
        // return;

        // if (signerAddr && contractDetails?.contractCreater) {
        if (signerAddr && contractDetails) {
          if (signerAddr.toLowerCase() === "0x21040277652EcBFf911f0848990753aB742436E1".toLowerCase()) {
            //   if (signerAddr.toLowerCase() === contractDetails?.contractCreater.toLowerCase()) {
            window.location.assign(`/tokenUpdateApplication/${contractDetails?.address}`);
          } else {
            setMainError("Sorry! The Signature Message Verification Failed");
          }
        } else {
          setMainError("Sorry! The Signature Message Verification Failed");
        }
        return true;
      } catch (err) {
        console.log("error (catch)", err);
        return false;
      }
    }
  };

  const handleResetClick = (e) => {
    setManualSignMessage(defaultMessage);
    setManualSignMessageError(null);
    setSignature("");
    setSignatureError(null);
    setManuallyVerify(true);
    setMainError("");
  };

  async function copyaddress() {
    setCopyAddress("copied");
    await delay(1000);
    setCopyAddress("");
  }

  const connectToWeb3 = async () => {
    if (typeof window.ethereum === "undefined") {
      return false;
    }
    window.ethereum.request({ method: "eth_requestAccounts" });
    const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
    const account = accounts[0];
    setAccount(account);
  };

  const handleSignMessageManually = () => {
    setSignature("");
    setManualSignMessage(defaultMessage);
    setManuallyVerify(true);
  };

  const signWithWeb3 = async () => {
    const msg = `0x${Buffer.from(defaultMessage, "utf8").toString("hex")}`;
    const sign = await window.ethereum.request({
      method: "personal_sign",
      params: [msg, account, "Example password"],
    });
    setSignature(sign ? sign : "");
  };

  return (
    <>
      <Header2 />
      {manuallyVerify ? (
        <main id="content" role="main" style={{ minHeight: "880px" }}>
          <div className="container space-2 text-center">
            <div class="text-center">
              <h3>Verify Address Ownweship</h3>
              <p>
                Find out more about{" "}
                <a href="javascript:void(0);">
                  <strong>verify address ownership</strong>
                </a>
              </p>
            </div>
            <div className="verify-card card">
              {mainError ? (
                <div className="alert alert-danger mb-4 text-left" role="alert">
                  <i className="fa fa-exclamation-circle mr-1" /> {mainError}
                </div>
              ) : (
                ""
              )}
              <div className="js-form-message form-group">
                <label className="d-flex" htmlFor="txtUserName">
                  Contract Ownwer/Creatore Address
                  <span class="text-danger">*</span>
                </label>
                <input
                  name="txtusername"
                  type="text"
                  maxLength={50}
                  id="txtusername"
                  tabIndex={1}
                  value={contractaddress}
                  className={
                    contractaddressError === null
                      ? "form-control form-control-sm"
                      : contractaddressError === ""
                      ? "form-control form-control-sm success"
                      : "form-control form-control-sm error"
                  }
                  style={{ fontSize: "14px" }}
                  readOnly
                  onChange={(e) => hanldecontractaddress(e.target.value)}
                />
                {contractaddressError && (
                  <div
                    id="ContentPlaceHolder1_txtUserName-error"
                    class="invalid-feedback"
                    style={{ display: "Block" }}
                  >
                    {contractaddressError}
                  </div>
                )}
              </div>

              <div className="js-form-message form-group">
                <lable className="d-flex align-items-center mb-2" htmlFor="msgSend">
                  Signed Message<span class="text-danger">*</span>
                </lable>

                <textarea
                  type="text"
                  rows={3}
                  cols={20}
                  className={
                    manualSignMessageError === null
                      ? "form-control form-control-sm"
                      : manualSignMessageError === ""
                      ? "form-control form-control-sm success"
                      : "form-control form-control-sm error"
                  }
                  value={manualSignMessage}
                  style={{ fontSize: "14px" }}
                  onChange={(e) => handleManualMessage(e.target.value)}
                />
                {manualSignMessageError && (
                  <div
                    id="ContentPlaceHolder1_txtUserName-error"
                    class="invalid-feedback text-left"
                    style={{ display: "Block" }}
                  >
                    {manualSignMessageError}
                  </div>
                )}
              </div>

              <div className="js-form-message form-group">
                <label className="d-flex" htmlFor="txtUserName">
                  Message Signature hash<span class="text-danger">*</span>
                </label>
                <input
                  name="txtusername1"
                  type="text"
                  maxLength={150}
                  id="signature"
                  tabIndex={1}
                  value={signature}
                  className={
                    signatureError === null
                      ? "form-control form-control-sm"
                      : signatureError === ""
                      ? "form-control form-control-sm success"
                      : "form-control form-control-sm error"
                  }
                  placeholder="Enter the message signature hash"
                  onChange={(e) => handleSignature(e.target.value)}
                  style={{ fontSize: "14px" }}
                />
                {signatureError && (
                  <div
                    id="ContentPlaceHolder1_txtUserName-error"
                    className="invalid-feedback text-left"
                    style={{ display: "Block" }}
                  >
                    {signatureError}
                  </div>
                )}
              </div>

              <div className="text-right">
                <input
                  type="button"
                  defaultValue="Reset"
                  onClick={handleResetClick}
                  className="btn btn-sm btn-secondary mr-3"
                />

                <button
                  type="button"
                  onClick={handleVerification}
                  className="btn btn-sm btn-primary"
                  disabled={!contractDetails ? true : false}
                >
                  Verify Ownership
                </button>
              </div>
            </div>
          </div>
        </main>
      ) : (
        <div className="container space-2 text-center">
          <div class="text-center">
            <h3>Verify Address Ownweship</h3>
            <p>
              Find out more about{" "}
              <a href="javascript:void(0);">
                <strong>verify address ownership</strong>
              </a>
            </p>
          </div>
          <div className="verify-card card">
            <div className="js-form-message form-group">
              <label className="d-flex" htmlFor="txtUserName">
                Copy the message below and sign it using thre {process.env.REACT_APP_NAME} sign message
                provider of your choice.
              </label>
            </div>
            <div className="js-form-message form-group d-flex justify-content-start ml-3">
              <div id="ContentPlaceHolder1_ContractCreator" className="custom-control custom-radio">
                <input
                  defaultValue="0x80dc00811e7c4a03c1f1599d3dc8febaad87bf87"
                  name="ctl00$ContentPlaceHolder1$ContractOwnerCreator"
                  type="radio"
                  id="ContentPlaceHolder1_RadioButton0"
                  className="custom-control-input"
                  defaultChecked="checked"
                />
                <label className="custom-control-label" htmlFor="ContentPlaceHolder1_RadioButton0">
                  Contract Creator: {contractDetails?.contractCreater}
                </label>
              </div>
            </div>
            <div className="js-form-message form-group mb-1">
              <label className="d-block" htmlFor="txtPassword">
                <span className="d-flex justify-content-between align-items-center">
                  <span> Message to sign </span>
                  <CopyToClipboard
                    text={defaultMessage}
                    onCopy={(e) => {
                      copyaddress();
                    }}
                  >
                    <span>
                      {copyAddress === "" ? (
                        <span className="js-clipboard btn btn-sm btn-icon btn-soft-success rounded-circle">
                          <i className="fal fa-copy mt-2"></i>{" "}
                        </span>
                      ) : (
                        <span class="js-clipboard btn btn-sm btn-icon btn-soft-success rounded-circle d-flex justify-content-center align-items-center">
                          <i className="fal fa-check-circle mx-1" />
                        </span>
                      )}{" "}
                    </span>
                  </CopyToClipboard>
                </span>
              </label>
            </div>
            <div className="js-form-message form-group">
              <textarea
                name="message"
                style={{ fontSize: "14px" }}
                rows={3}
                cols={20}
                id="ContentPlaceHolder1_txtPrivateNoteArea"
                spellCheck="false"
                className="form-control"
                value={defaultMessage}
                disabled
              />
            </div>
            {error ? <div className="text-danger"> {error}</div> : ""}
            <div className="js-form-message form-group text-left mb-2">
              <p>
                Check our article on *
                <a href="javascript:void(0);">
                  <strong>How to Sign message ?</strong>
                </a>
                * if you have not done before.
              </p>
            </div>
            <div className="text-center">
              {!signature ? (
                <input
                  type="button"
                  defaultValue="Sign Message Manually"
                  onClick={handleSignMessageManually}
                  className="btn btn-sm btn-primary mr-3 mt-1"
                />
              ) : (
                <input
                  type="button"
                  defaultValue="Sign Message Manually"
                  className="btn btn-sm btn-primary mr-3 mt-1"
                  disabled
                />
              )}{" "}
              OR
              {signature ? (
                <input
                  type="button"
                  value="Click to Proceed"
                  className="btn btn-sm btn-primary ml-3 mt-1"
                  onClick={(e) => {
                    setManualSignMessage(defaultMessage);
                    setManuallyVerify(true);
                  }}
                />
              ) : account ? (
                <input
                  type="button"
                  value="Sign with Web3"
                  className="btn btn-sm btn-primary ml-3 mt-1"
                  onClick={(e) => {
                    signWithWeb3();
                  }}
                />
              ) : (
                <input
                  type="button"
                  value="Connect to Web3"
                  className="btn btn-sm btn-primary ml-3 mt-1"
                  onClick={(e) => {
                    connectToWeb3();
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default VerifyAddressOwnership2;
