import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import Spinner from "../common/Spinner";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import { IgniteContext } from "../IgniteContext";
import Web3 from "web3";
import LatestTransactionLoader from "../common/PageContentLoaders/LatestTransactionLoader";

const LatestTransaction = () => {
  let txnData2 = [];
  const { formatAddress, formatHash } = useContext(IgniteContext);
  const [txnData, setTxnData] = useState([]);
  const [foundArr, setFoundArr] = useState([]);
  const [spinner, setSpinner] = useState(true);

  const getTxnData = async () => {
    setSpinner(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}txs/home`)
      .then((res) => {
        if (res.data.error) {
          console.log("error in home transaction : ", res.data.error);
        } else {
          const disptxs = [...res.data];
          txnData2 = [...disptxs];
          setTxnData([...disptxs]);
          setSpinner(false);
        }
      })
      .catch((err) => {
        console.log("error in home transaction (catch): ", err);
      });
  };
  const getTxnData2 = async () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}txs/home`)
      .then((res) => {
        if (res.data.error) {
          console.log("error in home transaction : ", res.data.error);
        } else {
          let prevdata = [...txnData2];
          txnData2 = [...res.data];
          let arr = [];
          for (let i = 0; i < res.data.length; i++) {
            let find = prevdata.find((item) => item.hash === res.data[i].hash);
            if (!find) {
              arr.push(res.data[i].hash);
            }
          }
          setFoundArr([...arr]);
          setTxnData(res.data);
        }
      })
      .catch((err) => {
        console.log("error in home transaction (catch): ", err);
      });
  };

  useEffect(() => {
    getTxnData();
  }, []);
  useEffect(() => {
    setInterval(() => {
      getTxnData2();
    }, 1000);
  }, []);

  return (
    <>
      <div className="col-lg-6">
        <div className="card w-100">
          <div className="card-header">
            <h2 className="card-header-title text-left">Latest Transactions</h2>
          </div>
          <div className="scrollbar_area card-body example" style={{ height: "400px" }}>
            {spinner ? (
              <LatestTransactionLoader />
            ) : txnData && txnData?.length ? (
              txnData.map((item, index) => {
                const value = item.value
                  ? Web3.utils.fromWei(item.value.toLocaleString("fullwide", { useGrouping: false }))
                  : 0;
                return (
                  <>
                    <div key={index} className={`row ${foundArr.includes(item.hash) ? "fade-in-anim" : ""}`}>
                      <div className="col-sm-4">
                        <div className="media align-items-sm-center mr-4 mb-1 mb-sm-0">
                          <div className="d-none d-sm-flex mr-2">
                            <span className="btn btn-icon btn-soft-secondary rounded-circle p-2">
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 16 16"
                                height="1.5rem"
                                width="1.5rem"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5"></path>
                                <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z"></path>
                              </svg>
                            </span>
                          </div>
                          <div className="media-body text-left">
                            <span className="d-inline-block d-sm-none mr-1">TX#</span>
                            <Link
                              className="hash-tag hash-tag--xs hash-tag-xs-down--md text-truncate"
                              to={`/tx/${item.hash}`}
                            >
                              {formatHash(item.hash)}
                            </Link>
                            <span className="d-block small text-secondary">{item.age}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div className="d-flex justify-content-between">
                          <div className="text-nowrap mr-4 mb-1 mb-sm-0 text-left">
                            <span className="d-flex flex-wrap gap-1">
                              From{" "}
                              <Link className="hash-tag" to={`address/${item.from}`}>
                                {formatAddress(item.from)}
                              </Link>
                            </span>
                            <span className="d-sm-block">
                              To{" "}
                              {item.to ? (
                                <Link to={`/address/${item.to}`} className="hash-tag">
                                  {formatAddress(item.to)}
                                </Link>
                              ) : (
                                <Link to={`/address/${item.to}`} className="hash-tag">
                                  {formatAddress(item.contractAddress)}
                                </Link>
                              )}
                            </span>
                          </div>
                          <OverlayTrigger placement="top" trigger="hover" overlay={<Tooltip>Amount</Tooltip>}>
                            <div className="d-flex align-items-center">
                              <span
                                className="u-label u-label--xs u-label--badge-in u-label--secondary text-center text-nowrap"
                                data-toggle="tooltip"
                              >
                                {Number(value) !== 0
                                  ? Number(value).toFixed(5) + " " + process.env.REACT_APP_TICKER
                                  : "0" + " " + process.env.REACT_APP_TICKER}
                              </span>
                            </div>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>
                    <hr className="hr-space" />
                  </>
                );
              })
            ) : (
              <p className="text-black container" style={{ textAlign: "center", height: "200px" }}>
                No Trasaction Found
              </p>
            )}
          </div>
          <div className="card-footer">
            <Link className="btn btn-xs btn-block btn-soft-primary card_footer_background" to="/txs">
              View all transactions
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default LatestTransaction;
