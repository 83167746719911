import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Pagesize from "../common/Pagesize";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import NoEntryMessage from "../common/NoEntryMessage";
import Spinner from "../common/Spinner";
import { IgniteContext } from "../IgniteContext";

import FadingLoaderCard1 from "../common/ContentLoaders/LoaderBigLine";
import Footer from "../common/Footer";

const Accounts = () => {
  const { verifyAndGetUser } = useContext(IgniteContext);
  const { encusername } = verifyAndGetUser();
  const [searchParams] = useSearchParams();
  const cpage = searchParams.get("p");

  const { numberWithCommas } = useContext(IgniteContext);

  const history = useNavigate();

  document.title = `Top Accounts by ${process.env.REACT_APP_TICKER} Balance`;

  const [spinner, setSpinner] = useState(true);

  const [accounts, setAccounts] = useState([]);

  const [currentPage, setCurrentPage] = useState(cpage ? cpage : 1);
  const [totalAccounts, setTotalAccounts] = useState(0);
  const [pages, setPages] = useState(0);
  const [PageSize, setPageSize] = useState(10);

  const getAccounts = async () => {
    setSpinner(true);
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}accounts`, {
        params: {
          limit: PageSize,
          page: currentPage,
        },
        headers: {
          etoken: encusername,
        },
      })
      .then((res) => {
        if (res.data.error) {
          // console.log("error in getAccounts : ", res.data.error);
        } else {
          console.log("res.data.items", res.data.items);

          setAccounts(res.data.items);
          setTotalAccounts(res.data.total);
          setPages(res.data.pages);
          setSpinner(false);
        }
      })
      .catch((err) => {
        // console.log("error in getAccounts (catch) : ", err)
      });
  };

  useEffect(() => {
    getAccounts();
  }, [PageSize, currentPage]);

  const onNext = (page) => {
    if (page === pages) return false;
    setCurrentPage(currentPage + 1);
    history({
      pathname: "/accounts",
      search: "?p=" + (currentPage + 1),
    });
  };

  const onPrevious = (page) => {
    if (page === 1) return false;
    setCurrentPage(currentPage - 1);
    history({
      pathname: "/accounts",
      search: "?p=" + (currentPage - 1),
    });
  };

  function getDecimalPart(num) {
    if (Number.isInteger(num)) {
      return 0;
    }

    const decimalStr = num.toString().split(".")[1];
    return decimalStr;
  }

  return (
    <>
      <main id="content" role="main" style={{ minHeight: "800px" }}>
        <div className="container">
          <div className="d-md-flex justify-content-between align-items-center py-3">
            <div className="mb-1 mb-md-0">
              <h1 className="h4 font-weight-normal mb-0">
                Top Accounts by {process.env.REACT_APP_TICKER} Balance&nbsp;
                <span className="small text-secondary" />
              </h1>
            </div>
          </div>
        </div>
        <div className="container space-bottom-2">
          <div className="card">
            <div className="card-body">
              <div id="ContentPlaceHolder1_topPageDiv" className="d-md-flex justify-content-between mb-4">
                {totalAccounts < 10000 ? (
                  <>
                    <p className="mb-2 mb-md-0">
                      <i
                        id="spinwheel"
                        className="fa fa-spin fa-spinner fa-1x fa-pulse mr-1"
                        style={{ display: "none" }}
                      />
                      A total&nbsp;
                      {numberWithCommas(totalAccounts)} accounts found
                    </p>
                  </>
                ) : (
                  <>
                    <p className="mb-2 mb-md-0">
                      <i
                        id="spinwheel"
                        className="fa fa-spin fa-spinner fa-1x fa-pulse mr-1"
                        style={{ display: "none" }}
                      />
                      A total&nbsp;
                      {numberWithCommas(totalAccounts)} accounts found
                      <span className="d-block text-secondary small">
                        (Showing the last 10,000 top accounts only)
                      </span>
                    </p>
                  </>
                )}
              </div>
              {spinner ? (
                <div id="ContentPlaceHolder1_divTable" className="table-responsive mb-2 mb-md-0">
                  <table className="table table-hover" id="datatable" style={{ width: "100%" }}>
                    <thead className="thead-light">
                      <tr className="text-black">
                        <th>Rank</th>
                        <th width={250}>Address</th>
                        <th>Name Tag</th>
                        <th>Balance</th>
                        <th>Percentage</th>
                        <th>Txn Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />{" "}
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                        </td>
                        <td>
                          {" "}
                          <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : (
                <div id="ContentPlaceHolder1_divTable" className="table-responsive mb-2 mb-md-0">
                  <table className="table table-hover" id="datatable" style={{ width: "100%" }}>
                    <thead className="thead-light">
                      <tr className="text-black">
                        <th>Rank</th>
                        <th width={250}>Address</th>
                        <th>Name Tag</th>
                        <th>Balance</th>
                        <th>Percentage</th>
                        <th>Txn Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      {accounts && accounts?.length ? (
                        accounts.map((item) => {
                          const dec = getDecimalPart(item.balance);
                          const wholeNum = parseInt(item.balance);
                          return (
                            <tr className="row0" key={item.rank}>
                              <td className>{item.rank}</td>
                              <td className>
                                {item.isContract || item.isToken ? (
                                  <i className="far fa-file-alt text-secondary ml-1 mr-1" />
                                ) : (
                                  ""
                                )}
                                <Link className="text-blue" to={`/address/${item.address}`}>
                                  {item.address}
                                </Link>
                              </td>
                              <td className>
                                {item.publicNameTag ? (
                                  <span class="u-label u-label--secondary text-dark font-size-1 rounded py-1 px-3">
                                    <span
                                      data-toggle="tooltip"
                                      data-original-title="Public Name Tag (viewable by anyone)"
                                    >
                                      {item.publicNameTag}
                                    </span>{" "}
                                  </span>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td className>
                                {numberWithCommas(wholeNum)}
                                {dec ? (
                                  <>
                                    <strong>.</strong>
                                    <span class="text-secondary">{dec.substring(0, 8)}</span>
                                  </>
                                ) : (
                                  ""
                                )}
                                &nbsp;
                                {process.env.REACT_APP_TICKER}
                              </td>
                              <td className>{item.percentage ? item.percentage + "%" : "--"}</td>
                              <td>{numberWithCommas(item.txCount)}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <NoEntryMessage message={process.env.REACT_APP_BLANK_DATA_MESSAGE} />
                      )}
                    </tbody>
                  </table>
                  {accounts && accounts?.length ? (
                    <div class="row w-100 mt-3">
                      <div class="col-md-6 mt-3">
                        <Pagesize setPageSize={setPageSize} PageSize={PageSize} />
                      </div>
                      <div class="col-md-6 mt-3">
                        <div className="float-right mr-3">
                          <div class="row">
                            <button
                              class={currentPage === 1 ? "page-link disabled" : "page-link"}
                              onClick={() => {
                                setCurrentPage(1);
                                history({
                                  pathname: "/accounts",
                                  search: "?p=" + 1,
                                });
                              }}
                              disabled={currentPage === 1}
                            >
                              First
                            </button>
                            <button
                              class={currentPage === 1 ? "page-link disabled" : "page-link"}
                              onClick={() => onPrevious(currentPage)}
                              disabled={currentPage === 1}
                            >
                              <i className="fa fa-angle-left" />
                            </button>
                            <div class="page-link-badge" disabled>
                              {" "}
                              {`${currentPage} of ${pages}`}
                            </div>
                            <button
                              class={currentPage === pages ? "page-link disabled" : "page-link"}
                              onClick={() => onNext(currentPage)}
                              disabled={currentPage === pages}
                            >
                              <i className="fa fa-angle-right" />
                            </button>
                            <button
                              class={currentPage === pages ? "page-link disabled" : "page-link"}
                              onClick={() => {
                                setCurrentPage(pages);
                                history({
                                  pathname: "/accounts",
                                  search: "?p=" + pages,
                                });
                              }}
                              disabled={currentPage === pages}
                            >
                              Last
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Accounts;
