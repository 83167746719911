import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { IgniteContext } from "../IgniteContext";
import { Link } from "react-router-dom";
import TxChart from "../common/TxChart";

const HomeSearch = () => {
  const history = useNavigate();
  const [Search, setsearch] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [dispDropDown, setDispDropDown] = useState(false);
  const [revanimate, setRevanimate] = useState(true);

  const { isNumber, isAddress } = useContext(IgniteContext);

  const ref = useRef();
  const btnref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        dispDropDown &&
        ref.current &&
        !ref.current.contains(e.target) &&
        btnref.current &&
        !btnref.current.contains(e.target)
      ) {
        setDispDropDown(false);
      }
    };

    document.addEventListener("mouseup", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mouseup", checkIfClickedOutside);
    };
  }, [dispDropDown]);

  const searchToken = async (val) => {
    if (val !== "") {
      await axios
        .get(`${process.env.REACT_APP_BACKEND_URL}tokens/search?option=${val}`)
        .then((res) => {
          if (res.data.error) {
            console.log("error in getallblocks : ", res.data.error);
          } else {
            setSearchData(res.data);
            if (res.data.length) {
              setDispDropDown(true);
            } else {
              setDispDropDown(false);
            }
          }
        })
        .catch((err) => {
          console.log("error in getallblocks (catch) : ", err);
        });
    } else {
      setSearchData([]);
      setDispDropDown(false);
    }
  };

  async function handlesubmit(e) {
    e.preventDefault();
    var a = isNumber(Search.trim());
    if (Search.trim().length === 64 || Search.trim().length === 66) {
      setsearch("");
      history(`/tx/${Search.trim()}`);
      // window.location.reload(false);
      return false;
    } else if (Search.trim().length === 42) {
      let valid = isAddress(Search.trim());
      if (Search.trim() && valid) {
        setsearch("");
        history(`/address/${Search.trim().toLowerCase()}`);
        // window.location.reload(false);
        return false;
      } else {
        history("/error");
        return false;
      }
    } else if (Search.trim().length > 0 && a === true) {
      setsearch("");
      history(`/block/${parseInt(Search, 10)}`);
      // window.location.reload(false);
      return false;
    } else if (Search.length === 0) {
      return false;
    } else {
      history("/error");
      return false;
    }
  }

  const handleSearch = (val) => {
    setsearch(val);
    searchToken(val);
  };

  useEffect(() => {
    setInterval(() => {
      setRevanimate((prev) => !prev);
    }, 6000);
  }, []);

  return (
    <div>
      <section className="bg-dark" style={{ backgroundImage: "url(asset/images/bg.webp)" }}>
        <div className="container space-top-2 space-bottom-3">
          <div className="row justify-content-between align-items-center mb-4">
            <div className="col-md-6 col-lg-6">
              <div className="pr-lg-4 pr-xll-5">
                <h1 className="h4 mb-3 text-white text-left">
                  {process.env.REACT_APP_TICKER} Blockchain Explorer
                </h1>
                <form
                  className="mb-1"
                  autoComplete="off"
                  spellCheck="false"
                  onSubmit={(e) => handlesubmit(e)}
                >
                  <div className="input-group input-group-shadow">
                    <input
                      type="text"
                      className="form-control searchautocomplete"
                      id="txtSearchInput"
                      placeholder="Search by Address / Txn Hash / Block / Token"
                      aria-label="Search by Address / Txn Hash / Block / Token"
                      aria-describedby="button-header-search"
                      ref={btnref}
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                    <br />
                    <br />
                    <div
                      className={`dropdown-menu d-block m-1 p-0 ${dispDropDown ? "" : "hide_div"}`}
                      style={{ width: "100%", maxHeight: "300px", overflowY: "auto" }}
                      ref={ref}
                    >
                      <div className="search-history">
                        <ul
                          id="ui-id-1"
                          className="ui-menu ui-widget ui-widget-content ui-autocomplete ui-front list-unstyled bg-white-content shadow p-2 mb-0"
                        >
                          {searchData && searchData?.length
                            ? searchData?.map((item, index) => {
                                return (
                                  <li className="mb-2 ui-menu-item" key={index}>
                                    <Link
                                      className="ether-search media rounded p-2 ui-menu-item-wrapper"
                                      to={`/token/${item.address}`}
                                      id="ui-id-150"
                                      tabIndex={-1}
                                    >
                                      <div className="mr-2">
                                        <img
                                          className="u-xs-avatar mr-2"
                                          src={
                                            item &&
                                            (item?.status === "true" || item?.status === true) &&
                                            item?.logo
                                              ? process.env.REACT_APP_TOKEN_LOGO_PATH + item?.logo
                                              : "/asset/images/48.png"
                                          }
                                        />
                                      </div>
                                      <div className="media-body text-truncate">
                                        <h6 className="d-flex align-items-center text-size-1 mb-0">
                                          <div className="text-truncate mr-2">
                                            {item.name} ({item.symbol})
                                          </div>
                                          <span className="badge bg-soft-secondary mr-2">$0.00</span>
                                        </h6>
                                        <span className="text-secondary text-truncate font-size-1">
                                          {item.address}
                                        </span>
                                        <div className="text-secondary font-size-1 text-truncate">
                                          https://multichain.org/
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                );
                              })
                            : ""}
                        </ul>
                      </div>
                    </div>
                    <div className="input-group-append" ref={btnref}>
                      <button
                        name="home-search"
                        className="btn btn-primary input_search_box"
                        id="search"
                        type="submit"
                      >
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                </form>
                {/* <div className="d-flex">
                                    <div className="slid-container-two">
                                        <div>
                                            <p className="text-white-70 mb-0" style={{ minHeight: "22px" }}>
                                                <ins
                                                    data-revive-zoneid={14}
                                                    data-revive-id="6452186c83cd256052c3c100f524ed97"
                                                    data-revive-seq={0}
                                                    id="revive-0-0"
                                                    data-revive-loaded={1}
                                                    style={{ textDecoration: "none" }}
                                                >
                                                    <b> Sponsored : </b>
                                                </ins>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="slid-container">
                                        <div className={`first-slid ${revanimate ? "first-slid-on" : "first-slid-rev"}`}>
                                            <p className="text-white-70 mb-0" style={{ minHeight: "22px" }}>
                                                <ins
                                                    data-revive-zoneid={14}
                                                    data-revive-id="6452186c83cd256052c3c100f524ed97"
                                                    data-revive-seq={0}
                                                    id="revive-0-0"
                                                    data-revive-loaded={1}
                                                    style={{ textDecoration: "none" }}
                                                >
                                                    <a href="https://storscan.io/" className="text-white" target="_blank">
                                                        Missed <b>binance</b> in 2017.{" "}
                                                    </a>
                                                </ins>
                                            </p>
                                        </div>
                                        <div className={`second-slid ${revanimate ? "second-slid-on" : "second-slid-rev"}`}>
                                            <p className="text-white-70 mb-0" style={{ minHeight: "22px" }}>
                                                <ins
                                                    data-revive-zoneid={14}
                                                    data-revive-id="6452186c83cd256052c3c100f524ed97"
                                                    data-revive-seq={0}
                                                    id="revive-0-0"
                                                    data-revive-loaded={1}
                                                    style={{ textDecoration: "none" }}
                                                >
                                                    <a href="https://storscan.io/" className="text-white" target="_blank">
                                                        Don't missed <b>STOR</b> in 2023.{" "}
                                                    </a>
                                                </ins>
                                            </p>
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
            <div className="col-md-6 col-lg-6 align-self-end mb-1">
              <a href="https://t.me/cloudatabox" target="_blank">
                <img
                  id="header-search-banner"
                  width="90%"
                  src="/asset/images/home_search_img.jpg"
                  alt="home_search"
                />
              </a>
            </div>
            {/* <div className="col-md-12 col-lg-5">
              <div className="row d-flex justify-content-center p-0 m-0">
                <div
                  className="col-12 col-md-7 card d-flex justify-content-center align-items-center p-0 m-0"
                  style={{ height: "130px", width: "350px" }}
                >
                  <TxChart />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeSearch;
