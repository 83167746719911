import React, { useContext } from "react";
import Header2 from "../common/Header2";
import Footer from "../common/Footer";
import { IgniteContext } from "../IgniteContext";

const AboutUs = () => {
  const { theme } = useContext(IgniteContext);
  return (
    <>
      <Header2 />
      <main id="content" role="main">
        <div className="d-lg-flex">
          <div className="container d-lg-flex align-items-lg-center space-2 space-sm-3 space-lg-0">
            <div className="w-lg-80 mx-lg-auto">
              <div className="card">
                <div className="position-relative info-page-title space-1 space-md-2 p-4 px-md-7 px-md-9 rounded-lg">
                  <h1 className="text-white text-center">About Us</h1>
                  {/* <p className="text-white-70">Last updated: 25 September, 2023</p> */}
                  <figure className="position-absolute right-0 bottom-0 left-0 mb-n1">
                    {theme === "normal" ? (
                      <svg
                        preserveAspectRatio="none"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 1920 100.1"
                        width="100%"
                        height={32}
                      >
                        <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                      </svg>
                    ) : (
                      <svg
                        preserveAspectRatio="none"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 1920 100.1"
                        width="100%"
                        height={32}
                      >
                        <path fill="#212121" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                      </svg>
                    )}
                  </figure>
                </div>
                <div className="card-body p-4 p-md-9">
                  <div className="mb-5">
                    <p>
                      Welcome to STOR Scan, your premier destination for secure and efficient cryptocurrency
                      trading. Established in 2024, STOR Scan has quickly emerged as a trusted platform for
                      crypto enthusiasts and traders worldwide.With a dedication to speed and efficiency, our
                      platform consistently achieves a maximum of 476 transactions per second, ensuring you
                      can trade confidently, knowing that your orders are executed swiftly and seamlessly. Its
                      mission is to empower individuals and institutions with equitable access to
                      cryptocurrencies and blockchain technology.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default AboutUs;
