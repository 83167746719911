import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Footer from "../common/Footer";
import Header2 from "../common/Header2";
import Pagesize from "../common/Pagesize";

function TokenHolding() {
  const location = useLocation();
  const history = useNavigate();

  const [toggleFileterMenu, setToggleFilterMenu] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [mydata, setmydata] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [length, setLength] = useState("");
  // const [isError, setIsError] = useState(false);
  const [allHolders, setAllHolders] = useState([]);
  const [totalLength, setTotalLength] = useState(0);
  const [PageSize, setPageSize] = useState(10);

  // let search = window.location.search;
  // let params = new URLSearchParams(search);

  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;

  if (location.search) {
    if (currentPage !== Number(location.search.split("p=")[1])) {
      setCurrentPage(Number(location.search.split("p=")[1]));
    }
  }

  useEffect(() => {
    setTotalLength(Math.ceil(length / PageSize));
  }, [length, PageSize]);

  const onNext = (page) => {
    if (page === lastPageIndex) return false;
    // setPage(n);
    setCurrentPage(currentPage + 1);
    history({
      pathname: "/accounts",
      search: "?p=" + (currentPage + 1),
    });
  };

  const onPrevious = (page) => {
    if (page === 1) return false;
    setCurrentPage(currentPage - 1);
    // setMinPage(n);
    history({
      pathname: "/accounts",
      search: "?p=" + (currentPage - 1),
    });
  };

  window.onpopstate = () => {
    window.location.reload();
  };

  return (
    <>
      <Header2 />
      <main id="content" role="main" style={{ minHeight: "800px" }}>
        <input type="hidden" name="hdnAgeText" id="hdnAgeText" defaultValue="Age" />
        <input type="hidden" name="hdnDateTimeText" id="hdnDateTimeText" defaultValue="Date Time (UTC)" />
        <input type="hidden" name="hdnAgeTitle" id="hdnAgeTitle" defaultValue="Click to show Age Format" />
        <input
          type="hidden"
          name="hdnDateTimeTitle"
          id="hdnDateTimeTitle"
          defaultValue="Click to show Datetime Format"
        />
        <input type="hidden" name="hdnGasPriceTitle" id="hdnGasPriceTitle" defaultValue="Gas Price in Gwei" />
        <input
          type="hidden"
          name="hdnTxnFeeTitle"
          id="hdnTxnFeeTitle"
          defaultValue="(Gas Price * Gas Used by Txns) in BNB"
        />
        <input type="hidden" name="hdnGasPriceText" id="hdnGasPriceText" defaultValue="Gas Price" />
        <input type="hidden" name="hdnTxnText" id="hdnTxnText" defaultValue="Txn Fee" />
        <div className="container py-4">
          <div className="d-md-flex justify-content-between align-items-center">
            <h1 className="h4 mb-md-0">
              <img id="icon" className="u-xs-avatar rounded mt-n1 mr-1" src="/asset/images/48.png" />
              Token Holdings{" "}
              <a
                className="text-size-address text-primary text-break"
                href="/address/0x0000000000000000000000000000000000001000"
              >
                <span id="mainaddress">0x0000000000000000000000000000000000001000</span>
              </a>
            </h1>
          </div>
        </div>
        <div className="container mb-3">
          <div className="row">
            <div className="col-md-6 mb-2">
              <div className="card h-100">
                <div className="card-body p-4">
                  <div className="media align-items-center">
                    <figure className="u-avatar mr-3">
                      <img src="/asset/images/icon-34.svg" alt="BNB Value" />
                    </figure>
                    <div className="media-body">
                      <h2 className="font-size-1 text-uppercase text-secondary mb-0">Value in BNB</h2>
                      <span className="h5 mb-0" id="HoldingsETH">
                        313.269303
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-2">
              <div className="card h-100">
                <div className="card-body p-4">
                  <div className="row align-items-center">
                    <div className="col-8">
                      <div className="media align-items-center">
                        <figure className="u-avatar mr-3">
                          <img src="/asset/images/icon-31.svg?v=0.6.3.0" alt="USD Value" />
                        </figure>
                        <div className="media-body">
                          <h2 className="font-size-1 text-uppercase text-secondary mb-0">Value in USD</h2>
                          <span className="text-size-1">
                            <span className="h5 mr-1 mb-0" id="HoldingsUSD">
                              $93,745.83
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 text-right">
                      <span id="HoldingsUSDPercentage">
                        <span class="u-label u-label--sm u-label--success rounded color-strong ml-2">
                          <i class="fa fa-caret-up"></i>
                          0.13%
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div id="favAlerts" style={{ display: "none" }}>
            <div id="favAlert" className="container-msgpopup">
              <div className="popup">
                <p className="p-msg">You have reach your max limit of 100 favourites</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container space-bottom-2">
          <div className="card">
            <div className="card-header d-md-flex justify-content-between align-items-center">
              <div className="d-flex ml-auto mb-2 mb-md-0 order-2">
                <div id="divLabelFilterStatus" />
                <div className="position-relative order-1 order-md-2">
                  <a
                    id="searchFilterInvoker"
                    className="btn btn-sm btn-icon btn-primary"
                    href="javascript:;"
                    role="button"
                    aria-controls="searchFilter"
                    aria-haspopup="true"
                    aria-expanded="false"
                    data-unfold-target="#searchFilter"
                    data-unfold-type="css-animation"
                    data-unfold-duration={300}
                    data-unfold-delay={300}
                    data-unfold-hide-on-scroll="false"
                    data-unfold-animation-in="slideInUp"
                    data-unfold-animation-out="fadeOut"
                  >
                    <i className="fa fa-search btn-icon__inner" />
                  </a>
                  <div
                    id="searchFilter"
                    className="dropdown-menu dropdown-unfold dropdown-menu-sm-right p-2"
                    aria-labelledby="searchFilterInvoker"
                    style={{ width: "280px" }}
                  >
                    <form
                      action="/tokenholdings"
                      method="get"
                      className="js-focus-state input-group input-group-sm w-100"
                    >
                      <input id="a" className="form-control" type="hidden" name="a" defaultValue />
                      <input
                        id="q"
                        type="text"
                        className="form-control"
                        name="q"
                        placeholder="Search for Token Name or Address"
                        autoComplete="off"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          Find
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="custom-control custom-checkbox d-flex align-items-center order-1">
                <input
                  id="chkHide0Asset"
                  className="custom-control-input"
                  type="checkbox"
                  onclick="HideAssets()"
                />
                <a
                  id="aHide0Asset"
                  href="#"
                  className="custom-control-label font-weight-bold text-secondary cursor-pointer"
                  onclick="HideAssets()"
                >
                  {" "}
                  Hide $0.00 assets
                </a>
              </div>
            </div>
            <div className="card-body">
              <div className="d-md-flex justify-content-between align-items-lg-center mb-4">
                <p className="mb-2 mb-md-0">
                  <i
                    id="spinwheel"
                    className="fa fa-spin fa-spinner fa-1x fa-pulse"
                    style={{ display: "none", marginTop: "4px", marginRight: "4px" }}
                  >
                    &nbsp;
                  </i>
                  <span id="RecordsFound" />
                </p>
                <div className="d-flex ml-auto">
                  <nav className="mb-4 mb-md-0" aria-label="page navigation">
                    <span id="PaginButtons_Top" />
                  </nav>
                </div>
              </div>
              <div style={{ width: "100%", position: "relative" }}>
                <div className="js-sticky-header">
                  <div className="table-responsive mb-2 mb-md-0">
                    <table className="table table-align-middle table-hover">
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" width={20} id="th-favAll">
                            <div id="divFav" />
                          </th>
                          <th scope="col" width={200}>
                            <a
                              href="/tokenholdings?a=0x0000000000000000000000000000000000001000&ps=25&sort=tokenname&order=asc"
                              data-toggle="tooltip"
                              data-boundary="viewport"
                              data-html="true"
                              title="Click for ASC sort"
                            >
                              Token Name
                            </a>
                          </th>
                          <th scope="col" width={100}>
                            <a
                              href="/tokenholdings?a=0x0000000000000000000000000000000000001000&ps=25&sort=symbol&order=asc"
                              data-toggle="tooltip"
                              data-boundary="viewport"
                              data-html="true"
                              title="Click for ASC sort"
                            >
                              Symbol
                            </a>
                          </th>
                          <th scope="col" width={150}>
                            <a
                              href="/tokenholdings?a=0x0000000000000000000000000000000000001000&ps=25&sort=quantity&order=desc"
                              data-toggle="tooltip"
                              data-boundary="viewport"
                              data-html="true"
                              title="Click for DESC sort"
                            >
                              Quantity
                            </a>
                          </th>
                          <th scope="col" width={200}>
                            <a
                              href="/tokenholdings?a=0x0000000000000000000000000000000000001000&ps=25&sort=icoprice&order=desc"
                              data-toggle="tooltip"
                              data-boundary="viewport"
                              data-html="true"
                              title="Click for DESC sort"
                            >
                              Token Price
                            </a>
                          </th>
                          <th scope="col">
                            <a
                              href="/tokenholdings?a=0x0000000000000000000000000000000000001000&ps=25&sort=percent_change_24h&order=desc"
                              data-toggle="tooltip"
                              data-boundary="viewport"
                              data-html="true"
                              title="Click for DESC sort"
                            >
                              Change (24h)
                            </a>
                          </th>
                          <th scope="col">
                            <a
                              href="/tokenholdings?a=0x0000000000000000000000000000000000001000&ps=25&sort=total_price_eth&order=desc"
                              data-toggle="tooltip"
                              data-boundary="viewport"
                              data-html="true"
                              title="Click for DESC sort"
                            >
                              Value in BNB
                            </a>
                          </th>
                          <th scope="col">
                            <span className="fa fa-angle-down text-secondary">&nbsp;&nbsp;</span>
                            <a
                              href="/tokenholdings?a=0x0000000000000000000000000000000000001000&ps=25&sort=total_price_usd&order=asc"
                              data-toggle="tooltip"
                              data-boundary="viewport"
                              data-html="true"
                              title="Sorted by DESC order,
Click for ASC sort"
                            >
                              Value in USD
                            </a>
                          </th>
                        </tr>
                      </thead>
                      <tbody id="tb1">
                        <tr>
                          <td id="td-0.1" class="clsShowAlert">
                            <span id="0.1" class="fa fa-star un-fav clsShowAlert"></span>
                          </td>
                          <td>
                            <div class="media align-items-center">
                              <div class="mr-2">
                                <img class="u-md-avatar" src="/asset/images/48.png" />
                              </div>
                              <div class="media-body font-weight-bold">{process.env.REACT_APP_NAME}</div>
                            </div>
                          </td>
                          <td>{process.env.REACT_APP_TICKER}</td>
                          <td>293.45871284</td>
                          <td>
                            $299.25
                            <span class="text-secondary"> (1 BNB)</span>
                          </td>
                          <td>
                            <span class="text-danger">
                              <i class="fa fa-caret-down"></i>
                              2.03%
                            </span>
                          </td>
                          <td>
                            <i class="fab fa-binance text-secondary"></i>
                            293.458713
                          </td>
                          <td>$87,817.52</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <table
                  id="tbl2"
                  className="table tablevisibility table-align-middle table-hover"
                  style={{ position: "absolute", width: "220px", top: "1px" }}
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" id="th-favAll2" style={{ position: "sticky", top: 0, zIndex: 100 }}>
                        <div id="divFav2" />
                      </th>
                      <th scope="col" style={{ position: "sticky", top: 0, zIndex: 100 }}>
                        <a
                          href="/tokenholdings?a=0x0000000000000000000000000000000000001000&ps=25&sort=tokenname&order=asc"
                          data-toggle="tooltip"
                          data-boundary="viewport"
                          data-html="true"
                          title="Click for ASC sort"
                        >
                          Token Name
                        </a>
                      </th>
                    </tr>
                  </thead>
                  <tbody id="tb2" />
                </table>
              </div>
              <div id="ContentPlaceHolder1_pageRecords">
                <form
                  method="post"
                  action="./txs?a=0xa47c861de3272690a51f57e0e43551e55f6c790d&f=3"
                  id="ctl00"
                >
                  <div className="aspNetHidden">
                    <input type="hidden" name="__EVENTTARGET" id="__EVENTTARGET" defaultValue />
                    <input type="hidden" name="__EVENTARGUMENT" id="__EVENTARGUMENT" defaultValue />
                    <input type="hidden" name="__LASTFOCUS" id="__LASTFOCUS" defaultValue />
                    <input
                      type="hidden"
                      name="__VIEWSTATE"
                      id="__VIEWSTATE"
                      defaultValue="Bq/9cO7CZoKsBJUV/29bD4FK9JfiWBR8K3Xct5pE4yeOeVHsl3QUCm1vNUaLdh2pTYNj+XJ1dJIkqB4uFKJJms5LClM8x1ely6dRwpel/v0="
                    />
                  </div>
                  <div className="aspNetHidden">
                    <input
                      type="hidden"
                      name="__VIEWSTATEGENERATOR"
                      id="__VIEWSTATEGENERATOR"
                      defaultValue="FCB51872"
                    />
                    <input
                      type="hidden"
                      name="__EVENTVALIDATION"
                      id="__EVENTVALIDATION"
                      defaultValue="5TrC1mV6S4Yx1yF2C2sLmicX3ATXUyUTLuxVAInkxacf5mTyIHKjLPrOmaOvJ9UXe9DGTD98Pk9ulUEydaJx7Iv6d2Y6lC/uZ+H1BgNAAWmsbx125KGCD+yRdaIq3v41KvarcJKdjK4Eiwz/l/6VvX4RNlG/B/1Hs2ybeTrvUGc7y2OY06zyOog62WKprhKt+jYh4FuBaDZjJaFOk7Tkaw=="
                    />
                  </div>
                  <div class="row w-100 mt-3">
                    <div class="col-md-6 mt-3">
                      <Pagesize setPageSize={setPageSize} PageSize={PageSize} />
                    </div>
                    <div class="col-md-6 mt-3">
                      <div className="float-right mr-3">
                        <div class="row">
                          <button
                            class={currentPage === 1 ? "page-link disabled" : "page-link"}
                            onClick={() => {
                              setCurrentPage(1);
                              history({
                                pathname: "/accounts",
                                search: "?p=" + 1,
                              });
                            }}
                            disabled={currentPage === 1}
                          >
                            First
                          </button>
                          <button
                            class={currentPage === 1 ? "page-link disabled" : "page-link"}
                            onClick={() => onPrevious(currentPage)}
                            disabled={currentPage === 1}
                          >
                            <i className="fa fa-angle-left" />
                          </button>
                          <div class="page-link-badge" disabled>
                            {" "}
                            {`${currentPage} of ${totalLength}`}
                          </div>
                          <button
                            class={currentPage === totalLength ? "page-link disabled" : "page-link"}
                            onClick={() => onNext(currentPage)}
                            disabled={currentPage === totalLength}
                          >
                            <i className="fa fa-angle-right" />
                          </button>
                          <button
                            class={currentPage === totalLength ? "page-link disabled" : "page-link"}
                            onClick={() => {
                              setCurrentPage(totalLength);
                              history({
                                pathname: "/accounts",
                                search: "?p=" + totalLength,
                              });
                            }}
                            disabled={currentPage === totalLength}
                          >
                            Last
                          </button>
                        </div>
                      </div>
                      {/* <Pagination currentPage={currentPage} setCurrentPage={setCurrentPage} totalPage={totalLength} /> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default TokenHolding;
