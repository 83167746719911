import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header2 from "../common/Header2";
import Footer from "../common/Footer";
import Pagesize from "../common/Pagesize";
import Spinner from "../common/Spinner";
import axios from "axios";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import NoEntryMessage from "../common/NoEntryMessage";
import { useSearchParams } from "react-router-dom";
import { IgniteContext } from "../IgniteContext";
import FadingLoaderCard1 from "../common/ContentLoaders/LoaderBigLine";

function ContractInternalTransaction() {
    let blocknum = null;

    const [searchParams] = useSearchParams();
    const cpage = searchParams.get("p");
    const block = searchParams.get("block");

    const { formatHash, formatDate, formatAddress, verifyAndGetUser } = useContext(IgniteContext);
    const { encusername } = verifyAndGetUser();

    const history = useNavigate();

    const [ageColChange, setAgeColChange] = useState(true);

    const [internaltsxn, setInternaltsxn] = useState([]);

    const [totalinternaltsxn, setTotalinternaltsxn] = useState(0);

    const [currentPage, setCurrentPage] = useState(cpage ? cpage : 1);
    const [spinner, setSpinner] = useState(true);

    const [totalLength, setTotalLength] = useState(1);
    const [pages, setPages] = useState(10);

    const firstPageIndex = (currentPage - 1) * pages;
    const lastPageIndex = firstPageIndex + pages;

    const getinternaltsxn = async () => {
        setSpinner(true);
        let param = {};
        if (block) {
            param = {
                limit: pages,
                page: currentPage,
                blockNumber: block,
            };
        } else {
            param = {
                limit: pages,
                page: currentPage,
            };
        }
        await axios
            .get(`${process.env.REACT_APP_BACKEND_URL}txs/internal`, {
                params: param,
                headers: {
                    etoken: encusername,
                },
            })
            .then((res) => {
                if (res.data.error) {
                } else {
                    setInternaltsxn(res.data.items);
                    setTotalinternaltsxn(res.data.total);
                    setTotalLength(res.data.pages);
                    setSpinner(false);
                }
            })
            .catch((err) => {
                console.log("error in getinternaltsxn (catch) : ", err);
            });
    };

    useEffect(() => {
        getinternaltsxn();
    }, [pages, currentPage]);

    const onNext = (page) => {
        if (page === lastPageIndex) return false;

        setCurrentPage(currentPage + 1);
        history({
            pathname: "/txsInternal",
            search: !block ? "?p=" + (currentPage + 1) : `?block=${block}&p=` + (currentPage + 1),
        });
    };

    const onPrevious = (page) => {
        if (page === 1) return false;
        setCurrentPage(currentPage - 1);

        history({
            pathname: "/txsInternal",
            search: !block ? "?p=" + (currentPage + 1) : `?block=${block}&p=` + (currentPage + 1),
        });
    };

    return (
        <>
            <Header2 />
            <main id="content" role="main" style={{ minHeight: "800px" }}>
                <div className="container py-3">
                    <div className="d-sm-flex align-items-center">
                        <div className="mb-2 mb-sm-0">
                            <h1 className="h4 mb-0">
                                Contract Internal Transactions
                                <p className="mb-0 text-break small">
                                    {block ? <span className="small text-secondary ml-1">For Block {block}</span> : ""}
                                </p>
                            </h1>
                        </div>
                    </div>
                </div>
                <span id="ContentPlaceHolder1_lblAdResult" />
                <div className="container space-bottom-2">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive mb-2 mb-md-0">
                                <table className="table table-hover">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Block</th>
                                            <th scope="col">
                                                {ageColChange ? (
                                                    <OverlayTrigger
                                                        placement="top"
                                                        trigger="hover"
                                                        overlay={<Tooltip>Click to show Datetime Format</Tooltip>}
                                                    >
                                                        <Link onClick={() => setAgeColChange(!ageColChange)} id="lnkAgeDateTime">
                                                            Age
                                                        </Link>
                                                    </OverlayTrigger>
                                                ) : (
                                                    <OverlayTrigger
                                                        placement="top"
                                                        trigger="hover"
                                                        overlay={<Tooltip>Click to show Age Format</Tooltip>}
                                                    >
                                                        <Link onClick={() => setAgeColChange(!ageColChange)}>Date Time (UTC)</Link>
                                                    </OverlayTrigger>
                                                )}
                                            </th>

                                            <th scope="col">Parent Txn Hash</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">From</th>
                                            <th scope="col" width={30} />
                                            <th scope="col">To</th>
                                            <th scope="col">Value</th>
                                        </tr>
                                    </thead>
                                    {spinner ? (
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="40" height="15" />{" "}
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td></td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="40" height="15" />{" "}
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td></td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="40" height="15" />{" "}
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td></td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="40" height="15" />{" "}
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td></td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="40" height="15" />{" "}
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td></td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="40" height="15" />{" "}
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td></td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="40" height="15" />{" "}
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td></td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="40" height="15" />{" "}
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td></td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="40" height="15" />{" "}
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td></td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="40" height="15" />{" "}
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td></td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="40" height="15" />{" "}
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td></td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                                <td>
                                                    {" "}
                                                    <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            {internaltsxn && internaltsxn?.length ? (
                                                internaltsxn.map((item) => {
                                                    let blockdisp = blocknum !== item.blockNumber;
                                                    blocknum = blocknum !== item.blockNumber ? item.blockNumber : blocknum;

                                                    return (
                                                        <tr>
                                                            <td>
                                                                {blockdisp ? <Link to={`/block/${item.blockNumber}`}>{item.blockNumber}</Link> : ""}
                                                            </td>

                                                            <td className="">
                                                                {blockdisp ? (
                                                                    ageColChange ? (
                                                                        <span rel="tooltip" data-toggle="tooltip" data-placement="bottom">
                                                                            {item.age}
                                                                        </span>
                                                                    ) : (
                                                                        <span rel="tooltip" data-toggle="tooltip" data-placement="bottom">
                                                                            {formatDate(item.timestamp)}
                                                                        </span>
                                                                    )
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </td>
                                                            <td className="bg-light">
                                                                <i className="fa fa-check-circle text-success" />{" "}
                                                                {/* <i className="fa fa-exclamation-circle text-danger" />{""} */}
                                                                <span className="hash-tag text-truncate">
                                                                    <Link to={`/tx/${item.hash}`}>{formatHash(item.hash)}</Link>
                                                                </span>{" "}
                                                                <i className="fas fa-chevron-circle-right text-secondary" />
                                                            </td>
                                                            <td>{item.type}</td>
                                                            <td>
                                                                {item.isSendercontract ? (
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        trigger="hover"
                                                                        overlay={<Tooltip>Contract</Tooltip>}
                                                                    >
                                                                        <i className="far fa-file-alt text-secondary" data-toggle="tooltip" />
                                                                    </OverlayTrigger>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                &nbsp;
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    trigger="hover"
                                                                    overlay={<Tooltip>{item.from}</Tooltip>}
                                                                >
                                                                    <Link
                                                                        className=""
                                                                        to={`/address/${item.from}`}
                                                                        target="_parent"
                                                                        data-toggle="tooltip"
                                                                    >
                                                                        {item?.fromNameTag
                                                                            ? item?.fromNameTag
                                                                            : item?.publicFromNameTag
                                                                            ? item?.publicFromNameTag
                                                                            : formatAddress(item.from)}
                                                                    </Link>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <span className="btn btn-xs btn-icon btn-soft-success rounded-circle">
                                                                    <i className="fas fa-long-arrow-alt-right btn-icon__inner" />
                                                                </span>
                                                            </td>
                                                            <td>
                                                                {item.isRecievercontract ? (
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        trigger="hover"
                                                                        overlay={<Tooltip>Contract</Tooltip>}
                                                                    >
                                                                        <i className="far fa-file-alt text-secondary" data-toggle="tooltip" />
                                                                    </OverlayTrigger>
                                                                ) : (
                                                                    ""
                                                                )}
                                                                &nbsp;
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    trigger="hover"
                                                                    overlay={<Tooltip>{item.to}</Tooltip>}
                                                                >
                                                                    <Link
                                                                        className=""
                                                                        to={`/address/${item.to}`}
                                                                        target="_parent"
                                                                        data-toggle="tooltip"
                                                                    >
                                                                        {item?.toNameTag
                                                                            ? item?.toNameTag
                                                                            : item?.publicToNameTag
                                                                            ? item?.publicToNameTag
                                                                            : formatAddress(item.to)}
                                                                    </Link>
                                                                </OverlayTrigger>
                                                            </td>
                                                            <td>{item.value}</td>
                                                        </tr>
                                                    );
                                                })
                                            ) : (
                                                <NoEntryMessage message={process.env.REACT_APP_BLANK_DATA_MESSAGE} />
                                            )}
                                        </tbody>
                                    )}
                                </table>
                                {internaltsxn && internaltsxn?.length ? (
                                    <div class="row w-100 mt-3">
                                        <div class="col-md-6 mt-3">
                                            <Pagesize setPages={setPages} pages={pages} />
                                        </div>
                                        <div class="col-md-6 mt-3">
                                            <div className="float-right mr-3">
                                                <div class="row">
                                                    <button
                                                        class={currentPage === 1 ? "page-link disabled" : "page-link"}
                                                        onClick={() => {
                                                            setCurrentPage(1);
                                                            history({
                                                                pathname: "/txsInternal",
                                                                search: !block ? "?p=1" : `?block=${block}&p=1`,
                                                            });
                                                        }}
                                                        disabled={currentPage === 1}
                                                    >
                                                        First
                                                    </button>
                                                    <button
                                                        class={currentPage === 1 ? "page-link disabled" : "page-link"}
                                                        onClick={() => onPrevious(currentPage)}
                                                        disabled={currentPage === 1}
                                                    >
                                                        <i className="fa fa-angle-left" />
                                                    </button>
                                                    <div class="page-link-badge" disabled>
                                                        {" "}
                                                        {`${currentPage} of ${totalLength}`}
                                                    </div>
                                                    <button
                                                        class={currentPage === totalLength ? "page-link disabled" : "page-link"}
                                                        onClick={() => onNext(currentPage)}
                                                        disabled={currentPage === totalLength}
                                                    >
                                                        <i className="fa fa-angle-right" />
                                                    </button>
                                                    <button
                                                        class={currentPage === totalLength ? "page-link disabled" : "page-link"}
                                                        onClick={() => {
                                                            setCurrentPage(totalLength);
                                                            history({
                                                                pathname: "/txsInternal",
                                                                search: !block ? "?p=" + totalLength : `?block=${block}&p=` + totalLength,
                                                            });
                                                        }}
                                                        disabled={currentPage === totalLength}
                                                    >
                                                        Last
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
}

export default ContractInternalTransaction;
