import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Header2 from "../common/Header2";
import Sidebar from "../common/Sidebar";
import CryptoJS from "crypto-js";
import Spinner from "../common/Spinner";
import { IgniteContext } from "../IgniteContext";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import delay from "delay";
import { getLocalStorage } from "../../utils";

function Mynotes_address() {
  const navigate = useNavigate();

  const { isAddress } = useContext(IgniteContext);

  const username = CryptoJS.AES.decrypt(
    getLocalStorage("ua5s21san"),
    process.env.REACT_APP_PASSWORD
  ).toString(CryptoJS.enc.Utf8);
  const password = CryptoJS.AES.decrypt(
    getLocalStorage("pn4sec3f1s"),
    process.env.REACT_APP_PASSWORD
  ).toString(CryptoJS.enc.Utf8);

  const PageSize = 10;
  const [addressNotes, setAddressNotes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState(0);
  const [state, setState] = useState("");
  const [spinner, setSpinner] = useState(true);
  const [totalAddNotes, setTotalAddNotes] = useState(0);

  const [address, setAddress] = useState("");
  const [nameTag, setNameTag] = useState("");
  const [addNotes, setAddNotes] = useState("");

  const [addressError, setAddressError] = useState(null);
  const [nameTagError, setNameTagError] = useState(null);
  const [addNotesError, setAddNotesError] = useState(null);

  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [editAddNote, setEditAddNote] = useState("");
  const [deleteAddNote, setDeleteAddNote] = useState("");

  const getAllAddressNotes = async () => {
    setSpinner(true);
    const encBody = CryptoJS.AES.encrypt(
      JSON.stringify({
        action: "get",
        page: currentPage,
        limit: PageSize,
        username: username,
        password: password,
        timestemp: Math.round(Date.now() / 1000),
      }),
      process.env.REACT_APP_PASSWORD
    ).toString();
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}user/addressprivatenote`, {
        token: encBody,
      })
      .then((res) => {
        if (res.data.error) {
          console.log("error in getTxNotes : ", res.data.error);
        } else {
          setAddressNotes(res.data.items);
          setTotalAddNotes(res.data.total);
          setPages(res.data.pages);
          setSpinner(false);
        }
      })
      .catch((err) => {
        console.log("error in getTxNotes (catch) : ", err);
      });
  };

  useEffect(() => {
    getAllAddressNotes();
  }, [currentPage, PageSize]);

  const handleAddressChange = (val) => {
    setAddress(val);
    if (val === "") {
      setAddressError("Require.");
    } else {
      if (!isAddress(val)) {
        setAddressError("Invalid address.");
      } else {
        setAddressError("");
      }
    }
  };
  const handleNameTagChange = (val) => {
    setNameTag(val);
    if (val === "") {
      setNameTagError("Require.");
    } else {
      setNameTagError("");
    }
  };
  const handleNoteChange = (val) => {
    setAddNotes(val);
    if (val === "") {
      setAddNotesError("Require.");
    } else {
      setAddNotesError("");
    }
  };

  const handleEditAddNote = (address, name, note) => {
    setEditAddNote(address);
    setAddress(address);
    setNameTag(name);
    setAddNotes(note);
    setErrorMsg("");
    setSuccessMsg("");
    setAddressError(null);
    setNameTagError(null);
    setAddNotesError(null);
  };

  const addAddressNote = async (e) => {
    let temp = 1;
    setAddressError(null);
    setNameTagError(null);
    setAddNotesError(null);
    if (address === "") {
      setAddressError("Require.");
      temp = 0;
    } else {
      if (!isAddress(address)) {
        setAddressError("Invalid address.");
        temp = 0;
      } else {
        setAddressError("");
      }
    }
    if (nameTag === "") {
      setNameTagError("Require.");
      temp = 0;
    } else {
      setNameTagError("");
    }
    if (addNotes === "") {
      setAddNotesError("Require.");
      temp = 0;
    } else {
      setAddNotesError("");
    }
    if (temp) {
      const encBody = CryptoJS.AES.encrypt(
        JSON.stringify({
          username: username,
          password: password,
          address: address,
          note: addNotes,
          name: nameTag,
          action: "insert",
          page: currentPage,
          limit: PageSize,
          timestemp: Math.round(Date.now() / 1000),
        }),
        process.env.REACT_APP_PASSWORD
      ).toString();
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}user/addressprivatenote`, {
          token: encBody,
        })
        .then((res) => {
          if (res.data.error) {
            setErrorMsg(res.data.error);
          } else {
            setSuccessMsg(res.data.message);
            getAllAddressNotes();
            clearStates();
          }
        });
    }
  };
  const updateAddressNote = async (e) => {
    let temp = 1;
    setAddressError(null);
    setNameTagError(null);
    setAddNotesError(null);
    if (address === "") {
      setAddressError("Require.");
      temp = 0;
    } else {
      if (!isAddress(address)) {
        setAddressError("Invalid address.");
        temp = 0;
      } else {
        setAddressError("");
      }
    }
    if (nameTag === "") {
      setNameTagError("Require.");
      temp = 0;
    } else {
      setNameTagError("");
    }
    if (addNotes === "") {
      setAddNotesError("Require.");
      temp = 0;
    } else {
      setAddNotesError("");
    }
    if (temp) {
      const encBody = CryptoJS.AES.encrypt(
        JSON.stringify({
          username: username,
          password: password,
          address: address,
          note: addNotes,
          name: nameTag,
          action: "update",
          page: currentPage,
          limit: PageSize,
          timestemp: Math.round(Date.now() / 1000),
        }),
        process.env.REACT_APP_PASSWORD
      ).toString();
      await axios
        .post(`${process.env.REACT_APP_BACKEND_URL}user/addressprivatenote`, {
          token: encBody,
        })
        .then((res) => {
          if (res.data.error) {
            setErrorMsg(res.data.error);
          } else {
            setSuccessMsg(res.data.message);
            setEditAddNote("");
            getAllAddressNotes();
            clearStates();
          }
        });
    }
  };
  const deleteAddressNote = async (e) => {
    const encBody = CryptoJS.AES.encrypt(
      JSON.stringify({
        username: username,
        password: password,
        address: deleteAddNote,
        action: "remove",
        timestemp: Math.round(Date.now() / 1000),
      }),
      process.env.REACT_APP_PASSWORD
    ).toString();
    await axios
      .post(`${process.env.REACT_APP_BACKEND_URL}user/addressprivatenote`, {
        token: encBody,
      })
      .then((res) => {
        if (res.data.error) {
          setErrorMsg(res.data.error);
        } else {
          setSuccessMsg(res.data.message);
          setDeleteAddNote("");
          getAllAddressNotes();
        }
      });
  };

  const clearStates = () => {
    setErrorMsg("");
    setSuccessMsg("");
    setAddressError(null);
    setNameTagError(null);
    setAddNotesError(null);
    setAddress("");
    setNameTag("");
    setAddNotes("");
    setEditAddNote("");
    setDeleteAddNote("");
  };

  async function copy(ind) {
    setState(ind);
    await delay(1000);
    setState("");
  }

  const onNext = (page) => {
    if (page === pages) return false;
    setCurrentPage(Number(currentPage) + 1);
    navigate({
      pathname: "/mynotes_address",
      search: "?p=" + (currentPage + 1),
    });
  };

  const onPrevious = (page) => {
    if (page === 1) return false;
    setCurrentPage(Number(currentPage) - 1);
    navigate({
      pathname: "/mynotes_address",
      search: "?p=" + (currentPage - 1),
    });
  };

  return (
    <>
      <Header2 />
      <main id="content" role="main" style={{ minHeight: "780px" }}>
        <input type="hidden" name="hdnAgeText" id="hdnAgeText" defaultValue="Age" />
        <input type="hidden" name="hdnDateTimeText" id="hdnDateTimeText" defaultValue="Date Time (UTC)" />
        <input type="hidden" name="hdnAgeTitle" id="hdnAgeTitle" defaultValue="Click to show Age Format" />
        <input
          type="hidden"
          name="hdnDateTimeTitle"
          id="hdnDateTimeTitle"
          defaultValue="Click to show Datetime Format"
        />
        <div className="container space-1">
          <div className="row">
            <div className="col-md-3">
              <Sidebar />
              <div className="card mb-3">
                <div className="card-body">
                  <div className="media">
                    <span className="btn btn-sm btn-icon btn-soft-info rounded-circle mb-2 mr-2">
                      <span className="fas fa-info btn-icon__inner" />
                    </span>
                    <div className="media">
                      <h2 className="h5">Address Notes?</h2>
                    </div>
                  </div>
                  <p className="mb-0">
                    A private name tag for individual addresses can be saved and is useful for labelling
                    addresses of interest. <br />
                    <br />
                    This information is only visible to you.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <div className="card">
                <div className="card-header d-md-flex justify-content-between align-items-center">
                  <div className="d-flex flex-wrap">
                    <div className="mr-2 mb-2 mb-md-0">
                      <h2 className="card-header-title mt-1">My Address Private Notes</h2>
                    </div>
                    <div className="mr-2 mb-2 mb-md-0">
                      <button
                        href="#"
                        id="ContentPlaceHolder1_addnew"
                        data-toggle="modal"
                        data-target="#responsive"
                        rel="tooltip"
                        title
                        className="btn btn-xss btn-primary rounded cursor-pointer"
                        onclick="addNewNote();"
                        data-original-title="Add a new address private note"
                      >
                        <i
                          className="fa fa-plus-square fa-lg mr-1"
                          style={{ marginLeft: "-3px", marginBottom: "3px" }}
                        />
                        Add
                      </button>
                    </div>
                  </div>
                </div>
                {spinner ? (
                  <Spinner />
                ) : (
                  <div className="card-body">
                    <div className="d-md-flex justify-content-lg-between align-items-center mb-4">
                      <div className="col-lg-6 mb-lg-0 mb-4">
                        <p className="mb-0 d-flex justify-content-lg-start justify-content-center">
                          <i
                            id="spinwheel"
                            className="fa fa-spin fa-spinner fa-1x fa-pulse"
                            style={{ display: "none", marginTop: "4px" }}
                          >
                            &nbsp;
                          </i>
                          &nbsp;{totalAddNotes} used (Out of 1000 max quota)
                        </p>
                      </div>
                      <div className="col-lg-6 justify-content-lg-center">
                        <div class="row w-100">
                          <div class="col-12">
                            <div>
                              <div class="row justify-content-lg-end justify-content-center">
                                <button
                                  class={currentPage === 1 ? "page-link disabled" : "page-link"}
                                  onClick={() => {
                                    setCurrentPage(1);
                                    navigate({
                                      pathname: "/mynotes_address",
                                      search: "?p=" + 1,
                                    });
                                  }}
                                  disabled={currentPage === 1}
                                >
                                  First
                                </button>
                                <button
                                  class={currentPage === 1 ? "page-link disabled" : "page-link"}
                                  onClick={() => onPrevious(currentPage)}
                                  disabled={currentPage === 1}
                                >
                                  <i className="fa fa-angle-left" />
                                </button>
                                <div class="page-link-badge" disabled>
                                  {" "}
                                  {`${currentPage} of ${pages}`}
                                </div>
                                <button
                                  class={currentPage === pages ? "page-link disabled" : "page-link"}
                                  onClick={() => onNext(currentPage)}
                                  disabled={currentPage === pages}
                                >
                                  <i className="fa fa-angle-right" />
                                </button>
                                <button
                                  class={currentPage === pages ? "page-link disabled" : "page-link"}
                                  onClick={() => {
                                    setCurrentPage(pages);
                                    navigate({
                                      pathname: "/mynotes_address",
                                      search: "?p=" + pages,
                                    });
                                  }}
                                  disabled={currentPage === pages}
                                >
                                  Last
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive mb-2 mb-md-0">
                      <div className="mx-md-auto">
                        <figure id="SVGdataReport1" className="mb-7 ie-data-report">
                          <table className="table">
                            <thead className="thead-light">
                              <tr>
                                <th scope="col">Action</th>
                                <th scope="col">Name Tag</th>
                                <th scope="col">Address Note</th>
                                <th
                                  scope="col"
                                  data-toggle="tooltip"
                                  title
                                  data-original-title="UTC TimeZone (yyyy-mm-dd)"
                                >
                                  Created
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {addressNotes && addressNotes?.length ? (
                                addressNotes?.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td className="align-top">
                                        <button
                                          data-toggle="modal"
                                          data-target="#responsive"
                                          className="btn btn-outline-secondary btn-xss html-link-custom mr-1 cursor-pointer"
                                          onClick={() =>
                                            handleEditAddNote(item.address, item.name, item.note)
                                          }
                                        >
                                          View
                                        </button>
                                        <button
                                          data-toggle="modal"
                                          data-target="#myModaldelete"
                                          className="btn btn-outline-secondary btn-xss html-link-custom cursor-pointer"
                                          onClick={() => setDeleteAddNote(item.address)}
                                        >
                                          Remove
                                        </button>
                                      </td>
                                      <td className="align-top">
                                        <span
                                          className="u-label u-label--value u-label--secondary text-dark"
                                          id="t_0xd89d4292cc8779037f3063aa35cb7473ca674bfd"
                                        >
                                          {item.name}
                                        </span>
                                      </td>
                                      <td className="align-top">
                                        <span className="d-block">
                                          <Link to={`/address/${item.address}`}>
                                            <span className="text-primary">{item.address}</span>
                                          </Link>
                                          <CopyToClipboard text={item.address} onCopy={() => copy(index)}>
                                            <span className="js-clipboard btn btn-sm btn-icon text-secondary">
                                              {state === index ? (
                                                <i className="fa fa-check mx-1 mt-2"></i>
                                              ) : (
                                                <i className="far fa-copy mt-2"></i>
                                              )}{" "}
                                            </span>
                                          </CopyToClipboard>
                                        </span>
                                        <span
                                          className="d-block small text-break"
                                          id="n_0xd89d4292cc8779037f3063aa35cb7473ca674bfd"
                                        >
                                          {item.note}
                                        </span>
                                      </td>
                                      <td className="align-top">
                                        {moment(item.createdAt).format("YYYY-MM-DD")}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colspan="5" className="align-top">
                                    <center>
                                      <div className="alert alert-secondary mt-4" role="alert">
                                        <i className="fal fa-info-circle"></i>{" "}
                                        <span className="text-dark">There are no private tags found</span>
                                      </div>
                                    </center>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </figure>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel1"
          aria-hidden="true"
          id="responsive"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header p-2">
                <h5
                  className="pl-3 modal-title h6 font-weight-bold text-break w-100 mt-2 mb-2"
                  id="myresponsiveLabel"
                >
                  My Address Private Note
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={clearStates}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                {errorMsg ? (
                  <span id="spanResult">
                    <div className="alert alert-danger alert-dismissible">
                      <strong>Status:</strong> Error! {errorMsg}{" "}
                      <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="True">×</span>
                      </button>
                    </div>
                  </span>
                ) : (
                  ""
                )}
                {successMsg ? (
                  <span id="spanResult">
                    <div className="alert alert-success alert-dismissible">
                      <strong>Status:</strong> Success! {successMsg}{" "}
                      <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                        <span aria-hidden="True">×</span>
                      </button>
                    </div>
                  </span>
                ) : (
                  ""
                )}
                <div className="form-group row">
                  <label className="col-sm-12 mt-12" htmlFor="Address">
                    &nbsp;Address :
                  </label>
                  <div className="js-form-message input-group input-group-sm col-sm-12">
                    <input
                      name="ctl00$ContentPlaceHolder1$txtAddress"
                      type="text"
                      value={address}
                      className={
                        addressError === null
                          ? "form-control form-control-sm"
                          : addressError === ""
                          ? "form-control form-control-sm success"
                          : "form-control form-control-sm error"
                      }
                      maxLength={44}
                      id="ContentPlaceHolder1_txtAddress"
                      readOnly={editAddNote ? true : false}
                      onChange={(e) => {
                        handleAddressChange(e.target.value);
                      }}
                    />
                    {addressError && (
                      <div
                        id="ContentPlaceHolder1_txtUserName-error"
                        class="invalid-feedback"
                        style={{ display: "Block" }}
                      >
                        {addressError}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 mt-12" htmlFor="nameTag">
                    &nbsp;Name Tag :
                  </label>
                  <div className="input-group input-group-sm col-sm-12">
                    <input
                      name="ctl00$ContentPlaceHolder1$txtPrivateNameTag"
                      type="text"
                      value={nameTag}
                      maxLength={35}
                      id="ContentPlaceHolder1_txtPrivateNameTag"
                      className={
                        nameTagError === null
                          ? "form-control form-control-sm"
                          : nameTagError === ""
                          ? "form-control form-control-sm success"
                          : "form-control form-control-sm error"
                      }
                      onChange={(e) => {
                        handleNameTagChange(e.target.value);
                      }}
                      spellCheck="false"
                    />
                    {nameTagError && (
                      <div
                        id="ContentPlaceHolder1_txtUserName-error"
                        class="invalid-feedback"
                        style={{ display: "Block" }}
                      >
                        {nameTagError}
                      </div>
                    )}
                  </div>
                  <div className="small text-secondary mt-1 ml-3">
                    <span id="privatetagtip">
                      Private Name Tags (up to 35 characters) can be used for easy identification of addresses
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 mt-12" htmlFor="nameTag">
                    &nbsp;Private Note :
                  </label>
                  <div className="input-group input-group-sm col-sm-12">
                    <textarea
                      name="ctl00$ContentPlaceHolder1$txtPrivateNoteArea"
                      value={addNotes}
                      rows={6}
                      cols={20}
                      id="ContentPlaceHolder1_txtPrivateNoteArea"
                      className={
                        addNotesError === null
                          ? "form-control form-control-sm"
                          : addNotesError === ""
                          ? "form-control form-control-sm success"
                          : "form-control form-control-sm error"
                      }
                      onChange={(e) => {
                        handleNoteChange(e.target.value);
                      }}
                    />
                    {addNotesError && (
                      <div
                        id="ContentPlaceHolder1_txtUserName-error"
                        class="invalid-feedback"
                        style={{ display: "Block" }}
                      >
                        {addNotesError}
                      </div>
                    )}
                  </div>
                  <div className="small text-secondary mt-1 ml-3">
                    <span id="privatenotetip" />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  data-dismiss="modal"
                  className="btn btn-xs btn-secondary"
                  type="button"
                  id="closenote"
                  onClick={clearStates}
                >
                  Cancel
                </button>
                {editAddNote ? (
                  <button
                    className="btn btn-xs btn-primary"
                    type="button"
                    id="savenote"
                    data-dismiss="modal"
                    onClick={(e) => {
                      updateAddressNote(e);
                    }}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    className="btn btn-xs btn-primary"
                    type="button"
                    id="savenote"
                    data-dismiss="modal"
                    onClick={(e) => {
                      addAddressNote(e);
                    }}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="myModalLabel"
          aria-hidden="true"
          id="myModaldelete"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header p-2">
                <h5 className="modal-title" id="myModalLabel">
                  Confirmation Required
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={clearStates}
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <p>
                  Are you sure you wish to remove the address tag?
                  <br />
                  <span className="text-secondary font-weight-normal" id="removeaddress" />
                </p>
              </div>
              <div className="modal-footer">
                <button
                  data-dismiss="modal"
                  className="btn btn-sm btn-secondary btn-xs"
                  type="button"
                  onClick={() => {
                    clearStates();
                  }}
                >
                  Close
                </button>
                <button
                  className="btn btn-sm btn-primary btn-xs"
                  type="button"
                  id="btnContinueRemove"
                  data-dismiss="modal"
                  onClick={() => {
                    deleteAddressNote();
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Mynotes_address;
