import React from "react";

import { OverlayTrigger, Popover } from "react-bootstrap";

const Type = ({ type }) => {
    const popoverTop18 = (
        <Popover id="popover-basic">
            <Popover.Body>
                Introduced in EIP2718, this defines a transaction type that is an envelope for current and future transaction types.
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <div className="row">
                <div className="col-md-3 mb-1 bs h6 text-blueish">
                    <OverlayTrigger style={{ padding: "100px 0" }} trigger="hover" placement="top" overlay={popoverTop18}>
                        <i class="fal fa-question-circle text-secondary mr-1" aria-hidden="true"></i>
                    </OverlayTrigger>{" "}
                    Txn Type:
                </div>
                <div className="col-md-4 mb-1 bs text-dark bs">{type}</div>
            </div>
            <hr />
        </>
    );
};

export default Type;
