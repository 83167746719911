import React, { useState, useEffect, useContext } from "react";
import Header2 from "../common/Header2";
import CryptoJS from "crypto-js";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { IgniteContext } from "../IgniteContext";
import { getLocalStorage } from "../../utils";

function TokenUpdateAppplication() {
    const { validateEmail, validateUrl } = useContext(IgniteContext);
    let { id } = useParams();
    const navigate = useNavigate();
    const isLogin = getLocalStorage("ua5s21san") && getLocalStorage("ea4sd4fam") && getLocalStorage("pn4sec3f1s") ? true : false;

    const [contractDetails, setContractDetails] = useState(null);
    const [contractaddress, setContractAddress] = useState(id ? id : "");

    const [requestType, setRequestType] = useState(1);

    const [requestComment, setRequestComment] = useState("");
    const [requestCommentErr, setRequestCommentErr] = useState(null);

    const [requesterName, setRequesterName] = useState("");
    const [requesterNameError, setRequesterNameError] = useState(null);

    const [requesterEmailAdd, setRequesterEmailAdd] = useState("");
    const [requesterEmailError, setRequesterEmailError] = useState(null);

    const [projectName, setProjectName] = useState("");
    const [projectnameError, setProjectnameError] = useState(null);

    const [projectWebsite, setProjectWebsite] = useState("");
    const [projectWebsiteError, setProjectWebsiteError] = useState(null);

    const [projectEmail, setProjectEmail] = useState("");
    const [projectEmailError, setProjectEmailError] = useState(null);

    const [logoLink, setLogoLink] = useState("");
    const [logolinkError, setLogolinkError] = useState(null);

    const [projectSectorType, setProjectSectorType] = useState("");
    const [projectsectorError, setProjectsectorError] = useState(null);

    const [projectDescription, setProjectDescription] = useState("");
    const [projectDecriptionError, setProjectDecriptionError] = useState(null);

    const [whitePaperLink, setWhitePaperLink] = useState("");
    const [mediumLink, setMediumLink] = useState("");
    const [githubLink, setGithubLink] = useState("");
    const [redditLink, setRedditLink] = useState("");
    const [telegramLink, setTelegramLink] = useState("");
    const [slackLink, setSlackLink] = useState("");
    const [weChatLink, setWeChatLink] = useState("");
    const [facebookLink, setfacebookLink] = useState("");
    const [linkedinLink, setLinkedinLink] = useState("");
    const [twitterLink, setTwitterLink] = useState("");
    const [discordLink, setDiscordLink] = useState("");
    const [bitcointalkLink, setBitcointalkLink] = useState("");
    const [ticketingLink, setTicketingLink] = useState("");

    const [coinMarketCapTicker, setCoinMarketCapTicker] = useState("");
    const [coinmarketcapError, setCoinmarketcapError] = useState(null);

    const [coinGeckoTicker, setCoinGeckoTicker] = useState("");
    const [coingeckoError, setCoingeckoError] = useState(null);

    const [submitError, setSubmitError] = useState(null);

    const [userAggrement, setUserAggrement] = useState(false);

    const [reCaptchaToken, setReCaptchaToken] = useState(false);
    const [reCaptchaTokenErr, setReCaptchaTokenErr] = useState("");

    useEffect(() => {
        const redirectState1 = JSON.parse(sessionStorage.getItem("a1BDc45Adfad"));
        const redirectState = JSON.parse(sessionStorage.getItem("a1BDc45Adfa"));
        if (!redirectState) {
            navigate(`/token/${id}`);
        }
    }, []);

    const verifyLogin = async () => {
        if (isLogin) {
            const username = CryptoJS.AES.decrypt(getLocalStorage("ua5s21san"), process.env.REACT_APP_PASSWORD).toString(CryptoJS.enc.Utf8);
            const password = CryptoJS.AES.decrypt(getLocalStorage("pn4sec3f1s"), process.env.REACT_APP_PASSWORD).toString(CryptoJS.enc.Utf8);
            const encBody = CryptoJS.AES.encrypt(
                JSON.stringify({
                    username: username.trim(),
                    password: password.trim(),
                    timestemp: Math.round(Date.now() / 1000),
                }),
                process.env.REACT_APP_PASSWORD
            ).toString();
            await axios
                .post(`${process.env.REACT_APP_BACKEND_URL}user/signin`, {
                    token: encBody,
                })
                .then((res) => {
                    if (res.data.error) {
                        window.location.assign("/login");
                    } else {
                        return true;
                    }
                })
                .catch((err) => {
                    console.log("error in userLogin (cathc) : ", err);
                });
        } else {
            window.location.assign("/login");
        }
    };
    useEffect(() => {
        verifyLogin();
    }, []);

    const getContractDetails = async () => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_URL}contract/${id}`)
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getContractDetails : ", res.data.error);
                    return navigate(-1);
                } else {
                    setContractDetails(res.data);
                    setContractAddress(res.data.address);
                    if (res.data.abiCode === null && res.data.sourceCode === null) {
                        navigate("/");
                    }
                }
            })
            .catch((err) => {
                console.log("error in getContractDetails (catch) : ", err);
                return navigate(-1);
            });
    };
    useEffect(() => {
        getContractDetails();
    }, []);

    const handlecomment = (val) => {
        setRequestComment(val);
        if (val === "") {
            setRequestCommentErr("Comment is required.");
        } else {
            setRequestCommentErr("");
        }
    };

    const handleRequestname = (val) => {
        setRequesterName(val);
        if (val === "") {
            setRequesterNameError("Request name is required.");
        } else {
            setRequesterNameError("");
        }
    };

    const handleRequestEmailadd = (val) => {
        setRequesterEmailAdd(val);
        if (val === "") {
            setRequesterEmailError("Requester email is required.");
        } else {
            if (!validateEmail(val)) {
                setRequesterEmailError("Invalid requester email.");
            } else {
                setRequesterEmailError("");
            }
        }
    };

    const handleprojectname = (val) => {
        setProjectName(val);
        if (val === "") {
            setProjectnameError("Project name is required.");
        } else {
            setProjectnameError("");
        }
    };

    const handleprojectwebiste = (val) => {
        setProjectWebsite(val);
        if (val === "") {
            setProjectWebsiteError("Project website is required.");
        } else {
            if (!validateUrl(val)) {
                setProjectWebsiteError("Invalid project website url.");
            } else {
                setProjectWebsiteError("");
            }
        }
    };

    const handleProjectEmail = (val) => {
        setProjectEmail(val);
        if (val === "") {
            setProjectEmailError("Project email is required.");
        } else {
            if (!validateEmail(val)) {
                setProjectEmailError("Invalid project email.");
            } else {
                setProjectEmailError("");
            }
        }
    };

    const handlelonglink = (val) => {
        setLogoLink(val);
        if (val === "") {
            setLogolinkError("LogoLink is required.");
        } else {
            if (!validateUrl(val)) {
                setLogolinkError("Invalid logoLink url.");
            } else {
                setLogolinkError("");
            }
        }
    };

    const handleprojectsector = (val) => {
        setProjectSectorType(val);
        if (val === "") {
            setProjectsectorError("Project sector is require.");
        } else {
            setProjectsectorError("");
        }
    };

    const handleprojectDecription = (val) => {
        setProjectDescription(val);
        if (val === "") {
            setProjectDecriptionError("Project decription is required.");
        } else {
            setProjectDecriptionError("");
        }
    };

    const handlesubmit = async () => {
        let temp = 1;
        if (requestComment === "") {
            setRequestCommentErr("Comment is required.");
            temp = 0;
        } else {
            setRequestCommentErr("");
        }
        if (requesterName === "") {
            setRequesterNameError("Request name is required.");
            temp = 0;
        } else {
            setRequesterNameError("");
        }
        if (requesterEmailAdd === "") {
            setRequesterEmailError("Email is required.");
            temp = 0;
        } else {
            if (!validateEmail(requesterEmailAdd)) {
                setRequesterEmailError("Invalid requester email.");
                temp = 0;
            } else {
                setRequesterEmailError("");
            }
        }
        if (projectName === "") {
            setProjectnameError("Project name is required.");
            temp = 0;
        } else {
            setProjectnameError("");
        }
        if (projectWebsite === "") {
            setProjectWebsiteError("Project website is required.");
            temp = 0;
        } else {
            if (!validateUrl(projectWebsite)) {
                setProjectWebsiteError("Invalid project website url.");
                temp = 0;
            } else {
                setProjectWebsiteError("");
            }
        }
        if (projectEmail === "") {
            setProjectEmailError("Project email is required.");
            temp = 0;
        } else {
            if (!validateEmail(projectEmail)) {
                setProjectEmailError("Invalid project email.");
                temp = 0;
            } else {
                setProjectEmailError("");
            }
        }
        if (logoLink === "") {
            setLogolinkError("LogoLink is required.");
            temp = 0;
        } else {
            if (!validateUrl(logoLink)) {
                setLogolinkError("Invalid logoLink url.");
                temp = 0;
            } else {
                setLogolinkError("");
            }
        }
        if (projectSectorType === "") {
            setProjectsectorError("Project sector is require.");
            temp = 0;
        } else {
            setProjectsectorError("");
        }
        if (projectDescription === "") {
            setProjectDecriptionError("Project decription is required.");
            temp = 0;
        } else {
            setProjectDecriptionError("");
        }
        if (
            whitePaperLink === "" &&
            mediumLink === "" &&
            ticketingLink === "" &&
            bitcointalkLink === "" &&
            discordLink === "" &&
            twitterLink === "" &&
            linkedinLink === "" &&
            facebookLink === "" &&
            weChatLink === "" &&
            slackLink === "" &&
            telegramLink === "" &&
            redditLink === "" &&
            githubLink === ""
        ) {
            setSubmitError("At least one social media link is required.");
            temp = 0;
        } else {
            setSubmitError("");
        }
        if (!reCaptchaToken) {
            setReCaptchaTokenErr("Invalid captcha.");
            temp = 0;
        } else {
            setReCaptchaTokenErr("");
        }
        if (temp) {
            await axios
                .post(`${process.env.REACT_APP_BACKEND_URL}tokenupdate`, {
                    request_type: Number(requestType),
                    comment_or_message: requestComment,
                    token_contract_adderss: contractaddress,
                    requester_name: requesterName,
                    requester_email_address: requesterEmailAdd,
                    project_name: projectName,
                    official_project_Website: projectWebsite,
                    official_project_email_address: projectEmail,
                    token_logo_url: logoLink,
                    project_sector: projectSectorType,
                    project_description: projectDescription,
                    social_whitepaper: whitePaperLink,
                    social_github: githubLink,
                    social_telegram: telegramLink,
                    social_wechat: weChatLink,
                    social_linkedin: linkedinLink,
                    social_discord: discordLink,
                    social_ticketing: ticketingLink,
                    social_medium: mediumLink,
                    social_reddit: redditLink,
                    social_slack: slackLink,
                    social_facebook: facebookLink,
                    social_twitter: twitterLink,
                    social_bitcointalk: bitcointalkLink,
                    coinmarketcap_ticker: coinMarketCapTicker,
                    coingecko_ticker: coinGeckoTicker,
                })
                .then((res) => {
                    if (res.data.error) {
                        setSubmitError(res.data.error);
                    } else {
                        if (res.data.success === true) {
                            sessionStorage.setItem("a1BDc45Adfad", JSON.stringify({ id: id, state: 55 }));
                            navigate("/token-verified-status");
                        }
                    }
                })
                .catch((err) => {
                    console.log("error in userLogin (cathc) : ", err);
                });
        }
    };

    const handlecoinmarket = (val) => {
        setCoinMarketCapTicker(val);
    };

    const hanldegetcoinmarket = (val) => {
        setCoinGeckoTicker(val);
    };

    const handleResetClick = (e) => {
        setRequestType("");
        setRequestComment("");
        setRequestCommentErr(null);
        setRequesterName("");
        setRequesterNameError(null);
        setRequesterEmailAdd("");
        setRequesterEmailError(null);
        setProjectName("");
        setProjectnameError(null);
        setProjectWebsite("");
        setProjectWebsiteError(null);
        setProjectEmail("");
        setProjectEmailError(null);
        setLogoLink("");
        setLogolinkError(null);
        setProjectSectorType("");
        setProjectsectorError(null);
        setProjectDescription("");
        setProjectDecriptionError(null);
        setWhitePaperLink("");
        setMediumLink("");
        setGithubLink("");
        setTelegramLink("");
        setSlackLink("");
        setWeChatLink("");
        setfacebookLink("");
        setLinkedinLink("");
        setTwitterLink("");
        setDiscordLink("");
        setBitcointalkLink("");
        setTicketingLink("");
        setCoinMarketCapTicker("");
        setCoinGeckoTicker("");
        setSubmitError("");
        setUserAggrement(false);
        setReCaptchaToken(false);
        setReCaptchaTokenErr("");
    };

    const handleReCaptcha = async (checkToken) => {
        await axios
            .post(`${process.env.REACT_APP_BACKEND_URL}captcha`, {
                token: checkToken,
                skey: process.env.REACT_APP_CAPTCHA_SECRET_KEY,
            })
            .then((res) => {
                if (!res.data.success) {
                    setReCaptchaToken(false);
                    setReCaptchaTokenErr("Invalid captcha.");
                } else {
                    setReCaptchaToken(res.data.success);
                    setReCaptchaTokenErr("");
                }
            })
            .catch((err) => {
                console.log("error in handleReCaptcha (catch) : ", err);
            });
    };

    return (
        <>
            <Header2 />
            <main id="content" role="main">
                <div class="container space-2">
                    <div class="text-center mb-4">
                        <h2 class="h3 mb-1">Token Update Application Form</h2>
                    </div>
                    <div class="card">
                        <div class="card-body p-4">
                            <div id="ContentPlaceHolder1_tokenupdate2div">
                                <div class="card card-pop-frame p-5-alt mb-4">
                                    <div class="row mx-gutters-md-1">
                                        <div class="col-md-5 mb-4 mb-md-0">
                                            <div class="sticky-top">
                                                <h2 class="h5 font-weight-bold">Request Type</h2>
                                                <p>Please specify the category of your token update application.</p>
                                            </div>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="js-form-message form-group mb-4">
                                                <label>
                                                    Choose one <span class="text-danger">*</span>
                                                </label>
                                                <select
                                                    name="ctl00$ContentPlaceHolder1$ddlFormContactPurpose"
                                                    onChange={(e) => {
                                                        setRequestType(e.currentTarget.value);
                                                    }}
                                                    id="ContentPlaceHolder1_ddlFormContactPurpose"
                                                    className="form-control "
                                                >
                                                    <option selected="selected" value={1}>
                                                        New/First Time Token Update
                                                    </option>
                                                    <option value={2}>Existing Token Info Update</option>
                                                    <option value={3}>Token/Contract Migration</option>
                                                </select>
                                                <span
                                                    id="ContentPlaceHolder1_rfvFormContactPurpose"
                                                    class="text-danger small"
                                                    style={{ display: "none" }}
                                                >
                                                    {" "}
                                                    **Required
                                                </span>
                                            </div>
                                            <div class="js-form-message form-group">
                                                <label>
                                                    Comment/Message <span class="text-danger">*</span>
                                                </label>
                                                <textarea
                                                    name="ctl00$ContentPlaceHolder1$txtFormComment"
                                                    rows="3"
                                                    value={requestComment}
                                                    cols="20"
                                                    id="ContentPlaceHolder1_txtFormComment"
                                                    class="form-control"
                                                    onChange={(e) => handlecomment(e.currentTarget.value)}
                                                    autocomplete="off"
                                                    autocorrect="off"
                                                    autocapitalize="off"
                                                    spellcheck="false"
                                                    className={
                                                        requestCommentErr === null
                                                            ? "form-control form-control-sm"
                                                            : requestCommentErr === ""
                                                            ? "form-control form-control-sm success"
                                                            : "form-control form-control-sm error"
                                                    }
                                                ></textarea>
                                                {requestCommentErr && (
                                                    <div
                                                        id="ContentPlaceHolder1_txtUserName-error"
                                                        class="invalid-feedback"
                                                        style={{ display: "Block" }}
                                                    >
                                                        {requestCommentErr}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card card-pop-frame p-5-alt mb-4">
                                    <div class="row mx-gutters-md-1">
                                        <div class="col-md-5 mb-4 mb-md-0">
                                            <div class="sticky-top">
                                                <h2 class="h5 font-weight-bold">Basic Information</h2>
                                                <p>Kindly check that all the links provided are working and are safe to visit before submitting.</p>
                                            </div>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="js-form-message form-group mb-4">
                                                <label>
                                                    Token Contract Address <span class="text-danger">*</span>
                                                </label>
                                                <input
                                                    name="ctl00$ContentPlaceHolder1$txtFormUpdateContractAddress"
                                                    type="text"
                                                    value={contractaddress}
                                                    maxlength="42"
                                                    id="ContentPlaceHolder1_txtFormUpdateContractAddress"
                                                    class="form-control"
                                                    disabled
                                                    placeholder="(0x...)"
                                                    aria-label="Contract address"
                                                    aria-describedby="contractAddressAddon"
                                                    required
                                                    data-msg="Contract address is required"
                                                    data-error-class="u-has-error"
                                                    data-success-class="u-has-success"
                                                />
                                            </div>
                                            <div class={requesterNameError ? "js-form-message form-group u-has-error" : "js-form-message form-group"}>
                                                <label>
                                                    Requester Name <span class="text-danger">*</span>
                                                </label>
                                                <input
                                                    name="ctl00$ContentPlaceHolder1$txtFormName"
                                                    type="text"
                                                    value={requesterName}
                                                    id="ContentPlaceHolder1_txtFormName"
                                                    class="form-control"
                                                    onChange={(e) => handleRequestname(e.currentTarget.value)}
                                                    aria-label="Requester Name"
                                                    aria-describedby="requesterNameAddon"
                                                    data-msg="Requester name is required"
                                                    data-error-class="u-has-error"
                                                    data-success-class="u-has-success"
                                                    className={
                                                        requesterNameError === null
                                                            ? "form-control form-control-sm"
                                                            : requesterNameError === ""
                                                            ? "form-control form-control-sm success"
                                                            : "form-control form-control-sm error"
                                                    }
                                                />
                                                {requesterNameError && (
                                                    <div
                                                        id="ContentPlaceHolder1_txtUserName-error"
                                                        class="invalid-feedback"
                                                        style={{ display: "Block" }}
                                                    >
                                                        {requesterNameError}
                                                    </div>
                                                )}
                                            </div>
                                            <div
                                                class={requesterEmailError ? "js-form-message form-group u-has-error" : "js-form-message form-group"}
                                            >
                                                <label>
                                                    Requester Email Address <span class="text-danger">*</span>
                                                </label>
                                                <input
                                                    name="ctl00$ContentPlaceHolder1$txtFormEmail"
                                                    id="ContentPlaceHolder1_txtFormEmail"
                                                    value={requesterEmailAdd}
                                                    aria-label="Requester Email Address"
                                                    onChange={(e) => handleRequestEmailadd(e.currentTarget.value)}
                                                    aria-describedby="requesterEmailAddressAddon"
                                                    data-msg="Please enter a valid email address"
                                                    data-error-class="u-has-error"
                                                    data-success-class="u-has-success"
                                                    type="email"
                                                    className={
                                                        requesterEmailError === null
                                                            ? "form-control form-control-sm"
                                                            : requesterEmailError === ""
                                                            ? "form-control form-control-sm success"
                                                            : "form-control form-control-sm error"
                                                    }
                                                />
                                                {requesterEmailError && (
                                                    <div
                                                        id="ContentPlaceHolder1_txtUserName-error"
                                                        class="invalid-feedback"
                                                        style={{ display: "Block" }}
                                                    >
                                                        {requesterEmailError}
                                                    </div>
                                                )}
                                                <span class="form-text text-secondary font-size-1">
                                                    Please make sure that you provide an email account of the project’s official domain. If the
                                                    contact email is using public domains (eg. Gmail) do publish on the website for
                                                    acknowledgement/verification.
                                                </span>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="js-form-message form-group mb-4">
                                                        <label>
                                                            Project Name <span class="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            name="ctl00$ContentPlaceHolder1$txtFormProjectName"
                                                            type="text"
                                                            value={projectName}
                                                            onChange={(e) => handleprojectname(e.currentTarget.value)}
                                                            id="ContentPlaceHolder1_txtFormProjectName"
                                                            className={
                                                                projectnameError === null
                                                                    ? "form-control form-control-sm"
                                                                    : projectnameError === ""
                                                                    ? "form-control form-control-sm success"
                                                                    : "form-control form-control-sm error"
                                                            }
                                                        />
                                                        {projectnameError && (
                                                            <div
                                                                id="ContentPlaceHolder1_txtUserName-error"
                                                                class="invalid-feedback"
                                                                style={{ display: "Block" }}
                                                            >
                                                                {projectnameError}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div
                                                        class={
                                                            projectWebsiteError
                                                                ? "js-form-message form-group u-has-error"
                                                                : "js-form-message form-group"
                                                        }
                                                    >
                                                        <label>
                                                            Official Project Website <span class="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            name="ctl00$ContentPlaceHolder1$txtFormProjectWebsite"
                                                            type="text"
                                                            value={projectWebsite}
                                                            onChange={(e) => handleprojectwebiste(e.currentTarget.value)}
                                                            id="ContentPlaceHolder1_txtFormProjectWebsite"
                                                            className={
                                                                projectWebsiteError === null
                                                                    ? "form-control form-control-sm"
                                                                    : projectWebsiteError === ""
                                                                    ? "form-control form-control-sm success"
                                                                    : "form-control form-control-sm error"
                                                            }
                                                            aria-label="Project Website"
                                                            aria-describedby="projectWebsiteAddon"
                                                            data-msg="Project website is required"
                                                            data-error-class="u-has-error"
                                                            data-success-class="u-has-success"
                                                        />
                                                        {projectWebsiteError && (
                                                            <div
                                                                id="ContentPlaceHolder1_txtUserName-error"
                                                                class="invalid-feedback"
                                                                style={{ display: "Block" }}
                                                            >
                                                                {projectWebsiteError}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class={projectEmailError ? "js-form-message form-group u-has-error" : "js-form-message form-group"}>
                                                <label>
                                                    Official Project Email Address <span class="text-danger">*</span>
                                                </label>
                                                <input
                                                    name="ctl00$ContentPlaceHolder1$txtFormProjectEmail"
                                                    id="ContentPlaceHolder1_txtFormProjectEmail"
                                                    value={projectEmail}
                                                    onChange={(e) => handleProjectEmail(e.currentTarget.value)}
                                                    aria-label="Project Email Address"
                                                    aria-describedby="projectEmailAddressAddon"
                                                    data-msg="Project email address is required"
                                                    data-error-class="u-has-error"
                                                    data-success-class="u-has-success"
                                                    type="email"
                                                    className={
                                                        projectEmailError === null
                                                            ? "form-control form-control-sm"
                                                            : projectEmailError === ""
                                                            ? "form-control form-control-sm success"
                                                            : "form-control form-control-sm error"
                                                    }
                                                />
                                                {projectEmailError && (
                                                    <div
                                                        id="ContentPlaceHolder1_txtUserName-error"
                                                        class="invalid-feedback"
                                                        style={{ display: "Block" }}
                                                    >
                                                        {projectEmailError}
                                                    </div>
                                                )}
                                                <span class="form-text text-secondary font-size-1">
                                                    Please make sure the email provided has the project official domain as its suffix.
                                                </span>
                                            </div>
                                            <div class={logolinkError ? "js-form-message form-group u-has-error" : "js-form-message form-group"}>
                                                <label>
                                                    Link to download a 32x32 png icon logo <span class="text-danger">*</span>
                                                </label>
                                                <input
                                                    name="ctl00$ContentPlaceHolder1$txtFormIconLogo"
                                                    type="text"
                                                    id="ContentPlaceHolder1_txtFormIconLogo"
                                                    value={logoLink}
                                                    onChange={(e) => handlelonglink(e.target.value)}
                                                    aria-label="Icon Logo"
                                                    aria-describedby="IconLogoAddon"
                                                    data-msg="Link to download a 32x32 png icon logo is required"
                                                    data-error-class="u-has-error"
                                                    data-success-class="u-has-success"
                                                    className={
                                                        logolinkError === null
                                                            ? "form-control form-control-sm"
                                                            : logolinkError === ""
                                                            ? "form-control form-control-sm success"
                                                            : "form-control form-control-sm error"
                                                    }
                                                />
                                                {logolinkError && (
                                                    <div
                                                        id="ContentPlaceHolder1_txtUserName-error"
                                                        class="invalid-feedback"
                                                        style={{ display: "Block" }}
                                                    >
                                                        {logolinkError}
                                                    </div>
                                                )}
                                                <span class="form-text text-secondary font-size-1">
                                                    Image should be a PNG file with a resolution of 32x32
                                                </span>
                                            </div>
                                            <div class="js-form-message form-group mb-4">
                                                <label>
                                                    Project Sector <span class="text-danger">*</span>
                                                </label>
                                                <input
                                                    name="ctl00$ContentPlaceHolder1$txtFormProjectSector"
                                                    type="text"
                                                    value={projectSectorType}
                                                    id="ContentPlaceHolder1_txtFormProjectSector"
                                                    onChange={(e) => handleprojectsector(e.currentTarget.value)}
                                                    className={
                                                        projectsectorError === null
                                                            ? "form-control form-control-sm"
                                                            : projectsectorError === ""
                                                            ? "form-control form-control-sm success"
                                                            : "form-control form-control-sm error"
                                                    }
                                                />
                                                {projectsectorError && (
                                                    <div
                                                        id="ContentPlaceHolder1_txtUserName-error"
                                                        class="invalid-feedback"
                                                        style={{ display: "Block" }}
                                                    >
                                                        {projectsectorError}
                                                    </div>
                                                )}
                                                <span class="form-text text-secondary font-size-1">
                                                    Please specify the industry/field that the project is a part of
                                                </span>
                                            </div>
                                            <div
                                                class={
                                                    projectDecriptionError ? "js-form-message form-group u-has-error" : "js-form-message form-group"
                                                }
                                            >
                                                <label>
                                                    Project Description (Max 300 characters)&nbsp;
                                                    <span class="text-danger">*</span>
                                                </label>
                                                <textarea
                                                    name="ctl00$ContentPlaceHolder1$txtFormProjectDesc"
                                                    rows="3"
                                                    cols="20"
                                                    id="ContentPlaceHolder1_txtFormProjectDesc"
                                                    className={
                                                        projectDecriptionError === null
                                                            ? "form-control form-control-sm"
                                                            : projectDecriptionError === ""
                                                            ? "form-control form-control-sm success"
                                                            : "form-control form-control-sm error"
                                                    }
                                                    onChange={(e) => handleprojectDecription(e.currentTarget.value)}
                                                    autocomplete="off"
                                                    autocorrect="off"
                                                    autocapitalize="off"
                                                    spellcheck="false"
                                                    data-msg="Project description is required"
                                                    data-error-class="u-has-error"
                                                    data-success-class="u-has-success"
                                                ></textarea>
                                                {projectDecriptionError && (
                                                    <div
                                                        id="ContentPlaceHolder1_txtUserName-error"
                                                        class="invalid-feedback"
                                                        style={{ display: "Block" }}
                                                    >
                                                        {projectDecriptionError}
                                                    </div>
                                                )}
                                                <span class="form-text text-secondary font-size-1">
                                                    Briefly encapsulate, introduce or summarise the project’s operation/mechanism/goals in a maximum
                                                    of 300 characters. The short description should be written in a neutral point of view and must
                                                    exclude unsubstantiated claims ("first", "most", "best", and etc) unless proven otherwise.
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card card-pop-frame p-5-alt mb-4">
                                    <div class="row mx-gutters-md-1">
                                        <div class="col-md-5 mb-4 mb-md-0">
                                            <div class="sticky-top">
                                                <h2 class="h5 font-weight-bold">Social Profiles</h2>
                                                <p>
                                                    Kindly provide us with the links to your official social media pages on these platforms. These are
                                                    usually the secondary channels which users may use to contact your team.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="js-form-message form-group mb-4">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text font-size-base">
                                                                    <i class="far fa-file-pdf"></i>
                                                                </span>
                                                            </div>
                                                            <input
                                                                name="ctl00$ContentPlaceHolder1$txtFormwhitepaperlink"
                                                                type="text"
                                                                id="ContentPlaceHolder1_txtFormwhitepaperlink"
                                                                class="form-control"
                                                                onChange={(e) => setWhitePaperLink(e.currentTarget.value)}
                                                                placeholder="Whitepaper"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="js-form-message form-group mb-4">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text font-size-base">
                                                                    <i class="fab fa-medium"></i>
                                                                </span>
                                                            </div>
                                                            <input
                                                                name="ctl00$ContentPlaceHolder1$txtFormmediumlink"
                                                                type="text"
                                                                id="ContentPlaceHolder1_txtFormmediumlink"
                                                                class="form-control"
                                                                onChange={(e) => setMediumLink(e.currentTarget.value)}
                                                                placeholder="Medium"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="js-form-message form-group mb-4">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text font-size-base">
                                                                    <i class="fab fa-github"></i>
                                                                </span>
                                                            </div>
                                                            <input
                                                                name="ctl00$ContentPlaceHolder1$txtFormgithublink"
                                                                type="text"
                                                                id="ContentPlaceHolder1_txtFormgithublink"
                                                                class="form-control"
                                                                onChange={(e) => setGithubLink(e.currentTarget.value)}
                                                                placeholder="GitHub"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="js-form-message form-group mb-4">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text font-size-base">
                                                                    <i class="fab fa-reddit"></i>
                                                                </span>
                                                            </div>
                                                            <input
                                                                name="ctl00$ContentPlaceHolder1$txtFormredditlink"
                                                                type="text"
                                                                id="ContentPlaceHolder1_txtFormredditlink"
                                                                class="form-control"
                                                                onChange={(e) => {
                                                                    setRedditLink(e.currentTarget.value);
                                                                }}
                                                                placeholder="Reddit"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="js-form-message form-group mb-4">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text font-size-base">
                                                                    <i class="fab fa-telegram"></i>
                                                                </span>
                                                            </div>
                                                            <input
                                                                name="ctl00$ContentPlaceHolder1$txtFormtelegramlink"
                                                                type="text"
                                                                id="ContentPlaceHolder1_txtFormtelegramlink"
                                                                class="form-control"
                                                                onChange={(e) => setTelegramLink(e.currentTarget.value)}
                                                                placeholder="Telegram"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="js-form-message form-group mb-4">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text font-size-base">
                                                                    <i class="fab fa-slack"></i>
                                                                </span>
                                                            </div>
                                                            <input
                                                                name="ctl00$ContentPlaceHolder1$txtFormslacklink"
                                                                type="text"
                                                                id="ContentPlaceHolder1_txtFormslacklink"
                                                                class="form-control"
                                                                onChange={(e) => setSlackLink(e.target.value)}
                                                                placeholder="Slack"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="js-form-message form-group mb-4">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text font-size-base">
                                                                    <i class="fab fa-weixin"></i>
                                                                </span>
                                                            </div>
                                                            <input
                                                                name="ctl00$ContentPlaceHolder1$txtFormwechatlink"
                                                                type="text"
                                                                id="ContentPlaceHolder1_txtFormwechatlink"
                                                                class="form-control"
                                                                onChange={(e) => setWeChatLink(e.target.value)}
                                                                placeholder="WeChat"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="js-form-message form-group mb-4">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text font-size-base">
                                                                    <i class="fab fa-facebook"></i>
                                                                </span>
                                                            </div>
                                                            <input
                                                                name="ctl00$ContentPlaceHolder1$txtFormfacebooklink"
                                                                type="text"
                                                                id="ContentPlaceHolder1_txtFormfacebooklink"
                                                                class="form-control"
                                                                onChange={(e) => setfacebookLink(e.currentTarget.value)}
                                                                placeholder="Facebook"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="js-form-message form-group mb-4">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text font-size-base">
                                                                    <i class="fab fa-linkedin"></i>
                                                                </span>
                                                            </div>
                                                            <input
                                                                name="ctl00$ContentPlaceHolder1$txtFormlinkedinlink"
                                                                type="text"
                                                                id="ContentPlaceHolder1_txtFormlinkedinlink"
                                                                class="form-control"
                                                                onChange={(e) => setLinkedinLink(e.currentTarget.value)}
                                                                placeholder="Linkedin"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="js-form-message form-group mb-4">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text font-size-base">
                                                                    <i class="fab fa-twitter"></i>
                                                                </span>
                                                            </div>
                                                            <input
                                                                name="ctl00$ContentPlaceHolder1$txtFormtwitterLink"
                                                                type="text"
                                                                id="ContentPlaceHolder1_txtFormtwitterLink"
                                                                class="form-control"
                                                                onChange={(e) => setTwitterLink(e.currentTarget.value)}
                                                                placeholder="Twitter"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="js-form-message form-group mb-4">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text font-size-base">
                                                                    <i class="fab fa-discord"></i>
                                                                </span>
                                                            </div>
                                                            <input
                                                                name="ctl00$ContentPlaceHolder1$txtFormdiscordlink"
                                                                type="text"
                                                                id="ContentPlaceHolder1_txtFormdiscordlink"
                                                                class="form-control"
                                                                onChange={(e) => setDiscordLink(e.currentTarget.value)}
                                                                placeholder="Discord"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="js-form-message form-group mb-4">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text font-size-base">
                                                                    <i class="fab fa-bitcoin"></i>
                                                                </span>
                                                            </div>
                                                            <input
                                                                name="ctl00$ContentPlaceHolder1$txtFormbitcointalklink"
                                                                type="text"
                                                                id="ContentPlaceHolder1_txtFormbitcointalklink"
                                                                class="form-control"
                                                                onChange={(e) => setBitcointalkLink(e.currentTarget.value)}
                                                                placeholder="Bitcointalk"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="js-form-message form-group mb-4">
                                                        <div class="input-group">
                                                            <div class="input-group-prepend">
                                                                <span class="input-group-text font-size-base">
                                                                    <i class="fas fa-user-tag"></i>
                                                                </span>
                                                            </div>
                                                            <input
                                                                name="ctl00$ContentPlaceHolder1$txtFormTicketing"
                                                                type="text"
                                                                id="ContentPlaceHolder1_txtFormTicketing"
                                                                class="form-control"
                                                                onChange={(e) => setTicketingLink(e.currentTarget.value)}
                                                                placeholder="Ticketing"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card card-pop-frame p-5-alt mb-4">
                                    <div class="row mx-gutters-md-1">
                                        <div class="col-md-5 mb-4 mb-md-0">
                                            <div class="sticky-top">
                                                <h2 class="h5 font-weight-bold">Price Data</h2>
                                                <p>
                                                    Kindly take note that the price data will be obtained through these service providers. By default,
                                                    price data will be provided by Coinmarketcap while exchanges with trading pairs will be provided
                                                    by Coingecko. If your token is not listed on either platform, its respective values will not be
                                                    shown.
                                                </p>
                                            </div>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="js-form-message form-group mb-4">
                                                <label>CoinMarketCap Ticker</label>
                                                <input
                                                    name="ctl00$ContentPlaceHolder1$txtFormcoinmarketcaplink"
                                                    type="text"
                                                    value={coinMarketCapTicker}
                                                    id="ContentPlaceHolder1_txtFormcoinmarketcaplink"
                                                    className="form-control form-control-sm"
                                                    onChange={(e) => handlecoinmarket(e.currentTarget.value)}
                                                    placeholder="https://coinmarketcap.com/currencies/binance/"
                                                />
                                            </div>
                                            <div class="js-form-message form-group mb-4">
                                                <label>CoinGecko Ticker</label>
                                                <input
                                                    name="ctl00$ContentPlaceHolder1$txtFormCoinGeckolink"
                                                    type="text"
                                                    id="ContentPlaceHolder1_txtFormCoinGeckolink"
                                                    className="form-control form-control-sm"
                                                    onChange={(e) => hanldegetcoinmarket(e.currentTarget.value)}
                                                    placeholder="https://www.coingecko.com/en/coins/binance"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="card card-pop-frame p-5-alt mb-4">
                                        <div class="row mx-gutters-md-1">
                                            <div class="col-md-5 mb-4 mb-md-0">
                                                <div class="sticky-top">
                                                    <h2 class="h5 font-weight-bold">Others</h2>
                                                    <p>Information pertaining to the project’s token sales (ICO/IEO) and/or burn history. Kindly provide links/sources where required.</p>
                                                </div>
                                            </div>
                                            <div class="col-md-7">
                                                <div class="js-form-message form-group mb-4">
                                                    <label>Public Sale (ICO/IEO) Details (if applicable)</label>
                                                    <textarea name="ctl00$ContentPlaceHolder1$txtTokenIcoInfo" rows="23" cols="20"  id="ContentPlaceHolder1_txtTokenIcoInfo" class="form-control" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" style={{whiteSpace: "pre-line"}}>
                                                        1. *Token Sale Address:;

                                                        2. *Token Sale Start Date:;

                                                        3. *Token Sale End Date:;

                                                        4. *Token Price (in USD and/or BNB):;

                                                        5. Public Sale Allocation:;

                                                        6. Public Sale Vesting Period:;

                                                        7. IEO Launchpad:;

                                                        8. Country:;

                                                        9. Soft Cap / Hard Cap (If any):;

                                                        10. Amount Raised:;

                                                        11. Token Distribution Date:;

                                                    </textarea>
                                                </div>
                                                <div class="js-form-message form-group mb-4">
                                                    <label>Private Sale Details (if applicable)</label>
                                                    <textarea name="ctl00$ContentPlaceHolder1$txtTokenPrivateSaleInfo" rows="13" cols="20"  id="ContentPlaceHolder1_txtTokenPrivateSaleInfo" class="form-control" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false">
                                                        1. Private Sale Token Price (in USD and/or BNB):;

                                                        2. Private Sale Allocation:;

                                                        3. Private Sale Vesting Period:; 

                                                        4. Seed Sale Token Price (in USD and/or BNB):;

                                                        5. Seed Sale Allocation:;

                                                        6. Seed Sale Vesting Period:;

                                                    </textarea>
                                                </div>
                                                <div class="js-form-message form-group mb-4">
                                                    <label>Burn Events (if applicable)</label>
                                                    <textarea name="ctl00$ContentPlaceHolder1$txtTokenBurnEvents" rows="10" cols="20"  id="ContentPlaceHolder1_txtTokenBurnEvents" class="form-control" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false">
                                                        1. Announcements Link:
                                                        Tx Hash:;

                                                        2. Announcements Link:
                                                        Tx Hash:;

                                                        3. Announcements Link:
                                                        Tx Hash:;

                                                    </textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                <div class="row my-4 px-2">
                                    <span id="ContentPlaceHolder1_lbrequiredfields4" class="denotescls">
                                        <i>* denotes required fields</i>
                                    </span>
                                </div>
                                <div class="bg-soft-secondary rounded p-3 mb-3">
                                    <div class="js-form-message custom-control custom-checkbox">
                                        <input
                                            name="ctl00$ContentPlaceHolder1$cbAgree"
                                            type="checkbox"
                                            id="ContentPlaceHolder1_cbAgree"
                                            class="custom-control-input"
                                            onChange={() => setUserAggrement(!userAggrement)}
                                            data-msg="Please check and agree"
                                            data-error-class="u-has-error"
                                            data-success-class="u-has-success"
                                        />

                                        <label class="custom-control-label" for="ContentPlaceHolder1_cbAgree">
                                            By clicking 'Submit', you are agreeing that you have provided all the details and information required for
                                            the token update process. Should any details or information submitted are found to be incomplete, false or
                                            fraudulent, we reserve the right to reject your token update request and we are not obliged to disclose or
                                            explain our reasons of rejection. If your submission is aptly completed, our team will get back to you
                                            with further instructions as soon as possible. Please do not submit duplicate submissions within this
                                            period of time.
                                        </label>
                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div className="col-12 d-flex flex-column align-items-center mb-3">
                                        <ReCAPTCHA
                                            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                                            onChange={(getmyToken) => {
                                                handleReCaptcha(getmyToken);
                                            }}
                                            onExpired={() => {
                                                setReCaptchaToken(false);
                                            }}
                                            tabIndex={3}
                                        />
                                        {reCaptchaTokenErr && (
                                            <div
                                                id="ContentPlaceHolder1_txtUserName-error"
                                                className="invalid-feedback f- text-center "
                                                style={{ display: "Block", fontSize: "14px" }}
                                            >
                                                {reCaptchaTokenErr}
                                            </div>
                                        )}
                                    </div>
                                    <div class="col-md-6 d-flex align-items-center mb-md-0 mb-3">
                                        {submitError && (
                                            <div className="alert alert-danger w-100 mb-0" role="alert">
                                                <i className="fa fa-exclamation-circle" aria-hidden="true"></i> {submitError}
                                            </div>
                                        )}
                                    </div>
                                    <div class="col-md-6 d-flex justify-content-md-end justify-content-center">
                                        <input
                                            type="reset"
                                            name="ctl00$ContentPlaceHolder1$btnReset2"
                                            onClick={handleResetClick}
                                            id="ContentPlaceHolder1_btnReset2"
                                            class="btn btn-soft-secondary mr-3"
                                        />
                                        <input
                                            type="button"
                                            name="ctl00$ContentPlaceHolder1$btnSubmit2"
                                            id="ContentPlaceHolder1_btnSubmit2"
                                            class="btn btn-primary btn-wide"
                                            value="Submit"
                                            // disabled={disableBtn}
                                            onClick={handlesubmit}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="modal fade" id="myModal11" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header p-2">
                                            <h5 class="pl-3 modal-title h6 font-weight-bold text-break w-100 mt-2 mb-2">Notice</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body ml-1">
                                            <p class="mt-1 mb-3 text-secondary">
                                                {process.env.REACT_APP_NAME}Scan aims to provide users with on-chain information and data extracted
                                                from the {process.env.REACT_APP_NAME}Scan blockchain in the most comprehensive and digestible way.
                                                Off-chain information such as the token information is submitted by users where{" "}
                                                {process.env.REACT_APP_NAME}Scan curates and updates onto the token/contract address upon verification
                                                of ownership.
                                            </p>
                                            <p class="mt-1 mb-3 text-secondary">
                                                This curation intends to provide end-users with verifiable project information about the
                                                token/contract address that they are interacting with.
                                            </p>
                                            <p class="mt-1 mb-3 text-secondary">
                                                As we receive a significant number of requests on a daily basis, we adopted a pre-filtering process
                                                that measures submissions against a number of variables and values before they are accepted.
                                            </p>
                                            <p class="mt-1 mb-3 text-secondary">
                                                Prior to sending in your submission, please check and confirm that the information submitted adheres
                                                to the items listed below. You may not receive a reply if ONE or more than one of the below is not
                                                satisfied.
                                            </p>
                                            <ol class="text-secondary">
                                                <li class="mb-2">The mandatory information (website, email and 32x32 PNG logo) are provided</li>
                                                <li class="mb-2">
                                                    Your email/contact email address is the same as your official website domain (i.e., [youremail
                                                    <i class="fal fa-at"></i>yourexample]
                                                </li>
                                                <li class="mb-2">
                                                    Your official website:
                                                    <ul>
                                                        <li>is accessible and safe to visit </li>
                                                        <li>has all its links working fine and its placeholders updated</li>
                                                        <li>has clear and sufficient information about your project or token</li>
                                                    </ul>
                                                </li>
                                                <li class="mb-2">
                                                    The contract:
                                                    <ul>
                                                        <li>adheres to {process.env.REACT_APP_TICKER}-20 specifications</li>
                                                        <li>is valid and it is the right contract address for the project/token</li>
                                                    </ul>
                                                </li>
                                                <li>
                                                    The project/token:
                                                    <ul>
                                                        <li>name, symbol or creative properties are not susceptible to brand infringement</li>
                                                        <li>is not fraudulent or misrepresentation of public entities or other projects</li>
                                                        <li>is using/deployed on the {process.env.REACT_APP_NAME}Scan blockchain</li>
                                                        <li>
                                                            team members and/or founders are clearly presented with links to their supporting
                                                            professional profiles (e.g. LinkedIn or equivalent)
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ol>
                                            <p class="mt-1 mb-3 text-secondary">
                                                Please take note that the requirements above are not a checklist that guarantees your submission to be
                                                listed and at any time we found false information or discrepancies, we reserve the rights to remove or
                                                update information.
                                            </p>
                                        </div>
                                        <div class="modal-footer">
                                            <button
                                                data-dismiss="modal"
                                                class="btn btn-sm btn-secondary btn-xs cancel"
                                                type="button"
                                                causesvalidation="False"
                                            >
                                                OK
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default TokenUpdateAppplication;
