import React from "react";
import Web3 from "web3";

import { OverlayTrigger, Popover } from "react-bootstrap";

const Value = ({ value = 0, rate = 3 }) => {
    const popoverTop5 = (
        <Popover id="popover-basic">
            {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
            <Popover.Body>
                The value being transacted in {process.env.REACT_APP_TICKER} and fiat value. Note: You can click the fiat value (if available) to see
                historical value at the time of transaction.
            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <div className="row">
                <div className="col-md-3 mb-1 bs h6 text-blueish">
                    <OverlayTrigger style={{ padding: "100px 0" }} trigger="hover" placement="top" overlay={popoverTop5}>
                        <i class="fal fa-question-circle text-secondary mr-1" aria-hidden="true"></i>
                    </OverlayTrigger>{" "}
                    Value
                </div>
                <div className="col-md-4 mb-1 bs text-dark bs">
                    <span class="u-label u-label--value u-label--primary rounded my-1">
                        {" "}
                        {value ? Web3.utils.fromWei(value.toLocaleString("fullwide", { useGrouping: false }), "ether") : 0}{" "}
                        <span>{process.env.REACT_APP_TICKER}</span>{" "}
                    </span>
                    &nbsp; ($
                    {(
                        Number(process.env.REACT_APP_PRICE) * Number(value ? Web3.utils.fromWei(value.toLocaleString("fullwide", { useGrouping: false }), "ether") : 0)
                    ).toFixed(8)}
                    )
                </div>
            </div>
            <hr />
        </>
    );
};

export default Value;
