import React, { useState } from "react";
import { Link } from "react-router-dom";

const Developers = () => {
  const pathName = window.location.href;
  const last = pathName.substring(pathName.lastIndexOf("/") + 1, pathName.length);

  const resources = ["charts"];
  const findPathResources = resources.find((e) => e === last);

  const [isHoverResources1, setIsHoverResources1] = useState(false);
  const [isHoverResources2, setIsHoverResources2] = useState(false);

  return (
    <li
      id="LI_resources"
      className={
        last === findPathResources
          ? `nav-item hs-has-sub-menu u-header__nav-item active ${
              isHoverResources1 ? "hs-sub-menu-opened" : ""
            }`
          : `nav-item hs-has-sub-menu u-header__nav-item ${isHoverResources2 ? "hs-sub-menu-opened" : ""}`
      }
      onMouseEnter={() => {
        setIsHoverResources1(true);
        setIsHoverResources2(true);
      }}
      onMouseLeave={() => {
        setIsHoverResources1(false);
        setIsHoverResources2(false);
      }}
      data-event="hover"
      data-animation-in="slideInUp"
      data-animation-out="fadeOut"
    >
      {/* eslint-disable-next-line */}
      <a
        id="resourcesMegaMenu"
        className="nav-link u-header__nav-link u-header__nav-link-toggle"
        href="javascript:void(0);"
        aria-haspopup="true"
        aria-expanded="false"
        aria-labelledby="resourcesSubMenu"
      >
        Developers
      </a>
      <ul
        id="resourcesSubMenu"
        className={`hs-sub-menu u-header__sub-menu u-header__sub-menu--spacer ${
          isHoverResources1 ? "fadeOut custom-drop-hover-none" : "slideInUp custom-drop-hover"
        }`}
        aria-labelledby="resourcesMegaMenu"
      >
        {/* <li id="LI_charts2">
          <Link className="nav-link u-header__sub-menu-nav-link" to="#">
            Charts &amp; Stats
          </Link>
        </li> */}
        <li id="LI20" className={`${pathName === "/verifyContract" ? "text-primary active" : ""}`}>
          <Link className="nav-link u-header__sub-menu-nav-link" to="/verifyContract">
            Verify Contract
          </Link>
        </li>
      </ul>
    </li>
  );
};

export default Developers;
