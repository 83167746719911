import { CopyToClipboard } from "react-copy-to-clipboard";
import delay from "delay";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Copyicon from "../../common/Copy/Copyicon";
import Copymsg from "../../common/Copy/Copymsg";
import { OverlayTrigger, Tooltip, Popover } from "react-bootstrap";

const To = ({ to, internalTx, toNameTag }) => {
    const popoverTop14 = (
        <Popover id="popover-basic">
            <Popover.Body>The receiving party of the transaction (could be a contract address).</Popover.Body>
        </Popover>
    );

    const [getCopyTo, setCopyto] = useState("");

    async function copyto() {
        setCopyto("copied");
        await delay(1000);
        setCopyto("");
    }

    return (
        <>
            <div className="row">
                <div className="col-md-3 mb-1 bs h6 text-blueish">
                    <OverlayTrigger style={{ padding: "100px 0" }} trigger="hover" placement="top" overlay={popoverTop14}>
                        <i class="fal fa-question-circle text-secondary mr-1" aria-hidden="true"></i>
                    </OverlayTrigger>
                    To
                </div>
                <div className="col-md-6 mb-1 bs text-blue">
                    {to?.isContract && !to?.contractcreation && (
                        <span>
                            <i class="far fa-file-alt text-secondary" aria-hidden="true"></i>
                        </span>
                    )}
                    {to?.contractcreation ? (
                        <>
                            [Contract&nbsp;
                            <Link to={`/address/${to?.address}`} className="text-blue">
                                {to?.address}
                            </Link>
                            &nbsp;Created]
                        </>
                    ) : typeof to !== "string" ? (
                        <Link to={`/address/${to?.address}`} className="text-blue">
                            {" "}
                            {to?.address}
                        </Link>
                    ) : (
                        <Link to={`/address/${to}`} className="text-blue">
                            {" "}
                            {to}
                        </Link>
                    )}
                    &nbsp;
                    {toNameTag ? (
                        <span
                            className="hash-tag text-truncate u-label u-label--value u-label--secondary"
                            data-toggle="tooltip"
                            title
                            data-original-title="My Name Tag: hld name
"
                        >
                            {toNameTag}
                        </span>
                    ) : (
                        ""
                    )}
                    <CopyToClipboard
                        text={to?.address}
                        onCopy={(e) => {
                            copyto();
                        }}
                    >
                        <span>{getCopyTo === "" ? <Copyicon /> : <Copymsg />} </span>
                    </CopyToClipboard>
                    <ul className="list-unstyled mb-0" id="wrapperContent">
                        {internalTx && internalTx.length
                            ? internalTx
                                  .filter((item) => item.isSimple)
                                  .map((item, index) => {
                                      return (
                                          <li className="media align-items-baseline">
                                              <div className="small mb-1">
                                                  <img src="/asset/images/shape-1.svg" className="mt-n1 ml-1" width={8} />
                                                  &nbsp;<span className="text-secondary">TRANSFER</span> &nbsp;{item.value}{" "}
                                                  {process.env.REACT_APP_TICKER}
                                                  &nbsp;
                                                  <span className="text-secondary">From</span>{" "}
                                                  <OverlayTrigger placement="top" trigger="hover" overlay={<Tooltip>{item.from}</Tooltip>}>
                                                      <Link to={`/address/${item.from}`} className="hash-tag text-truncate">
                                                          <span className="hash-tag text-truncate hash-tag-custom-from-int tooltip-address">
                                                              {item.from}
                                                          </span>
                                                      </Link>
                                                  </OverlayTrigger>
                                                  <span className="text-secondary">To</span>{" "}
                                                  <i className="fa fa-long-arrow-alt-right text-secondary" />{" "}
                                                  <OverlayTrigger placement="top" trigger="hover" overlay={<Tooltip>{item.to}</Tooltip>}>
                                                      <Link to={`/address/${item.to}`} className="hash-tag text-truncate">
                                                          <span className="hash-tag text-truncate tooltip-address">{item.to}</span>
                                                      </Link>
                                                  </OverlayTrigger>
                                              </div>
                                          </li>
                                      );
                                  })
                            : ""}
                        {/* {simpleTransaciton &&
                            simpleTransaciton.map((item, index) => {
                                return (
                                    <li key={index} className="media align-items-baseline">
                                        <div className="small mb-1">
                                            <img src="https://etherscan.io/images/svg/shapes/shape-1.svg?v=0.01" className="mt-n1 ml-1" width={8} />
                                            &nbsp; <span className="text-secondary">TRANSFER</span> &nbsp;{item.value} {process.env.REACT_APP_TICKER}{" "}
                                            <span className="text-secondary pr-1">From</span>
                                            <a href={`/address/${item.from}`} className="hash-tag text-truncate">
                                                <span
                                                    className="hash-tag text-truncate hash-tag-custom-from-int tooltip-address"
                                                    data-toggle="tooltip"
                                                    title
                                                    data-original-title={item.from}
                                                >
                                                    {item.from}
                                                </span>
                                            </a>
                                            <span className="text-secondary pr-1">To</span>
                                            <i className="fa fa-long-arrow-alt-right text-secondary" />
                                            <a href={`/address/${item.to}`} className="hash-tag text-truncate">
                                                <span
                                                    className="hash-tag text-truncate hash-tag-custom-to-int tooltip-address"
                                                    data-toggle="tooltip"
                                                    title
                                                    data-original-title={item.to}
                                                >
                                                    {item.to}
                                                </span>
                                            </a>
                                        </div>
                                    </li>
                                );
                            })} */}
                    </ul>
                </div>
            </div>
            <hr />
        </>
    );
};

export default To;
