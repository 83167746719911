import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./components/home/Home";
import ErrorPage from "./components/common/Error404/ErrorPage";
import BlockDetails from "./components/BlockDetails/BlockDetails";
import Address from "./components/address/Address";
import TransactionDetails from "./components/txnDetails/TransactionDetails";
import BlockChain from "./components/accounts/BlockChain";
import AllBlocks from "./components/blocks/AllBlocks";
import ContractsVerified from "./components/ContractsVerified/ContractsVerified";
import TokenDetails from "./components/tokenDetails/TokenDetails";
import Tokens from "./components/tokens/Tokens";
import Validators from "./components/Validators/validators";
import PendingTransactions from "./components/PendingTransactions/PendingTransactions";
import TransactionsType from "./components/AllTransactions/TransactionsType";
import Login from "./components/login&register/Login";
import Register from "./components/login&register/Register";
import LostPassword from "./components/login&register/LostPassword";
import Erc20Tokens from "./components/tokens/Erc20Tokens";
import Erc721Tokens from "./components/tokens/Erc721Tokens";
import Erc1155Tokens from "./components/tokens/Erc1155Tokens";
import TokenHolding from "./components/tokenHolders/TokenHolding";
import ViewErc20Transfer from "./components/tokens/ViewErc20Transfer";
import ViewErc1155TokenTransfer from "./components/tokens/ViewErc1155TokenTransfer";
import ViewErc721TokenTransfer from "./components/tokens/ViewErc721TokenTransfer";
import Contractlicense from "./components/contractLicence/Contractlicense";
import ContractInternalTransaction from "./components/contractInternalTransaction/ContractInternalTransaction";
import VerifyContract from "./components/ContractVerification/Verifycontract";
import VerifyData from "./components/ContractVerification/VerifyData";
import UnitConverter from "./components/unitConverter/UnitConverter";
import Myprofile from "./components/useraccount/Myprofile";
import Mynotes_address from "./components/useraccount/Mynotes_address";
import Mynotes from "./components/useraccount/Mynotes";
// import MyTokenList from "./components/useraccount/MyTokenList";
import TokenUpdateForm from "./components/VerifyToeknDetails/Tokenupdateform";
import TokenUpdateAppplication from "./components/VerifyToeknDetails/Tokenupdateapplication";
import TokenVerifyStatus from "./components/VerifyToeknDetails/Tokenverifystatus";
import VerifyAddressOwnership2 from "./components/VerifyToeknDetails/VerifyAddressownership2";
import VerifyRegisteredUser from "./components/common/VerifyRegisteredUser";
import ForgotPassword from "./components/login&register/ForgotPassword";
import ContactUs from "./components/contactus/ContactUs";
import BlockCountDown from "./components/BlockDetails/BlockCountDown";
import BugBounty from "./components/DocsPages/BugBounty";
import AboutUs from "./components/DocsPages/AboutUs";
import TermsOfService from "./components/DocsPages/TermsOfService";
import BrandAssets from "./components/DocsPages/BrandAssets";
import Careers from "./components/DocsPages/Careers";
import Newsletters from "./components/DocsPages/Newsletters";
import GetBalances from "./components/GetBalances/GetBalances";

function App() {
  return (
    <Routes>
      {/* normal routes */}
      <Route path="/" element={<Home />} />
      <Route path="/accounts" element={<BlockChain />} />
      <Route path="/txs" element={<TransactionsType />} />
      <Route path="/txsPending" element={<PendingTransactions />} />
      <Route path="/txsInternal" element={<ContractInternalTransaction />} />
      <Route exact path="/contactus" key="unitConverter1" element={<ContactUs />} />
      <Route path="/blocks" element={<AllBlocks />} />
      <Route path="/contractsVerified" element={<ContractsVerified />} />
      <Route path="/validators" element={<Validators />} />
      {process.env.REACT_APP_LOGIN === "true" || process.env.REACT_APP_LOGIN === true ? (
        <>
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route exact path="/loastpassword" key="forgotPassword" element={<ForgotPassword />} />
          <Route path="/myaccount" element={<Myprofile />} />
          <Route path="/mynotes_tx" element={<Mynotes />} />
          <Route path="/mynotes_address" element={<Mynotes_address />} />
          {/* <Route path="/MytokenList" element={<MyTokenList />} /> */}
        </>
      ) : (
        ""
      )}
      <Route path="/verifyContract" element={<VerifyContract />} />
      <Route path="/verifyContract-solc" element={<VerifyData />} />

      <Route path="/tokens" element={<Erc20Tokens />} />
      <Route path="/tokentxns" element={<ViewErc20Transfer />} />
      <Route path="/tokens-nft" element={<Erc721Tokens />} />
      <Route path="/tokentxns-nft" element={<ViewErc721TokenTransfer />} />
      <Route path="/tokens-nft1155" element={<Erc1155Tokens />} />
      <Route path="/tokentxns-nft1155" element={<ViewErc1155TokenTransfer />} />

      {/* <Route path="/lostpassword" element={<LostPassword />} /> */}
      <Route path="/tokenholding" element={<TokenHolding />} />
      <Route path="/contract-license-types" element={<Contractlicense />} />
      <Route exact path="/unitconverter" key="countdown" element={<UnitConverter />} />

      {/* token verification details */}
      <Route path="/tokenupdate/:id" element={<TokenUpdateForm />} />
      <Route path="/verifyAddress/:id" element={<VerifyAddressOwnership2 />} />
      <Route path="/tokenUpdateApplication/:id" element={<TokenUpdateAppplication />} />
      <Route path="/token-verified-status" element={<TokenVerifyStatus />} />

      {/* routes with parameters */}
      <Route path="/block/:id" element={<BlockDetails />} />
      <Route path="/address/:id" element={<Address />} />
      <Route path="/tx/:id" element={<TransactionDetails />} />
      <Route path="/token/:id" element={<TokenDetails />} />
      {/* <Route path="/txsInternal/:id" element={<ContractInternalTransaction />} /> */}
      <Route exact path="/registrationCheck/:id" key="checkRegister" element={<VerifyRegisteredUser />} />
      <Route exact path="/block/countdown/:id" key="countdown" element={<BlockCountDown />} />

      {/* docs pages */}
      <Route exact path="/bugbounty" key="countdown" element={<BugBounty />} />
      <Route exact path="/aboutus" key="countdown" element={<AboutUs />} />
      <Route exact path="/terms" key="countdown" element={<TermsOfService />} />
      <Route exact path="/brandassets" key="countdown" element={<BrandAssets />} />
      <Route exact path="/careers" key="countdown" element={<Careers />} />
      <Route exact path="/newsletters" key="countdown" element={<Newsletters />} />
      <Route exact path="/balance" key="countdown" element={<GetBalances />} />

      {/* page not found. */}
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

export default App;
