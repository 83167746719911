import React, { useContext } from "react";
import Header2 from "../common/Header2";
import Footer from "../common/Footer";
import { IgniteContext } from "../IgniteContext";

const TermsOfService = () => {
  const { theme } = useContext(IgniteContext);
  return (
    <>
      <Header2 />
      <main id="content" role="main">
        <div className="d-lg-flex">
          <div className="container d-lg-flex align-items-lg-center space-2 space-sm-3 space-lg-0">
            <div className="w-lg-80 mx-lg-auto">
              <div className="card">
                <div className="position-relative info-page-title space-1 space-md-2 p-4 px-md-7 px-md-9 rounded-lg">
                  <h1 className="text-white">Terms of Service</h1>
                  {/* <p className="text-white-70">Last updated: 25 September, 2023</p> */}
                  <figure className="position-absolute right-0 bottom-0 left-0 mb-n1">
                    {theme === "normal" ? (
                      <svg
                        preserveAspectRatio="none"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 1920 100.1"
                        width="100%"
                        height={32}
                      >
                        <path fill="#fff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                      </svg>
                    ) : (
                      <svg
                        preserveAspectRatio="none"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 1920 100.1"
                        width="100%"
                        height={32}
                      >
                        <path fill="#212121" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z" />
                      </svg>
                    )}
                  </figure>
                </div>
                <div className="card-body p-4 p-md-9">
                  <div className="mb-5">
                    <p>
                      <strong>PLEASE READ THESE TERMS OF SERVICE CAREFULLY.</strong>
                    </p>
                    <p>
                      Welcome to  STOR Scan! These Terms of Service ("Terms") govern your
                      use of the  STOR Scan platform and services provided by Open Trade
                      Exchange - STOR Scan ("we," "us," or "our"). Please read these Terms carefully before
                      using our services. By accessing or using  STOR Scan, you agree to
                      comply with and be bound by these Terms. If you disagree with these Terms, please do not
                      use our services.
                    </p>
                  </div>
                  <div className="mb-5">
                    <div className="mb-3">
                      <h5>1. Acceptance of Terms</h5>
                    </div>
                    <p>
                      By using  STOR Scan, you acknowledge that you have read,
                      understood, and accepted these Terms and our Privacy Policy. These Terms constitute a
                      legally binding agreement between you and  STOR Scan.
                    </p>
                  </div>
                  <div className="mb-5">
                    <div className="mb-3">
                      <h5>2. Eligibility</h5>
                    </div>
                    <p>
                      You must be at least 18 years old and have the legal capacity to enter into a binding
                      agreement to use  STOR Scan's services. Using our services, you
                      represent and warrant meeting these eligibility requirements.
                    </p>
                  </div>
                  <div className="mb-5">
                    <div className="mb-3">
                      <h5>3. Account Registration</h5>
                    </div>
                    <p>
                      To use certain features of  STOR Scan, you may be required to
                      create an account. You agree to provide accurate and complete information during the
                      registration process and to keep your account information updated. You are responsible
                      for maintaining the confidentiality of your account credentials and all activities under
                      your account.
                    </p>
                  </div>
                  <div className="mb-5">
                    <div className="mb-3">
                      <h5>4. Risk Disclosure</h5>
                    </div>
                    <p>
                      Cryptocurrency trading involves risks, including the risk of substantial loss. You
                      acknowledge that you are solely responsible for assessing the risks associated with
                      cryptocurrency trading and that  STOR Scan does not provide
                      investment advice. You agree to use our services at your own risk.
                    </p>
                  </div>
                  <div className="mb-5">
                    <div className="mb-3">
                      <h5>5. Compliance with Laws</h5>
                    </div>
                    <p>
                      You agree to comply with all applicable laws and regulations when using Open Trade
                      Exchange - STOR Scan. You are solely responsible for ensuring that your use of our
                      services is legal in your jurisdiction.
                    </p>
                  </div>
                  <div className="mb-5">
                    <div className="mb-3">
                      <h5>6. Prohibited Activities</h5>
                    </div>
                    <p>
                      You may not use  STOR Scan for illegal, fraudulent, or harmful
                      activities. Prohibited activities include, but are not limited to, money laundering,
                      terrorist financing, and engaging in activities that violate the rights of others.
                    </p>
                  </div>
                  <div className="mb-5">
                    <div className="mb-3">
                      <h5>7. Termination</h5>
                    </div>
                    <p>
                      We reserve the right to suspend or terminate your access to  STOR
                      Scan at our discretion, without notice, for any reason, including violations of these
                      Terms or suspected fraudulent activity.
                    </p>
                  </div>
                  <div className="mb-5">
                    <div className="mb-3">
                      <h5>8. Intellectual Property</h5>
                    </div>
                    <p>
                      All content and materials on  STOR Scan, including but not limited
                      to logos, trademarks, text, graphics, and software, are the property of Open Trade
                      Exchange - STOR Scan and are protected by intellectual property laws. You may not use,
                      reproduce, or distribute our content without our express written permission.
                    </p>
                  </div>
                  <div className="mb-5">
                    <div className="mb-3">
                      <h5>9. Privacy</h5>
                    </div>
                    <p>
                      Your use of  STOR Scan is subject to our Privacy Policy, which
                      outlines how we collect, use, and protect your personal information. Please review our
                      Privacy Policy to understand how we handle your data.
                    </p>
                  </div>
                  <div className="mb-5">
                    <div className="mb-3">
                      <h5>10. Not Registered in the USA - Exclusion for USA Citizens</h5>
                    </div>
                    <p>
                      We are not registered in the United States, and our services are not intended for or
                      available to United States citizens. If any individual who is a United States citizen
                      chooses to invest in or participate in our services, we explicitly disclaim any
                      liability or responsibility for their actions or involvement.
                    </p>
                  </div>
                  <div className="mb-5">
                    <div className="mb-3">
                      <h5>11. Disclaimer of Warranties</h5>
                    </div>
                    <p>
                       STOR Scan is provided "as is" and "as available" without express
                      or implied warranties. We do not guarantee our services' accuracy, reliability, or
                      availability.
                    </p>
                  </div>
                  <div className="mb-5">
                    <div className="mb-3">
                      <h5>12. Limitation of Liability</h5>
                    </div>
                    <p>
                      Shall  STOR Scan not be liable for any indirect, incidental,
                      special, consequential, or punitive damages arising from or relating to your use of our
                      services.
                    </p>
                  </div>
                  <div className="mb-5">
                    <div className="mb-3">
                      <h5>13. Changes to Terms</h5>
                    </div>
                    <p>
                      We may update these Terms from time to time. Any changes will be posted on the Open
                      Trade Exchange - STOR Scan website, and your continued use of our services after such
                      changes constitutes acceptance of the modified Terms.
                    </p>
                  </div>
                  <div className="mb-5">
                    <div className="mb-3">
                      <h5>14. Contact Us</h5>
                    </div>
                    <p>
                      If you have any questions or concerns about these Terms, please get in touch with us at
                      support@ STORscan.io
                    </p>
                    <p>
                      By using  STOR Scan, you agree to these Terms of Service. Thank you
                      for choosing  STOR Scan as your cryptocurrency trading platform.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default TermsOfService;
