import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import Header2 from "../common/Header2";
import { IgniteContext } from "../IgniteContext";
import { setLocalStorage, getLocalStorage } from "../../utils";

const ForgotPassword = () => {
    // useEffect(() => {
    //     return () => {
    //         localStorage.removeItem("timer");
    //         localStorage.removeItem("timermail");
    //     };
    // }, []);
    const { validateEmail } = useContext(IgniteContext);

    const [email, setEmail] = useState("");
    const [emailError, setemailError] = useState(null);

    const [oneMinTimer, setOneMinTimer] = useState(59);
    const [dispTimer, setDispTimer] = useState(false);
    const [hideBtn, setHideBtn] = useState(false);

    const [success, setSuccess] = useState(false);
    const [serverError, setServerError] = useState("");

    const [reCaptchaToken, setReCaptchaToken] = useState(false);
    const [reCaptchaTokenErr, setReCaptchaTokenErr] = useState("");

    const handleEmailChange = (val) => {
        setEmail(val);
        if (validateEmail(val)) {
            setemailError("");
        } else {
            setemailError("Please enter a valid email address.");
        }
    };

    const handleForgotPassword = async () => {
        let temp = 1;

        if (getLocalStorage("timer") && getLocalStorage("timermail")) {
            let lctimer = new Date(getLocalStorage("timer").toString());
            // console.log("forgetPassword call", Math.floor((new Date().getTime() - lctimer.getTime()) / 1000));
            let maintime = Math.floor((new Date().getTime() - lctimer.getTime()) / 1000);
            let lctimermail = getLocalStorage("timermail");
            // console.log("forgetPassword call", typeof lctimermail, lctimermail);
            if (getLocalStorage("timermail") && getLocalStorage("timer") && maintime <= 60 && lctimermail === email) {
                setDispTimer(true);
                setHideBtn(true);
                let diftime = 60 - maintime;
                setOneMinTimer(diftime);
                return false;
            }
        }

        if (validateEmail(email)) {
            setemailError("");
        } else {
            setemailError("Please enter a valid email address.");
            temp = 0;
        }
        if (!reCaptchaToken) {
            setReCaptchaTokenErr("Invalid captcha.");
            temp = 0;
        } else {
            setReCaptchaTokenErr("");
        }
        if (temp) {
            setHideBtn(true);
            setLocalStorage("timer", new Date().toString(), 100);
            setLocalStorage("timermail", email.toString(), 100);
            await axios
                .post(`${process.env.REACT_APP_BACKEND_URL}user/forgotpasswordmail`, { email: email })
                .then((res) => {
                    if (res.data.error) {
                        setSuccess(false);
                        setServerError(res.data.error);
                        setHideBtn(false);
                    } else {
                        setSuccess(true);
                        setDispTimer(true);
                        setHideBtn(true);
                    }
                })
                .catch((err) => {
                    setServerError(err?.error);
                    setHideBtn(false);
                    // console.log("error in handleForgotPassword (catch) : ", err);
                });
        }
    };

    const minTimer = () => {
        if (dispTimer) {
            let time = oneMinTimer;
            const cleartimer = setInterval(() => {
                if (time !== 0) {
                    time -= 1;
                    setOneMinTimer(time);
                    // console.log("timer => ", time);
                } else {
                    // console.log("timer end");
                    setDispTimer(false);
                    setHideBtn(false);
                    setOneMinTimer(59);
                    localStorage.removeItem("timer");
                    localStorage.removeItem("timermail");
                    clearInterval(cleartimer);
                }
            }, 1000);
        }
    };

    useEffect(() => {
        minTimer();
    }, [dispTimer]);

    const handleReCaptcha = async (checkToken) => {
        await axios
            .post(`${process.env.REACT_APP_BACKEND_URL}captcha`, {
                token: checkToken,
                skey: process.env.REACT_APP_CAPTCHA_SECRET_KEY,
            })
            .then((res) => {
                if (!res.data.success) {
                    setReCaptchaToken(false);
                    setReCaptchaTokenErr("Invalid captcha.");
                } else {
                    setReCaptchaToken(res.data.success);
                    setReCaptchaTokenErr("");
                }
            })
            .catch((err) => {
                console.log("error in handleReCaptcha (catch) : ", err);
            });
    };
    return (
        <>
            <Header2 />
            <main id="content" role="main" style={{ minHeight: "870px" }}>
                <div class="container space-2">
                    <form class="js-validate w-md-75 w-lg-50 mx-md-auto" novalidate="novalidate">
                        <div class="mb-4">
                            <h1 class="h3 text-primary font-weight-normal mb-0">Forgot your password?</h1>
                            <p>Enter your email address below and we'll get you back on track.</p>
                        </div>

                        <div>
                            {success ? (
                                <div className="alert alert-success" role="alert">
                                    You've successfully requested a forgot password. If the email address belongs to a known account, a recovery
                                    password will be sent to you within the next few minutes. If you have yet to receive the "Password Recovery"
                                    email, please check your spam/junk email folders Or you may request for a new password reset after 1 minutes.
                                </div>
                            ) : serverError ? (
                                <div className="alert alert-danger" role="alert">
                                    {serverError}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>

                        <div class="js-form-message form-group ">
                            <label class="d-block" for="txtUserName">
                                Email Address
                            </label>
                            <input
                                name="txtemail"
                                type="email"
                                maxlength="50"
                                id="txtemail"
                                tabindex="1"
                                className={
                                    emailError === null
                                        ? "form-control form-control-sm"
                                        : emailError == ""
                                        ? "form-control form-control-sm success "
                                        : "form-control form-control-sm error"
                                }
                                placeholder="Email"
                                onChange={(e) => {
                                    handleEmailChange(e.target.value);
                                }}
                            />
                            {emailError && (
                                <div id="ContentPlaceHolder1_txtUserName-error" class="invalid-feedback" style={{ display: "Block" }}>
                                    {emailError}
                                </div>
                            )}
                            {dispTimer ? <div className="d-flex justify-content-start w-100 opacity-25 mt-1">(Resend after {oneMinTimer}s)</div> : ""}
                        </div>
                        <div className="row form-group text-center justify-content-center">
                            <div className="d-flex flex-column justify-content-center my-2 align-items-center">
                                <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                                    onChange={(getmyToken) => {
                                        handleReCaptcha(getmyToken);
                                    }}
                                    onExpired={() => {
                                        setReCaptchaToken(false);
                                    }}
                                    tabIndex={3}
                                />
                                {reCaptchaTokenErr && (
                                    <div
                                        id="ContentPlaceHolder1_txtUserName-error"
                                        className="invalid-feedback f- text-center "
                                        style={{ display: "Block", fontSize: "14px" }}
                                    >
                                        {reCaptchaTokenErr}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div class="row align-items-center">
                            <div class="col-12 col-sm-12">
                                <a class="link-muted" href="/login">
                                    Back to sign in
                                </a>
                            </div>
                        </div>
                        <div class="text-center pt-2">
                            {/* {process.env.REACT_APP_LOGIN === "true" ? (
                                <input type="button" value="Reset Password" class="btn btn-sm btn-primary" onClick={forgetPassword} />
                            ) : (
                                <input type="button" value="Reset Password" class="btn btn-sm btn-primary disabled" />
                            )} */}
                            <input
                                type="button"
                                value="Reset Password"
                                class="btn btn-sm btn-primary"
                                onClick={handleForgotPassword}
                                disabled={hideBtn}
                            />
                        </div>
                    </form>
                </div>
            </main>
        </>
    );
};

export default ForgotPassword;
