import React, { useState } from "react";
import { Link } from "react-router-dom";

const Blockchain = () => {
    const pathName = window.location.pathname;
    const last = pathName.substring(pathName.lastIndexOf("/") + 1, pathName.length);

    const blockChain = ["/accounts", "/txs", "/txsPending", "/blocks", "/contractsVerified"];

    const findPathBlockchain = blockChain.find((e) => e === last);

    const [isHoverBlockchain1, setIsHoverBlockchain1] = useState(false);
    const [isHoverBlockchain2, setIsHoverBlockchain2] = useState(false);

    return (
        <li
            id="LI_blockchain"
            className={
                blockChain.includes(pathName)
                    ? `nav-item hs-has-sub-menu u-header__nav-item active ${isHoverBlockchain1 ? "hs-sub-menu-opened" : ""}`
                    : `nav-item hs-has-sub-menu u-header__nav-item ${isHoverBlockchain2 ? "hs-sub-menu-opened" : ""}`
            }
            onMouseEnter={() => {
                setIsHoverBlockchain1(true);
                setIsHoverBlockchain2(true);
            }}
            onMouseLeave={() => {
                setIsHoverBlockchain1(false);
                setIsHoverBlockchain2(false);
            }}
            data-event="hover"
            data-animation-in="slideInUp"
            data-animation-out="fadeOut"
        >
            {/* eslint-disable-next-line */}
            <a
                id="blockchainMegaMenu"
                className="nav-link u-header__nav-link u-header__nav-link-toggle"
                href="javascript:void(0);"
                aria-haspopup="true"
                aria-expanded="false"
                aria-labelledby="pagesSubMenu"
            >
                Blockchain
            </a>
            <ul
                id="blockchainSubMenu"
                className={`hs-sub-menu u-header__sub-menu  u-header__sub-menu--spacer ${
                    isHoverBlockchain1 ? "fadeOut custom-drop-hover-none" : "slideInUp custom-drop-hover"
                }`}
                aria-labelledby="blockchainMegaMenu"
            >
                <li id="LI_accountall" className={`${pathName === "/accounts" ? "text-primary active" : ""}`}>
                    <Link to="/accounts" className="nav-link u-header__sub-menu-nav-link">
                        Top Accounts
                    </Link>
                </li>
                <li className="dropdown-divider" />
                <li id="LI12" className={`${pathName === "/txs" ? "text-primary active" : ""}`}>
                    <Link to="/txs?p=1" className="nav-link u-header__sub-menu-nav-link">
                        View Txns
                    </Link>
                </li>
                <li id="LI16" className={`${pathName === "/txsPending" ? "text-primary active" : ""}`}>
                    <Link to="/txsPending" className="nav-link u-header__sub-menu-nav-link">
                        View Pending Txns
                    </Link>
                    <li id="LI16" className={`${pathName === "/txsInternal" ? "text-primary active" : ""}`}>
                        <Link to="/txsInternal" className="nav-link u-header__sub-menu-nav-link">
                            View Contract Internal Txns
                        </Link>
                    </li>
                </li>
                <li className="dropdown-divider" />
                <li id="LI_blocks" className={`${pathName === "/blocks" ? "text-primary active" : ""}`}>
                    <Link
                        id="navBlocks"
                        className="nav-link u-header__sub-menu-nav-link"
                        to="/blocks"
                        aria-haspopup="true"
                        aria-expanded="false"
                        aria-controls="navSubBlocks"
                    >
                        View Blocks
                    </Link>
                </li>

                <li className="divider" />
                <li className="dropdown-divider" />
                <li id="LI_contract_verified" className={`${pathName === "/contractsVerified" ? "text-primary active" : ""}`}>
                    <Link to="/contractsVerified" className="nav-link u-header__sub-menu-nav-link">
                        Verified Contracts
                    </Link>
                </li>
            </ul>
        </li>
    );
};

export default Blockchain;
