import React, { useState } from "react";
import { Link } from "react-router-dom";

const Tokens = () => {
  const pathName = window.location.pathname;

  const tokens = ["/tokens", "/tokentxns"];

  const [isHoverTokens1, setIsHoverTokens1] = useState(false);
  const [isHoverTokens2, setIsHoverTokens2] = useState(false);

  return (
    <li
      id="LI_Tokens"
      className={
        tokens.includes(pathName)
          ? `nav-item hs-has-sub-menu u-header__nav-item active ${isHoverTokens1 ? "hs-sub-menu-opened" : ""}`
          : `nav-item hs-has-sub-menu u-header__nav-item ${isHoverTokens2 ? "hs-sub-menu-opened" : ""}`
      }
      onMouseEnter={() => {
        setIsHoverTokens1(true);
        setIsHoverTokens2(true);
      }}
      onMouseLeave={() => {
        setIsHoverTokens1(false);
        setIsHoverTokens2(false);
      }}
      data-event="hover"
      data-animation-in="slideInUp"
      data-animation-out="fadeOut"
    >
      {/* eslint-disable-next-line */}
      <a
        id="testTokensMegaMenu"
        className="nav-link u-header__nav-link u-header__nav-link-toggle"
        href="javascript:void(0);"
        aria-haspopup="true"
        aria-expanded="false"
        aria-labelledby="testTokensSubMenu"
      >
        Tokens
      </a>
      <ul
        id="testTokensSubMenu"
        className={`hs-sub-menu u-header__sub-menu  u-header__sub-menu--spacer ${
          isHoverTokens1 ? "fadeOut custom-drop-hover-none" : "slideInUp custom-drop-hover"
        }`}
        aria-labelledby="testTokensMegaMenu"
      >
        <li id="LI18" className={`${pathName === "/tokens" ? "text-primary active" : ""}`}>
          <Link className="nav-link u-header__sub-menu-nav-link" to="/tokens">
            SRC20 Top Tokens
          </Link>
        </li>
        <li id="LI18" className={`${pathName === "/tokentxns" ? "text-primary active" : ""}`}>
          <Link className="nav-link u-header__sub-menu-nav-link" to="/tokentxns">
            View SRC20 Transfers
          </Link>
        </li>
      </ul>
    </li>
  );
};

export default Tokens;
