import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

const TransactionFee = ({ transactionFee, rate=3 }) => {
    const popoverTop6 = (
        <Popover id="popover-basic">
            {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
            <Popover.Body>Amount paid to the miner for processing the transaction.</Popover.Body>
        </Popover>
    );

    return (
        <>
            <div className="row">
                <div className="col-md-3 mb-1 bs  h6 text-blueish">
                    <OverlayTrigger style={{ padding: "100px 0" }} trigger="hover" placement="top" overlay={popoverTop6}>
                        <i class="fal fa-question-circle text-secondary mr-1" aria-hidden="true"></i>
                    </OverlayTrigger>{" "}
                    Transaction Fee
                </div>
                <div className="col-md-4 mb-1 bs text-dark bs">
                    {transactionFee ? (
                        <>
                            <span>{transactionFee}&nbsp;</span>
                            <span>{process.env.REACT_APP_TICKER}</span> (${Number(process.env.REACT_APP_PRICE) * Number(transactionFee)})
                        </>
                    ) : (
                        <i class="text-secondary">(pending)</i>
                    )}
                </div>
            </div>
            <hr />
        </>
    );
};

export default TransactionFee;
