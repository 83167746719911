import React from "react";

function SupportTechnicalIssue() {
    return (
        <>
            <div id="ContentPlaceHolder1_divFaq">
                <hr className="hr-space-lg" />
                <div id="accordion1">
                    <div className="accordion">
                        <h5>INTRODUCTION</h5>
                        <div className="card shadow-none">
                            <div className="card-header card-collapse p-0" id="heading1_1">
                                <a
                                    className="btn btn-link btn-block d-flex justify-content-between align-items-center py-2 collapsed text-left"
                                    data-toggle="collapse"
                                    href="#collapse1_1"
                                    aria-expanded="false"
                                    aria-controls="collapse1_1"
                                >
                                    What is {process.env.REACT_APP_NAME}Scan?
                                    <span className="accordion-arrow">
                                        <i className="fas fa-arrow-down small" />
                                    </span>
                                </a>
                            </div>
                            <div id="collapse1_1" className="collapse" aria-labelledby="heading1_1" data-parent="#accordion1">
                                <div className="card-body">
                                    <a
                                        href="https://bscscan.freshdesk.com/support/solutions/articles/67000552911-what-is-bscscan-"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {process.env.REACT_APP_NAME}Scan
                                    </a>
                                    &nbsp;is a block explorer for the {process.env.REACT_APP_TICKER} Smart Chain, just as Google is a search engine
                                    for the Internet.
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-header card-collapse p-0" id="heading1_2">
                                <a
                                    className="btn btn-link btn-block d-flex justify-content-between align-items-center py-2 collapsed text-left"
                                    data-toggle="collapse"
                                    href="#collapse1_2"
                                    aria-expanded="false"
                                    aria-controls="collapse1_2"
                                >
                                    What does {process.env.REACT_APP_NAME}Scan do?
                                    <span className="accordion-arrow">
                                        <i className="fas fa-arrow-down small" />
                                    </span>
                                </a>
                            </div>
                            <div id="collapse1_2" className="collapse" aria-labelledby="heading1_2" data-parent="#accordion1">
                                <div className="card-body">
                                    We are a search engine that collects and gathers data from the blockchain and converts them into human-readable
                                    format, allowing for equitable access to blockchain data for all. {process.env.REACT_APP_NAME}Scan users can
                                    easily validate transactions that have been made, check the transaction history of any wallet address, interact
                                    with any smart contract, browse through pending transactions yet to be mined, and much more.
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-header card-collapse p-0" id="heading1_3">
                                <a
                                    className="btn btn-link btn-block d-flex justify-content-between align-items-center py-2 collapsed text-left"
                                    data-toggle="collapse"
                                    href="#collapse1_3"
                                    aria-expanded="false"
                                    aria-controls="collapse1_3"
                                >
                                    What doesn’t {process.env.REACT_APP_NAME}Scan do?
                                    <span className="accordion-arrow">
                                        <i className="fas fa-arrow-down small" />
                                    </span>
                                </a>
                            </div>
                            <div id="collapse1_3" className="collapse" aria-labelledby="heading1_3" data-parent="#accordion1">
                                <div className="card-body">
                                    Processing transactions, moving assets from and to a wallet address, recovering lost funds, and having access to
                                    your private keys and assets. Essentially, we don’t provide wallet or exchange services. We are also not capable
                                    of reversing transactions or recovering assets.
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-header card-collapse p-0" id="heading1_4">
                                <a
                                    className="btn btn-link btn-block d-flex justify-content-between align-items-center py-2 collapsed text-left"
                                    data-toggle="collapse"
                                    href="#collapse1_4"
                                    aria-expanded="false"
                                    aria-controls="collapse1_4"
                                >
                                    Why am I sent here?
                                    <span className="accordion-arrow">
                                        <i className="fas fa-arrow-down small" />
                                    </span>
                                </a>
                            </div>
                            <div id="collapse1_4" className="collapse" aria-labelledby="heading1_4" data-parent="#accordion1">
                                <div className="card-body">
                                    Users are usually given a link to our site by their wallet service provider to show details of their transaction.
                                    This page is called the Transaction Details page and is often used as proof of payment or receipt if you are on
                                    the receiving end.
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-header card-collapse p-0" id="heading1_5">
                                <a
                                    className="btn btn-link btn-block d-flex justify-content-between align-items-center py-2 collapsed text-left"
                                    data-toggle="collapse"
                                    href="#collapse1_5"
                                    aria-expanded="false"
                                    aria-controls="collapse1_5"
                                >
                                    Is {process.env.REACT_APP_NAME}Scan related to {process.env.REACT_APP_NAME}Chain?
                                    <span className="accordion-arrow">
                                        <i className="fas fa-arrow-down small" />
                                    </span>
                                </a>
                            </div>
                            <div id="collapse1_5" className="collapse" aria-labelledby="heading1_5" data-parent="#accordion1">
                                <div className="card-body">
                                    No, {process.env.REACT_APP_NAME}Scan is independently operated and not related to {process.env.REACT_APP_NAME}
                                    Chain. {process.env.REACT_APP_NAME}Scan is a block explorer used to gather data on the BSC blockchain while{" "}
                                    {process.env.REACT_APP_NAME}Chain is a cryptocurrency exchange.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion mt-4">
                        <h5>TRANSACTIONS</h5>
                        <div className="card shadow-none">
                            <div className="card-header card-collapse p-0" id="heading2_1">
                                <a
                                    className="btn btn-link btn-block d-flex justify-content-between align-items-center py-2 collapsed text-left"
                                    data-toggle="collapse"
                                    href="#collapse2_1"
                                    aria-expanded="false"
                                    aria-controls="collapse2_1"
                                >
                                    Why are my funds sent to {process.env.REACT_APP_NAME}Scan?
                                    <span className="accordion-arrow">
                                        <i className="fas fa-arrow-down small" />
                                    </span>
                                </a>
                            </div>
                            <div id="collapse2_1" className="collapse" aria-labelledby="heading2_1" data-parent="#accordion1">
                                <div className="card-body">
                                    No, they are not. You are sent here to view a detailed version of the transaction you have made or one that is
                                    being sent to you. We only provide details pertaining to your transactions. We are not a wallet provider, hence we
                                    do not hold any funds.
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-header card-collapse p-0" id="heading2_2">
                                <a
                                    className="btn btn-link btn-block d-flex justify-content-between align-items-center py-2 collapsed text-left"
                                    data-toggle="collapse"
                                    href="#collapse2_2"
                                    aria-expanded="false"
                                    aria-controls="collapse2_2"
                                >
                                    Why do I see my funds on {process.env.REACT_APP_NAME}Scan?
                                    <span className="accordion-arrow">
                                        <i className="fas fa-arrow-down small" />
                                    </span>
                                </a>
                            </div>
                            <div id="collapse2_2" className="collapse" aria-labelledby="heading2_2" data-parent="#accordion1">
                                <div className="card-body">
                                    As a block explorer, we show all the information that is available on the blockchain to our users - this includes
                                    (but not limited to) the funds inside your wallet address, your address’s transaction history, and the contract
                                    addresses that you have interacted with.
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-header card-collapse p-0" id="heading2_3">
                                <a
                                    className="btn btn-link btn-block d-flex justify-content-between align-items-center py-2 collapsed text-left"
                                    data-toggle="collapse"
                                    href="#collapse2_3"
                                    aria-expanded="false"
                                    aria-controls="collapse2_3"
                                >
                                    Can I withdraw my funds from {process.env.REACT_APP_NAME}Scan?
                                    <span className="accordion-arrow">
                                        <i className="fas fa-arrow-down small" />
                                    </span>
                                </a>
                            </div>
                            <div id="collapse2_3" className="collapse" aria-labelledby="heading2_3" data-parent="#accordion1">
                                <div className="card-body">
                                    No. As a block explorer, we only provide a platform that lets users look up, confirm and validate transactions and
                                    other data that are publicly available on the {process.env.REACT_APP_TICKER} Smart Chain. In order to send or
                                    transfer {process.env.REACT_APP_TICKER} or tokens from your account, you’ll need to do so via the wallet platform
                                    that you normally use.
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-header card-collapse p-0" id="heading2_4">
                                <a
                                    className="btn btn-link btn-block d-flex justify-content-between align-items-center py-2 collapsed text-left"
                                    data-toggle="collapse"
                                    href="#collapse2_4"
                                    aria-expanded="false"
                                    aria-controls="collapse2_4"
                                >
                                    Can I get a refund?
                                    <span className="accordion-arrow">
                                        <i className="fas fa-arrow-down small" />
                                    </span>
                                </a>
                            </div>
                            <div id="collapse2_4" className="collapse" aria-labelledby="heading2_4" data-parent="#accordion1">
                                <div className="card-body">
                                    Due to the immutable nature of blockchains, successful transactions are permanent and irreversible. As such, in
                                    cases where you might have sent your token to the wrong address, you will most likely never be able to recover the
                                    said token. The only person able to issue a refund is the person holding the coins – the owner of the receiving
                                    address. If you do not know the owner of the address, unfortunately, there is no other way to retrieve the funds.
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-header card-collapse p-0" id="heading2_5">
                                <a
                                    className="btn btn-link btn-block d-flex justify-content-between align-items-center py-2 collapsed text-left"
                                    data-toggle="collapse"
                                    href="#collapse2_5"
                                    aria-expanded="false"
                                    aria-controls="collapse2_5"
                                >
                                    My transaction shows “‘success’’, but I didn't receive my tokens. Why is this happening?
                                    <span className="accordion-arrow">
                                        <i className="fas fa-arrow-down small" />
                                    </span>
                                </a>
                            </div>
                            <div id="collapse2_5" className="collapse" aria-labelledby="heading2_5" data-parent="#accordion1">
                                <div className="card-body">
                                    If the transaction is successful but the balance is not updated in your wallet, it could be because the
                                    recipient's wallet does not support the token in the transaction or it does not support the network that the
                                    transaction was carried out in altogether. We recommend contacting your wallet service provider directly for
                                    further assistance/confirmation on this subject matter.
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-header card-collapse p-0" id="heading2_6">
                                <a
                                    className="btn btn-link btn-block d-flex justify-content-between align-items-center py-2 collapsed text-left"
                                    data-toggle="collapse"
                                    href="#collapse2_6"
                                    aria-expanded="false"
                                    aria-controls="collapse2_6"
                                >
                                    Why did you charge me a gas fee for a failed transaction?
                                    <span className="accordion-arrow">
                                        <i className="fas fa-arrow-down small" />
                                    </span>
                                </a>
                            </div>
                            <div id="collapse2_6" className="collapse" aria-labelledby="heading2_6" data-parent="#accordion1">
                                <div className="card-body">
                                    As a block explorer, we are not involved in the process of a transaction. When a transaction fails, the miners
                                    still have to validate and execute (compute) that transaction. The deducted gas fee is to compensate for the
                                    computational power used to process the said transaction. Read more about gas fees{" "}
                                    <a
                                        href="https://bscscan.freshdesk.com/support/solutions/articles/67000687315-what-is-gas-fee-%E2%9B%BD"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        here
                                    </a>
                                    .
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-header card-collapse p-0" id="heading2_7">
                                <a
                                    className="btn btn-link btn-block d-flex justify-content-between align-items-center py-2 collapsed text-left"
                                    data-toggle="collapse"
                                    href="#collapse2_7"
                                    aria-expanded="false"
                                    aria-controls="collapse2_7"
                                >
                                    I've been scammed. What can {process.env.REACT_APP_NAME}Scan do?
                                    <span className="accordion-arrow">
                                        <i className="fas fa-arrow-down small" />
                                    </span>
                                </a>
                            </div>
                            <div id="collapse2_7" className="collapse" aria-labelledby="heading2_7" data-parent="#accordion1">
                                <div className="card-body">
                                    Unfortunately, due to the irreversible nature of blockchains, transactions can neither be canceled nor reversed
                                    and the funds are not recoverable. Please write to us{" "}
                                    <a href="https://bscscan.com/contactus" target="_blank" rel="noopener noreferrer">
                                        here
                                    </a>{" "}
                                    so we can tag the addresses involved in the incident accordingly.
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-header card-collapse p-0" id="heading2_8">
                                <a
                                    className="btn btn-link btn-block d-flex justify-content-between align-items-center py-2 collapsed text-left"
                                    data-toggle="collapse"
                                    href="#collapse2_8"
                                    aria-expanded="false"
                                    aria-controls="collapse2_8"
                                >
                                    I have sent my funds successfully to find out that my wallet does not support this network. Can you help me
                                    recover?
                                    <span className="accordion-arrow">
                                        <i className="fas fa-arrow-down small" />
                                    </span>
                                </a>
                            </div>
                            <div id="collapse2_8" className="collapse" aria-labelledby="heading2_8" data-parent="#accordion1">
                                <div className="card-body">
                                    No. Sending assets through a network that is unsupported by the receiving platform may lead to a loss of funds.
                                    Please research beforehand the networks supported by the platform on the receiving end of the transaction. We
                                    strongly recommend contacting the support team of the recipient's exchange/wallet platform as they are in a better
                                    position and have more knowledge on the subject matter to assist you.
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-header card-collapse p-0" id="heading2_9">
                                <a
                                    className="btn btn-link btn-block d-flex justify-content-between align-items-center py-2 collapsed text-left"
                                    data-toggle="collapse"
                                    href="#collapse2_9"
                                    aria-expanded="false"
                                    aria-controls="collapse2_9"
                                >
                                    I have sent my funds successfully to find out that I chose the wrong network. Can you help me recover?
                                    <span className="accordion-arrow">
                                        <i className="fas fa-arrow-down small" />
                                    </span>
                                </a>
                            </div>
                            <div id="collapse2_9" className="collapse" aria-labelledby="heading2_9" data-parent="#accordion1">
                                <div className="card-body">
                                    If you mistakenly sent tokens via the wrong network (ERC20 to BEP20), there is still a possibility to recover your
                                    tokens if you have the receiving address’s private keys. If the receiving address is an exchange address, the
                                    assets may be unrecoverable as it is highly unlikely that you would have access to the address’s private key. We
                                    strongly recommend contacting the support team of the recipient's exchange/wallet platform as they are in a better
                                    position and have more knowledge on the subject matter to assist you. For more details, refer to our article{" "}
                                    <a
                                        href="https://bscscan.freshdesk.com/support/solutions/articles/67000229445-how-to-recover-erc20-tokens-sent-to-ethereum-address-as-bep20-"
                                        rel="noopener noreferrer"
                                    >
                                        here
                                    </a>
                                    .
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-header card-collapse p-0" id="heading2_10">
                                <a
                                    className="btn btn-link btn-block d-flex justify-content-between align-items-center py-2 collapsed text-left"
                                    data-toggle="collapse"
                                    href="#collapse2_10"
                                    aria-expanded="false"
                                    aria-controls="collapse2_10"
                                >
                                    Why did my transaction fail?
                                    <span className="accordion-arrow">
                                        <i className="fas fa-arrow-down small" />
                                    </span>
                                </a>
                            </div>
                            <div id="collapse2_10" className="collapse" aria-labelledby="heading2_10" data-parent="#accordion1">
                                <div className="card-body">
                                    A transaction may fail due to a number of different reasons. For more information on the most common cases of
                                    failed transactions, their causes, and what to do in each situation, please refer to our article{" "}
                                    <a
                                        href="https://bscscan.freshdesk.com/support/solutions/articles/67000507748-what-are-the-reasons-for-failed-transactions-"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        here
                                    </a>
                                    .
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-header card-collapse p-0" id="heading2_11">
                                <a
                                    className="btn btn-link btn-block d-flex justify-content-between align-items-center py-2 collapsed text-left"
                                    data-toggle="collapse"
                                    href="#collapse2_11"
                                    aria-expanded="false"
                                    aria-controls="collapse2_11"
                                >
                                    My transaction ran out of gas. How am I to know if an amount of gas is enough for a transaction?
                                    <span className="accordion-arrow">
                                        <i className="fas fa-arrow-down small" />
                                    </span>
                                </a>
                            </div>
                            <div id="collapse2_11" className="collapse" aria-labelledby="heading2_11" data-parent="#accordion1">
                                <div className="card-body">
                                    A transaction can fail and run out of gas when the gas limit is set too low. We recommend checking the gas limit
                                    used by other transactions (with the same Method as yours) that have interacted with the contract address. If
                                    unsure, it is best to contact the contract address’s creator for further assistance on this subject matter.
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-header card-collapse p-0" id="heading2_12">
                                <a
                                    className="btn btn-link btn-block d-flex justify-content-between align-items-center py-2 collapsed text-left"
                                    data-toggle="collapse"
                                    href="#collapse2_12"
                                    aria-expanded="false"
                                    aria-controls="collapse2_12"
                                >
                                    This transaction was not authorized or performed by me, can you please help me get it back?
                                    <span className="accordion-arrow">
                                        <i className="fas fa-arrow-down small" />
                                    </span>
                                </a>
                            </div>
                            <div id="collapse2_12" className="collapse" aria-labelledby="heading2_12" data-parent="#accordion1">
                                <div className="card-body">
                                    No, all transactions are irreversible and not recoverable. Please be reminded that this can happen when visiting a
                                    phishing site, by sharing a user’s private key or by granting token approval to a malicious contract address.
                                    Please exercise caution when using your private key. You may check and revoke access from smart contracts to your
                                    tokens with our{" "}
                                    <a href="https://bscscan.com/tokenapprovalchecker" target="_blank" rel="noopener noreferrer">
                                        Token Approvals
                                    </a>{" "}
                                    tool.
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-header card-collapse p-0" id="heading2_13">
                                <a
                                    className="btn btn-link btn-block d-flex justify-content-between align-items-center py-2 collapsed text-left"
                                    data-toggle="collapse"
                                    href="#collapse2_13"
                                    aria-expanded="false"
                                    aria-controls="collapse2_13"
                                >
                                    Why is my BEP-20 transfer not reflecting in my Coinbase wallet?
                                    <span className="accordion-arrow">
                                        <i className="fas fa-arrow-down small" />
                                    </span>
                                </a>
                            </div>
                            <div id="collapse2_13" className="collapse" aria-labelledby="heading2_13" data-parent="#accordion1">
                                <div className="card-body">
                                    Coinbase wallet does not support BEP-20 tokens (and the {process.env.REACT_APP_TICKER} Smart Chain network) as of
                                    now. When sending out a token that is supported by Coinbase wallet but through a network that is unsupported by
                                    the platform, the token would not be reflected in the receiving address and is unrecoverable.
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-header card-collapse p-0" id="heading2_14">
                                <a
                                    className="btn btn-link btn-block d-flex justify-content-between align-items-center py-2 collapsed text-left"
                                    data-toggle="collapse"
                                    href="#collapse2_14"
                                    aria-expanded="false"
                                    aria-controls="collapse2_14"
                                >
                                    Are BEP-2 and BEP-20 the same thing?
                                    <span className="accordion-arrow">
                                        <i className="fas fa-arrow-down small" />
                                    </span>
                                </a>
                            </div>
                            <div id="collapse2_14" className="collapse" aria-labelledby="heading2_14" data-parent="#accordion1">
                                <div className="card-body">
                                    BEP-2 represents a token standard on {process.env.REACT_APP_NAME}Chain Chain, while BEP-20 represents a token
                                    standard on {process.env.REACT_APP_TICKER} Smart Chain. Please make sure to select the intended network when
                                    trying to deposit and withdraw each asset. Do not deposit via cross-chain transfer from{" "}
                                    {process.env.REACT_APP_NAME}Chain Chain directly into the BSC deposit address. It will not be credited to your
                                    account as announced by {process.env.REACT_APP_NAME}Chain team{" "}
                                    <a
                                        href="https://www.binance.com/en/support/announcement/daca7c991d5f4c45a4d1083f70912515"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        here
                                    </a>
                                    .
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion mt-4">
                        <h5>TOKEN UPDATES</h5>
                        <div className="card shadow-none">
                            <div className="card-header card-collapse p-0" id="heading3_1">
                                <a
                                    className="btn btn-link btn-block d-flex justify-content-between align-items-center py-2 collapsed text-left"
                                    data-toggle="collapse"
                                    href="#collapse3_1"
                                    aria-expanded="false"
                                    aria-controls="collapse3_1"
                                >
                                    I am unable to verify my ownership of a contract address. What should I do?
                                    <span className="accordion-arrow">
                                        <i className="fas fa-arrow-down small" />
                                    </span>
                                </a>
                            </div>
                            <div id="collapse3_1" className="collapse" aria-labelledby="heading3_1" data-parent="#accordion1">
                                <div className="card-body">
                                    For token creators, we require them to{" "}
                                    <a
                                        href="https://bscscan.freshdesk.com/support/solutions/articles/67000670199-how-to-verify-contract-address-ownership-"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        verify their ownership
                                    </a>{" "}
                                    first before they can submit a token update request. If you were unable to verify your ownership:
                                    <ol>
                                        <li>
                                            Due to the contract address having been deployed by another contract address, please refer to our tutorial{" "}
                                            <a
                                                href="https://bscscan.freshdesk.com/support/solutions/articles/67000663077-contract-created-by-contract"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                here
                                            </a>
                                            .
                                        </li>
                                        <li>
                                            Because the contract address has already been assigned to another {process.env.REACT_APP_NAME}Scan
                                            account, you will need to release the ownership first. Read more{" "}
                                            <a
                                                href="https://bscscan.freshdesk.com/support/solutions/articles/67000681823-how-to-release-contract-address-ownership-"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                here
                                            </a>
                                            .
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-header card-collapse p-0" id="heading3_2">
                                <a
                                    className="btn btn-link btn-block d-flex justify-content-between align-items-center py-2 collapsed text-left"
                                    data-toggle="collapse"
                                    href="#collapse3_2"
                                    aria-expanded="false"
                                    aria-controls="collapse3_2"
                                >
                                    Why did my token update request get rejected?
                                    <span className="accordion-arrow">
                                        <i className="fas fa-arrow-down small" />
                                    </span>
                                </a>
                            </div>
                            <div id="collapse3_2" className="collapse" aria-labelledby="heading3_2" data-parent="#accordion1">
                                <div className="card-body">
                                    Please refer to our article{" "}
                                    <a
                                        href="https://bscscan.freshdesk.com/support/solutions/articles/67000666500-token-info-submission-guidelines"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        here
                                    </a>{" "}
                                    for a guideline on submitting a token update request.
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-header card-collapse p-0" id="heading3_3">
                                <a
                                    className="btn btn-link btn-block d-flex justify-content-between align-items-center py-2 collapsed text-left"
                                    data-toggle="collapse"
                                    href="#collapse3_3"
                                    aria-expanded="false"
                                    aria-controls="collapse3_3"
                                >
                                    Why is the token update process taking too long and what can I do to quicken it?
                                    <span className="accordion-arrow">
                                        <i className="fas fa-arrow-down small" />
                                    </span>
                                </a>
                            </div>
                            <div id="collapse3_3" className="collapse" aria-labelledby="heading3_3" data-parent="#accordion1">
                                <div className="card-body">
                                    We carefully go through each inquiry and request within our best capacity. Due to the large number of inquiries
                                    that we receive, it might take some time before we can get back to you. We do, however, have a premium
                                    subscription plan with a guaranteed 24-hour turnaround period for token updates. Read more on our Priority Support
                                    plan{" "}
                                    <a href="https://bscscan.com/priority-support" rel="noopener noreferrer">
                                        here
                                    </a>
                                    .
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion mt-4">
                        <h5>FEATURES &amp; SERVICES</h5>
                        <div className="card shadow-none">
                            <div className="card-header card-collapse p-0" id="heading4_1">
                                <a
                                    className="btn btn-link btn-block d-flex justify-content-between align-items-center py-2 collapsed text-left"
                                    data-toggle="collapse"
                                    href="#collapse4_1"
                                    aria-expanded="false"
                                    aria-controls="collapse4_1"
                                >
                                    What can I do on {process.env.REACT_APP_NAME}Scan?
                                    <span className="accordion-arrow">
                                        <i className="fas fa-arrow-down small" />
                                    </span>
                                </a>
                            </div>
                            <div id="collapse4_1" className="collapse" aria-labelledby="heading4_1" data-parent="#accordion1">
                                <div className="card-body">
                                    Users can look up, confirm, validate and create a “
                                    <a
                                        href="https://bscscan.freshdesk.com/support/solutions/articles/67000687317-address-watch-list"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        watch list
                                    </a>
                                    ” of addresses making it easier to track transactions,{" "}
                                    <a href="https://bscscan.com/tokenapprovalchecker" target="_blank" rel="noopener noreferrer">
                                        revoke token approvals
                                    </a>
                                    , check smart contracts, check out{" "}
                                    <a href="https://bscscan.com/charts" rel="noopener noreferrer">
                                        charts and stats
                                    </a>
                                    , and generally stay updated on what’s happening on the {process.env.REACT_APP_TICKER} Smart Chain.
                                    <br />
                                    Developers may access a set of{" "}
                                    <a href="https://bscscan.com/apis" target="_blank" rel="noopener noreferrer">
                                        API services
                                    </a>{" "}
                                    that can be used to build decentralized applications or as a data source for information on the{" "}
                                    {process.env.REACT_APP_TICKER} Smart Chain.
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-header card-collapse p-0" id="heading4_2">
                                <a
                                    className="btn btn-link btn-block d-flex justify-content-between align-items-center py-2 collapsed text-left"
                                    data-toggle="collapse"
                                    href="#collapse4_2"
                                    aria-expanded="false"
                                    aria-controls="collapse4_2"
                                >
                                    Do I need to have an account to use {process.env.REACT_APP_NAME}Scan?
                                    <span className="accordion-arrow">
                                        <i className="fas fa-arrow-down small" />
                                    </span>
                                </a>
                            </div>
                            <div id="collapse4_2" className="collapse" aria-labelledby="heading4_2" data-parent="#accordion1">
                                <div className="card-body">
                                    Most of the features and tools on our website are readily available to everyone and do not require having a
                                    registered account. However, we do have exclusive features accessible only to registered users. Sign up{" "}
                                    <a href="https://bscscan.com/register" target="_blank" rel="noopener noreferrer">
                                        here
                                    </a>{" "}
                                    (signing up is free!).
                                </div>
                            </div>
                        </div>
                        <div className="card shadow-none">
                            <div className="card-header card-collapse p-0" id="heading4_3">
                                <a
                                    className="btn btn-link btn-block d-flex justify-content-between align-items-center py-2 collapsed text-left"
                                    data-toggle="collapse"
                                    href="#collapse4_3"
                                    aria-expanded="false"
                                    aria-controls="collapse4_3"
                                >
                                    There is a bug on your website. How can I report it?
                                    <span className="accordion-arrow">
                                        <i className="fas fa-arrow-down small" />
                                    </span>
                                </a>
                            </div>
                            <div id="collapse4_3" className="collapse" aria-labelledby="heading4_3" data-parent="#accordion1">
                                <div className="card-body">
                                    Email us the details{" "}
                                    <a
                                        href="/cdn-cgi/l/email-protection#2d4f5e4e5e4e4c434e42405e585d5d425f596d485945485f5e4e4c43034b5f485e4549485e46034e4240"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        here
                                    </a>
                                    .
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion mt-4">
                        <h5>ADDITIONAL SUPPORT</h5>
                        <div className="card shadow-none">
                            <div className="card-header card-collapse p-0" id="heading5_1">
                                <a
                                    className="btn btn-link btn-block d-flex justify-content-between align-items-center py-2 collapsed text-left"
                                    data-toggle="collapse"
                                    href="#collapse5_1"
                                    aria-expanded="false"
                                    aria-controls="collapse5_1"
                                >
                                    Where can I go to ask further questions?
                                    <span className="accordion-arrow">
                                        <i className="fas fa-arrow-down small" />
                                    </span>
                                </a>
                            </div>
                            <div id="collapse5_1" className="collapse" aria-labelledby="heading5_1" data-parent="#accordion1">
                                <div className="card-body">
                                    If you have additional questions or the issue you are currently facing is not addressed above, we may have
                                    answered it in one of our articles on our{" "}
                                    <a href="https://bscscan.freshdesk.com/support/solutions" target="_blank" rel="noopener noreferrer">
                                        Knowledge Base
                                    </a>
                                    . Alternatively, for further assistance from our support team, kindly reach out to us
                                    <input
                                        type="submit"
                                        name="ctl00$ContentPlaceHolder1$btnFurtherAssistance"
                                        defaultValue="here"
                                        id="ContentPlaceHolder1_btnFurtherAssistance"
                                        className="btn btn-link border-0 p-0 align-baseline btn-further-assistance"
                                    />
                                    .
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SupportTechnicalIssue;
