import React from "react";
import FadingLoaderCard1 from "../ContentLoaders/LoaderBigLine";
import { Link } from "react-router-dom";

const TransactionDetailsLoader = () => {
    return (
        <main id="content" role="main" style={{ minHeight: "800px" }}>
            <div className="container py-3 mn-b3">
                <div className="d-lg-flex justify-content-between align-items-center">
                    <div className="mb-3 mb-lg-0">
                        <h1 className="h4 mb-0"> Transaction Details </h1>
                    </div>
                    <div className="d-flex flex-wrap mt-1 mt-md-0 flex-wrap ml-md-auto" />
                </div>
            </div>
            <div className="container">
                <div className="border-top py-3">
                    <div className="d-flex text-secondary" style={{ lineHeight: "2.2" }} />
                </div>
            </div>
            <div className="container space-bottom-2">
                <div className="card">
                    <div className="card-header sticky-card-header d-flex justify-content-between p-0">
                        <ul className="nav nav-custom nav-borderless nav_tabs1" id="nav_tabs" role="tablist">
                            <li className="nav-item">
                                <Link className={`nav-link active"}`}>Overview</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade active show" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div id="ContentPlaceHolder1_maintable" className="card-body">
                                <div>
                                    <div className="row">
                                        <div className="col-md-3 mb-1 bs  h6 text-blueish">
                                            {/* <OverlayTrigger style={{ padding: "100px 0" }} trigger="hover" placement="top" overlay={popoverTop1}> */}
                                            <i class="fal fa-question-circle text-secondary mr-1" aria-hidden="true"></i>
                                            {/* </OverlayTrigger> */}
                                            TXN#
                                        </div>
                                        <div className="col-md-8 mb-1 bs text-dark bs">
                                            <div className="mb-2 mb-md-0e">
                                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="150" height="12" />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-3 mt-1 bs text-blueish">
                                            {/* <OverlayTrigger style={{ padding: "100px 0" }} trigger="hover" placement="top" overlay={popoverTop3}> */}
                                            <i class="fal fa-question-circle text-secondary mr-1" aria-hidden="true"></i>
                                            {/* </OverlayTrigger> */}
                                            Status
                                        </div>
                                        <div className="col-md-4 mt-1 text-dark">
                                            <div className="mb-2 mb-md-0e">
                                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="150" height="12" />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-3 mb-1 bs  h6 text-blueish">
                                            {/* <OverlayTrigger style={{ padding: "100px 0" }} trigger="hover" placement="top" overlay={popoverTop16}> */}
                                            <i class="fal fa-question-circle text-secondary mr-1" aria-hidden="true"></i>
                                            {/* </OverlayTrigger> */}
                                            Block
                                        </div>
                                        <div className="col-md-4 mb-1 bs text-info bs">
                                            <div className="mb-2 mb-md-0e">
                                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="150" height="12" />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-3 mb-1 bs  h6 text-blueish">
                                            {/* <OverlayTrigger style={{ padding: "100px 0" }} trigger="hover" placement="top" overlay={popoverTop15}> */}
                                            <i class="fal fa-question-circle text-secondary mr-1" aria-hidden="true"></i>
                                            {/* </OverlayTrigger> */}
                                            Timestamp
                                        </div>
                                        <div className="col-md-4 mb-1 bs text-dark bs">
                                            <div className="mb-2 mb-md-0e">
                                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="150" height="12" />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-3 mb-1 bs h6 text-blueish">
                                            {/* <OverlayTrigger style={{ padding: "100px 0" }} trigger="hover" placement="top" overlay={popoverTop2}> */}
                                            <i class="fal fa-question-circle text-secondary mr-1" aria-hidden="true"></i>
                                            {/* </OverlayTrigger> */}
                                            From
                                        </div>
                                        <div className="col-md-6 mb-1 bs text-blue">
                                            <div className="mb-2 mb-md-0e">
                                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="150" height="12" />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-3 mb-1 bs h6 text-blueish">
                                            {/* <OverlayTrigger style={{ padding: "100px 0" }} trigger="hover" placement="top" overlay={popoverTop14}> */}
                                            <i class="fal fa-question-circle text-secondary mr-1" aria-hidden="true"></i>
                                            {/* </OverlayTrigger> */}
                                            To
                                        </div>
                                        <div className="col-md-6 mb-1 bs text-blue">
                                            <div className="mb-2 mb-md-0e">
                                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="150" height="12" />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-3 mb-1 bs h6 text-blueish">
                                            {/* <OverlayTrigger style={{ padding: "100px 0" }} trigger="hover" placement="top" overlay={popoverTop5}> */}
                                            <i class="fal fa-question-circle text-secondary mr-1" aria-hidden="true"></i>
                                            {/* </OverlayTrigger>{" "} */}
                                            Value
                                        </div>
                                        <div className="col-md-4 mb-1 bs text-dark bs">
                                            <div className="mb-2 mb-md-0e">
                                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="150" height="12" />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-3 mb-1 bs h6 text-blueish">
                                            {/* <OverlayTrigger style={{ padding: "100px 0" }} trigger="hover" placement="top" overlay={popoverTop18}> */}
                                            <i class="fal fa-question-circle text-secondary mr-1" aria-hidden="true"></i>
                                            {/* </OverlayTrigger>{" "} */}
                                            Txn Type:
                                        </div>
                                        <div className="col-md-4 mb-1 bs text-dark bs">
                                            <div className="mb-2 mb-md-0e">
                                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="150" height="12" />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-md-3 mb-1 bs  h6 text-blueish">
                                            {/* <OverlayTrigger style={{ padding: "100px 0" }} trigger="hover" placement="top" overlay={popoverTop6}> */}
                                            <i class="fal fa-question-circle text-secondary mr-1" aria-hidden="true"></i>
                                            {/* </OverlayTrigger>{" "} */}
                                            Transaction Fee
                                        </div>
                                        <div className="col-md-4 mb-1 bs text-dark bs">
                                            <div className="mb-2 mb-md-0e">
                                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="150" height="12" />
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    {/* <Rewards
                                                validatorReward={"0.002975195"}
                                                txnReward={"0.0007437987"}
                                                systemReward={"0.0008628066"}
                                                foundationReward={"0.0002975195"}
                                                developerReward={"0.0001190078"}
                                                delegatorReward={"0.0002082637"}
                                            /> */}
                                    <div className="collapse " id="collapsePanel">
                                        {/* <Gasprice price={txDetails?.gasPrice} />
                                        <GasLimit gasLimit={831540} />
                                        <GasUsedByTansaction gasUsed={txDetails?.gasUsed} />
                                        <Nonce nonce={txDetails?.nonce} />
                                        <Inputdata inputData={txDetails?.inputdata} /> */}
                                    </div>
                                </div>
                                <span id="ContentPlaceHolder1_collapsedLink_span">
                                    <a
                                        className="d-block collapsed"
                                        id="collapsedLink"
                                        data-toggle="collapse"
                                        href="#collapsePanel"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="collapsePanel"
                                    >
                                        <span>
                                            Click to see <span className="card-arrow-more">more</span> <span className="card-arrow-less">less</span>
                                            <span className="card-btn-arrow card-btn-arrow--up ml-1">
                                                <span className="fas fa-arrow-up small my-3" />
                                            </span>
                                        </span>
                                    </a>
                                </span>
                                {/* <TxnPrivateNameTag
                                    txnNameTag={""}
                                    hash={""}
                                    getTransactionDetails={{}}
                                /> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-4 text-center" />
            </div>
        </main>
    );
};

export default TransactionDetailsLoader;
