import React, { useContext, useState } from "react";
import { IgniteContext } from "../../IgniteContext";
import CryptoJS from "crypto-js";
import axios from "axios";

const TxnPrivateNameTag = ({ txnNameTag, hash, getTransactionDetails }) => {
    const { verifyAndGetUser } = useContext(IgniteContext);
    const { isLogin, username, password } = verifyAndGetUser();

    const [txPrivateNote, setTxPrivateNote] = useState(txnNameTag ? txnNameTag : "");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    const handleTxnNote = async () => {
        if (txnNameTag && txPrivateNote === "") {
            removeTxnote();
        } else if (txnNameTag && txPrivateNote !== "") {
            updateTxnote();
        } else {
            insertTxnote();
        }
    };

    const insertTxnote = async () => {
        const encBody = CryptoJS.AES.encrypt(
            JSON.stringify({
                username: username,
                password: password,
                hash: hash,
                note: txPrivateNote,
                action: "insert",
                timestemp: Math.round(Date.now() / 1000),
            }),
            process.env.REACT_APP_PASSWORD
        ).toString();
        await axios
            .post(`${process.env.REACT_APP_BACKEND_URL}user/txnprivatenote`, {
                token: encBody,
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error : ", res.data.error);
                } else {
                    setError(false);
                    setSuccess(true);
                }
            });
    };
    const updateTxnote = async () => {
        const encBody = CryptoJS.AES.encrypt(
            JSON.stringify({
                username: username,
                password: password,
                hash: hash,
                note: txPrivateNote,
                action: "update",
                timestemp: Math.round(Date.now() / 1000),
            }),
            process.env.REACT_APP_PASSWORD
        ).toString();
        await axios
            .post(`${process.env.REACT_APP_BACKEND_URL}user/txnprivatenote`, {
                token: encBody,
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error : ", res.data.error);
                    insertTxnote();
                } else {
                    setError(false);
                    setSuccess(true);
                }
            });
    };
    const removeTxnote = async () => {
        const encBody = CryptoJS.AES.encrypt(
            JSON.stringify({
                action: "remove",
                hash: hash,
                username: username,
                password: password,
                timestemp: Math.round(Date.now() / 1000),
            }),
            process.env.REACT_APP_PASSWORD
        ).toString();
        await axios
            .post(`${process.env.REACT_APP_BACKEND_URL}user/txnprivatenote`, {
                token: encBody,
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error : ", res.data.error);
                } else {
                    setError(true);
                    setSuccess(false);
                }
            });
    };

    return (
        <div id="ContentPlaceHolder1_privatenotediv">
            <hr className="hr-space" />
            <div className="row">
                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0">
                    <i
                        className="fal fa-question-circle text-secondary d-none d-sm-inline-block mr-1"
                        data-container="body"
                        data-toggle="popover"
                        data-placement="top"
                        data-original-title
                        title
                        data-content="Private note to keep track of the transaction. Only viewable to PolygonScan's user who assign them."
                    />
                    Private Note:{" "}
                    <i
                        id="ContentPlaceHolder1_infoPrivateNote"
                        className="fa fa-info-circle text-secondary"
                        rel="tooltip"
                        data-placement="right"
                        style={{ display: "none" }}
                        title
                        data-toggle="tooltip"
                        data-original-title="You can attach a private note to this transaction which you can view when logged in"
                    />
                </div>
                {isLogin ? (
                    <div className="col-md-9">
                        <span id="ContentPlaceHolder1_privatenotedivcontent">
                            <textarea
                                rows={1}
                                cols={50}
                                spellCheck="false"
                                value={txPrivateNote}
                                style={{ color: "dimgrey" }}
                                className="inputbox form-control form-control-xs mb-1"
                                id="txtPrivateNoteArea"
                                onkeyup="javascript:Count(this);"
                                onchange="javascript:Count(this);"
                                defaultValue={""}
                                onChange={(e) => setTxPrivateNote(e.target.value)}
                                onBlur={handleTxnNote}
                            />
                            {success ? (
                                <span id="privatenotetip" className="small text-secondary">
                                    <i className="fa fa-check text-success" /> Your private Note has been successfully updated.
                                </span>
                            ) : error ? (
                                <span id="privatenotetip" className="small text-secondary">
                                    <i className="fa fa-exclamation-circle text-secondary" /> The private note was successfully removed.
                                </span>
                            ) : (
                                <span id="privatenotetip" className="small text-secondary">
                                    Tip: A private note (up to 100 characters) can be saved and is useful for transaction tracking. Please DO NOT
                                    store any passwords or private keys here.{" "}
                                </span>
                            )}
                        </span>
                    </div>
                ) : (
                    <div className="col-md-9">
                        To access the Private Note feature, you must be{" "}
                        <a href="/login?returntx=0x7dec7703861c5b0afcb2c0460d4632387f1c7fd061d7e2c27ed67ed3cf948533">Logged In</a>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TxnPrivateNameTag;
