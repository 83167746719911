import axios from "axios";
import { TokenIterator } from "brace";
import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Footer from "../common/Footer";
import Header2 from "../common/Header2";
import Pagesize from "../common/Pagesize";
import { IgniteContext } from "../IgniteContext";
import { useSearchParams } from "react-router-dom";
import Spinner from "./../common/Spinner";
import NoEntryMessage from "../common/NoEntryMessage";
import FadingLoaderCard1 from "../common/ContentLoaders/LoaderBigLine";

function ViewErc20Transfer() {
  const history = useNavigate();

  const { formatAddress, formatHash, formatTokenSymbol, formatTokenName, formatDate } =
    useContext(IgniteContext);

  const [searchParams] = useSearchParams();
  const pagefromurl = searchParams.get("p");
  const cpage = pagefromurl ? Number(pagefromurl) : 1;

  const [currentPage, setCurrentPage] = useState(cpage);
  const [PageSize, setPageSize] = useState(10);

  const [erc20TokenTxs, setErc20TokenTxs] = useState([]);
  const [totalTxs, setTotalTxs] = useState(0);
  const [pages, setPages] = useState(0);
  const [spinner, setSpinner] = useState(true);

  const [ageColChange, setAgeColChange] = useState(true);

  const firstPageIndex = (currentPage - 1) * PageSize;
  const lastPageIndex = firstPageIndex + PageSize;

  const getErc20TokenTxs = async () => {
    setSpinner(true);
    await axios
      .get(`${process.env.REACT_APP_BACKEND_URL}tokentxs/erc20`, {
        params: {
          limit: PageSize,
          page: currentPage,
        },
      })
      .then((res) => {
        if (res.data.error) {
          console.log("error in getErc20TokenTxs : ", res.data.error);
        } else {
          setErc20TokenTxs(res.data.items);
          setTotalTxs(res.data.total);
          setPages(res.data.pages);
          setSpinner(false);
        }
      })
      .catch((err) => {
        console.log("error in getErc20TokenTxs (catch) : ", err);
      });
  };

  useEffect(() => {
    getErc20TokenTxs();
  }, [currentPage, PageSize]);

  const onNext = (page) => {
    if (page === lastPageIndex) return false;
    setCurrentPage(currentPage + 1);
    history({
      pathname: "/tokentxns",
      search: "?p=" + (currentPage + 1),
    });
  };

  const onPrevious = (page) => {
    if (page === 1) return false;
    setCurrentPage(currentPage - 1);
    history({
      pathname: "/tokentxns",
      search: "?p=" + (currentPage - 1),
    });
  };

  return (
    <>
      <Header2 />
      <main id="content" role="main" style={{ minHeight: "800px" }}>
        <input type="hidden" name="hdnAgeText" id="hdnAgeText" defaultValue="Age" />
        <input type="hidden" name="hdnDateTimeText" id="hdnDateTimeText" defaultValue="Date Time (UTC)" />
        <input type="hidden" name="hdnAgeTitle" id="hdnAgeTitle" defaultValue="Click to show Age Format" />
        <input
          type="hidden"
          name="hdnDateTimeTitle"
          id="hdnDateTimeTitle"
          defaultValue="Click to show Datetime Format"
        />
        <input type="hidden" name="hdnGasPriceTitle" id="hdnGasPriceTitle" defaultValue="Gas Price in Gwei" />
        <input
          type="hidden"
          name="hdnTxnFeeTitle"
          id="hdnTxnFeeTitle"
          defaultValue="(Gas Price * Gas Used by Txns) in Matic"
        />
        <input type="hidden" name="hdnGasPriceText" id="hdnGasPriceText" defaultValue="Gas Price" />
        <input type="hidden" name="hdnTxnText" id="hdnTxnText" defaultValue="Txn Fee" />
        <div className="bg-light py-3">
          <div className="container">
            <h1 className="h4 mb-0">
              <div className="d-flex align-items-center">
                Token Transfers
                <a
                  className="u-label u-label--xs u-label--secondary rounded color-strong ml-2"
                  href="https://docs.matic.network/docs/develop/ethereum-matic/plasma/erc20"
                  target="_blank"
                  rel="nofollow noreferrer"
                  data-toggle="tooltip"
                >
                  Token Transfers
                </a>
              </div>
              <p className="mb-0 text-break small">
                <span className="small text-secondary" />
              </p>
            </h1>
          </div>
        </div>
        <div className="container space-bottom-2">
          <div className="card">
            <div className="card-body">
              <div
                id="ContentPlaceHolder1_divTopPagination"
                className="d-md-flex justify-content-between mb-4"
              >
                {totalTxs < 10000 ? (
                  <p class="mb-2 mb-md-0">
                    <i
                      id="spinwheel"
                      class="fa fa-spin fa-spinner fa-1x fa-pulse mr-1"
                      style={{ display: "none" }}
                    ></i>
                    Total&nbsp;
                    {totalTxs} txns found
                  </p>
                ) : totalTxs > 10000000 ? (
                  <p class="mb-2 mb-md-0">
                    <i
                      id="spinwheel"
                      class="fa fa-spin fa-spinner fa-1x fa-pulse mr-1"
                      style={{ display: "none" }}
                    ></i>
                    A total of &gt; 10 M txns found{" "}
                    <span class="d-block text-secondary small">(Showing the last 10,000 records only)</span>
                  </p>
                ) : (
                  <p class="mb-2 mb-md-0">
                    <i
                      id="spinwheel"
                      class="fa fa-spin fa-spinner fa-1x fa-pulse mr-1"
                      style={{ display: "none" }}
                    ></i>
                    Total {totalTxs} txns found{" "}
                    <span class="d-block text-secondary small">(Showing the last 10,000 records only)</span>
                  </p>
                )}
                {/* <p className="mb-2 mb-md-0">
                                    <i id="spinwheel" className="fa fa-spin fa-spinner fa-1x fa-pulse mr-1" style={{ display: "none" }} />A total of
                                    &gt; 10 M txns found <span className="d-block text-secondary small">(Showing the last 10,000 records only)</span>
                                </p> */}
                {/* <nav aria-label="page navigation">
            <ul className="pagination pagination-sm mb-0"><li className="page-item disabled"><span className="page-link">First</span></li><li className="page-item disabled"><span className="page-link"><i className="fa fa-chevron-left small" /></span><span className="sr-only">Previous</span></li><li className="page-item disabled"><span className="page-link text-nowrap">Page <strong className="font-weight-medium">1</strong> of <strong className="font-weight-medium">200</strong></span></li><li className="page-item" data-toggle="tooltip" title="Go to Next"><a className="page-link" href="tokentxns?p=2" aria-label="Next"><span aria-hidden="True"><i className="fa fa-chevron-right small" /></span> <span className="sr-only">Next</span></a></li><li className="page-item"><a className="page-link" href="tokentxns?p=200"><span aria-hidden="True">Last</span> <span className="sr-only">Last</span></a></li>
            </ul></nav> */}
              </div>
              <div className="js-sticky-header" id="divSTContainer">
                <div className="table-responsive mb-2 mb-md-0">
                  <table className="table table-text-normal table-hover" id="tblResult">
                    <thead className="thead-light">
                      <tr>
                        {/* <th scope="col" width={20} className="token-sticky-header" /> */}
                        <th scope="col" width={30} className="token-sticky-header">
                          Txn Hash
                        </th>
                        <th className="d-none" width={1} />
                        <th scope="col">
                          {ageColChange ? (
                            <OverlayTrigger
                              placement="top"
                              trigger="hover"
                              overlay={<Tooltip>Click to show Datetime Format</Tooltip>}
                            >
                              <Link onClick={() => setAgeColChange(!ageColChange)} id="lnkAgeDateTime">
                                Age
                              </Link>
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger
                              placement="top"
                              trigger="hover"
                              overlay={<Tooltip>Click to show Age Format</Tooltip>}
                            >
                              <Link onClick={() => setAgeColChange(!ageColChange)}>Date Time (UTC)</Link>
                            </OverlayTrigger>
                          )}
                        </th>
                        <th scope="col">From</th>
                        <th scope="col" width={30} />
                        <th scope="col">To</th>
                        <th scope="col">Value</th>
                        <th scope="col">Token</th>
                      </tr>
                    </thead>
                    <tbody>
                      {spinner ? (
                        <>
                          <tr>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                            </td>
                            <td></td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                            </td>
                            <td></td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                            </td>
                            <td></td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                            </td>
                            <td></td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                            </td>
                            <td></td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                            </td>
                            <td></td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                            </td>
                            <td></td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                            </td>
                            <td></td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                            </td>
                            <td></td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                            </td>
                            <td></td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />{" "}
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                            </td>
                            <td>
                              {" "}
                              <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                            </td>
                          </tr>
                        </>
                      ) : erc20TokenTxs && erc20TokenTxs.length ? (
                        erc20TokenTxs?.map((item, index) => {
                          const ind = currentPage * 10 - 10 + (index + 1);
                          return (
                            <tr>
                              {/* <td>
                                                                  <a
                                                                      role="button"
                                                                      tabIndex={0}
                                                                      type="button"
                                                                      className="js-txnAdditional-1 btn btn-xs btn-icon btn-soft-secondary myFnExpandBox"
                                                                  >
                                                                      <i className="far fa-eye btn-icon__inner" />
                                                                  </a>
                                                              </td> */}
                              <td>
                                <span>
                                  <Link to={`/tx/${item.hash}`} className="myFnExpandBox_searchVal">
                                    {formatHash(item.hash)}
                                  </Link>
                                </span>
                              </td>
                              <td className="">
                                {ageColChange ? (
                                  <span rel="tooltip" data-toggle="tooltip" data-placement="bottom">
                                    {item.age}
                                  </span>
                                ) : (
                                  <span rel="tooltip" data-toggle="tooltip" data-placement="bottom">
                                    {formatDate(item.timestamp)}
                                  </span>
                                )}
                              </td>
                              <td>
                                {item.isSenderContract ? (
                                  <OverlayTrigger
                                    placement="top"
                                    trigger="hover"
                                    overlay={<Tooltip>Contract</Tooltip>}
                                  >
                                    <i className="far fa-file-alt text-secondary" data-toggle="tooltip" />
                                  </OverlayTrigger>
                                ) : (
                                  ""
                                )}
                                &nbsp;
                                <OverlayTrigger
                                  placement="top"
                                  trigger="hover"
                                  overlay={<Tooltip>{item.from}</Tooltip>}
                                >
                                  <Link
                                    className=""
                                    to={`/address/${item.from}`}
                                    target="_parent"
                                    data-toggle="tooltip"
                                  >
                                    {formatAddress(item.from)}
                                  </Link>
                                </OverlayTrigger>
                              </td>
                              <td className="text-align">
                                <span className="btn btn-xs btn-icon btn-soft-success rounded-circle">
                                  <i className="fas fa-long-arrow-alt-right btn-icon__inner" />
                                </span>
                              </td>
                              <td>
                                {item.isRecieverContract ? (
                                  <OverlayTrigger
                                    placement="top"
                                    trigger="hover"
                                    overlay={<Tooltip>Contract</Tooltip>}
                                  >
                                    <i className="far fa-file-alt text-secondary" data-toggle="tooltip" />
                                  </OverlayTrigger>
                                ) : (
                                  ""
                                )}
                                &nbsp;
                                <OverlayTrigger
                                  placement="top"
                                  trigger="hover"
                                  overlay={<Tooltip>{item.to}</Tooltip>}
                                >
                                  <Link
                                    className=""
                                    to={`/address/${item.to}`}
                                    target="_parent"
                                    data-toggle="tooltip"
                                  >
                                    {formatAddress(item.to)}
                                  </Link>
                                </OverlayTrigger>
                              </td>
                              <td>{Number(item.value) > 0 ? Number(item.value).toFixed(5) : 0}</td>
                              <td>
                                <Link to={`/token/${item.token}`}>
                                  <img
                                    src={
                                      item && item?.status == "true" && item?.logo
                                        ? item?.logo
                                        : "/asset/images/48.png"
                                    }
                                    width="18px"
                                  />
                                  &nbsp;
                                  <OverlayTrigger
                                    placement="top"
                                    trigger="hover"
                                    overlay={<Tooltip>{item.name}</Tooltip>}
                                  >
                                    <span>
                                      <font color>{formatTokenName(item.name)}</font>
                                    </span>
                                  </OverlayTrigger>{" "}
                                  <OverlayTrigger
                                    placement="top"
                                    trigger="hover"
                                    overlay={<Tooltip>{item.symbol}</Tooltip>}
                                  >
                                    <span>({formatTokenSymbol(item.symbol)})</span>
                                  </OverlayTrigger>
                                </Link>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <NoEntryMessage message={process.env.REACT_APP_BLANK_DATA_MESSAGE} />
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {erc20TokenTxs && erc20TokenTxs.length ? (
                <div class="row w-100 mt-3">
                  <div class="col-md-6 mt-3">
                    <Pagesize setPageSize={setPageSize} PageSize={PageSize} />
                  </div>
                  <div class="col-md-6 mt-3">
                    <div className="float-right mr-3">
                      <div class="row">
                        <button
                          class={currentPage === 1 ? "page-link disabled" : "page-link"}
                          onClick={() => {
                            setCurrentPage(1);
                            history({
                              pathname: "/tokentxns",
                              search: "?p=" + 1,
                            });
                          }}
                          disabled={currentPage === 1}
                        >
                          First
                        </button>
                        <button
                          class={currentPage === 1 ? "page-link disabled" : "page-link"}
                          onClick={() => onPrevious(currentPage)}
                          disabled={currentPage === 1}
                        >
                          <i className="fa fa-angle-left" />
                        </button>
                        <div class="page-link-badge" disabled>
                          {`${currentPage} of ${pages}`}
                        </div>
                        <button
                          class={currentPage === pages ? "page-link disabled" : "page-link"}
                          onClick={() => onNext(currentPage)}
                          disabled={currentPage === pages}
                        >
                          <i className="fa fa-angle-right" />
                        </button>
                        <button
                          class={currentPage === pages ? "page-link disabled" : "page-link"}
                          onClick={() => {
                            setCurrentPage(pages);
                            history({
                              pathname: "/tokentxns",
                              search: "?p=" + pages,
                            });
                          }}
                          disabled={currentPage === pages}
                        >
                          Last
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* </form> */}
              <div className="d-flex justify-content-between align-items-center mt-3 mb-4">
                <div className="d-flex align-items-center text-secondary" />
                <div className="small text-muted"></div>
              </div>
            </div>
          </div>
          <div
            id="js-column_freeze_id"
            className="d-sm-none d-flex justify-content-between align-items-center mt-3"
          >
            <p className="mb-0">Turn Column Freeze on/off:</p>
            <div id="js-column_freeze_id_inner">
              <div className="js-mainbtn custom-control custom-switch mt-n3">
                <input type="checkbox" className="custom-control-input" id="js-customSwitch1" />
                <label className="custom-control-label" htmlFor="js-customSwitch1" />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default ViewErc20Transfer;
