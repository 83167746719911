import React from "react";
import Header2 from "../common/Header2";
import BlockOverview from "./BlockOverview";
import { useParams } from "react-router-dom";
const BlockDetails = () => {
    const { id } = useParams();

    return (
        <>
            <Header2 />
            <main id="content" role="main" style={{ minHeight: "800px" }}>
                <div className="container py-3">
                    <div className="d-sm-flex align-items-center">
                        <div className="mb-2 mb-sm-0">
                            <h1 className="h4 mb-0">
                                Block <span className="small text-secondary">&nbsp;{id}</span>
                                <br />
                            </h1>
                        </div>
                    </div>
                </div>
                <BlockOverview blocknum={id} />
            </main>
        </>
    );
};

export default BlockDetails;
