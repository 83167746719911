import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Spinner from "../common/Spinner";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tooltip from "react-bootstrap/Tooltip";
import { Popover } from "react-bootstrap";
import Copyicon from "../common/Copy/Copyicon";
import delay from "delay";
import Copymsg from "../common/Copy/Copymsg";
import axios from "axios";
import { IgniteContext } from "../IgniteContext";
import Footer from "../common/Footer";
import { useNavigate } from "react-router-dom";
import Web3 from "web3";
import BlockDetailsLoader from "../common/PageContentLoaders/BlockDetailsLoader";

const web3 = new Web3(process.env.REACT_APP_HTTP_RPC);

function BlockOverview({ blocknum }) {
    const { numberWithCommas } = useContext(IgniteContext);
    const navigate = useNavigate();

    const [copyAddress, setCopyAddress] = useState("");
    const [acToggle, setAcToggle] = useState(false);
    const [spinner, setSpinner] = useState(true);
    const [blockDetails, setBlockDetails] = useState(null);

    const handleAcordian = () => {
        setAcToggle(!acToggle);
    };

    const b1 = <Tooltip>{Number(blockDetails?.number) - 1}</Tooltip>;

    const b2 = <Tooltip>{Number(blockDetails?.number) + 1}</Tooltip>;

    const detail1 = (
        <Popover>
            <Popover.Body>
                Also known as Block Number. The block height, which indicates the length of the blockchain, increases after the addition of the new
                block.
            </Popover.Body>
        </Popover>
    );
    const detail2 = (
        <Popover>
            <Popover.Body>The date and time at which a block is mined.</Popover.Body>
        </Popover>
    );
    const detail3 = (
        <Popover>
            <Popover.Body>
                The number of transactions in the block. Internal transaction is transactions as a result of contract execution that involves Ether
                value.
            </Popover.Body>
        </Popover>
    );
    const detail4 = (
        <Popover>
            <Popover.Body>Miner who successfully include the block onto the blockchain.</Popover.Body>
        </Popover>
    );
    const detail5 = (
        <Popover>
            <Popover.Body>
                For each block, the miner is rewarded with a finite amount of Ether on top of the fees paid for all transactions in the block.
            </Popover.Body>
        </Popover>
    );
    const detail6 = (
        <Popover>
            <Popover.Body>The amount of effort required to mine a new block. The difficulty algorithm may adjust according to time.</Popover.Body>
        </Popover>
    );
    const detail7 = (
        <Popover>
            <Popover.Body>The amount of effort required to mine a new block. The difficulty algorithm may adjust according to time.</Popover.Body>
        </Popover>
    );
    const detail8 = (
        <Popover>
            <Popover.Body>The total gas used in the block and its percentage of gas filled in the block.</Popover.Body>
        </Popover>
    );
    const detail9 = (
        <Popover>
            <Popover.Body>Total gas limit provided by all transactions in the block.</Popover.Body>
        </Popover>
    );
    const detail10 = (
        <Popover>
            <Popover.Body>The hash of the block header of the current block.</Popover.Body>
        </Popover>
    );
    const detail11 = (
        <Popover>
            <Popover.Body>The hash of the block from which this block was generated, also known as its parent block.</Popover.Body>
        </Popover>
    );
    const detail12 = (
        <Popover>
            <Popover.Body>Block nonce is a value used during mining to demonstrate proof of work for a block.</Popover.Body>
        </Popover>
    );
    const detail13 = (
        <Popover>
            <Popover.Body>Total difficulty of the chain until this block.</Popover.Body>
        </Popover>
    );
    const detail14 = (
        <Popover>
            <Popover.Body>Any data that can be included by the miner in the block.</Popover.Body>
        </Popover>
    );
    const detail15 = (
        <Popover>
            <Popover.Body>
                Post-London Upgrade, this represents the minimum gasUsed multiplier required for a tx to be included in a block.{" "}
            </Popover.Body>
        </Popover>
    );
    const detail16 = (
        <Popover>
            <Popover.Body>The mechanism which Polygon Javascript RLP encodes an empty string.</Popover.Body>
        </Popover>
    );

    async function copyaddress() {
        setCopyAddress("copied");
        await delay(1000);
        setCopyAddress("");
    }

    const getBlockDetails = async () => {
        setSpinner(true);
        await axios
            .get(`${process.env.REACT_APP_BACKEND_URL}block/${blocknum}`)
            .then((res) => {
                if (res.data.error) {
                    // console.log("error in block details : ", res.data.error);
                } else {
                    console.log("res.data", res.data);
                    
                    setBlockDetails(res.data);
                    setSpinner(false);
                }
            })
            .catch(async (err) => {
                console.log("error in block details (catch) : ", err);
                const cblock = await web3.eth.getBlockNumber();
                if (cblock < Number(blocknum)) {
                    navigate(`/block/countdown/${blocknum}`);
                }
            });
    };

    useEffect(() => {
        getBlockDetails();
    }, [blocknum]);

    return (
        <>
            <div className="container space-bottom-2">
                <div className="card">
                    {spinner ? (
                        <BlockDetailsLoader />
                    ) : (
                        <div>
                            <div className="card-header d-flex justify-content-between align-items-center p-0">
                                <ul className="nav nav-custom nav-borderless nav_tabs1" id="nav_tabs" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" href="#overview" data-toggle="tab">
                                            Overview
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="overview" role="tabpanel" aria-labelledby="home-tab">
                                    <div id="ContentPlaceHolder1_maintable" className="card-body">
                                        <div className="row align-items-center  mt-1">
                                            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                                <OverlayTrigger placement="top" trigger="hover" overlay={detail1}>
                                                    <i
                                                        className="fal fa-question-circle text-secondary mr-1"
                                                        data-container="body"
                                                        data-toggle="popover"
                                                        data-placement="top"
                                                        data-original-title
                                                        title
                                                        data-content="Also known as Block Number. The block height, which indicates the length of the blockchain, increases after the addition of the new block."
                                                    />
                                                </OverlayTrigger>
                                                Block Height:
                                            </div>
                                            <div className="col-md-9">
                                                <div className="d-flex">
                                                    <span className="font-weight-sm-bold mr-2">
                                                        {blockDetails?.number} &nbsp;
                                                        <OverlayTrigger placement="top" trigger="hover" overlay={b1}>
                                                            <Link
                                                                className="u-label u-label--value u-label--primary rounded mr-1"
                                                                to={`/block/${Number(blockDetails?.number) - 1}`}
                                                            >
                                                                <i className="fa fa-chevron-left"></i>
                                                            </Link>
                                                        </OverlayTrigger>
                                                        <OverlayTrigger placement="top" trigger="hover" overlay={b2}>
                                                            <Link
                                                                className="u-label u-label--value u-label--primary rounded mr-1"
                                                                to={`/block/${Number(blockDetails?.number) + 1}`}
                                                            >
                                                                <i className="fa fa-chevron-right"></i>
                                                            </Link>
                                                        </OverlayTrigger>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="hr-space" />
                                        <div className="row align-items-center">
                                            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                                <OverlayTrigger placement="top" trigger="hover" overlay={detail2}>
                                                    <i
                                                        className="fal fa-question-circle text-secondary mr-1"
                                                        data-container="body"
                                                        data-toggle="popover"
                                                        data-placement="top"
                                                        data-original-title
                                                        title
                                                        data-content="The date and time at which a block is validated."
                                                    />
                                                </OverlayTrigger>
                                                Timestamp:
                                            </div>
                                            <div className="col-md-9">
                                                <i class="fal fa-clock small text-secondary" aria-hidden="true"></i>&nbsp;
                                                {blockDetails?.age} &nbsp; ({new Date(blockDetails?.timestamp * 1000).toUTCString()})
                                                {/* ({new Date(blockDetails?.timestamp).toString()}) */}
                                                {/* <span id="clock" /><span id="age"> </span><i className="far fa-clock small mr-1" /> <span id="clocks"><span>
                                                    </span></span> */}
                                            </div>
                                        </div>
                                        <div id="ContentPlaceHolder1_div_tx_fieldname">
                                            <hr className="hr-space mb-2" />
                                            <div className="row align-items-center">
                                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                                    <OverlayTrigger placement="top" trigger="hover" overlay={detail3}>
                                                        <i
                                                            className="fal fa-question-circle text-secondary mr-1"
                                                            data-container="body"
                                                            data-toggle="popover"
                                                            data-placement="top"
                                                            data-original-title
                                                            title
                                                            data-content={`The number of transactions in the block. Internal transaction is transactions as a result of contract execution that involves ${process.env.REACT_APP_TICKER} value.`}
                                                        />
                                                    </OverlayTrigger>
                                                    Transactions:
                                                </div>
                                                <div id="ContentPlaceHolder1_div_tx_fieldvalue" className="col-md-9">
                                                    {blockDetails && blockDetails?.transactions?.total ? (
                                                        <OverlayTrigger
                                                            placement="top"
                                                            trigger="hover"
                                                            overlay={<Tooltip>Click to view Transactions</Tooltip>}
                                                        >
                                                            <Link
                                                                className="u-label u-label--value u-label--primary rounded my-1"
                                                                to={{
                                                                    pathname: "/txs",
                                                                    search: "?block=" + (blockDetails?.transactions?.total ? `${blocknum}` : "0"),
                                                                }}
                                                                data-toggle="tooltip"
                                                                data-original-title
                                                            >
                                                                {blockDetails?.transactions?.total} Transactions
                                                            </Link>
                                                        </OverlayTrigger>
                                                    ) : (
                                                        " 0 Transactions "
                                                    )}
                                                    &nbsp;and{" "}
                                                    {blockDetails && blockDetails?.transactions?.internalTx ? (
                                                        <OverlayTrigger
                                                            placement="top"
                                                            trigger="hover"
                                                            overlay={<Tooltip>Click to view Internal Transactions</Tooltip>}
                                                        >
                                                            <Link
                                                                className="u-label u-label--value u-label--primary rounded my-1"
                                                                to={{
                                                                    pathname: "/txsInternal",
                                                                    search:
                                                                        "?block=" + (blockDetails?.transactions?.internalTx ? `${blocknum}` : "0"),
                                                                }}
                                                                data-toggle="tooltip"
                                                                data-original-title
                                                            >
                                                                {blockDetails?.transactions?.internalTx} contract internal transaction
                                                            </Link>
                                                        </OverlayTrigger>
                                                    ) : (
                                                        " 0 contract internal transaction "
                                                    )}{" "}
                                                    in this block.
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="hr-space mt-2" />
                                        <div className="row align-items-center">
                                            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                                <OverlayTrigger placement="top" trigger="hover" overlay={detail4}>
                                                    <i
                                                        className="fal fa-question-circle text-secondary mr-1"
                                                        data-container="body"
                                                        data-toggle="popover"
                                                        data-placement="top"
                                                        data-original-title
                                                        title
                                                        data-content="Validator who successfully include the block onto the blockchain."
                                                    />
                                                </OverlayTrigger>
                                                Validated by:
                                            </div>
                                            <div className="col-md-9">
                                                <Link to={`/address/${blockDetails?.validator}`} className="text-blue">
                                                    {blockDetails?.validator}
                                                </Link>
                                                {/* <span>{blockData.nameTag ? <span className="badge badge-light text-dark " style={{ backgroundColor: "rgba(119,131,143,.1)" }}>{blockData.nameTag}</span> : ""}{getCopyAddress === '' ? <Copyicon /> : <Copymsg />} </span> */}
                                                <CopyToClipboard
                                                    text={blockDetails?.validator}
                                                    onCopy={(e) => {
                                                        copyaddress();
                                                    }}
                                                >
                                                    <span>{copyAddress === "" ? <Copyicon /> : <Copymsg />} </span>
                                                </CopyToClipboard>
                                            </div>
                                        </div>
                                        <hr className="hr-space" />
                                        <div className="row align-items-center">
                                            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                                <OverlayTrigger placement="top" trigger="hover" overlay={detail5}>
                                                    <i
                                                        className="fal fa-question-circle text-secondary mr-1"
                                                        data-container="body"
                                                        data-toggle="popover"
                                                        data-placement="top"
                                                        data-original-title
                                                        title
                                                        data-content="The amount of effort required to mine a new block. The difficulty algorithm may adjust according to time."
                                                    />
                                                </OverlayTrigger>
                                                Block Reward:
                                            </div>
                                            <div className="col-md-9">
                                                {parseFloat(Number(blockDetails?.reward))} {process.env.REACT_APP_TICKER}
                                                {/* <span>(${parseFloat(Number(blockDetails?.liverate) * Number(blockDetails?.reward)).toFixed(4)})</span> */}
                                            </div>
                                        </div>
                                        <hr className="hr-space" />
                                        <div className="row align-items-center">
                                            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                                <OverlayTrigger placement="top" trigger="hover" overlay={detail6}>
                                                    <i
                                                        className="fal fa-question-circle text-secondary mr-1"
                                                        data-container="body"
                                                        data-toggle="popover"
                                                        data-placement="top"
                                                        data-original-title
                                                        title
                                                        data-content="The amount of effort required to mine a new block. The difficulty algorithm may adjust according to time."
                                                    />
                                                </OverlayTrigger>
                                                Difficulty:
                                            </div>
                                            <div className="col-md-9">{numberWithCommas(blockDetails?.difficulty)}</div>
                                        </div>
                                        <hr className="hr-space" />
                                        <div className="row align-items-center">
                                            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                                <OverlayTrigger placement="top" trigger="hover" overlay={detail13}>
                                                    <i
                                                        className="fal fa-question-circle text-secondary mr-1"
                                                        data-container="body"
                                                        data-toggle="popover"
                                                        data-placement="top"
                                                        data-original-title
                                                    />
                                                </OverlayTrigger>
                                                Total Difficulty:
                                            </div>
                                            <div className="col-md-9">{numberWithCommas(blockDetails?.totalDifficulty)}</div>
                                        </div>
                                        <hr className="hr-space" />
                                        <div className="row align-items-center">
                                            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                                <OverlayTrigger placement="top" trigger="hover" overlay={detail7}>
                                                    <i
                                                        className="fal fa-question-circle text-secondary mr-1"
                                                        data-container="body"
                                                        data-toggle="popover"
                                                        data-placement="top"
                                                        data-original-title
                                                        title
                                                        data-content="The block size is actually determined by the block's gas limit."
                                                    />
                                                </OverlayTrigger>
                                                Size:
                                            </div>
                                            <div className="col-md-9">{numberWithCommas(blockDetails?.size)} bytes</div>
                                        </div>
                                        <hr className="hr-space" />
                                        <div className="row align-items-center">
                                            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                                <OverlayTrigger placement="top" trigger="hover" overlay={detail8}>
                                                    <i
                                                        className="fal fa-question-circle text-secondary mr-1"
                                                        data-container="body"
                                                        data-toggle="popover"
                                                        data-placement="top"
                                                        data-original-title
                                                        title
                                                        data-content="The total gas used in the block and its percentage of gas filled in the block."
                                                    />
                                                </OverlayTrigger>
                                                Gas Used:
                                            </div>
                                            <div className="col-md-9">
                                                {numberWithCommas(blockDetails?.gasUsed)}&nbsp;(
                                                {Number(blockDetails?.gasUsedPercentage).toFixed(2) + "%"})
                                            </div>
                                        </div>
                                        <hr className="hr-space" />
                                        <div className="row align-items-center">
                                            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                                <OverlayTrigger placement="top" trigger="hover" overlay={detail9}>
                                                    <i
                                                        className="fal fa-question-circle text-secondary mr-1"
                                                        data-container="body"
                                                        data-toggle="popover"
                                                        data-placement="top"
                                                        data-original-title
                                                        title
                                                        data-content="Total gas limit provided by all transactions in the block."
                                                    />
                                                </OverlayTrigger>
                                                Gas Limit:
                                            </div>
                                            <div className="col-md-9">{numberWithCommas(blockDetails?.gasLimit)}</div>
                                        </div>
                                        <hr className="hr-space" />
                                        <div className="row align-items-center">
                                            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                                <OverlayTrigger placement="top" trigger="hover" overlay={detail15}>
                                                    <i
                                                        className="fal fa-question-circle text-secondary mr-1"
                                                        data-container="body"
                                                        data-toggle="popover"
                                                        data-placement="top"
                                                        data-original-title
                                                    />
                                                </OverlayTrigger>
                                                Base Fee Per Gas:
                                            </div>
                                            <div className="col-md-9">
                                                {blockDetails?.baseFeePerGas?.igt + " " + process.env.REACT_APP_TICKER} (
                                                {blockDetails?.baseFeePerGas?.gwei} Gwei)
                                            </div>
                                        </div>
                                        <hr className="hr-space" />
                                        <div className="row align-items-center">
                                            <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                                <OverlayTrigger placement="top" trigger="hover" overlay={detail14}>
                                                    <i
                                                        className="fal fa-question-circle text-secondary mr-1"
                                                        data-container="body"
                                                        data-toggle="popover"
                                                        data-placement="top"
                                                        data-original-title
                                                        title
                                                    />
                                                </OverlayTrigger>
                                                Extra Data:
                                            </div>
                                            <div className="col-md-9">
                                                <textarea
                                                    readOnly
                                                    spellCheck="false"
                                                    className="form-control bg-light text-secondary text-monospace p-3"
                                                    rows={6}
                                                    id="extraData"
                                                    defaultValue={"Hex:\b" + blockDetails?.extraData}
                                                />
                                            </div>
                                        </div>
                                        <hr className="hr-space" />
                                        <div className="collapse  " id="collapsePanel">
                                            <div className="row align-items-center">
                                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                                    <OverlayTrigger placement="top" trigger="hover" overlay={detail10}>
                                                        <i
                                                            className="fal fa-question-circle text-secondary mr-1"
                                                            data-container="body"
                                                            data-toggle="popover"
                                                            data-placement="top"
                                                            data-original-title
                                                            title
                                                            data-content="The hash of the block header of the current block."
                                                        />
                                                    </OverlayTrigger>
                                                    Hash:
                                                </div>
                                                <div className="col-md-9">{blockDetails?.hash}</div>
                                            </div>
                                            <hr className="hr-space" />
                                            <div className="row align-items-center">
                                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                                    <OverlayTrigger placement="top" trigger="hover" overlay={detail11}>
                                                        <i
                                                            className="fal fa-question-circle text-secondary mr-1"
                                                            data-container="body"
                                                            data-toggle="popover"
                                                            data-placement="top"
                                                            data-original-title
                                                            title
                                                            data-content="The hash of the block from which this block was generated, also known as its parent block."
                                                        />
                                                    </OverlayTrigger>
                                                    Parent Hash:
                                                </div>
                                                <div className="col-md-9">
                                                    <span>{blockDetails?.parentHash}</span>
                                                </div>
                                            </div>
                                            <hr className="hr-space" />
                                            <div className="row align-items-center">
                                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                                    <OverlayTrigger placement="top" trigger="hover" overlay={detail16}>
                                                        <i
                                                            className="fal fa-question-circle text-secondary mr-1"
                                                            data-container="body"
                                                            data-toggle="popover"
                                                            data-placement="top"
                                                            data-original-title
                                                        />
                                                    </OverlayTrigger>
                                                    Sha3Uncles:
                                                </div>
                                                <div className="col-md-9">{blockDetails?.sha3Uncles}</div>
                                            </div>
                                            <hr className="hr-space" />
                                            <div className="row align-items-center">
                                                <div className="col-md-3 font-weight-bold font-weight-sm-normal mb-1 mb-md-0e">
                                                    <OverlayTrigger placement="top" trigger="hover" overlay={detail12}>
                                                        <i
                                                            className="fal fa-question-circle text-secondary mr-1"
                                                            data-container="body"
                                                            data-toggle="popover"
                                                            data-placement="top"
                                                            data-original-title
                                                            title
                                                            data-content="Block nonce is a value used during mining to demonstrate proof of work for a block."
                                                        />
                                                    </OverlayTrigger>
                                                    Nonce:
                                                </div>
                                                <div className="col-md-9">{blockDetails?.nonce}</div>
                                            </div>
                                            <hr className="hr-space" />
                                        </div>
                                        {/* <span id="ContentPlaceHolder1_collapsedLink_span">
                                        <a className={!acToggle ? "d-block collapsed text-primary cursor-pointer" : "d-block text-primary cursor-pointer"} id="collapsedLink" data-toggle="collapse" role="button" aria-expanded={acToggle} aria-controls="collapsePanel" onClick={handleAcordian}>
                                            <span>
                                                Click to see <span className="card-arrow-more">more</span> <span className="card-arrow-less">less</span>
                                                <span className="card-btn-arrow card-btn-arrow--up ml-1">
                                                    <span className="fas fa-arrow-up small my-3" />
                                                </span>
                                            </span>
                                        </a>
                                    </span> */}
                                        <span id="ContentPlaceHolder1_collapsedLink_span">
                                            <a
                                                className="d-block collapsed"
                                                id="collapsedLink"
                                                data-toggle="collapse"
                                                href="#collapsePanel"
                                                role="button"
                                                aria-expanded="false"
                                                aria-controls="collapsePanel"
                                            >
                                                <span>
                                                    Click to see <span className="card-arrow-more">more</span>{" "}
                                                    <span className="card-arrow-less">less</span>
                                                    <span className="card-btn-arrow card-btn-arrow--up ml-1">
                                                        <span className="fas fa-arrow-up small my-3" />
                                                    </span>
                                                </span>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="comments" role="tabpanel" aria-labelledby="comments-tab">
                                    <div className="card-body">
                                        Make sure to use the "Vote Down" button for any spammy posts, and the "Vote Up" for interesting conversations.
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
}

export default BlockOverview;
