import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import Header2 from "../common/Header2";
import Pagination from "../common/Pagination";
import Pagesize from "../common/Pagesize";
import Spinner from "../common/Spinner";
import NoEntryMessage from "../common/NoEntryMessage";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { IgniteContext } from "../IgniteContext";
import { useSearchParams } from "react-router-dom";
import FadingLoaderCard1 from "../common/ContentLoaders/LoaderBigLine";
import Footer from "../common/Footer";

const ContractsVerified = () => {
    const [searchParams] = useSearchParams();
    const cpage = searchParams.get("p");

    const { formatHash, numberWithCommas } = useContext(IgniteContext);

    const history = useNavigate();

    document.title = `${process.env.REACT_APP_NAME}chain Top ContractVerified by ${process.env.REACT_APP_TICKER} Balance | ${process.env.REACT_APP_NAME}Scan`;

    const [spinner, setSpinner] = useState(true);

    const [contractverified, setContractverified] = useState([]);

    const [currentPage, setCurrentPage] = useState(cpage ? cpage : 1);

    const [totalContractverified, setTotalContractverified] = useState(0);
    const [pages, setPages] = useState(0);
    const [PageSize, setPageSize] = useState(10);

    const getContractverified = async () => {
        setSpinner(true);
        await axios
            .get(`${process.env.REACT_APP_BACKEND_URL}contracts`, {
                params: {
                    limit: PageSize,
                    page: currentPage,
                },
            })
            .then((res) => {
                if (res.data.error) {
                } else {
                    setContractverified(res.data.items);
                    setTotalContractverified(res.data.total);
                    setPages(res.data.pages);
                    setSpinner(false);
                }
            })
            .catch((err) => console.log("error in getContractverified (catch) : ", err));
    };

    useEffect(() => {
        getContractverified();
    }, [Pagesize, currentPage]);

    const onNext = (page) => {
        if (page === pages) return false;
        // setPage(n);
        setCurrentPage(currentPage + 1);
        history({
            pathname: "/contractsVerified",
            search: "?p=" + (currentPage + 1),
        });
    };

    const onPrevious = (page) => {
        if (page === 1) return false;
        setCurrentPage(currentPage - 1);

        history({
            pathname: "/contractsVerified",
            search: "?p=" + (currentPage - 1),
        });
    };

    function getDecimalPart(num) {
        if (Number.isInteger(num)) {
            return 0;
        }

        const decimalStr = num.toString().split(".")[1];
        return decimalStr;
    }

    return (
        <>
            <Header2 />
            <main id="content" role="main" style={{ minHeight: "800px" }}>
                <div className="container">
                    <div className="d-md-flex justify-content-between align-items-center py-3">
                        <div className="d-sm-flex align-items-center">
                            <div className="mb-2 mb-sm-0">
                                <h1 className="h4 font-weight-normal mb-0">
                                    Contracts <span className="small text-secondary">With verified source codes only</span>
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container space-bottom-2">
                    <div className="card">
                        <div className="card-header d-md-flex justify-content-between align-items-center">
                            <div className="d-flex flex-wrap">
                                <div className="mr-2 mb-2 mb-md-0"></div>
                                <div className="mr-2 mb-2 mb-md-0" />
                            </div>
                            <div className="d-flex ml-auto">
                                <div
                                    className="position-relative order-1 order-md-2"
                                    data-toggle="tooltip"
                                    title
                                    data-original-title="Search Contract Name"
                                >
                                    <div
                                        id="searchFilter"
                                        className="dropdown-menu dropdown-unfold dropdown-menu-sm-right p-2 u-unfold--css-animation u-unfold--hidden fadeOut"
                                        aria-labelledby="searchFilterInvoker"
                                        style={{ width: "265px", animationDuration: "300ms" }}
                                    >
                                        <form action method="get" className="js-focus-state input-group input-group-sm w-100">
                                            <input
                                                name="cn"
                                                type="search"
                                                maxLength={60}
                                                defaultValue
                                                className="form-control"
                                                placeholder="Search Contract Name"
                                            />
                                            <div className="input-group-append">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary"
                                                    data-toggle="tooltip"
                                                    data-original-title="Search Contract Name"
                                                >
                                                    Find
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive mb-2 mb-md-0">
                                {false ? (
                                    <div className="text-center"> No contacts found </div>
                                ) : (
                                    <>
                                        <p class="text-secondary"> Showing the last {"2"} verified contracts source code</p>
                                        <table className="table table-hover" id="datatable">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col" width={250}>
                                                        Address
                                                    </th>
                                                    <th scope="col">Contract Name</th>
                                                    <th scope="col">Version</th>
                                                    <th scope="col">Compiler</th>
                                                    <th scope="col">Balance</th>
                                                    <th scope="col">Txns</th>
                                                    <th scope="col">Optimization</th>
                                                    <th scope="col">
                                                        License
                                                        <a
                                                            href="/contract-license-types"
                                                            target="_blank"
                                                            data-toggle="tooltip"
                                                            title=""
                                                            data-original-title="Contract Source Code License Type, click for more info"
                                                        >
                                                            <i class="far fa-info-circle"></i>
                                                        </a>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {spinner ? (
                                                    <>
                                                        <tr>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />{" "}
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                            </td>
                                                            <td>
                                                                <FadingLoaderCard1 x="0" y="0" rx="10" ry="10" width="20" height="20" /> &nbsp;
                                                                <FadingLoaderCard1 x="0" y="0" rx="10" ry="10" width="20" height="20" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />{" "}
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                            </td>
                                                            <td>
                                                                <FadingLoaderCard1 x="0" y="0" rx="10" ry="10" width="20" height="20" /> &nbsp;
                                                                <FadingLoaderCard1 x="0" y="0" rx="10" ry="10" width="20" height="20" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />{" "}
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                            </td>
                                                            <td>
                                                                <FadingLoaderCard1 x="0" y="0" rx="10" ry="10" width="20" height="20" /> &nbsp;
                                                                <FadingLoaderCard1 x="0" y="0" rx="10" ry="10" width="20" height="20" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />{" "}
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                            </td>

                                                            <td>
                                                                <FadingLoaderCard1 x="0" y="0" rx="10" ry="10" width="20" height="20" /> &nbsp;
                                                                <FadingLoaderCard1 x="0" y="0" rx="10" ry="10" width="20" height="20" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />{" "}
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                            </td>

                                                            <td>
                                                                <FadingLoaderCard1 x="0" y="0" rx="10" ry="10" width="20" height="20" /> &nbsp;
                                                                <FadingLoaderCard1 x="0" y="0" rx="10" ry="10" width="20" height="20" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />{" "}
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                            </td>
                                                            <td>
                                                                <FadingLoaderCard1 x="0" y="0" rx="10" ry="10" width="20" height="20" /> &nbsp;
                                                                <FadingLoaderCard1 x="0" y="0" rx="10" ry="10" width="20" height="20" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />{" "}
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                            </td>
                                                            <td>
                                                                <FadingLoaderCard1 x="0" y="0" rx="10" ry="10" width="20" height="20" /> &nbsp;
                                                                <FadingLoaderCard1 x="0" y="0" rx="10" ry="10" width="20" height="20" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />{" "}
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                            </td>
                                                            <td>
                                                                <FadingLoaderCard1 x="0" y="0" rx="10" ry="10" width="20" height="20" /> &nbsp;
                                                                <FadingLoaderCard1 x="0" y="0" rx="10" ry="10" width="20" height="20" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />{" "}
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                            </td>

                                                            <td>
                                                                <FadingLoaderCard1 x="0" y="0" rx="10" ry="10" width="20" height="20" /> &nbsp;
                                                                <FadingLoaderCard1 x="0" y="0" rx="10" ry="10" width="20" height="20" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="200" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="150" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />{" "}
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="80" height="15" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="50" height="15" />
                                                            </td>

                                                            <td>
                                                                <FadingLoaderCard1 x="0" y="0" rx="10" ry="10" width="20" height="20" /> &nbsp;
                                                                <FadingLoaderCard1 x="0" y="0" rx="10" ry="10" width="20" height="20" />
                                                            </td>
                                                            <td>
                                                                {" "}
                                                                <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="70" height="15" />
                                                            </td>
                                                        </tr>
                                                    </>
                                                ) : contractverified && contractverified?.length ? (
                                                    contractverified.map((item) => {
                                                        const dec = getDecimalPart(item.balance);
                                                        const wholeNum = parseInt(item.balance);
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        trigger="hover"
                                                                        overlay={<Tooltip> Verified Code</Tooltip>}
                                                                    >
                                                                        <span
                                                                            data-toggle="tooltip"
                                                                            title
                                                                            size="2"
                                                                            data-original-title="Verified Code"
                                                                        >
                                                                            <i class="fas fa-check-circle text-success-verify mr-1"></i>
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        trigger="hover"
                                                                        overlay={<Tooltip>{item.address ? item.address : ""}</Tooltip>}
                                                                    >
                                                                        <Link to={`/address/${item.address}`}>{formatHash(item.address)}</Link>
                                                                    </OverlayTrigger>
                                                                </td>
                                                                <td>{item.name}</td>
                                                                <td>{item.version}</td>
                                                                <td>{item.compiler}</td>
                                                                <td>
                                                                    {Number(wholeNum)}&nbsp;
                                                                    {process.env.REACT_APP_TICKER}
                                                                </td>
                                                                <td>{item.txns}</td>

                                                                <td>
                                                                    <span
                                                                        aria-hidden="true"
                                                                        class="btn btn-xs btn-icon btn-soft-secondary rounded-circle"
                                                                        data-toggle="tooltip"
                                                                        title=""
                                                                        data-original-title="Optimization Enabled"
                                                                    >
                                                                        <i class="fas fa-bolt btn-icon__inner"></i>
                                                                    </span>
                                                                    &nbsp;
                                                                    <span
                                                                        aria-hidden="true"
                                                                        class="btn btn-xs btn-icon btn-soft-secondary rounded-circle"
                                                                        data-toggle="tooltip"
                                                                        title=""
                                                                        data-original-title="Constructor Arguments"
                                                                        aria-describedby="tooltip696559"
                                                                    >
                                                                        <i class="fas fa-wrench btn-icon__inner"></i>
                                                                    </span>
                                                                </td>
                                                                <td>{item.licence}</td>
                                                            </tr>
                                                        );
                                                    })
                                                ) : (
                                                    <NoEntryMessage message={process.env.REACT_APP_BLANK_DATA_MESSAGE} />
                                                )}
                                            </tbody>
                                        </table>
                                        {contractverified && contractverified?.length ? (
                                            <div class="row w-100 mt-3">
                                                <div class="col-md-6 mt-3">
                                                    <Pagesize setPageSize={setPageSize} PageSize={PageSize} />
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <div className="float-right mr-3">
                                                        <div class="row">
                                                            <button
                                                                class={currentPage === 1 ? "page-link disabled" : "page-link"}
                                                                onClick={() => {
                                                                    setCurrentPage(1);
                                                                    history({
                                                                        pathname: "/contractsVerified",
                                                                        search: "?p=" + 1,
                                                                    });
                                                                }}
                                                                disabled={currentPage === 1}
                                                            >
                                                                First
                                                            </button>
                                                            <button
                                                                class={currentPage === 1 ? "page-link disabled" : "page-link"}
                                                                onClick={() => onPrevious(currentPage)}
                                                                disabled={currentPage === 1}
                                                            >
                                                                <i className="fa fa-angle-left" />
                                                            </button>
                                                            <div class="page-link-badge" disabled>
                                                                {" "}
                                                                {`${currentPage} of ${pages}`}
                                                            </div>
                                                            <button
                                                                class={currentPage === pages ? "page-link disabled" : "page-link"}
                                                                onClick={() => onNext(currentPage)}
                                                                disabled={currentPage === pages}
                                                            >
                                                                <i className="fa fa-angle-right" />
                                                            </button>
                                                            <button
                                                                class={currentPage === pages ? "page-link disabled" : "page-link"}
                                                                onClick={() => {
                                                                    setCurrentPage(pages);
                                                                    history({
                                                                        pathname: "/contractsVerified",
                                                                        search: "?p=" + pages,
                                                                    });
                                                                }}
                                                                disabled={currentPage === pages}
                                                            >
                                                                Last
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </>
    );
};

export default ContractsVerified;
