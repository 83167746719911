import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";

const GasUsedByTansaction = ({ gasUsed }) => {
    const popoverTop10 = (
        <Popover id="popover-basic">
            {/* <Popover.Header as="h3">Popover right</Popover.Header> */}
            <Popover.Body>The exact units of gas that was used for the transaction.</Popover.Body>
        </Popover>
    );

    return (
        <>
            <div className="row">
                <div className="col-md-3 mb-1 bs  h6 text-blueish">
                    <OverlayTrigger style={{ padding: "100px 0" }} trigger="hover" placement="top" overlay={popoverTop10}>
                        <i class="fal fa-question-circle text-secondary mr-1" aria-hidden="true"></i>
                    </OverlayTrigger>{" "}
                    Gas Used By Transaction
                </div>
                <div className="col-md-4 mb-1 bs text-dark bs">
                    <span>{gasUsed ? gasUsed : <i class="text-secondary">(pending)</i>}</span>
                </div>
            </div>
            <hr />
        </>
    );
};

export default GasUsedByTansaction;
