import React from "react";
import FadingLoaderCard1 from "../ContentLoaders/LoaderBigLine";

const LatestTransactionLoader = () => {
    return (
        <>
            <div className="row">
                <div className="col-sm-4">
                    <div className="media align-items-sm-center mr-4 mb-1 mb-sm-0">
                        <div className="d-none d-sm-flex mr-2">
                            <div className="mt-0">
                                <FadingLoaderCard1 x="0" y="0" rx="25" ry="25" width="50" height="50" />
                            </div>
                        </div>
                        <div className="media-body text-left">
                            <span className="d-inline-block d-sm-none mr-1">TX#</span>&nbsp;&nbsp;&nbsp;
                            <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="100" height="15" />
                            <span className="d-block small text-secondary">
                                &nbsp;&nbsp; &nbsp;
                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="70" height="12" />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-sm-8">
                    <div className="d-sm-flex justify-content-between">
                        <div className="text-nowrap mr-4 mb-1 mb-sm-0 text-left">
                            <span>
                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="200" height="15" />
                            </span>
                            <span className="d-sm-block">
                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="110" height="12" />
                            </span>
                        </div>
                        <div>
                            <span className="u-label u-label--xs u-label--badge-in u-label--secondary text-center text-nowrap" data-toggle="tooltip">
                                <FadingLoaderCard1 x="0" y="1" rx="5" ry="5" width="30" height="15" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="hr-space" />
            <div className="row">
                <div className="col-sm-4">
                    <div className="media align-items-sm-center mr-4 mb-1 mb-sm-0">
                        <div className="d-none d-sm-flex mr-2">
                            <div className="mt-0">
                                <FadingLoaderCard1 x="0" y="0" rx="25" ry="25" width="50" height="50" />
                            </div>
                        </div>
                        <div className="media-body text-left">
                            <span className="d-inline-block d-sm-none mr-1">TX#</span>&nbsp;&nbsp;&nbsp;
                            <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="100" height="15" />
                            <span className="d-block small text-secondary">
                                &nbsp;&nbsp; &nbsp;
                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="70" height="12" />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-sm-8">
                    <div className="d-sm-flex justify-content-between">
                        <div className="text-nowrap mr-4 mb-1 mb-sm-0 text-left">
                            <span>
                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="200" height="15" />
                            </span>
                            <span className="d-sm-block">
                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="110" height="12" />
                            </span>
                        </div>
                        <div>
                            <span className="u-label u-label--xs u-label--badge-in u-label--secondary text-center text-nowrap" data-toggle="tooltip">
                                <FadingLoaderCard1 x="0" y="1" rx="5" ry="5" width="30" height="15" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="hr-space" />
            <div className="row">
                <div className="col-sm-4">
                    <div className="media align-items-sm-center mr-4 mb-1 mb-sm-0">
                        <div className="d-none d-sm-flex mr-2">
                            <div className="mt-0">
                                <FadingLoaderCard1 x="0" y="0" rx="25" ry="25" width="50" height="50" />
                            </div>
                        </div>
                        <div className="media-body text-left">
                            <span className="d-inline-block d-sm-none mr-1">TX#</span>&nbsp;&nbsp;&nbsp;
                            <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="100" height="15" />
                            <span className="d-block small text-secondary">
                                &nbsp;&nbsp; &nbsp;
                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="70" height="12" />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-sm-8">
                    <div className="d-sm-flex justify-content-between">
                        <div className="text-nowrap mr-4 mb-1 mb-sm-0 text-left">
                            <span>
                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="200" height="15" />
                            </span>
                            <span className="d-sm-block">
                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="110" height="12" />
                            </span>
                        </div>
                        <div>
                            <span className="u-label u-label--xs u-label--badge-in u-label--secondary text-center text-nowrap" data-toggle="tooltip">
                                <FadingLoaderCard1 x="0" y="1" rx="5" ry="5" width="30" height="15" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="hr-space" />
            <div className="row">
                <div className="col-sm-4">
                    <div className="media align-items-sm-center mr-4 mb-1 mb-sm-0">
                        <div className="d-none d-sm-flex mr-2">
                            <div className="mt-0">
                                <FadingLoaderCard1 x="0" y="0" rx="25" ry="25" width="50" height="50" />
                            </div>
                        </div>
                        <div className="media-body text-left">
                            <span className="d-inline-block d-sm-none mr-1">TX#</span>&nbsp;&nbsp;&nbsp;
                            <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="100" height="15" />
                            <span className="d-block small text-secondary">
                                &nbsp;&nbsp; &nbsp;
                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="70" height="12" />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-sm-8">
                    <div className="d-sm-flex justify-content-between">
                        <div className="text-nowrap mr-4 mb-1 mb-sm-0 text-left">
                            <span>
                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="200" height="15" />
                            </span>
                            <span className="d-sm-block">
                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="110" height="12" />
                            </span>
                        </div>
                        <div>
                            <span className="u-label u-label--xs u-label--badge-in u-label--secondary text-center text-nowrap" data-toggle="tooltip">
                                <FadingLoaderCard1 x="0" y="1" rx="5" ry="5" width="30" height="15" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="hr-space" />
            <div className="row">
                <div className="col-sm-4">
                    <div className="media align-items-sm-center mr-4 mb-1 mb-sm-0">
                        <div className="d-none d-sm-flex mr-2">
                            <div className="mt-0">
                                <FadingLoaderCard1 x="0" y="0" rx="25" ry="25" width="50" height="50" />
                            </div>
                        </div>
                        <div className="media-body text-left">
                            <span className="d-inline-block d-sm-none mr-1">TX#</span>&nbsp;&nbsp;&nbsp;
                            <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="100" height="15" />
                            <span className="d-block small text-secondary">
                                &nbsp;&nbsp; &nbsp;
                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="70" height="12" />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-sm-8">
                    <div className="d-sm-flex justify-content-between">
                        <div className="text-nowrap mr-4 mb-1 mb-sm-0 text-left">
                            <span>
                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="200" height="15" />
                            </span>
                            <span className="d-sm-block">
                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="110" height="12" />
                            </span>
                        </div>
                        <div>
                            <span className="u-label u-label--xs u-label--badge-in u-label--secondary text-center text-nowrap" data-toggle="tooltip">
                                <FadingLoaderCard1 x="0" y="1" rx="5" ry="5" width="30" height="15" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="hr-space" />
            <div className="row">
                <div className="col-sm-4">
                    <div className="media align-items-sm-center mr-4 mb-1 mb-sm-0">
                        <div className="d-none d-sm-flex mr-2">
                            <div className="mt-0">
                                <FadingLoaderCard1 x="0" y="0" rx="25" ry="25" width="50" height="50" />
                            </div>
                        </div>
                        <div className="media-body text-left">
                            <span className="d-inline-block d-sm-none mr-1">TX#</span>&nbsp;&nbsp;&nbsp;
                            <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="100" height="15" />
                            <span className="d-block small text-secondary">
                                &nbsp;&nbsp; &nbsp;
                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="70" height="12" />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-sm-8">
                    <div className="d-sm-flex justify-content-between">
                        <div className="text-nowrap mr-4 mb-1 mb-sm-0 text-left">
                            <span>
                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="200" height="15" />
                            </span>
                            <span className="d-sm-block">
                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="110" height="12" />
                            </span>
                        </div>
                        <div>
                            <span className="u-label u-label--xs u-label--badge-in u-label--secondary text-center text-nowrap" data-toggle="tooltip">
                                <FadingLoaderCard1 x="0" y="1" rx="5" ry="5" width="30" height="15" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="hr-space" />
            <div className="row">
                <div className="col-sm-4">
                    <div className="media align-items-sm-center mr-4 mb-1 mb-sm-0">
                        <div className="d-none d-sm-flex mr-2">
                            <div className="mt-0">
                                <FadingLoaderCard1 x="0" y="0" rx="25" ry="25" width="50" height="50" />
                            </div>
                        </div>
                        <div className="media-body text-left">
                            <span className="d-inline-block d-sm-none mr-1">TX#</span>&nbsp;&nbsp;&nbsp;
                            <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="100" height="15" />
                            <span className="d-block small text-secondary">
                                &nbsp;&nbsp; &nbsp;
                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="70" height="12" />
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-sm-8">
                    <div className="d-sm-flex justify-content-between">
                        <div className="text-nowrap mr-4 mb-1 mb-sm-0 text-left">
                            <span>
                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="200" height="15" />
                            </span>
                            <span className="d-sm-block">
                                <FadingLoaderCard1 x="0" y="0" rx="8" ry="8" width="110" height="12" />
                            </span>
                        </div>
                        <div>
                            <span className="u-label u-label--xs u-label--badge-in u-label--secondary text-center text-nowrap" data-toggle="tooltip">
                                <FadingLoaderCard1 x="0" y="1" rx="5" ry="5" width="30" height="15" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="hr-space" />
        </>
    );
};

export default LatestTransactionLoader;
