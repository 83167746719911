export const setLocalStorage = (key, value, ttl = 60 * 60 * 24 * 10) => {
    if (typeof value === "object" && Array.isArray(value) && value !== null && key) {
        let obj = { value: value, expire: ttl * 1000 };
        localStorage.setItem(key, JSON.stringify(obj));
        return true;
    } else {
        if (key && value !== null) {
            let obj = { value: value, expire: new Date().getTime() + ttl * 1000 };
            localStorage.setItem(key, JSON.stringify(obj));
            return true;
        }
    }
};
export const getLocalStorage = (key) => {
    if (key) {
        const data = localStorage.getItem(key);
        if (data) {
            const lsdata = JSON.parse(data);
            if (lsdata.expire && Number(lsdata.expire) > new Date().getTime()) {
                return lsdata?.value;
            } else {
                localStorage.removeItem(key);
            }
        } else {
            return false;
        }
    }
};
export const expireLocalStorage = () => {};
