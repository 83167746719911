import React from 'react';
// import ErrorHeader from './ErrorHeader';
import Header from '../../common/Header';
// import Overview from '../../tokens/tokenid/Overview';




function ErrorPage() {
    return (
        <>
            <Header />
            <main id="content" role="main" className="error-img" style={{ backgroundImage: "url('/asset/images/error-404.svg')" }}>
                <div className="container d-lg-flex align-items-center min-height-100vh">
                    <div className="w-lg-60 w-xl-50 justify-content-center">
                        <div className="mb-4">
                            <span className="mr-2 font-weigth-bolder fs-35"><strong>404</strong></span>
                            <br />
                            <span className="font-weight-normal fs-30">
                                <strong> Ooops !
                                    <br />Page Not Found</strong>
                            </span>
                            <p className="mb-0 text-secondary">This page dosen't exist or was removed !<br /> We suggest you to back to home.</p>
                        </div>
                        <a className="btn btn-primary btn-wide transition-3d-hover back-home-button" href="/">Back Home</a>
                    </div>
                </div>
            </main>


        </>
    )
}

export default ErrorPage;