import React, { useState, useContext, useEffect, startTransition } from "react";
import axios from "axios";
import Web3 from "web3";
import { Link, useParams, useNavigate } from "react-router-dom";
import { IgniteContext } from "../IgniteContext";
import Erc20Holdres from "./tokenholder/Erc20Holdres";
import Erc20TokenTransactions from "./tokentransactions/Erc20TokenTransactions";
import Erc721Holders from "./tokenholder/Erc721Holders";
import Erc1155Holders from "./tokenholder/Erc1155Holders";
import Erc721TokenTransactions from "./tokentransactions/Erc721TokenTransactions";
import Erc1155TokenTransactions from "./tokentransactions/Erc1155TokenTransactions";
import ContractDetailsForToken from "./contractDetails/ContractDetailsForToken";
import Spinner from "../common/Spinner";
import { useSearchParams } from "react-router-dom";
import FadingLoaderCard1 from "../common/ContentLoaders/LoaderBigLine";

const web3 = new Web3(Web3.givenProvider || process.env.REACT_APP_HTTP_RPC);

function Lists({ tokenDetails, setTransferOnId }) {
    const { isAddress, formatAddress } = useContext(IgniteContext);
    const hash = window.location.hash;

    const [searchParams] = useSearchParams();
    const paramsid = searchParams.get("a");

    const { id } = useParams();

    const [contractDeta, setContractDeta] = useState(null);
    const [contractSpinner, setContractSpinner] = useState(true);

    const getContractData = async () => {
        setContractSpinner(true);
        await axios
            .get(`${process.env.REACT_APP_BACKEND_URL}contract/${id}`)
            .then((res) => {
                if (res.data.error) {
                    console.log("error in address data : ", res.data.error);
                    setContractSpinner(false);
                } else {
                    setContractDeta(res.data);
                    setContractSpinner(false);
                }
            })
            .catch((err) => {
                console.log("error in address data (catch) : ", err);
            });
    };

    useEffect(() => {
        getContractData();
    }, [id, paramsid]);

    return (
        <>
            <div class="container">
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center p-0">
                        <ul className="nav nav-custom nav-borderless nav_tabs1" id="nav_tabs" role="tablist">
                            <li id="ContentPlaceHolder1_li_transactions" className="nav-item">
                                <Link className={`nav-link ${!hash && "active"}`} to={`/token/${id}`}>
                                    Transactions
                                </Link>
                            </li>
                            <li id="ContentPlaceHolder1_li_mine" className="nav-item">
                                <Link className={`nav-link ${hash === "#balances" && "active"}`} to={`/token/${id}#balances`}>
                                    Holders
                                </Link>
                            </li>
                            <li id="ContentPlaceHolder1_li_uncle" className="nav-item">
                                <Link
                                    className={`nav-link ${
                                        hash === "#code" || hash === "#readContract" || hash === "#writeContract" ? "active" : ""
                                    }`}
                                    to={`/token/${id}#code`}
                                >
                                    Contract &nbsp;
                                    {contractDeta?.abiCode === null && contractDeta?.sourceCode === null ? (
                                        ""
                                    ) : (
                                        <i class="fa fa-check-circle text-success"></i>
                                    )}
                                </Link>
                            </li>
                            <li id="ContentPlaceHolder1_li_analytics" className="nav-item d-none">
                                <a className="nav-link" href="#analytics" data-toggle="tab">
                                    Analytics
                                </a>{" "}
                            </li>
                        </ul>
                        {paramsid ? (
                            isAddress(paramsid) ? (
                                <div className="d-flex  order-1 order-md-2 ml-md-auto my-2">
                                    <div id="ContentPlaceHolder1_searchdiv" className="btn-group order-2 order-md-1 ml-2 mr-md-2 " role="group">
                                        <span className="btn btn-xss btn-soft-secondary ml-auto">
                                            <span
                                                className="hash-tag"
                                                data-toggle="tooltip"
                                                date-placement="top"
                                                data-original-title={`Filtered by addres : ${paramsid}`}
                                                aria-describedby="tooltip292032"
                                            >
                                                {formatAddress(paramsid)}
                                            </span>
                                        </span>
                                        <Link
                                            className="btn btn-sm btn-icon btn-soft-secondary"
                                            to={`/token/${id}`}
                                            role="button"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            // data-original-title="Clear filter Address / Txn Hash / Token ID"
                                        >
                                            <i className="fa fa-times btn-icon__inner" />
                                        </Link>
                                    </div>
                                </div>
                            ) : (
                                <div className="d-flex  order-1 order-md-2 ml-md-auto my-2">
                                    <div id="ContentPlaceHolder1_searchdiv" className="btn-group order-2 order-md-1 ml-2 mr-md-2 " role="group">
                                        <span className="btn btn-xss btn-soft-secondary ml-auto">
                                            <span
                                                className="hash-tag text-truncate"
                                                data-toggle="tooltip"
                                                date-placement="top"
                                                title
                                                data-original-title="Filtered by TokenID"
                                                aria-describedby="tooltip292032"
                                            >
                                                TokenId: {paramsid}
                                            </span>
                                        </span>
                                        <Link
                                            className="btn btn-sm btn-icon btn-soft-secondary"
                                            to={`/token/${id}`}
                                            role="button"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            // data-original-title="Clear filter Address / Txn Hash / Token ID"
                                        >
                                            <i className="fa fa-times btn-icon__inner" />
                                        </Link>
                                    </div>
                                </div>
                            )
                        ) : (
                            ""
                        )}
                    </div>
                    <div className="card-body">
                        {contractSpinner ? (
                            <Spinner />
                        ) : (
                            <div className="tab-content">
                                {hash === "" ? (
                                    tokenDetails?.type === "erc20" ? (
                                        <Erc20TokenTransactions id={id} />
                                    ) : tokenDetails?.type === "erc721" ? (
                                        <Erc721TokenTransactions id={id} setTransferOnId={setTransferOnId} />
                                    ) : tokenDetails?.type === "erc1155" ? (
                                        <Erc1155TokenTransactions id={id} setTransferOnId={setTransferOnId} />
                                    ) : (
                                        <>
                                            <table className="table table-hover">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th>
                                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="25" />{" "}
                                                        </th>
                                                        <th>
                                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="25" />{" "}
                                                        </th>
                                                        <th>
                                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="25" />{" "}
                                                        </th>
                                                        <th>
                                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="25" />{" "}
                                                        </th>
                                                        <th>
                                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="25" />{" "}
                                                        </th>
                                                        <th scope="col" width={30} />
                                                        <th>
                                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="25" />{" "}
                                                        </th>
                                                        <th>
                                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="25" />{" "}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {" "}
                                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />{" "}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />{" "}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />{" "}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />{" "}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />{" "}
                                                        </td>
                                                        <td></td>
                                                        <td>
                                                            {" "}
                                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />{" "}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />{" "}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </>
                                    )
                                ) : hash === "#balances" ? (
                                    tokenDetails?.type === "erc20" ? (
                                        <Erc20Holdres id={id} />
                                    ) : tokenDetails?.type === "erc721" ? (
                                        <Erc721Holders id={id} />
                                    ) : tokenDetails?.type === "erc1155" ? (
                                        <Erc1155Holders id={id} />
                                    ) : (
                                        <>
                                            <table className="table table-hover">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th>
                                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="25" />{" "}
                                                        </th>
                                                        <th>
                                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="25" />{" "}
                                                        </th>
                                                        <th>
                                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="25" />{" "}
                                                        </th>
                                                        <th>
                                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="25" />{" "}
                                                        </th>
                                                        <th scope="col" width={30} />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            {" "}
                                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />{" "}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />{" "}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />{" "}
                                                        </td>
                                                        <td>
                                                            {" "}
                                                            <FadingLoaderCard1 x="0" y="3" rx="3" ry="3" width="100" height="15" />{" "}
                                                        </td>
                                                        <td></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </>
                                    )
                                ) : hash === "#code" || hash === "#readContract" || hash === "#writeContract" ? (
                                    contractDeta === null ? (
                                        ""
                                    ) : contractSpinner ? (
                                        <Spinner />
                                    ) : (
                                        <ContractDetailsForToken contractDeta={contractDeta} hash={hash} id={id} />
                                    )
                                ) : (
                                    ""
                                )}
                                <div className="tab-pane fade" id="analytics">
                                    <p>Analytics data </p>
                                </div>
                                <div className="tab-pane fade in" id="comments">
                                    <div>
                                        <p>
                                            {" "}
                                            Make sure to use the "Vote Down" button for any spammy posts, and the "Vote Up" for interesting
                                            conversations.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Lists;

// leave joveshe next week ma 6 and 7 February (Monday and Tuesday) marriage che.
