import React from "react";

function HelpfulLinks() {
    return (
        <>
            <div class="col-md-3">
                <div id="ContentPlaceHolder1_divUsefulLink" class="card">
                    <div class="card-header">
                        <h2 class="card-header-title">Helpful Links</h2>
                    </div>
                    <div class="card-body">
                        <h3 class="h6 mb-0">Wallets:</h3>
                        <div class="mx-2 mb-4">
                            <ul class="list-group list-group-flush mb-0">
                                <li class="list-group-item font-size-base">
                                    <a class="link-hover-secondary" rel="nofollow noopener" href="/#">
                                        <i class="fa fa fa-external-link-alt small mr-1"></i>TrustWallet (Mobile)
                                    </a>{" "}
                                    <sup>
                                        <span class="badge badge-success ml-1"> Popular</span>
                                    </sup>
                                </li>
                                <li class="list-group-item font-size-base">
                                    <a class="link-hover-secondary" rel="nofollow noopener" href="/#">
                                        <i class="fa fa fa-external-link-alt small mr-1"></i>
                                        {process.env.REACT_APP_NAME}Chain Extension Wallet
                                    </a>
                                </li>
                                <li class="list-group-item font-size-base">
                                    <a class="link-hover-secondary" rel="nofollow noopener" href="/#">
                                        <i class="fa fa fa-external-link-alt small mr-1"></i>Metamask (Browser web3)
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <h3 class="h6 mb-0">Others:</h3>
                        <div class="mx-2">
                            <ul class="list-group list-group-flush mb-0">
                                <li class="list-group-item font-size-base">
                                    <a class="link-hover-secondary" href="/#">
                                        <i class="fa fa fa-external-link-alt small mr-1"></i>What is {process.env.REACT_APP_NAME}Scan?
                                    </a>
                                </li>
                                <li class="list-group-item font-size-base">
                                    <a class="link-hover-secondary" rel="nofollow noopener" href="/#">
                                        <i class="fa fa fa-external-link-alt small mr-1"></i>How to receive and send {process.env.REACT_APP_TICKER} on
                                        {process.env.REACT_APP_NAME}scan
                                    </a>
                                </li>
                                <li class="list-group-item font-size-base">
                                    <a class="link-hover-secondary" rel="nofollow noopener" href="/#">
                                        <i class="fa fa fa-external-link-alt small mr-1"></i>Connecting MetaMask to BSC Mainnet
                                    </a>
                                </li>
                                <li class="list-group-item font-size-base">
                                    <a class="link-hover-secondary" rel="nofollow noopener" href="/#">
                                        <i class="fa fa fa-external-link-alt small mr-1"></i>
                                        {process.env.REACT_APP_TICKER} Smart Chain Official Site
                                    </a>
                                </li>
                                <li class="list-group-item font-size-base">
                                    <a class="link-hover-secondary" href="/#">
                                        <i class="fa fa fa-external-link-alt small mr-1"></i>
                                        {process.env.REACT_APP_NAME}Scan Priority Support
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HelpfulLinks;
