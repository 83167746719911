import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import delay from "delay";
import AceEditor from "react-ace";
import ReadFromContract from "./ReadFromContract";
import WriteInContract from "./WriteInContract";

const ContractDetailsForToken = ({ contractDeta, hash, id }) => {
    const [copySourceCode, setCopySourceCode] = useState(false);
    const [copyAbi, setCopyAbi] = useState(false);
    const [copyBytecode, setCopyByteCode] = useState(false);

    const copyContent = async (type) => {
        if (type === "sourceCode") {
            setCopySourceCode(true);
            await delay(1000);
            setCopySourceCode(false);
        } else if (type === "abi") {
            setCopyAbi(true);
            await delay(1000);
            setCopyAbi(false);
        } else if (type === "bytecode") {
            setCopyByteCode(true);
            await delay(1000);
            setCopyByteCode(false);
        } else {
            return false;
        }
    };
    return contractDeta?.abiCode === null && contractDeta?.sourceCode === null ? (
        <>
            <span class="mb-3">
                <span class="h5 mb-0">
                    {" "}
                    <i class="fa fa-info-circle text-primary"></i>{" "}
                </span>{" "}
                Are you the contract creator?
                <Link to={{ pathname: "/verifyContract", search: `?a=${contractDeta?.address}` }}>
                    &nbsp;<b className="text-primary">Verify and Publish</b>
                </Link>
                &nbsp; your contract source code today!
                <br />
            </span>

            <textarea
                readOnly
                spellCheck="false"
                className="form-control bg-light text-black text-monospace mt-3 p-1"
                rows={12}
                id="extraData"
                defaultValue={contractDeta?.code}
            />
        </>
    ) : (
        <>
            <div className="row">
                <div className="col-md-12">
                    <ul className="nav nav-pills mb-4 light">
                        <ul className="nav nav-pills mb-4 light">
                            <Link to={`/token/${contractDeta?.address}#code`} className={`nav-link mx-1 ${hash === "#code" ? "active" : ""}`} active>
                                <li className=" nav-item">Code</li>
                            </Link>

                            <Link
                                to={`/token/${contractDeta?.address}#readContract`}
                                className={`nav-link mx-1 ${hash === "#readContract" ? "active" : ""}`}
                            >
                                <li className="nav-item">Read</li>
                            </Link>

                            <Link
                                to={`/token/${contractDeta?.address}#writeContract`}
                                className={`nav-link mx-1 ${hash === "#writeContract" ? "active" : ""}`}
                                type="button"
                            >
                                <li className="nav-item">Write</li>
                            </Link>
                        </ul>
                    </ul>
                    <div className="tab-content">
                        {hash === "#code" ? (
                            <div id="code" className="tab-pane active">
                                <div className="card">
                                    <div class="card-body">
                                        <div className="p-2 mb-1 bs">
                                            <span>
                                                <i className="fa fa-check-circle badge-text"></i> Source Code Verified
                                            </span>
                                        </div>
                                        <div class="row mt-3">
                                            <div className="col-md-6">
                                                <div class="row">
                                                    <div className="col-md-6 mb-1 h6 text-blueish">Contract Name:</div>
                                                    <div className="col-md-6 h6 font-weight-bold mb-0">{contractDeta?.contractName}</div>
                                                </div>
                                                <hr class="hr-space" />
                                                {contractDeta?.optimization ? (
                                                    <div class="row">
                                                        <div className="col-md-6 mb-1 h6 text-blueish">Optimization:</div>
                                                        <div className="col-md-6 mb-1 bs h6 mb-0">
                                                            {contractDeta.optimization ? (
                                                                <>
                                                                    <b>Yes</b> with <b>{contractDeta.optimization}</b> runs
                                                                </>
                                                            ) : (
                                                                "No"
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>

                                            <div className="col-md-6">
                                                {/* <div className="col-md-6"> */}
                                                <div class="row">
                                                    <div className="col-md-6 mb-1 h6 text-blueish">Compiler version:</div>
                                                    <div className="col-md-6 mb-1 bs h6 font-weight-bold mb-0">{contractDeta?.compiler} </div>
                                                </div>
                                                {/* </div> */}
                                                <hr class="hr-space" />
                                                <div className="row"></div>
                                                {/* <div class="col-md-6"></div> */}
                                            </div>
                                        </div>

                                        <div class="mt-5 mb-3 d-flex justify-content-between">
                                            <span class="font-weight-bold h6">
                                                {" "}
                                                <i className="far fa-file-code text-secondary mr-1"></i> Contract Source Code{" "}
                                            </span>
                                            <CopyToClipboard text={contractDeta?.sourceCode} onCopy={() => copyContent("sourceCode")}>
                                                {/* eslint-disable-next-line */}
                                                <a
                                                    class="js-clipboard btn btn-sm btn-icon btn-secondary mr-1 text-light pt-2"
                                                    data-toggle="tooltip"
                                                    title
                                                    data-original-title="Copy source code to clipboard"
                                                >
                                                    {copySourceCode ? <i className="fa fa-check" /> : <i className="fal fa-copy" />}{" "}
                                                </a>
                                            </CopyToClipboard>
                                        </div>

                                        <div className="row pl-3 ">
                                            <div className="editor">
                                                <AceEditor
                                                    placeholder="Placeholder Text"
                                                    mode="javascript"
                                                    name="blah2"
                                                    showPrintMargin={false}
                                                    width={1275}
                                                    height={255}
                                                    showGutter={true}
                                                    highlightActiveLine={true}
                                                    value={contractDeta?.sourceCode}
                                                    minLines={10}
                                                    setOptions={{
                                                        enableBasicAutocompletion: false,
                                                        enableLiveAutocompletion: false,
                                                        enableSnippets: false,
                                                        showLineNumbers: true,
                                                        readOnly: true,
                                                        tabSize: 2,
                                                    }}
                                                    style={{ backgroundColor: "#f9f9f9", fontSize: "87.5%" }}
                                                />
                                            </div>
                                        </div>

                                        <div class="mt-5 mb-3 d-flex justify-content-between">
                                            <span class="font-weight-bold h6">
                                                <i className="fa fa-tasks text-secondary mr-1"></i> Contract ABI
                                            </span>
                                            <CopyToClipboard text={contractDeta.abiCode} onCopy={() => copyContent("abi")}>
                                                {/* eslint-disable-next-line */}
                                                <a
                                                    href="javascript:void(0);"
                                                    class="js-clipboard btn btn-sm btn-icon btn-secondary mr-1 text-light pt-2"
                                                    data-toggle="tooltip"
                                                    title
                                                    data-original-title="Copy source code to clipboard"
                                                >
                                                    {copyAbi ? <i className="fa fa-check" /> : <i className="fal fa-copy" />}
                                                </a>

                                                {/* <span>{copyAbi ? <Copymsg /> : <Copyicon />}</span> */}
                                            </CopyToClipboard>
                                        </div>

                                        <div className="row pl-3 ">
                                            <textarea
                                                readOnly
                                                spellCheck="false"
                                                className="form-control bg-light text-black text-monospace p-3 text-black small"
                                                rows={6}
                                                id="extraData"
                                                defaultValue={contractDeta?.abiCode}
                                            />
                                        </div>

                                        <div class="mt-5 mb-3 d-flex justify-content-between">
                                            <span class="font-weight-bold h6">
                                                <i className="fa fa-code text-secondary mr-1"></i> Contract Creation Code
                                            </span>
                                            <CopyToClipboard text={contractDeta.bytecode} onCopy={() => copyContent("bytecode")}>
                                                {/* eslint-disable-next-line */}
                                                <a
                                                    class="js-clipboard btn btn-sm btn-icon btn-secondary mr-1 text-light pt-2"
                                                    data-toggle="tooltip"
                                                    title
                                                    data-original-title="Copy source code to clipboard"
                                                >
                                                    {copyBytecode ? <i className="fa fa-check" /> : <i className="fal fa-copy" />}
                                                </a>

                                                {/* <span>{copyBytecode ? <Copymsg /> : <Copyicon />}</span> */}
                                            </CopyToClipboard>
                                        </div>

                                        <div className="row pl-3 ">
                                            <textarea
                                                readOnly
                                                spellCheck="false"
                                                className="form-control bg-light text-black text-monospace p-3 text-black small"
                                                rows={10}
                                                id="extraData"
                                                defaultValue={contractDeta.bytecode}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : hash === "#readContract" ? (
                            <ReadFromContract id={id} />
                        ) : hash === "#writeContract" ? (
                            <WriteInContract id={id} contractDeta={contractDeta} />
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContractDetailsForToken;
