import React from "react";



const Pagination = ({ currentPage = 1, setCurrentPage, totalPage = 2 }) => {

    const onNext = (page) => {
        if (page === totalPage) return false
        setCurrentPage(currentPage + 1)
    }

    const onPrevious = (page) => {
        if (page === 1) return false;
        setCurrentPage(currentPage - 1)
    }
    return (
        <>
            <div className="float-right mr-3">
                <div className="row">
                    <button className={currentPage === 1 ? "page-link disabled" : "page-link"} onClick={() => setCurrentPage(1)} disabled={currentPage === 1}>First</button>

                    <button className={currentPage === 1 ? "page-link disabled" : "page-link"} onClick={() => onPrevious(currentPage)} disabled={currentPage === 1}><i className="fa fa-angle-left" /></button>

                    <div className="page-link-badge" disabled> {`${currentPage} of ${totalPage}`}</div>

                    <button className={currentPage === totalPage ? "page-link disabled" : "page-link"} onClick={() => onNext(currentPage)} disabled={currentPage === totalPage}><i className="fa fa-angle-right" /></button>

                    <button className={currentPage === totalPage ? "page-link disabled" : "page-link"} onClick={() => setCurrentPage(totalPage)} disabled={currentPage === totalPage}>Last</button>
                </div>
            </div>

        </>
    )
}

export default Pagination;