import React, { useContext, useState, useEffect } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Header2 from "../common/Header2";
import Sidebar from "../common/Sidebar";
import Pagination from "../common/Pagination";
import Spinner from "../common/Spinner";
import { IgniteContext } from "../IgniteContext";
import CryptoJS from "crypto-js";
import moment from "moment";
import { getLocalStorage } from "../../utils";

function Mynotes_tx() {
    const [searchParams] = useSearchParams();
    const cpage = Number(searchParams.get("p"));
    const { isHash } = useContext(IgniteContext);

    const navigate = useNavigate();

    const [userTxnNotes, setUserTxnNotes] = useState([]);
    const [totalTxnNotes, setTotalTxnNotes] = useState(0);
    const PageSize = 10;
    const [currentPage, setCurrentPage] = useState(cpage ? cpage : 1);
    const [pages, setPages] = useState(0);
    const [spinner, setSpinner] = useState(true);

    const [editTxnHash, setEditTxnHash] = useState("");
    const [deleteTxnHash, setDeleteTxnHash] = useState("");

    const [txnHash, setTxnHash] = useState("");
    const [txnNote, setTxnNote] = useState("");
    const [txnNoteErr, setTxnNoteErr] = useState(null);
    const [txnHashErr, setTxnHashErr] = useState(null);

    const [errorMsg, setErrorMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");

    const username = CryptoJS.AES.decrypt(getLocalStorage("ua5s21san"), process.env.REACT_APP_PASSWORD).toString(CryptoJS.enc.Utf8);
    const password = CryptoJS.AES.decrypt(getLocalStorage("pn4sec3f1s"), process.env.REACT_APP_PASSWORD).toString(CryptoJS.enc.Utf8);

    async function getTxNotes() {
        setSpinner(true);
        const encBody = CryptoJS.AES.encrypt(
            JSON.stringify({
                action: "get",
                page: currentPage,
                limit: PageSize,
                username: username,
                password: password,
                timestemp: Math.round(Date.now() / 1000),
            }),
            process.env.REACT_APP_PASSWORD
        ).toString();
        await axios
            .post(`${process.env.REACT_APP_BACKEND_URL}user/txnprivatenote`, {
                token: encBody,
            })
            .then((res) => {
                if (res.data.error) {
                    console.log("error in getTxNotes : ", res.data.error);
                } else {
                    setUserTxnNotes(res.data.items);
                    setTotalTxnNotes(res.data.total);
                    setPages(res.data.pages);
                    setSpinner(false);
                }
            })
            .catch((err) => {
                console.log("error in getTxNotes (catch) : ", err);
            });
    }

    useEffect(() => {
        getTxNotes();
    }, [currentPage]);

    const handleTxnHashChange = (val) => {
        setTxnHash(val);
        if (val === "") {
            setTxnHashErr("Require");
        } else {
            if (!isHash(val)) {
                setTxnHashErr("Invalid Txn hash.");
            } else {
                setTxnHashErr("");
            }
        }
    };
    const handleTxnNoteChange = (val) => {
        setTxnNote(val);
        if (val === "") {
            setTxnNoteErr("Require.");
        } else {
            setTxnNoteErr("");
        }
    };

    async function addNotes(e) {
        e.preventDefault();
        setSuccessMsg("");
        setErrorMsg("");
        let temp = 1;
        if (txnHash === "") {
            setTxnHashErr("Require");
            temp = 0;
        } else {
            if (!isHash(txnHash)) {
                setTxnHashErr("Invalid Txn hash.");
                temp = 0;
            } else {
                setTxnHashErr("");
            }
        }
        if (txnNote === "") {
            setTxnNoteErr("Require.");
            temp = 0;
        } else {
            setTxnNoteErr("");
        }
        if (temp) {
            const encBody = CryptoJS.AES.encrypt(
                JSON.stringify({
                    username: username,
                    password: password,
                    hash: txnHash,
                    note: txnNote,
                    action: "insert",
                    page: currentPage,
                    limit: PageSize,
                    timestemp: Math.round(Date.now() / 1000),
                }),
                process.env.REACT_APP_PASSWORD
            ).toString();
            await axios
                .post(`${process.env.REACT_APP_BACKEND_URL}user/txnprivatenote`, {
                    token: encBody,
                })
                .then((res) => {
                    if (res.data.error) {
                        setErrorMsg(res.data.error);
                    } else {
                        setSuccessMsg(res.data.message);
                        getTxNotes();
                        getTxNotes();
                    }
                });
        }
    }

    async function removeNote() {
        const encBody = CryptoJS.AES.encrypt(
            JSON.stringify({
                action: "remove",
                hash: deleteTxnHash,
                username: username,
                password: password,
                page: currentPage,
                limit: PageSize,
                timestemp: Math.round(Date.now() / 1000),
            }),
            process.env.REACT_APP_PASSWORD
        ).toString();
        await axios
            .post(`${process.env.REACT_APP_BACKEND_URL}user/txnprivatenote`, {
                token: encBody,
            })
            .then((res) => {
                if (res.data.error) {
                    setErrorMsg(res.data.error);
                } else {
                    setSuccessMsg(res.data.message);
                    setDeleteTxnHash("");
                    getTxNotes();
                    getTxNotes();
                }
            });
    }

    const handleEditTxnNote = (hash, note) => {
        setTxnHash(hash);
        setEditTxnHash(hash);
        setTxnHashErr(null);
        setTxnNote(note);
        setTxnNoteErr(null);
        setErrorMsg("");
        setSuccessMsg("");
    };

    async function updateNotes(e) {
        e.preventDefault();
        setSuccessMsg("");
        setErrorMsg("");
        let temp = 1;
        if (txnHash === "") {
            setTxnHashErr("Require");
            temp = 0;
        } else {
            if (!isHash(txnHash)) {
                setTxnHashErr("Invalid Txn hash.");
                temp = 0;
            } else {
                setTxnHashErr("");
            }
        }
        if (txnNote === "") {
            setTxnNoteErr("Require.");
            temp = 0;
        } else {
            setTxnNoteErr("");
        }
        if (temp) {
            const encBody = CryptoJS.AES.encrypt(
                JSON.stringify({
                    username: username,
                    password: password,
                    hash: txnHash,
                    note: txnNote,
                    action: "update",
                    page: currentPage,
                    limit: PageSize,
                    timestemp: Math.round(Date.now() / 1000),
                }),
                process.env.REACT_APP_PASSWORD
            ).toString();
            await axios
                .post(`${process.env.REACT_APP_BACKEND_URL}user/txnprivatenote`, {
                    token: encBody,
                })
                .then((res) => {
                    if (res.data.error) {
                        setErrorMsg(res.data.error);
                    } else {
                        setSuccessMsg(res.data.message);
                        setEditTxnHash("");
                        getTxNotes();
                        getTxNotes();
                    }
                });
        }
    }

    const clearState = (e) => {
        e.preventDefault();
        setErrorMsg("");
        setSuccessMsg("");
        setTxnHash("");
        setTxnNote("");
        setTxnHashErr(null);
        setTxnNoteErr(null);
        setEditTxnHash("");
    };

    const onNext = (page) => {
        if (page === pages) return false;
        setCurrentPage(Number(currentPage) + 1);
        navigate({
            pathname: "/mynotes_tx",
            search: "?p=" + (currentPage + 1),
        });
    };

    const onPrevious = (page) => {
        if (page === 1) return false;
        setCurrentPage(Number(currentPage) - 1);
        navigate({
            pathname: "/mynotes_tx",
            search: "?p=" + (currentPage - 1),
        });
    };

    return (
        <>
            <Header2 />
            <main id="content" role="main" style={{ minHeight: "780px" }}>
                <input type="hidden" name="hdnAgeText" id="hdnAgeText" defaultValue="Age" />
                <input type="hidden" name="hdnDateTimeText" id="hdnDateTimeText" defaultValue="Date Time (UTC)" />
                <input type="hidden" name="hdnAgeTitle" id="hdnAgeTitle" defaultValue="Click to show Age Format" />
                <input type="hidden" name="hdnDateTimeTitle" id="hdnDateTimeTitle" defaultValue="Click to show Datetime Format" />
                <div className="container space-1">
                    <div className="row">
                        <div className="col-md-3">
                            <Sidebar />
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="media">
                                        <span className="btn btn-sm btn-icon btn-soft-info rounded-circle mb-2 mr-2">
                                            <span className="fas fa-info btn-icon__inner" />
                                        </span>
                                        <div className="media">
                                            <h2 className="h5">Txn Notes?</h2>
                                        </div>
                                    </div>
                                    <p className="mb-0">
                                        A private transaction note can be saved and is useful for transaction tracking. <br />
                                        <br />
                                        This information is only visible to you.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="card">
                                <div className="card-header d-md-flex justify-content-between align-items-center">
                                    <div className="d-flex flex-wrap">
                                        <div className="mr-2 mb-2 mb-md-0">
                                            <h2 className="card-header-title mt-1">My Transactions Private Notes</h2>
                                        </div>
                                        <div className="mr-2 mb-2 mb-md-0">
                                            <a
                                                href="#"
                                                id="ContentPlaceHolder1_addnew"
                                                data-toggle="modal"
                                                data-target="#responsive"
                                                rel="tooltip"
                                                title
                                                className="btn btn-xss btn-primary rounded cursor-pointer"
                                                onclick="addNewNote();"
                                                data-original-title="Add a new transaction private note"
                                                onClick={clearState}
                                            >
                                                <i className="fa fa-plus-square fa-lg mr-1" style={{ marginLeft: "-3px", marginBottom: "3px" }} />
                                                Add
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                {spinner ? (
                                    <Spinner />
                                ) : (
                                    <div className="card-body">
                                        <div className="d-md-flex justify-content-lg-between align-items-center mb-4">
                                            <div className="col-lg-6 mb-lg-0 mb-4">
                                                <p className="mb-0 d-flex justify-content-lg-start justify-content-center">
                                                    <i
                                                        id="spinwheel"
                                                        className="fa fa-spin fa-spinner fa-1x fa-pulse"
                                                        style={{ display: "none", marginTop: "4px", marginRight: "4px" }}
                                                    >
                                                        &nbsp;
                                                    </i>
                                                    &nbsp;{totalTxnNotes} used (Out of 2000 max quota)
                                                </p>
                                            </div>
                                            <div className="col-lg-6 justify-content-lg-center">
                                                <div class="row w-100">
                                                    <div class="col-12">
                                                        <div>
                                                            <div class="row justify-content-lg-end justify-content-center">
                                                                <button
                                                                    class={currentPage === 1 ? "page-link disabled" : "page-link"}
                                                                    onClick={() => {
                                                                        setCurrentPage(1);
                                                                        navigate({
                                                                            pathname: "/mynotes_tx",
                                                                            search: "?p=" + 1,
                                                                        });
                                                                    }}
                                                                    disabled={currentPage === 1}
                                                                >
                                                                    First
                                                                </button>
                                                                <button
                                                                    class={currentPage === 1 ? "page-link disabled" : "page-link"}
                                                                    onClick={() => onPrevious(currentPage)}
                                                                    disabled={currentPage === 1}
                                                                >
                                                                    <i className="fa fa-angle-left" />
                                                                </button>
                                                                <div class="page-link-badge" disabled>
                                                                    {" "}
                                                                    {`${currentPage} of ${pages}`}
                                                                </div>
                                                                <button
                                                                    class={currentPage === pages ? "page-link disabled" : "page-link"}
                                                                    onClick={() => onNext(currentPage)}
                                                                    disabled={currentPage === pages}
                                                                >
                                                                    <i className="fa fa-angle-right" />
                                                                </button>
                                                                <button
                                                                    class={currentPage === pages ? "page-link disabled" : "page-link"}
                                                                    onClick={() => {
                                                                        setCurrentPage(pages);
                                                                        navigate({
                                                                            pathname: "/mynotes_tx",
                                                                            search: "?p=" + pages,
                                                                        });
                                                                    }}
                                                                    disabled={currentPage === pages}
                                                                >
                                                                    Last
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="table-responsive mb-2 mb-md-0">
                                            <div className="mx-md-auto">
                                                <figure id="SVGdataReport1" className="mb-7 ie-data-report">
                                                    <table className="table">
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col">Action</th>
                                                                <th scope="col">Transaction Private Note</th>
                                                                <th
                                                                    scope="col"
                                                                    data-toggle="tooltip"
                                                                    title
                                                                    data-original-title="UTC TimeZone (yyyy-mm-dd)"
                                                                >
                                                                    Created
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {userTxnNotes && userTxnNotes?.length ? (
                                                                userTxnNotes?.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td className="align-top">
                                                                                <button
                                                                                    data-toggle="modal"
                                                                                    data-target="#responsive"
                                                                                    className="btn btn-outline-secondary btn-xss html-link-custom mr-1 cursor-pointer"
                                                                                    rel="tooltip"
                                                                                    onClick={() => handleEditTxnNote(item.hash, item.note)}
                                                                                >
                                                                                    View
                                                                                </button>
                                                                                <button
                                                                                    data-toggle="modal"
                                                                                    data-target="#myModaldelete"
                                                                                    className="btn btn-outline-secondary btn-xss html-link-custom cursor-pointer"
                                                                                    onClick={() => setDeleteTxnHash(item.hash)}
                                                                                >
                                                                                    Remove
                                                                                </button>
                                                                            </td>
                                                                            <td className="align-top">
                                                                                <span className="d-block">
                                                                                    <Link to={`/tx/${item.hash}`}>
                                                                                        <span className="text-primary">{item.hash}</span>
                                                                                    </Link>
                                                                                </span>
                                                                                <span className="d-block small text-break">{item.note}</span>
                                                                            </td>
                                                                            <td className="align-top">
                                                                                {moment(item.createdAt).format("YYYY-MM-DD")}
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : (
                                                                <tr>
                                                                    <td colspan="3" className="align-top">
                                                                        <center>
                                                                            <div className="alert alert-secondary mt-4" role="alert">
                                                                                <i class="fal fa-info-circle"></i>{" "}
                                                                                <span className="text-dark">There are no private notes found</span>
                                                                            </div>
                                                                        </center>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" id="responsive">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header p-2">
                                <h5 className="pl-3 modal-title h6 font-weight-bold text-break w-100 mt-2 mb-2" id="myresponsiveLabel">
                                    My Transaction Private Note
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                {errorMsg ? (
                                    <span id="spanResult">
                                        <div className="alert alert-danger alert-dismissible">
                                            <strong>Status:</strong> Error! {errorMsg}{" "}
                                            <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                <span aria-hidden="True">×</span>
                                            </button>
                                        </div>
                                    </span>
                                ) : (
                                    ""
                                )}
                                {successMsg ? (
                                    <span id="spanResult">
                                        <div className="alert alert-success alert-dismissible">
                                            <strong>Status:</strong> Success! {successMsg}{" "}
                                            <button
                                                type="button"
                                                className="close"
                                                data-dismiss="alert"
                                                aria-label="Close"
                                                onClick={() => setEditTxnHash("")}
                                            >
                                                <span aria-hidden="True">×</span>
                                            </button>
                                        </div>
                                    </span>
                                ) : (
                                    ""
                                )}
                                <div className="form-group row">
                                    <label className="col-sm-12 mt-12" htmlFor="Txnhash">
                                        &nbsp;Txn Hash :
                                    </label>
                                    <div className="js-form-message input-group input-group-sm col-sm-12">
                                        <input
                                            name="ctl00$ContentPlaceHolder1$txtTxnHash"
                                            type="text"
                                            maxLength={66}
                                            id="ContentPlaceHolder1_txtTxnHash"
                                            className={
                                                txnHashErr === null
                                                    ? "form-control form-control-sm"
                                                    : txnHashErr === ""
                                                    ? "form-control form-control-sm success"
                                                    : "form-control form-control-sm error"
                                            }
                                            readOnly={editTxnHash ? true : false}
                                            data-msg="Please enter a valid Txn hash"
                                            placeholder="Enter a Txn hash"
                                            value={txnHash}
                                            onChange={(e) => {
                                                handleTxnHashChange(e.target.value);
                                            }}
                                        />
                                        {txnHashErr && (
                                            <div id="ContentPlaceHolder1_txtUserName-error" class="invalid-feedback" style={{ display: "Block" }}>
                                                {txnHashErr}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-12 mt-12" htmlFor="Txnhash">
                                        &nbsp;View / Update Private Note :
                                    </label>
                                    <div className="input-group input-group-sm col-sm-12">
                                        <textarea
                                            name="ctl00$ContentPlaceHolder1$txtPrivateNoteArea"
                                            value={txnNote}
                                            rows={2}
                                            cols={20}
                                            id="ContentPlaceHolder1_txtPrivateNoteArea"
                                            spellCheck="false"
                                            className={
                                                txnNoteErr === null
                                                    ? "form-control form-control-sm"
                                                    : txnNoteErr === ""
                                                    ? "form-control form-control-sm success"
                                                    : "form-control form-control-sm error"
                                            }
                                            onChange={(e) => {
                                                handleTxnNoteChange(e.target.value);
                                            }}
                                            defaultValue={""}
                                        />
                                        {txnNoteErr && (
                                            <div id="ContentPlaceHolder1_txtUserName-error" class="invalid-feedback" style={{ display: "Block" }}>
                                                {txnNoteErr}
                                            </div>
                                        )}
                                    </div>
                                    <div className="small text-secondary mt-1 ml-3">
                                        <span id="privatenotetip" />
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button data-dismiss="modal" className="btn btn-xs btn-secondary" type="button" id="closenote1" onClick={clearState}>
                                    Cancel
                                </button>

                                {editTxnHash ? (
                                    <button
                                        className="btn btn-xs btn-primary"
                                        type="button"
                                        id="savenote"
                                        data-dismiss="modal"
                                        onClick={(e) => {
                                            updateNotes(e);
                                        }}
                                    >
                                        Update
                                    </button>
                                ) : (
                                    <button
                                        className="btn btn-xs btn-primary"
                                        type="button"
                                        id="savenote"
                                        data-dismiss="modal"
                                        onClick={(e) => {
                                            addNotes(e);
                                        }}
                                    >
                                        Save
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" tabIndex={-1} role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" id="myModaldelete">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header p-2">
                                <h5 className="modal-title" id="myModalLabel">
                                    Confirmation Required
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p>
                                    Are you sure you wish to remove the private note?
                                    <br />
                                    <span className="text-secondary font-weight-normal" id="removetxhash" />
                                </p>
                            </div>
                            <div className="modal-footer">
                                <button data-dismiss="modal" className="btn btn-sm btn-secondary btn-xs" type="button">
                                    Close
                                </button>
                                <button
                                    className="btn btn-sm btn-primary btn-xs"
                                    type="button"
                                    id="btnContinueRemove"
                                    data-dismiss="modal"
                                    onClick={() => {
                                        removeNote();
                                    }}
                                >
                                    Continue
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
}

export default Mynotes_tx;
