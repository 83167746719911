import React, { useEffect, useState } from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import axios from "axios";
//var CanvasJSReact = require('@canvasjs/react-charts');

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const TxChart = () => {
  const [options, setOptions] = useState(null);
  const getBlocksData = async () => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}last10dtxs`)
      .then((res) => {
        if (res.data.error) {
          console.log("error in home blocks : ", res.data.error);
        } else {
          let data = res.data.data;

          setOptions({
            theme: "dark2",
            title: {
              text: "Transaction History in 10 days",
            },
            data: [
              {
                type: "line",
                xValueFormatString: "MMM YYYY",
                yValueFormatString: "#,##0",
                dataPoints: data,
              },
            ],
          });
        }
      })
      .catch((err) => {
        console.log("error in home blocks (catch): ", err);
      });
  };

  useEffect(() => {
    getBlocksData();
  }, []);

  return options ? <CanvasJSChart options={options} /> : "";
};

export default TxChart;
