import React, { useState, useContext, useEffect } from "react";
import GeneralInquiry from "./selection-pages/GeneralInquiry";
import SupportTechnicalIssue from "./selection-pages/SupportTechnicalIssue";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { IgniteContext } from "../IgniteContext";

function FeedbackForm() {
    const contact_url = window.location.search;
    const add = new URLSearchParams(contact_url).get("a");

    const [nameTags, setNameTags] = useState([
        {
            id: 0,
            contractaddress: "",
            nametag: "",
            website: "",
            categorylabel: "",
            shortdescription: "",
            errcontractAddress: "",
            errnameTag: "",
            errwebsite: "",
            errcategoryLabel: "",
            errshortDescription: "",
        },
    ]);

    const { selectValue, setSelectValue, validateUrl, validateEmail } = useContext(IgniteContext);
    const [getmyTokenR, setmyTokenR] = useState(false);

    const [captchError, setCaptchError] = useState("");

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [companyWebsite, setCompanyWebsite] = useState("");
    const [addressStatus, setAddressStatus] = useState(0);
    const [additionComment, setAdditionalComment] = useState("");
    const [nameTagID, setNameTagID] = useState(1);

    const [errname, setErrName] = useState("");
    const [erremail, setErrEmail] = useState("");
    const [errcompanyName, setErrCompanyName] = useState("");
    const [errcompanyWebsite, setErrCompanyWebsite] = useState("");
    const [erraddressStatus, setErrAddressStatus] = useState("");

    const [showSuccessMsg, setShowSuccessMsg] = useState(false);

    const [reCaptchaToken, setReCaptchaToken] = useState(false);
    const [reCaptchaTokenErr, setReCaptchaTokenErr] = useState("");

    const handelSearchQuery = () => {
        if (contact_url) {
            setSelectValue("3");
        } else {
            return false;
        }
    };

    useEffect(() => {
        handelSearchQuery();
    }, [contact_url]);

    const handleSelectChange = (e) => {
        setSelectValue(e.target.value);
    };

    const handleRedirect = () => {
        if (localStorage.name && localStorage.password) {
            window.location.assign("/tokenupdate");
        } else {
            window.location.assign("/login");
        }
    };

    const handleSelectBox = (e) => {
        setAddressStatus(e.target.value);
    };

    const handleSubmit = () => {
        var temp = 1;
        let nameTagArr = [];
        if (name === "") {
            setErrName("Name is require.");
            temp = 0;
        } else {
            setErrName("");
        }
        if (email === "") {
            setErrEmail("Email address is require.");
            temp = 0;
        } else {
            if (!validateEmail(email)) {
                setErrEmail("Enter the valid email.");
                temp = 0;
            } else {
                setErrEmail("");
            }
        }
        if (companyName === "") {
            setErrCompanyName("Company Name is require.");
            temp = 0;
        } else {
            setErrCompanyName("");
        }
        if (companyWebsite === "") {
            setErrCompanyWebsite("Company website is require.");
            temp = 0;
        } else {
            if (!validateUrl(companyWebsite)) {
                setErrCompanyWebsite("Invalid website url.");
                temp = 0;
            } else {
                setErrCompanyWebsite("");
            }
        }
        if (addressStatus === 0) {
            setErrAddressStatus("Please select the field.");
            temp = 0;
        } else {
            setErrAddressStatus("");
        }
        if (!reCaptchaToken) {
            setReCaptchaTokenErr("Invalid captcha.");
            temp = 0;
        } else {
            setReCaptchaTokenErr("");
        }
        nameTags.map((item) => {
            var errcontractAddress = "";
            var errnameTag = "";
            var errwebsite = "";
            var errcategoryLabel = "";
            var errshortDescription = "";
            if (item.contractaddress === "") {
                errcontractAddress = "Contract address is require.";
                temp = 0;
            } else {
                if (item.contractaddress.length > 44) {
                    errcontractAddress = "Contract address contain lessthan 44 charachters.";
                    temp = 0;
                } else {
                    errcontractAddress = "";
                }
            }
            if (item.nametag === "") {
                errnameTag = "Name tag is require.";
                temp = 0;
            } else {
                if (item.nametag) {
                    if (item.nametag.includes(" ")) {
                        errnameTag = "Whitespace is not allowed in the suggested name tag.";
                        temp = 0;
                    } else {
                        errnameTag = "";
                    }
                }
            }
            if (item.website === "") {
                errwebsite = "Website is require.";
                temp = 0;
            } else {
                if (!validateUrl(item.website)) {
                    errwebsite = "Invalid website url.";
                    temp = 0;
                } else {
                    errwebsite = "";
                }
            }
            if (item.categorylabel === "") {
                errcategoryLabel = "Category Label is require.";
                temp = 0;
            } else {
                errcategoryLabel = "";
            }
            if (item.shortdescription === "") {
                errshortDescription = "Short Description is require.";
                temp = 0;
            } else {
                errshortDescription = "";
            }
            nameTagArr.push({
                id: item.id,
                contractaddress: item.contractaddress,
                nameTag: item.nametag,
                website: item.website,
                categorylabel: item.categorylabel,
                shortDescription: item.shortdescription,
                errcontractAddress: errcontractAddress,
                errnameTag: errnameTag,
                errwebsite: errwebsite,
                errcategoryLabel: errcategoryLabel,
                errshortDescription: errshortDescription,
            });
        });
        if (temp === 1) {
            let newNameTagData = [];
            // eslint-disable-next-line
            nameTags.map((item, index) => {
                newNameTagData.push({
                    id: index + 1,
                    contractaddress: item.contractaddress,
                    nametag: item.nametag,
                    website: item.website,
                    categorylabel: item.categorylabel,
                    shortdiscription: item.shortdescription,
                    status: 0,
                });
            });
            addNameTag(newNameTagData);
        } else {
            setNameTags(nameTagArr);
        }
    };

    const addNameTag = async (nameTags1) => {
        await axios
            .post(`${process.env.REACT_APP_BACKEND_URL}publicnametag`, {
                method: "insert",
                name: name,
                email: email,
                companyname: companyName,
                companywebsite: companyWebsite,
                userstatus: addressStatus,
                nametags: nameTags1,
                comment: additionComment,
            })
            .then((res) => {
                if (!res.data.success) {
                    if (res.data?.erraddress && res.data?.erraddress?.length) {
                        let errfromback = res.data?.erraddress;
                        let errNameTage = nameTags;
                        let newErrArr = [];
                        for (let i = 0; i < errfromback?.length; i++) {
                            let findAdd = errNameTage.find((item) => item.contractaddress === errfromback[i]);
                            findAdd.errcontractAddress = "Contract address alerady in use.";
                            findAdd.errnameTag = "";
                            findAdd.errwebsite = "";
                            findAdd.errcategoryLabel = "";
                            findAdd.errshortDescription = "";
                            newErrArr.push(findAdd);
                        }
                        setNameTags(newErrArr);
                    }
                } else {
                    setShowSuccessMsg(true);
                    setSelectValue(0);
                }
            });
    };

    const handleInputChange = (index, event) => {
        const values = [...nameTags];
        const updatedValue = event.target.name;
        values[index][updatedValue] = event.target.value;

        setNameTags(values);
    };

    const handleInput = (e) => {
        // let eventClass = e.target.className;
        // // alert(eventClass)
        // switch(eventClass) {
        //   case "btn btn-sm btn-link add_contact":
        //     //   alert("hello")
        //     setContactCount(contactCount + 1)
        //     break

        // }
        setNameTags((prev) => [
            ...prev,
            {
                id: nameTagID,
                contractaddress: "",
                nametag: "",
                website: "",
                categorylabel: "",
                shortescription: "",
                errcontractAddress: "",
                errnameTag: "",
                errwebsite: "",
                errcategoryLabel: "",
                errshortDescription: "",
            },
        ]);
        setNameTagID((prev) => prev + 1);
    };

    const handleInputClose = (index) => {
        const data = nameTags.filter((item) => item.id !== index);
        setNameTags(data);
    };

    const handleRedirectNameTag = () => {
        window.location.assign("/");
    };

    const handleReCaptcha = async (checkToken) => {
        await axios
            .post(`${process.env.REACT_APP_BACKEND_URL}captcha`, {
                token: checkToken,
                skey: process.env.REACT_APP_CAPTCHA_SECRET_KEY,
            })
            .then((res) => {
                if (!res.data.success) {
                    setReCaptchaToken(false);
                    setReCaptchaTokenErr("Invalid captcha.");
                } else {
                    setReCaptchaToken(res.data.success);
                    setReCaptchaTokenErr("");
                }
            })
            .catch((err) => {
                console.log("error in handleReCaptcha (catch) : ", err);
            });
    };

    return (
        <>
            <div class="col-md-9 mb-4 mb-md-0">
                <div class="card">
                    <div class="card-header">
                        <h2 class="card-header-title">Feedback Form</h2>
                    </div>
                    <div class="card-body">
                        <div class="alert alert-info mb-4">
                            <p class="alert-text font-weight-bold">
                                Please understand that we can only assist with issues directly related to the block explorer services. Before writing
                                in, please be informed that:
                            </p>
                            <ol class="alert-text list-xs-space mb-0">
                                <li>
                                    <strong>Pending Transaction</strong>
                                    <span role="img" aria-label="img1" aria-labelledby="123456">
                                        ⏳
                                    </span>
                                    <ul class="list-unstyled">
                                        <li>
                                            We do not <strong>process transactions</strong> and are therefore unable to expedite, cancel or replace
                                            them.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>{process.env.REACT_APP_TICKER} Block Explorer</strong>{" "}
                                    <span role="img" aria-label="img1" aria-labelledby="123456">
                                        🔍
                                    </span>
                                    <ul class="list-unstyled">
                                        <li>
                                            {process.env.REACT_APP_NAME}Scan is an independent{" "}
                                            <a
                                                href="https://bscscan.freshdesk.com/support/solutions/articles/67000185691-what-is-binance-smart-chain-"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <strong>block explorer</strong>
                                            </a>{" "}
                                            unrelated to other service providers (unless stated explicitly otherwise) and is therefore unable to
                                            provide a precise response for inquiries that are specific to other service providers.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Knowledge Base</strong>{" "}
                                    <span role="img" aria-label="img1" aria-labelledby="123456">
                                        📚
                                    </span>
                                    <ul class="list-unstyled">
                                        <li>
                                            The links on the right might already have the answer to your inquiry. If they don't, perhaps one of the
                                            other articles in our{" "}
                                            <a href="https://bscscan.freshdesk.com/support/solutions" target="_blank" rel="noopener noreferrer">
                                                Knowledge Base
                                            </a>{" "}
                                            page does.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Community Support</strong>{" "}
                                    <span role="img" aria-label="img1" aria-labelledby="123456">
                                        👋
                                    </span>
                                    <ul class="list-unstyled">
                                        <li>
                                            We will never ask for your private keys or passwords. <strong>NEVER send us these.</strong> While we try
                                            our best to respond in a timely manner, we often have a backlog of inquiries. Please do not{" "}
                                            <strong>spam us</strong>.
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Wallet / Exchange / Project related issues</strong>{" "}
                                    <span role="img" aria-label="img1" aria-labelledby="123456">
                                        🏦
                                    </span>
                                    <ul class="list-unstyled">
                                        <li>
                                            Kindly reach out to your wallet service provider, exchanges or project/contract owner for further support
                                            as they are in a better position to assist you on the issues related to and from their platforms.
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                        </div>
                        <p class="mt-0 mb-2">
                            <span id="ContentPlaceHolder1_lblResult"></span>
                        </p>
                        {/* eslint-disable-next-line */}
                        <form method="post" action="./contactus" onSubmit="javascript:return WebForm_OnSubmit();" id="form1" class="js-validate">
                            <div class="aspNetHidden">
                                <input type="hidden" name="__EVENTTARGET" id="__EVENTTARGET" value="" />
                                <input type="hidden" name="__EVENTARGUMENT" id="__EVENTARGUMENT" value="" />
                                <input type="hidden" name="__LASTFOCUS" id="__LASTFOCUS" value="" />
                                <input
                                    type="hidden"
                                    name="__VIEWSTATE"
                                    id="__VIEWSTATE"
                                    value="wHhE1pdm6kF8Ogjjvas0WOU0EWEh22M9FLIYg2w+rwa+Fq78E1l9kgIPOXY/5t8PZ+h7mGzJiwoodjGl9o2ZJd7PRrAfq45ZDf8mP4JiUEN4+OxR8CvC4PKKMn9NcUrNFJrs6GGIHXnnarp436mOT3dn1D9LA7JKFvth9bKIJ6RK0Tx9ph0EQKjIQ/u4HEgtfN3BaH0yt/tSHRPfJySGB3A72OEpVQq/lLQsLcWSvFxQfqHxZbvZV7TdKWLpaHk5c3jrXkPWopxhD19spoyIkAgstU5a33BV1GUyFioPyYM4H+SelxE6ZprX98mq6li01HJYmlkxaY1O57ivlUKqEqI21MEzLinuD/SDoR/+KmdvjunHwk9LyPl5YGjkJBX4GWyfr4TJnIxJZQTuNFNPLOLw8YXZ/b8k6y931cQQSPjoLGaiDd5i7aXTqhZnnRi7odrUkS4sGi6tA6hUg7PvuaW8a43+pLHGCvKfmTOQOo1zELbzYKmamQfVNITs2qZO5TN39LwvzN/R60FaAMFim/hX92i7uLiGupJmcrXM1lnHX3dtXjBEPxPGKBj/pczTjy1cdCjmgCkulRN5CmK0he57Fbvsm5RoFgCt/FyNKskgUUDUYOGxHfM2xa0JIQVA8lddG1fbhc4gnZvAJpK0N49DMIUizRRzn5dMhcQGntiepbEEzFwQyHXWLYzYn6GeqBlYMhcQUWozSheUyzDWzs+YtxNicVXuvNNPBXm4pfTJyphfhhmvin6DevBfisy6NGtlIA4lug12XQtIprBVy+o5N/Csi21uMb/AYXS/UFtOIQ52X4ebhChSkKII09RTRjAOVGUYUFX/DIi4jNmTnLo7Bzy69rQ0gaKMTmVDBsfJYqVcUhYQsdvW5A8haokEdeynXZR9i2u35UmIyj94WW5HiCanHXCfGYuFH3/1c5pNJXleaMhjf5Hbz3LtuLopZf+UEO9WCgH689idSwf0dosEaFx0dpFuS36/pVZLUzXb/idmuXyCkdlubZJUZXoO4qmEKwAy9yrrVgY460ksMaQTiN0NDPSRjb2uLhKlmcgZUK/5LMah2YrdMAFuGEvH6FUqdR9ma/VGCnfi1M+68KuBSKWj6URjKlktEBB8r2UHmsXAaeUUt5rxPpu/ABFOoN5XNSO9JH8HiOcqQXllOimT0YfV14hijxSSkLpzlkztroiROjTN7dwSdeIFcySnEioH+ynIt8EaOzffugQuHTCb8lmB+Q6dedWzQxS+tjpWTqtTR5BTHzYtLhd04gVwvYNdYPYzcN2RJL6z90E257eHjKbsyxHEpFuA612gRM1uiY3aEuCubZwzOmqaRIor0VgATmpZpsanbF5uV36LOSNjViFOjwSfGAGxFhRsSvd/YFkzikLxiflt5UFRrS8HnkDVjAYPGOjkl5c84pICAyQsO4KoqXsX7MaWkuvxRgwG8DDHA3JT/RocDAJNa0j0WovhL5OAWaG81WtRbLz7b5QgadgLfBIA/+zkVgfhsJc="
                                />
                            </div>

                            <div class="aspNetHidden">
                                <input type="hidden" name="__VIEWSTATEGENERATOR" id="__VIEWSTATEGENERATOR" value="46798EB0" />
                                <input
                                    type="hidden"
                                    name="__EVENTVALIDATION"
                                    id="__EVENTVALIDATION"
                                    value="yorcKJtrzVi70+jje4H6SKR23GJnEsUuMIOC3HRUiGUeGLL5bmKHCpNB8BNC38NYu/f2sVH3EGDq8iA6+fQdNYkWWura4wMOWoC1pAyowIuEzifrX02+vtgZdeEpGx3ygUSwXzE5d2yEvLblBf4RDRCLBg+rRXPhXPf/yiKBDXHrcUVZdTFCqjO0YDfMDY/cu1XcXrQbdVhFsmcnvT81z3Diaiw7pHbPwxUSMch51SbOwDpbkBHkkeJic5G5BUbR"
                                />
                            </div>

                            <div class="row mb-2">
                                {showSuccessMsg ? (
                                    <p style={{ color: "green", fontSize: "1.2rem", marginLeft: "10px" }}> Your request has been submitted.</p>
                                ) : selectValue === "3" || selectValue === 3 || selectValue === "4" || selectValue === 4 ? (
                                    <>
                                        <div class="col-lg-6">
                                            <label class="mr-2">
                                                Subject<span class="text-danger"> * </span>
                                            </label>
                                            <select
                                                name="ctl00$ContentPlaceHolder1$ddlSubject"
                                                onChange={handleSelectChange}
                                                disabled
                                                id="ContentPlaceHolder1_ddlSubject"
                                                class="custom-select"
                                            >
                                                <option selected="selected" value="0">
                                                    Please Select Your Message Subject
                                                </option>
                                                <option value="1">1. General Inquiry (Non support related)</option>
                                                <option value="2">2. Support / Technical Issue</option>
                                                <option value="3">3. Name Tagging / Label Address</option>
                                                <option value="4">4. Update Token Info (for Contract Owners)</option>
                                                <option value="5">5. API Pro inquiry</option>
                                            </select>
                                        </div>

                                        <div class="col-lg-6 align-self-end mt-3">
                                            <input
                                                type="button"
                                                name="ctl00$ContentPlaceHolder1$btnReset"
                                                value="Start Over"
                                                id="ContentPlaceHolder1_btnReset"
                                                class="btn btn-soft-primary cancel"
                                                onClick={() => {
                                                    window.location.reload();
                                                    window.location.assign("/contactus");
                                                }}
                                                data-toggle="tooltip"
                                                title="Click to reset and start over"
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div class="col-lg-6">
                                            <label class="mr-2">
                                                Subject<span class="text-danger"> * </span>
                                            </label>
                                            <select
                                                name="ctl00$ContentPlaceHolder1$ddlSubject"
                                                onChange={(e) => setSelectValue(e.target.value)}
                                                id="ContentPlaceHolder1_ddlSubject"
                                                class="custom-select"
                                            >
                                                <option selected="selected" value="0">
                                                    Please Select Your Message Subject
                                                </option>
                                                <option value="1">1. General Inquiry (Non support related)</option>
                                                <option value="2">2. Support / Technical Issue</option>
                                                <option value="3">3. Name Tagging / Label Address</option>
                                                <option value="4">4. Update Token Info (for Contract Owners)</option>
                                                <option value="5">5. API Pro inquiry</option>
                                            </select>
                                        </div>
                                        <div class="col-lg-6 align-self-end mt-3"></div>
                                    </>
                                )}
                                {/* <div class="col-lg-6 align-self-end mt-3">
                                </div> */}
                            </div>

                            {selectValue === "" ? (
                                <p class="mb-0">
                                    <span id="ContentPlaceHolder1_RequiredFieldValidator4" class="small text-danger" style={{ display: "none" }}>
                                        {" "}
                                        * Missing Subject
                                    </span>
                                    <strong>Note</strong>: Selecting an incorrect subject could result in a delayed or non response
                                </p>
                            ) : (
                                ""
                            )}
                            {/* // <p class="mb-0">
                            //     <span id="ContentPlaceHolder1_RequiredFieldValidator4" class="small text-danger" style={{ display: "none" }}>  * Missing Subject</span>
                            //     <strong>Note</strong>: Selecting an incorrect subject could result in a delayed or non response
                            // </p> */}
                            {(() => {
                                if (selectValue === "1" || selectValue === 1) {
                                    return (
                                        <>
                                            {/* <h3>Hello there</h3> */}
                                            <GeneralInquiry />
                                        </>
                                    );
                                } else if (selectValue === "2" || selectValue === 2) {
                                    return (
                                        <>
                                            <SupportTechnicalIssue />
                                        </>
                                    );
                                } else if (selectValue === "3" || selectValue === 3) {
                                    return (
                                        <>
                                            <div id="ContentPlaceHolder1_maindiv">
                                                <hr className="hr-space-lg" />
                                                <div id="ContentPlaceHolder1_divTokenAlert" className="alert alert-secondary mb-4">
                                                    <div className="media align-items-baseline">
                                                        <div className="media-body">
                                                            <div className="d-flex align-items-baseline">
                                                                <i className="fal fa-lightbulb-on mr-1" />
                                                                <p className="alert-text font-weight-bold mb-0">To tag an address for private use:</p>
                                                            </div>
                                                            <p className="alert-text mb-2">
                                                                Log in to your {process.env.REACT_APP_NAME}Scan account, navigate to the address page
                                                                and click on "Update?" located next to the "My Name Tag" section.
                                                            </p>
                                                            <div className="d-flex align-items-baseline">
                                                                <i className="fal fa-lightbulb-on mr-1" />
                                                                <p className="alert-text font-weight-bold mb-0">To update token information:</p>
                                                            </div>
                                                            <p className="alert-text mb-0">
                                                                Log in to your {process.env.REACT_APP_NAME}Scan account and proceed to our token
                                                                update page: <a href="" target="_blank" rel="noopener noreferrer"></a>
                                                            </p>
                                                            <p className="alert-text mb-0">
                                                                If you do not have an account, please sign up here:{" "}
                                                                <a href="" target="_blank" rel="noopener noreferrer"></a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6 mb-4">
                                                        <div
                                                            className={
                                                                errname ? "js-form-message form-group u-has-error" : "js-form-message form-group"
                                                            }
                                                        >
                                                            <label>
                                                                Name<span className="text-danger"> * </span>
                                                                <span className="font-size-1 text-secondary">(required)</span>
                                                            </label>
                                                            <input
                                                                name="ctl00$ContentPlaceHolder1$txtName"
                                                                value={name}
                                                                type="text"
                                                                maxLength={150}
                                                                id="ContentPlaceHolder1_txtName"
                                                                className="form-control"
                                                                required
                                                                data-msg="Please enter your name"
                                                                data-error-class="u-has-error"
                                                                data-success-class="u-has-success"
                                                                placeholder="John Doe"
                                                                onChange={(e) => setName(e.target.value)}
                                                            />
                                                            {errname ? <p className="invalid-feedback mx-2 d-block">{errname}</p> : ""}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 mb-4">
                                                        <div
                                                            className={
                                                                erremail ? "js-form-message form-group u-has-error" : "js-form-message form-group"
                                                            }
                                                        >
                                                            <label>
                                                                Email Address<span className="text-danger"> * </span>
                                                                <span className="font-size-1 text-secondary">(required)</span>
                                                            </label>
                                                            <input
                                                                name="ctl00$ContentPlaceHolder1$txtEmail"
                                                                value={email}
                                                                maxLength={150}
                                                                id="ContentPlaceHolder1_txtEmail"
                                                                className="form-control"
                                                                type="email"
                                                                required
                                                                data-msg="Please enter a valid email address"
                                                                data-error-class="u-has-error"
                                                                data-success-class="u-has-success"
                                                                placeholder="johndoe@cryptokitties.com"
                                                                aria-label="johndoe@cryptokitties.com"
                                                                onChange={(e) => setEmail(e.target.value)}
                                                            />
                                                            {erremail ? <p className="invalid-feedback mx-2 d-block">{erremail}</p> : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="ContentPlaceHolder1_divCompanyInfo" className="row">
                                                    <div className="col-sm-6 mb-4">
                                                        <div
                                                            className={
                                                                errcompanyName
                                                                    ? "js-form-message form-group u-has-error"
                                                                    : "js-form-message form-group"
                                                            }
                                                        >
                                                            <label>
                                                                Company Name<span className="text-danger"> * </span>
                                                                <span className="font-size-1 text-secondary">(required)</span>
                                                            </label>
                                                            <input
                                                                name="ctl00$ContentPlaceHolder1$txtCompanyName"
                                                                value={companyName}
                                                                type="text"
                                                                maxLength={150}
                                                                id="ContentPlaceHolder1_txtCompanyName"
                                                                className="form-control"
                                                                required
                                                                data-msg="Please enter your company name"
                                                                data-error-class="u-has-error"
                                                                data-success-class="u-has-success"
                                                                placeholder="Cryptokitties"
                                                                onChange={(e) => setCompanyName(e.target.value)}
                                                            />
                                                            {errcompanyName ? <p className="invalid-feedback mx-2 d-block">{errcompanyName}</p> : ""}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 mb-4">
                                                        <div
                                                            className={
                                                                errcompanyWebsite
                                                                    ? "js-form-message form-group u-has-error"
                                                                    : "js-form-message form-group"
                                                            }
                                                        >
                                                            <label>
                                                                Company Website<span className="text-danger"> * </span>
                                                                <span className="font-size-1 text-secondary">(required)</span>
                                                            </label>
                                                            <input
                                                                name="ctl00$ContentPlaceHolder1$txtCompanyWebsite"
                                                                value={companyWebsite}
                                                                type="text"
                                                                maxLength={150}
                                                                id="ContentPlaceHolder1_txtCompanyWebsite"
                                                                className="form-control"
                                                                required
                                                                data-msg="Please enter your company website"
                                                                data-error-class="u-has-error"
                                                                data-success-class="u-has-success"
                                                                placeholder="https://cryptokitties.com"
                                                                onChange={(e) => setCompanyWebsite(e.target.value)}
                                                            />
                                                            {errcompanyWebsite ? (
                                                                <p className="invalid-feedback mx-2 d-block">{errcompanyWebsite}</p>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div id="ContentPlaceHolder1_divCompanyUser" className="row">
                                                    <div className="col-sm-6 mb-4">
                                                        <div
                                                            className={
                                                                erraddressStatus
                                                                    ? "js-form-message form-group u-has-error"
                                                                    : "js-form-message form-group"
                                                            }
                                                        >
                                                            <label>
                                                                Is this an address owner or a user submission?<span className="text-danger"> * </span>
                                                                <span className="font-size-1 text-secondary">(required)</span>
                                                            </label>
                                                            <select
                                                                id="ddlCompanyUser"
                                                                className="custom-select"
                                                                required
                                                                data-msg="Please select the field"
                                                                data-error-class="u-has-error"
                                                                data-success-class="u-has-success"
                                                                onChange={handleSelectBox}
                                                            >
                                                                <option value={0}>Please select the field</option>
                                                                <option value={1}>I'm the address owner</option>
                                                                <option value={2}>I'm a user</option>
                                                            </select>
                                                            {erraddressStatus ? (
                                                                <p className="invalid-feedback mx-2 d-block">{erraddressStatus}</p>
                                                            ) : (
                                                                ""
                                                            )}
                                                            <input
                                                                type="hidden"
                                                                name="ctl00$ContentPlaceHolder1$hdnCompanyUser"
                                                                id="ContentPlaceHolder1_hdnCompanyUser"
                                                            />
                                                            <input
                                                                type="hidden"
                                                                name="ctl00$ContentPlaceHolder1$hdnCompanyUserId"
                                                                id="ContentPlaceHolder1_hdnCompanyUserId"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="mb-4" id="divAssociateAddress">
                            <label>Where did you discover this address? <a className="font-size-1" target="_blank" rel="nofollow noopener" href="https://compound.finance/developers">Example Link <i className="far fa-external-link-alt ml-1" /></a></label>
                            <textarea name="ctl00$ContentPlaceHolder1$txtAssociatedAddress" rows={1} cols={20} id="ContentPlaceHolder1_txtAssociatedAddress" className="form-control mb-2" placeholder="Project's developer page at https://compound.finance/developers" defaultValue={""} />
                        </div> */}

                                                <div id="ContentPlaceHolder1_divNameTag" className="mb-4">
                                                    <hr className="hr-space-lg" />
                                                    <h3 className="h5">Name Tagging</h3>
                                                    {nameTags.map((item, index) => (
                                                        <div className="position-relative bg-light border rounded p-3 mb-3" id={`delete${item.id}`}>
                                                            <div
                                                                className="position-absolute bg-white-content"
                                                                style={{ top: "-5px", right: "-5px", borderRadius: "10rem" }}
                                                            >
                                                                {index !== 0 && (
                                                                    <button
                                                                        type="button"
                                                                        name="ctl00$ContentPlaceHolder1$rptNameTag$ctl01$btnCloseNT"
                                                                        value="X"
                                                                        onClick={() => handleInputClose(item.id)}
                                                                        id="ContentPlaceHolder1_rptNameTag_btnCloseNT_1"
                                                                        class="btn btn-sm btn-outline-danger btn-icon rounded-circle cancel"
                                                                    >
                                                                        X
                                                                    </button>
                                                                )}
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6 mb-4">
                                                                    <div
                                                                        className={
                                                                            item.errcontractAddress
                                                                                ? "js-form-message form-group u-has-error"
                                                                                : "js-form-message form-group"
                                                                        }
                                                                    >
                                                                        <label>
                                                                            Smart Contract / Address <span className="text-danger">*</span>{" "}
                                                                            <span className="font-size-1 text-secondary">(required)</span>
                                                                        </label>
                                                                        <input
                                                                            type="hidden"
                                                                            name="contractAddress"
                                                                            id={`contractAddress${item.id}`}
                                                                            defaultValue={1}
                                                                        />
                                                                        {contact_url && index === 0 ? (
                                                                            <input
                                                                                name="contractaddress"
                                                                                defaultValue={add}
                                                                                type="text"
                                                                                id="ContentPlaceHolder1_rptNameTag_txtNTAddress_0"
                                                                                className="form-control"
                                                                                placeholder="0x..."
                                                                                required
                                                                                data-msg="Please enter your address"
                                                                                data-error-class="u-has-error"
                                                                                data-success-class="u-has-success"
                                                                                value={add}
                                                                                onChange={(event) => handleInputChange(index, event)}
                                                                            />
                                                                        ) : (
                                                                            <input
                                                                                name="contractaddress"
                                                                                type="text"
                                                                                id="ContentPlaceHolder1_rptNameTag_txtNTAddress_0"
                                                                                className="form-control"
                                                                                placeholder="0x..."
                                                                                required
                                                                                data-msg="Please enter your address"
                                                                                data-error-class="u-has-error"
                                                                                data-success-class="u-has-success"
                                                                                value={item.contractaddress}
                                                                                onChange={(event) => handleInputChange(index, event)}
                                                                            />
                                                                        )}
                                                                        {/* <input name="contractAddress" defaultValue={add} type="text" id="ContentPlaceHolder1_rptNameTag_txtNTAddress_0" className="form-control" placeholder="0x..." required data-msg="Please enter your address" data-error-class="u-has-error" data-success-class="u-has-success"
                                                            value={item.contractAddress}
                                                                onChange={(event) => handleInputChange(index, event)} /> */}
                                                                        {item.errcontractAddress ? (
                                                                            <p className="invalid-feedback mx-2 d-block">{item.errcontractAddress}</p>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-4 mb-4">
                                                                    <div
                                                                        className={
                                                                            item.errnameTag
                                                                                ? "js-form-message form-group u-has-error"
                                                                                : "js-form-message form-group"
                                                                        }
                                                                    >
                                                                        <label>
                                                                            Suggested Name Tag <span className="text-danger">*</span>{" "}
                                                                            <span className="font-size-1 text-secondary">(required)</span>
                                                                        </label>
                                                                        <input
                                                                            name="nametag"
                                                                            value={item.nametag}
                                                                            type="text"
                                                                            id="ContentPlaceHolder1_rptNameTag_txtNTSuggested_0"
                                                                            className="form-control"
                                                                            placeholder="e.g. Compound Contract"
                                                                            required
                                                                            data-msg="Please enter your name tag"
                                                                            data-error-class="u-has-error"
                                                                            data-success-class="u-has-success"
                                                                            onChange={(event) => handleInputChange(index, event)}
                                                                        />
                                                                        {item.errnameTag ? (
                                                                            <p className="invalid-feedback mx-2 d-block">{item.errnameTag}</p>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4 mb-4">
                                                                    <div
                                                                        className={
                                                                            item.errwebsite
                                                                                ? "js-form-message form-group u-has-error"
                                                                                : "js-form-message form-group"
                                                                        }
                                                                    >
                                                                        <label>
                                                                            Website <span className="text-danger">*</span>{" "}
                                                                            <span className="font-size-1 text-secondary">(required)</span>
                                                                        </label>
                                                                        <input
                                                                            name="website"
                                                                            value={item.website}
                                                                            type="text"
                                                                            id="ContentPlaceHolder1_rptNameTag_txtNTWebsite_0"
                                                                            className="form-control"
                                                                            placeholder="https://www.example.com"
                                                                            required
                                                                            data-msg="Please enter your website"
                                                                            data-error-class="u-has-error"
                                                                            data-success-class="u-has-success"
                                                                            onChange={(event) => handleInputChange(index, event)}
                                                                        />
                                                                        {item.errwebsite ? (
                                                                            <p className="invalid-feedback mx-2 d-block">{item.errwebsite}</p>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4 mb-4">
                                                                    <div
                                                                        className={
                                                                            item.errcategoryLabel
                                                                                ? "js-form-message form-group u-has-error"
                                                                                : "js-form-message form-group"
                                                                        }
                                                                    >
                                                                        <label>Category Label</label>
                                                                        <input
                                                                            name="categorylabel"
                                                                            value={item.categorylabel}
                                                                            type="text"
                                                                            id="ContentPlaceHolder1_rptNameTag_txtNTCategoryLabel_0"
                                                                            className="form-control"
                                                                            placeholder="e.g. DeFi"
                                                                            onChange={(event) => handleInputChange(index, event)}
                                                                        />
                                                                        {item.errcategoryLabel ? (
                                                                            <p className="invalid-feedback mx-2 d-block">{item.errcategoryLabel}</p>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={
                                                                    item.errshortDescription
                                                                        ? "js-form-message form-group u-has-error"
                                                                        : "js-form-message form-group"
                                                                }
                                                            >
                                                                <label>
                                                                    Short Description <span className="text-danger">*</span>{" "}
                                                                    <span className="font-size-1 text-secondary">(required)</span>
                                                                </label>
                                                                <textarea
                                                                    name="shortdescription"
                                                                    value={item.shortdescription}
                                                                    rows={3}
                                                                    cols={20}
                                                                    id="ContentPlaceHolder1_rptNameTag_txtNTShortDesc_0"
                                                                    className="form-control mb-2"
                                                                    placehoder="Type here to describe the purpose of the address"
                                                                    aria-label="Type here to describe the purpose of the address"
                                                                    required
                                                                    data-msg="Please enter short description"
                                                                    data-error-class="u-has-error"
                                                                    defaultValue={""}
                                                                    onChange={(event) => handleInputChange(index, event)}
                                                                />
                                                                {item.errshortDescription ? (
                                                                    <p className="invalid-feedback mx-2 d-block">{item.errshortDescription}</p>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        </div>
                                                    ))}
                                                    {/* {getContactInputElementsRemove()}

                                    {getContactInputElements()} */}

                                                    <div className="mb-4 mt-n1">
                                                        <button onClick={handleInput} type="button" className="btn btn-sm btn-link add_contact">
                                                            <i className="fa fa-plus-circle mr-1" />
                                                            Add more name tagging
                                                        </button>
                                                    </div>
                                                </div>
                                                <div id="ContentPlaceHolder1_divAdditionalComment" className="mb-4">
                                                    <hr className="hr-space-lg" />
                                                    <label>Additional Comment</label>
                                                    <textarea
                                                        name="ctl00$ContentPlaceHolder1$txtAdditionalComment"
                                                        value={additionComment}
                                                        rows={2}
                                                        cols={20}
                                                        id="ContentPlaceHolder1_txtAdditionalComment"
                                                        className="form-control"
                                                        onChange={(e) => setAdditionalComment(e.target.value)}
                                                    />
                                                    {/* {
                                            erradditionComment ? (<p style={{color: "red", fontSize: "0.9rem"}}>  {erradditionComment}</p>) : ""
                                        } */}
                                                </div>
                                                <div className="row my-3">
                                                    <div className="col-md-12">
                                                        <div className="row form-group text-center justify-content-center">
                                                            <div className="d-flex flex-column justify-content-center my-2 align-items-center">
                                                                <ReCAPTCHA
                                                                    sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                                                                    onChange={(getmyToken) => {
                                                                        handleReCaptcha(getmyToken);
                                                                    }}
                                                                    onExpired={() => {
                                                                        setReCaptchaToken(false);
                                                                    }}
                                                                    tabIndex={3}
                                                                />
                                                                {reCaptchaTokenErr && (
                                                                    <div
                                                                        id="ContentPlaceHolder1_txtUserName-error"
                                                                        className="invalid-feedback f- text-center "
                                                                        style={{ display: "Block", fontSize: "14px" }}
                                                                    >
                                                                        {reCaptchaTokenErr}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="text-center my-3">
                                                            <i
                                                                id="spinwheel"
                                                                className="fa fa-spin fa-spinner fa-2x fa-pulse mr-1"
                                                                style={{ display: "none" }}
                                                            />
                                                            <input
                                                                type="button"
                                                                name="ctl00$ContentPlaceHolder1$btnSubmit"
                                                                defaultValue="Send Message"
                                                                id="ContentPlaceHolder1_btnSubmit"
                                                                className="btn btn-primary btn-wide transition-3d-hover mb-4"
                                                                onClick={handleSubmit}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    );
                                }
                                // else if (selectValue == "4" || selectValue == 4) {
                                //     return (
                                //         <>

                                //         </>
                                //     )
                                // }
                                else if (selectValue === "5" || selectValue === 5) {
                                    window.location.assign("");
                                } else {
                                    return false;
                                }
                            })()}

                            <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" id="myModal">
                                <div class="modal-dialog modal-dialog-centered" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header p-2">
                                            <h5 class="modal-title" id="myModalLabel">
                                                Update Token Information Page
                                            </h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <p>
                                                To submit a token information's update request, please confirm beforehand that you have:
                                                <ul>
                                                    <li>registered and logged in to your {process.env.REACT_APP_NAME}Scan account</li>
                                                    <li>
                                                        verified the contract's source code using our tool{" "}
                                                        <a href="/verifyContract" target="_blank">
                                                            here
                                                        </a>
                                                    </li>
                                                    <li>
                                                        been successfully verified as the{" "}
                                                        <a
                                                            href="https://bscscan.freshdesk.com/support/solutions/articles/67000573840-what-is-verify-contract-address-ownership-"
                                                            data-placement="top"
                                                            data-toggle="tooltip"
                                                            data-original-title="What is Verify Address Ownership?"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            contract address owner
                                                        </a>
                                                    </li>
                                                </ul>
                                            </p>
                                        </div>
                                        <div class="modal-footer">
                                            <button data-dismiss="modal" class="btn btn-sm btn-secondary btn-xs" type="button">
                                                Close
                                            </button>
                                            {localStorage.name && localStorage.password ? (
                                                <input
                                                    type="button"
                                                    name="ctl00$ContentPlaceHolder1$btnGotoLogin"
                                                    value="Update Token Info"
                                                    onClick={handleRedirect}
                                                    id="ContentPlaceHolder1_btnGotoLogin"
                                                    class="btn btn-sm btn-primary btn-xs"
                                                />
                                            ) : (
                                                <input
                                                    type="button"
                                                    name="ctl00$ContentPlaceHolder1$btnGotoLogin"
                                                    value="Login"
                                                    onClick={handleRedirect}
                                                    id="ContentPlaceHolder1_btnGotoLogin"
                                                    class="btn btn-sm btn-primary btn-xs"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" id="myModal2">
                                <div class="modal-dialog modal-dialog-centered" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header p-2">
                                            <h5 class="modal-title" id="myModalLabel">
                                                Message
                                            </h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                            <p>
                                                <li>Your name tag submitted successfully.</li>
                                            </p>
                                        </div>
                                        <div class="modal-footer">
                                            {/* <button data-dismiss="modal" class="btn btn-sm btn-secondary btn-xs" type="button">Close</button> */}
                                            <input
                                                type="button"
                                                name="ctl00$ContentPlaceHolder1$btnGotoLogin"
                                                value="Ok"
                                                onClick={handleRedirectNameTag}
                                                id="ContentPlaceHolder1_btnGotoLogin"
                                                class="btn btn-sm btn-primary btn-xs"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FeedbackForm;
