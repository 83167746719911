import React, { useState } from "react";
import Header2 from "../common/Header2";
import Footer from "../common/Footer";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

const Careers = () => {
    const [reCaptchaToken, setReCaptchaToken] = useState(false);
    const [reCaptchaTokenErr, setReCaptchaTokenErr] = useState("");

    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [text, setText] = useState("");
    const [emailErr, setEmailErr] = useState("");
    const [subjectErr, setSubjectErr] = useState("");
    const [textErr, setTextErr] = useState("");

    const handleReCaptcha = async (checkToken) => {
        await axios
            .post(`${process.env.REACT_APP_BACKEND_URL}captcha`, {
                token: checkToken,
                skey: process.env.REACT_APP_CAPTCHA_SECRET_KEY,
            })
            .then((res) => {
                if (!res.data.success) {
                    setReCaptchaToken(false);
                    setReCaptchaTokenErr("Invalid captcha.");
                } else {
                    setReCaptchaToken(res.data.success);
                    setReCaptchaTokenErr("");
                }
            })
            .catch((err) => {
                console.log("error in handleReCaptcha (catch) : ", err);
            });
    };

    const openMail = async (e) => {
        e.preventDefault();
        let temp = 1;
        // if (!reCaptchaToken) {
        //     setReCaptchaTokenErr("Invalid captcha.");
        //     temp = 0;
        // } else {
        //     setReCaptchaTokenErr("");
        // }
        if (temp) {
            console.log("process.env.REACT_APP_MAILID : ", process.env.REACT_APP_MAILID);
            window.location.href = `mailto:${process.env.REACT_APP_MAILID}?subject=${subject}&body=${text}`;
            setTimeout(() => {
                setSubject("");
                setText("");
            }, 2000);
        }
    };

    return (
        <>
            <Header2 />
            <main id="content" role="main">
                <div className="space-1">
                    <div className="container">
                        <div id="contactSection" className="space-1 space-bottom-2">
                            <div className="w-lg-75 w-xl-50 mx-auto">
                                <div className="text-center">
                                    <h2 className="h3">Careers</h2>
                                    {/* <p className="lead">Please use this form to get in touch for priority support with our team.</p> */}
                                </div>
                            </div>
                            <div className="w-lg-75 w-xl-50 mx-auto">
                                <form onSubmit={openMail}>
                                    {/* <div className="js-form-message mb-3">
                                        <label>
                                            Email Address <span className="text-danger">*</span>{" "}
                                            <span className="font-size-1 text-secondary">(required)</span>
                                        </label>
                                        <input
                                            name="ctl00$ContentPlaceHolder1$txtName"
                                            type="email"
                                            maxLength={150}
                                            className="form-control"
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div> */}
                                    <div className="js-form-message mb-3">
                                        <label>
                                            Subject <span className="text-danger">*</span>{" "}
                                            <span className="font-size-1 text-secondary">(required)</span>
                                        </label>
                                        <input
                                            name="ctl00$ContentPlaceHolder1$txtTokenContractAddress"
                                            type="text"
                                            className="form-control"
                                            placeholder="Subject"
                                            value={subject}
                                            onChange={(e) => setSubject(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="js-form-message mb-3">
                                        <label>
                                            Text <span className="text-danger">*</span> <span className="font-size-1 text-secondary">(required)</span>
                                        </label>
                                        <textarea
                                            className="form-control mb-2"
                                            rows={6}
                                            placeholder="Write here."
                                            defaultValue={""}
                                            value={text}
                                            onChange={(e) => setText(e.target.value)}
                                            required
                                        />
                                    </div>
                                    {/* <div className="row form-group text-center justify-content-center">
                                        <div className="d-flex flex-column justify-content-center my-2 align-items-center">
                                            <ReCAPTCHA
                                                sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY}
                                                onChange={(getmyToken) => {
                                                    handleReCaptcha(getmyToken);
                                                }}
                                                onExpired={() => {
                                                    setReCaptchaToken(false);
                                                }}
                                                tabIndex={3}
                                            />
                                            {reCaptchaTokenErr && (
                                                <div
                                                    id="ContentPlaceHolder1_txtUserName-error"
                                                    className="invalid-feedback f- text-center "
                                                    style={{ display: "Block", fontSize: "14px" }}
                                                >
                                                    {reCaptchaTokenErr}
                                                </div>
                                            )}
                                        </div>
                                    </div> */}
                                    <div className="text-center">
                                        <input type="submit" className="btn btn-primary btn-wide transition-3d-hover my-5 px-5" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </>
    );
};

export default Careers;
